import React from 'react';
import { Navigate } from 'react-router-dom';

interface PublicRouteProps {
  element: React.ReactElement;
  restricted: boolean;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ element, restricted }) => {
  // Example token check (replace with your actual logic)
  const token = localStorage.getItem('authToken'); // Example for checking token

  return token && restricted ? <Navigate to="/" /> : element;
};

export default PublicRoute;
