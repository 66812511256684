const COUNTRY_CODES = {
  TAIWAN: '+886',
  USA: '+1',
  AU: '+61',
};

const US_CITIES = [
  {Id: 'CIT_US_NY_1', Name: 'New York', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_CA_2',
    Name: 'Los Angeles',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_3', Name: 'Chicago', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_FL_4', Name: 'Miami', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_TX_5', Name: 'Dallas', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_6',
    Name: 'Philadelphia',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_7', Name: 'Houston', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_GA_8', Name: 'Atlanta', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_DC_9',
    Name: 'Washington',
    stateId: 'DC',
    stateName: 'District of Columbia',
  },
  {
    Id: 'CIT_US_MA_10',
    Name: 'Boston',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_AZ_11', Name: 'Phoenix', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_WA_12', Name: 'Seattle', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_CA_13',
    Name: 'San Francisco',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MI_14', Name: 'Detroit', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_CA_15',
    Name: 'San Diego',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_16',
    Name: 'Minneapolis',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_FL_17', Name: 'Tampa', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_CO_18', Name: 'Denver', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_NY_19', Name: 'Brooklyn', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_NY_20', Name: 'Queens', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_CA_21',
    Name: 'Riverside',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MD_22', Name: 'Baltimore', stateId: 'MD', stateName: 'Maryland'},
  {Id: 'CIT_US_NV_23', Name: 'Las Vegas', stateId: 'NV', stateName: 'Nevada'},
  {Id: 'CIT_US_OR_24', Name: 'Portland', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_TX_25', Name: 'San Antonio', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MO_26', Name: 'St. Louis', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_CA_27',
    Name: 'Sacramento',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_FL_28', Name: 'Orlando', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_29',
    Name: 'San Jose',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_30', Name: 'Cleveland', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_31',
    Name: 'Pittsburgh',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_32', Name: 'Austin', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_33', Name: 'Cincinnati', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MO_34',
    Name: 'Kansas City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_NY_35', Name: 'Manhattan', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_IN_36',
    Name: 'Indianapolis',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OH_37', Name: 'Columbus', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_38',
    Name: 'Charlotte',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_VA_39',
    Name: 'Virginia Beach',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_NY_40', Name: 'Bronx', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_WI_41',
    Name: 'Milwaukee',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_RI_42',
    Name: 'Providence',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_FL_43',
    Name: 'Jacksonville',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_UT_44',
    Name: 'Salt Lake City',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_TN_45',
    Name: 'Nashville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_VA_46', Name: 'Richmond', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_TN_47', Name: 'Memphis', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_NC_48',
    Name: 'Raleigh',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_LA_49',
    Name: 'New Orleans',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_KY_50',
    Name: 'Louisville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_OK_51',
    Name: 'Oklahoma City',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_CT_52',
    Name: 'Bridgeport',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_NY_53', Name: 'Buffalo', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_TX_54', Name: 'Fort Worth', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CT_55',
    Name: 'Hartford',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_AZ_56', Name: 'Tucson', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_NE_57', Name: 'Omaha', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_TX_58', Name: 'El Paso', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_HI_59', Name: 'Honolulu', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_TX_60', Name: 'McAllen', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NM_61',
    Name: 'Albuquerque',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_AL_62', Name: 'Birmingham', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_FL_63', Name: 'Sarasota', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_OH_64', Name: 'Dayton', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_NY_65', Name: 'Rochester', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_CA_66', Name: 'Fresno', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_PA_67',
    Name: 'Allentown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OK_68', Name: 'Tulsa', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_FL_69', Name: 'Cape Coral', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_CA_70', Name: 'Concord', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_CO_71',
    Name: 'Colorado Springs',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_SC_72',
    Name: 'Charleston',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MA_73',
    Name: 'Springfield',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_MI_74',
    Name: 'Grand Rapids',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_75',
    Name: 'Mission Viejo',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NY_76', Name: 'Albany', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_TN_77',
    Name: 'Knoxville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_CA_78',
    Name: 'Bakersfield',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_79', Name: 'Ogden', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_LA_80',
    Name: 'Baton Rouge',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OH_81', Name: 'Akron', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CT_82',
    Name: 'New Haven',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_SC_83',
    Name: 'Columbia',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_AZ_84', Name: 'Mesa', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_FL_85', Name: 'Palm Bay', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_UT_86', Name: 'Provo', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MA_87',
    Name: 'Worcester',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_CA_88',
    Name: 'Murrieta',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_89',
    Name: 'Greenville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_KS_90', Name: 'Wichita', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OH_91', Name: 'Toledo', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_92',
    Name: 'Staten Island',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IA_93', Name: 'Des Moines', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_94',
    Name: 'Long Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_95',
    Name: 'Port St. Lucie',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_96', Name: 'Denton', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_WI_97', Name: 'Madison', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_NV_98', Name: 'Reno', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_PA_99',
    Name: 'Harrisburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AR_100',
    Name: 'Little Rock',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CA_101',
    Name: 'Oakland',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_102',
    Name: 'Durham',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_103',
    Name: 'Winston-Salem',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_FL_104',
    Name: 'Bonita Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_CA_105', Name: 'Indio', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_FL_106',
    Name: 'Palm Coast',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_TN_107',
    Name: 'Chattanooga',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WA_108',
    Name: 'Spokane',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_NY_109', Name: 'Syracuse', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_PA_110',
    Name: 'Lancaster',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_111', Name: 'Arlington', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_112',
    Name: 'Stockton',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_113',
    Name: 'Poughkeepsie',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_GA_114', Name: 'Augusta', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_ID_115', Name: 'Boise', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_CA_116', Name: 'Oxnard', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_PA_117',
    Name: 'Scranton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_118',
    Name: 'Modesto',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_FL_119', Name: 'Kissimmee', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_CO_120', Name: 'Aurora', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_OH_121', Name: 'Youngstown', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AR_122',
    Name: 'Fayetteville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CA_123',
    Name: 'Anaheim',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_FL_124', Name: 'Pensacola', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_125',
    Name: 'Victorville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_126',
    Name: 'Lancaster',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_127',
    Name: 'Greensboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TX_128',
    Name: 'Corpus Christi',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IN_129',
    Name: 'Fort Wayne',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CA_130',
    Name: 'Santa Ana',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MI_131', Name: 'Flint', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_NC_132',
    Name: 'Fayetteville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MS_133',
    Name: 'Jackson',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CA_134',
    Name: 'Santa Rosa',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MI_135', Name: 'Lansing', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_PR_136',
    Name: 'San Juan',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_KY_137',
    Name: 'Lexington',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MI_138',
    Name: 'Ann Arbor',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_NV_139', Name: 'Henderson', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_AL_140',
    Name: 'Huntsville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_AL_141', Name: 'Mobile', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_CO_142',
    Name: 'Fort Collins',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NC_143',
    Name: 'Asheville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_144',
    Name: 'Santa Clarita',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_145',
    Name: 'St. Paul',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_146',
    Name: 'Antioch',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_FL_147', Name: 'Lakeland', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NJ_148',
    Name: 'Trenton',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_NE_149', Name: 'Lincoln', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_MO_150',
    Name: 'Springfield',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_AK_151', Name: 'Anchorage', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_TX_152', Name: 'Plano', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_CA_153', Name: 'Irvine', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_IA_154', Name: 'Davenport', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IL_155', Name: 'Rockford', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_NJ_156', Name: 'Newark', stateId: 'NJ', stateName: 'New Jersey'},
  {
    Id: 'CIT_US_IN_157',
    Name: 'South Bend',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_LA_158',
    Name: 'Shreveport',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_IL_159',
    Name: 'Round Lake Beach',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_GA_160', Name: 'Savannah', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_SC_161',
    Name: 'Myrtle Beach',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CA_162',
    Name: 'Chula Vista',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OR_163', Name: 'Eugene', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_OH_164', Name: 'Canton', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_165', Name: 'Lubbock', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_166',
    Name: 'Reading',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_167',
    Name: 'Winter Haven',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OR_168', Name: 'Salem', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_FL_169',
    Name: 'St. Petersburg',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_LA_170',
    Name: 'Lafayette',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_171', Name: 'Laredo', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_172',
    Name: 'Jersey City',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NC_173',
    Name: 'Concord',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_GA_174', Name: 'Columbus', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_AZ_175', Name: 'Chandler', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_TX_176', Name: 'McKinney', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AZ_177',
    Name: 'Scottsdale',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_TX_178', Name: 'Killeen', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_179',
    Name: 'Tallahassee',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IL_180', Name: 'Peoria', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NC_181',
    Name: 'Wilmington',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_AL_182',
    Name: 'Montgomery',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_AZ_183', Name: 'Gilbert', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_AZ_184', Name: 'Glendale', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_NV_185',
    Name: 'North Las Vegas',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_VA_186',
    Name: 'Chesapeake',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MA_187',
    Name: 'Barnstable',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_VA_188', Name: 'Norfolk', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_CA_189',
    Name: 'Fremont',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WA_190',
    Name: 'Kennewick',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_TX_191', Name: 'Garland', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_192', Name: 'Irving', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_193',
    Name: 'Visalia',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_194',
    Name: 'Atlantic City',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_NV_195', Name: 'Paradise', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_NH_196',
    Name: 'Nashua',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_VA_197',
    Name: 'Arlington',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_FL_198', Name: 'Hialeah', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_PA_199', Name: 'York', stateId: 'PA', stateName: 'Pennsylvania'},
  {
    Id: 'CIT_US_IN_200',
    Name: 'Evansville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_AZ_201', Name: 'Avondale', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_TX_202', Name: 'Brownsville', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_203',
    Name: 'Gulfport',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WI_204',
    Name: 'Appleton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WA_205',
    Name: 'Bremerton',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NC_206',
    Name: 'Hickory',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_WA_207', Name: 'Tacoma', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_CA_208',
    Name: 'San Bernardino',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_209',
    Name: 'College Station',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MI_210',
    Name: 'Kalamazoo',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_211',
    Name: 'Thousand Oaks',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_VA_212', Name: 'Roanoke', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_CA_213',
    Name: 'Fontana',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_214',
    Name: 'Moreno Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_215',
    Name: 'North Port',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_ND_216',
    Name: 'Fargo',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_WI_217',
    Name: 'Green Bay',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NV_218',
    Name: 'Spring Valley',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {Id: 'CIT_US_TX_219', Name: 'Amarillo', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_ME_220', Name: 'Portland', stateId: 'ME', stateName: 'Maine'},
  {
    Id: 'CIT_US_CA_221',
    Name: 'Santa Barbara',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_222',
    Name: 'Gainesville',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WA_223',
    Name: 'Olympia',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_TX_224', Name: 'Frisco', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_NY_225', Name: 'Yonkers', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_CT_226',
    Name: 'Norwich',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_CA_227',
    Name: 'Glendale',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_228',
    Name: 'Huntington Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_FL_229', Name: 'Deltona', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_IL_230', Name: 'Aurora', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_AZ_231', Name: 'Tempe', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_KS_232',
    Name: 'Overland Park',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_TX_233',
    Name: 'Grand Prairie',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NV_234',
    Name: 'Sunrise Manor',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {Id: 'CIT_US_TX_235', Name: 'Waco', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_236',
    Name: 'Salinas',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CT_237',
    Name: 'Waterbury',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_TN_238',
    Name: 'Clarksville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_IA_239', Name: 'Cedar Rapids', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SD_240',
    Name: 'Sioux Falls',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_WV_241',
    Name: 'Huntington',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_CA_242',
    Name: 'Ontario',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_243',
    Name: 'Hagerstown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_PA_244', Name: 'Erie', stateId: 'PA', stateName: 'Pennsylvania'},
  {
    Id: 'CIT_US_WA_245',
    Name: 'Vancouver',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_ID_246', Name: 'Nampa', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_SC_247',
    Name: 'Spartanburg',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NC_248',
    Name: 'Gastonia',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_FL_249',
    Name: 'Fort Lauderdale',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_250', Name: 'Lorain', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TN_251',
    Name: 'Murfreesboro',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NC_252',
    Name: 'High Point',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_VA_253',
    Name: 'Newport News',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_254',
    Name: 'Rancho Cucamonga',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_CA_255', Name: 'Hemet', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_CA_256',
    Name: 'Santa Cruz',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CT_257',
    Name: 'Danbury',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_AZ_258', Name: 'Peoria', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_CA_259',
    Name: 'Oceanside',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_260',
    Name: 'Elk Grove',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_261',
    Name: 'Pembroke Pines',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_262',
    Name: 'Vallejo',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_263',
    Name: 'Garden Grove',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NV_264', Name: 'Enterprise', stateId: 'NV', stateName: 'Nevada'},
  {Id: 'CIT_US_OR_265', Name: 'Medford', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NC_266',
    Name: 'Cary',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_267',
    Name: 'Marysville',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_CA_268', Name: 'Corona', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_FL_269', Name: 'Ocala', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_VA_270',
    Name: 'Fredericksburg',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_GA_271',
    Name: 'Gainesville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NH_272',
    Name: 'Manchester',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_PR_273',
    Name: 'Bayamón',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_IL_274',
    Name: 'Champaign',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_VA_275',
    Name: 'Alexandria',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_276',
    Name: 'Hayward',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_277',
    Name: 'Springfield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_CO_278', Name: 'Lakewood', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_IN_279', Name: 'Lafayette', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_MD_280',
    Name: 'Frederick',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_LA_281',
    Name: 'Lake Charles',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_282', Name: 'Odessa', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AL_283',
    Name: 'Tuscaloosa',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_GA_284',
    Name: 'Warner Robins',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CA_285',
    Name: 'Palmdale',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_FL_286', Name: 'Hollywood', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_TX_287', Name: 'Midland', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_FL_288', Name: 'Leesburg', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_TX_289', Name: 'Port Arthur', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MI_290', Name: 'Muskegon', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_GA_291', Name: 'Macon', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_KS_292',
    Name: 'Kansas City',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_CA_293',
    Name: 'Sunnyvale',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_CA_294', Name: 'Pomona', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_CA_295',
    Name: 'Escondido',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_296', Name: 'Pasadena', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MA_297',
    Name: 'New Bedford',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_CA_298',
    Name: 'Fairfield',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_299',
    Name: 'Naperville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_300',
    Name: 'Bellevue',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NY_301',
    Name: 'Binghamton',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IN_302', Name: 'Elkhart', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_KS_303', Name: 'Topeka', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_IL_304', Name: 'Joliet', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_TX_305', Name: 'Beaumont', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_306',
    Name: 'Paterson',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_CA_307', Name: 'Merced', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_CO_308', Name: 'Pueblo', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_TX_309', Name: 'Tyler', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_310',
    Name: 'Torrance',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AZ_311', Name: 'Yuma', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_LA_312',
    Name: 'Metairie',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_AZ_313', Name: 'Surprise', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_MO_314', Name: 'Columbia', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_GA_315', Name: 'Athens', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CA_316',
    Name: 'Roseville',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_CO_317', Name: 'Thornton', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_FL_318', Name: 'Miramar', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_319',
    Name: 'Pasadena',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_320', Name: 'Mesquite', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_KS_321', Name: 'Olathe', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_CA_322',
    Name: 'Santa Maria',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_LA_323', Name: 'Houma', stateId: 'LA', stateName: 'Louisiana'},
  {Id: 'CIT_US_TX_324', Name: 'Carrollton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CO_325',
    Name: 'Grand Junction',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_WV_326',
    Name: 'Charleston',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_CA_327', Name: 'Orange', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_CA_328',
    Name: 'Fullerton',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_CO_329', Name: 'Greeley', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_PR_330',
    Name: 'Carolina',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_NM_331',
    Name: 'Las Cruces',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_FL_332',
    Name: 'Panama City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_333', Name: 'Harlingen', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_UT_334',
    Name: 'West Valley City',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {Id: 'CIT_US_VA_335', Name: 'Hampton', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_MI_336', Name: 'Warren', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_SC_337',
    Name: 'Mauldin',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_IL_338',
    Name: 'Bloomington',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_339',
    Name: 'Coral Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_340', Name: 'Round Rock', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_WA_341', Name: 'Yakima', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_MI_342',
    Name: 'Sterling Heights',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_WA_343', Name: 'Kent', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_NC_344',
    Name: 'Burlington',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_345',
    Name: 'Bellingham',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_346',
    Name: 'Santa Clara',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_WI_347', Name: 'Racine', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_NC_348',
    Name: 'Greenville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CT_349',
    Name: 'Stamford',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_NJ_350',
    Name: 'Elizabeth',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_TN_351',
    Name: 'Johnson City',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_VA_352',
    Name: 'Lynchburg',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_353',
    Name: 'Simi Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AR_354',
    Name: 'Fort Smith',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_WI_355', Name: 'Kenosha', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_OK_356', Name: 'Norman', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_MI_357',
    Name: 'South Lyon',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_CO_358', Name: 'Boulder', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_TX_359', Name: 'Abilene', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_360',
    Name: 'Lehigh Acres',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_361', Name: 'Pearland', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_362',
    Name: 'Berkeley',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_363', Name: 'Richardson', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_364',
    Name: 'Redding',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_CO_365', Name: 'Arvada', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_CA_366',
    Name: 'East Los Angeles',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_367', Name: 'St. George', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_MT_368', Name: 'Billings', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_CA_369',
    Name: 'Yuba City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_370',
    Name: 'Rochester',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MA_371',
    Name: 'Leominster',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_TN_372',
    Name: 'Kingsport',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_MN_373', Name: 'Duluth', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_SC_374',
    Name: 'Rock Hill',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_CA_375', Name: 'Gilroy', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_MA_376',
    Name: 'Cambridge',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_TX_377', Name: 'Sugar Land', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_378', Name: 'Texas City', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_379', Name: 'Iowa City', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MI_380', Name: 'Saginaw', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_CA_381', Name: 'Chico', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_DE_382', Name: 'Dover', stateId: 'DE', stateName: 'Delaware'},
  {
    Id: 'CIT_US_FL_383',
    Name: 'Clearwater',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_ID_384',
    Name: "Coeur d'Alene",
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_CA_385',
    Name: 'Seaside',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_386',
    Name: 'Independence',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_UT_387', Name: 'West Jordan', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_FL_388', Name: 'Brandon', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_IN_389',
    Name: 'Bloomington',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CA_390',
    Name: 'El Monte',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_391',
    Name: 'North Charleston',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CA_392',
    Name: 'Carlsbad',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_393',
    Name: 'St. Cloud',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_394',
    Name: 'Temecula',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_CA_395', Name: 'Clovis', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_ID_396', Name: 'Meridian', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_TX_397',
    Name: 'The Woodlands',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_PR_398', Name: 'Ponce', stateId: 'PR', stateName: 'Puerto Rico'},
  {
    Id: 'CIT_US_CO_399',
    Name: 'Westminster',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CA_400',
    Name: 'Costa Mesa',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_LA_401', Name: 'Monroe', stateId: 'LA', stateName: 'Louisiana'},
  {Id: 'CIT_US_NY_402', Name: 'Utica', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_FL_403',
    Name: 'Pompano Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_404',
    Name: 'West Palm Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IA_405', Name: 'Waterloo', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WA_406',
    Name: 'Everett',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_407',
    Name: 'El Centro',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NM_408',
    Name: 'Santa Fe',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_CA_409', Name: 'Downey', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_FL_410',
    Name: 'Spring Hill',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MA_411',
    Name: 'Lowell',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_CO_412',
    Name: 'Centennial',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_IL_413', Name: 'Elgin', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CA_414',
    Name: 'Richmond',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OK_415',
    Name: 'Broken Arrow',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_FL_416',
    Name: 'Miami Gardens',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OR_417', Name: 'Bend', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_VT_418',
    Name: 'Burlington',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_CA_419',
    Name: 'Jurupa Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_GA_420',
    Name: 'Sandy Springs',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_OR_421', Name: 'Gresham', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_TX_422', Name: 'Lewisville', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OR_423', Name: 'Hillsboro', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_CA_424',
    Name: 'San Buenaventura',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_425',
    Name: 'Jacksonville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_426',
    Name: 'Pottstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_427',
    Name: 'Inglewood',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_428', Name: 'League City', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_429',
    Name: 'Eau Claire',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_430',
    Name: 'Turlock',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_431', Name: 'Temple', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_432', Name: 'Sioux City', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MD_433',
    Name: 'Salisbury',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_FL_434', Name: 'Davie', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_435',
    Name: 'Daly City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CO_436',
    Name: 'Highlands Ranch',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_TX_437', Name: 'Allen', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_438',
    Name: 'West Covina',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NV_439', Name: 'Sparks', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_TX_440',
    Name: 'Wichita Falls',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CA_441',
    Name: 'San Mateo',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MD_442', Name: 'Columbia', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_CA_443',
    Name: 'Norwalk',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_CA_444', Name: 'Rialto', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_CA_445',
    Name: 'Manteca',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_446',
    Name: 'Arden-Arcade',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_447',
    Name: 'El Cajon',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_448',
    Name: 'Burbank',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_CO_449', Name: 'Longmont', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_WA_450', Name: 'Renton', stateId: 'WA', stateName: 'Washington'},
  {Id: 'CIT_US_CA_451', Name: 'Vista', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_UT_452', Name: 'Logan', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_AZ_453',
    Name: 'Prescott Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_CA_454',
    Name: 'Vacaville',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_455', Name: 'Edinburg', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IN_456', Name: 'Carmel', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_WA_457',
    Name: 'Spokane Valley',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_TX_458', Name: 'San Angelo', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_459',
    Name: 'La Crosse',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_ID_460', Name: 'Idaho Falls', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_MI_461', Name: 'Holland', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_VA_462',
    Name: 'Charlottesville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_463', Name: 'Longview', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_CA_464', Name: 'Tracy', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_FL_465',
    Name: 'Boca Raton',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CO_466',
    Name: 'Lafayette',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MO_467',
    Name: "Lee's Summit",
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NM_468',
    Name: 'Rio Rancho',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_GA_469',
    Name: 'South Fulton',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_OR_470', Name: 'Beaverton', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_KS_471', Name: 'Lawrence', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_ND_472',
    Name: 'Bismarck',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_UT_473', Name: 'Orem', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_OH_474', Name: 'Middletown', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AZ_475',
    Name: 'San Tan Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_CA_476',
    Name: 'San Marcos',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_477', Name: 'Sandy', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_WA_478',
    Name: 'Federal Way',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_LA_479',
    Name: 'Mandeville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_480',
    Name: 'Hesperia',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MA_481',
    Name: 'Brockton',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_CA_482',
    Name: 'Compton',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_FL_483', Name: 'Riverview', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_IN_484', Name: 'Fishers', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_FL_485', Name: 'Sunrise', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_KY_486',
    Name: 'Bowling Green',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_GA_487', Name: 'Roswell', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CA_488',
    Name: 'Menifee',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_489',
    Name: 'Plantation',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NH_490',
    Name: 'Dover',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_MA_491',
    Name: 'Quincy',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_VA_492',
    Name: 'Portsmouth',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_CA_493', Name: 'Chino', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_MA_494',
    Name: 'Lynn',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_OK_495', Name: 'Edmond', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CA_496',
    Name: 'Hanford',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MI_497', Name: 'Dearborn', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_MI_498', Name: 'Livonia', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_CA_499',
    Name: 'South Gate',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_500',
    Name: 'Vineland',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_SC_501',
    Name: 'Florence',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NH_502',
    Name: 'Portsmouth',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {Id: 'CIT_US_LA_503', Name: 'Slidell', stateId: 'LA', stateName: 'Louisiana'},
  {Id: 'CIT_US_OK_504', Name: 'Lawton', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_WA_505',
    Name: 'Kirkland',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_MT_506', Name: 'Missoula', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_AL_507', Name: 'Auburn', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_SD_508',
    Name: 'Rapid City',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_IN_509',
    Name: 'Terre Haute',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_VA_510', Name: 'Suffolk', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_VA_511',
    Name: 'Blacksburg',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_SC_512',
    Name: 'Mount Pleasant',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_CA_513', Name: 'Carson', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_TX_514', Name: 'Conroe', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_FL_515', Name: 'Alafaya', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_516',
    Name: 'Livermore',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_517',
    Name: 'Westminster',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_518',
    Name: 'Germantown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_519',
    Name: 'Santa Monica',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_520',
    Name: 'New Braunfels',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MA_521',
    Name: 'Fall River',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_GA_522', Name: 'Albany', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_FL_523',
    Name: 'Miami Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CT_524',
    Name: 'Norwalk',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_CA_525',
    Name: 'San Leandro',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MO_526', Name: "O'Fallon", stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MA_527',
    Name: 'Newton',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_IN_528', Name: 'Muncie', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_CA_529',
    Name: 'Citrus Heights',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_530',
    Name: 'State College',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_531',
    Name: "Town 'n' Country",
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IL_532', Name: 'Decatur', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_MI_533', Name: 'Jackson', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_FL_534',
    Name: 'Fort Myers',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_AZ_535', Name: 'Goodyear', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_GA_536', Name: 'Dalton', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_TX_537', Name: 'Bryan', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_538', Name: 'Waukegan', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CA_539',
    Name: 'Hawthorne',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IN_540', Name: 'Anderson', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_CA_541',
    Name: 'Redwood City',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AL_542', Name: 'Hoover', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_CA_543',
    Name: 'Lake Forest',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_CA_544', Name: 'Napa', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_CA_545',
    Name: 'Whittier',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_546',
    Name: 'Clifton',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_FL_547', Name: 'Largo', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MN_548',
    Name: 'Bloomington',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_GA_549',
    Name: 'Johns Creek',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CA_550',
    Name: 'Newport Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_551', Name: 'Mission', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_552',
    Name: 'Milpitas',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MI_553', Name: 'Troy', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_CA_554', Name: 'Madera', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_MO_555', Name: 'Joplin', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_CA_556',
    Name: 'Chino Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_557',
    Name: 'Alhambra',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TN_558',
    Name: 'Franklin',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_FL_559', Name: 'Melbourne', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MI_560',
    Name: 'Port Huron',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OH_561', Name: 'Springfield', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_562',
    Name: 'Mountain View',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_563',
    Name: 'Zephyrhills',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_564',
    Name: 'St. Augustine',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_AZ_565', Name: 'Flagstaff', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_CA_566',
    Name: 'Buena Park',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_567',
    Name: 'Pleasanton',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_568',
    Name: 'Silver Spring',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_569', Name: 'Atascocita', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MI_570', Name: 'Westland', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_WA_571', Name: 'Auburn', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_RI_572',
    Name: 'Cranston',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_MA_573',
    Name: 'Somerville',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_CA_574', Name: 'Folsom', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_AR_575',
    Name: 'Springdale',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_FL_576',
    Name: 'Deerfield Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_RI_577',
    Name: 'Warwick',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {Id: 'CIT_US_IL_578', Name: 'Cicero', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MI_579',
    Name: 'Farmington Hills',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OH_580', Name: 'Newark', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_VA_581',
    Name: 'Williamsburg',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MN_582',
    Name: 'Brooklyn Park',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MS_583',
    Name: 'Hattiesburg',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_LA_584',
    Name: 'Alexandria',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MA_585',
    Name: 'Lawrence',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_AL_586', Name: 'Florence', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_GA_587', Name: 'Valdosta', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MN_588',
    Name: 'Plymouth',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AZ_589', Name: 'Buckeye', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_TX_590', Name: 'Georgetown', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_WY_591', Name: 'Cheyenne', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_TX_592', Name: 'Cedar Park', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_593',
    Name: 'The Villages',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IN_594', Name: 'Kokomo', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_CA_595', Name: 'Tustin', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_CA_596',
    Name: 'Lakewood',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_CA_597', Name: 'Perris', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_MO_598',
    Name: 'St. Joseph',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TX_599',
    Name: 'Flower Mound',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_600', Name: 'Pharr', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_601',
    Name: 'Pine Hills',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IL_602', Name: 'Alton', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_CO_603', Name: 'Loveland', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_FL_604',
    Name: 'Boynton Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NY_605',
    Name: 'New Rochelle',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_SC_606',
    Name: 'Anderson',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_AR_607',
    Name: 'Jonesboro',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_608',
    Name: 'Lebanon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_609', Name: 'Parma', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_UT_610', Name: 'Layton', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_KY_611',
    Name: 'Elizabethtown',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_TX_612', Name: 'Texarkana', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_613',
    Name: 'Alameda',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_614', Name: 'Kankakee', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CA_615',
    Name: 'Watsonville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_616',
    Name: 'Porterville',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_617', Name: 'Baytown', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_CA_618', Name: 'Upland', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_CA_619', Name: 'Davis', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_CA_620',
    Name: 'Camarillo',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_621', Name: 'South Jordan', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MI_622',
    Name: 'Battle Creek',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_623',
    Name: 'Bellflower',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_624', Name: 'San Marcos', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_625',
    Name: 'San Ramon',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_626',
    Name: 'Lake Jackson',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_627',
    Name: 'Bethlehem',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MI_628', Name: 'Wyoming', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_WI_629', Name: 'Oshkosh', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_IN_630', Name: 'Hammond', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_MD_631', Name: 'Waldorf', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_TX_632',
    Name: 'Missouri City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_WA_633', Name: 'Pasco', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_WV_634',
    Name: 'Wheeling',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_FL_635', Name: 'Kendall', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_636',
    Name: 'Baldwin Park',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_637',
    Name: 'Rancho Cordova',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AR_638', Name: 'Conway', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_IN_639', Name: 'Gary', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PA_640',
    Name: 'Altoona',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_CA_641', Name: 'Lodi', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_IL_642',
    Name: 'Arlington Heights',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_643',
    Name: 'Bolingbrook',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MI_644',
    Name: 'Rochester Hills',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_VA_645',
    Name: 'Winchester',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MA_646',
    Name: 'Framingham',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_SC_647',
    Name: 'Hilton Head Island',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_VA_648',
    Name: 'Centreville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_649',
    Name: 'Union City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_650',
    Name: 'Owensboro',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_TN_651', Name: 'Jackson', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_TN_652',
    Name: 'Cleveland',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_WI_653', Name: 'Wausau', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_NJ_654', Name: 'Camden', stateId: 'NJ', stateName: 'New Jersey'},
  {Id: 'CIT_US_AL_655', Name: 'Anniston', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_IL_656', Name: 'Evanston', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CA_657',
    Name: 'Apple Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_658', Name: 'Mansfield', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PR_659',
    Name: 'Caguas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_IL_660',
    Name: 'Schaumburg',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_ID_661', Name: 'Pocatello', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_MN_662',
    Name: 'Woodbury',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MI_663',
    Name: 'Southfield',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MD_664',
    Name: 'Ellicott City',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_VA_665',
    Name: 'Dale City',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MN_666',
    Name: 'Maple Grove',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_667',
    Name: 'Pittsburg',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CT_668',
    Name: 'New Britain',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_WV_669',
    Name: 'Morgantown',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_TX_670', Name: 'Mansfield', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_LA_671', Name: 'Hammond', stateId: 'LA', stateName: 'Louisiana'},
  {Id: 'CIT_US_AL_672', Name: 'Dothan', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_VA_673',
    Name: 'Harrisonburg',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WA_674',
    Name: 'Wenatchee',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_675',
    Name: 'Waukesha',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_RI_676',
    Name: 'Pawtucket',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_WA_677',
    Name: 'Redmond',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_FL_678',
    Name: 'Lauderhill',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_SC_679',
    Name: 'Sumter',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CA_680',
    Name: 'Redlands',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AL_681', Name: 'Daphne', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_WA_682',
    Name: 'Mount Vernon',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_FL_683', Name: 'Weston', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MO_684',
    Name: 'St. Charles',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WI_685',
    Name: 'Janesville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_686', Name: 'Sherman', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TX_687',
    Name: 'North Richland Hills',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CO_688',
    Name: 'Broomfield',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CA_689',
    Name: 'El Paso de Robles',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TN_690', Name: 'Bristol', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_DE_691',
    Name: 'Wilmington',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_AZ_692',
    Name: 'Casas Adobes',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_CA_693',
    Name: 'Walnut Creek',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_FL_694', Name: 'Poinciana', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_695',
    Name: 'Lynwood',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_696',
    Name: 'Glen Burnie',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_UT_697', Name: 'Lehi', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NJ_698',
    Name: 'Passaic',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_FL_699', Name: 'Homestead', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_WI_700',
    Name: 'West Bend',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_FL_701',
    Name: 'Delray Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WI_702',
    Name: 'Sheboygan',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_703',
    Name: 'Lake Elsinore',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_704',
    Name: 'Daytona Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NM_705',
    Name: 'Los Lunas',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_OH_706', Name: 'Lima', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AL_707', Name: 'Decatur', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_CA_708',
    Name: 'Rocklin',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AR_709', Name: 'Rogers', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_CO_710',
    Name: 'Castle Rock',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PA_711',
    Name: 'Hanover',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_712',
    Name: 'Bossier City',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_IA_713', Name: 'Dubuque', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MD_714',
    Name: 'Rockville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_715', Name: 'Victoria', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_716',
    Name: 'Saratoga Springs',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PR_717',
    Name: 'Guaynabo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MD_718',
    Name: 'Gaithersburg',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NJ_719',
    Name: 'Union City',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IA_720', Name: 'Ames', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IA_721',
    Name: 'West Des Moines',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_CA_722',
    Name: 'South San Francisco',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_723',
    Name: 'Yorba Linda',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_WY_724', Name: 'Casper', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_IL_725', Name: 'Palatine', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OR_726', Name: 'Corvallis', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_IA_727', Name: 'Ankeny', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NY_728',
    Name: 'Mount Vernon',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_729', Name: 'Rowlett', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MN_730',
    Name: 'Lakeville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_GA_731',
    Name: 'Alpharetta',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_WA_732',
    Name: 'Longview',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IL_733', Name: 'DeKalb', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CA_734',
    Name: 'Petaluma',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_735',
    Name: 'Redondo Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_FL_736', Name: 'Tamarac', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_737',
    Name: 'Laguna Niguel',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MN_738', Name: 'Eagan', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_LA_739', Name: 'Kenner', stateId: 'LA', stateName: 'Louisiana'},
  {Id: 'CIT_US_MI_740', Name: 'Bay City', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_AR_741',
    Name: 'North Little Rock',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_WA_742',
    Name: 'Sammamish',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_KS_743', Name: 'Shawnee', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_FL_744', Name: 'Jupiter', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_FL_745', Name: 'Doral', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_746',
    Name: 'Florence-Graham',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_747',
    Name: 'Carbondale',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MN_748', Name: 'Blaine', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_WV_749',
    Name: 'Weirton',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_CA_750', Name: 'Tulare', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_FL_751',
    Name: 'Wellington',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_TX_752',
    Name: 'Pflugerville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CA_753',
    Name: 'Palo Alto',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_754',
    Name: 'Schenectady',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MT_755',
    Name: 'Great Falls',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_IN_756',
    Name: 'Michigan City',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NJ_757',
    Name: 'Bayonne',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MN_758',
    Name: 'Eden Prairie',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_FL_759',
    Name: 'Port Orange',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_CA_760', Name: 'Dublin', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_ND_761',
    Name: 'Grand Forks',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_IN_762',
    Name: 'Noblesville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CA_763',
    Name: 'San Clemente',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_764',
    Name: 'Brentwood',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_765',
    Name: 'Carmichael',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_766',
    Name: 'East Orange',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_767',
    Name: 'Eastvale',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_768',
    Name: 'Chapel Hill',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_769',
    Name: 'Rocky Mount',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MA_770',
    Name: 'Haverhill',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_WI_771', Name: 'Beloit', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_NY_772',
    Name: 'Glens Falls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_773',
    Name: 'Brentwood',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AK_774', Name: 'Fairbanks', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_OR_775',
    Name: 'Springfield',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_MD_776', Name: 'Bethesda', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_PA_777',
    Name: 'Johnstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MD_778', Name: 'Dundalk', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_CA_779',
    Name: 'Castro Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_780',
    Name: 'Coon Rapids',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_NY_781', Name: 'Elmira', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_MN_782', Name: 'Mankato', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_OR_783', Name: 'Albany', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_FL_784',
    Name: 'North Miami',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_785',
    Name: 'Encinitas',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_786', Name: 'Skokie', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_TX_787', Name: 'Leander', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WV_788',
    Name: 'Parkersburg',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MA_789',
    Name: 'Waltham',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_FL_790',
    Name: 'Port Charlotte',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_791',
    Name: 'Palm Harbor',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_792',
    Name: 'San Luis Obispo',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_FL_793', Name: 'Sebring', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_IA_794',
    Name: 'Council Bluffs',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_OH_795', Name: 'Hamilton', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OK_796', Name: 'Moore', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CA_797',
    Name: 'Pico Rivera',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AZ_798',
    Name: 'Casa Grande',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_PA_799',
    Name: 'Monessen',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_800',
    Name: 'Montebello',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_GA_801', Name: 'Rome', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_FL_802',
    Name: 'The Hammocks',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_UT_803', Name: 'Millcreek', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_FL_804', Name: 'Sanford', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_805',
    Name: 'National City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PR_806',
    Name: 'Mayagüez',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MN_807',
    Name: 'Burnsville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_FL_808',
    Name: 'Coconut Creek',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_TN_809',
    Name: 'Morristown',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_VA_810', Name: 'Reston', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_WA_811',
    Name: 'Lakewood',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_TX_812', Name: 'Spring', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AL_813', Name: 'Gadsden', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_MI_814', Name: 'Taylor', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_MI_815', Name: 'Novi', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_GA_816', Name: 'Marietta', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_AR_817',
    Name: 'Hot Springs',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CA_818',
    Name: 'Woodland',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_819',
    Name: 'Fountainebleau',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_820',
    Name: 'La Habra',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MA_821',
    Name: 'Malden',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_CA_822',
    Name: 'South Whittier',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CO_823',
    Name: 'Commerce City',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_WA_824',
    Name: 'South Hill',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CT_825',
    Name: 'Bristol',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_IN_826', Name: 'Columbus', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_WI_827',
    Name: 'West Allis',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_UT_828', Name: 'Taylorsville', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_ME_829', Name: 'Bangor', stateId: 'ME', stateName: 'Maine'},
  {
    Id: 'CIT_US_CA_830',
    Name: 'Monterey Park',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IN_831', Name: 'Greenwood', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_TN_832',
    Name: 'Bartlett',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_FL_833', Name: 'Bradenton', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_MI_834', Name: 'Pontiac', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_VA_835', Name: 'Staunton', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_CT_836',
    Name: 'Meriden',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_CA_837',
    Name: 'Gardena',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_838',
    Name: 'Apex',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MI_839',
    Name: 'Royal Oak',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_840',
    Name: 'Cupertino',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_841',
    Name: 'La Mesa',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MI_842',
    Name: 'Benton Harbor',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MI_843',
    Name: 'St. Clair Shores',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_844',
    Name: 'Des Plaines',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_ME_845', Name: 'Lewiston', stateId: 'ME', stateName: 'Maine'},
  {Id: 'CIT_US_FL_846', Name: 'Margate', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_MI_847', Name: 'Midland', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_NV_848',
    Name: 'Carson City',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {Id: 'CIT_US_MD_849', Name: 'Bowie', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_NY_850',
    Name: 'Middletown',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_ID_851', Name: 'Caldwell', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_CA_852',
    Name: 'San Rafael',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WA_853',
    Name: 'Richland',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MO_854',
    Name: 'St. Peters',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_FL_855',
    Name: 'Wesley Chapel',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_TN_856',
    Name: 'Hendersonville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NY_857',
    Name: 'White Plains',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NC_858',
    Name: 'Huntersville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_CA_859', Name: 'Santee', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_MO_860',
    Name: 'Jefferson City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WV_861',
    Name: 'Beckley',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_CA_862',
    Name: 'Arcadia',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_863',
    Name: 'Titusville',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_GA_864',
    Name: 'Cartersville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_WA_865',
    Name: 'Walla Walla',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_IL_866',
    Name: 'Orland Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MD_867', Name: 'Towson', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_MA_868',
    Name: 'Weymouth',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_CO_869', Name: 'Parker', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_FL_870',
    Name: 'Palm Beach Gardens',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_MT_871', Name: 'Bozeman', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_PA_872',
    Name: 'East Stroudsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_873',
    Name: 'Huntington Park',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MA_874',
    Name: 'Taunton',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_OK_875',
    Name: 'Midwest City',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MA_876',
    Name: 'Medford',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_NC_877',
    Name: 'Goldsboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_878', Name: 'Euless', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_KS_879', Name: 'Manhattan', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WA_880',
    Name: 'Shoreline',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_AZ_881',
    Name: 'Lake Havasu City',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_GA_882', Name: 'Smyrna', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MO_883',
    Name: 'Cape Girardeau',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_FL_884', Name: 'Tamiami', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_FL_885',
    Name: 'Kendale Lakes',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_OR_886',
    Name: 'Grants Pass',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MO_887',
    Name: 'Blue Springs',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MS_888',
    Name: 'Southaven',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IL_889',
    Name: 'Tinley Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_890',
    Name: 'Diamond Bar',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MA_891',
    Name: 'Pittsfield',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_TX_892', Name: 'Eagle Pass', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_893',
    Name: 'New Brunswick',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_KS_894', Name: 'Lenexa', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_895',
    Name: 'Hazleton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_ID_896', Name: 'Twin Falls', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_GA_897',
    Name: 'Brookhaven',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_CA_898', Name: 'Novato', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_OR_899', Name: 'Tigard', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_CA_900',
    Name: 'Highland',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_901',
    Name: 'Fountain Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MI_902',
    Name: 'Dearborn Heights',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_TX_903', Name: 'Grapevine', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_904',
    Name: 'Fond du Lac',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_905',
    Name: 'Hacienda Heights',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NY_906', Name: 'Ithaca', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_MN_907',
    Name: 'Apple Valley',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MA_908',
    Name: 'Chicopee',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_IL_909', Name: 'Oak Lawn', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CA_910',
    Name: 'Cathedral City',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_CA_911', Name: 'Delano', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_AR_912',
    Name: 'Bentonville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_GA_913',
    Name: 'Stonecrest',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_OH_914', Name: 'Kettering', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_CA_915', Name: 'Colton', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_NY_916', Name: 'Kingston', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_CT_917',
    Name: 'West Haven',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_FL_918', Name: 'St. Cloud', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_IL_919', Name: 'Normal', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_IL_920', Name: 'Berwyn', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CT_921',
    Name: 'Milford',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_OR_922', Name: 'Aloha', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_CA_923',
    Name: 'Arroyo Grande',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_924',
    Name: 'Minnetonka',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_925',
    Name: 'Rosemead',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_926',
    Name: 'Paramount',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_927',
    Name: 'Yucaipa',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_GA_928', Name: 'Brunswick', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_PA_929',
    Name: 'Williamsport',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_930', Name: 'Elyria', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_VA_931', Name: 'Leesburg', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_IL_932',
    Name: 'Mount Prospect',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_933',
    Name: 'Pinellas Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_NE_934', Name: 'Bellevue', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_CA_935',
    Name: 'West Sacramento',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_FL_936', Name: 'Apopka', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_ID_937', Name: 'Lewiston', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_NE_938',
    Name: 'Grand Island',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_CA_939',
    Name: 'Palm Desert',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CT_940',
    Name: 'Milford city',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_NY_941',
    Name: 'Watertown',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_942', Name: 'Little Elm', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_943',
    Name: 'Chambersburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MA_944',
    Name: 'Revere',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_MA_945',
    Name: 'Peabody',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_TX_946', Name: 'Wylie', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SC_947',
    Name: 'Beaufort',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MD_948',
    Name: 'Aspen Hill',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_949', Name: 'DeSoto', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MT_950', Name: 'Helena', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_MN_951', Name: 'Edina', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_IL_952', Name: 'Wheaton', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NJ_953',
    Name: 'West New York',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_954',
    Name: 'Hoboken',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_WA_955', Name: 'Lacey', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_SC_956',
    Name: 'Summerville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_IL_957', Name: 'Oak Park', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_AZ_958', Name: 'Maricopa', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_CA_959', Name: 'Lompoc', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_MI_960', Name: 'Kentwood', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_PA_961',
    Name: 'Levittown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AZ_962',
    Name: 'Sierra Vista',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NY_963',
    Name: 'Levittown',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AL_964', Name: 'Madison', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_TN_965', Name: 'Smyrna', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_CA_966',
    Name: 'Glendora',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_WA_967', Name: 'Burien', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_NJ_968',
    Name: 'Perth Amboy',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_UT_969', Name: 'Herriman', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NM_970',
    Name: 'Farmington',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NC_971',
    Name: 'New Bern',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_972',
    Name: 'Placentia',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_GA_973',
    Name: 'Hinesville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CA_974',
    Name: 'Beaumont',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TN_975',
    Name: 'Collierville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_IN_976',
    Name: 'West Lafayette',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MO_977',
    Name: 'Florissant',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_978',
    Name: 'Hoffman Estates',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AZ_979',
    Name: 'Queen Creek',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_CA_980',
    Name: 'Aliso Viejo',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_981',
    Name: 'Kannapolis',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MA_982',
    Name: 'Methuen Town',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_AZ_983', Name: 'Sahuarita', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_MI_984',
    Name: 'Traverse City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_MD_985', Name: 'Severn', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_AZ_986',
    Name: 'Catalina Foothills',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_TX_987', Name: 'Galveston', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_988',
    Name: 'Country Club',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_TN_989',
    Name: 'Cookeville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_IN_990', Name: 'Mishawaka', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_CA_991',
    Name: 'Rowland Heights',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_992',
    Name: 'Plainfield',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_AZ_993',
    Name: 'Bullhead City',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_OK_994',
    Name: 'Stillwater',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_TX_995', Name: 'Forney', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MD_996', Name: 'Wheaton', stateId: 'MD', stateName: 'Maryland'},
  {Id: 'CIT_US_CA_997', Name: 'Azusa', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_MD_998',
    Name: 'North Bethesda',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_999',
    Name: 'Cerritos',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_VA_1000', Name: 'Ashburn', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_PA_1001',
    Name: 'Bloomsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_1002',
    Name: 'Morehead City',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_FL_1003',
    Name: 'Coral Gables',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OK_1004', Name: 'Enid', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_OH_1005', Name: 'Lakewood', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_1006',
    Name: 'Oroville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_1007',
    Name: 'Wilson',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IN_1008', Name: 'Lawrence', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_MI_1009', Name: 'Portage', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_ND_1010',
    Name: 'Minot',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_GA_1011', Name: 'Dunwoody', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_CA_1012', Name: 'Poway', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_CA_1013',
    Name: 'San Jacinto',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NY_1014', Name: 'Troy', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_CA_1015',
    Name: 'Newark',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OH_1016',
    Name: 'Cuyahoga Falls',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IL_1017',
    Name: 'Downers Grove',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_1018', Name: 'Bedford', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_1019',
    Name: 'Reedley',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_1020', Name: 'Dublin', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AZ_1021', Name: 'Marana', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_CA_1022',
    Name: 'Cypress',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_1023',
    Name: 'Hollister',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_1024', Name: 'Murray', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NM_1025',
    Name: 'Roswell',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MS_1026',
    Name: 'Pascagoula',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_VA_1027',
    Name: 'Tuckahoe',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_CA_1028', Name: 'Ceres', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_MN_1029',
    Name: 'St. Louis Park',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_1030',
    Name: 'Rio Grande City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_UT_1031', Name: 'Draper', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_CA_1032',
    Name: 'Palm Springs',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_KY_1033', Name: 'Paducah', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_OH_1034', Name: 'Findlay', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_FL_1035',
    Name: 'University',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MD_1036',
    Name: 'Bel Air South',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_1037',
    Name: 'Antelope',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_1038', Name: 'Kyle', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MI_1039', Name: 'Monroe', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_CA_1040',
    Name: 'Lincoln',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_FL_1041', Name: 'Ocoee', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_TX_1042', Name: 'Burleson', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_1043',
    Name: 'La Mirada',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_1044',
    Name: 'Farmers Branch',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MI_1045',
    Name: 'East Lansing',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_1046',
    Name: 'Uniontown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_1047',
    Name: 'Jeffersonville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_GA_1048', Name: 'Winder', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WI_1049',
    Name: 'Wauwatosa',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CO_1050',
    Name: 'Littleton',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MD_1051',
    Name: 'Cumberland',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_1052', Name: 'Cedar Hill', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_GA_1053',
    Name: 'Carrollton',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CA_1054',
    Name: 'Rancho Santa Margarita',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_1055', Name: 'Beavercreek', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_1056',
    Name: 'Niagara Falls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_VA_1057', Name: 'McLean', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_CA_1058',
    Name: 'North Highlands',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_RI_1059',
    Name: 'East Providence',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_CA_1060',
    Name: 'Florin',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_HI_1061',
    Name: 'East Honolulu',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_MO_1062',
    Name: 'Chesterfield',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_1063',
    Name: 'Covina',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WA_1064',
    Name: 'Bothell',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_IL_1065',
    Name: 'Glenview',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_1066', Name: 'Mentor', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_1067', Name: 'Keller', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_1068',
    Name: 'Danville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TN_1069',
    Name: 'Spring Hill',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MI_1070',
    Name: 'Roseville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_1071',
    Name: 'Elmhurst',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OH_1072',
    Name: 'New Philadelphia',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OH_1073', Name: 'Sandusky', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_1074',
    Name: 'El Dorado Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_KS_1075', Name: 'Salina', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OH_1076', Name: 'Euclid', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AZ_1077', Name: 'Kingman', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_MA_1078',
    Name: 'Everett',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_MO_1079',
    Name: 'Farmington',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TX_1080',
    Name: 'Weatherford',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CT_1081',
    Name: 'Middletown',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_OR_1082', Name: 'Roseburg', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MS_1083',
    Name: 'Biloxi',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_KS_1084',
    Name: 'Leavenworth',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_FL_1085',
    Name: 'Fort Pierce',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_1086',
    Name: 'Winter Garden',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AZ_1087',
    Name: 'Oro Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_CA_1088',
    Name: 'Morgan Hill',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MD_1089', Name: 'Potomac', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_NC_1090',
    Name: 'Pinehurst',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_VA_1091',
    Name: 'Danville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_1092', Name: 'Rockwall', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_1093',
    Name: 'Coachella',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_1094',
    Name: 'Wake Forest',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_HI_1095',
    Name: 'Pearl City',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_MS_1096',
    Name: 'Tupelo',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MA_1097',
    Name: 'Attleboro',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_AR_1098',
    Name: 'Pine Bluff',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_FL_1099',
    Name: 'Oakland Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_1100', Name: 'Marion', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_HI_1101', Name: 'Hilo', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_TX_1102', Name: 'Lufkin', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_1103',
    Name: 'Altadena',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_1104',
    Name: 'Manitowoc',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_VA_1105',
    Name: 'Lake Ridge',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_CA_1106', Name: 'Selma', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_OH_1107',
    Name: 'Strongsville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CA_1108',
    Name: 'Danville',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_1109', Name: 'Quincy', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_1110', Name: 'Lancaster', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_UT_1111', Name: 'Riverton', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_TX_1112', Name: 'The Colony', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_1113', Name: 'Urbandale', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_1114',
    Name: 'Plainfield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IL_1115', Name: 'Lombard', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_AZ_1116', Name: 'Prescott', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_FL_1117',
    Name: 'North Lauderdale',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_1118',
    Name: 'Eureka',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_1119',
    Name: 'Hackensack',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_1120',
    Name: 'Sayreville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_FL_1121',
    Name: 'Altamonte Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_1122', Name: 'Del Rio', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OH_1123',
    Name: 'Cleveland Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_UT_1124', Name: 'Bountiful', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_CA_1125',
    Name: 'Desert Hot Springs',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_GA_1126',
    Name: 'Peachtree Corners',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_TX_1127',
    Name: 'Haltom City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_FL_1128',
    Name: 'Ormond Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_1129',
    Name: 'Cutler Bay',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PR_1130',
    Name: 'Trujillo Alto',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_MI_1131', Name: 'Adrian', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_SC_1132',
    Name: 'Goose Creek',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MN_1133',
    Name: 'Moorhead',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IN_1134',
    Name: 'Westfield',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NH_1135',
    Name: 'Concord',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_VA_1136',
    Name: 'Annandale',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_1137',
    Name: 'Rohnert Park',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_CA_1138', Name: 'Brea', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_OR_1139',
    Name: 'Klamath Falls',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MA_1140',
    Name: 'Salem',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_TX_1141',
    Name: 'Channelview',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_FL_1142',
    Name: 'North Miami Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_NV_1143', Name: 'Whitney', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_NY_1144',
    Name: 'Freeport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WA_1145',
    Name: 'Centralia',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_TN_1146',
    Name: 'Gallatin',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WI_1147',
    Name: 'Stevens Point',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_1148',
    Name: 'San Bruno',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_VA_1149', Name: 'Burke', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_TN_1150',
    Name: 'Brentwood',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_FL_1151',
    Name: 'North Fort Myers',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NY_1152',
    Name: 'Hicksville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_1153',
    Name: 'West Babylon',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WA_1154',
    Name: 'Edmonds',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_AZ_1155',
    Name: 'Apache Junction',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_OH_1156', Name: 'Fairfield', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_1157',
    Name: 'Oakley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KS_1158',
    Name: 'Hutchinson',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_SC_1159',
    Name: 'Greenwood',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_IN_1160', Name: 'Richmond', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NJ_1161',
    Name: 'Linden',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WA_1162',
    Name: 'Puyallup',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_TX_1163', Name: 'Huntsville', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_1164', Name: 'Urbana', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MA_1165',
    Name: 'Beverly',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_NM_1166',
    Name: 'Clovis',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_GA_1167',
    Name: 'Statesboro',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_NM_1168', Name: 'Hobbs', stateId: 'NM', stateName: 'New Mexico'},
  {
    Id: 'CIT_US_OR_1169',
    Name: 'McMinnville',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_TX_1170', Name: 'Schertz', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_1171',
    Name: 'Bell Gardens',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_FL_1172', Name: 'Oviedo', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_MD_1173', Name: 'Odenton', stateId: 'MD', stateName: 'Maryland'},
  {Id: 'CIT_US_OH_1174', Name: 'Grove City', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_1175',
    Name: 'Campbell',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_1176',
    Name: 'Wentzville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_RI_1177',
    Name: 'Woonsocket',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_CA_1178',
    Name: 'La Quinta',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_1179', Name: 'Weslaco', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_1180', Name: 'Zanesville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_GA_1181', Name: 'Newnan', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MN_1182',
    Name: 'Shakopee',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CO_1183',
    Name: 'Brighton',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MD_1184',
    Name: 'Catonsville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_1185',
    Name: 'Rancho Palos Verdes',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_GA_1186', Name: 'Mableton', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_TX_1187', Name: 'Coppell', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_1188',
    Name: 'Linton Hall',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_IL_1189', Name: 'Moline', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_1190', Name: 'Delaware', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MA_1191',
    Name: 'Westfield',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_CT_1192',
    Name: 'Shelton',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_FL_1193',
    Name: 'Greenacres',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_1194', Name: 'Westerville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_VA_1195',
    Name: 'Manassas',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NJ_1196',
    Name: 'Kearny',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_1197',
    Name: 'Los Banos',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_1198',
    Name: 'Richmond',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_UT_1199',
    Name: 'Spanish Fork',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_IL_1200',
    Name: 'Belleville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_1201',
    Name: 'Maplewood',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_1202',
    Name: 'Wilkes-Barre',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_1203',
    Name: 'Pottsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TN_1204',
    Name: 'Columbia',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_IL_1205',
    Name: 'Bartlett',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WV_1206',
    Name: 'Bluefield',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MA_1207',
    Name: 'Fitchburg',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_IN_1208', Name: 'Marion', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_IA_1209', Name: 'Cedar Falls', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_1210',
    Name: 'Buffalo Grove',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_1211',
    Name: 'Jamestown',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MD_1212',
    Name: 'Woodlawn',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WV_1213',
    Name: 'Clarksburg',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_FL_1214',
    Name: 'Royal Palm Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IA_1215', Name: 'Marion', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_KY_1216',
    Name: 'Covington',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_NY_1217', Name: 'Coram', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_TX_1218',
    Name: 'Friendswood',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NC_1219',
    Name: 'Indian Trail',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MA_1220',
    Name: 'Woburn',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_FL_1221',
    Name: 'The Acreage',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MA_1222',
    Name: 'Holyoke',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_CA_1223',
    Name: 'Montclair',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NM_1224',
    Name: 'South Valley',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_CT_1225',
    Name: 'Torrington',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_UT_1226', Name: 'Cedar City', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_CA_1227',
    Name: 'San Gabriel',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_1228',
    Name: 'Hallandale Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_1229',
    Name: 'Crystal Lake',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_1230',
    Name: 'Calexico',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OR_1231',
    Name: 'Lake Oswego',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MA_1232',
    Name: 'Amherst',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_OK_1233',
    Name: 'Muskogee',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_IL_1234',
    Name: 'Romeoville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_1235',
    Name: 'Plant City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OR_1236', Name: 'Keizer', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_WI_1237',
    Name: 'New Berlin',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MA_1238',
    Name: 'Chelsea',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_CA_1239',
    Name: 'La Puente',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_1240', Name: 'Roy', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_CO_1241',
    Name: 'Security-Widefield',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MA_1242',
    Name: 'Marlborough',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_GA_1243', Name: 'Milton', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WA_1244',
    Name: 'Issaquah',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_HI_1245', Name: 'Waipahu', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_MD_1246', Name: 'Essex', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_OK_1247',
    Name: 'Bartlesville',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_TX_1248', Name: 'Lancaster', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_1249',
    Name: 'Streamwood',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TN_1250',
    Name: 'Germantown',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MD_1251',
    Name: 'Annapolis',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_IL_1252',
    Name: 'Carol Stream',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_1253',
    Name: 'Asheboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_1254',
    Name: 'Culver City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MT_1255',
    Name: 'Kalispell',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_PR_1256',
    Name: 'Arecibo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_WA_1257',
    Name: 'Lynnwood',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NC_1258',
    Name: 'Mooresville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WI_1259',
    Name: 'Brookfield',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_FL_1260',
    Name: 'Crestview',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_1261',
    Name: 'Horizon West',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_MD_1262', Name: 'Clinton', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_WA_1263',
    Name: 'Moses Lake',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_1264',
    Name: 'New Castle',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_1265',
    Name: 'Olive Branch',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_AL_1266',
    Name: 'Enterprise',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_AZ_1267', Name: 'Sun City', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_KY_1268',
    Name: 'Frankfort',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CO_1269',
    Name: 'Northglenn',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_OH_1270', Name: 'Warren', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TX_1271',
    Name: 'Duncanville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_1272', Name: 'Hurst', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_FL_1273', Name: 'Clermont', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_IL_1274',
    Name: 'Wheeling',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NJ_1275',
    Name: 'Fort Lee',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_1276',
    Name: 'Pacifica',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_1277',
    Name: 'Lake Worth',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_TN_1278',
    Name: 'Lebanon',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_FL_1279',
    Name: 'Kendall West',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_1280',
    Name: "Land O' Lakes",
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_UT_1281',
    Name: 'Eagle Mountain',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_OH_1282',
    Name: 'Reynoldsburg',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MS_1283',
    Name: 'Oxford',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_TX_1284', Name: 'Rosenberg', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_1285',
    Name: 'Martinez',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_UT_1286',
    Name: 'Pleasant Grove',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_CA_1287',
    Name: 'La Presa',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_1288',
    Name: 'Severna Park',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_OH_1289',
    Name: 'Huber Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CA_1290',
    Name: 'Stanton',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_1291',
    Name: 'East Meadow',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WI_1292',
    Name: 'Menomonee Falls',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_1293', Name: 'Granbury', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_1294', Name: 'Waxahachie', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_FL_1295', Name: 'Valrico', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_TX_1296',
    Name: 'Mission Bend',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NC_1297',
    Name: 'Holly Springs',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_1298',
    Name: 'Butler',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_1299',
    Name: 'Albertville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_KY_1300', Name: 'London', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_FL_1301',
    Name: 'Egypt Lake-Leto',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MN_1302',
    Name: 'Cottage Grove',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_HI_1303', Name: 'Kailua', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_GA_1304',
    Name: 'St. Marys',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_VA_1305',
    Name: 'Mechanicsville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_FL_1306',
    Name: 'Richmond West',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_1307', Name: 'Cleburne', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_1308',
    Name: 'Valley Stream',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_1309',
    Name: 'Hanover Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_NY_1310', Name: 'Oswego', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_FL_1311', Name: 'Navarre', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_OR_1312',
    Name: 'Oregon City',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_FL_1313',
    Name: 'Winter Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WA_1314',
    Name: 'Parkland',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_NV_1315', Name: 'Pahrump', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_IL_1316',
    Name: 'Carpentersville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_1317',
    Name: 'Wildomar',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_1318',
    Name: 'Greenfield',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MA_1319',
    Name: 'Braintree',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_IL_1320',
    Name: 'Rock Island',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_1321',
    Name: 'South Miami Heights',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_GA_1322', Name: 'Evans', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_ND_1323',
    Name: 'West Fargo',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_TN_1324',
    Name: 'Mount Juliet',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_TX_1325', Name: 'San Juan', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_VA_1326', Name: 'Oakton', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_KY_1327',
    Name: 'Georgetown',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IN_1328', Name: 'Portage', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_FL_1329', Name: 'Aventura', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_OK_1330', Name: 'Owasso', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_NY_1331', Name: 'Commack', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_IL_1332',
    Name: 'Park Ridge',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_AL_1333', Name: 'Foley', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_IN_1334',
    Name: 'New Albany',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_AR_1335', Name: 'Benton', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_MS_1336',
    Name: 'Meridian',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IA_1337', Name: 'Bettendorf', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_FL_1338', Name: 'Dunedin', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_OH_1339', Name: 'Hilliard', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_FL_1340',
    Name: 'Merritt Island',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AL_1341',
    Name: 'Phenix City',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_IL_1342', Name: 'Addison', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CA_1343',
    Name: 'West Hollywood',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_1344',
    Name: 'Roseville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MO_1345',
    Name: 'Oakville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_GA_1346', Name: 'Tucker', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CA_1347',
    Name: 'Moorpark',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_1348',
    Name: 'Richfield',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_1349',
    Name: 'Monrovia',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_1350', Name: 'Kearns', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_WI_1351',
    Name: 'Oak Creek',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MI_1352',
    Name: 'Lincoln Park',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_1353',
    Name: 'Claremont',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_1354', Name: 'Oswego', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_ID_1355', Name: 'Post Falls', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_GA_1356',
    Name: 'Peachtree City',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_FL_1357',
    Name: 'Lauderdale Lakes',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_1358',
    Name: 'French Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_1359', Name: 'Portsmouth', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OK_1360', Name: 'Shawnee', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_WY_1361', Name: 'Gillette', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_UT_1362', Name: 'Tooele', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_AL_1363',
    Name: 'Prattville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_OR_1364', Name: 'Woodburn', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MA_1365',
    Name: 'Watertown Town',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_IL_1366',
    Name: 'Calumet City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_1367',
    Name: 'San Juan Capistrano',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_1368',
    Name: 'Foothill Farms',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NY_1369', Name: 'Elmont', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_WI_1370',
    Name: 'Franklin',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_1371',
    Name: 'Temple City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_1372',
    Name: 'Cooper City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AZ_1373',
    Name: 'El Mirage',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_FL_1374',
    Name: 'Meadow Woods',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_GA_1375', Name: 'LaGrange', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_TN_1376',
    Name: 'La Vergne',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_DE_1377',
    Name: 'Middletown',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_MI_1378',
    Name: 'Mount Pleasant',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_FL_1379',
    Name: 'Carrollwood',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_KS_1380',
    Name: 'Junction City',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_MN_1381',
    Name: 'Inver Grove Heights',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_MD_1382', Name: 'Chillum', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_NC_1383',
    Name: 'Monroe',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MD_1384',
    Name: 'Randallstown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_CA_1385', Name: 'Bell', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_OH_1386', Name: 'Gahanna', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_1387',
    Name: 'Sanford',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_FL_1388',
    Name: 'Riviera Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MO_1389',
    Name: 'Wildwood',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OH_1390',
    Name: 'Upper Arlington',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CA_1391',
    Name: 'Auburn',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IN_1392',
    Name: 'Plainfield',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CA_1393',
    Name: 'Westmont',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WA_1394',
    Name: 'Oak Harbor',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_MD_1395', Name: 'Olney', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_CA_1396',
    Name: 'Manhattan Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_1397',
    Name: 'New City',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MD_1398',
    Name: 'Owings Mills',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_1399', Name: 'La Porte', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CO_1400',
    Name: 'Englewood',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_OH_1401', Name: 'Brunswick', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_GA_1402',
    Name: 'East Point',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_FL_1403', Name: 'DeLand', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_GA_1404', Name: 'Martinez', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CA_1405',
    Name: 'Pleasant Hill',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_1406',
    Name: 'Orangevale',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IN_1407',
    Name: 'Merrillville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OH_1408', Name: 'Stow', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_FL_1409',
    Name: 'Buenaventura Lakes',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_AZ_1410', Name: 'San Luis', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_CA_1411',
    Name: 'Oildale',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_GA_1412', Name: 'Calhoun', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_KS_1413', Name: 'Leawood', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_CA_1414',
    Name: 'Menlo Park',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_1415',
    Name: 'Sun Prairie',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CO_1416',
    Name: 'Dakota Ridge',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CA_1417',
    Name: 'Arcata',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_1418',
    Name: 'Lockport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WA_1419',
    Name: 'Pullman',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OH_1420', Name: 'Boardman', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TX_1421',
    Name: 'Nacogdoches',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_NE_1422', Name: 'Kearney', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_AL_1423',
    Name: 'Vestavia Hills',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_OH_1424',
    Name: 'North Ridgeville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_TX_1425', Name: 'Socorro', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MA_1426',
    Name: 'Randolph',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_PA_1427',
    Name: 'Norristown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MD_1428',
    Name: 'Montgomery Village',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OR_1429', Name: 'Redmond', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_CO_1430',
    Name: 'Firestone',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_IN_1431', Name: 'Goshen', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_MT_1432', Name: 'Butte', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_AR_1433',
    Name: 'Russellville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CA_1434',
    Name: 'Grass Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_FL_1435', Name: 'Parkland', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MO_1436',
    Name: 'University City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_UT_1437', Name: 'Midvale', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_FL_1438',
    Name: 'Golden Glades',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MA_1439',
    Name: 'Franklin',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_GA_1440', Name: 'Kennesaw', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CA_1441',
    Name: 'Adelanto',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CO_1442',
    Name: 'Ken Caryl',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NM_1443',
    Name: 'Carlsbad',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_WA_1444',
    Name: 'University Place',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_1445',
    Name: 'Chester',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_GA_1446',
    Name: 'Douglasville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NC_1447',
    Name: 'Salisbury',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_WY_1448', Name: 'Laramie', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_WA_1449',
    Name: 'Lake Stevens',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_1450',
    Name: 'Foster City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IN_1451',
    Name: 'Valparaiso',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OH_1452', Name: 'Fairborn', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_FL_1453', Name: 'Estero', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_OH_1454', Name: 'Mason', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_UT_1455',
    Name: 'Cottonwood Heights',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {Id: 'CIT_US_HI_1456', Name: 'Kaneohe', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_CA_1457',
    Name: 'Sonoma',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_1458',
    Name: 'Beverly Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_1459',
    Name: 'Galesburg',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IN_1460', Name: 'Warsaw', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_CA_1461',
    Name: 'San Dimas',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_1462',
    Name: 'Glendale Heights',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_1463',
    Name: 'Dana Point',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_1464', Name: 'Midlothian', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_DE_1465', Name: 'Newark', stateId: 'DE', stateName: 'Delaware'},
  {
    Id: 'CIT_US_NM_1466',
    Name: 'Alamogordo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_AL_1467',
    Name: 'Alabaster',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MD_1468',
    Name: 'Pikesville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_VA_1469',
    Name: 'Fair Oaks',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_1470', Name: 'Deer Park', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_1471',
    Name: 'Long Beach',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_1472',
    Name: 'Woodridge',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NJ_1473',
    Name: 'Bridgeton',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_SC_1474',
    Name: 'Greer',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_UT_1475', Name: 'Springville', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_UT_1476',
    Name: 'Saratoga Springs',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_TX_1477',
    Name: 'Copperas Cove',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_UT_1478',
    Name: 'American Fork',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_NJ_1479',
    Name: 'Franklin',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MN_1480',
    Name: 'Andover',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NC_1481',
    Name: 'Matthews',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_GA_1482',
    Name: 'Woodstock',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_KY_1483',
    Name: 'Florence',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IL_1484',
    Name: 'Northbrook',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_1485',
    Name: 'West Little River',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NJ_1486',
    Name: 'Fair Lawn',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_IL_1487',
    Name: 'St. Charles',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_1488',
    Name: 'Huntington Station',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WV_1489',
    Name: 'Fairmont',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_CA_1490',
    Name: 'Ridgecrest',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_1491', Name: 'Massillon', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_1492',
    Name: 'Spanaway',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MS_1493',
    Name: 'Starkville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_TX_1494',
    Name: 'Harker Heights',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_1495',
    Name: 'Elk Grove Village',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_UT_1496', Name: 'Kaysville', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_CA_1497',
    Name: 'Lawndale',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_1498', Name: 'Southlake', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_1499',
    Name: 'Ferry Pass',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_MN_1500', Name: 'Savage', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_WA_1501',
    Name: 'Des Moines',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_1502',
    Name: 'Bethel Park',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_1503',
    Name: 'Princeton',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_1504',
    Name: 'Dania Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NY_1505',
    Name: 'Spring Valley',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OH_1506',
    Name: 'Bowling Green',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_VA_1507',
    Name: 'Springfield',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_FL_1508',
    Name: 'East Lake',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MD_1509',
    Name: 'College Park',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OR_1510', Name: 'Coos Bay', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NC_1511',
    Name: 'Smithfield',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_NY_1512', Name: 'Rome', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_UT_1513', Name: 'Clearfield', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_MO_1514', Name: 'Liberty', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MI_1515',
    Name: 'Eastpointe',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NY_1516',
    Name: 'Franklin Square',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IL_1517', Name: 'Pekin', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_1518', Name: 'Westlake', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_1519',
    Name: 'Uniondale',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OH_1520', Name: 'Chillicothe', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_1521',
    Name: 'La Verne',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AK_1522', Name: 'Juneau', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_LA_1523',
    Name: 'Prairieville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_1524',
    Name: 'Placerville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_1525',
    Name: 'Bel Air North',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_GA_1526', Name: 'Redan', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_VA_1527',
    Name: 'Martinsville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CO_1528',
    Name: 'Pueblo West',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NJ_1529',
    Name: 'Garfield',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_1530',
    Name: 'Fallbrook',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_1531',
    Name: 'Spring Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_1532',
    Name: 'Hopkinsville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NH_1533',
    Name: 'Rochester',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_VA_1534',
    Name: 'South Riding',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NY_1535',
    Name: 'Centereach',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_GA_1536',
    Name: 'Milledgeville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_UT_1537', Name: 'Syracuse', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MD_1538',
    Name: 'Parkville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_AR_1539',
    Name: 'Sherwood',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NC_1540',
    Name: 'Garner',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_FL_1541', Name: 'Key West', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_FL_1542',
    Name: 'Miami Lakes',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_VA_1543',
    Name: 'Petersburg',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_OH_1544',
    Name: 'North Olmsted',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_HI_1545', Name: 'Kahului', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_CO_1546',
    Name: 'Wheat Ridge',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_FL_1547',
    Name: 'Fruit Cove',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_1548', Name: 'Cibolo', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_1549', Name: 'Alliance', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_1550',
    Name: 'Banning',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_1551',
    Name: 'Laguna Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_1552',
    Name: 'Central Islip',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AL_1553', Name: 'Athens', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NJ_1554',
    Name: 'Princeton',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_1555',
    Name: 'Orcutt',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CT_1556',
    Name: 'Naugatuck',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_MD_1557',
    Name: 'Eldersburg',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MS_1558',
    Name: 'Columbus',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IL_1559',
    Name: 'Mundelein',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_1560',
    Name: 'Fair Oaks',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_1561',
    Name: 'San Pablo',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_ID_1562', Name: 'Rexburg', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_AZ_1563', Name: 'Nogales', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_KY_1564',
    Name: 'Nicholasville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CA_1565',
    Name: 'Goleta',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AL_1566', Name: 'Opelika', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_IL_1567',
    Name: 'Algonquin',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_LA_1568',
    Name: 'Marrero',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_1569',
    Name: 'Burlingame',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_1570',
    Name: 'Aiken',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_GA_1571',
    Name: 'Lawrenceville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_FL_1572',
    Name: 'Winter Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_1573',
    Name: 'Lake Magdalene',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WI_1574',
    Name: 'Fitchburg',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IN_1575', Name: 'Granger', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_OH_1576', Name: 'Wooster', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CO_1577',
    Name: 'Fountain',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MN_1578',
    Name: 'Brooklyn Center',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_1579', Name: 'Kerrville', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_1580',
    Name: 'Barstow',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_1581',
    Name: 'Oceanside',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_GA_1582', Name: 'Canton', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_NY_1583', Name: 'Auburn', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_IN_1584',
    Name: 'Crown Point',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_CO_1585', Name: 'Windsor', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_FL_1586', Name: 'Lakeside', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MA_1587',
    Name: 'Gloucester',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_IL_1588', Name: 'Gurnee', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_SC_1589',
    Name: 'Orangeburg',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_UT_1590', Name: 'Holladay', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NC_1591',
    Name: 'Fuquay-Varina',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_GA_1592', Name: 'Chamblee', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_MD_1593', Name: 'Carney', stateId: 'MD', stateName: 'Maryland'},
  {Id: 'CIT_US_IL_1594', Name: 'Peru', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_VA_1595',
    Name: 'Sterling',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NC_1596',
    Name: 'Cornelius',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_1597',
    Name: 'North Tonawanda',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NJ_1598',
    Name: 'Long Branch',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_1599',
    Name: 'Santa Paula',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_1600',
    Name: 'Los Gatos',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_1601',
    Name: 'South Lake Tahoe',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_1602',
    Name: 'San Carlos',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_1603', Name: 'Clinton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_1604',
    Name: 'Saratoga',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_1605',
    Name: 'Los Altos',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MO_1606', Name: 'Ballwin', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_CA_1607',
    Name: 'Atascadero',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OH_1608',
    Name: 'North Royalton',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_KY_1609',
    Name: 'Somerset',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_TX_1610', Name: 'Seguin', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AZ_1611',
    Name: 'Fortuna Foothills',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_MD_1612',
    Name: 'Milford Mill',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MD_1613',
    Name: 'Pasadena',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_GA_1614',
    Name: 'Stockbridge',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_OH_1615',
    Name: 'East Liverpool',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NJ_1616',
    Name: 'Rahway',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MI_1617',
    Name: 'Madison Heights',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_ND_1618',
    Name: 'Williston',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NY_1619',
    Name: 'Bay Shore',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_ID_1620', Name: 'Eagle', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_FL_1621',
    Name: 'Westchester',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_TN_1622',
    Name: 'Maryville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_CA_1623',
    Name: 'Walnut',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_1624',
    Name: 'Suisun City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KS_1625',
    Name: 'Garden City',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_AR_1626',
    Name: 'Texarkana',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_OH_1627', Name: 'Kent', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_VA_1628',
    Name: 'West Falls Church',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IL_1629',
    Name: 'North Chicago',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MS_1630',
    Name: 'Greenville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_GA_1631', Name: 'Duluth', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WA_1632',
    Name: 'Aberdeen',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_MN_1633', Name: 'Winona', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_IL_1634',
    Name: "O'Fallon",
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AZ_1635',
    Name: 'Drexel Heights',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_CA_1636', Name: 'Ukiah', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_CA_1637',
    Name: 'Atwater',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_1638',
    Name: 'Golden Gate',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_ME_1639', Name: 'Sanford', stateId: 'ME', stateName: 'Maine'},
  {
    Id: 'CIT_US_IL_1640',
    Name: 'Highland Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NJ_1641',
    Name: 'Westfield',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OR_1642', Name: 'Hermiston', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MI_1643',
    Name: 'Oak Park',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_LA_1644',
    Name: 'Central',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_FL_1645',
    Name: 'East Lake-Orient Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_1646',
    Name: 'Chicago Heights',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_1647',
    Name: 'East Palo Alto',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OR_1648', Name: 'Newberg', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_DE_1649', Name: 'Lewes', stateId: 'DE', stateName: 'Delaware'},
  {
    Id: 'CIT_US_MA_1650',
    Name: 'North Attleborough',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_UT_1651', Name: 'Washington', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NY_1652',
    Name: 'Port Chester',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TN_1653',
    Name: 'Oak Ridge',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_MD_1654', Name: 'Crofton', stateId: 'MD', stateName: 'Maryland'},
  {Id: 'CIT_US_OH_1655', Name: 'Athens', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_LA_1656',
    Name: 'Laplace',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WA_1657',
    Name: 'SeaTac',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_MO_1658', Name: 'Raytown', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_AR_1659',
    Name: 'Paragould',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_WA_1660',
    Name: 'Graham',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MI_1661',
    Name: 'Southgate',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NY_1662',
    Name: 'Harrison',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IL_1663', Name: 'Niles', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_FL_1664',
    Name: 'Fernandina Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_1665', Name: 'Big Spring', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AR_1666',
    Name: 'Bella Vista',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_1667',
    Name: 'Milton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_1668',
    Name: 'Morrisville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_1669', Name: 'Greenville', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_1670',
    Name: 'Casselberry',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_NY_1671', Name: 'Plattsburgh', stateName: 'New York'},
  {Id: 'CIT_US_CA_1672', Name: 'Wasco', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_MD_1673',
    Name: 'South Laurel',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_FL_1674',
    Name: 'Fleming Island',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_1675',
    Name: 'Belle Glade',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_1676',
    Name: 'Lake in the Hills',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MA_1677',
    Name: 'Agawam',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_NY_1678', Name: 'Shirley', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_MI_1679', Name: 'Burton', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_NY_1680', Name: 'Geneva', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_IN_1681',
    Name: 'Schererville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_KY_1682',
    Name: 'Independence',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MA_1683',
    Name: 'West Springfield',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_OH_1684', Name: 'Austintown', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IA_1685', Name: 'Burlington', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_1686',
    Name: 'Shamokin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_1687',
    Name: 'New Iberia',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MA_1688',
    Name: 'Northampton',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_NJ_1689',
    Name: 'Englewood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_1690',
    Name: 'Vineyard',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IN_1691',
    Name: 'Zionsville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_VA_1692',
    Name: 'Short Pump',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_IL_1693', Name: 'Burbank', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NC_1694',
    Name: 'Lumberton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MD_1695',
    Name: 'Perry Hall',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_SD_1696',
    Name: 'Aberdeen',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_CA_1697',
    Name: 'Benicia',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_1698',
    Name: 'Oakleaf Plantation',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AR_1699',
    Name: 'Jacksonville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NV_1700',
    Name: 'Winchester',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_FL_1701',
    Name: 'Rockledge',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_KY_1702',
    Name: 'Henderson',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CA_1703',
    Name: 'Monterey',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_1704', Name: 'Converse', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_1705',
    Name: 'Granite City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OK_1706', Name: 'Yukon', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CA_1707',
    Name: 'Sonora',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MA_1708',
    Name: 'Melrose',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_MO_1709',
    Name: 'Mehlville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WI_1710',
    Name: 'Wisconsin Rapids',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_CA_1711', Name: 'Galt', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_TX_1712', Name: 'Hutto', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OK_1713', Name: 'Bixby', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_IN_1714', Name: 'Hobart', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PA_1715',
    Name: 'Drexel Hill',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_1716',
    Name: 'Oakdale',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_1717',
    Name: 'Sunbury',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NV_1718',
    Name: 'Summerlin South',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_FL_1719',
    Name: 'New Smyrna Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OR_1720', Name: 'Tualatin', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MI_1721',
    Name: 'Forest Hills',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MN_1722',
    Name: 'Fridley',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IN_1723',
    Name: 'East Chicago',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MO_1724',
    Name: 'Kirkwood',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_1725',
    Name: 'Sanger',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_LA_1726',
    Name: 'Morgan City',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_MN_1727', Name: 'Ramsey', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_LA_1728', Name: 'Ruston', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_KY_1729',
    Name: 'Jeffersontown',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IL_1730',
    Name: 'Glen Ellyn',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_1731',
    Name: 'Isla Vista',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CO_1732',
    Name: 'Cañon City',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MN_1733',
    Name: 'Monticello',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OH_1734', Name: 'Ashtabula', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_1735',
    Name: 'Temescal Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MA_1736',
    Name: 'Bridgewater',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_NC_1737',
    Name: 'Mint Hill',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_HI_1738',
    Name: 'Mililani Town',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_NC_1739',
    Name: 'Statesville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_1740',
    Name: 'Gloversville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_1741',
    Name: 'Gladstone',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NY_1742',
    Name: 'Deer Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_NE_1743', Name: 'Fremont', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_OH_1744',
    Name: 'Garfield Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CA_1745',
    Name: 'Imperial Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_1746',
    Name: 'Seneca',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_AZ_1747', Name: 'Florence', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_FL_1748',
    Name: 'Bayonet Point',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IL_1749', Name: 'Lansing', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NJ_1750',
    Name: 'Millville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_1751',
    Name: 'Monroeville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OR_1752',
    Name: 'St. Helens',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_NJ_1753',
    Name: 'Bergenfield',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_1754',
    Name: 'Ladera Ranch',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MS_1755',
    Name: 'Horn Lake',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MN_1756',
    Name: 'Prior Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IL_1757', Name: 'Huntley', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_WA_1758',
    Name: 'Maple Valley',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_1759',
    Name: 'Easton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_1760',
    Name: 'Oak Forest',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_1761',
    Name: 'Glen Cove',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_1762',
    Name: 'Indiana',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_1763',
    Name: 'Shoreview',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_1764',
    Name: 'Windsor',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_1765',
    Name: 'Sterling',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_KS_1766',
    Name: 'Dodge City',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_TX_1767',
    Name: 'Colleyville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_1768',
    Name: 'Wilmette',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_1769',
    Name: 'Plum',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_1770',
    Name: 'Mount Pleasant',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IL_1771', Name: 'McHenry', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_ME_1772', Name: 'Waterville', stateId: 'ME', stateName: 'Maine'},
  {Id: 'CIT_US_NE_1773', Name: 'Norfolk', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_OH_1774',
    Name: 'Shaker Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_FL_1775',
    Name: 'Citrus Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MD_1776',
    Name: 'Reisterstown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_CO_1777', Name: 'Erie', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_IN_1778',
    Name: 'Brownsburg',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_MN_1779', Name: 'Chaska', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_MD_1780',
    Name: 'Ilchester',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NC_1781',
    Name: 'Shelby',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_1782',
    Name: 'Maywood',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_1783',
    Name: 'Maryland Heights',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_UT_1784', Name: 'Magna', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_OH_1785', Name: 'Xenia', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_1786',
    Name: 'Belmont',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MI_1787',
    Name: 'Allen Park',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IA_1788', Name: 'Mason City', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_1789',
    Name: 'New Lenox',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SC_1790',
    Name: 'Hanahan',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NC_1791',
    Name: 'Kinston',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_AR_1792', Name: 'Searcy', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_CT_1793',
    Name: 'New London',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_NY_1794',
    Name: 'West Islip',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_1795',
    Name: 'West Chicago',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_1796',
    Name: 'Kiryas Joel',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_1797',
    Name: 'Lemon Grove',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_1798',
    Name: 'Lindenhurst',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_GA_1799',
    Name: 'McDonough',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_OR_1800', Name: 'West Linn', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NY_1801',
    Name: 'Cortland',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_1802',
    Name: 'Lemoore',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_1803', Name: 'Alvin', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_1804',
    Name: 'Waynesboro',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IA_1805',
    Name: 'Marshalltown',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_NH_1806',
    Name: 'Lebanon',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_CA_1807',
    Name: 'West Whittier-Los Nietos',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_1808',
    Name: 'Leisure City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NC_1809',
    Name: 'Thomasville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_FL_1810',
    Name: 'Temple Terrace',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_1811',
    Name: 'Lafayette',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_1812',
    Name: 'University Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_CA_1813', Name: 'Norco', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_FL_1814',
    Name: 'Immokalee',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_1815',
    Name: 'Vernon Hills',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MS_1816',
    Name: 'Pearl',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OR_1817', Name: 'Bethany', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MI_1818',
    Name: 'Marquette',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_MO_1819', Name: 'Sedalia', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_AL_1820', Name: 'Bessemer', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NY_1821',
    Name: 'Plainview',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IL_1822', Name: 'Batavia', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MI_1823',
    Name: 'Garden City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MN_1824',
    Name: 'Chanhassen',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AR_1825', Name: 'Cabot', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_MS_1826',
    Name: 'Vicksburg',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CA_1827',
    Name: 'Brawley',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_WI_1828', Name: 'Neenah', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_WA_1829',
    Name: 'Snoqualmie',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OH_1830', Name: 'Troy', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_1831',
    Name: 'Hercules',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_1832',
    Name: 'Lake City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NJ_1833',
    Name: 'Paramus',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_FL_1834',
    Name: 'Okeechobee',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_1835',
    Name: 'Soledad',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_1836', Name: 'Muscatine', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NC_1837',
    Name: 'Boone',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_ID_1838', Name: 'Moscow', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_NJ_1839',
    Name: 'Cliffside Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_FL_1840',
    Name: 'Sebastian',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AL_1841',
    Name: 'Northport',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MS_1842',
    Name: 'Natchez',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_KY_1843',
    Name: 'Winchester',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CA_1844',
    Name: 'Twentynine Palms',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MI_1845', Name: 'Holt', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_IL_1846',
    Name: 'East St. Louis',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_1847', Name: 'Sachse', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_1848', Name: 'Paris', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_1849',
    Name: 'Haines City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_VA_1850',
    Name: 'Cave Spring',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WI_1851',
    Name: 'Superior',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TX_1852',
    Name: 'West Odessa',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OH_1853', Name: 'Medina', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_SC_1854',
    Name: 'Conway',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OH_1855', Name: 'Barberton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NM_1856',
    Name: 'Española',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NY_1857',
    Name: 'Dix Hills',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WY_1858',
    Name: 'Rock Springs',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_WA_1859',
    Name: 'Mercer Island',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_UT_1860', Name: 'Heber', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MN_1861',
    Name: 'White Bear Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MS_1862',
    Name: 'Laurel',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NY_1863',
    Name: 'Holbrook',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_1864',
    Name: 'Bay Point',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_1865',
    Name: 'Woodstock',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MD_1866',
    Name: 'Lochearn',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OH_1867', Name: 'Green', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_1868', Name: 'Cloverleaf', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MN_1869',
    Name: 'Owatonna',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_GA_1870', Name: 'Decatur', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_GA_1871', Name: 'Pooler', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MS_1872',
    Name: 'Madison',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_SC_1873',
    Name: 'Lancaster',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_HI_1874',
    Name: 'Ewa Gentry',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {Id: 'CIT_US_MD_1875', Name: 'Laurel', stateId: 'MD', stateName: 'Maryland'},
  {Id: 'CIT_US_MN_1876', Name: 'Austin', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_IL_1877',
    Name: 'Lockport',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IN_1878', Name: 'Franklin', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_UT_1879',
    Name: 'South Salt Lake',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_FL_1880',
    Name: 'Tarpon Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MD_1881',
    Name: 'Edgewood',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_1882',
    Name: 'San Lorenzo',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_1883',
    Name: 'Bluffton',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_TN_1884',
    Name: 'Sevierville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_OR_1885',
    Name: 'Forest Grove',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_AZ_1886',
    Name: 'Sun City West',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_ME_1887',
    Name: 'South Portland',
    stateId: 'ME',
    stateName: 'Maine',
  },
  {Id: 'CIT_US_FL_1888', Name: 'Ruskin', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_TX_1889', Name: 'Denison', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_1890',
    Name: 'El Cerrito',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_FL_1891', Name: 'Keystone', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_FL_1892',
    Name: 'Coral Terrace',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_OK_1893',
    Name: 'Claremore',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_AL_1894', Name: 'Homewood', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_UT_1895', Name: 'Farmington', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_CO_1896',
    Name: 'Columbine',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CA_1897',
    Name: 'South Pasadena',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_1898',
    Name: 'Canyon Lake',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_1899', Name: 'Kingsville', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NC_1900',
    Name: 'Forest City',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_VA_1901', Name: 'Salem', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_WA_1902',
    Name: 'Bainbridge Island',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_1903',
    Name: 'Caledonia',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MN_1904',
    Name: 'Champlin',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_1905',
    Name: 'Edwardsville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_1906',
    Name: 'South Bradenton',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MD_1907',
    Name: 'Fairland',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_FL_1908',
    Name: 'Palm Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MN_1909',
    Name: 'Elk River',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MN_1910',
    Name: 'Rosemount',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AZ_1911',
    Name: 'Fountain Hills',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_IL_1912',
    Name: 'Belvidere',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_1913', Name: 'Riverside', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MD_1914',
    Name: 'Middle River',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WI_1915',
    Name: 'Muskego',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PR_1916',
    Name: 'Levittown',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MO_1917',
    Name: 'Hazelwood',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NE_1918',
    Name: 'Scottsbluff',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_TX_1919', Name: 'Fresno', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_1920',
    Name: 'Ridgewood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_1921',
    Name: 'Yucca Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MO_1922', Name: 'Branson', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_TX_1923',
    Name: 'Balch Springs',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_TX_1924',
    Name: 'University Park',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_TX_1925',
    Name: 'Timberwood Park',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WI_1926',
    Name: 'De Pere',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_SC_1927',
    Name: 'Socastee',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_LA_1928',
    Name: 'Terrytown',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PR_1929',
    Name: 'Fajardo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_OH_1930', Name: 'Marysville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_KS_1931', Name: 'Derby', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_OR_1932',
    Name: 'Wilsonville',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_NC_1933',
    Name: 'Clayton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_SD_1934',
    Name: 'Brookings',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_CA_1935',
    Name: 'Riverbank',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_1936',
    Name: 'North Laurel',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_MI_1937', Name: 'Walker', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MI_1938',
    Name: 'Wyandotte',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MO_1939',
    Name: 'Grandview',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MN_1940',
    Name: 'Forest Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MD_1941',
    Name: 'Chesapeake Beach',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_FL_1942',
    Name: 'Palm City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_WI_1943', Name: 'Hudson', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_MD_1944',
    Name: 'Clarksburg',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_IL_1945',
    Name: 'South Elgin',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MI_1946',
    Name: 'Auburn Hills',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_NY_1947', Name: 'Baldwin', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_CA_1948',
    Name: 'North Tustin',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OR_1949', Name: 'Ontario', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_IL_1950',
    Name: 'Melrose Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OK_1951', Name: 'Ardmore', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NE_1952',
    Name: 'Hastings',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_WI_1953',
    Name: 'Burlington',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MI_1954',
    Name: 'Norton Shores',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NC_1955',
    Name: 'Kernersville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_GA_1956',
    Name: 'Sugar Hill',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_VA_1957', Name: 'Herndon', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_KS_1958', Name: 'Emporia', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_TX_1959', Name: 'Prosper', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_1960',
    Name: 'Rockville Centre',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_FL_1961',
    Name: 'Palmetto Bay',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MD_1962',
    Name: 'Suitland',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NJ_1963',
    Name: 'Somerset',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OH_1964', Name: 'Avon Lake', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_1965',
    Name: 'Lathrop',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_1966',
    Name: 'Loma Linda',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_1967', Name: 'Watauga', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_1968',
    Name: 'Homer Glen',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_GA_1969', Name: 'Tifton', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CA_1970',
    Name: 'Dinuba',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_1971',
    Name: 'Bloomingdale',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_1972',
    Name: 'Westmont',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MS_1973',
    Name: 'Clinton',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CA_1974',
    Name: 'Ashland',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_LA_1975',
    Name: 'Opelousas',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_WA_1976', Name: 'Camas', stateId: 'WA', stateName: 'Washington'},
  {Id: 'CIT_US_IL_1977', Name: 'Harvey', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_NY_1978', Name: 'Medford', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_OH_1979', Name: 'Trotwood', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AR_1980',
    Name: 'West Memphis',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_IL_1981',
    Name: 'Collinsville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_WI_1982', Name: 'Mequon', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_WA_1983',
    Name: 'Ellensburg',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IA_1984', Name: 'Ottumwa', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_VA_1985',
    Name: 'Chantilly',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_KY_1986',
    Name: 'Shelbyville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_VA_1987',
    Name: 'Woodlawn',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_NJ_1988', Name: 'Lodi', stateId: 'NJ', stateName: 'New Jersey'},
  {
    Id: 'CIT_US_WA_1989',
    Name: 'Orchards',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_RI_1990',
    Name: 'Newport',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_CA_1991',
    Name: 'San Fernando',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_1992', Name: 'Saginaw', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_1993',
    Name: 'Peekskill',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WA_1994',
    Name: 'Port Angeles',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WA_1995',
    Name: 'Mill Creek East',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MS_1996',
    Name: 'Brandon',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_FL_1997',
    Name: 'Sun City Center',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_MI_1998', Name: 'Inkster', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_GA_1999',
    Name: 'Thomasville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_OK_2000',
    Name: 'Ponca City',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_PR_2001',
    Name: 'Cataño',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_VA_2002', Name: 'Tysons', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_FL_2003',
    Name: 'West Melbourne',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MN_2004',
    Name: 'Faribault',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_2005', Name: 'San Benito', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MD_2006',
    Name: 'Fort Washington',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NC_2007',
    Name: 'Lincolnton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_VA_2008',
    Name: 'West Springfield',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_SC_2009',
    Name: 'Simpsonville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_MI_2010', Name: 'Waverly', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_VA_2011',
    Name: "Bailey's Crossroads",
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_MI_2012', Name: 'Okemos', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MS_2013',
    Name: 'Ridgeland',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IL_2014',
    Name: 'Elmwood Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_NE_2015', Name: 'North', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_WV_2016',
    Name: 'Charles Town',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IL_2017',
    Name: 'Freeport',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_2018', Name: 'Waukee', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NJ_2019',
    Name: 'South Plainfield',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OH_2020', Name: 'Wadsworth', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_2021',
    Name: 'Tumwater',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_VA_2022', Name: 'Fairfax', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_GA_2023',
    Name: 'Candler-McAfee',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_FL_2024',
    Name: 'Oak Ridge',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_FL_2025', Name: 'Venice', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_FL_2026', Name: 'Wright', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_VA_2027',
    Name: 'Lincolnia',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_FL_2028',
    Name: 'Westchase',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_2029',
    Name: 'Edgewater',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MD_2030',
    Name: 'North Potomac',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_AL_2031', Name: 'Pelham', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_TX_2032', Name: 'Corsicana', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_2033',
    Name: 'Seal Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_2034', Name: 'Fort Dodge', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_NY_2035', Name: 'Dunkirk', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_CA_2036',
    Name: 'Calabasas',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_LA_2037',
    Name: 'Chalmette',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_SC_2038',
    Name: 'North Augusta',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_FL_2039',
    Name: 'Palm River-Clair Mel',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_2040',
    Name: 'Mount Pocono',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TN_2041',
    Name: 'Farragut',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_OK_2042', Name: 'Jenks', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_FL_2043', Name: 'Palatka', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_2044',
    Name: 'Coronado',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_2045',
    Name: 'Valinda',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_2046', Name: 'Centerville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AR_2047',
    Name: 'Van Buren',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_TN_2048',
    Name: 'Greeneville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_GA_2049', Name: 'Waycross', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CA_2050',
    Name: 'Half Moon Bay',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MO_2051', Name: 'Belton', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_FL_2052',
    Name: 'Jacksonville Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WA_2053',
    Name: 'North Lynnwood',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_NY_2054', Name: 'Ilion', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_CA_2055',
    Name: 'Rosemont',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IN_2056', Name: 'Seymour', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_CA_2057',
    Name: 'Oakdale',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_2058',
    Name: 'Madisonville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_MI_2059', Name: 'Romulus', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_CA_2060',
    Name: 'Cudahy',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_2061',
    Name: 'Copiague',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_2062',
    Name: 'Winter Gardens',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_2063',
    Name: 'Leland',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_2064',
    Name: 'Rolling Meadows',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_2065',
    Name: 'Wekiwa Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_2066',
    Name: 'Northdale',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_2067', Name: 'Fort Hood', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_2068', Name: 'Benbrook', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_2069', Name: 'Zion', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_WI_2070',
    Name: 'Watertown',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CO_2071',
    Name: 'Montrose',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_FL_2072',
    Name: 'Hialeah Gardens',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NE_2073',
    Name: 'Columbus',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_TX_2074', Name: 'Plainview', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_ME_2075', Name: 'Auburn', stateId: 'ME', stateName: 'Maine'},
  {
    Id: 'CIT_US_NJ_2076',
    Name: 'Carteret',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_MD_2077', Name: 'Arnold', stateId: 'MD', stateName: 'Maryland'},
  {Id: 'CIT_US_OH_2078', Name: 'Avon', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_2079',
    Name: 'West Puente Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_2080',
    Name: 'Loves Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_2081',
    Name: 'Vero Beach South',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IL_2082', Name: 'Lisle', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MD_2083',
    Name: 'Greenbelt',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_2084',
    Name: 'Granite Bay',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_2085', Name: 'Maywood', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_ND_2086',
    Name: 'Dickinson',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_OH_2087', Name: 'Oxford', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_2088',
    Name: 'Kenmore',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_2089',
    Name: 'Allison Park',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_2090',
    Name: 'Farmington',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_NY_2091', Name: 'Monsey', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_MN_2092',
    Name: 'Hastings',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NC_2093',
    Name: 'Rockingham',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IN_2094',
    Name: 'Greenfield',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IN_2095', Name: 'Auburn', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_WA_2096',
    Name: 'Frederickson',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OH_2097', Name: 'Willoughby', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OK_2098', Name: 'Mustang', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CA_2099',
    Name: 'Patterson',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NM_2100',
    Name: 'Gallup',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_KY_2101',
    Name: 'Radcliff',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CA_2102',
    Name: 'West Rancho Dominguez',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_2103',
    Name: 'Blue Island',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_2104',
    Name: 'Crystal',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_2105', Name: 'Belton', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_FL_2106', Name: 'Pace', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_TX_2107', Name: 'Marshall', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_2108',
    Name: 'Laguna Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_2109',
    Name: 'Webster Groves',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_GA_2110', Name: 'Acworth', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_GA_2111', Name: 'Griffin', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CA_2112',
    Name: 'Willowbrook',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_2113',
    Name: 'Morton Grove',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NH_2114',
    Name: 'Keene',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_CA_2115',
    Name: 'Marina',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_2116', Name: 'Solon', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MN_2117',
    Name: 'New Brighton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_ND_2118',
    Name: 'Mandan',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_KS_2119', Name: 'Pittsburg', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NC_2120',
    Name: 'Elizabeth City',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TN_2121',
    Name: 'Harriman',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_FL_2122',
    Name: 'Ives Estates',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MA_2123',
    Name: 'Greenfield',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_AL_2124', Name: 'Fairhope', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_WA_2125',
    Name: 'Cottage Lake',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_IL_2126',
    Name: 'Machesney Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_2127',
    Name: 'Braidwood',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_UT_2128', Name: 'Hurricane', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_AL_2129',
    Name: 'Trussville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_VA_2130',
    Name: 'Waynesboro',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_IA_2131', Name: 'Johnston', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_2132',
    Name: 'Corcoran',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_2133',
    Name: 'Meadville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MD_2134',
    Name: 'Landover',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_OR_2135',
    Name: 'Happy Valley',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_IL_2136',
    Name: 'East Peoria',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_VA_2137',
    Name: 'Hopewell',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_FL_2138',
    Name: 'Fort Walton Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_MO_2139', Name: 'Nixa', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_UT_2140', Name: 'Clinton', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_IN_2141', Name: 'Munster', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_VA_2142',
    Name: 'Christiansburg',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_AZ_2143', Name: 'Anthem', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_IL_2144', Name: 'Roselle', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NY_2145',
    Name: 'Garden City',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_FL_2146',
    Name: 'The Crossings',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MO_2147',
    Name: 'Warrensburg',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_HI_2148', Name: 'Kihei', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_GA_2149',
    Name: 'Union City',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CA_2150',
    Name: 'Millbrae',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_2151',
    Name: 'Florida Ridge',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WA_2152',
    Name: 'Silver Firs',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IL_2153', Name: 'Dolton', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OK_2154', Name: 'Duncan', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_FL_2155', Name: 'Brent', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MN_2156',
    Name: 'Northfield',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IN_2157', Name: 'Highland', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_WA_2158',
    Name: 'Sequim',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KS_2159',
    Name: 'Prairie Village',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_ID_2160', Name: 'Kuna', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_IA_2161', Name: 'Coralville', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SC_2162',
    Name: 'Fort Mill',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_VA_2163',
    Name: 'Rose Hill',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_OH_2164', Name: 'Hudson', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_FL_2165', Name: 'Lutz', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_SC_2166',
    Name: 'Taylors',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NC_2167',
    Name: 'Roanoke Rapids',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PR_2168',
    Name: 'Vega Baja',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_MO_2169', Name: 'Raymore', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_FL_2170', Name: 'Ensley', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_OH_2171', Name: 'Fremont', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_2172',
    Name: 'Mukwonago',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_SD_2173',
    Name: 'Watertown',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NY_2174',
    Name: 'East Patchogue',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OR_2175', Name: 'Lebanon', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_OH_2176',
    Name: 'Pickerington',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_SC_2177',
    Name: 'Lexington',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_CA_2178', Name: 'Arvin', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_MN_2179',
    Name: 'Lino Lakes',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_ME_2180', Name: 'Augusta', stateId: 'ME', stateName: 'Maine'},
  {Id: 'CIT_US_DE_2181', Name: 'Milford', stateId: 'DE', stateName: 'Delaware'},
  {
    Id: 'CIT_US_TN_2182',
    Name: 'Shelbyville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_TX_2183', Name: 'Corinth', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_FL_2184', Name: 'Naples', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_2185',
    Name: 'West Carson',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OH_2186',
    Name: 'Maple Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_FL_2187', Name: 'Lealman', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_VA_2188', Name: 'Chester', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_KS_2189', Name: 'Gardner', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WA_2190',
    Name: 'Union Hill-Novelty Hill',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_OR_2191',
    Name: 'Hayesville',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_AR_2192',
    Name: 'Siloam Springs',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_OH_2193', Name: 'Piqua', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_FL_2194',
    Name: 'Fish Hawk',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_2195',
    Name: 'Port Hueneme',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_2196',
    Name: 'Lakeside',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_2197',
    Name: 'Summit',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_2198',
    Name: 'Secaucus',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MN_2199',
    Name: 'Golden Valley',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_FL_2200',
    Name: 'Jasmine Estates',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_OH_2201',
    Name: 'Mount Vernon',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CA_2202',
    Name: 'Bloomington',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_2203',
    Name: 'Camp Springs',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_2204',
    Name: 'Lennox',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_2205',
    Name: 'Roselle',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IL_2206', Name: 'Geneva', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_FL_2207',
    Name: 'Sunny Isles Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_2208',
    Name: 'Rancho San Diego',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_2209',
    Name: 'Massapequa',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_2210',
    Name: 'Bloomingdale',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_2211',
    Name: 'Shafter',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WA_2212',
    Name: 'Eastmont',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_TX_2213', Name: 'Katy', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_2214', Name: 'Brownwood', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OK_2215',
    Name: 'Del City',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_IL_2216',
    Name: 'Jacksonville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_2217',
    Name: 'North Bay Shore',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WA_2218',
    Name: 'Silverdale',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_HI_2219', Name: 'Kapolei', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_MI_2220',
    Name: 'Allendale',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IL_2221', Name: 'Darien', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_2222', Name: 'Perrysburg', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_2223',
    Name: 'Lewistown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_2224',
    Name: 'Hamtramck',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IN_2225', Name: 'La Porte', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_IN_2226',
    Name: 'Clarksville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IN_2227',
    Name: 'New Castle',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_FL_2228', Name: 'Bellview', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_ME_2229', Name: 'Biddeford', stateId: 'ME', stateName: 'Maine'},
  {Id: 'CIT_US_VA_2230', Name: 'McNair', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_IL_2231',
    Name: 'Villa Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_NV_2232', Name: 'Fernley', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_MD_2233',
    Name: 'Elkridge',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WA_2234',
    Name: 'Mukilteo',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_TN_2235', Name: 'Atoka', stateId: 'TN', stateName: 'Tennessee'},
  {Id: 'CIT_US_AL_2236', Name: 'Prichard', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_KS_2237', Name: 'Hays', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MI_2238',
    Name: 'Birmingham',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SC_2239',
    Name: 'Easley',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_VA_2240',
    Name: 'Brambleton',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WA_2241',
    Name: 'Mountlake Terrace',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_2242',
    Name: 'Ramona',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MI_2243', Name: 'Owosso', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_PA_2244',
    Name: 'King of Prussia',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_FL_2245', Name: 'DeBary', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_FL_2246', Name: 'Eustis', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_OH_2247',
    Name: 'South Euclid',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IL_2248',
    Name: 'South Holland',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_2249',
    Name: 'Palm Valley',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NJ_2250',
    Name: 'North Plainfield',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OR_2251', Name: 'Ashland', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_OK_2252', Name: 'Sapulpa', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CA_2253',
    Name: 'Duarte',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_2254', Name: 'Marietta', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_2255',
    Name: 'Battle Ground',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_TX_2256',
    Name: 'Stephenville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NY_2257',
    Name: 'Amsterdam',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AL_2258', Name: 'Oxford', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_IL_2259',
    Name: 'Park Forest',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_CO_2260', Name: 'Evans', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_MN_2261',
    Name: 'Alexandria',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WI_2262',
    Name: 'Fort Atkinson',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WA_2263',
    Name: 'Hazel Dell',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NC_2264',
    Name: 'Carrboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_KY_2265',
    Name: 'Bardstown',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_TN_2266',
    Name: 'East Ridge',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_NV_2267', Name: 'Elko', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_WA_2268',
    Name: 'Covington',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_SC_2269',
    Name: 'Gaffney',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NV_2270',
    Name: 'Sun Valley',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {Id: 'CIT_US_OH_2271', Name: 'Sidney', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_2272',
    Name: 'Bonney Lake',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_TX_2273', Name: 'Dickinson', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_2274',
    Name: 'Martha Lake',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WA_2275',
    Name: 'Bothell West',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_MO_2276', Name: 'Arnold', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MD_2277',
    Name: 'Ballenger Creek',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_MO_2278', Name: 'Rolla', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_FL_2279',
    Name: 'West Pensacola',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_LA_2280', Name: 'Harvey', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_CO_2281',
    Name: 'Johnstown',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MD_2282',
    Name: 'Cockeysville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WI_2283',
    Name: 'Pleasant Prairie',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IN_2284',
    Name: 'Shelbyville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_FL_2285',
    Name: 'Sweetwater',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_SC_2286',
    Name: 'St. Andrews',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OR_2287', Name: 'Milwaukie', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_AR_2288', Name: 'Bryant', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_WA_2289',
    Name: 'Salmon Creek',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NC_2290',
    Name: 'Kill Devil Hills',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_UT_2291',
    Name: 'North Salt Lake',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_FL_2292',
    Name: 'Apollo Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PR_2293',
    Name: 'Guayama',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_GA_2294', Name: 'Cornelia', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_GA_2295', Name: 'Suwanee', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MN_2296',
    Name: 'New Hope',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_SC_2297',
    Name: 'Wade Hampton',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_AZ_2298',
    Name: 'Green Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WA_2299',
    Name: 'Mill Creek',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_TX_2300',
    Name: 'Universal City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_VA_2301',
    Name: 'Montclair',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_VA_2302', Name: 'Lorton', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_NC_2303',
    Name: 'Clemmons',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_2304',
    Name: 'Rosamond',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_DE_2305', Name: 'Bear', stateId: 'DE', stateName: 'Delaware'},
  {
    Id: 'CIT_US_TN_2306',
    Name: 'Dyersburg',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_NY_2307', Name: 'Oneida', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_FL_2308',
    Name: 'Hunters Creek',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_FL_2309', Name: 'Holiday', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CO_2310',
    Name: 'Louisville',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_CA_2311', Name: 'Dixon', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_KY_2312', Name: 'Murray', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_CO_2313', Name: 'Golden', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_NY_2314', Name: 'Olean', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_CO_2315', Name: 'Clifton', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_IL_2316',
    Name: 'Grayslake',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_2317',
    Name: 'North Bellmore',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NJ_2318',
    Name: 'Palisades Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WI_2319',
    Name: 'South Milwaukee',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MA_2320',
    Name: 'Gardner',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_NC_2321',
    Name: 'Havelock',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_2322',
    Name: 'Lake Ronkonkoma',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OH_2323', Name: 'Lebanon', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_2324',
    Name: 'East Moline',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_2325', Name: 'Norwalk', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_2326',
    Name: 'Yorkville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IN_2327',
    Name: 'Logansport',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_GA_2328', Name: 'Dublin', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_UT_2329', Name: 'North Ogden', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_CA_2330',
    Name: 'South El Monte',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_2331',
    Name: 'Lynn Haven',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MO_2332',
    Name: 'Ferguson',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WA_2333',
    Name: 'Arlington',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MN_2334',
    Name: 'Willmar',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_2335', Name: 'Alice', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NC_2336',
    Name: 'Swansboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_2337', Name: 'Murphy', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_2338',
    Name: 'Culpeper',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_MO_2339', Name: 'Ozark', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_CA_2340',
    Name: 'American Canyon',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NE_2341',
    Name: 'Papillion',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_OR_2342',
    Name: 'The Dalles',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MN_2343',
    Name: 'Columbia Heights',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_VT_2344', Name: 'Barre', stateId: 'VT', stateName: 'Vermont'},
  {
    Id: 'CIT_US_MA_2345',
    Name: 'Amherst Center',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_IL_2346',
    Name: 'Crest Hill',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_2347',
    Name: 'Punta Gorda',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_VA_2348', Name: 'Neabsco', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_KS_2349', Name: 'Newton', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MO_2350',
    Name: 'Poplar Bluff',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_2351', Name: 'Ennis', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_2352',
    Name: 'Tukwila',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_2353',
    Name: 'Prunedale',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_2354',
    Name: 'Lomita',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_2355', Name: 'Payson', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_AL_2356',
    Name: 'Mountain Brook',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NJ_2357',
    Name: 'Glassboro',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_FL_2358',
    Name: 'Bayshore Gardens',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_2359', Name: 'Ashland', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_2360',
    Name: 'Shelton',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_LA_2361',
    Name: 'Shenandoah',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_VA_2362',
    Name: 'Cherry Hill',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NC_2363',
    Name: 'Henderson',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_CA_2364', Name: 'Linda', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_CA_2365',
    Name: 'Agoura Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_2366',
    Name: 'East Hemet',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_2367',
    Name: 'Libertyville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_CO_2368', Name: 'Durango', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_NY_2369',
    Name: 'East Northport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_WI_2370', Name: 'Howard', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_OR_2371', Name: 'Monmouth', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MI_2372',
    Name: 'Ypsilanti',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IL_2373', Name: 'Mokena', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NJ_2374',
    Name: 'Pleasantville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_MO_2375', Name: 'Affton', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_FL_2376', Name: 'Bartow', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_KY_2377', Name: 'Ashland', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_CA_2378',
    Name: 'Mead Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_2379', Name: 'Miamisburg', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_NY_2380', Name: 'Merrick', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_IL_2381',
    Name: 'Charleston',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WI_2382',
    Name: 'Germantown',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MI_2383',
    Name: 'Escanaba',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_NY_2384', Name: 'Selden', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_LA_2385',
    Name: 'Bayou Cane',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_HI_2386',
    Name: 'Mililani Mauka',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_GA_2387',
    Name: 'Snellville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_LA_2388',
    Name: 'Sulphur',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NJ_2389',
    Name: 'Harrison',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MN_2390',
    Name: 'South St. Paul',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OH_2391', Name: 'Oregon', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_2392',
    Name: 'Middleton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MI_2393',
    Name: 'Ferndale',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_2394',
    Name: 'West Chester',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_GA_2395',
    Name: 'Forest Park',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CA_2396',
    Name: 'La Cañada Flintridge',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_2397',
    Name: 'Hauppauge',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AZ_2398', Name: 'Safford', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_OH_2399', Name: 'Rocky River', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_2400',
    Name: 'South San Jose Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_2401',
    Name: 'Elmwood Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OK_2402', Name: 'El Reno', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_ME_2403', Name: 'Saco', stateId: 'ME', stateName: 'Maine'},
  {
    Id: 'CIT_US_WA_2404',
    Name: 'Fairwood',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MS_2405',
    Name: 'Greenwood',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MN_2406',
    Name: 'West St. Paul',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AL_2407', Name: 'Valley', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_CA_2408',
    Name: 'Orinda',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AL_2409', Name: 'Helena', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_AL_2410', Name: 'Cullman', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_TX_2411', Name: 'Alamo', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OK_2412',
    Name: 'Sand Springs',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_UT_2413', Name: 'Vernal', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NC_2414',
    Name: 'Laurinburg',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OH_2415', Name: 'Painesville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OR_2416', Name: 'Sherwood', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_IL_2417',
    Name: 'Lake Zurich',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_2418',
    Name: 'West Hempstead',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MD_2419',
    Name: 'Rosedale',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_IL_2420', Name: 'Ottawa', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_VA_2421',
    Name: 'Meadowbrook',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_2422',
    Name: 'Casa de Oro-Mount Helix',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_2423',
    Name: 'Lake Shore',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_OH_2424',
    Name: 'Parma Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MO_2425',
    Name: 'Old Jamestown',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_2426', Name: 'Norwood', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_2427',
    Name: 'Monroe',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_AL_2428', Name: 'Selma', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_HI_2429', Name: 'Makakilo', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_OK_2430',
    Name: 'Tahlequah',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NY_2431',
    Name: 'Brockport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_2432', Name: 'Rockport', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_NV_2433', Name: 'Mesquite', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_VA_2434',
    Name: 'Franconia',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_VA_2435',
    Name: 'Front Royal',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_2436',
    Name: 'West Mifflin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_2437',
    Name: 'Albany',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_2438',
    Name: 'La Crescenta-Montrose',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AZ_2439', Name: 'Somerton', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_CA_2440',
    Name: 'Blythe',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_2441',
    Name: 'Horizon City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_2442',
    Name: 'Montgomery',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_2443',
    Name: 'Stillwater',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AZ_2444', Name: 'Eloy', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_UT_2445',
    Name: 'Brigham City',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_PA_2446',
    Name: 'Murrysville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_2447',
    Name: 'Brainerd',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AZ_2448', Name: 'Rio Rico', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_RI_2449',
    Name: 'Central Falls',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {Id: 'CIT_US_MI_2450', Name: 'Haslett', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_TN_2451',
    Name: 'Tullahoma',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_2452',
    Name: 'Baldwin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_2453', Name: 'Anna', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KY_2454',
    Name: 'Danville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MD_2455',
    Name: 'Langley Park',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_VT_2456',
    Name: 'South Burlington',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_IA_2457',
    Name: 'North Liberty',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_FL_2458',
    Name: 'Lake Butler',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_HI_2459',
    Name: 'Schofield Barracks',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_IN_2460',
    Name: 'Vincennes',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_TX_2461',
    Name: 'Sienna Plantation',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NY_2462',
    Name: 'Holtsville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_LA_2463',
    Name: 'Abbeville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_IL_2464',
    Name: 'Bourbonnais',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_NY_2465', Name: 'Syosset', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_IL_2466',
    Name: 'Matteson',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_2467',
    Name: 'Lynbrook',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_2468',
    Name: 'Lake Forest',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_2469', Name: 'Angleton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_2470',
    Name: 'Castaic',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OR_2471', Name: 'Canby', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NC_2472',
    Name: 'Wendell',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_2473',
    Name: 'Frankfort',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NH_2474',
    Name: 'Laconia',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_CO_2475',
    Name: 'Silverthorne',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_OR_2476', Name: 'Altamont', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_WY_2477', Name: 'Sheridan', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_CA_2478',
    Name: 'Hermosa Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OK_2479', Name: 'Durant', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_OH_2480', Name: 'Sylvania', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_NY_2481', Name: 'Corning', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_MD_2482', Name: 'Arbutus', stateId: 'MD', stateName: 'Maryland'},
  {Id: 'CIT_US_OH_2483', Name: 'White Oak', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NJ_2484',
    Name: 'Morristown',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_2485',
    Name: 'Pinole',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_2486',
    Name: 'Erlanger',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IA_2487', Name: 'Altoona', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OK_2488', Name: 'Bethany', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CO_2489',
    Name: 'Sherrelwood',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_NY_2490', Name: 'Mineola', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_PA_2491',
    Name: 'Carlisle',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_2492',
    Name: 'Stevenson Ranch',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_2493', Name: 'Highland', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_KS_2494', Name: 'Liberal', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_CA_2495',
    Name: 'Cameron Park',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_2496',
    Name: 'Pinecrest',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_2497',
    Name: 'Evergreen Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_2498', Name: 'Tiffin', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_2499',
    Name: 'Mount Airy',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OH_2500',
    Name: 'Broadview Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_ME_2501', Name: 'Westbrook', stateId: 'ME', stateName: 'Maine'},
  {
    Id: 'CIT_US_CA_2502',
    Name: 'Red Bluff',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_FL_2503', Name: 'Arcadia', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MD_2504',
    Name: 'White Oak',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WI_2505',
    Name: 'Fox Crossing',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_2506',
    Name: 'McKeesport',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_2507', Name: 'Bellaire', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AL_2508', Name: 'Troy', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_WI_2509',
    Name: 'Onalaska',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NC_2510',
    Name: 'Lexington',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OH_2511', Name: 'Springboro', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_2512', Name: 'Whitehall', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MN_2513',
    Name: 'Sartell',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AK_2514', Name: 'Badger', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_WA_2515',
    Name: 'Five Corners',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_OK_2516',
    Name: 'McAlester',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_TN_2517',
    Name: 'La Follette',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_TX_2518', Name: 'Terrell', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_LA_2519',
    Name: 'Natchitoches',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OH_2520', Name: 'Twinsburg', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OR_2521',
    Name: 'Central Point',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_FL_2522', Name: 'Seminole', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MI_2523',
    Name: 'Coldwater',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IN_2524', Name: 'St. John', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NJ_2525',
    Name: 'Point Pleasant',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WA_2526',
    Name: 'Grandview',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_2527',
    Name: 'Marshfield',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NJ_2528',
    Name: 'Hawthorne',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CO_2529',
    Name: 'Cimarron Hills',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_IL_2530', Name: 'Alsip', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_IN_2531', Name: 'Avon', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_IL_2532',
    Name: 'Homewood',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_2533',
    Name: 'Port Isabel',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_2534',
    Name: 'Bellwood',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WI_2535',
    Name: 'Marinette',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_2536', Name: 'Bay City', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_2537',
    Name: 'North Valley Stream',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CT_2538',
    Name: 'Ansonia',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_IL_2539',
    Name: 'Deerfield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MD_2540',
    Name: 'Westminster',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MO_2541',
    Name: 'Creve Coeur',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MD_2542',
    Name: 'Seabrook',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OH_2543', Name: 'Berea', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_FL_2544', Name: 'Cocoa', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_GA_2545', Name: 'Moultrie', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NJ_2546',
    Name: 'Avenel',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OH_2547', Name: 'Forest Park', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_VA_2548', Name: 'Sudley', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_WA_2549',
    Name: 'Anacortes',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MA_2550',
    Name: 'Winthrop',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_CA_2551',
    Name: 'Rancho Mirage',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_2552',
    Name: 'Gibsonton',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WA_2553',
    Name: 'Lynden',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NY_2554',
    Name: 'North Massapequa',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MS_2555',
    Name: 'Gautier',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_OH_2556',
    Name: 'Mayfield Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_LA_2557',
    Name: 'Breaux Bridge',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MN_2558',
    Name: 'Hopkins',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OH_2559', Name: 'Defiance', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MI_2560',
    Name: 'Iron Mountain',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_KY_2561', Name: 'Berea', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_OK_2562', Name: 'Altus', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_WA_2563',
    Name: 'Bryn Mawr-Skyway',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_TX_2564', Name: 'Uvalde', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TX_2565',
    Name: 'Brushy Creek',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OH_2566', Name: 'Brook Park', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_2567',
    Name: 'North Amityville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CT_2568',
    Name: 'Wallingford Center',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_WA_2569',
    Name: 'Sunnyside',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_FL_2570',
    Name: 'World Golf Village',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_2571',
    Name: 'Sycamore',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_VA_2572',
    Name: 'Franklin Farm',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_2573',
    Name: 'Chowchilla',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_2574',
    Name: 'Brookfield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_2575',
    Name: 'Ronkonkoma',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NJ_2576',
    Name: 'Rutherford',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_VA_2577',
    Name: 'Merrifield',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MA_2578',
    Name: 'Newburyport',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_VA_2579',
    Name: 'Idylwood',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_2580', Name: 'Kilgore', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_VA_2581', Name: 'Radford', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_TX_2582', Name: 'Palestine', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IN_2583',
    Name: 'Crawfordsville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_TN_2584',
    Name: 'Springfield',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_TX_2585', Name: 'Boerne', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MD_2586',
    Name: 'Hyattsville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_FL_2587',
    Name: 'Lake Placid',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MN_2588',
    Name: 'St. Michael',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AR_2589',
    Name: 'Maumelle',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_OH_2590', Name: 'Niles', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NJ_2591',
    Name: 'Iselin',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WI_2592',
    Name: 'Menomonie',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MI_2593', Name: 'Trenton', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_VT_2594', Name: 'Rutland', stateId: 'VT', stateName: 'Vermont'},
  {
    Id: 'CIT_US_CA_2595',
    Name: 'Imperial',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_2596', Name: 'Orange', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MN_2597', Name: 'Otsego', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_IN_2598', Name: 'Madison', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_KY_2599',
    Name: 'St. Matthews',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_TX_2600', Name: 'Alton', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_WI_2601', Name: 'Cudahy', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_IL_2602',
    Name: 'Round Lake',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MD_2603',
    Name: 'Oxon Hill',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MO_2604',
    Name: 'Manchester',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_2605', Name: 'Beeville', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_2606', Name: 'Cary', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NC_2607',
    Name: 'North Wilkesboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_2608',
    Name: 'North Aurora',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_GA_2609',
    Name: 'Lithia Springs',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_VA_2610', Name: 'Bon Air', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_IL_2611',
    Name: 'Bensenville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_2612', Name: 'Eastlake', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_2613',
    Name: 'Beaver Dam',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_FL_2614',
    Name: 'Safety Harbor',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IL_2615', Name: 'Mattoon', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_IL_2616', Name: 'Macomb', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_GA_2617',
    Name: 'North Druid Hills',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_SC_2618',
    Name: 'West Columbia',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_GA_2619',
    Name: 'Fayetteville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NM_2620',
    Name: 'Sunland Park',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_LA_2621',
    Name: 'Estelle',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_2622',
    Name: 'Mountain House',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_2623',
    Name: 'San Carlos Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_LA_2624',
    Name: 'Zachary',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_GA_2625',
    Name: 'Kingsland',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_FL_2626',
    Name: 'Marco Island',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IN_2627',
    Name: 'Huntington',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_TX_2628', Name: 'Ingleside', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PR_2629',
    Name: 'Humacao',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_NY_2630', Name: 'Wantagh', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_NC_2631',
    Name: 'Lenoir',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_MO_2632', Name: 'Concord', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_MD_2633', Name: 'Easton', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_VA_2634',
    Name: 'Burke Centre',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_GA_2635', Name: 'Perry', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MO_2636',
    Name: 'Hannibal',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WI_2637',
    Name: 'Menasha',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OR_2638', Name: 'Oak Grove', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NY_2639',
    Name: 'Scarsdale',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_2640', Name: 'Brenham', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_2641',
    Name: 'Ocean Springs',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OR_2642', Name: 'Dallas', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_CA_2643',
    Name: 'Woodcrest',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_2644',
    Name: 'White Settlement',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_SC_2645',
    Name: 'Five Forks',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NC_2646',
    Name: 'Knightdale',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_2647',
    Name: 'Shenandoah',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OK_2648', Name: 'Ada', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_TN_2649',
    Name: 'Crossville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MD_2650',
    Name: 'Beltsville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NJ_2651',
    Name: 'Colonia',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_2652',
    Name: 'Moraga',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_FL_2653', Name: 'Midway', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_OH_2654',
    Name: 'Steubenville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_FL_2655', Name: 'Tavares', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_MT_2656', Name: 'Belgrade', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_MD_2657',
    Name: 'Takoma Park',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_NJ_2658', Name: 'Dover', stateId: 'NJ', stateName: 'New Jersey'},
  {
    Id: 'CIT_US_NY_2659',
    Name: 'Lackawanna',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_2660',
    Name: 'Melville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_HI_2661', Name: 'Wailuku', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_VA_2662',
    Name: 'Buckhall',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_AR_2663',
    Name: 'El Dorado',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CA_2664',
    Name: 'Valle Vista',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OR_2665',
    Name: 'Cedar Mill',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MN_2666',
    Name: 'Albert Lea',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NJ_2667',
    Name: 'Madison',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_FL_2668', Name: 'Maitland', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_LA_2669', Name: 'Gretna', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_CA_2670',
    Name: 'Greenfield',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_2671',
    Name: 'Hinsdale',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_2672',
    Name: 'Santa Fe Springs',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_2673',
    Name: 'Franklin Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_2674',
    Name: 'Bethpage',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_2675',
    Name: 'East Massapequa',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_2676',
    Name: 'Kirksville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_2677',
    Name: 'Nipomo',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_2678', Name: 'Centerville', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NC_2679',
    Name: 'Butner',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OH_2680', Name: 'Salem', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AL_2681',
    Name: 'Tillmans Corner',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_FL_2682',
    Name: 'Upper Grand Lagoon',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_MN_2683', Name: 'Anoka', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_FL_2684', Name: 'Pinewood', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_VA_2685', Name: 'Laurel', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_MA_2686',
    Name: 'Amesbury',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_IL_2687', Name: 'Marion', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_2688', Name: 'Tallmadge', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_VA_2689',
    Name: 'Kingstowne',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_NY_2690', Name: 'Nanuet', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_NJ_2691',
    Name: 'Dumont',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MD_2692',
    Name: 'Maryland City',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_IL_2693',
    Name: 'Shorewood',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_2694',
    Name: 'Vero Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_SC_2695',
    Name: 'Clemson',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_FL_2696',
    Name: 'Country Walk',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MO_2697',
    Name: 'Spanish Lake',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_FL_2698',
    Name: 'Lake Mary',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_VA_2699',
    Name: 'Manassas Park',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NY_2700',
    Name: 'Woodmere',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_2701',
    Name: 'Lakeport',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AK_2702',
    Name: 'Knik-Fairview',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_WV_2703',
    Name: 'Martinsburg',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NJ_2704',
    Name: 'Tinton Falls',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_TX_2705',
    Name: 'South Houston',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_MI_2706', Name: 'Jenison', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_MD_2707', Name: 'Redland', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_KY_2708',
    Name: 'Burlington',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IL_2709', Name: 'Godfrey', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OR_2710', Name: 'Pendleton', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_TX_2711', Name: 'Taylor', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TX_2712',
    Name: 'Pecan Grove',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_2713', Name: 'Nederland', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_2714',
    Name: 'Colonial Heights',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_2715', Name: 'Stafford', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CT_2716',
    Name: 'Willimantic',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_NJ_2717',
    Name: 'Ocean Acres',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_TX_2718', Name: 'La Marque', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KY_2719',
    Name: 'Versailles',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IL_2720', Name: 'Morris', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_IL_2721', Name: 'Lemont', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_VA_2722',
    Name: 'Gainesville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_2723',
    Name: 'Sayre',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_2724', Name: 'Portland', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_2725',
    Name: 'Lindenwold',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IA_2726', Name: 'Clive', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_NY_2727', Name: 'Oneonta', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_PA_2728',
    Name: 'Shippensburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_2729', Name: 'Pampa', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_2730',
    Name: 'McKinleyville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_2731',
    Name: 'Ferndale',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_UT_2732', Name: 'South Ogden', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NC_2733',
    Name: 'Albemarle',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_2734',
    Name: 'Coalinga',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OH_2735',
    Name: 'North Canton',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NE_2736',
    Name: 'La Vista',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_WI_2737',
    Name: 'Ashwaubenon',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TN_2738',
    Name: 'Dickson',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MA_2739',
    Name: 'North Adams',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_NC_2740',
    Name: 'Waxhaw',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_2741',
    Name: 'Massapequa Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_2742',
    Name: 'North Babylon',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_HI_2743', Name: 'Wahiawa', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_TX_2744', Name: 'Bastrop', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_ID_2745', Name: 'Ammon', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_WA_2746', Name: 'Yelm', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_PA_2747',
    Name: 'Lansdale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MD_2748', Name: 'Parole', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_VA_2749',
    Name: 'Hybla Valley',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_FL_2750',
    Name: 'Glenvar Heights',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_2751',
    Name: 'Palos Hills',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_2752',
    Name: 'Brownsville',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WA_2753',
    Name: 'Camano',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_IL_2754',
    Name: 'Streator',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_2755',
    Name: 'Buffalo',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IL_2756', Name: 'Lincoln', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_AL_2757',
    Name: 'Sylacauga',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_WI_2758',
    Name: 'Oconomowoc',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_VA_2759',
    Name: 'Fort Hunt',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_2760',
    Name: 'Phoenixville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OH_2761',
    Name: 'East Cleveland',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_ID_2762',
    Name: 'Mountain Home',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {Id: 'CIT_US_GA_2763', Name: 'Americus', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MO_2764',
    Name: 'Republic',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MD_2765',
    Name: 'Glassmanor',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_MO_2766', Name: 'Lemay', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_TX_2767', Name: 'Buda', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_2768',
    Name: 'Roosevelt',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MI_2769',
    Name: 'Cutlerville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MD_2770',
    Name: 'Calverton',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_TX_2771',
    Name: 'Gainesville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MA_2772',
    Name: 'Southbridge',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_FL_2773',
    Name: 'Myrtle Grove',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MO_2774',
    Name: 'Lake St. Louis',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_2775', Name: 'Seagoville', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_2776',
    Name: 'Crescent City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_2777',
    Name: 'Live Oak',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MS_2778',
    Name: 'Picayune',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CA_2779',
    Name: 'Rosedale',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_2780',
    Name: 'North Myrtle Beach',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_MI_2781', Name: 'Wayne', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MN_2782',
    Name: 'Bemidji',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TN_2783',
    Name: 'Goodlettsville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MN_2784',
    Name: 'Ham Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_GA_2785', Name: 'Fairburn', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_VA_2786', Name: 'Bristol', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_FL_2787',
    Name: 'Lake Wales',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_MO_2788', Name: 'Clayton', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_FL_2789',
    Name: 'New Port Richey',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_2790',
    Name: 'Truckee',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_2791',
    Name: 'Lindsay',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_GA_2792',
    Name: 'North Decatur',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_NY_2793', Name: 'Cohoes', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_MS_2794',
    Name: 'McComb',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_TX_2795',
    Name: 'Highland Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MD_2796',
    Name: 'Glenmont',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_VA_2797',
    Name: 'Bull Run',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TN_2798', Name: 'Athens', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_FL_2799',
    Name: 'Auburndale',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_2800',
    Name: 'El Segundo',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_2801', Name: 'Mercedes', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_2802',
    Name: 'Bostonia',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_2803',
    Name: 'Artesia',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_GA_2804', Name: 'Norcross', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_PR_2805',
    Name: 'Yauco',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_NY_2806', Name: 'Batavia', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_NC_2807',
    Name: 'Morganton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_2808',
    Name: 'Harrisburg',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_2809',
    Name: 'Murraysville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_LA_2810',
    Name: 'Donaldsonville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_2811',
    Name: 'Solvang',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_2812',
    Name: 'Los Osos',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VA_2813',
    Name: 'Groveton',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IL_2814',
    Name: 'Washington',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_2815', Name: 'Live Oak', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_2816',
    Name: 'Lock Haven',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_VA_2817', Name: 'Vienna', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_IL_2818',
    Name: 'Country Club Hills',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_2819', Name: 'Streetsboro', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IN_2820', Name: 'Jasper', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NC_2821',
    Name: 'Eden',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_2822',
    Name: 'Pearl River',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AZ_2823', Name: 'Douglas', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_TX_2824', Name: 'Crowley', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_2825',
    Name: 'Highland Springs',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_TX_2826',
    Name: 'Cinco Ranch',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_VA_2827',
    Name: 'Wolf Trap',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_OK_2828',
    Name: 'Chickasha',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NJ_2829',
    Name: 'New Milford',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NY_2830',
    Name: 'Kings Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_FL_2831',
    Name: 'Groveland',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_FL_2832', Name: 'Ojus', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MD_2833',
    Name: 'East Riverdale',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_TX_2834',
    Name: 'Mount Pleasant',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WI_2835',
    Name: 'River Falls',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_OR_2836',
    Name: 'Four Corners',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MS_2837',
    Name: 'Hernando',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MD_2838',
    Name: 'Hillcrest Heights',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_CA_2839', Name: 'Ripon', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_GA_2840', Name: 'Monroe', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_MD_2841', Name: 'Adelphi', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_CA_2842',
    Name: 'Rio Linda',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_2843', Name: 'Bluffdale', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_CT_2844',
    Name: 'Storrs',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_MI_2845',
    Name: 'Hazel Park',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_ID_2846', Name: 'Burley', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_OH_2847', Name: 'Aurora', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_2848', Name: 'Donna', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_2849',
    Name: 'Stanford',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_2850',
    Name: 'Red Wing',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_NV_2851', Name: 'Fallon', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_IL_2852',
    Name: 'Fairview Heights',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_2853', Name: 'Celina', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TX_2854',
    Name: 'Gun Barrel City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IL_2855', Name: 'Morton', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NY_2856',
    Name: 'Port Jervis',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_SC_2857',
    Name: 'Red Hill',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_WI_2858',
    Name: 'Kaukauna',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_2859', Name: 'Addison', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KY_2860',
    Name: 'Fort Thomas',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NC_2861',
    Name: 'Mebane',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_2862',
    Name: 'Mount Holly',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_GA_2863', Name: 'Conyers', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CA_2864',
    Name: 'East San Gabriel',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AR_2865',
    Name: 'Centerton',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_FL_2866', Name: 'Stuart', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MO_2867',
    Name: 'Sikeston',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TX_2868',
    Name: 'Sulphur Springs',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CA_2869',
    Name: 'Vincent',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NV_2870',
    Name: 'Boulder City',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_IL_2871',
    Name: 'Centralia',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IL_2872', Name: 'Dixon', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OR_2873', Name: 'Troutdale', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MI_2874',
    Name: 'Mount Clemens',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_OH_2875',
    Name: 'Fairview Park',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CA_2876',
    Name: 'Discovery Bay',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_2877',
    Name: 'Stallings',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MN_2878',
    Name: 'Cambridge',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IN_2879',
    Name: 'Frankfort',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IL_2880',
    Name: 'Westchester',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AL_2881',
    Name: 'Center Point',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_TX_2882', Name: 'Canyon', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_UT_2883', Name: 'West Haven', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_WA_2884',
    Name: 'Washougal',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_IL_2885',
    Name: 'Bridgeview',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MO_2886', Name: 'Troy', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_CA_2887',
    Name: 'Cherryland',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IN_2888', Name: 'Lebanon', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_IN_2889', Name: 'Griffith', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_GA_2890',
    Name: 'Villa Rica',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_MI_2891', Name: 'Alma', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MO_2892',
    Name: 'Fort Leonard Wood',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MS_2893',
    Name: 'Long Beach',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_FL_2894',
    Name: 'Lady Lake',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MD_2895',
    Name: 'Aberdeen',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_CO_2896', Name: 'Welby', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_IA_2897', Name: 'Indianola', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NC_2898',
    Name: 'Washington',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_2899',
    Name: 'Phelan',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_2900', Name: 'Lakeway', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IN_2901', Name: 'Dyer', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NC_2902',
    Name: 'Archer Lodge',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_FL_2903',
    Name: 'Niceville',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_2904',
    Name: 'Clearlake',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_2905',
    Name: 'Fillmore',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OR_2906',
    Name: 'Hood River',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_WI_2907',
    Name: 'Bellevue',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NV_2908',
    Name: 'Spanish Springs',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_IN_2909',
    Name: 'New Haven',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AZ_2910',
    Name: 'New River',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NY_2911',
    Name: 'Sayville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AL_2912',
    Name: 'Millbrook',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_CA_2913',
    Name: 'Walnut Park',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_2914',
    Name: 'Carthage',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_2915',
    Name: 'Prospect Heights',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_2916',
    Name: 'King City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_2917',
    Name: 'Opa-locka',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_2918', Name: 'Pataskala', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_FL_2919', Name: 'Sunset', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MI_2920',
    Name: 'Grandville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MN_2921',
    Name: 'Hibbing',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_FL_2922',
    Name: 'Sarasota Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_2923',
    Name: 'Laguna Woods',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_2924',
    Name: 'Hope Mills',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_2925',
    Name: 'Floral Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AZ_2926',
    Name: 'Sierra Vista Southeast',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WA_2927',
    Name: 'White Center',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_GA_2928',
    Name: 'Wilmington Island',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MA_2929',
    Name: 'Easthampton',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_TX_2930', Name: 'Humble', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_2931',
    Name: 'Parkway',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AZ_2932', Name: 'Payson', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_NY_2933',
    Name: 'Port Washington',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OH_2934', Name: 'Circleville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_2935',
    Name: 'East Rancho Dominguez',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_2936',
    Name: 'Fullerton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CO_2937',
    Name: 'Sterling',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NJ_2938',
    Name: 'South River',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_GA_2939',
    Name: 'Powder Springs',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_AZ_2940',
    Name: 'Flowing Wells',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_VA_2941',
    Name: 'Purcellville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CO_2942',
    Name: 'Greenwood Village',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NY_2943',
    Name: 'Eggertsville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_2944',
    Name: 'Farmingville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_2945', Name: 'Andrews', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AR_2946',
    Name: 'Blytheville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_KY_2947', Name: 'Shively', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_NJ_2948',
    Name: 'North Arlington',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_SD_2949',
    Name: 'Mitchell',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_CA_2950',
    Name: 'McFarland',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_2951',
    Name: 'Graham',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_FL_2952',
    Name: 'Palmetto Estates',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AZ_2953',
    Name: 'Tanque Verde',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_MD_2954', Name: 'Elkton', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_CA_2955',
    Name: 'Parlier',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_2956',
    Name: 'Three Lakes',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_2957', Name: 'Fate', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_GA_2958',
    Name: 'Riverdale',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_AZ_2959',
    Name: 'Fort Mohave',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_ID_2960', Name: 'Chubbuck', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_TX_2961',
    Name: 'West University Place',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IA_2962', Name: 'Newton', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OK_2963', Name: 'Miami', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_TN_2964',
    Name: 'McMinnville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_FL_2965', Name: 'Longwood', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MO_2966',
    Name: 'Overland',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NC_2967',
    Name: 'Dunn',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MI_2968',
    Name: 'Houghton',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SC_2969',
    Name: 'Ladson',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_ID_2970', Name: 'Blackfoot', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_GA_2971', Name: 'Buford', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WI_2972',
    Name: 'Lake Geneva',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OH_2973', Name: 'Harrison', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OR_2974', Name: 'La Grande', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_VA_2975',
    Name: 'East Highland Park',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_VA_2976',
    Name: 'Glen Allen',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_SC_2977',
    Name: 'Seven Oaks',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_TX_2978', Name: 'Groves', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_NY_2979', Name: 'Mastic', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_PA_2980',
    Name: 'Hermitage',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MD_2981',
    Name: 'Cloverly',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_AL_2982',
    Name: 'Talladega',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_WI_2983',
    Name: 'Hartford',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_GA_2984',
    Name: 'Holly Springs',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_GA_2985',
    Name: 'Belvedere Park',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_ID_2986', Name: 'Hayden', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_OH_2987',
    Name: 'Middleburg Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CA_2988',
    Name: 'La Palma',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_2989',
    Name: 'Mount Vernon',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_2990',
    Name: 'Avocado Heights',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_2991',
    Name: 'Pacific Grove',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_2992',
    Name: 'Asbury Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_VA_2993', Name: 'Pulaski', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_MI_2994',
    Name: 'Northview',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NY_2995',
    Name: 'Bellmore',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_MI_2996', Name: 'Berkley', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_NJ_2997',
    Name: 'Washington',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NY_2998',
    Name: 'Westbury',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MI_2999',
    Name: 'Grosse Pointe Woods',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_AL_3000', Name: 'Hueytown', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_IL_3001',
    Name: 'La Grange',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_3002', Name: 'Aldine', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_3003', Name: 'Bradley', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_FL_3004',
    Name: 'Southchase',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_3005',
    Name: 'Wilkinsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_3006',
    Name: 'Kerman',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MN_3007', Name: 'Hugo', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_NJ_3008', Name: 'Fords', stateId: 'NJ', stateName: 'New Jersey'},
  {
    Id: 'CIT_US_MD_3009',
    Name: 'Damascus',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NY_3010',
    Name: 'South Farmingdale',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_3011',
    Name: 'Mineral Wells',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OH_3012', Name: 'Bay Village', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PR_3013',
    Name: 'Candelaria',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_WA_3014',
    Name: 'Elk Plain',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_WI_3015', Name: 'Weston', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_TX_3016', Name: 'Hereford', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_3017', Name: 'Galion', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_GA_3018',
    Name: 'College Park',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_SC_3019',
    Name: 'Dentsville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_GA_3020',
    Name: 'Grovetown',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_TX_3021', Name: 'Pleasanton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KS_3022',
    Name: 'Great Bend',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_SC_3023',
    Name: 'Gantt',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MD_3024',
    Name: 'Rossville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_FL_3025',
    Name: 'Englewood',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_3026',
    Name: 'West Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_ND_3027',
    Name: 'Jamestown',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NY_3028',
    Name: 'North New Hyde Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WA_3029',
    Name: 'West Richland',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_UT_3030', Name: 'Park City', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_FL_3031', Name: 'Oldsmar', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_OH_3032',
    Name: 'Washington Court House',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CA_3033',
    Name: 'Coto de Caza',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NY_3034', Name: 'Seaford', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_NY_3035', Name: 'Kenmore', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_NY_3036', Name: 'Depew', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_CA_3037',
    Name: 'Susanville',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_3038', Name: 'Vandalia', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_3039',
    Name: 'Willow Grove',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_HI_3040',
    Name: 'Ocean Pointe',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_CO_3041',
    Name: 'Fort Carson',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_IN_3042',
    Name: 'Beech Grove',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_TX_3043', Name: 'Hewitt', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_KY_3044', Name: 'Newport', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_CA_3045',
    Name: 'Tehachapi',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OR_3046', Name: 'Astoria', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_CA_3047', Name: 'Alamo', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_CA_3048', Name: 'Taft', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_WA_3049',
    Name: 'Ferndale',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_3050',
    Name: 'Livingston',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_3051',
    Name: 'Whitewater',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MS_3052',
    Name: 'Clarksdale',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_FL_3053',
    Name: 'West Lealman',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_3054',
    Name: 'Franklin Park',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_3055',
    Name: 'Ramsey',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_3056',
    Name: 'New Freedom',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_3057',
    Name: 'Alpine',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VA_3058',
    Name: 'Great Falls',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_SD_3059',
    Name: 'Yankton',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_OH_3060', Name: 'London', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_3061',
    Name: 'New Cassel',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WI_3062',
    Name: 'Salem Lakes',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MD_3063',
    Name: 'Brooklyn Park',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NY_3064',
    Name: 'Hampton Bays',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_MO_3065', Name: 'Jackson', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MS_3066',
    Name: 'Yazoo City',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_KY_3067',
    Name: 'Mount Washington',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_TX_3068',
    Name: 'Jacksonville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MD_3069',
    Name: 'California',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_IA_3070', Name: 'Grimes', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_FL_3071',
    Name: 'Bellair-Meadowbrook Terrace',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_3072',
    Name: 'Lamont',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MO_3073', Name: 'Lebanon', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_AR_3074',
    Name: 'Hot Springs Village',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_GA_3075',
    Name: 'St. Simons',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_KY_3076',
    Name: 'Campbellsville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IN_3077', Name: 'Bedford', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_FL_3078', Name: 'Nocatee', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NY_3079',
    Name: 'Tonawanda',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OH_3080', Name: 'Cambridge', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_3081',
    Name: 'Lemon Hill',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_3082',
    Name: 'Kemp Mill',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_AL_3083', Name: 'Calera', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_LA_3084',
    Name: 'Youngsville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_3085', Name: 'Royse City', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_3086',
    Name: 'Azalea Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_SC_3087',
    Name: 'Hartsville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OH_3088', Name: 'Worthington', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NM_3089',
    Name: 'Las Vegas',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_SD_3090',
    Name: 'Pierre',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NC_3091',
    Name: 'Southern Pines',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_3092',
    Name: 'Hershey',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SC_3093',
    Name: 'Berea',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_AL_3094', Name: 'Saraland', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_KS_3095', Name: 'El Dorado', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_TX_3096', Name: 'Manor', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_3097',
    Name: 'Williamstown',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_AZ_3098',
    Name: 'Paradise Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WI_3099',
    Name: 'Pewaukee',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_VA_3100',
    Name: 'Falls Church',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_FL_3101', Name: 'Gonzalez', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_WA_3102',
    Name: 'Port Orchard',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_FL_3103',
    Name: 'Forest City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AL_3104',
    Name: 'Muscle Shoals',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MO_3105',
    Name: 'Jennings',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_3106', Name: 'Henderson', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IN_3107',
    Name: 'Connersville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_HI_3108',
    Name: 'Royal Kunia',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_NJ_3109',
    Name: 'Metuchen',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MO_3110',
    Name: 'Grain Valley',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_FL_3111',
    Name: 'Mount Dora',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_KY_3112', Name: 'Glasgow', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_MI_3113', Name: 'Fraser', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_HI_3114', Name: 'Ewa Beach', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_AZ_3115',
    Name: 'Sun Lakes',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_MS_3116',
    Name: 'Corinth',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_AL_3117',
    Name: 'Hartselle',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_FL_3118',
    Name: 'Thonotosassa',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_3119', Name: 'Fostoria', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NJ_3120',
    Name: 'Tenafly',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_HI_3121', Name: 'Halawa', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_NM_3122',
    Name: 'Chaparral',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_AL_3123',
    Name: 'Scottsboro',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_FL_3124', Name: 'Lockhart', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_LA_3125',
    Name: 'Thibodaux',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MO_3126',
    Name: 'Washington',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NJ_3127',
    Name: 'Bradley Gardens',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NY_3128',
    Name: 'Greenlawn',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MN_3129',
    Name: 'Robbinsdale',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_FL_3130',
    Name: 'Clewiston',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_3131',
    Name: 'North Fair Oaks',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_3132',
    Name: 'Warren',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_3133',
    Name: 'Chippewa Falls',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_DE_3134',
    Name: 'Ocean View',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {Id: 'CIT_US_DE_3135', Name: 'Glasgow', stateId: 'DE', stateName: 'Delaware'},
  {Id: 'CIT_US_OH_3136', Name: 'Bridgetown', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_FL_3137',
    Name: 'Warrington',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PR_3138',
    Name: 'Cayey',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_IL_3139',
    Name: 'Midlothian',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MD_3140',
    Name: 'Summerfield',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_VA_3141', Name: 'Hollins', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_AR_3142',
    Name: 'Mountain Home',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_AL_3143',
    Name: 'Alexander City',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_SC_3144',
    Name: 'Camden',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_WA_3145',
    Name: 'Toppenish',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_AL_3146', Name: 'Ozark', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_GA_3147', Name: 'Douglas', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CA_3148',
    Name: 'North Auburn',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_3149',
    Name: 'Mill Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_3150',
    Name: 'Tamaqua',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_FL_3151', Name: 'Destin', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_3152',
    Name: 'Salida',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_3153',
    Name: 'Lewisville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_VA_3154',
    Name: 'Kings Park West',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_VA_3155',
    Name: 'Stone Ridge',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WA_3156',
    Name: 'East Wenatchee',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_IL_3157',
    Name: 'Lindenhurst',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NJ_3158',
    Name: 'Phillipsburg',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_GA_3159',
    Name: 'Covington',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NY_3160',
    Name: 'Jefferson Valley-Yorktown',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_GA_3161', Name: 'Rincon', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CA_3162',
    Name: 'California City',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NM_3163', Name: 'Taos', stateId: 'NM', stateName: 'New Mexico'},
  {
    Id: 'CIT_US_NJ_3164',
    Name: 'Fairview',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_3165',
    Name: 'Hopatcong',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_TX_3166', Name: 'Hidalgo', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MD_3167',
    Name: 'Cambridge',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_AL_3168',
    Name: 'Gardendale',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_IL_3169', Name: 'Antioch', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NY_3170',
    Name: 'Johnson City',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_3171',
    Name: 'Hawaiian Gardens',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_3172',
    Name: 'Hendersonville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_3173',
    Name: 'Norridge',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_3174', Name: 'Seabrook', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_LA_3175',
    Name: 'Crowley',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MN_3176',
    Name: 'Sauk Rapids',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WI_3177',
    Name: 'Greendale',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_3178', Name: 'Lockhart', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OH_3179',
    Name: 'New Franklin',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_AL_3180', Name: 'Chelsea', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_MI_3181', Name: 'Ionia', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_LA_3182',
    Name: 'Pineville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_FL_3183', Name: 'Iona', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MS_3184',
    Name: 'Cleveland',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_3185',
    Name: 'Greensburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_3186',
    Name: 'Middleburg',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_3187', Name: 'Willowick', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AR_3188',
    Name: 'Harrison',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_IN_3189',
    Name: 'Chesterton',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CO_3190',
    Name: 'Steamboat Springs',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CO_3191',
    Name: 'Black Forest',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_AL_3192',
    Name: 'Fort Payne',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NJ_3193',
    Name: 'Cherry Hill Mall',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_FL_3194',
    Name: 'Fruitville',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_3195',
    Name: 'Lakewood Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_FL_3196', Name: 'Callaway', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_OR_3197', Name: 'Oatfield', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_WI_3198',
    Name: 'Waunakee',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MI_3199', Name: 'Wixom', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_LA_3200', Name: 'Luling', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_MS_3201',
    Name: 'Canton',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_AL_3202',
    Name: 'Pell City',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_HI_3203', Name: 'Waimalu', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_TX_3204', Name: 'Pecos', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_3205',
    Name: 'Bay St. Louis',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_LA_3206',
    Name: 'Belle Chasse',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MD_3207',
    Name: 'Havre de Grace',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OH_3208', Name: 'Monroe', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MS_3209',
    Name: "D'Iberville",
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_SC_3210',
    Name: 'Cayce',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_IL_3211',
    Name: 'Effingham',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_UT_3212', Name: 'Santaquin', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_FL_3213',
    Name: 'Hobe Sound',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_FL_3214', Name: 'Wauchula', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MD_3215',
    Name: 'Colesville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_3216', Name: 'Dayton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NC_3217',
    Name: 'Reidsville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MN_3218',
    Name: 'Hutchinson',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_GA_3219', Name: 'Dallas', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NJ_3220',
    Name: 'Franklin Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_NY_3221', Name: 'Beacon', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_TX_3222', Name: 'Dumas', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TX_3223',
    Name: 'Raymondville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CO_3224',
    Name: 'Frederick',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_FL_3225',
    Name: 'Olympia Heights',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NM_3226',
    Name: 'Artesia',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MN_3227',
    Name: 'North Mankato',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_OK_3228',
    Name: 'Glenpool',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_PA_3229',
    Name: 'Oil City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_3230',
    Name: 'Hammonton',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_AZ_3231',
    Name: 'New Kingman-Butler',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_IL_3232',
    Name: 'Chicago Ridge',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NM_3233',
    Name: 'Deming',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MI_3234',
    Name: 'Big Rapids',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_FL_3235',
    Name: 'Miami Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_3236', Name: 'Fulshear', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_3237',
    Name: 'South Venice',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WI_3238',
    Name: 'Allouez',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_3239', Name: 'Princeton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_3240',
    Name: 'Baraboo',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_NY_3241', Name: 'Jericho', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_NJ_3242',
    Name: 'Collingswood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_3243',
    Name: 'El Sobrante',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_3244', Name: 'Cahokia', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NJ_3245',
    Name: 'Springdale',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NC_3246',
    Name: 'Oak Island',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_LA_3247',
    Name: 'Gramercy',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_FL_3248',
    Name: 'Atlantic Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WV_3249',
    Name: 'Teays Valley',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_3250',
    Name: 'Ephrata',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_3251',
    Name: 'Oak Park',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_GA_3252',
    Name: 'Richmond Hill',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_VA_3253',
    Name: 'Brandermill',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_AR_3254',
    Name: 'Forrest City',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CA_3255',
    Name: 'Sun Village',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_3256',
    Name: 'Moorestown-Lenola',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MN_3257',
    Name: 'Fergus Falls',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WI_3258',
    Name: 'Whitefish Bay',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OH_3259', Name: 'Bexley', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_3260', Name: 'Conneaut', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_GA_3261',
    Name: 'Thomaston',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_TX_3262', Name: 'Levelland', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_FL_3263', Name: 'Palmetto', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MI_3264',
    Name: 'Harper Woods',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NC_3265',
    Name: 'Piney Green',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_KY_3266',
    Name: 'Fort Campbell North',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NJ_3267',
    Name: 'Princeton Meadows',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_3268',
    Name: 'Highland Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_IL_3269',
    Name: 'Hickory Hills',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_3270',
    Name: 'Forest Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_3271',
    Name: 'Beach Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_3272',
    Name: 'East Milton',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_3273', Name: 'Sharonville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NJ_3274',
    Name: 'West Freehold',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_3275',
    Name: 'Middlesex',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NV_3276',
    Name: 'Spring Creek',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_NY_3277',
    Name: 'St. James',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OH_3278', Name: 'Maumee', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_KY_3279',
    Name: 'Lawrenceburg',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NC_3280',
    Name: 'Brevard',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TN_3281',
    Name: 'Soddy-Daisy',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_MO_3282', Name: 'Moberly', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_OH_3283',
    Name: 'Port Clinton',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_IL_3284', Name: 'Canton', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_HI_3285', Name: 'Waianae', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_MN_3286',
    Name: 'Vadnais Heights',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_3287',
    Name: 'Wood Dale',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_3288', Name: 'Brecksville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NJ_3289',
    Name: 'New Providence',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_3290',
    Name: 'Roselle Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IL_3291', Name: 'Shiloh', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_IL_3292', Name: 'Rantoul', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_PA_3293',
    Name: 'Colonial Park',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_3294',
    Name: 'Broadlands',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IN_3295',
    Name: 'Washington',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IL_3296',
    Name: 'Hazel Crest',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_UT_3297', Name: 'Price', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_FL_3298', Name: 'LaBelle', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_MI_3299', Name: 'Alpena', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_FL_3300', Name: 'Conway', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MD_3301',
    Name: 'Kettering',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NY_3302',
    Name: 'Rocky Point',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TN_3303',
    Name: 'Elizabethton',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_IL_3304',
    Name: 'Wauconda',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_3305',
    Name: 'Lake Forest Park',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_3306',
    Name: 'Avenal',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MN_3307', Name: 'Rogers', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_MN_3308',
    Name: 'Marshall',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_FL_3309',
    Name: 'Jensen Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_CO_3310', Name: 'Fruita', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_FL_3311',
    Name: 'Homosassa Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_KY_3312',
    Name: 'Middlesborough',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_TX_3313', Name: 'Red Oak', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_3314',
    Name: 'Olivehurst',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_3315',
    Name: 'Big Bear City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_3316',
    Name: 'Grover Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_3317',
    Name: 'Marion',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_3318',
    Name: 'Fortuna',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_3319', Name: 'Santa Fe', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NM_3320',
    Name: 'North Valley',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_TX_3321', Name: 'Lindale', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_3322', Name: 'Englewood', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_3323',
    Name: 'Lakeland South',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_3324',
    Name: 'Washington',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AL_3325', Name: 'Jasper', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NJ_3326',
    Name: 'Short Hills',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_SD_3327',
    Name: 'Huron',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MI_3328',
    Name: 'Sault Ste. Marie',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_KS_3329', Name: 'Andover', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_DE_3330',
    Name: 'Brookside',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_PA_3331',
    Name: 'South Park Township',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_GA_3332',
    Name: 'Mountain Park',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CA_3333',
    Name: 'Carpinteria',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_3334',
    Name: 'Glenn Heights',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OH_3335', Name: 'Powell', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_3336', Name: 'Lyndhurst', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_3337',
    Name: 'Artondale',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NJ_3338',
    Name: 'Edgewater',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MD_3339',
    Name: 'Glenn Dale',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_IL_3340',
    Name: 'Western Springs',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OK_3341',
    Name: 'Okmulgee',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_LA_3342',
    Name: 'DeRidder',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_3343', Name: 'Azle', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_GA_3344', Name: 'Vidalia', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_IL_3345', Name: 'Swansea', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MS_3346',
    Name: 'Moss Point',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CO_3347',
    Name: 'Fort Morgan',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MO_3348',
    Name: 'Dardenne Prairie',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_LA_3349',
    Name: 'River Ridge',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_VA_3350',
    Name: 'Huntington',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_NY_3351', Name: 'Newark', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_MN_3352',
    Name: 'Mounds View',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_GA_3353',
    Name: 'Jefferson',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MI_3354',
    Name: 'Rochester',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_3355',
    Name: 'Solana Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_3356',
    Name: 'Nesconset',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_3357',
    Name: 'Richton Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_3358',
    Name: 'Palos Verdes Estates',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WA_3359',
    Name: 'Woodinville',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NY_3360',
    Name: 'Manorville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OH_3361',
    Name: 'Bellefontaine',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_VA_3362',
    Name: 'Timberlake',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IL_3363',
    Name: 'Channahon',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SC_3364',
    Name: 'Port Royal',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_WI_3365', Name: 'Verona', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_MD_3366',
    Name: 'Bensville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OH_3367', Name: 'Clayton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_3368',
    Name: 'East Islip',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MN_3369',
    Name: 'New Ulm',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_VA_3370',
    Name: 'Newington',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_LA_3371', Name: 'Baker', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_IN_3372',
    Name: 'Cedar Lake',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_AZ_3373', Name: 'Globe', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_IL_3374',
    Name: 'La Grange Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_3375',
    Name: 'Newton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_3376',
    Name: 'Warrenville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_DE_3377',
    Name: 'Hockessin',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {Id: 'CIT_US_TX_3378', Name: 'Rendon', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_ID_3379', Name: 'Sandpoint', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_OH_3380', Name: 'Loveland', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_3381',
    Name: 'Shorewood',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OH_3382', Name: 'Trenton', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AZ_3383', Name: 'Coolidge', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_FL_3384',
    Name: 'North Palm Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WI_3385',
    Name: 'Stoughton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_VA_3386',
    Name: 'Mount Vernon',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_OH_3387',
    Name: 'Warrensville Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MN_3388',
    Name: 'Worthington',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_WI_3389', Name: 'Plover', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_IN_3390',
    Name: 'Martinsville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IL_3391', Name: 'Mahomet', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CO_3392',
    Name: 'Superior',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CO_3393',
    Name: 'Lone Tree',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PA_3394',
    Name: 'Montgomeryville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_3395',
    Name: 'South Daytona',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_3396',
    Name: 'Riverdale',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_3397', Name: 'Lumberton', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_FL_3398', Name: 'Villas', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_PA_3399',
    Name: 'Coatesville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AL_3400', Name: 'Moody', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_KS_3401', Name: 'McPherson', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WA_3402',
    Name: 'Fort Lewis',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NC_3403',
    Name: 'Davidson',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_3404',
    Name: 'Edgewood',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_3405',
    Name: 'Suamico',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_3406',
    Name: 'San Marino',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TN_3407',
    Name: 'Portland',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NY_3408',
    Name: 'Fort Drum',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IL_3409', Name: 'Chatham', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CA_3410',
    Name: 'Lakeland Village',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_3411',
    Name: 'Bellefonte',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_3412',
    Name: 'Forest Hill',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_AZ_3413', Name: 'Vail', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_FL_3414',
    Name: 'Cypress Lake',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_3415',
    Name: 'Gladeview',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_OH_3416',
    Name: 'Monfort Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_GA_3417',
    Name: 'Braselton',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_MI_3418', Name: 'Lapeer', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_VA_3419',
    Name: 'Sugarland Run',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_3420',
    Name: 'Dunmore',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_GA_3421',
    Name: 'Georgetown',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PA_3422',
    Name: 'Kittanning',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_3423', Name: 'Vinings', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_PA_3424',
    Name: 'Sharon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NH_3425',
    Name: 'Claremont',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_MD_3426',
    Name: 'New Carrollton',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NJ_3427',
    Name: 'Oakland',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_AZ_3428',
    Name: 'Verde Village',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NJ_3429',
    Name: 'Ridgefield Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_AL_3430', Name: 'Irondale', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_SC_3431',
    Name: 'Lake Wylie',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_MI_3432', Name: 'Sturgis', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_GA_3433',
    Name: 'Loganville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_IN_3434',
    Name: 'Lawrenceburg',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_WA_3435',
    Name: 'Lakeland North',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_OH_3436',
    Name: 'West Carrollton',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OH_3437', Name: 'Finneytown', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_NY_3438', Name: 'Ridge', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_IL_3439',
    Name: 'Glen Carbon',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_3440',
    Name: 'Key Biscayne',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MO_3441',
    Name: 'Marshall',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WA_3442',
    Name: 'Lake Tapps',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_SD_3443',
    Name: 'Spearfish',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_CO_3444',
    Name: 'Federal Heights',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_OR_3445', Name: 'Cornelius', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_GA_3446',
    Name: 'Cedartown',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MN_3447',
    Name: 'Cloquet',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_3448',
    Name: 'Weigelstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_3449',
    Name: 'Kingston',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_3450', Name: 'Lilburn', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NE_3451',
    Name: 'South Sioux City',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MO_3452',
    Name: 'Maryville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_3453',
    Name: 'Ardmore',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_3454',
    Name: 'Franklin',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_OH_3455',
    Name: 'University Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_AK_3456', Name: 'College', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_HI_3457', Name: 'Lahaina', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_WI_3458',
    Name: 'Glendale',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_3459',
    Name: 'Terryville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WA_3460',
    Name: 'Othello',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_AL_3461',
    Name: 'Gulf Shores',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_FL_3462',
    Name: 'Wilton Manors',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_FL_3463', Name: 'Yulee', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_TX_3464', Name: 'Athens', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_3465',
    Name: 'Jupiter Farms',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NC_3466',
    Name: 'Tarboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OH_3467', Name: 'Wickliffe', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_3468', Name: 'Elgin', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_3469',
    Name: 'Mercerville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_TX_3470', Name: 'La Homa', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_3471', Name: 'Dover', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_3472', Name: 'Greenville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_3473', Name: 'Borger', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_3474',
    Name: 'Bonita',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MI_3475',
    Name: 'Charlotte',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_LA_3476',
    Name: 'Broussard',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PR_3477',
    Name: 'Dorado',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_IL_3478', Name: 'Herrin', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_PR_3479',
    Name: 'Manatí',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_OK_3480', Name: 'Choctaw', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_TX_3481', Name: 'Manvel', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NM_3482',
    Name: 'Los Alamos',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_WI_3483', Name: 'Oregon', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_CA_3484',
    Name: 'Commerce',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_3485',
    Name: 'Taylorville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MD_3486',
    Name: 'Riviera Beach',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_TX_3487',
    Name: 'Port Neches',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_OR_3488',
    Name: 'Prineville',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_TN_3489',
    Name: 'Lakeland',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_IN_3490', Name: 'Peru', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_TN_3491',
    Name: 'White House',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_GA_3492',
    Name: 'Clarkston',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_IA_3493', Name: 'Keokuk', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MO_3494', Name: 'St. Ann', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_NY_3495',
    Name: 'Mastic Beach',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IL_3496', Name: 'Justice', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_FL_3497', Name: 'Elfers', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_MO_3498', Name: 'Fulton', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_FL_3499', Name: 'Minneola', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_IA_3500', Name: 'Oskaloosa', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MD_3501', Name: 'Urbana', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_CA_3502',
    Name: 'Grand Terrace',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_3503',
    Name: 'Panama City Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NJ_3504',
    Name: 'Woodland Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_FL_3505', Name: 'Lantana', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_TX_3506', Name: 'Richmond', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_WY_3507', Name: 'Jackson', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_TX_3508',
    Name: 'Fredericksburg',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CA_3509',
    Name: 'Muscoy',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_3510',
    Name: 'Belmont',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MD_3511',
    Name: 'Annapolis Neck',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_AL_3512',
    Name: 'Jacksonville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NY_3513',
    Name: 'Endicott',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WA_3514',
    Name: 'Cheney',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_IL_3515',
    Name: 'Palos Heights',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_3516', Name: 'Boone', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MN_3517',
    Name: 'North St. Paul',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MD_3518',
    Name: 'Ocean Pines',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CO_3519',
    Name: 'Cherry Creek',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PR_3520',
    Name: 'Río Grande',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_KY_3521', Name: 'Paris', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_SC_3522',
    Name: 'Irmo',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CA_3523',
    Name: 'San Anselmo',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_3524',
    Name: 'Marysville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MI_3525',
    Name: 'Woodhaven',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OH_3526', Name: 'Bedford', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TX_3527',
    Name: 'Trophy Club',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_WY_3528', Name: 'Riverton', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_KY_3529',
    Name: 'Shepherdsville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IN_3530', Name: 'Plymouth', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_TN_3531',
    Name: 'Manchester',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MN_3532',
    Name: 'Waconia',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NY_3533',
    Name: 'Stony Brook',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MN_3534',
    Name: 'St. Peter',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_WA_3535', Name: 'Kelso', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_WI_3536',
    Name: 'Elkhorn',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_3537', Name: 'Gatesville', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_3538',
    Name: 'Goldenrod',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MN_3539',
    Name: 'Virginia',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AZ_3540',
    Name: 'Chino Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_VA_3541',
    Name: 'Lakeside',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_VA_3542',
    Name: 'Newington Forest',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_OH_3543', Name: 'Blue Ash', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TN_3544',
    Name: 'Lewisburg',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_OH_3545', Name: 'Wilmington', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_3546',
    Name: 'Harrison',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_FL_3547',
    Name: 'Holly Hill',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_VA_3548',
    Name: 'Lansdowne',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WA_3549',
    Name: 'Minnehaha',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MI_3550',
    Name: 'New Baltimore',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_FL_3551', Name: 'Gulfport', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MD_3552',
    Name: 'Forestville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CT_3553',
    Name: 'Derby',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_IL_3554', Name: 'Kewanee', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_FL_3555',
    Name: 'Orange City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_3556',
    Name: 'Lakeland Highlands',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_LA_3557',
    Name: 'Bastrop',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OR_3558', Name: 'Gladstone', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NY_3559',
    Name: 'Patchogue',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_3560',
    Name: 'Winnetka',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IL_3561', Name: 'Markham', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_AR_3562', Name: 'Marion', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_OR_3563', Name: 'Oak Hills', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_TX_3564',
    Name: 'Leon Valley',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MD_3565',
    Name: 'Joppatowne',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MO_3566',
    Name: 'West Plains',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_CA_3567', Name: 'Delhi', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_TN_3568',
    Name: 'Winchester',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WA_3569',
    Name: 'Newcastle',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_3570',
    Name: 'New Kensington',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NE_3571',
    Name: 'Beatrice',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_VA_3572',
    Name: 'Poquoson',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_3573',
    Name: 'Clayton',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_3574',
    Name: 'St. Marys',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KS_3575', Name: 'Ottawa', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_CA_3576',
    Name: 'Larkspur',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AZ_3577',
    Name: 'Cottonwood',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_MD_3578',
    Name: 'Mays Chapel',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_FL_3579',
    Name: 'Shady Hills',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_3580',
    Name: 'Lincolnwood',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_KS_3581',
    Name: 'Arkansas City',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_NC_3582',
    Name: 'Elon',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MA_3583',
    Name: 'Palmer',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_LA_3584',
    Name: 'West Monroe',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_SC_3585',
    Name: 'Parker',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_AR_3586',
    Name: 'Batesville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_OH_3587', Name: 'Amherst', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MS_3588',
    Name: 'Grenada',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MI_3589',
    Name: 'Ishpeming',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_GA_3590', Name: 'Jesup', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NJ_3591',
    Name: 'Ringwood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_MD_3592', Name: 'Overlea', stateId: 'MD', stateName: 'Maryland'},
  {Id: 'CIT_US_IN_3593', Name: 'Speedway', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_WA_3594',
    Name: 'Enumclaw',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_MO_3595', Name: 'Neosho', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_TX_3596',
    Name: 'Wells Branch',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_OR_3597',
    Name: 'Lincoln City',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_NY_3598',
    Name: 'North Merrick',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_3599',
    Name: 'Northlake',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NJ_3600',
    Name: 'Eatontown',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OR_3601', Name: 'Newport', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_CA_3602',
    Name: 'Home Gardens',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_3603', Name: 'Freeport', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_3604',
    Name: 'Webb City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NY_3605',
    Name: 'Salisbury',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OK_3606',
    Name: 'Woodward',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_TX_3607', Name: 'Melissa', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MD_3608',
    Name: 'Brock Hall',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_SC_3609',
    Name: 'James Island',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CA_3610',
    Name: 'Kingsburg',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_3611',
    Name: 'Platteville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MO_3612', Name: 'Eureka', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_NM_3613',
    Name: 'Lovington',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_CA_3614',
    Name: 'Emeryville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_3615',
    Name: 'Little Chute',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_GA_3616',
    Name: 'Bainbridge',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_WA_3617',
    Name: 'Sedro-Woolley',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_LA_3618', Name: 'Minden', stateId: 'LA', stateName: 'Louisiana'},
  {Id: 'CIT_US_UT_3619', Name: 'Grantsville', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NJ_3620',
    Name: 'Somerville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_TN_3621',
    Name: 'Middle Valley',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_FL_3622',
    Name: 'Union Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WV_3623',
    Name: 'South Charleston',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_VA_3624',
    Name: 'Fairfax Station',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_AL_3625', Name: 'Leeds', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NJ_3626',
    Name: 'Holiday City-Berkeley',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MN_3627',
    Name: 'East Bethel',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_MO_3628', Name: 'Festus', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MI_3629',
    Name: 'Riverview',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_UT_3630', Name: 'Smithfield', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_OK_3631',
    Name: 'Weatherford',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NY_3632',
    Name: 'Miller Place',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NC_3633',
    Name: 'Spring Lake',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_GA_3634', Name: 'Folkston', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_OH_3635', Name: 'Macedonia', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_3636',
    Name: 'Carnot-Moon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_ID_3637', Name: 'Jerome', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_WA_3638',
    Name: 'Prairie Ridge',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NJ_3639',
    Name: 'Hasbrouck Heights',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_MO_3640', Name: 'Union', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_NJ_3641',
    Name: 'Hamilton Square',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_SC_3642',
    Name: 'Moncks Corner',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_FL_3643', Name: 'Goulds', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NJ_3644',
    Name: 'Newton',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WA_3645',
    Name: 'Maltby',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_ID_3646',
    Name: 'Garden City',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_NH_3647',
    Name: 'Somersworth',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {Id: 'CIT_US_OH_3648', Name: 'Norton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NJ_3649',
    Name: 'Red Bank',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_3650',
    Name: 'Ellwood City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CO_3651',
    Name: 'Glenwood Springs',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_IN_3652',
    Name: 'Greensburg',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AZ_3653',
    Name: 'Tucson Estates',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_FL_3654',
    Name: 'Macclenny',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NJ_3655',
    Name: 'Echelon',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MI_3656',
    Name: 'East Grand Rapids',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SC_3657',
    Name: 'Newberry',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_KS_3658', Name: 'Lansing', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MS_3659',
    Name: 'Brookhaven',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_KS_3660', Name: 'Winfield', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_IA_3661', Name: 'Norwalk', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_3662',
    Name: 'Camp Pendleton South',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_FL_3663', Name: 'Hudson', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_TX_3664', Name: 'Robinson', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_RI_3665',
    Name: 'Newport East',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_NM_3666',
    Name: 'Portales',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_WI_3667',
    Name: 'Port Washington',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_FL_3668',
    Name: 'South Miami',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_HI_3669', Name: 'Waimea', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_WY_3670',
    Name: 'Green River',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {Id: 'CIT_US_UT_3671', Name: 'Tremonton', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_OR_3672',
    Name: 'Cottage Grove',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_RI_3673',
    Name: 'Valley Falls',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {Id: 'CIT_US_CO_3674', Name: 'Berkley', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_NJ_3675',
    Name: 'Robertsville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WI_3676',
    Name: 'Delavan',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_UT_3677', Name: 'Vineyard', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_WI_3678',
    Name: 'Richfield',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TX_3679',
    Name: 'Port Lavaca',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_MI_3680', Name: 'Otsego', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_AR_3681', Name: 'Malvern', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_MI_3682',
    Name: 'Cadillac',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IN_3683',
    Name: 'Lake Station',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CA_3684',
    Name: 'Healdsburg',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MI_3685', Name: 'Clawson', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_OR_3686', Name: 'Sandy', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_WA_3687',
    Name: 'East Renton Highlands',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_TN_3688',
    Name: 'Red Bank',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WI_3689',
    Name: 'Brown Deer',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MO_3690',
    Name: 'Crestwood',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NM_3691',
    Name: 'Grants',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_CA_3692',
    Name: 'Malibu',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_DE_3693', Name: 'Smyrna', stateId: 'DE', stateName: 'Delaware'},
  {Id: 'CIT_US_OH_3694', Name: 'Bucyrus', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AZ_3695',
    Name: 'Valencia West',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_FL_3696',
    Name: 'Viera East',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_3697',
    Name: 'Newman',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_3698', Name: 'Tomball', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_FL_3699', Name: 'Naranja', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_FL_3700',
    Name: 'Florida City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NJ_3701',
    Name: 'Wanaque',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_3702',
    Name: 'Scotts Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_3703',
    Name: 'View Park-Windsor Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_3704', Name: 'Robstown', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SC_3705',
    Name: 'Georgetown',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CA_3706',
    Name: 'Lake Los Angeles',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TN_3707',
    Name: 'Arlington',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_3708',
    Name: 'Somerset',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_3709',
    Name: 'Excelsior Springs',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_3710',
    Name: 'Diamond Springs',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_HI_3711', Name: 'Kalaoa', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_NY_3712',
    Name: 'North Wantagh',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_GA_3713', Name: 'Cordele', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CA_3714',
    Name: 'Mendota',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_3715',
    Name: 'Grafton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_AL_3716', Name: 'Eufaula', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NJ_3717',
    Name: 'Glen Rock',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_FL_3718',
    Name: 'Cocoa Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_3719', Name: 'Clute', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_3720',
    Name: 'Tamalpais-Homestead Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_3721',
    Name: 'Port St. John',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_NY_3722', Name: 'Endwell', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_IL_3723', Name: 'Plano', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_VA_3724',
    Name: 'Madison Heights',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WA_3725',
    Name: 'Bothell East',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OK_3726', Name: 'Guthrie', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_VA_3727',
    Name: 'Dranesville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_3728', Name: 'El Campo', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MD_3729',
    Name: 'Accokeek',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_WY_3730', Name: 'Evanston', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_IL_3731', Name: 'Pontiac', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_3732', Name: 'Franklin', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AZ_3733',
    Name: 'Gold Canyon',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WI_3734',
    Name: 'Cedarburg',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_LA_3735',
    Name: 'Claiborne',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OH_3736', Name: 'Beachwood', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_3737', Name: 'Seven Hills', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NJ_3738',
    Name: 'Upper Montclair',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_3739',
    Name: 'Martinsville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_OK_3740',
    Name: 'Elk City',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_GA_3741',
    Name: 'Scottdale',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NM_3742',
    Name: 'Silver City',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_VA_3743',
    Name: 'Wakefield',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_3744',
    Name: 'Alum Rock',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_3745',
    Name: 'Mitchellville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_IA_3746', Name: 'Spirit Lake', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CO_3747',
    Name: 'Woodland Park',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MO_3748',
    Name: 'Bridgeton',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MO_3749', Name: 'Mexico', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_NC_3750',
    Name: 'Archdale',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_FL_3751',
    Name: 'Westwood Lakes',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_LA_3752',
    Name: 'Bogalusa',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_TN_3753',
    Name: 'Newport',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_NY_3754', Name: 'Elwood', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_PA_3755',
    Name: 'Yeadon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_3756',
    Name: 'Florham Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_3757',
    Name: 'Wallington',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_FL_3758', Name: 'Trinity', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_OH_3759', Name: 'Mack', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_3760', Name: 'Roma', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_3761',
    Name: 'Mountain Top',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_3762',
    Name: 'Port Salerno',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_3763',
    Name: 'Magalia',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_HI_3764', Name: 'Waipio', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_AR_3765',
    Name: 'Magnolia',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_3766',
    Name: 'Emmaus',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_3767', Name: 'Jackson', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_HI_3768', Name: 'Nanakuli', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_TX_3769', Name: 'Webster', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_3770',
    Name: 'Elizabethtown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AZ_3771', Name: 'Show Low', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_HI_3772', Name: 'Maili', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_NJ_3773',
    Name: 'River Edge',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_UT_3774', Name: 'Woods Cross', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MS_3775',
    Name: 'Byram',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NY_3776',
    Name: 'North Bellport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_3777', Name: 'Fort Bliss', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_3778',
    Name: 'Highland',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_KY_3779', Name: 'Lyndon', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_CA_3780',
    Name: 'Signal Hill',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_ID_3781', Name: 'Hailey', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_IN_3782',
    Name: 'Scottsburg',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OH_3783', Name: 'Urbana', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_3784',
    Name: 'Schiller Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MI_3785', Name: 'Fenton', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_LA_3786',
    Name: 'Bayou Blue',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_3787',
    Name: 'Los Alamitos',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_LA_3788',
    Name: 'Destrehan',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_IL_3789', Name: 'Minooka', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_LA_3790',
    Name: 'Gardere',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_VA_3791',
    Name: 'Cascades',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_3792',
    Name: 'Hillsborough',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NV_3793',
    Name: 'Gardnerville Ranchos',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_TN_3794',
    Name: 'Collegedale',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NC_3795',
    Name: 'Summerfield',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_3796', Name: 'Snyder', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_3797',
    Name: 'Tarrytown',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_FL_3798',
    Name: 'Viera West',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NY_3799',
    Name: 'Wyandanch',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IA_3800', Name: 'Fairfield', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_KY_3801',
    Name: 'Mount Sterling',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OH_3802', Name: 'Ravenna', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NJ_3803',
    Name: 'Bellmawr',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IN_3804', Name: 'Angola', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_OH_3805', Name: 'Coshocton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MN_3806',
    Name: 'Mendota Heights',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_KS_3807', Name: 'Haysville', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_SC_3808',
    Name: 'Tega Cay',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OH_3809', Name: 'Dent', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NJ_3810',
    Name: 'Haddonfield',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_3811',
    Name: 'Beachwood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_3812',
    Name: 'Bradford',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_3813',
    Name: 'North Vernon',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MD_3814',
    Name: 'Travilah',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_HI_3815',
    Name: 'Kaneohe Station',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_CA_3816',
    Name: 'Winton',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_3817', Name: 'Celina', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TN_3818',
    Name: 'Hartsville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_CA_3819',
    Name: 'Canyon Lake',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_3820',
    Name: 'Burkburnett',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_FL_3821',
    Name: 'Lighthouse Point',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_LA_3822', Name: 'Eunice', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_NJ_3823',
    Name: 'Greentree',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_FL_3824', Name: 'Westview', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_PA_3825',
    Name: 'DuBois',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_3826', Name: 'Lamesa', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_UT_3827', Name: 'North Logan', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NH_3828',
    Name: 'Berlin',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {Id: 'CIT_US_WI_3829', Name: 'Waupun', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_MN_3830',
    Name: 'Big Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MI_3831',
    Name: 'Hillsdale',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_3832',
    Name: 'Broomall',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_3833',
    Name: 'Gloucester City',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MN_3834',
    Name: 'Grand Rapids',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_HI_3835',
    Name: 'Hawaiian Paradise Park',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_AZ_3836',
    Name: 'Arizona City',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_TN_3837',
    Name: 'Seymour',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PR_3838',
    Name: 'Hormigueros',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_AZ_3839',
    Name: 'Camp Verde',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NC_3840',
    Name: 'Weddington',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NJ_3841',
    Name: 'Ridgefield',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WA_3842',
    Name: 'Poulsbo',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OR_3843', Name: 'Stayton', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_OH_3844', Name: 'Springdale', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OR_3845', Name: 'Brookings', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_OR_3846', Name: 'Damascus', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_MI_3847', Name: 'Niles', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_FL_3848',
    Name: 'Fuller Heights',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_FL_3849', Name: 'Mango', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_3850',
    Name: 'Piedmont',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_GA_3851',
    Name: 'Fitzgerald',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PA_3852',
    Name: 'Huntingdon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_3853', Name: 'Selma', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_3854',
    Name: 'Satellite Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_3855',
    Name: 'Rossmoor',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_3856',
    Name: 'Mayfield',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NJ_3857',
    Name: 'Guttenberg',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_MD_3858', Name: 'Lanham', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_NJ_3859',
    Name: 'Franklin Lakes',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_FL_3860',
    Name: 'Gulf Gate Estates',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IL_3861', Name: 'Summit', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_IN_3862', Name: 'Yorktown', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_MO_3863',
    Name: 'Town and Country',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_NY_3864', Name: 'Fulton', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_NY_3865',
    Name: 'Mount Sinai',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MD_3866',
    Name: 'Princess Anne',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_PA_3867',
    Name: 'Jefferson Hills',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_UT_3868', Name: 'Lindon', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_KY_3869',
    Name: 'Maysville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MA_3870',
    Name: 'South Yarmouth',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_IL_3871',
    Name: 'Campton Hills',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_3872',
    Name: 'Woodbury',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_CO_3873', Name: 'Rifle', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_SC_3874',
    Name: 'Bennettsville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_NY_3875', Name: 'Walden', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_PA_3876',
    Name: 'Grove City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MD_3877',
    Name: 'Lexington Park',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_KS_3878', Name: 'Merriam', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NJ_3879',
    Name: 'Westwood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_3880',
    Name: 'Lower Burrell',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_3881',
    Name: 'Valley Center',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OR_3882', Name: 'Silverton', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_ND_3883',
    Name: 'Wahpeton',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_MO_3884', Name: 'Bolivar', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_IA_3885', Name: 'Storm Lake', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MI_3886',
    Name: 'Whitehall',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_3887',
    Name: 'Orange Cove',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_3888',
    Name: 'Lake Grove',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_LA_3889',
    Name: 'Moss Bluff',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MI_3890',
    Name: 'Grosse Pointe Park',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_TX_3891',
    Name: 'Four Corners',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MI_3892',
    Name: 'Grand Haven',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WI_3893',
    Name: 'Two Rivers',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TN_3894',
    Name: 'Lawrenceburg',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_SC_3895',
    Name: 'Clinton',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NY_3896',
    Name: 'Dobbs Ferry',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PR_3897',
    Name: 'Coamo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_NY_3898', Name: 'Suffern', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_PA_3899',
    Name: 'Shiloh',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_3900',
    Name: 'Munhall',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_3901',
    Name: 'Lake Morton-Berrydale',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OR_3902', Name: 'Florence', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NJ_3903',
    Name: 'Browns Mills',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OK_3904', Name: 'Guymon', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_DE_3905',
    Name: 'Pike Creek Valley',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_NJ_3906',
    Name: 'Pompton Lakes',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_DE_3907',
    Name: 'Millsboro',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_NC_3908',
    Name: 'Kings Mountain',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_WI_3909', Name: 'Sussex', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_TX_3910', Name: 'Pearsall', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_3911',
    Name: 'Ocean City',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_MD_3912', Name: 'Largo', stateId: 'MD', stateName: 'Maryland'},
  {Id: 'CIT_US_UT_3913', Name: 'West Point', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_LA_3914',
    Name: 'Gonzales',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WA_3915',
    Name: 'Liberty Lake',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IA_3916', Name: 'Spencer', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_NE_3917', Name: 'Chalco', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_AZ_3918',
    Name: 'Saddlebrooke',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_IN_3919', Name: 'Decatur', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_OH_3920', Name: 'Heath', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_3921', Name: 'New Albany', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_SD_3922',
    Name: 'Vermillion',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_VA_3923',
    Name: 'Manchester',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_GA_3924', Name: 'Cusseta', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MD_3925',
    Name: 'Walker Mill',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WV_3926',
    Name: 'Elkins',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_MD_3927', Name: 'Halfway', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_CA_3928',
    Name: 'Soquel',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_3929',
    Name: 'Fort Meade',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OH_3930', Name: 'Montgomery', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_VA_3931',
    Name: 'Lake Monticello',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_VA_3932',
    Name: 'Lowes Island',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_MO_3933', Name: 'Kearney', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_TX_3934', Name: 'Bacliff', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VT_3935',
    Name: 'Essex Junction',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_CA_3936',
    Name: 'La Riviera',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_UT_3937',
    Name: 'Pleasant View',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {Id: 'CIT_US_AK_3938', Name: 'Wasilla', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_VA_3939', Name: 'Forest', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_SC_3940',
    Name: 'Oak Grove',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_IL_3941',
    Name: 'River Forest',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_FL_3942', Name: 'Cheval', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CO_3943',
    Name: 'The Pinery',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_GA_3944',
    Name: 'Panthersville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MO_3945',
    Name: 'Smithville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NY_3946',
    Name: 'Mount Kisco',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_3947',
    Name: 'Sierra Madre',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_3948',
    Name: 'Totowa',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PR_3949',
    Name: 'San Germán',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MI_3950',
    Name: 'Highland Park',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MN_3951',
    Name: 'North Branch',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NV_3952',
    Name: 'Winnemucca',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_CO_3953',
    Name: 'Castle Pines',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_IL_3954',
    Name: 'Burr Ridge',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_3955',
    Name: 'Galena Park',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NC_3956',
    Name: 'Myrtle Grove',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MI_3957',
    Name: 'Ludington',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_AR_3958', Name: 'Camden', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_TX_3959', Name: 'Bellmead', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_3960',
    Name: 'Little Ferry',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PR_3961',
    Name: 'Aguadilla',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MI_3962',
    Name: 'Muskegon Heights',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_UT_3963', Name: 'Mapleton', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_FL_3964',
    Name: 'Fruitland Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AR_3965',
    Name: 'Arkadelphia',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_IL_3966',
    Name: 'Rochelle',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_3967',
    Name: 'Gig Harbor',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_IL_3968',
    Name: 'Crestwood',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_3969',
    Name: 'Farmersville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_3970',
    Name: 'Darby',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_3971',
    Name: 'Smithfield',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_FL_3972', Name: 'Gateway', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_LA_3973',
    Name: 'Raceland',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_OR_3974',
    Name: 'Sweet Home',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_WI_3975',
    Name: 'DeForest',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_3976', Name: 'Cleveland', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_3977',
    Name: 'Avon Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_3978', Name: 'Northbrook', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_3979', Name: 'Van Wert', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_SC_3980',
    Name: 'Garden City',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_NY_3981', Name: 'Hornell', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_MO_3982', Name: 'Pacific', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_OK_3983',
    Name: 'Newcastle',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_AK_3984', Name: 'Ketchikan', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_CO_3985', Name: 'Alamosa', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_PA_3986',
    Name: 'Lansdowne',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_3987', Name: 'Toccoa', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_OH_3988', Name: 'Brooklyn', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AL_3989', Name: 'Saks', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_TN_3990',
    Name: 'Millington',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_DE_3991',
    Name: 'Georgetown',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_NY_3992',
    Name: 'Ogdensburg',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_3993',
    Name: 'Wyomissing',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_3994',
    Name: 'Petal',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CA_3995',
    Name: 'Anderson',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_3996',
    Name: 'North Lindenhurst',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_WI_3997', Name: 'Monroe', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_KS_3998', Name: 'Atchison', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_FL_3999',
    Name: 'Highland City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_HI_4000', Name: 'Kapaa', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_IL_4001',
    Name: 'Waterloo',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_VA_4002', Name: 'Wyndham', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_VA_4003', Name: 'Norton', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_AL_4004',
    Name: 'Fairfield',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_IN_4005',
    Name: 'Greencastle',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_LA_4006',
    Name: 'Covington',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MI_4007',
    Name: 'Three Rivers',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MD_4008',
    Name: 'Timonium',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_OH_4009',
    Name: 'Lincoln Village',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CA_4010',
    Name: 'Morro Bay',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IN_4011', Name: 'Lowell', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_TN_4012', Name: 'Paris', stateId: 'TN', stateName: 'Tennessee'},
  {Id: 'CIT_US_OH_4013', Name: 'Ironton', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_ID_4014', Name: 'Star', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_CA_4015',
    Name: 'Fairview',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_4016',
    Name: 'Pasadena Hills',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_4017',
    Name: 'Richmond Heights',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_FL_4018', Name: 'Milton', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_4019',
    Name: 'San Diego Country Estates',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_4020',
    Name: 'Columbia',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IL_4021', Name: 'Roscoe', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_LA_4022',
    Name: 'Jefferson',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MN_4023',
    Name: 'Little Canada',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MD_4024',
    Name: 'Rosaryville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OH_4025', Name: 'Wapakoneta', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_UT_4026', Name: 'Alpine', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MI_4027',
    Name: 'Farmington',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_FL_4028',
    Name: 'Doctor Phillips',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_4029',
    Name: 'Exeter',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TN_4030', Name: 'Martin', stateId: 'TN', stateName: 'Tennessee'},
  {Id: 'CIT_US_FL_4031', Name: 'Gifford', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NM_4032',
    Name: 'Bernalillo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_FL_4033',
    Name: 'Pine Castle',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WV_4034',
    Name: 'Point Pleasant',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_FL_4035',
    Name: 'Cape Canaveral',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_4036', Name: 'Sweetwater', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_4037',
    Name: 'Fairview Shores',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_TX_4038',
    Name: 'Jacinto City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IL_4039', Name: 'Worth', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_WI_4040',
    Name: 'Portage',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_AK_4041', Name: 'Tanaina', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_OH_4042',
    Name: 'Bedford Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IL_4043',
    Name: 'Fox Lake',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_4044',
    Name: 'Oak Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_4045',
    Name: 'Linthicum',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_SC_4046',
    Name: 'Fountain Inn',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_VA_4047',
    Name: 'New Baltimore',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CO_4048',
    Name: 'Wellington',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_IN_4049',
    Name: 'Kendallville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_WA_4050',
    Name: 'Sumner',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NJ_4051',
    Name: 'Pine Hill',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OH_4052', Name: 'Forestville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_NY_4053', Name: 'Hudson', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_CA_4054',
    Name: 'Shasta Lake',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TN_4055', Name: 'Dayton', stateId: 'TN', stateName: 'Tennessee'},
  {Id: 'CIT_US_CA_4056', Name: 'Rodeo', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_MS_4057',
    Name: 'West Point',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_TX_4058', Name: 'Vidor', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_4059',
    Name: 'New Port Richey East',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MO_4060',
    Name: 'Bellefontaine Neighbors',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_4061', Name: 'Vermilion', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_4062', Name: 'Bonham', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MO_4063', Name: 'Kennett', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_IL_4064', Name: 'Troy', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CA_4065',
    Name: 'Fort Bragg',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_4066', Name: 'Lyons', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_FL_4067',
    Name: 'Miami Shores',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_4068',
    Name: 'East Whittier',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_4069',
    Name: 'Columbia',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_4070',
    Name: 'Beverly Hills',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NE_4071',
    Name: 'Lexington',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_OH_4072',
    Name: 'Richmond Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_AZ_4073', Name: 'Sedona', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_GA_4074',
    Name: 'Summerville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_TN_4075',
    Name: 'Union City',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_TX_4076', Name: 'Vernon', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_4077',
    Name: 'South Huntington',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IA_4078',
    Name: 'Fort Madison',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_PA_4079',
    Name: 'Nanticoke',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_4080',
    Name: 'Hillsdale',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MI_4081',
    Name: 'Comstock Park',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NY_4082',
    Name: 'Fredonia',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AR_4083',
    Name: 'Helena-West Helena',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_4084',
    Name: 'Park Forest Village',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SC_4085',
    Name: 'Forest Acres',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OH_4086', Name: 'Reading', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_4087',
    Name: 'Vashon',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MN_4088',
    Name: 'Arden Hills',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_FL_4089',
    Name: 'Pine Ridge',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_FL_4090', Name: 'Medulla', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_OR_4091',
    Name: 'Independence',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_GA_4092',
    Name: 'Doraville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_IL_4093',
    Name: 'Boulder Hill',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_4094',
    Name: 'Cypress Gardens',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_4095',
    Name: 'Pebble Creek',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MI_4096',
    Name: 'Melvindale',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_4097',
    Name: 'Sauk Village',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_4098',
    Name: 'Clearfield',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_4099', Name: 'Pella', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WA_4100',
    Name: 'Lake Stickney',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_WI_4101', Name: 'Sparta', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_SD_4102',
    Name: 'Box Elder',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NY_4103',
    Name: 'Middle Island',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_4104',
    Name: 'Barrington',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_4105',
    Name: 'Great Neck',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CO_4106',
    Name: 'Fort Lupton',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MN_4107',
    Name: 'Victoria',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CO_4108',
    Name: 'Gunbarrel',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_IN_4109', Name: 'Bluffton', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_IA_4110', Name: 'Waverly', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NY_4111',
    Name: 'West Haverstraw',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_WA_4112', Name: 'Fife', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_NJ_4113',
    Name: 'Bound Brook',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_4114',
    Name: 'Somers Point',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_LA_4115',
    Name: 'Woodmere',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_IL_4116',
    Name: 'Gages Lake',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_4117',
    Name: 'Asbury Lake',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AL_4118',
    Name: 'Pike Road',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_MT_4119', Name: 'Havre', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_WA_4120',
    Name: 'Snohomish',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_4121',
    Name: 'Citrus',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_4122',
    Name: 'Waynesville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_4123',
    Name: 'Clarion',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_4124',
    Name: 'Kutztown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IN_4125', Name: 'Danville', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_WV_4126',
    Name: 'Vienna',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NJ_4127',
    Name: 'Manville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_MD_4128', Name: 'Bel Air', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_OK_4129',
    Name: 'Warr Acres',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_KY_4130',
    Name: 'Franklin',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OH_4131', Name: 'Tipp City', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_4132', Name: 'Struthers', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NJ_4133',
    Name: 'Lincoln Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_4134',
    Name: 'Waldwick',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WI_4135',
    Name: 'Shawano',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_SC_4136',
    Name: 'Red Bank',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_4137',
    Name: 'Progress',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_4138',
    Name: 'Fairmount',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_UT_4139', Name: 'Cedar Hills', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_WI_4140', Name: 'Hobart', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_IA_4141', Name: 'Le Mars', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_4142',
    Name: 'Cresson',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_4143',
    Name: 'Harrisonville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TN_4144',
    Name: 'Clinton',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_SD_4145',
    Name: 'Brandon',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_TN_4146',
    Name: 'Nolensville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PR_4147',
    Name: 'Isabela',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_IL_4148',
    Name: 'Wood River',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MD_4149',
    Name: 'Friendly',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_4150',
    Name: 'Charter Oak',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_4151',
    Name: 'Sleepy Hollow',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_4152',
    Name: 'Fair Oaks Ranch',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_WI_4153', Name: 'Holmen', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_PA_4154',
    Name: 'Croydon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OK_4155', Name: 'Coweta', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_MN_4156',
    Name: 'Fairmont',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_VA_4157',
    Name: 'Warrenton',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_FL_4158',
    Name: 'Progress Village',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IA_4159',
    Name: 'Pleasant Hill',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_LA_4160',
    Name: 'Waggaman',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_4161',
    Name: 'Capitola',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_4162',
    Name: 'Laurens',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_WA_4163',
    Name: 'Midland',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_OR_4164',
    Name: 'Bull Mountain',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MI_4165',
    Name: 'Flat Rock',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_4166',
    Name: 'Quartz Hill',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OR_4167', Name: 'Molalla', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NY_4168',
    Name: 'Scotchtown',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_GA_4169',
    Name: 'Fort Oglethorpe',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CA_4170',
    Name: 'Del Aire',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TN_4171', Name: 'Alcoa', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_CA_4172',
    Name: 'Country Club',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_4173',
    Name: 'Lake Arbor',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_4174',
    Name: 'Garden Acres',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_4175',
    Name: 'Orland',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_4176', Name: 'Helotes', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_4177',
    Name: 'Bee Ridge',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_4178',
    Name: 'Key Largo',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_LA_4179',
    Name: 'Timberlane',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MD_4180',
    Name: 'Westphalia',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_IN_4181', Name: 'Wabash', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NC_4182',
    Name: 'Winterville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_AL_4183', Name: 'Clay', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_FL_4184',
    Name: 'Celebration',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WV_4185',
    Name: 'St. Albans',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_KS_4186', Name: 'Mission', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_4187',
    Name: 'Berwick',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_4188',
    Name: 'Watervliet',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_FL_4189', Name: 'Alachua', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NJ_4190',
    Name: 'Kinnelon',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_4191',
    Name: 'Ventnor City',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OR_4192', Name: 'Rockcreek', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_NY_4193', Name: 'Inwood', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_OR_4194',
    Name: 'White City',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_ID_4195', Name: 'Emmett', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_IL_4196',
    Name: 'Sugar Grove',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_4197',
    Name: 'River Grove',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_4198',
    Name: 'Pingree Grove',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_4199',
    Name: 'Fort Irwin',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_4200',
    Name: 'Northampton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_4201',
    Name: 'Ellisville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_4202',
    Name: 'Madera Acres',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VA_4203',
    Name: 'Gloucester Point',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_4204',
    Name: 'Marina del Rey',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_4205',
    Name: 'Brandywine',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CO_4206',
    Name: 'Stonegate',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_IA_4207', Name: 'Carroll', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WA_4208',
    Name: 'Port Townsend',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KS_4209',
    Name: 'Coffeyville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_FL_4210',
    Name: 'West Perrine',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MO_4211',
    Name: 'Chillicothe',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_VA_4212',
    Name: 'Lake Barcroft',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MD_4213',
    Name: 'Burtonsville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NH_4214',
    Name: 'Franklin',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_AL_4215',
    Name: 'Robertsdale',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NY_4216',
    Name: 'East Rockaway',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OR_4217',
    Name: 'Baker City',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_NY_4218',
    Name: 'New Hyde Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IL_4219', Name: 'Itasca', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NJ_4220',
    Name: 'Brookdale',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_LA_4221',
    Name: 'Jennings',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NJ_4222',
    Name: 'Woodbury',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_4223',
    Name: 'Mentone',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_4224',
    Name: 'Little River',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_WY_4225', Name: 'Cody', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_PR_4226',
    Name: 'Vega Alta',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_IN_4227',
    Name: 'Mooresville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CT_4228',
    Name: 'Conning Towers Nautilus Park',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_FL_4229', Name: 'Westgate', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_VA_4230',
    Name: 'George Mason',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NY_4231',
    Name: 'Fort Salonga',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AL_4232',
    Name: 'Russellville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_OR_4233',
    Name: 'North Bend',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_AR_4234',
    Name: 'Clarksville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_4235',
    Name: 'Philipsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_4236',
    Name: 'Denham Springs',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MD_4237',
    Name: 'Scaggsville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_4238',
    Name: 'Corte Madera',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CT_4239',
    Name: 'Jewett City',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_TX_4240', Name: 'Cuero', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_4241',
    Name: 'Budd Lake',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_IN_4242',
    Name: 'Columbia City',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_OH_4243',
    Name: 'Blacklick Estates',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NY_4244',
    Name: 'East Aurora',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_4245',
    Name: 'Rio Vista',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_4246',
    Name: 'Alexandria',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_TX_4247',
    Name: 'Eidson Road',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PR_4248',
    Name: 'Pájaros',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_CA_4249',
    Name: 'West Athens',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AL_4250', Name: 'Boaz', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MD_4251',
    Name: 'Chesapeake Ranch Estates',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_VA_4252',
    Name: 'Countryside',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WI_4253',
    Name: 'St. Francis',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_HI_4254', Name: 'Holualoa', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_NJ_4255',
    Name: 'East Rutherford',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_GA_4256',
    Name: 'Fort Valley',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_TX_4257', Name: 'Commerce', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TX_4258',
    Name: 'West Tawakoni',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MO_4259',
    Name: 'Warrenton',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MI_4260',
    Name: 'Marysville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NJ_4261',
    Name: 'Marlton',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MI_4262',
    Name: 'Greenville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_4263',
    Name: 'Rio del Mar',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AZ_4264',
    Name: 'Picture Rocks',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_MO_4265', Name: 'Cameron', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_CA_4266',
    Name: 'Waterford',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_FL_4267', Name: 'Memphis', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_GA_4268',
    Name: 'Fort Stewart',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_GA_4269',
    Name: 'Port Wentworth',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_VA_4270',
    Name: 'Yorkshire',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_HI_4271', Name: 'Aiea', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_MN_4272',
    Name: 'Hermantown',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_4273',
    Name: 'Winfield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_4274',
    Name: 'Oxford',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NV_4275',
    Name: 'Cold Springs',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_NJ_4276',
    Name: 'Keansburg',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_SC_4277',
    Name: 'Powdersville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MD_4278',
    Name: 'La Plata',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WI_4279',
    Name: 'Merrill',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_FL_4280', Name: 'Bithlo', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MD_4281',
    Name: 'Chevy Chase',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_KY_4282', Name: 'Wilmore', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_NJ_4283',
    Name: 'Maywood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_AL_4284',
    Name: 'Rainbow City',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_MI_4285', Name: 'Howell', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_AL_4286',
    Name: 'Pleasant Grove',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_CA_4287',
    Name: 'Blackhawk',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AR_4288', Name: 'Hope', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_NC_4289',
    Name: 'Roxboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_4290',
    Name: 'Bishop',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_4291',
    Name: 'Middletown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_4292',
    Name: 'Greenville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_4293',
    Name: 'St. Pete Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WI_4294',
    Name: 'Rice Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_4295',
    Name: 'North Gates',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WA_4296',
    Name: 'Alderwood Manor',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_MI_4297', Name: 'Ecorse', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_PA_4298',
    Name: 'Harleysville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OR_4299', Name: 'Fairview', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_OK_4300',
    Name: 'The Village',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_OR_4301',
    Name: 'Eagle Point',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_CO_4302', Name: 'Craig', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_OH_4303',
    Name: 'Willoughby Hills',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_SC_4304',
    Name: 'Walterboro',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NJ_4305',
    Name: 'White Meadow Lake',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WA_4306',
    Name: 'Mount Vista',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_AR_4307', Name: 'Lowell', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_TX_4308', Name: 'Silsbee', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_4309', Name: 'Harvard', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_KY_4310',
    Name: 'Central City',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IN_4311',
    Name: 'Tell City',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MI_4312',
    Name: 'Lambertville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CO_4313',
    Name: 'Roxborough Park',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NY_4314',
    Name: 'Rye Brook',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WI_4315',
    Name: 'Reedsburg',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_FL_4316', Name: 'Laurel', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_GA_4317',
    Name: 'Fair Oaks',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_WA_4318',
    Name: 'DuPont',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_MN_4319', Name: 'Mound', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_MD_4320', Name: 'Jessup', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_SC_4321',
    Name: 'Boiling Springs',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_RI_4322',
    Name: 'Wakefield-Peacedale',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {Id: 'CIT_US_TN_4323', Name: 'Erwin', stateId: 'TN', stateName: 'Tennessee'},
  {Id: 'CIT_US_ID_4324', Name: 'Rigby', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_TX_4325',
    Name: 'Fort Stockton',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WA_4326',
    Name: 'Airway Heights',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_KS_4327', Name: 'Parsons', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_TN_4328',
    Name: 'Fayetteville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_AL_4329',
    Name: 'Meadowbrook',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PA_4330',
    Name: 'Lititz',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_4331',
    Name: 'Siler City',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MO_4332',
    Name: 'Platte City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_4333', Name: 'Lantana', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SC_4334',
    Name: 'Woodfield',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MD_4335',
    Name: 'Mount Airy',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_LA_4336',
    Name: 'Carencro',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_AZ_4337', Name: 'Winslow', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_NM_4338',
    Name: 'Ruidoso',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_TX_4339', Name: 'Hondo', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MT_4340',
    Name: 'Miles City',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_TN_4341',
    Name: 'Brownsville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MI_4342',
    Name: 'Manistee',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_LA_4343', Name: 'Rayne', stateId: 'LA', stateName: 'Louisiana'},
  {Id: 'CIT_US_KS_4344', Name: 'Augusta', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WI_4345',
    Name: 'New Richmond',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_AR_4346',
    Name: 'Greenwood',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_AR_4347',
    Name: 'Monticello',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MD_4348',
    Name: 'Bladensburg',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NC_4349',
    Name: 'St. Stephens',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MN_4350',
    Name: 'Little Falls',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OH_4351', Name: 'Bryan', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_4352',
    Name: 'Amityville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_WI_4353', Name: 'Tomah', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_MD_4354',
    Name: 'Brunswick',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_4355', Name: 'Roanoke', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KY_4356',
    Name: 'Francisville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_HI_4357',
    Name: 'Hickam Housing',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {Id: 'CIT_US_TX_4358', Name: 'Navasota', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_4359',
    Name: 'Flowood',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_OK_4360',
    Name: 'Pryor Creek',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_FL_4361', Name: 'Bardmoor', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NM_4362',
    Name: 'Bloomfield',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_GA_4363', Name: 'Cairo', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_NV_4364', Name: 'Dayton', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_MO_4365',
    Name: 'Boonville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_4366', Name: 'Louisville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_FL_4367',
    Name: 'Beverly Hills',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_4368', Name: 'Brownfield', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_4369',
    Name: 'Hackettstown',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_VA_4370',
    Name: 'Stuarts Draft',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_AL_4371',
    Name: 'Bay Minette',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_CA_4372',
    Name: 'Golden Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_CO_4373', Name: 'Edwards', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_CO_4374',
    Name: 'Woodmoor',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_MI_4375', Name: 'Saline', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_FL_4376',
    Name: 'St. Augustine Shores',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OR_4377', Name: 'Madras', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NC_4378',
    Name: 'Clinton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_4379', Name: 'Heath', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_DE_4380',
    Name: 'Claymont',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_UT_4381',
    Name: 'Stansbury Park',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {Id: 'CIT_US_FL_4382', Name: 'Rotonda', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MD_4383',
    Name: 'Marlboro Village',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_TN_4384',
    Name: 'Lenoir City',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WA_4385',
    Name: 'Birch Bay',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_4386',
    Name: 'Hartland',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_HI_4387',
    Name: 'Waihee-Waiehu',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_WA_4388',
    Name: 'College Place',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_TX_4389', Name: 'Liberty', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MN_4390',
    Name: 'Zimmerman',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NC_4391',
    Name: 'Wesley Chapel',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NJ_4392',
    Name: 'Wood-Ridge',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MN_4393',
    Name: 'Detroit Lakes',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AL_4394',
    Name: 'Fultondale',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_OH_4395',
    Name: 'North College Hill',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WV_4396',
    Name: 'Cross Lanes',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_FL_4397', Name: 'Marathon', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MI_4398',
    Name: 'Westwood',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_4399',
    Name: 'Brentwood',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_4400',
    Name: 'Lexington',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_NY_4401', Name: 'Bohemia', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_FL_4402', Name: 'Hernando', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_WV_4403',
    Name: 'Cheat Lake',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_LA_4404',
    Name: 'Harahan',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_UT_4405',
    Name: 'Washington Terrace',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_WA_4406',
    Name: 'Sultan',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_4407',
    Name: 'Muncy',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_4408', Name: 'Madeira', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NM_4409',
    Name: 'Anthony',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_CA_4410',
    Name: 'Gridley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_4411',
    Name: 'Kendall Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OH_4412', Name: 'Girard', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_KY_4413',
    Name: 'Hillview',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WA_4414',
    Name: 'Burlington',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IL_4415', Name: 'Steger', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_SC_4416',
    Name: 'Sans Souci',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MD_4417',
    Name: 'White Marsh',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_AL_4418',
    Name: 'Spanish Fort',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MN_4419',
    Name: 'Lake Elmo',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_4420',
    Name: 'Bethalto',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_4421',
    Name: 'Cloverdale',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VA_4422',
    Name: 'Independent Hill',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_4423',
    Name: 'Good Hope',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_4424', Name: 'Lynwood', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_CO_4425', Name: 'Basalt', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_UT_4426', Name: 'Ivins', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_OK_4427', Name: 'Wagoner', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_OH_4428', Name: 'St. Marys', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IN_4429', Name: 'Elwood', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PA_4430',
    Name: 'Woodlyn',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_4431',
    Name: 'South Amboy',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_TN_4432',
    Name: 'Bloomingdale',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PR_4433',
    Name: 'Sabana Grande',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MO_4434',
    Name: 'Sullivan',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NY_4435',
    Name: 'Rensselaer',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_4436',
    Name: 'Audubon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_4437',
    Name: 'Calimesa',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VA_4438',
    Name: 'Dunn Loring',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IL_4439',
    Name: 'Flossmoor',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OH_4440',
    Name: 'Beckett Ridge',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MI_4441',
    Name: 'Plymouth',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WA_4442',
    Name: 'Ridgefield',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_UT_4443', Name: 'Hooper', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_ID_4444', Name: 'Rathdrum', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_MN_4445',
    Name: 'Thief River Falls',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TN_4446',
    Name: 'Fairview',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_TX_4447', Name: 'Fairview', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MT_4448', Name: 'Anaconda', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_SC_4449',
    Name: 'Murrells Inlet',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_SC_4450',
    Name: 'Union',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_VA_4451',
    Name: 'Woodburn',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_MO_4452', Name: 'Monett', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_IA_4453', Name: 'Grinnell', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_4454',
    Name: 'Jersey Shore',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_4455',
    Name: 'Grosse Pointe Farms',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SD_4456',
    Name: 'Rapid Valley',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MD_4457',
    Name: 'Linganore',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MD_4458',
    Name: 'Edgewater',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_AL_4459', Name: 'Atmore', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_PA_4460',
    Name: 'Economy',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_4461',
    Name: 'Plymouth',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_SC_4462',
    Name: 'Burton',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CO_4463',
    Name: 'Berthoud',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_IN_4464',
    Name: 'Whitestown',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_TX_4465',
    Name: 'San Elizario',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_SC_4466',
    Name: 'Dillon',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OK_4467', Name: 'Clinton', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CA_4468',
    Name: 'Tiburon',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_4469',
    Name: 'Monmouth',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_4470',
    Name: 'Highland Park',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_FL_4471',
    Name: 'Sugarmill Woods',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AZ_4472',
    Name: 'Golden Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_PA_4473',
    Name: 'Northwest Harborcreek',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_4474',
    Name: 'Jeannette',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MD_4475', Name: 'Marlton', stateId: 'MD', stateName: 'Maryland'},
  {Id: 'CIT_US_OR_4476', Name: 'Sutherlin', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_WA_4477',
    Name: 'Picnic Point',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OR_4478', Name: 'Seaside', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MT_4479',
    Name: 'Livingston',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_LA_4480',
    Name: 'Fort Polk South',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_FL_4481', Name: 'Micco', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_KS_4482', Name: 'Chanute', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_MI_4483', Name: 'Beecher', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MD_4484',
    Name: 'Leisure World',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MS_4485',
    Name: 'Indianola',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MD_4486',
    Name: 'North Kensington',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NJ_4487',
    Name: 'Leonia',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_ME_4488', Name: 'Brewer', stateId: 'ME', stateName: 'Maine'},
  {
    Id: 'CIT_US_KY_4489',
    Name: 'La Grange',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PR_4490',
    Name: 'Corozal',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_WI_4491',
    Name: 'McFarland',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_FL_4492',
    Name: 'Silver Springs Shores',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_4493', Name: 'Shelby', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_4494',
    Name: 'Pineville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_4495', Name: 'Woodway', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KY_4496',
    Name: 'Oakbrook',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NY_4497',
    Name: 'Valley Cottage',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MN_4498',
    Name: 'Lindstrom',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NM_4499',
    Name: 'Shiprock',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MN_4500',
    Name: 'St. Anthony',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_ME_4501',
    Name: 'Presque Isle',
    stateId: 'ME',
    stateName: 'Maine',
  },
  {
    Id: 'CIT_US_NY_4502',
    Name: 'Farmingdale',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IL_4503', Name: 'Manteno', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NJ_4504',
    Name: 'Lake Mohawk',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_MO_4505', Name: 'Dexter', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_CO_4506', Name: 'Delta', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_PA_4507',
    Name: 'Mechanicsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_4508',
    Name: 'Albion',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_HI_4509',
    Name: 'Haiku-Pauwela',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_GA_4510',
    Name: 'Skidaway Island',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_IL_4511', Name: 'LaSalle', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_TN_4512',
    Name: 'Fairfield Glade',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_MN_4513', Name: 'Waseca', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_IL_4514',
    Name: 'Murphysboro',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_4515',
    Name: 'Plainedge',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MD_4516',
    Name: 'Fallston',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_VA_4517',
    Name: 'Triangle',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_SC_4518',
    Name: 'Chester',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_CO_4519', Name: 'Derby', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_LA_4520',
    Name: 'Lacombe',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WA_4521',
    Name: 'Felida',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WA_4522',
    Name: 'Summit',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_MO_4523', Name: 'Clinton', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_NY_4524',
    Name: 'Stony Brook University',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_4525',
    Name: 'Lake Arrowhead',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_4526', Name: 'Oakwood', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_4527',
    Name: 'Sturgeon Bay',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_4528',
    Name: 'Harrisburg',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_KS_4529',
    Name: 'Independence',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_AL_4530',
    Name: 'Forestdale',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NJ_4531',
    Name: 'Boonton',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MN_4532',
    Name: 'Oak Grove',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_KY_4533',
    Name: 'Harrodsburg',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_OH_4534',
    Name: 'Sheffield Lake',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_4535',
    Name: 'Folsom',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OR_4536',
    Name: 'Cedar Hills',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_TX_4537', Name: 'Aledo', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IN_4538',
    Name: 'Sellersburg',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_OK_4539',
    Name: 'Blanchard',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_AL_4540',
    Name: 'Southside',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_TX_4541', Name: 'Whitehouse', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_LA_4542',
    Name: 'Merrydale',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_AL_4543',
    Name: 'Sheffield',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_VT_4544',
    Name: 'St. Albans',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_TN_4545',
    Name: 'Oakland',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_OR_4546',
    Name: 'West Haven-Sylvan',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_VA_4547',
    Name: 'Innsbrook',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CO_4548',
    Name: 'Evergreen',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_WV_4549',
    Name: 'Logan',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_GA_4550',
    Name: 'Gresham Park',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_OK_4551', Name: 'Poteau', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_PR_4552',
    Name: 'Cabo Rojo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MN_4553',
    Name: 'New Prague',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NY_4554',
    Name: 'Woodbury',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AK_4555', Name: 'Kodiak', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_PA_4556',
    Name: 'Aliquippa',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_4557',
    Name: 'Bridgeport',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MO_4558',
    Name: 'Berkeley',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_UT_4559', Name: 'Riverdale', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_TN_4560',
    Name: 'Covington',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WI_4561',
    Name: 'Rhinelander',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_LA_4562',
    Name: 'Ville Platte',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_OH_4563',
    Name: 'Olmsted Falls',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_IL_4564', Name: 'Glencoe', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_FL_4565',
    Name: 'Orange Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_OH_4566',
    Name: 'Canal Winchester',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NJ_4567',
    Name: 'Absecon',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NY_4568',
    Name: 'Glens Falls North',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_FL_4569',
    Name: 'Palm Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_4570',
    Name: 'North Sarasota',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_4571',
    Name: 'Franklin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_4572',
    Name: 'Richmond Heights',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CT_4573',
    Name: 'Oakville',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_AL_4574', Name: 'Clanton', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_PA_4575',
    Name: 'Collingdale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_NY_4576', Name: 'Airmont', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_PA_4577',
    Name: 'Quakertown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_4578',
    Name: 'Citrus Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NJ_4579',
    Name: 'Villas',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_IL_4580',
    Name: 'Frankfort Square',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_4581',
    Name: 'West Miami',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_4582', Name: 'Wharton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CO_4583',
    Name: 'Redlands',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NY_4584',
    Name: 'New Square',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MD_4585',
    Name: 'South Kensington',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_PA_4586',
    Name: 'Canonsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_4587',
    Name: 'Edgewood',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_TX_4588', Name: 'Graham', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_4589',
    Name: 'New Albany',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IL_4590',
    Name: 'Clarendon Hills',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_4591',
    Name: 'Needles',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_4592',
    Name: 'Pitman',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_IL_4593',
    Name: 'Rock Falls',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_4594',
    Name: 'Perkasie',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_4595',
    Name: 'Clayton',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_CO_4596', Name: 'Cortez', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_IN_4597',
    Name: 'Princeton',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IL_4598', Name: 'Olney', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_TX_4599', Name: 'Sanger', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_4600',
    Name: 'Seven Corners',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MD_4601',
    Name: 'Cape St. Claire',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_4602', Name: 'Kirby', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_4603',
    Name: 'Glenwood',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_VA_4604',
    Name: 'Dumbarton',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_GA_4605',
    Name: 'Garden City',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CA_4606',
    Name: 'Crestline',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_4607',
    Name: 'Jerseyville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CT_4608',
    Name: 'Thompsonville',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_MO_4609', Name: 'Nevada', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MO_4610',
    Name: 'Des Peres',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MO_4611',
    Name: 'Perryville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NM_4612',
    Name: 'Corrales',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_KY_4613',
    Name: 'Fort Knox',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_RI_4614',
    Name: 'Greenville',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_NJ_4615',
    Name: 'Park Ridge',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_FL_4616',
    Name: 'Fern Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_VA_4617',
    Name: 'Greenbriar',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MD_4618',
    Name: 'Coral Hills',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WA_4619',
    Name: 'Montesano',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MO_4620',
    Name: 'Pleasant Hill',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AL_4621',
    Name: 'Andalusia',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_LA_4622', Name: 'Scott', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_NV_4623',
    Name: 'Incline Village',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_PA_4624',
    Name: 'Glenshaw',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IA_4625',
    Name: 'Mount Pleasant',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_NJ_4626',
    Name: 'Cresskill',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_4627',
    Name: 'Earlimart',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_4628',
    Name: 'Hawthorn Woods',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_4629',
    Name: 'Hoquiam',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NJ_4630',
    Name: 'Brigantine',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_VA_4631',
    Name: 'Fishersville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_4632',
    Name: 'Swissvale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_4633',
    Name: 'Kings Grant',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_4634', Name: 'Kennedale', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_4635',
    Name: 'Matawan',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PR_4636',
    Name: 'San Sebastián',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_TX_4637', Name: 'Monahans', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_4638',
    Name: 'Audubon',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_OH_4639',
    Name: 'North Madison',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WI_4640',
    Name: 'Prairie du Sac',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_SC_4641',
    Name: 'York',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_UT_4642', Name: 'Salem', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_UT_4643', Name: 'Hyrum', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_VA_4644',
    Name: 'Richlands',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_MO_4645', Name: 'Ladue', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_ME_4646', Name: 'Rockland', stateId: 'ME', stateName: 'Maine'},
  {
    Id: 'CIT_US_TX_4647',
    Name: 'Alamo Heights',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_LA_4648',
    Name: 'Reserve',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WA_4649',
    Name: 'Orting',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_AZ_4650',
    Name: 'Tuba City',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_TN_4651',
    Name: 'Signal Mountain',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_CO_4652',
    Name: 'Fairmount',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CT_4653',
    Name: 'Riverside',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_CA_4654',
    Name: 'Corning',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_4655', Name: 'Orrville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_KY_4656', Name: 'Elsmere', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_VA_4657',
    Name: 'Farmville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_OR_4658', Name: 'Sheridan', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_PA_4659',
    Name: 'Sanatoga',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AK_4660',
    Name: 'Kalifornsky',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_IL_4661',
    Name: 'Willowbrook',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_4662',
    Name: 'Springfield',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_4663',
    Name: 'Green Cove Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NJ_4664',
    Name: 'White Horse',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_OH_4665',
    Name: 'Perry Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IL_4666',
    Name: 'Lake Villa',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AL_4667',
    Name: 'Guntersville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NJ_4668',
    Name: 'Montvale',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_CO_4669', Name: 'Aspen', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_CA_4670', Name: 'Ione', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_FL_4671',
    Name: 'Brooksville',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_4672',
    Name: 'Riverside',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_4673', Name: 'Wyoming', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_4674',
    Name: 'West Frankfort',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_4675',
    Name: 'Indian Harbour Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_4676',
    Name: 'Lake Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_4677', Name: 'Lucas', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OK_4678',
    Name: 'Piedmont',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_VA_4679',
    Name: 'Franklin',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NC_4680',
    Name: 'Conover',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_AL_4681', Name: 'Wetumpka', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MN_4682',
    Name: 'East Grand Forks',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MO_4683',
    Name: 'Park Hills',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MT_4684', Name: 'Laurel', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_MD_4685',
    Name: 'Edgemere',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_PA_4686',
    Name: 'Morrisville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_WY_4687', Name: 'Rawlins', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_NJ_4688',
    Name: 'Succasunna',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_TX_4689', Name: 'Perryton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_4690',
    Name: 'Closter',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MI_4691',
    Name: 'St. Johns',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WA_4692',
    Name: 'Terrace Heights',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MD_4693',
    Name: 'Frostburg',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NC_4694',
    Name: 'Rolesville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MS_4695',
    Name: 'Booneville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_OK_4696',
    Name: 'Sallisaw',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_PA_4697',
    Name: 'East York',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AK_4698', Name: 'Sitka', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_AR_4699',
    Name: 'Stuttgart',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NY_4700',
    Name: 'Malverne',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NJ_4701',
    Name: 'Mystic Island',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_4702',
    Name: 'Willow Street',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MD_4703',
    Name: 'Lansdowne',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_4704', Name: 'Hillsboro', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_4705',
    Name: 'Summit View',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_AZ_4706',
    Name: 'Corona de Tucson',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_ID_4707', Name: 'Middleton', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_AL_4708',
    Name: 'Tuscumbia',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_MI_4709', Name: 'Mason', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_TN_4710',
    Name: 'Humboldt',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MT_4711',
    Name: 'Helena Valley Southeast',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MO_4712',
    Name: 'Sunset Hills',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_VA_4713',
    Name: 'University of Virginia',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_LA_4714', Name: 'Prien', stateId: 'LA', stateName: 'Louisiana'},
  {Id: 'CIT_US_TX_4715', Name: 'Onalaska', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_4716',
    Name: 'Pine Lake Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_4717',
    Name: 'Los Altos Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_4718', Name: 'Santa Clara', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_IN_4719',
    Name: 'Monticello',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NC_4720',
    Name: 'Ogden',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_VA_4721',
    Name: 'Lake of the Woods',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_NY_4722', Name: 'Congers', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_NJ_4723',
    Name: 'Ashland',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_4724',
    Name: 'August',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_HI_4725', Name: 'Makaha', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_CA_4726',
    Name: 'Spring Valley Lake',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NY_4727', Name: 'Bayport', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_IL_4728', Name: 'Savoy', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_VA_4729',
    Name: 'Fair Lakes',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MI_4730',
    Name: 'Tecumseh',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NJ_4731',
    Name: 'North Haledon',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IL_4732', Name: 'Paris', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_MI_4733', Name: 'Gaylord', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_TX_4734',
    Name: 'Aransas Pass',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_4735',
    Name: 'Carbondale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AL_4736', Name: 'Arab', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_VA_4737',
    Name: 'Long Branch',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_4738',
    Name: 'Fairless Hills',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OH_4739',
    Name: 'Highland Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_WI_4740', Name: 'Somers', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_IN_4741',
    Name: 'Charlestown',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NC_4742',
    Name: 'Fletcher',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_GA_4743',
    Name: 'Druid Hills',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_MN_4744', Name: 'Baxter', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_FL_4745', Name: 'Seffner', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_PA_4746',
    Name: 'Millersville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_FL_4747', Name: 'Wimauma', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_FL_4748',
    Name: 'Miramar Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_4749', Name: 'Hempstead', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_4750',
    Name: 'Gulf Hills',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WV_4751',
    Name: 'Buckhannon',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_4752',
    Name: 'Kulpsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_4753',
    Name: 'Socorro',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_TX_4754',
    Name: 'West Livingston',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_LA_4755',
    Name: 'Tallulah',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_4756',
    Name: 'Midway City',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MN_4757', Name: 'Orono', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_ME_4758', Name: 'Bath', stateId: 'ME', stateName: 'Maine'},
  {
    Id: 'CIT_US_SC_4759',
    Name: 'Clover',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NJ_4760',
    Name: 'Bogota',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NY_4761',
    Name: 'Garden City Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MI_4762',
    Name: 'Petoskey',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_OH_4763',
    Name: 'Grandview Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IL_4764',
    Name: 'Harwood Heights',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_4765',
    Name: 'Beaver Falls',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OR_4766', Name: 'Tillamook', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_LA_4767',
    Name: 'Oak Hills Place',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_LA_4768',
    Name: 'Westwego',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_GA_4769',
    Name: 'Flowery Branch',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MI_4770',
    Name: 'St. Joseph',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_MI_4771', Name: 'Holly', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_WA_4772',
    Name: 'Quincy',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_4773',
    Name: 'Larkfield-Wikiup',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_4774',
    Name: 'Frostproof',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_4775', Name: 'Dalhart', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MD_4776',
    Name: 'Garrison',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_4777',
    Name: 'Gonzales',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_CA_4778', Name: 'Orosi', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_NJ_4779',
    Name: 'Runnemede',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_KS_4780', Name: 'Bel Aire', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WA_4781',
    Name: 'Milton',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_MO_4782', Name: 'Aurora', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_CA_4783',
    Name: 'Firebaugh',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MT_4784',
    Name: 'Whitefish',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MN_4785',
    Name: 'Mahtomedi',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NJ_4786',
    Name: 'Haledon',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_FL_4787',
    Name: 'West Vero Corridor',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_VA_4788', Name: 'Floris', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_TN_4789',
    Name: 'Savannah',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_4790',
    Name: 'Dormont',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_4791',
    Name: 'Mount Joy',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_GA_4792',
    Name: 'McRae-Helena',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CO_4793',
    Name: 'Trinidad',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_OH_4794', Name: 'Kenton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MO_4795',
    Name: 'Carl Junction',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_4796',
    Name: 'Alondra Park',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_4797',
    Name: 'Southeast Arcadia',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_MI_4798', Name: 'Paw Paw', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_SC_4799',
    Name: 'Lake City',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_AZ_4800',
    Name: 'Colorado City',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_MO_4801',
    Name: 'Oak Grove',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WV_4802',
    Name: 'Moundsville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_RI_4803',
    Name: 'Cumberland Hill',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_MD_4804',
    Name: 'Four Corners',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_4805',
    Name: 'Montecito',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_4806', Name: 'Denison', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_GA_4807',
    Name: 'Locust Grove',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NC_4808',
    Name: 'Half Moon',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_SC_4809',
    Name: 'Lugoff',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_KY_4810',
    Name: 'Fort Mitchell',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IL_4811',
    Name: 'Manhattan',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_4812',
    Name: 'North Weeki Wachee',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_4813',
    Name: 'Mammoth Lakes',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_4814',
    Name: 'Johnstown',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NJ_4815',
    Name: 'Spotswood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_4816',
    Name: 'Waynesburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_4817', Name: 'Thomson', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_GA_4818', Name: 'Irondale', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CA_4819',
    Name: 'Westlake Village',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_4820',
    Name: 'Castle Shannon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IL_4821', Name: 'Chester', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_4822', Name: 'Cheviot', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_4823', Name: 'Napoleon', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NJ_4824',
    Name: 'Upper Saddle River',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_NY_4825', Name: 'Mahopac', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_MI_4826',
    Name: 'Temperance',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OH_4827', Name: 'Oberlin', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CO_4828',
    Name: 'Twin Lakes',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NC_4829',
    Name: 'Lake Norman of Catawba',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TN_4830',
    Name: 'Jefferson City',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_GA_4831',
    Name: 'Country Club Estates',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NJ_4832',
    Name: 'Kenilworth',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_ME_4833', Name: 'Ellsworth', stateId: 'ME', stateName: 'Maine'},
  {
    Id: 'CIT_US_FL_4834',
    Name: 'Wedgefield',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_WI_4835', Name: 'Monona', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_MI_4836',
    Name: 'Center Line',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_FL_4837',
    Name: 'Venice Gardens',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_4838',
    Name: 'Broadview Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_4839',
    Name: 'Pismo Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AR_4840', Name: 'Beebe', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_CO_4841',
    Name: 'Milliken',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NC_4842',
    Name: 'Black Mountain',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NJ_4843',
    Name: 'Crestwood Village',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_AK_4844',
    Name: 'Meadow Lakes',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_NY_4845',
    Name: 'Center Moriches',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_4846',
    Name: 'Port Jefferson',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OH_4847', Name: 'Middleport', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_4848', Name: 'Eaton', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AL_4849', Name: 'Tuskegee', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_CA_4850',
    Name: 'South San Gabriel',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WA_4851',
    Name: 'Ephrata',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_VA_4852',
    Name: 'Rockwood',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_LA_4853',
    Name: 'Leesville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NJ_4854',
    Name: 'Oradell',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MN_4855',
    Name: 'Minnetrista',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_NE_4856', Name: 'Gering', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_TN_4857',
    Name: 'Harrison',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_KY_4858', Name: 'Hazard', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_UT_4859', Name: 'Summit Park', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_CA_4860',
    Name: 'Cherry Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_WA_4861', Name: 'Omak', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_CA_4862',
    Name: 'Vandenberg Village',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_4863',
    Name: 'West Dundee',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_4864',
    Name: 'Blandon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_4865',
    Name: 'Duvall',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_VA_4866', Name: 'Vinton', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_TX_4867', Name: 'Joshua', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WV_4868',
    Name: 'Oak Hill',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_CO_4869',
    Name: 'Monument',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NY_4870',
    Name: 'Croton-on-Hudson',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OH_4871', Name: 'Bellevue', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_4872',
    Name: 'Briarcliff Manor',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MD_4873',
    Name: 'Mount Rainier',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NE_4874',
    Name: 'Alliance',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MO_4875',
    Name: 'Maplewood',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AZ_4876',
    Name: 'Wickenburg',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_WA_4877', Name: 'Selah', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_MN_4878',
    Name: 'Shorewood',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MT_4879',
    Name: 'Helena Valley West Central',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_CO_4880',
    Name: 'Applewood',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_WI_4881',
    Name: 'Kronenwetter',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_4882',
    Name: 'Gilberts',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IN_4883',
    Name: 'Bargersville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_GA_4884', Name: 'Hampton', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WA_4885',
    Name: 'Sudden Valley',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_VA_4886',
    Name: 'Hollymead',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_GA_4887', Name: 'Rockmart', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NY_4888',
    Name: 'Lake Carmel',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_MT_4889', Name: 'Lockwood', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_MN_4890',
    Name: 'Wyoming',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_4891',
    Name: 'Lake Dallas',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NJ_4892',
    Name: 'Bloomingdale',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_4893',
    Name: 'Rolling Hills Estates',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_4894',
    Name: 'North Bay Village',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_TN_4895',
    Name: 'South Cleveland',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_CA_4896',
    Name: 'Willits',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_4897', Name: 'Clinton', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_OK_4898',
    Name: 'Skiatook',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_IA_4899', Name: 'Decorah', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_4900',
    Name: 'Brookhaven',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_4901',
    Name: 'Diamondhead',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_4902',
    Name: 'Conshohocken',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_4903',
    Name: 'Floresville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TN_4904', Name: 'Ripley', stateId: 'TN', stateName: 'Tennessee'},
  {Id: 'CIT_US_OH_4905', Name: 'Chardon', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_KY_4906',
    Name: 'Morehead',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_WI_4907', Name: 'Ripon', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_FL_4908', Name: 'Odessa', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_PA_4909',
    Name: 'Bellevue',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MD_4910', Name: 'Mayo', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_NJ_4911',
    Name: 'Northfield',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MI_4912',
    Name: 'Menominee',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_4913',
    Name: 'Pleasant Hills',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_FL_4914', Name: 'Pahokee', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_HI_4915', Name: 'Ahuimanu', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_IL_4916', Name: 'Crete', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_VA_4917',
    Name: 'Loudoun Valley Estates',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NY_4918',
    Name: 'Chestnut Ridge',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_VA_4919',
    Name: 'Laurel Hill',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IL_4920',
    Name: 'Island Lake',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_4921',
    Name: 'Oak Brook',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_DE_4922', Name: 'Seaford', stateId: 'DE', stateName: 'Delaware'},
  {
    Id: 'CIT_US_MT_4923',
    Name: 'Evergreen',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_IL_4924',
    Name: 'Mascoutah',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IN_4925', Name: 'Brazil', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NC_4926',
    Name: 'Aberdeen',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OR_4927',
    Name: 'Jennings Lodge',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_WI_4928',
    Name: 'Jefferson',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TX_4929',
    Name: 'Paloma Creek South',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NY_4930',
    Name: 'Manhasset',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OR_4931', Name: 'Green', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_TX_4932', Name: 'Lampasas', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_4933',
    Name: 'Village Green-Green Ridge',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CT_4934',
    Name: 'Kensington',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_VA_4935',
    Name: 'Fort Belvoir',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MO_4936',
    Name: 'Brentwood',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_NV_4937', Name: 'Laughlin', stateId: 'NV', stateName: 'Nevada'},
  {Id: 'CIT_US_NM_4938', Name: 'Aztec', stateId: 'NM', stateName: 'New Mexico'},
  {
    Id: 'CIT_US_MS_4939',
    Name: 'St. Martin',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CO_4940',
    Name: 'La Junta',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_MO_4941', Name: 'Murphy', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_FL_4942',
    Name: 'Southwest Ranches',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_FL_4943', Name: 'Quincy', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_TX_4944',
    Name: 'Richland Hills',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_4945',
    Name: 'Maryville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MD_4946',
    Name: 'Baltimore Highlands',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NJ_4947',
    Name: 'Caldwell',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_IL_4948',
    Name: 'Hillside',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_4949', Name: 'Columbiana', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_4950', Name: 'Logan', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IN_4951',
    Name: 'Batesville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CT_4952',
    Name: 'Glastonbury Center',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_WI_4953',
    Name: 'Sheboygan Falls',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MD_4954',
    Name: 'Temple Hills',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_VA_4955',
    Name: 'Wytheville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MN_4956',
    Name: 'St. Francis',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_4957', Name: 'Hitchcock', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AZ_4958', Name: 'Catalina', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_KS_4959',
    Name: 'Bonner Springs',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_IL_4960',
    Name: 'Long Grove',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_4961',
    Name: 'Camp Hill',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MA_4962',
    Name: 'Mansfield Center',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_DE_4963',
    Name: 'Wilmington Manor',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_CA_4964',
    Name: 'Topanga',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_4965',
    Name: 'Downingtown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_4966',
    Name: 'Grand Ledge',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_4967',
    Name: 'Old Forge',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_4968',
    Name: 'Lincolnshire',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_NE_4969', Name: 'Blair', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_TX_4970',
    Name: 'Jersey Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_TN_4971',
    Name: 'Lexington',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_UT_4972', Name: 'Richfield', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_GA_4973',
    Name: 'Centerville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NJ_4974',
    Name: 'West Long Branch',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MI_4975',
    Name: 'Flushing',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_KY_4976',
    Name: 'Middletown',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_VA_4977', Name: 'Ashland', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_CO_4978',
    Name: 'Fruitvale',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_TX_4979', Name: 'Seminole', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_4980',
    Name: 'Altoona',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MI_4981',
    Name: 'Grand Blanc',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_VA_4982',
    Name: 'Abingdon',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_TX_4983',
    Name: 'Bridge City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CA_4984',
    Name: 'Woodlake',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_4985',
    Name: 'Hastings-on-Hudson',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MD_4986',
    Name: 'Bryans Road',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_NE_4987', Name: 'York', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_WI_4988',
    Name: 'Ashland',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_4989',
    Name: 'Princeton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SC_4990',
    Name: 'Sangaree',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CA_4991',
    Name: 'Templeton',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_4992',
    Name: 'Los Fresnos',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_UT_4993', Name: 'South Weber', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_ND_4994',
    Name: 'Watford City',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MN_4995',
    Name: 'Crookston',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NJ_4996',
    Name: 'Madison Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_VA_4997',
    Name: 'Covington',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_4998',
    Name: 'Latrobe',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_4999',
    Name: 'DeFuniak Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_5000',
    Name: 'Williamsburg',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MO_5001',
    Name: 'Olivette',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NY_5002',
    Name: 'Baldwinsville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_5003',
    Name: 'Sound Beach',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_5004',
    Name: 'Winters',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PR_5005',
    Name: 'Aibonito',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_PA_5006',
    Name: 'Glenside',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CO_5007',
    Name: 'Breckenridge',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CA_5008',
    Name: 'Interlaken',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_5009',
    Name: 'Southgate',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_NY_5010', Name: 'Medina', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_NJ_5011',
    Name: 'Strathmore',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NY_5012',
    Name: 'Greenville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OH_5013', Name: 'Seville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AK_5014', Name: 'Kenai', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_PA_5015',
    Name: 'Wilson',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_5016',
    Name: 'Carnegie',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_5017', Name: 'Aubrey', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_5018',
    Name: 'Pittston',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_5019',
    Name: 'Holly Springs',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_TX_5020', Name: 'Rio Bravo', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OH_5021',
    Name: 'Withamsville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_TX_5022', Name: 'Kaufman', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_5023', Name: 'Campbell', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_5024',
    Name: 'Guadalupe',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_5025', Name: 'Providence', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_MI_5026', Name: 'Milan', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_CA_5027',
    Name: 'Oroville East',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CO_5028',
    Name: 'Estes Park',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MN_5029',
    Name: 'Waite Park',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_WI_5030', Name: 'Antigo', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_KS_5031', Name: 'Park City', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_MO_5032', Name: 'Forsyth', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_FL_5033', Name: 'McGregor', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_SC_5034',
    Name: 'Cheraw',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_GA_5035',
    Name: 'Swainsboro',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_TX_5036', Name: 'Jasper', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AR_5037', Name: 'Wynne', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_IL_5038',
    Name: 'Round Lake Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_5039',
    Name: 'Quarryville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_CO_5040', Name: 'Salida', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_VA_5041',
    Name: 'South Boston',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_FL_5042',
    Name: 'Citrus Hills',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NY_5043',
    Name: 'Albion',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_5044',
    Name: 'Wading River',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IL_5045', Name: 'Salem', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CA_5046',
    Name: 'Boyes Hot Springs',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_ID_5047', Name: 'Payette', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_OK_5048', Name: 'Grove', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_PA_5049',
    Name: 'Gettysburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_5050', Name: 'Highlands', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SC_5051',
    Name: 'Welcome',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_MN_5052', Name: 'Kasson', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_WA_5053',
    Name: 'Granite Falls',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IA_5054', Name: 'Creston', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_5055', Name: 'Clear Lake', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AK_5056',
    Name: 'Steele Creek',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_KS_5057',
    Name: 'Fort Scott',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_NJ_5058',
    Name: 'Fanwood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_HI_5059', Name: 'Pukalani', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_TX_5060',
    Name: 'Hornsby Bend',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CO_5061',
    Name: 'Carbondale',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MD_5062',
    Name: 'Robinwood',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_IA_5063', Name: 'Perry', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_5064',
    Name: 'Sebastopol',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IA_5065',
    Name: 'Webster City',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_MI_5066',
    Name: 'Hastings',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_UT_5067', Name: 'Plain City', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_FL_5068',
    Name: 'Indiantown',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NJ_5069',
    Name: 'Fort Dix',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_VA_5070',
    Name: 'Collinsville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_5071',
    Name: 'Holtville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KS_5072',
    Name: 'Wellington',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_CA_5073',
    Name: 'Piñon Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_GA_5074', Name: 'Auburn', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MD_5075',
    Name: 'Thurmont',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_LA_5076',
    Name: 'Old Jefferson',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NY_5077',
    Name: 'North Patchogue',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MI_5078',
    Name: 'Brighton',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_LA_5079',
    Name: 'Oakdale',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PR_5080',
    Name: 'San Antonio',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_CO_5081', Name: 'Lamar', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_NJ_5082',
    Name: 'Butler',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WA_5083',
    Name: 'Chehalis',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NC_5084',
    Name: 'Etowah',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NJ_5085',
    Name: 'East Franklin',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WA_5086',
    Name: 'Waller',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_5087',
    Name: 'Windsor',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_NY_5088', Name: 'Scotia', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_MO_5089',
    Name: 'Marshfield',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_ND_5090',
    Name: 'Devils Lake',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_TX_5091', Name: 'River Oaks', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_5092',
    Name: 'Waupaca',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WA_5093',
    Name: 'Wapato',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_IL_5094',
    Name: 'South Beloit',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_5095',
    Name: 'Baldwin Harbor',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_HI_5096', Name: 'Kula', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_IL_5097',
    Name: 'Broadview',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OK_5098', Name: 'Cushing', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_DE_5099',
    Name: 'Pike Creek',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {Id: 'CIT_US_TN_5100', Name: 'Milan', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_MS_5101',
    Name: 'Senatobia',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PR_5102',
    Name: 'Juncos',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_IA_5103',
    Name: 'Sioux Center',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_IL_5104',
    Name: 'Calumet Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_5105',
    Name: 'Escalon',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PR_5106',
    Name: 'Luquillo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_WA_5107',
    Name: 'Lake Shore',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_TN_5108',
    Name: 'Pulaski',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NJ_5109',
    Name: 'Emerson',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_5110',
    Name: 'Bernardsville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_ME_5111', Name: 'Caribou', stateId: 'ME', stateName: 'Maine'},
  {Id: 'CIT_US_OK_5112', Name: 'Tuttle', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_GA_5113',
    Name: 'LaFayette',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PA_5114',
    Name: 'Palmyra',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_5115',
    Name: 'Joshua Tree',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_5116',
    Name: 'Leola',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_5117',
    Name: 'Dayton',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_FL_5118', Name: 'Inwood', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NJ_5119',
    Name: 'Glen Ridge',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_5120',
    Name: 'Plumas Lake',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_5121',
    Name: 'Hales Corners',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_5122',
    Name: 'Hughson',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OR_5123', Name: 'Scappoose', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_NE_5124', Name: 'McCook', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_TX_5125', Name: 'Lago Vista', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MT_5126',
    Name: 'Columbia Falls',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_OH_5127', Name: 'Dry Run', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_5128',
    Name: 'Jackson',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MI_5129',
    Name: 'Marshall',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_VA_5130',
    Name: 'Montrose',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MN_5131',
    Name: 'Albertville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NC_5132',
    Name: 'Porters Neck',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_5133',
    Name: 'Gold River',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_5134',
    Name: 'Berlin',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WI_5135',
    Name: 'Delafield',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WI_5136',
    Name: 'Mount Horeb',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_VA_5137',
    Name: 'Big Stone Gap',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_5138', Name: 'Gonzales', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KY_5139',
    Name: 'Pikeville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NJ_5140',
    Name: 'Haddon Heights',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_AZ_5141', Name: 'Page', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_CA_5142',
    Name: 'Fairfax',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_CA_5143', Name: 'Yreka', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_IL_5144', Name: 'Benton', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_NY_5145', Name: 'Norwich', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_IL_5146', Name: 'Marengo', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_5147', Name: 'Fairlawn', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_5148',
    Name: 'Robinson',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PR_5149',
    Name: 'Gurabo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_GA_5150', Name: 'Tyrone', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_DE_5151',
    Name: 'North Star',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {Id: 'CIT_US_OK_5152', Name: 'Purcell', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_MI_5153', Name: 'Lowell', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_PA_5154',
    Name: 'Hatboro',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_5155', Name: 'Landen', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_5156',
    Name: 'Dos Palos',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_5157',
    Name: 'Oceano',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_5158',
    Name: 'Sappington',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IN_5159',
    Name: 'McCordsville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IL_5160', Name: 'Silvis', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_AR_5161', Name: 'Newport', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_CT_5162',
    Name: 'Southwood Acres',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_CA_5163',
    Name: 'Mount Shasta',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_CA_5164', Name: 'Ojai', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_NY_5165',
    Name: 'Monticello',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_KY_5166',
    Name: 'Villa Hills',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_LA_5167',
    Name: 'St. Gabriel',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_WY_5168', Name: 'Lander', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_AK_5169', Name: 'Palmer', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_GA_5170',
    Name: 'Dock Junction',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_FL_5171',
    Name: 'Keystone Heights',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_HI_5172', Name: 'Waikele', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_PA_5173',
    Name: 'White Oak',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_5174',
    Name: 'Hillcrest',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IL_5175', Name: 'Rockton', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_IL_5176',
    Name: 'Park City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_5177',
    Name: 'Ashland',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CO_5178',
    Name: 'Florence',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_ME_5179', Name: 'Old Town', stateId: 'ME', stateName: 'Maine'},
  {
    Id: 'CIT_US_WI_5180',
    Name: 'Edgerton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WI_5181',
    Name: 'New London',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WA_5182',
    Name: 'North Bend',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IA_5183', Name: 'Hiawatha', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OH_5184', Name: 'Hubbard', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MI_5185',
    Name: 'River Rouge',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_5186',
    Name: 'Sandwich',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_NM_5187', Name: 'Belen', stateId: 'NM', stateName: 'New Mexico'},
  {Id: 'CIT_US_VA_5188', Name: 'Marion', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_FL_5189',
    Name: 'Inverness',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_5190',
    Name: 'Cotati',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_5191', Name: 'Wauseon', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_5192',
    Name: 'Mills River',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_5193',
    Name: 'Saranac Lake',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_FL_5194', Name: 'Sanibel', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_AR_5195',
    Name: 'Farmington',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_AK_5196', Name: 'Soldotna', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_NY_5197',
    Name: 'Port Jefferson Station',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AL_5198',
    Name: 'Greenville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MA_5199',
    Name: 'West Concord',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_OH_5200', Name: 'New Bremen', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OH_5201',
    Name: 'Mentor-on-the-Lake',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_5202',
    Name: 'Ancient Oaks',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_UT_5203', Name: 'Farr West', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_FL_5204',
    Name: 'South Apopka',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NC_5205',
    Name: 'Pembroke',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PR_5206',
    Name: 'Canóvanas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_TX_5207',
    Name: 'Providence Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_5208',
    Name: 'Inverness',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_CO_5209', Name: 'Gypsum', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_WA_5210',
    Name: 'Clarkston',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_AZ_5211', Name: 'Tolleson', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_VT_5212',
    Name: 'Montpelier',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_LA_5213',
    Name: 'New Roads',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_LA_5214',
    Name: 'Ponchatoula',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_5215',
    Name: 'Camp Pendleton North',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_5216',
    Name: 'Smithville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_LA_5217',
    Name: 'Eden Isle',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NJ_5218',
    Name: 'Pomona',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_IA_5219',
    Name: 'Charles City',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_WA_5220',
    Name: 'Stanwood',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_LA_5221',
    Name: 'St. Rose',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MN_5222',
    Name: 'St. Joseph',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MI_5223',
    Name: 'Hudsonville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_KS_5224',
    Name: 'Spring Hill',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_MS_5225',
    Name: 'Philadelphia',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OH_5226', Name: 'Bellbrook', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_5227', Name: 'Mexia', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_5228', Name: 'Kenwood', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MA_5229',
    Name: 'Middleborough Center',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_VA_5230', Name: 'Mantua', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_HI_5231', Name: 'Makawao', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_NC_5232',
    Name: 'Gibsonville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_FL_5233',
    Name: 'Dade City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_LA_5234',
    Name: 'Red Chute',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_LA_5235',
    Name: 'Village St. George',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_VT_5236', Name: 'Winooski', stateId: 'VT', stateName: 'Vermont'},
  {Id: 'CIT_US_FL_5237', Name: 'Sky Lake', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_KS_5238',
    Name: 'Valley Center',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_OR_5239', Name: 'Umatilla', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_KY_5240',
    Name: 'Oak Grove',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_VA_5241',
    Name: 'Strasburg',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_OH_5242', Name: 'Geneva', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_5243',
    Name: 'Whiteville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_5244',
    Name: 'University of California-Davis',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PR_5245',
    Name: 'Sabana Seca',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_UT_5246', Name: 'Ephraim', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_CO_5247',
    Name: 'Lochbuie',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_VA_5248',
    Name: 'North Springfield',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_FL_5249',
    Name: 'Longboat Key',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_GA_5250',
    Name: 'Dahlonega',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NE_5251',
    Name: 'Nebraska City',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_SC_5252',
    Name: 'Hardeeville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_5253',
    Name: 'Connellsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WY_5254',
    Name: 'Torrington',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_PA_5255',
    Name: 'New Cumberland',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MA_5256',
    Name: 'Pinehurst',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_CA_5257', Name: 'Huron', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_CA_5258',
    Name: 'Alta Sierra',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_5259', Name: 'Northridge', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_UT_5260', Name: 'Moab', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_FL_5261', Name: 'Wildwood', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_NY_5262', Name: 'Baywood', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_NY_5263',
    Name: 'Northport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_NE_5264', Name: 'Ralston', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_SD_5265',
    Name: 'Madison',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_WA_5266',
    Name: 'Tanglewilde',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_FL_5267',
    Name: 'Ormond-by-the-Sea',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_5268', Name: 'Delphos', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MD_5269',
    Name: 'Stevensville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_HI_5270', Name: 'Lihue', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_TX_5271', Name: 'Murillo', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MI_5272',
    Name: 'St. Louis',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WA_5273',
    Name: 'Hobart',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NY_5274',
    Name: 'Pleasantville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_FL_5275',
    Name: 'Neptune Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_TX_5276',
    Name: 'Crystal City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NY_5277',
    Name: 'Williston Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WI_5278',
    Name: 'Union Grove',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MS_5279',
    Name: 'Richland',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_VA_5280', Name: 'Bedford', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_FL_5281',
    Name: 'Belle Isle',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_VA_5282',
    Name: 'Woodstock',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NC_5283',
    Name: 'Franklin',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OK_5284',
    Name: 'Collinsville',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_FL_5285',
    Name: 'Willow Oak',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MD_5286',
    Name: 'Emmitsburg',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_5287',
    Name: 'Willows',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_5288',
    Name: 'Parkville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_UT_5289', Name: 'Roosevelt', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NY_5290',
    Name: 'East Hills',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_CA_5291', Name: 'Acton', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_TX_5292', Name: 'La Feria', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_5293', Name: 'Washington', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_NY_5294', Name: 'Tappan', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_MA_5295',
    Name: 'Cochituate',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_MA_5296',
    Name: 'Whitinsville',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_MS_5297',
    Name: 'Batesville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_FL_5298',
    Name: 'Lake Lorraine',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_NE_5299', Name: 'Seward', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_NJ_5300',
    Name: 'Midland Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NV_5301',
    Name: 'Moapa Valley',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_PA_5302',
    Name: 'Middletown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KY_5303', Name: 'Corbin', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_NJ_5304',
    Name: 'Dunellen',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WI_5305',
    Name: 'Jackson',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MD_5306',
    Name: 'Riverdale Park',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_IA_5307', Name: 'Knoxville', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NC_5308',
    Name: 'Unionville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CO_5309',
    Name: 'Gunnison',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_GA_5310', Name: 'Morrow', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_VA_5311',
    Name: 'Sandston',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MN_5312',
    Name: 'Belle Plaine',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_CA_5313', Name: 'Nuevo', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_UT_5314', Name: 'Enoch', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_WA_5315',
    Name: 'Pacific',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PR_5316',
    Name: 'Utuado',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_OH_5317', Name: 'Canfield', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OH_5318',
    Name: 'Independence',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_TX_5319', Name: 'Elsa', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SC_5320',
    Name: 'Centerville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_GA_5321', Name: 'Commerce', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_GA_5322', Name: 'Austell', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_PA_5323',
    Name: 'Glenolden',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_5324',
    Name: 'Parkville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NC_5325',
    Name: 'Hillsborough',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_VA_5326', Name: 'Crozet', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_CA_5327',
    Name: 'Pollock Pines',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NY_5328', Name: 'Nyack', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_PA_5329',
    Name: 'Tyrone',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_LA_5330', Name: 'Larose', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_IL_5331',
    Name: 'Lawrenceville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AL_5332',
    Name: 'Brook Highland',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_WI_5333',
    Name: 'Cottage Grove',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MO_5334', Name: 'De Soto', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_NJ_5335',
    Name: 'Palmyra',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IN_5336', Name: 'Nappanee', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_CA_5337',
    Name: 'Atherton',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_5338', Name: 'Nibley', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_TX_5339', Name: 'Kingsland', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MI_5340',
    Name: 'Walled Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PR_5341',
    Name: 'San Lorenzo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_CT_5342',
    Name: 'Winsted',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_MO_5343',
    Name: 'Bonne Terre',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_FL_5344', Name: 'Live Oak', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CT_5345',
    Name: 'Rockville',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_CA_5346',
    Name: 'Calipatria',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_WA_5347', Name: 'Mead', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_WA_5348',
    Name: 'Parkwood',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KY_5349',
    Name: 'Russellville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_AL_5350', Name: 'Pinson', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NC_5351',
    Name: 'Selma',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TX_5352',
    Name: 'Marble Falls',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_5353', Name: 'Decatur', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_5354',
    Name: 'Fort Myers Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WV_5355',
    Name: 'Dunbar',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MI_5356',
    Name: 'Byron Center',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_TX_5357',
    Name: 'Homestead Meadows South',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_OH_5358',
    Name: 'Portage Lakes',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_AL_5359',
    Name: 'Meridianville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_CA_5360',
    Name: 'Homeland',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AR_5361', Name: 'Osceola', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_IN_5362', Name: 'Syracuse', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PA_5363',
    Name: 'Souderton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_5364',
    Name: 'Flatwoods',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MI_5365',
    Name: 'Fair Plain',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_AK_5366', Name: 'Gateway', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_NY_5367',
    Name: 'Mount Ivy',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OR_5368',
    Name: 'Milton-Freewater',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_FL_5369',
    Name: 'River Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PR_5370',
    Name: 'Yabucoa',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_CA_5371',
    Name: 'Sausalito',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_5372',
    Name: 'South Lockport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_5373',
    Name: 'Ridley Park',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_5374',
    Name: 'Highland Heights',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_VA_5375',
    Name: 'Woodlake',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_GA_5376',
    Name: 'Barnesville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MI_5377',
    Name: 'Berrien Springs',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_5378',
    Name: 'St. Helena',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_5379',
    Name: 'Hudson Falls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OK_5380', Name: 'Noble', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_MS_5381',
    Name: 'Gulf Park Estates',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NC_5382',
    Name: 'Oak Ridge',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IN_5383',
    Name: 'Rochester',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PR_5384',
    Name: 'San Isidro',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_IA_5385', Name: 'Orange City', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OK_5386',
    Name: 'Seminole',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_OH_5387', Name: 'Groesbeck', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_NY_5388', Name: 'Springs', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_LA_5389',
    Name: 'Marksville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NM_5390',
    Name: 'Truth or Consequences',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_MI_5391', Name: 'Laurium', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_PA_5392',
    Name: 'Archbald',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_5393', Name: 'Willis', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_5394',
    Name: 'Great Neck Plaza',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_5395',
    Name: 'Rancho Calaveras',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_5396',
    Name: 'Orland Hills',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_5397',
    Name: 'Kentfield',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_WI_5398', Name: 'Kiel', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_IL_5399',
    Name: 'Mount Carmel',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_NE_5400', Name: 'Crete', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_LA_5401', Name: 'Meraux', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_OR_5402',
    Name: 'Garden Home-Whitford',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_FL_5403',
    Name: 'St. Augustine Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NM_5404',
    Name: 'Zuni Pueblo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_IL_5405', Name: 'Mendota', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CT_5406',
    Name: 'Cos Cob',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_GA_5407', Name: 'Adel', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_FL_5408', Name: 'Heathrow', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NY_5409',
    Name: 'Calverton',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_LA_5410',
    Name: 'Galliano',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_CO_5411', Name: 'Eagle', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_IL_5412',
    Name: 'Hillsboro',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_NY_5413', Name: 'Oakdale', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_WA_5414',
    Name: 'Barberton',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NJ_5415',
    Name: 'Keyport',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_FL_5416',
    Name: 'Beacon Square',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_WA_5417', Name: 'Brier', stateId: 'WA', stateName: 'Washington'},
  {Id: 'CIT_US_AR_5418', Name: 'Trumann', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_NJ_5419',
    Name: 'Milltown',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_MT_5420', Name: 'Hamilton', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_KY_5421',
    Name: 'Leitchfield',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OR_5422', Name: 'Creswell', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_IA_5423', Name: 'Bondurant', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NJ_5424',
    Name: 'Stratford',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_TX_5425',
    Name: 'Prairie View',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OK_5426', Name: 'Catoosa', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_KY_5427',
    Name: 'Paintsville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_5428',
    Name: 'Plymouth Meeting',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_5429', Name: 'Bremen', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MD_5430',
    Name: 'Ocean City',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_IL_5431',
    Name: 'Vandalia',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_DE_5432',
    Name: 'Edgemoor',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_NY_5433',
    Name: 'Westmere',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_FL_5434',
    Name: 'Fort Meade',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_FL_5435', Name: 'Osprey', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_5436',
    Name: 'Franklin',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_5437',
    Name: 'Fort Pierce North',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_SD_5438',
    Name: 'Sturgis',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_FL_5439',
    Name: 'West Samoset',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_5440',
    Name: 'Treasure Island',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MN_5441',
    Name: 'Spring Lake Park',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MO_5442',
    Name: 'Black Jack',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WV_5443',
    Name: 'Lewisburg',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_AR_5444',
    Name: 'Heber Springs',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_OR_5445',
    Name: 'West Slope',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_WA_5446',
    Name: 'Woodland',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_FL_5447',
    Name: 'Gulf Breeze',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_NY_5448', Name: 'Viola', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_MI_5449',
    Name: 'Freeland',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WI_5450',
    Name: 'Lake Mills',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IL_5451', Name: 'Byron', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MS_5452',
    Name: 'Latimer',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CA_5453',
    Name: 'Thermalito',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_GA_5454',
    Name: 'Whitemarsh Island',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CA_5455',
    Name: 'Colusa',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_5456', Name: 'Union', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OR_5457',
    Name: 'Junction City',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_CA_5458',
    Name: 'Ben Lomond',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_5459',
    Name: 'University Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CO_5460',
    Name: 'Gleneagle',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NJ_5461',
    Name: 'Mountainside',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_AL_5462',
    Name: 'Moores Mill',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_FL_5463', Name: 'Perry', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MD_5464',
    Name: 'Chestertown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_LA_5465',
    Name: 'St. Martinville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_UT_5466', Name: 'Harrisville', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NJ_5467',
    Name: 'Twin Rivers',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OH_5468', Name: 'Huron', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_5469',
    Name: 'Mechanicstown',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_5470',
    Name: 'Loomis',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_5471',
    Name: 'King',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_5472',
    Name: 'West Glens Falls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AZ_5473',
    Name: 'Youngtown',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_CA_5474',
    Name: 'Lemoore Station',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_5475',
    Name: 'Lutherville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OH_5476', Name: 'Milford', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TN_5477',
    Name: 'Greenbrier',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_FL_5478', Name: 'Lecanto', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_IA_5479', Name: 'Eldridge', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CT_5480',
    Name: 'Old Greenwich',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_OK_5481', Name: 'Idabel', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_FL_5482',
    Name: 'South Patrick Shores',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_5483', Name: 'Sunnyvale', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_5484', Name: 'Bee Cave', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_GA_5485', Name: 'Lovejoy', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WA_5486',
    Name: 'Fircrest',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PR_5487',
    Name: 'Las Piedras',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_IN_5488',
    Name: 'Huntertown',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AR_5489',
    Name: 'Crossett',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MD_5490',
    Name: 'Taneytown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_IN_5491',
    Name: 'Mount Vernon',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_FL_5492',
    Name: 'Fort Myers Shores',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_5493', Name: 'Kirtland', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IN_5494',
    Name: 'Lakes of the Four Seasons',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_SC_5495',
    Name: 'Marion',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CA_5496',
    Name: 'San Martin',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_5497',
    Name: 'Kimberly',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_NY_5498', Name: 'Hewlett', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_KY_5499',
    Name: 'Taylor Mill',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NJ_5500',
    Name: 'Yardville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NY_5501',
    Name: 'East Shoreham',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_5502',
    Name: 'Valley Park',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_5503',
    Name: 'Thousand Palms',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MS_5504',
    Name: 'Amory',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WI_5505',
    Name: 'Columbus',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_5506',
    Name: 'Lake Mathews',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_5507',
    Name: 'Marvin',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_5508',
    Name: 'Fowler',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AK_5509', Name: 'Fishhook', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_PA_5510',
    Name: 'Breinigsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AL_5511', Name: 'Lincoln', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NY_5512',
    Name: 'Myers Corner',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_5513',
    Name: 'South Valley Stream',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TN_5514',
    Name: 'Millersville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_OH_5515', Name: 'Cortland', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PR_5516',
    Name: 'Guánica',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_CA_5517',
    Name: 'Desert Palms',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_5518',
    Name: 'Indian River Estates',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_5519',
    Name: 'Pembroke Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WI_5520',
    Name: 'Lake Hallie',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IN_5521',
    Name: 'Ellettsville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AR_5522',
    Name: 'Prairie Grove',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_HI_5523',
    Name: 'Napili-Honokowai',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_AL_5524',
    Name: 'Montevallo',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_OH_5525',
    Name: 'Mount Healthy',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WA_5526',
    Name: 'Clover Creek',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NJ_5527',
    Name: 'Allendale',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_ID_5528', Name: 'Rupert', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_AZ_5529',
    Name: 'Snowflake',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NY_5530',
    Name: 'Bayville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_5531',
    Name: 'Litchfield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_5532',
    Name: 'Maple Glen',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_5533', Name: 'Eatonton', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_VA_5534',
    Name: 'Bellwood',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_TX_5535',
    Name: 'Lacy-Lakeview',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MS_5536',
    Name: 'Kosciusko',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CO_5537',
    Name: 'Orchard Mesa',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PA_5538',
    Name: 'Roaring Spring',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_5539',
    Name: 'Woodfin',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NJ_5540',
    Name: 'Rumson',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_VA_5541',
    Name: 'Broadway',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_MN_5542', Name: 'Medina', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_SD_5543',
    Name: 'Harrisburg',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_CA_5544',
    Name: 'Bermuda Dunes',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_5545',
    Name: 'Clifton Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_5546',
    Name: 'Wescosville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KS_5547',
    Name: 'Roeland Park',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_MN_5548', Name: 'Dayton', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_CA_5549',
    Name: 'Lucas Valley-Marinwood',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_5550',
    Name: 'Cherryville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_ME_5551', Name: 'Belfast', stateId: 'ME', stateName: 'Maine'},
  {Id: 'CIT_US_IA_5552', Name: 'Nevada', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IN_5553', Name: 'Dunlap', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_FL_5554',
    Name: 'Lauderdale-by-the-Sea',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_TN_5555',
    Name: 'Church Hill',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_VA_5556',
    Name: 'Potomac Mills',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NJ_5557',
    Name: 'Linwood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OH_5558', Name: 'Ashville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_SC_5559',
    Name: 'Valley Falls',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_GA_5560',
    Name: 'Sandersville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PA_5561',
    Name: 'Blairsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_5562', Name: 'Bridgeport', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_5563',
    Name: 'Sturtevant',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MN_5564',
    Name: 'Litchfield',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CO_5565',
    Name: 'Cherry Hills Village',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_IN_5566',
    Name: 'Notre Dame',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AR_5567',
    Name: 'Pocahontas',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_AR_5568',
    Name: 'Morrilton',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NJ_5569',
    Name: 'Barrington',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_VA_5570',
    Name: 'Belle Haven',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PR_5571',
    Name: 'Juana Díaz',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_OK_5572',
    Name: 'Tecumseh',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_CA_5573', Name: 'Mecca', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_CA_5574',
    Name: 'Mira Monte',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_5575',
    Name: 'Cedarhurst',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_5576',
    Name: 'Folcroft',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_5577',
    Name: 'Pea Ridge',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_VA_5578', Name: 'Belmont', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_NJ_5579',
    Name: 'Green Knoll',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_AZ_5580',
    Name: 'Guadalupe',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_OR_5581', Name: 'Veneta', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NY_5582',
    Name: 'North Syracuse',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_5583',
    Name: 'Manorhaven',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_5584',
    Name: 'Battlefield',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PR_5585',
    Name: 'Puerto Real',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_NJ_5586',
    Name: 'North Caldwell',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_KY_5587',
    Name: 'Beaver Dam',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CA_5588',
    Name: 'Ladera Heights',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_5589',
    Name: 'Winthrop Harbor',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MI_5590',
    Name: 'Whitmore Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OH_5591', Name: 'Hillsboro', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AL_5592', Name: 'Brewton', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_TX_5593', Name: 'Kermit', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AL_5594',
    Name: 'Demopolis',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_OR_5595', Name: 'Talent', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_AL_5596', Name: 'Hamilton', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NC_5597',
    Name: 'Trinity',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_5598',
    Name: 'Normandy Park',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_LA_5599',
    Name: 'Bridge City',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_5600',
    Name: 'Ambridge',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_5601',
    Name: 'Amite City',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_5602',
    Name: 'Catasauqua',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_5603',
    Name: 'Cocoa West',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_SC_5604',
    Name: 'Forestbrook',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_AK_5605', Name: 'Bethel', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_HI_5606',
    Name: 'Ewa Villages',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {Id: 'CIT_US_OH_5607', Name: 'Northgate', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AL_5608', Name: 'Oneonta', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_TX_5609',
    Name: 'Mont Belvieu',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CA_5610',
    Name: 'South Monrovia Island',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_5611',
    Name: 'Cold Spring',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_TX_5612', Name: 'Keene', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AR_5613',
    Name: 'Walnut Ridge',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_TN_5614',
    Name: "Thompson's Station",
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_IL_5615',
    Name: 'Stickney',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TN_5616',
    Name: 'Green Hill',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_OH_5617', Name: 'Rossford', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OK_5618',
    Name: 'Blackwell',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_CA_5619',
    Name: 'Contra Costa Centre',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_5620',
    Name: 'Lawrence',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_5621',
    Name: 'Roslyn Heights',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_5622',
    Name: 'South Hill',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_5623',
    Name: 'Tuckahoe',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_HI_5624',
    Name: 'Waikoloa Village',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {Id: 'CIT_US_CA_5625', Name: 'Keyes', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_GA_5626', Name: 'Cumming', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_PA_5627',
    Name: 'Portage',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_5628',
    Name: 'Fox Point',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_5629',
    Name: 'Slippery Rock',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_5630',
    Name: 'Clairton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_5631',
    Name: 'Oakmont',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_5632', Name: 'Fairview', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_LA_5633',
    Name: 'Plaquemine',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NC_5634',
    Name: 'Elkin',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NE_5635',
    Name: 'Plattsmouth',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_AR_5636',
    Name: 'De Queen',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_GA_5637',
    Name: 'Hapeville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NJ_5638',
    Name: 'Laurence Harbor',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IA_5639', Name: 'Atlantic', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_5640',
    Name: 'Castroville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OH_5641',
    Name: 'Russells Point',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_OH_5642',
    Name: 'Martins Ferry',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_5643',
    Name: 'West View',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MI_5644', Name: 'Milford', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_RI_5645',
    Name: 'Kingston',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_CO_5646',
    Name: 'Air Force Academy',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_KS_5647', Name: 'De Soto', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_CO_5648', Name: 'Avon', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_NJ_5649',
    Name: 'Heathcote',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_FL_5650',
    Name: 'Naples Manor',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_5651', Name: 'Rittman', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_5652',
    Name: 'Bangor Base',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_OK_5653',
    Name: 'Anadarko',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_LA_5654',
    Name: 'Schriever',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OK_5655', Name: 'Harrah', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_KY_5656',
    Name: 'Monticello',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MI_5657',
    Name: 'Williamston',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_5658',
    Name: 'Homeacre-Lyndora',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KS_5659', Name: 'Pratt', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_KS_5660', Name: 'Basehor', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_IL_5661', Name: 'Geneseo', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CO_5662',
    Name: 'Severance',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_WA_5663',
    Name: 'Ocean Shores',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_5664',
    Name: 'La Habra Heights',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_5665',
    Name: 'Prospect Park',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_5666',
    Name: 'Ambler',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_5667',
    Name: 'East Rochester',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_KS_5668', Name: 'Mulvane', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_GA_5669', Name: 'Camilla', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_TN_5670',
    Name: 'Lynchburg',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MD_5671',
    Name: 'Hillandale',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CO_5672',
    Name: 'Stratmoor',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_VA_5673',
    Name: 'Buena Vista',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WI_5674',
    Name: 'Prairie du Chien',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NC_5675',
    Name: 'Silver Lake',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_5676',
    Name: 'Irvington',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_KY_5677',
    Name: 'Prestonsburg',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OH_5678', Name: 'Moraine', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_5679',
    Name: 'Penn Yan',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MD_5680',
    Name: 'Forest Glen',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_TX_5681',
    Name: 'Scenic Oaks',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MS_5682',
    Name: 'Columbia',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IL_5683', Name: 'Genoa', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_WA_5684',
    Name: 'Otis Orchards-East Farms',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_MT_5685', Name: 'Sidney', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_MD_5686',
    Name: 'Darnestown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_KY_5687', Name: 'Hebron', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_MT_5688', Name: 'Glendive', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_NJ_5689',
    Name: 'Yorketown',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_SC_5690',
    Name: 'Lake Murray of Richland',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MI_5691',
    Name: 'Ironwood',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_TX_5692', Name: 'Sealy', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_VA_5693', Name: 'Emporia', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_VA_5694',
    Name: 'Carrollton',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_FL_5695',
    Name: 'Inverness Highlands South',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MA_5696',
    Name: 'East Falmouth',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_OH_5697',
    Name: 'Upper Sandusky',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_KY_5698',
    Name: 'Vine Grove',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_AZ_5699',
    Name: 'Litchfield Park',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_TX_5700', Name: 'Devine', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_5701',
    Name: 'Orange Lake',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IA_5702', Name: 'Oelwein', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_TX_5703', Name: 'Carthage', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_5704',
    Name: 'Crystal Lake',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WV_5705',
    Name: 'Hurricane',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IL_5706',
    Name: 'North Riverside',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MD_5707',
    Name: 'Cheverly',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_MD_5708', Name: 'Savage', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_MI_5709',
    Name: 'Bridgeport',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_ND_5710',
    Name: 'Valley City',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_IN_5711', Name: 'Salem', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_WY_5712', Name: 'Douglas', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_IL_5713',
    Name: 'Greenville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_5714',
    Name: 'Orlovista',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_KS_5715', Name: 'Eudora', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MD_5716',
    Name: 'Walkersville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_IN_5717', Name: 'Garrett', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_MD_5718',
    Name: 'Bowleys Quarters',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_PA_5719',
    Name: 'Littlestown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_5720',
    Name: 'Bronxville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NJ_5721',
    Name: 'Kingston Estates',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_TX_5722', Name: 'Burnet', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_5723',
    Name: 'Lincroft',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_MN_5724', Name: 'Jordan', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_MD_5725',
    Name: 'Hampstead',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NC_5726',
    Name: 'Carolina Beach',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IA_5727',
    Name: 'Mount Vernon',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_PA_5728',
    Name: 'Lionville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_5729',
    Name: 'Ramtown',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MI_5730',
    Name: 'Rockford',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WA_5731',
    Name: 'Steilacoom',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_AL_5732', Name: 'Opp', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_KS_5733', Name: 'Abilene', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_TX_5734', Name: 'Rhome', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_CA_5735', Name: 'Aptos', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_OH_5736', Name: 'Belpre', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_5737', Name: 'Crockett', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_5738',
    Name: 'Ellenville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AR_5739',
    Name: 'East End',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_IN_5740',
    Name: 'Rushville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MN_5741',
    Name: 'International Falls',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TN_5742',
    Name: 'Rogersville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WA_5743',
    Name: 'Prosser',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NY_5744',
    Name: 'West Point',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_UT_5745', Name: 'Nephi', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_PA_5746',
    Name: 'Richboro',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_5747',
    Name: 'Cullowhee',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_FL_5748', Name: 'Mims', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_AZ_5749',
    Name: 'Village of Oak Creek',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_GA_5750', Name: 'Jasper', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WV_5751',
    Name: 'Nitro',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NJ_5752',
    Name: 'Wharton',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_IL_5753',
    Name: 'Johnsburg',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_KS_5754',
    Name: 'Fort Riley',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_OH_5755', Name: 'Sunbury', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_5756',
    Name: 'Wollochet',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WV_5757',
    Name: 'Keyser',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_FL_5758', Name: 'Kathleen', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_FL_5759',
    Name: 'Combee Settlement',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_TN_5760',
    Name: 'Henderson',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_KY_5761',
    Name: 'Cynthiana',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NC_5762',
    Name: 'Tabor City',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_5763',
    Name: 'North Fort Lewis',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MS_5764',
    Name: 'West Hattiesburg',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_MI_5765', Name: 'Allegan', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_GA_5766', Name: 'Dacula', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NE_5767',
    Name: 'Schuyler',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_IN_5768', Name: 'Tipton', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_WA_5769',
    Name: 'Clarkston Heights-Vineland',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_VA_5770', Name: 'Galax', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_FL_5771',
    Name: 'Jan Phyl Village',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_5772',
    Name: 'Swarthmore',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_5773',
    Name: 'Carmel Hamlet',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_5774', Name: 'Gladewater', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_5775', Name: 'Iowa Park', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_NY_5776', Name: 'Yaphank', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_MO_5777',
    Name: 'St. John',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_AL_5778', Name: 'Theodore', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_OH_5779', Name: 'Pepper Pike', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_5780',
    Name: 'Red Lion',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_5781',
    Name: 'Hamlet',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_5782', Name: 'White Oak', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MI_5783',
    Name: 'Comstock Northwest',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_FL_5784',
    Name: 'Islamorada, Village of Islands',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_FL_5785', Name: 'Mascotte', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MS_5786',
    Name: 'Pass Christian',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_LA_5787',
    Name: 'Jeanerette',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_MN_5788', Name: 'Delano', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_MS_5789',
    Name: 'Waveland',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NM_5790',
    Name: 'North Hobbs',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_5791',
    Name: 'Forest Hills',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_5792',
    Name: 'El Rio',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_5793',
    Name: 'Lely Resort',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_5794',
    Name: 'East Porterville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WY_5795',
    Name: 'Ranchettes',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_NC_5796',
    Name: 'Boiling Spring Lakes',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_GA_5797',
    Name: 'Stone Mountain',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_OH_5798', Name: 'Covedale', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_5799',
    Name: 'California',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_5800',
    Name: 'St. Robert',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CO_5801',
    Name: 'New Castle',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_OH_5802',
    Name: 'Howland Center',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_TN_5803',
    Name: 'Pigeon Forge',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MI_5804',
    Name: 'Huntington Woods',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MI_5805',
    Name: 'Richmond',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MD_5806',
    Name: 'Fairwood',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NY_5807',
    Name: 'East Glenville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_FL_5808',
    Name: 'Lake Alfred',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_UT_5809', Name: 'Snyderville', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NJ_5810',
    Name: 'Morris Plains',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_WY_5811', Name: 'Powell', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_IL_5812',
    Name: 'Hampshire',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_5813',
    Name: 'Enola',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_5814',
    Name: 'Salton City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_5815',
    Name: 'Blue Bell',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_5816',
    Name: 'Corcoran',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_FL_5817',
    Name: 'Ocean City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_LA_5818', Name: 'Walker', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_NC_5819',
    Name: 'St. James',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_5820', Name: 'Camp Swift', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_5821',
    Name: 'South Run',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_TX_5822',
    Name: 'Pecan Plantation',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IN_5823',
    Name: 'New Whiteland',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_TX_5824',
    Name: 'Cameron Park',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_GA_5825',
    Name: 'Sylvester',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_IN_5826',
    Name: 'Boonville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PA_5827',
    Name: 'Boothwyn',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_5828',
    Name: 'Orange Beach',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MI_5829',
    Name: 'Buena Vista',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_NY_5830', Name: 'Solvay', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_OH_5831',
    Name: 'Champion Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CA_5832',
    Name: 'East Foothills',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_5833',
    Name: 'South Gate Ridge',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_FL_5834', Name: 'Newberry', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_5835',
    Name: 'Saranap',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_5836', Name: 'Glenwood', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_TX_5837', Name: 'Kenedy', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_5838',
    Name: 'Pimmit Hills',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_5839',
    Name: 'Edinboro',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_UT_5840',
    Name: 'Fruit Heights',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {Id: 'CIT_US_LA_5841', Name: 'Addis', stateId: 'LA', stateName: 'Louisiana'},
  {Id: 'CIT_US_HI_5842', Name: 'Wailea', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_WA_5843',
    Name: 'Chelan',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_AR_5844',
    Name: 'Pea Ridge',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_5845',
    Name: 'Blakely',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_5846',
    Name: 'Grayson Valley',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PA_5847',
    Name: 'Corry',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_5848',
    Name: 'Twin Lakes',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_AZ_5849',
    Name: 'Avra Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_PA_5850',
    Name: 'Kennett Square',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_5851', Name: 'Everman', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_5852',
    Name: 'Union Gap',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_AL_5853', Name: 'Satsuma', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_CO_5854',
    Name: 'Sheridan',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MD_5855',
    Name: 'Leonardtown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_GA_5856', Name: 'Eastman', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_FL_5857',
    Name: 'High Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NY_5858',
    Name: 'University at Buffalo',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IN_5859',
    Name: 'Huntingburg',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MS_5860',
    Name: 'Pontotoc',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NY_5861',
    Name: 'East Farmingdale',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OH_5862', Name: 'Clyde', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AZ_5863', Name: 'Eagar', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_CT_5864',
    Name: 'Branford Center',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_WI_5865',
    Name: 'Rib Mountain',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_OK_5866',
    Name: 'Henryetta',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_PA_5867',
    Name: 'Montgomery',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CT_5868',
    Name: 'Simsbury Center',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_IN_5869', Name: 'Clinton', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_WI_5870',
    Name: 'Evansville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_AL_5871', Name: 'Lanett', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_OR_5872',
    Name: 'Raleigh Hills',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_VA_5873',
    Name: 'Bridgewater',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MD_5874',
    Name: 'Glenarden',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_5875',
    Name: 'East Pasadena',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_5876',
    Name: 'Pelican Bay',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_HI_5877',
    Name: 'West Loch Estate',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {Id: 'CIT_US_FL_5878', Name: 'Tequesta', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_WI_5879',
    Name: 'Elm Grove',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NJ_5880',
    Name: 'Carlstadt',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_ME_5881',
    Name: 'North Windham',
    stateId: 'ME',
    stateName: 'Maine',
  },
  {Id: 'CIT_US_GA_5882', Name: 'Cochran', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NY_5883',
    Name: 'Lakeview',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_5884',
    Name: 'Binghamton University',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_5885',
    Name: 'Eucalyptus Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_5886',
    Name: 'Arlington Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IA_5887',
    Name: 'Independence',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_SC_5888',
    Name: 'Laurel Bay',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_OH_5889',
    Name: 'Garrettsville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NV_5890',
    Name: 'Gardnerville',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {Id: 'CIT_US_MN_5891', Name: 'Isanti', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_PA_5892',
    Name: 'Punxsutawney',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_5893',
    Name: 'Country Homes',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_HI_5894',
    Name: 'Wailua Homesteads',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_CA_5895',
    Name: 'Fruitridge Pocket',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NE_5896', Name: 'Sidney', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_KY_5897',
    Name: 'Princeton',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IL_5898',
    Name: 'Bartonville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AK_5899',
    Name: 'Chena Ridge',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_OK_5900',
    Name: 'Pauls Valley',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_TN_5901',
    Name: 'Munford',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_AL_5902', Name: 'Tarrant', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NM_5903',
    Name: 'Los Chaves',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NM_5904',
    Name: 'Los Ranchos de Albuquerque',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NM_5905',
    Name: 'Edgewood',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_VA_5906',
    Name: 'Aquia Harbour',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NV_5907',
    Name: 'Indian Hills',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_CA_5908',
    Name: 'El Granada',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_5909',
    Name: 'Stewartville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_VA_5910', Name: 'Bensley', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_MO_5911',
    Name: 'Shrewsbury',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_5912', Name: 'Alpine', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_KY_5913', Name: 'Harlan', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_NY_5914',
    Name: 'Larchmont',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OH_5915', Name: 'Willard', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_5916', Name: 'Ontario', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OH_5917',
    Name: 'Wheelersburg',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CT_5918',
    Name: 'Cheshire Village',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_CA_5919',
    Name: 'Pleasure Point',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PR_5920',
    Name: 'Arroyo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_NY_5921',
    Name: 'Clifton Springs',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_5922',
    Name: 'South Williamsport',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_5923', Name: 'Nelsonville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_5924', Name: 'Childress', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OR_5925',
    Name: 'Deschutes River Woods',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_WV_5926',
    Name: 'Brookhaven',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_TX_5927',
    Name: 'Carrizo Springs',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MD_5928',
    Name: 'Riverside',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_KY_5929',
    Name: 'Barbourville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_CO_5930', Name: 'Dacono', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_KY_5931', Name: 'Union', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_SD_5932', Name: 'Tea', stateId: 'SD', stateName: 'South Dakota'},
  {
    Id: 'CIT_US_IN_5933',
    Name: 'Rensselaer',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_TX_5934', Name: 'Pinehurst', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_5935',
    Name: 'Finley',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_IN_5936',
    Name: 'Cumberland',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IL_5937',
    Name: 'Chillicothe',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OH_5938',
    Name: 'Fort Shawnee',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IL_5939',
    Name: 'Metropolis',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_5940', Name: 'Maquoketa', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NJ_5941',
    Name: 'Watchung',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_FL_5942',
    Name: 'Davenport',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IN_5943', Name: 'Portland', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_KY_5944',
    Name: 'Williamsburg',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IL_5945',
    Name: 'Du Quoin',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WI_5946',
    Name: 'Baldwin',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IL_5947', Name: 'Elburn', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MD_5948',
    Name: 'Ashton-Sandy Spring',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_TN_5949',
    Name: 'Kingston',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_SC_5950',
    Name: 'Homeland Park',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MT_5951',
    Name: 'Lewistown',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_CA_5952',
    Name: 'Durham',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IN_5953', Name: 'Winfield', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_LA_5954',
    Name: 'Cut Off',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MD_5955',
    Name: 'District Heights',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MS_5956',
    Name: 'Louisville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_5957',
    Name: 'Northern Cambria',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MO_5958', Name: 'Pevely', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MI_5959',
    Name: 'South Monroe',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MD_5960',
    Name: 'Naval Academy',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NY_5961',
    Name: 'North Hills',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_5962',
    Name: 'Lakemoor',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_5963',
    Name: 'Trooper',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_CO_5964', Name: 'Vail', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_LA_5965',
    Name: 'Inniswold',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_5966',
    Name: 'Steelton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_5967',
    Name: 'Northville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NV_5968',
    Name: 'Johnson Lane',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_CA_5969',
    Name: 'Lucerne Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_5970',
    Name: 'St. Clair',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_5971',
    Name: 'East Alton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_VA_5972',
    Name: 'Rocky Mount',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_5973', Name: 'Savannah', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_5974',
    Name: 'Barnhart',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_5975', Name: 'Mila Doce', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_NY_5976', Name: 'Gowanda', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_MI_5977', Name: 'Shields', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_TX_5978', Name: 'Progreso', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_LA_5979', Name: 'Gray', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_IN_5980',
    Name: 'Westville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_TX_5981', Name: 'Yoakum', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SC_5982',
    Name: 'Darlington',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_NM_5983', Name: 'Raton', stateId: 'NM', stateName: 'New Mexico'},
  {
    Id: 'CIT_US_MI_5984',
    Name: 'Goodrich',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_5985',
    Name: 'Countryside',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_5986',
    Name: 'Sullivan City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_5987',
    Name: 'Penn Wynne',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_5988',
    Name: 'Paradise',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CO_5989',
    Name: 'Pagosa Springs',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_TX_5990', Name: 'Woodcreek', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_5991',
    Name: 'Dumfries',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_AK_5992', Name: 'Homer', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_NY_5993',
    Name: 'Wesley Hills',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_5994', Name: 'Mineola', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_5995',
    Name: 'Finderne',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NC_5996',
    Name: 'Zebulon',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MD_5997',
    Name: 'Cresaptown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OH_5998', Name: 'Granville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_5999',
    Name: 'Staunton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_6000',
    Name: 'Taylor',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AL_6001', Name: 'Roanoke', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_CA_6002',
    Name: 'Greenacres',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TN_6003',
    Name: 'Eagleton Village',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_6004',
    Name: 'Selinsgrove',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_6005',
    Name: 'Hampstead',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_6006',
    Name: 'Norwood',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TN_6007',
    Name: 'Sweetwater',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NJ_6008',
    Name: 'Old Tappan',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_MO_6009', Name: 'Willard', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_TN_6010',
    Name: 'Madisonville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_TN_6011', Name: 'Loudon', stateId: 'TN', stateName: 'Tennessee'},
  {Id: 'CIT_US_TX_6012', Name: 'Slaton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_6013',
    Name: 'Chamberlayne',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_GA_6014',
    Name: 'Montezuma',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NJ_6015',
    Name: 'Jamesburg',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_VA_6016',
    Name: 'Massanetta Springs',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_6017',
    Name: 'Gustine',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_6018', Name: 'Nolanville', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_6019', Name: 'Windcrest', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MD_6020',
    Name: 'Spring Ridge',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OH_6021', Name: 'Brookville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_6022',
    Name: 'Mayflower Village',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_6023',
    Name: 'Marlow Heights',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_DE_6024', Name: 'Elsmere', stateId: 'DE', stateName: 'Delaware'},
  {
    Id: 'CIT_US_OH_6025',
    Name: 'Turpin Hills',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_TX_6026', Name: 'Luling', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_6027',
    Name: 'Glendale',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_6028', Name: 'Hutchins', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_NY_6029', Name: 'Chester', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_SC_6030',
    Name: 'Belton',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NJ_6031',
    Name: 'Margate City',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IL_6032', Name: 'Posen', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MI_6033',
    Name: 'Dowagiac',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_AR_6034', Name: 'Alma', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_CA_6035',
    Name: 'Las Flores',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_6036',
    Name: 'Franklin Center',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_6037',
    Name: 'McKees Rocks',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_6038',
    Name: 'Paulsboro',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WV_6039',
    Name: 'New Martinsville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_IL_6040', Name: 'Breese', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NJ_6041',
    Name: 'Mount Arlington',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_6042',
    Name: 'Old Fig Garden',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_6043',
    Name: 'Silver Lakes',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_6044',
    Name: 'Harris Hill',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_6045',
    Name: 'Carterville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MT_6046',
    Name: 'Orchard Homes',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_TX_6047',
    Name: 'Littlefield',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NJ_6048',
    Name: 'Prospect Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_TX_6049',
    Name: 'Willow Park',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_GA_6050', Name: 'Hartwell', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NJ_6051',
    Name: 'Woodcliff Lake',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_AZ_6052',
    Name: 'Cave Creek',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_MI_6053', Name: 'Chelsea', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_NJ_6054',
    Name: 'Roseland',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PR_6055',
    Name: 'Cidra',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MO_6056',
    Name: 'Greenwood',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_6057',
    Name: 'Moosic',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_6058',
    Name: 'Hellertown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NH_6059',
    Name: 'South Hooksett',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {Id: 'CIT_US_IN_6060', Name: 'Berne', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_WA_6061',
    Name: 'West Clarkston-Highland',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NM_6062',
    Name: 'Eldorado at Santa Fe',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NY_6063',
    Name: 'Mattydale',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AL_6064', Name: 'Harvest', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_KY_6065', Name: 'Lebanon', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_TX_6066', Name: 'Brookshire', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IN_6067', Name: 'Bright', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_IL_6068', Name: 'Volo', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NJ_6069',
    Name: 'Ramblewood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NY_6070',
    Name: 'Lake Mohegan',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_6071',
    Name: 'Beardstown',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NJ_6072',
    Name: 'Manasquan',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_FL_6073', Name: 'Marianna', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_UT_6074',
    Name: 'West Bountiful',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_FL_6075',
    Name: 'Fellsmere',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_6076',
    Name: 'Mount Zion',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SC_6077',
    Name: 'Piedmont',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_6078',
    Name: 'Plymouth',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_6079',
    Name: 'Bay Harbor Islands',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NJ_6080',
    Name: 'Norwood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_LA_6081',
    Name: 'Patterson',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_6082',
    Name: 'Villa Park',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_6083',
    Name: 'Highgrove',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OH_6084',
    Name: 'The Village of Indian Hill',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_GA_6085', Name: 'Conley', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NJ_6086',
    Name: 'Little Silver',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_TX_6087', Name: 'Edna', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_6088',
    Name: 'Peoria Heights',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_NY_6089', Name: 'Hilton', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_TX_6090',
    Name: 'Granite Shoals',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_6091', Name: 'Palmview', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MD_6092',
    Name: 'Fountainhead-Orchard Hills',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_CO_6093', Name: 'Eaton', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_IN_6094', Name: 'Gas City', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_LA_6095',
    Name: 'Brownfields',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_AL_6096', Name: 'Attalla', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_MI_6097', Name: 'Belding', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_PA_6098',
    Name: 'Crafton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_6099',
    Name: 'Washingtonville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_CO_6100', Name: 'Brush', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_UT_6101', Name: 'White City', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_MO_6102', Name: 'Trenton', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_VA_6103',
    Name: 'Fort Lee',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_6104', Name: 'Oak Point', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_6105',
    Name: 'West Modesto',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_6106',
    Name: 'Dickson City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_6107',
    Name: 'Hilmar-Irwin',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_LA_6108', Name: 'Violet', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_NM_6109',
    Name: 'White Rock',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NY_6110',
    Name: 'Flanders',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_6111',
    Name: 'Sansom Park',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IN_6112',
    Name: 'North Manchester',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IA_6113', Name: 'Asbury', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_KY_6114',
    Name: 'Fort Wright',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CA_6115',
    Name: 'Rancho Murieta',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MA_6116',
    Name: 'West Yarmouth',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_OR_6117', Name: 'Warrenton', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_TX_6118', Name: 'Sinton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_6119',
    Name: 'Fair Haven',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_TX_6120', Name: 'Fabens', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NM_6121',
    Name: 'Lee Acres',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_FL_6122',
    Name: 'Naples Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_LA_6123', Name: 'Benton', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_PA_6124',
    Name: 'Fort Washington',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_6125',
    Name: 'Crosspointe',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_6126',
    Name: 'Mount Carmel',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_6127',
    Name: 'Oceanport',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_KY_6128',
    Name: 'Bellevue',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MI_6129',
    Name: 'South Haven',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_KS_6130', Name: 'Paola', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_AL_6131',
    Name: 'Monroeville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_TX_6132', Name: 'Zapata', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AZ_6133',
    Name: 'South Tucson',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WV_6134',
    Name: 'Grafton',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_FL_6135',
    Name: 'Rio Pinar',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_KS_6136', Name: 'Ulysses', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_6137',
    Name: 'Lewisburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_6138',
    Name: 'Dripping Springs',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OH_6139', Name: 'Grafton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_6140',
    Name: 'Highland',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_KY_6141',
    Name: 'Douglass Hills',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IL_6142',
    Name: 'Spring Grove',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_6143',
    Name: 'Woods Creek',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_HI_6144', Name: 'Laie', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_SD_6145',
    Name: 'Belle Fourche',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_AL_6146',
    Name: 'Chickasaw',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PA_6147',
    Name: 'Nazareth',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_6148',
    Name: 'Elmwood',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_6149',
    Name: 'Sharon Hill',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_GA_6150',
    Name: 'Wrightsville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PA_6151',
    Name: 'Linglestown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_6152',
    Name: 'Media',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_6153',
    Name: 'New Brighton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_6154',
    Name: 'Princeton',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_6155',
    Name: 'Hollidaysburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_FL_6156', Name: 'Loughman', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_WA_6157',
    Name: 'Manchester',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CO_6158',
    Name: 'Dove Valley',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_TN_6159',
    Name: 'Tellico Village',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_IA_6160', Name: 'Estherville', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OR_6161', Name: 'Philomath', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_AR_6162',
    Name: 'Greenbrier',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CA_6163',
    Name: 'Wilton',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_6164', Name: 'Mathis', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IN_6165',
    Name: 'Hartford City',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_NE_6166', Name: 'Wayne', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_MI_6167',
    Name: 'Eastwood',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MO_6168',
    Name: 'Richmond',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_ME_6169', Name: 'Gardiner', stateId: 'ME', stateName: 'Maine'},
  {
    Id: 'CIT_US_IL_6170',
    Name: 'Wilmington',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_FL_6171', Name: 'Surfside', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_PA_6172',
    Name: 'Paoli',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_FL_6173', Name: 'Starke', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NC_6174',
    Name: 'East Flat Rock',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TN_6175',
    Name: 'Dandridge',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_CA_6176',
    Name: 'Cambria',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IN_6177', Name: 'Corydon', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_IL_6178',
    Name: 'Pontoon Beach',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MN_6179', Name: 'Byron', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_PA_6180',
    Name: 'Wyndmoor',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_6181',
    Name: 'Samsula-Spruce Creek',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_6182',
    Name: 'Zephyrhills South',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_6183', Name: 'Lakehills', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OK_6184', Name: 'Alva', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_WI_6185', Name: 'Milton', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_PA_6186',
    Name: 'Oreland',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_6187', Name: 'Groveport', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_6188', Name: 'Olivarez', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_6189',
    Name: 'Willow Springs',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TN_6190',
    Name: 'Jonesborough',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_SC_6191',
    Name: 'Kingstree',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_IL_6192', Name: 'Anna', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_WI_6193',
    Name: 'Lake Delton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_KY_6194', Name: 'Dayton', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_MO_6195',
    Name: 'Cottleville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MO_6196',
    Name: 'Bowling Green',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WA_6197',
    Name: 'Blaine',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_TX_6198',
    Name: 'Lackland AFB',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CA_6199',
    Name: 'Elverta',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_LA_6200',
    Name: 'Mansfield',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_6201', Name: 'Rusk', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IN_6202', Name: 'Linton', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_AL_6203', Name: 'Semmes', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_TX_6204',
    Name: 'Palmview South',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_6205',
    Name: 'Honesdale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AR_6206', Name: 'Warren', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_KS_6207',
    Name: 'Tonganoxie',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_TX_6208', Name: 'Marlin', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_6209',
    Name: 'Oxford',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_6210',
    Name: 'Islip Terrace',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NC_6211',
    Name: 'Bessemer City',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_FL_6212',
    Name: 'Siesta Key',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_OH_6213',
    Name: 'New Carlisle',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OH_6214', Name: 'Deer Park', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_6215',
    Name: 'Slinger',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_6216',
    Name: 'Lake Bluff',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WI_6217',
    Name: 'Waterford',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MI_6218',
    Name: 'Frankenmuth',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IL_6219', Name: 'Eureka', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_PA_6220',
    Name: 'Stroudsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_6221',
    Name: 'Duquesne',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_ID_6222', Name: 'Preston', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_WA_6223',
    Name: 'Tracyton',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NC_6224',
    Name: 'Nashville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CO_6225',
    Name: 'Leadville',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PR_6226',
    Name: 'Ceiba',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_AR_6227',
    Name: 'Berryville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_OK_6228', Name: 'Vinita', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_WA_6229',
    Name: 'Connell',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NJ_6230',
    Name: 'Belmar',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_KY_6231', Name: 'Buckner', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_NC_6232',
    Name: 'Taylorsville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MN_6233',
    Name: 'Glencoe',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NY_6234',
    Name: 'Wappingers Falls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_GA_6235',
    Name: 'Lake Park',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_OH_6236', Name: 'Ada', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_6237',
    Name: 'Exeter',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_6238',
    Name: 'Hidden Valley Lake',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_6239', Name: 'Waterville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_HI_6240', Name: 'Waimanalo', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_IA_6241', Name: 'Anamosa', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MI_6242', Name: 'Zeeland', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MS_6243',
    Name: 'Forest',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NY_6244',
    Name: 'Pelham Manor',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_6245',
    Name: 'Monticello',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_6246',
    Name: 'Colorado City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MO_6247',
    Name: 'Weldon Spring',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IN_6248',
    Name: 'Hidden Valley',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CO_6249',
    Name: 'Shaw Heights',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MN_6250',
    Name: 'Falcon Heights',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_6251',
    Name: 'Strawberry',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_6252', Name: 'Atlanta', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_6253',
    Name: 'Palm Springs North',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_ID_6254', Name: 'Weiser', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_CA_6255',
    Name: 'Yosemite Lakes',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MI_6256', Name: 'Clare', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_AL_6257',
    Name: 'Highland Lakes',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_OH_6258', Name: 'Germantown', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_GA_6259', Name: 'Baxley', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NM_6260',
    Name: 'Santa Teresa',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IL_6261',
    Name: 'Carlinville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CT_6262',
    Name: 'Sherwood Manor',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_GA_6263', Name: 'Elberton', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_AZ_6264', Name: 'Kayenta', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_OR_6265', Name: 'Winston', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_OH_6266', Name: 'Wellston', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_6267', Name: 'Rockdale', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OK_6268',
    Name: 'Holdenville',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MI_6269',
    Name: 'Swartz Creek',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_AR_6270', Name: 'Mena', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_NC_6271',
    Name: 'Stokesdale',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_6272',
    Name: 'Fort Plain',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_HI_6273', Name: 'Kalaheo', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_TX_6274', Name: 'Wolfforth', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_6275',
    Name: 'Fairview',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NC_6276',
    Name: 'Manteo',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MO_6277',
    Name: 'Peculiar',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OK_6278', Name: 'Muldrow', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_TX_6279',
    Name: 'Breckenridge',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NJ_6280',
    Name: 'Somerdale',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_TN_6281', Name: 'Sparta', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_VA_6282',
    Name: 'Dulles Town Center',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_6283',
    Name: 'Indian Wells',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_6284',
    Name: 'Williams',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_6285',
    Name: 'Charleston',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_6286', Name: 'Cameron', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_6287',
    Name: 'New Holland',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IL_6288', Name: 'Pana', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CA_6289',
    Name: 'Woodside',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_6290', Name: 'Adel', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_HI_6291',
    Name: 'Waipio Acres',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_PA_6292',
    Name: 'Jim Thorpe',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_6293',
    Name: 'Terrell Hills',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OH_6294', Name: 'Carlisle', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_KS_6295', Name: 'Colby', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_IA_6296', Name: 'Centerville', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_CA_6297', Name: 'Jamul', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_NY_6298',
    Name: 'East Moriches',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_LA_6299',
    Name: 'Springhill',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_TN_6300',
    Name: 'South Pittsburg',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MI_6301',
    Name: 'Eaton Rapids',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_6302',
    Name: 'Gilbertsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IL_6303', Name: 'Robbins', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_FL_6304',
    Name: 'South Highpoint',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_6305', Name: 'Northwood', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_FL_6306',
    Name: 'St. Augustine South',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WI_6307',
    Name: 'Black River Falls',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_6308',
    Name: 'Honeoye Falls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_ID_6309', Name: 'Fruitland', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_TN_6310',
    Name: 'Rockwood',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_6311',
    Name: 'Coraopolis',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MD_6312',
    Name: 'Shady Side',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_GA_6313',
    Name: 'Waynesboro',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PA_6314',
    Name: 'Monaca',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_6315', Name: 'Jackson', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_TN_6316',
    Name: 'Lafayette',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NC_6317',
    Name: 'Angier',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_SC_6318',
    Name: 'Batesburg-Leesville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_TX_6319',
    Name: 'Denver City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_NE_6320', Name: 'Chadron', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_LA_6321',
    Name: 'Ferriday',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NY_6322',
    Name: 'New Hempstead',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_6323',
    Name: 'Coal City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OH_6324',
    Name: 'Canal Fulton',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NE_6325',
    Name: 'Holdrege',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_WA_6326',
    Name: 'East Port Orchard',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NY_6327',
    Name: 'Salamanca',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_FL_6328',
    Name: 'Valparaiso',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_NY_6329', Name: 'Valatie', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_IA_6330', Name: 'Algona', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NC_6331',
    Name: 'Mocksville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MD_6332',
    Name: 'Friendship Heights Village',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_6333',
    Name: 'Palermo',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_6334',
    Name: 'Floral City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NY_6335',
    Name: 'Centerport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_6336',
    Name: 'Pinckneyville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_DE_6337',
    Name: 'New Castle',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_AL_6338',
    Name: 'Smiths Station',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_FL_6339',
    Name: 'Warm Mineral Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CO_6340',
    Name: 'Manitou Springs',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_IL_6341',
    Name: 'Northfield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_WI_6342', Name: 'Berlin', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_SC_6343',
    Name: 'Central',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_TX_6344', Name: 'La Grange', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_6345', Name: 'Winterset', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NV_6346',
    Name: 'Lemmon Valley',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_PA_6347',
    Name: 'Exton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VT_6348',
    Name: 'Bellows Falls',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_PA_6349',
    Name: 'Orwigsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AZ_6350',
    Name: 'Citrus Park',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_OH_6351', Name: 'Caldwell', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_GA_6352',
    Name: 'Cleveland',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_OH_6353', Name: 'Lisbon', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_MT_6354', Name: 'Polson', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_WA_6355',
    Name: 'Cavalero',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_NY_6356', Name: 'Kaser', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_OR_6357', Name: 'Estacada', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_LA_6358',
    Name: 'Chackbay',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MD_6359',
    Name: 'Manchester',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_IA_6360', Name: 'Clarinda', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_UT_6361', Name: 'Sunset', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_WI_6362',
    Name: 'Wind Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_6363',
    Name: 'Penn Estates',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_6364',
    Name: 'St. Paul Park',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IN_6365',
    Name: 'Simonton Lake',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_AR_6366', Name: 'Ward', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_WA_6367',
    Name: 'Hockinson',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PR_6368',
    Name: 'Añasco',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_NJ_6369',
    Name: 'Englewood Cliffs',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NC_6370',
    Name: 'James City',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_6371', Name: 'Anthony', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WV_6372',
    Name: 'Shepherdstown',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MN_6373',
    Name: 'North Oaks',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WA_6374',
    Name: 'Mattawa',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MO_6375',
    Name: 'Caruthersville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_FL_6376',
    Name: 'Port LaBelle',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AZ_6377',
    Name: 'Williamson',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_SC_6378',
    Name: 'Travelers Rest',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_AZ_6379',
    Name: 'Rincon Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NY_6380',
    Name: 'East Quogue',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_MO_6381', Name: 'Macon', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_KY_6382',
    Name: 'Morganfield',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_OH_6383',
    Name: 'Apple Valley',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_6384',
    Name: 'Palmerton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_6385', Name: 'San Leon', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CO_6386',
    Name: 'Edgewater',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_LA_6387',
    Name: 'Jonesboro',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_MN_6388', Name: 'Morris', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_TX_6389', Name: 'McGregor', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MD_6390',
    Name: 'Westernport',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_6391',
    Name: 'Kensington',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_6392',
    Name: 'Wake Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_LA_6393',
    Name: 'Avondale',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_HI_6394',
    Name: 'Hawaiian Beaches',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_PA_6395',
    Name: 'Shillington',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TN_6396',
    Name: 'McKenzie',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_OH_6397',
    Name: 'Uhrichsville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_6398',
    Name: 'Lehighton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ND_6399',
    Name: 'Minot AFB',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_AL_6400',
    Name: 'Fort Rucker',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MD_6401',
    Name: 'Fruitland',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MO_6402',
    Name: 'Savannah',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NY_6403',
    Name: 'Fairport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NJ_6404',
    Name: 'Union Beach',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CT_6405',
    Name: 'Pawcatuck',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_NJ_6406',
    Name: 'Hightstown',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_TX_6407', Name: 'Brady', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AL_6408', Name: 'Brent', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_PA_6409',
    Name: 'Eagleville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_6410', Name: 'Bulverde', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_6411',
    Name: 'Paxtonia',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_6412',
    Name: 'Waynesville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TN_6413',
    Name: 'Mount Carmel',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NY_6414',
    Name: 'Kings Point',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_6415',
    Name: 'Bear Valley Springs',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_VA_6416', Name: 'Orange', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_HI_6417',
    Name: 'Kahaluu-Keauhou',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {Id: 'CIT_US_KS_6418', Name: 'Iola', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_FL_6419',
    Name: 'Zephyrhills West',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_6420',
    Name: 'Garnet',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MI_6421',
    Name: 'Lake Fenton',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NY_6422',
    Name: 'Old Bethpage',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_FL_6423',
    Name: 'South Sarasota',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_UT_6424', Name: 'Midway', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_CA_6425',
    Name: 'Big Bear Lake',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_6426',
    Name: 'Titusville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_6427',
    Name: 'St. Clair',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_KY_6428', Name: 'Grayson', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_IA_6429', Name: 'Red Oak', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AR_6430',
    Name: 'Cave Springs',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NC_6431',
    Name: 'Wadesboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MS_6432',
    Name: 'Ripley',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_GA_6433',
    Name: 'Hawkinsville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_GA_6434', Name: 'Forsyth', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WI_6435',
    Name: 'Rothschild',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OH_6436', Name: 'Delhi Hills', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_SC_6437',
    Name: 'Belvedere',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_TX_6438',
    Name: 'Pecan Acres',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_6439', Name: 'Giddings', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_6440', Name: 'Venus', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_UT_6441', Name: 'Perry', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_LA_6442',
    Name: 'Carlyss',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PR_6443',
    Name: 'Santa Isabel',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_CA_6444',
    Name: 'Calistoga',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MO_6445', Name: 'Odessa', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MD_6446',
    Name: 'Poolesville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_6447', Name: 'Livingston', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NC_6448',
    Name: 'Walkertown',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IA_6449', Name: 'Osceola', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MA_6450',
    Name: 'Yarmouth Port',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_TX_6451', Name: 'Briar', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WV_6452',
    Name: 'Ranson',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_NJ_6453', Name: 'Salem', stateId: 'NJ', stateName: 'New Jersey'},
  {
    Id: 'CIT_US_CA_6454',
    Name: 'Lake Wildwood',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_KY_6455', Name: 'Hendron', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_PA_6456',
    Name: 'Bangor',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SC_6457',
    Name: 'Hollywood',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_GA_6458', Name: 'Byron', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NY_6459',
    Name: 'Ballston Spa',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AZ_6460', Name: 'Bisbee', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_NY_6461',
    Name: 'Albertson',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_GA_6462',
    Name: 'Springfield',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_IL_6463',
    Name: 'Highwood',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_6464',
    Name: 'Chisago City',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IN_6465',
    Name: 'Union City',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CA_6466',
    Name: 'Myrtletown',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_6467', Name: 'Gilmer', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_6468',
    Name: 'Williamsville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_MD_6469', Name: 'Layhill', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_NY_6470',
    Name: 'Elmsford',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_6471', Name: 'Center', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_6472',
    Name: 'Cutler',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AK_6473',
    Name: 'Farmers Loop',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_PR_6474',
    Name: 'Naguabo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MN_6475',
    Name: 'Montrose',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NH_6476',
    Name: 'Pinardville',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_MS_6477',
    Name: 'Aberdeen',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_TX_6478', Name: 'Diboll', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_6479',
    Name: 'Hutchinson Island South',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NC_6480',
    Name: 'Williamston',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_NY_6481', Name: 'Ravena', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_VA_6482', Name: 'Ettrick', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_AZ_6483', Name: 'Thatcher', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_FL_6484',
    Name: 'South Bay',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MA_6485',
    Name: 'Ocean Bluff-Brant Rock',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_MI_6486',
    Name: 'Springfield',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_FL_6487',
    Name: 'Timber Pines',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NV_6488',
    Name: 'West Wendover',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {Id: 'CIT_US_OH_6489', Name: 'Obetz', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_6490',
    Name: 'Twin Lakes',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_6491', Name: 'DeWitt', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NC_6492',
    Name: 'Sawmills',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_AZ_6493', Name: 'Paulden', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_IN_6494', Name: 'Attica', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NC_6495',
    Name: 'Mount Olive',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_6496',
    Name: 'Boulder Creek',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_6497',
    Name: 'Tichigan',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_6498', Name: 'Parker', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TN_6499', Name: 'Dunlap', stateId: 'TN', stateName: 'Tennessee'},
  {Id: 'CIT_US_TX_6500', Name: 'Doffing', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_6501',
    Name: 'Collegeville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CO_6502',
    Name: 'West Pleasant View',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CA_6503',
    Name: 'Broadmoor',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_6504',
    Name: 'Grandwood Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_6505',
    Name: 'Creve Coeur',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_AK_6506', Name: 'Sterling', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_FL_6507',
    Name: 'Crawfordville',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MN_6508',
    Name: 'Lake City',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_FL_6509',
    Name: 'River Ridge',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NC_6510',
    Name: 'Erwin',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MI_6511',
    Name: 'Grosse Pointe',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NY_6512',
    Name: 'Blauvelt',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_HI_6513', Name: 'Hanamaulu', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_MA_6514',
    Name: 'Bliss Corner',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_LA_6515',
    Name: 'Grambling',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_6516',
    Name: 'Bret Harte',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_6517', Name: 'Ottawa', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_6518',
    Name: 'Birdsboro',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_6519',
    Name: 'Bristol',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PR_6520',
    Name: 'Peñuelas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_OH_6521',
    Name: 'St. Clairsville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NC_6522',
    Name: 'Ayden',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CO_6523',
    Name: 'Glendale',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PA_6524',
    Name: 'Turtle Creek',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AL_6525', Name: 'Margaret', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_WA_6526',
    Name: 'Colville',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NY_6527',
    Name: 'Carle Place',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_6528', Name: 'Primera', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_6529', Name: 'Humboldt', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IA_6530',
    Name: 'Sergeant Bluff',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_TX_6531',
    Name: 'Homestead Meadows North',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_6532',
    Name: 'Spring Valley',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_6533',
    Name: 'Flagler Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_6534', Name: 'Canutillo', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AL_6535',
    Name: 'Rainsville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_FL_6536',
    Name: 'Kenneth City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_OK_6537',
    Name: 'Lone Grove',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MD_6538',
    Name: 'Silver Hill',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_IL_6539', Name: 'Colona', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CA_6540',
    Name: 'Tara Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CO_6541',
    Name: 'Battlement Mesa',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_TX_6542', Name: 'Post', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_6543', Name: 'Bowie', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_6544',
    Name: 'McGuire AFB',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IL_6545', Name: 'Carmi', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_VA_6546',
    Name: 'Kings Park',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_VA_6547',
    Name: 'Quantico Base',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_PR_6548', Name: 'Coco', stateId: 'PR', stateName: 'Puerto Rico'},
  {Id: 'CIT_US_MI_6549', Name: 'Utica', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_PA_6550',
    Name: 'Schuylkill Haven',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_6551',
    Name: 'Scott City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MI_6552',
    Name: 'Portland',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_6553',
    Name: 'Poplar Grove',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_6554',
    Name: 'Buellton',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AL_6555',
    Name: 'Daleville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_FL_6556',
    Name: 'Belleview',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_6557',
    Name: 'Denair',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_6558',
    Name: 'Highland Lakes',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OH_6559', Name: 'Johnstown', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_FL_6560',
    Name: 'South Pasadena',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IN_6561',
    Name: 'South Haven',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OK_6562', Name: 'Hugo', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_FL_6563', Name: 'Tice', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_WA_6564',
    Name: 'Town and Country',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_GA_6565', Name: 'Lakeview', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NJ_6566',
    Name: 'Morganville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_OH_6567',
    Name: 'New Burlington',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_AZ_6568', Name: 'Holbrook', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_MT_6569',
    Name: 'Four Corners',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_TX_6570', Name: 'Rio Hondo', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_6571', Name: 'Sheldon', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WI_6572',
    Name: 'North Fond du Lac',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_KY_6573',
    Name: 'Crestwood',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_6574',
    Name: 'Fox Chapel',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_6575', Name: 'Vinton', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IN_6576', Name: 'De Motte', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NV_6577',
    Name: 'Silver Springs',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_WA_6578',
    Name: 'Maplewood',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_AL_6579', Name: 'Glencoe', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_PA_6580',
    Name: 'Shanor-Northvue',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KY_6581', Name: 'Massac', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_OH_6582',
    Name: 'Munroe Falls',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MS_6583',
    Name: 'Saltillo',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IL_6584', Name: 'Monee', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_IA_6585', Name: 'Iowa Falls', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OR_6586',
    Name: 'Roseburg North',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_WA_6587',
    Name: 'Buckley',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_NE_6588', Name: 'Gretna', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_PA_6589',
    Name: 'Stewartstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_6590',
    Name: 'West Hills',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_6591',
    Name: 'Glen Head',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PR_6592',
    Name: 'Salinas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_AZ_6593', Name: 'Clifton', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_NE_6594',
    Name: 'Offutt AFB',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IL_6595',
    Name: 'Berkeley',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MN_6596', Name: 'Carver', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_FL_6597', Name: 'Dundee', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MA_6598',
    Name: 'North Scituate',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_MN_6599',
    Name: 'Montevideo',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OK_6600', Name: 'Sulphur', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_WI_6601', Name: 'Summit', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_OH_6602', Name: 'Amelia', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_6603',
    Name: 'Mechanicville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AR_6604', Name: 'Barling', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_TX_6605', Name: 'Krum', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CT_6606',
    Name: 'Terryville',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_MN_6607',
    Name: 'La Crescent',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_WY_6608', Name: 'Worland', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_PA_6609',
    Name: 'Olyphant',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CT_6610',
    Name: 'Stafford Springs',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_FL_6611',
    Name: 'Whiskey Creek',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AZ_6612',
    Name: 'Doney Park',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_GA_6613',
    Name: 'Glennville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NY_6614',
    Name: 'Sea Cliff',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_SC_6615',
    Name: 'Abbeville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_TX_6616', Name: 'Muleshoe', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_6617',
    Name: 'West Salem',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_6618',
    Name: 'Guerneville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_HI_6619',
    Name: 'Hawaiian Ocean View',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_NY_6620',
    Name: 'West Sayville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_SC_6621',
    Name: 'Mullins',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_6622',
    Name: 'Swoyersville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_6623',
    Name: 'Hoopeston',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_6624', Name: 'Greenfield', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_6625', Name: 'Hudson', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SC_6626',
    Name: 'Ridgeland',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_IL_6627', Name: 'Dwight', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MN_6628',
    Name: 'Oak Park Heights',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_6629',
    Name: 'Gillespie',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_AL_6630', Name: 'Midfield', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NY_6631',
    Name: 'Westvale',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_CO_6632', Name: 'Timnath', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_MO_6633',
    Name: 'Dellwood',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_6634',
    Name: 'South Barrington',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_6635',
    Name: 'Pine Manor',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IL_6636', Name: 'Milan', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_FL_6637',
    Name: 'Orangetree',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_6638', Name: 'Pittsburg', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_KS_6639', Name: 'Concordia', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_IA_6640', Name: 'Manchester', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OH_6641',
    Name: 'Montrose-Ghent',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_AR_6642', Name: 'Gibson', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_IA_6643', Name: 'Huxley', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MN_6644',
    Name: 'Princeton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IN_6645',
    Name: 'Alexandria',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_LA_6646',
    Name: 'Winnsboro',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WA_6647',
    Name: 'Medical Lake',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MN_6648',
    Name: 'Redwood Falls',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_GA_6649',
    Name: 'Adairsville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_AR_6650',
    Name: 'White Hall',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_GA_6651',
    Name: 'Jonesboro',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_IA_6652', Name: 'Polk City', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_AZ_6653', Name: 'Parker', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_KY_6654',
    Name: 'Carrollton',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WA_6655',
    Name: 'Everson',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NJ_6656',
    Name: 'Clementon',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NC_6657',
    Name: 'Raeford',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OH_6658', Name: 'Wellington', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_MN_6659', Name: 'Becker', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_IN_6660', Name: 'Cicero', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_MI_6661',
    Name: 'Kingsford',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WI_6662',
    Name: 'Richland Center',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_LA_6663',
    Name: 'Westlake',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_6664',
    Name: 'Mayfair',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_6665',
    Name: 'Wildwood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_6666',
    Name: 'Beckett',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_TX_6667', Name: 'Alvarado', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MD_6668',
    Name: 'Centreville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NY_6669',
    Name: 'Blue Point',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_6670',
    Name: 'Wheatley Heights',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_FL_6671', Name: 'Jasper', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MS_6672',
    Name: 'Vancleave',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MN_6673',
    Name: 'Circle Pines',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_6674',
    Name: 'Fairfield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TN_6675',
    Name: 'Bolivar',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_KS_6676', Name: 'Maize', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NC_6677',
    Name: 'Pleasant Garden',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OH_6678',
    Name: 'Mount Carmel',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NY_6679',
    Name: 'Cold Spring Harbor',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_UT_6680', Name: 'Kanab', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NC_6681',
    Name: 'Long View',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_AR_6682',
    Name: 'Sheridan',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NJ_6683',
    Name: 'Blackwood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NC_6684',
    Name: 'Farmville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NJ_6685',
    Name: 'Northvale',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MS_6686',
    Name: 'Crystal Springs',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MA_6687',
    Name: 'Raynham Center',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_OH_6688', Name: 'Toronto', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_6689',
    Name: 'Rutherford',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MO_6690', Name: 'Salem', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MO_6691',
    Name: 'Glasgow Village',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NY_6692',
    Name: 'Firthcliffe',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NC_6693',
    Name: 'Midway',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_6694',
    Name: 'St. Joseph',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CO_6695',
    Name: 'Buena Vista',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_FL_6696', Name: 'Bay Hill', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_OK_6697',
    Name: 'Kingfisher',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MI_6698',
    Name: 'New Haven',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_KY_6699',
    Name: 'Prospect',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_6700',
    Name: 'Sugarcreek',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_6701',
    Name: 'Bridgeville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_6702',
    Name: 'Seven Lakes',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IN_6703',
    Name: 'Winona Lake',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_VA_6704',
    Name: 'King George',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_TN_6705',
    Name: 'Mount Pleasant',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_IL_6706',
    Name: 'Centreville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_6707',
    Name: 'Big Pine Key',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_6708', Name: 'Lake Worth', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_6709',
    Name: 'Telford',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_6710',
    Name: 'Demarest',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WA_6711',
    Name: 'Cashmere',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_6712',
    Name: 'Mayville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_6713',
    Name: 'Flower Hill',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NH_6714',
    Name: 'Suncook',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_OH_6715',
    Name: 'Pleasant Run',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NY_6716',
    Name: 'Island Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TN_6717',
    Name: 'Smithville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_MO_6718', Name: 'Desloge', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_MI_6719', Name: 'Davison', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_AZ_6720', Name: 'Benson', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_TX_6721',
    Name: 'West Columbia',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NJ_6722',
    Name: 'East Freehold',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_AZ_6723', Name: 'Chinle', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_OH_6724',
    Name: 'New Lexington',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_6725',
    Name: 'Vandergrift',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_6726',
    Name: 'Bealeton',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_MD_6727', Name: 'Denton', stateId: 'MD', stateName: 'Maryland'},
  {Id: 'CIT_US_NY_6728', Name: 'Thiells', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_TX_6729',
    Name: 'Hunters Creek Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IA_6730', Name: 'Cherokee', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_6731', Name: 'Shenandoah', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OH_6732', Name: 'Middlefield', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_6733',
    Name: 'Lake Barrington',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NM_6734',
    Name: 'Tucumcari',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_MD_6735', Name: 'Berlin', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_PA_6736',
    Name: 'Clarks Summit',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_FL_6737', Name: 'Wahneta', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_6738',
    Name: 'Lincoln Village',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_6739',
    Name: 'Chittenango',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IL_6740', Name: 'Flora', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_PA_6741',
    Name: 'Duryea',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_6742', Name: 'Palmetto', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NJ_6743',
    Name: 'Glendora',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_GA_6744',
    Name: 'Nashville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MN_6745',
    Name: 'Chisholm',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_6746',
    Name: 'Manheim',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_VA_6747', Name: 'Luray', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_OH_6748', Name: 'Whitehouse', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_FL_6749', Name: 'Sawgrass', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MO_6750',
    Name: 'Normandy',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_VA_6751',
    Name: 'Bluefield',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_OK_6752', Name: 'Perry', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_AL_6753',
    Name: 'Childersburg',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_IN_6754', Name: 'Porter', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_IL_6755',
    Name: 'Marseilles',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_6756',
    Name: 'Green Tree',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_6757',
    Name: 'Arnold',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_6758',
    Name: 'Rockford',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OH_6759', Name: 'West Milton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_6760',
    Name: 'Stone Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_6761',
    Name: 'Chattahoochee',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_6762',
    Name: 'Hummelstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MI_6763', Name: 'Caro', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_TN_6764',
    Name: 'Forest Hills',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_FL_6765',
    Name: 'Ridge Manor',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_6766', Name: 'Falfurrias', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SC_6767',
    Name: 'Edgefield',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_IA_6768',
    Name: 'Windsor Heights',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_MI_6769', Name: 'DeWitt', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MS_6770',
    Name: 'Waynesboro',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_AZ_6771',
    Name: 'Three Points',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NY_6772',
    Name: 'West Bay Shore',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_FL_6773',
    Name: 'Nassau Village-Ratliff',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_6774',
    Name: 'Chesterbrook',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_6775', Name: 'Magnolia', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MI_6776',
    Name: 'Michigan Center',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_OH_6777',
    Name: 'West Jefferson',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_UT_6778', Name: 'Hyde Park', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NC_6779',
    Name: 'Dallas',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_KS_6780', Name: 'Goddard', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MA_6781',
    Name: 'East Harwich',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_TN_6782',
    Name: 'Pleasant View',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_GA_6783', Name: 'Temple', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_TX_6784',
    Name: 'Hickory Creek',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_6785',
    Name: 'Schlusser',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MT_6786', Name: 'Dillon', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_TX_6787',
    Name: 'Midway North',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MD_6788',
    Name: 'Middletown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_IL_6789',
    Name: 'Olympia Fields',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_GA_6790', Name: 'Lindale', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_KY_6791',
    Name: 'Manchester',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_LA_6792',
    Name: 'DeQuincy',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MI_6793',
    Name: 'Wolverine Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_KS_6794', Name: 'Wamego', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MS_6795',
    Name: 'Carthage',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WA_6796',
    Name: 'Black Diamond',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_VA_6797',
    Name: 'Clifton Forge',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_SC_6798',
    Name: 'Landrum',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MN_6799',
    Name: 'Elko New Market',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_LA_6800',
    Name: 'Monticello',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TN_6801', Name: 'Bells', stateId: 'TN', stateName: 'Tennessee'},
  {Id: 'CIT_US_TX_6802', Name: 'Wilmer', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_6803', Name: 'New Boston', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_6804', Name: 'Watseka', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_TN_6805',
    Name: 'Ashland City',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_IA_6806', Name: 'Harlan', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MO_6807', Name: 'Potosi', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_IN_6808', Name: 'Whiting', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_OH_6809', Name: 'Crooksville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_6810',
    Name: 'Dansville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WV_6811',
    Name: 'Weston',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NC_6812',
    Name: 'Ahoskie',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_6813',
    Name: 'Benton City',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_ID_6814', Name: 'Shelley', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_PA_6815',
    Name: 'West Pittston',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_6816',
    Name: 'Forked River',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IN_6817', Name: 'Ligonier', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NC_6818',
    Name: 'Royal Pines',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NJ_6819',
    Name: 'Penns Grove',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_FL_6820',
    Name: 'Mount Plymouth',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_6821',
    Name: 'Shelbyville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WI_6822',
    Name: 'Dodgeville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_6823',
    Name: 'Royersford',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_6824',
    Name: 'Ford City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_6825',
    Name: 'Lake Sarasota',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_OH_6826',
    Name: 'Cherry Grove',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_TX_6827',
    Name: 'Madisonville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WI_6828',
    Name: 'Bloomfield',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_ND_6829',
    Name: 'Lincoln',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_AR_6830',
    Name: 'Rockwell',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_OK_6831', Name: 'McLoud', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_LA_6832',
    Name: 'Port Allen',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_IA_6833', Name: 'Evansdale', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MN_6834',
    Name: 'Nowthen',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_KS_6835',
    Name: 'Baxter Springs',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_MD_6836',
    Name: 'Seat Pleasant',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_IL_6837',
    Name: 'Palos Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SC_6838',
    Name: 'Manning',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_KY_6839',
    Name: 'Columbia',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IL_6840',
    Name: 'Candlewick Lake',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PR_6841',
    Name: 'Campanilla',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_LA_6842',
    Name: 'Winnfield',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_CO_6843', Name: 'Mead', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_PR_6844',
    Name: 'Santa Bárbara',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_NJ_6845',
    Name: 'Harrington Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OH_6846', Name: 'Archbold', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_6847', Name: 'Silverton', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_6848', Name: 'Medina', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OK_6849', Name: 'Madill', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CO_6850',
    Name: 'El Jebel',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PA_6851',
    Name: 'Campbelltown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_6852',
    Name: 'Grandyle Village',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_6853',
    Name: 'Edwardsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_6854',
    Name: 'Chula Vista',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CA_6855',
    Name: 'Camino Tassajara',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_6856',
    Name: 'Emerald Lake Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_6857',
    Name: 'Weston Lakes',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_6858',
    Name: 'Indian Mountain Lake',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_6859',
    Name: 'Butler Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_6860', Name: 'Penitas', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MI_6861', Name: 'Dexter', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_NJ_6862',
    Name: 'Highlands',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_6863',
    Name: 'Lake San Marcos',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AL_6864', Name: 'Headland', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_AZ_6865', Name: 'Summit', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_KY_6866',
    Name: 'Westwood',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_MO_6867', Name: 'Eldon', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_MD_6868', Name: 'Deale', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_IN_6869',
    Name: 'Winchester',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_TX_6870',
    Name: 'Castroville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MO_6871',
    Name: 'Crystal City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_KS_6872',
    Name: 'Baldwin City',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_PA_6873',
    Name: 'Fountain Hill',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_6874',
    Name: 'Gladstone',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_OH_6875',
    Name: 'Miami Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CT_6876',
    Name: 'Broad Brook',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_MO_6877',
    Name: 'Mountain Grove',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_VA_6878',
    Name: 'Falmouth',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MI_6879',
    Name: 'Cheboygan',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SC_6880',
    Name: 'Bishopville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NM_6881',
    Name: 'Placitas',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_6882',
    Name: 'Westmont',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_6883',
    Name: "Morgan's Point Resort",
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OH_6884', Name: 'Lexington', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_6885',
    Name: 'Sierra View',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_6886',
    Name: 'Lake View',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_MI_6887', Name: 'Wayland', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_IL_6888', Name: 'Sherman', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MD_6889',
    Name: 'Mountain Lake Park',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MA_6890',
    Name: 'Smith Mills',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_NY_6891',
    Name: 'Mattituck',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_SC_6892',
    Name: 'Brookdale',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_FL_6893',
    Name: 'Connerton',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_6894',
    Name: 'Brisbane',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_6895',
    Name: 'North Braddock',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_6896',
    Name: 'June Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_OH_6897',
    Name: 'South Lebanon',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_MT_6898', Name: 'Bigfork', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_MO_6899',
    Name: 'Osage Beach',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NJ_6900',
    Name: 'Brielle',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OH_6901', Name: 'Summerside', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TN_6902',
    Name: 'Spurgeon',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_IL_6903', Name: 'Tuscola', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_AR_6904',
    Name: 'Cherokee Village',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_TX_6905', Name: 'Sandy Oaks', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NC_6906',
    Name: 'Carolina Shores',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_6907',
    Name: 'Granite Falls',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_6908',
    Name: 'Hawthorne',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_MD_6909', Name: 'Chester', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_MA_6910',
    Name: 'Millis-Clicquot',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_MN_6911',
    Name: 'Wayzata',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IA_6912', Name: 'Tama', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_GA_6913', Name: 'Madison', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NM_6914',
    Name: 'Paradise Hills',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_TX_6915', Name: 'Tulia', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_6916',
    Name: 'West Elmira',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OR_6917', Name: 'Phoenix', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_PA_6918',
    Name: 'Reiffton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_6919',
    Name: 'Danville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_6920',
    Name: 'Little Falls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_6921',
    Name: 'Boalsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SC_6922',
    Name: 'Woodruff',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NY_6923',
    Name: 'Galeville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AL_6924', Name: 'Jackson', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_FL_6925',
    Name: 'Pea Ridge',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_LA_6926', Name: 'Kaplan', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_PA_6927',
    Name: 'Lemoyne',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MI_6928', Name: 'Durand', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_OH_6929',
    Name: 'East Palestine',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NY_6930',
    Name: 'Gardnertown',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NJ_6931',
    Name: 'Wanamassa',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NC_6932',
    Name: 'Spruce Pine',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_LA_6933', Name: 'Swartz', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_TX_6934',
    Name: 'Hebbronville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_TX_6935',
    Name: 'Daingerfield',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_MD_6936', Name: 'Hampton', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_PR_6937',
    Name: 'Comerío',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_AR_6938', Name: 'Vilonia', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_IL_6939', Name: 'Auburn', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MO_6940',
    Name: 'Rock Hill',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NJ_6941',
    Name: 'Cape May Court House',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_FL_6942',
    Name: 'Blountstown',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MI_6943',
    Name: 'Vandercook Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CT_6944',
    Name: 'Hazardville',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_NC_6945',
    Name: 'Edenton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_6946',
    Name: 'Old Westbury',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_FL_6947',
    Name: 'Daytona Beach Shores',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NC_6948',
    Name: 'Creedmoor',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MO_6949',
    Name: 'Higginsville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_6950', Name: 'Coldwater', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MD_6951',
    Name: 'Woodmore',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_VA_6952',
    Name: 'Spotsylvania Courthouse',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_SC_6953',
    Name: 'Winnsboro',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_FL_6954', Name: 'Bushnell', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_AR_6955', Name: 'Haskell', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_MO_6956',
    Name: 'Village of Four Seasons',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_VA_6957',
    Name: 'Altavista',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_SC_6958',
    Name: 'Newport',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_MI_6959', Name: 'Dundee', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MN_6960',
    Name: 'Cold Spring',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_6961',
    Name: 'Oak View',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_6962',
    Name: 'North Sea',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NJ_6963',
    Name: 'Neptune City',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PR_6964',
    Name: 'Lajas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_RI_6965',
    Name: 'Pascoag',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_NC_6966',
    Name: 'Wingate',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_6967',
    Name: 'Williamstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_6968',
    Name: 'Montebello',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_6969',
    Name: 'Searingtown',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_6970',
    Name: 'Hollister',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_FL_6971',
    Name: 'Suncoast Estates',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NC_6972',
    Name: 'Newport',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TX_6973',
    Name: 'Meadows Place',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IN_6974',
    Name: 'Edinburgh',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_OK_6975',
    Name: 'Verdigris',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_AZ_6976',
    Name: 'Fort Defiance',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_PA_6977',
    Name: 'Spry',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_6978',
    Name: 'Mount Ephraim',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OH_6979', Name: 'Plain City', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TN_6980',
    Name: 'Coopertown',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_GA_6981',
    Name: 'Montgomery',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_FL_6982',
    Name: 'Lower Grand Lagoon',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_6983',
    Name: 'Farrell',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_6984',
    Name: 'Flemington',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_HI_6985', Name: 'Kahaluu', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_NJ_6986',
    Name: 'Beattystown',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MD_6987',
    Name: 'Peppermill Village',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_WY_6988', Name: 'Buffalo', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_IL_6989',
    Name: 'Fox River Grove',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_6990',
    Name: 'North Kansas City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_6991',
    Name: 'West York',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_6992', Name: 'Blakely', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_PA_6993',
    Name: 'Bridgeport',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_6994',
    Name: 'Portola Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_6995',
    Name: 'Scottsville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MO_6996',
    Name: 'Breckenridge Hills',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WA_6997',
    Name: 'Belfair',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_FL_6998', Name: 'Vamo', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_KY_6999', Name: 'Benton', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_GA_7000', Name: 'Gray', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_KS_7001', Name: 'Louisburg', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_7002',
    Name: 'Donora',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_7003',
    Name: 'Whitfield',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CT_7004',
    Name: 'Byram',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_IL_7005',
    Name: 'Sullivan',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MS_7006',
    Name: 'Ellisville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_TX_7007', Name: 'Perezville', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CO_7008',
    Name: 'Castle Pines Village',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NY_7009',
    Name: 'Gang Mills',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NM_7010',
    Name: 'Rio Communities',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NJ_7011',
    Name: 'Point Pleasant Beach',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WI_7012',
    Name: 'Okauchee Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_7013',
    Name: 'Hartsdale',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AL_7014',
    Name: 'Columbiana',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_WY_7015',
    Name: 'Fox Farm-College',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {Id: 'CIT_US_NE_7016', Name: 'Wahoo', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_LA_7017',
    Name: 'Eastwood',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_NE_7018', Name: 'Aurora', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_AL_7019',
    Name: 'Union Springs',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NJ_7020',
    Name: 'Leisure Village',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IN_7021', Name: 'Bremen', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_CA_7022',
    Name: 'Santa Ynez',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_WI_7023', Name: 'Oconto', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_GA_7024',
    Name: 'Social Circle',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NY_7025',
    Name: 'Canastota',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_DE_7026',
    Name: 'Harrington',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_AR_7027',
    Name: 'Dardanelle',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MS_7028',
    Name: 'Wiggins',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_TX_7029', Name: 'Dilley', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_7030',
    Name: 'Avalon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_UT_7031', Name: 'Gunnison', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NC_7032',
    Name: 'Boiling Springs',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_AL_7033', Name: 'Piedmont', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_TX_7034', Name: 'Palacios', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_7035',
    Name: 'East Hampton North',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IL_7036', Name: 'Paxton', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MO_7037',
    Name: 'Lexington',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IN_7038',
    Name: 'Whiteland',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MN_7039',
    Name: 'Luverne',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MN_7040',
    Name: 'Watertown',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_7041', Name: 'Laureles', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_7042',
    Name: 'Hazlehurst',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WA_7043',
    Name: 'Moses Lake North',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MI_7044',
    Name: 'Negaunee',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SC_7045',
    Name: 'Surfside Beach',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NC_7046',
    Name: 'Wrightsboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TX_7047',
    Name: 'Pilot Point',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_ID_7048', Name: 'Buhl', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_WA_7049',
    Name: 'Chain Lake',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NJ_7050',
    Name: 'Spring Lake Heights',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_AR_7051', Name: 'Piney', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_VA_7052',
    Name: 'South Hill',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MI_7053',
    Name: 'Gibraltar',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_AL_7054', Name: 'Winfield', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_PA_7055',
    Name: 'Bonneauville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_7056',
    Name: 'South Bound Brook',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_OH_7057',
    Name: 'Reminderville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_TN_7058',
    Name: 'Oak Hill',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_TX_7059', Name: 'Smithville', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MD_7060',
    Name: 'Capitol Heights',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_IL_7061',
    Name: 'Lake Holiday',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_7062',
    Name: 'Phoenix Lake',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MA_7063',
    Name: 'North Westport',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_MO_7064',
    Name: 'Mount Vernon',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MI_7065', Name: 'Hancock', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MN_7066',
    Name: 'Sauk Centre',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WI_7067',
    Name: 'Lake Wisconsin',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_KY_7068', Name: 'Ludlow', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_FL_7069',
    Name: 'Laguna Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_7070',
    Name: 'East Petersburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_UT_7071',
    Name: 'Silver Summit',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {Id: 'CIT_US_NY_7072', Name: 'Ardsley', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_AL_7073',
    Name: 'Tallassee',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PR_7074',
    Name: 'Guayanilla',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_FL_7075',
    Name: 'Crystal River',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_GA_7076',
    Name: 'Hazlehurst',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NE_7077',
    Name: 'Ogallala',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NC_7078',
    Name: 'Bayshore',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_KS_7079',
    Name: 'Edwardsville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_MI_7080', Name: 'Fremont', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MS_7081',
    Name: 'Florence',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NY_7082',
    Name: 'Orangeburg',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OH_7083',
    Name: 'Ottawa Hills',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NC_7084',
    Name: 'Jamestown',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OK_7085', Name: 'Sayre', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_AL_7086',
    Name: 'Pine Level',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PA_7087',
    Name: 'Ebensburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_7088',
    Name: 'Fussels Corner',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TN_7089', Name: 'Medina', stateId: 'TN', stateName: 'Tennessee'},
  {Id: 'CIT_US_TX_7090', Name: 'San Diego', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_7091',
    Name: 'Santa Venetia',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_7092',
    Name: 'Williamstown',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_TX_7093',
    Name: 'Shady Hollow',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WA_7094',
    Name: 'Rosedale',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_SC_7095',
    Name: 'Walhalla',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_OH_7096',
    Name: 'Newton Falls',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_TX_7097',
    Name: 'Castle Hills',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AZ_7098',
    Name: 'Pinetop-Lakeside',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_KS_7099', Name: 'Goodland', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OH_7100', Name: 'Huber Ridge', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_7101', Name: 'Woodville', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_7102',
    Name: 'Berryville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_7103',
    Name: 'Millersburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AK_7104', Name: 'Utqiagvik', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_MT_7105',
    Name: 'Helena Valley Northwest',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_NJ_7106',
    Name: 'Holiday City South',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_FL_7107',
    Name: 'White City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_7108',
    Name: 'Sunnyside',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TN_7109', Name: 'Algood', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_PA_7110',
    Name: 'Mount Holly Springs',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_7111',
    Name: 'Horse Cave',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MO_7112',
    Name: 'High Ridge',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NC_7113',
    Name: 'Cramerton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TN_7114',
    Name: 'Oak Grove',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_CA_7115',
    Name: 'Bonsall',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_7116', Name: 'Minerva', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TN_7117',
    Name: 'Whiteville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_IL_7118',
    Name: 'West Peoria',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_7119',
    Name: 'Beaufort',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_GA_7120', Name: 'Grayson', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_AZ_7121',
    Name: 'San Carlos',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NH_7122',
    Name: 'East Merrimack',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_AL_7123',
    Name: 'Holtville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_CA_7124',
    Name: 'Mountain View Acres',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_7125', Name: 'La Verkin', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_OR_7126',
    Name: 'Mount Hood Village',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_HI_7127', Name: 'Pupukea', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_OR_7128', Name: 'Reedsport', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_TN_7129',
    Name: 'Lake Tansi',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_OR_7130', Name: 'Coquille', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_NY_7131', Name: 'Armonk', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_FL_7132',
    Name: 'Mary Esther',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WI_7133',
    Name: 'Saukville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_AK_7134', Name: 'Unalaska', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_FL_7135',
    Name: 'The Meadows',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_VA_7136',
    Name: 'Tazewell',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_IL_7137', Name: 'Beecher', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_7138', Name: 'Crestline', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_VA_7139', Name: 'Enon', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_KS_7140', Name: 'Russell', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MN_7141',
    Name: 'Dilworth',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NC_7142',
    Name: 'Valdese',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OK_7143', Name: 'Marlow', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_PA_7144',
    Name: 'Jenkintown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MD_7145',
    Name: 'Perryville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_LA_7146',
    Name: 'Berwick',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_7147',
    Name: 'Planada',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_7148',
    Name: 'Towanda',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_7149',
    Name: 'California',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MO_7150',
    Name: 'Ste. Genevieve',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_FL_7151',
    Name: 'Stock Island',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_7152',
    Name: 'Brookridge',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_7153',
    Name: 'Churchville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_7154', Name: 'Senoia', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_IL_7155',
    Name: 'Cortland',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OR_7156', Name: 'King City', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_FL_7157',
    Name: 'Kensington Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_7158',
    Name: 'Armona',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_7159', Name: 'Jourdanton', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_7160', Name: 'Sheffield', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_7161',
    Name: 'Viroqua',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_7162',
    Name: 'Jessup',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_7163',
    Name: 'St. James',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AR_7164', Name: 'Ashdown', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_MI_7165', Name: 'Sparta', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_PA_7166',
    Name: 'Montoursville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_7167',
    Name: 'Walworth',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_FL_7168', Name: 'Samoset', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_7169',
    Name: 'McSwain',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_7170', Name: 'Carlisle', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WA_7171',
    Name: 'Suquamish',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KY_7172',
    Name: 'Stanford',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_NV_7173', Name: 'Ely', stateId: 'NV', stateName: 'Nevada'},
  {Id: 'CIT_US_TX_7174', Name: 'Hallsville', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_7175',
    Name: 'Holts Summit',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WI_7176',
    Name: 'Clintonville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_AZ_7177',
    Name: 'Clarkdale',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_LA_7178',
    Name: 'Church Point',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_7179',
    Name: 'Brookville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AL_7180', Name: 'Redland', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_KY_7181',
    Name: 'Hurstbourne',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_AL_7182',
    Name: 'Mount Olive',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MI_7183',
    Name: 'Coopersville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_LA_7184', Name: 'Arabi', stateId: 'LA', stateName: 'Louisiana'},
  {Id: 'CIT_US_MN_7185', Name: 'Windom', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_AR_7186',
    Name: 'Nashville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MD_7187',
    Name: 'Kingsville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_PA_7188',
    Name: 'Larksville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_7189', Name: 'Jacksboro', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MD_7190',
    Name: 'West Laurel',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_7191', Name: 'Argyle', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TX_7192',
    Name: 'Van Alstyne',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_FL_7193', Name: 'Cortez', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_PA_7194',
    Name: 'Cornwall',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_7195',
    Name: 'Fetters Hot Springs-Agua Caliente',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_7196', Name: 'Caldwell', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_7197',
    Name: 'Woodbridge',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_7198',
    Name: 'Albion',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_NY_7199', Name: 'Noyack', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_HI_7200', Name: 'Heeia', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_IN_7201',
    Name: 'Pendleton',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IL_7202',
    Name: 'Wonder Lake',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IN_7203',
    Name: 'Fort Branch',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CT_7204',
    Name: 'Long Hill',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_NC_7205',
    Name: 'Pittsboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_VA_7206',
    Name: 'Grottoes',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_GA_7207', Name: 'Euharlee', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_PA_7208',
    Name: 'Tarentum',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_7209', Name: 'Camanche', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CO_7210',
    Name: 'Todd Creek',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_WA_7211',
    Name: 'Deer Park',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_FL_7212', Name: 'Balm', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_7213',
    Name: 'Pacheco',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_LA_7214',
    Name: 'Lake Providence',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_7215',
    Name: 'Copperopolis',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_7216',
    Name: 'Isle of Palms',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_AR_7217',
    Name: 'Tontitown',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_DE_7218', Name: 'Laurel', stateId: 'DE', stateName: 'Delaware'},
  {
    Id: 'CIT_US_PA_7219',
    Name: 'Hamburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_7220',
    Name: 'Mauston',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_7221',
    Name: 'West Hazleton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_7222', Name: 'Hearne', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_7223',
    Name: 'Garwood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NC_7224',
    Name: 'Shallotte',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NJ_7225',
    Name: 'Atlantic Highlands',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IN_7226', Name: 'Sullivan', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_TN_7227',
    Name: 'Harrogate',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_AL_7228', Name: 'Argo', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NC_7229',
    Name: 'Canton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_ID_7230',
    Name: 'American Falls',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {Id: 'CIT_US_OH_7231', Name: 'Cedarville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_7232',
    Name: 'Brices Creek',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IN_7233',
    Name: 'Greendale',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_WI_7234',
    Name: 'French Island',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IN_7235',
    Name: 'North Terre Haute',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_WI_7236',
    Name: 'East Troy',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OH_7237', Name: 'St. Bernard', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_7238',
    Name: 'Eldorado',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_7239',
    Name: 'Narberth',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_UT_7240', Name: 'Elk Ridge', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_WI_7241',
    Name: 'Bayside',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_7242',
    Name: 'Flourtown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_7243', Name: 'Dyersville', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AZ_7244',
    Name: 'Lake Montezuma',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_WA_7245', Name: 'Forks', stateId: 'WA', stateName: 'Washington'},
  {Id: 'CIT_US_MD_7246', Name: 'Fulton', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_NC_7247',
    Name: 'Wallace',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_MN_7248', Name: 'Cokato', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_AZ_7249', Name: 'Taylor', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_AR_7250', Name: 'Dumas', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_TX_7251',
    Name: 'Spring Valley Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CA_7252',
    Name: 'Del Mar',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WV_7253',
    Name: 'Montgomery',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_LA_7254',
    Name: 'Bayou Vista',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_AK_7255', Name: 'Nikiski', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_PA_7256',
    Name: 'Glassport',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_7257',
    Name: 'Humboldt Hill',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_KS_7258', Name: 'Larned', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_FL_7259',
    Name: 'Moon Lake',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_VA_7260',
    Name: 'Bethel Manor',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_FL_7261',
    Name: 'Indian River Shores',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MD_7262',
    Name: 'West Ocean City',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MO_7263',
    Name: 'Wright City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_7264',
    Name: 'Slatington',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OR_7265', Name: 'Aumsville', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_FL_7266',
    Name: 'Holmes Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AL_7267',
    Name: 'Springville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PA_7268',
    Name: 'Pennside',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_7269',
    Name: 'Madeira Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_7270', Name: 'Whitesboro', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AZ_7271',
    Name: 'Whiteriver',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_OK_7272',
    Name: 'Slaughterville',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MD_7273',
    Name: 'National Harbor',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_PA_7274',
    Name: 'West Reading',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_7275',
    Name: 'Prescott',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MO_7276', Name: 'Lamar', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_WA_7277', Name: 'Wauna', stateId: 'WA', stateName: 'Washington'},
  {Id: 'CIT_US_TX_7278', Name: 'La Joya', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IN_7279',
    Name: 'Middlebury',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_SC_7280',
    Name: 'Barnwell',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_AL_7281',
    Name: 'Priceville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_TN_7282', Name: 'Selmer', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_GA_7283',
    Name: 'Chatsworth',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_TX_7284', Name: 'Crane', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AL_7285', Name: 'Geneva', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_MD_7286', Name: 'Melwood', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_KY_7287',
    Name: 'Crittenden',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NY_7288',
    Name: 'Springville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_FL_7289',
    Name: 'Ridge Wood Heights',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_GA_7290', Name: 'Nelson', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WI_7291',
    Name: 'Cross Plains',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_FL_7292',
    Name: 'Grant-Valkaria',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_VA_7293',
    Name: 'Hayfield',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_FL_7294',
    Name: 'Indian Rocks Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_KS_7295',
    Name: 'Osawatomie',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_NJ_7296', Name: 'Buena', stateId: 'NJ', stateName: 'New Jersey'},
  {
    Id: 'CIT_US_PA_7297',
    Name: 'Sellersville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_7298',
    Name: 'Medford',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_7299',
    Name: 'Mifflinburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_7300',
    Name: 'Three Oaks',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AL_7301',
    Name: 'Adamsville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_TX_7302', Name: 'Justin', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AL_7303', Name: 'Fayette', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_PA_7304',
    Name: 'Kenmar',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_7305', Name: 'Lytle', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_7306', Name: 'Coleman', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_7307', Name: 'Sparta', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_UT_7308', Name: 'Morgan', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NJ_7309',
    Name: 'Magnolia',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_7310',
    Name: 'Mather',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_7311',
    Name: 'Centralia',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_7312',
    Name: 'Chalfont',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PR_7313',
    Name: 'Quebradillas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_TN_7314', Name: 'Oneida', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_TX_7315',
    Name: 'Port Aransas',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_7316',
    Name: 'Beaver',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_7317',
    Name: 'Travis Ranch',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OR_7318', Name: 'Lafayette', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_KY_7319',
    Name: 'Reidland',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WI_7320',
    Name: 'Kewaskum',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OH_7321', Name: 'Park Layne', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_SC_7322',
    Name: 'Clearwater',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MI_7323',
    Name: 'Buchanan',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OH_7324', Name: 'Blanchester', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_FL_7325', Name: 'Belleair', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_SC_7326',
    Name: 'Williamston',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PR_7327',
    Name: 'Florida',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MO_7328',
    Name: 'Imperial',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WV_7329',
    Name: 'Barboursville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PR_7330',
    Name: 'Adjuntas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_FL_7331', Name: 'Mulberry', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_AL_7332',
    Name: 'Hokes Bluff',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_VT_7333', Name: 'Newport', stateId: 'VT', stateName: 'Vermont'},
  {
    Id: 'CIT_US_PA_7334',
    Name: 'Mifflintown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_7335',
    Name: 'Pittsfield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NJ_7336',
    Name: 'Ellisburg',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_TX_7337', Name: 'Groesbeck', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_7338', Name: 'Edgewood', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_VA_7339',
    Name: 'Stafford Courthouse',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NY_7340',
    Name: 'Garden City South',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_FL_7341', Name: 'Parker', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_IN_7342', Name: 'Mitchell', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_CT_7343',
    Name: 'Mystic',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_OH_7344',
    Name: 'Mount Repose',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NJ_7345',
    Name: 'Barclay',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_OH_7346',
    Name: 'Pleasant Run Farm',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_KY_7347', Name: 'Farley', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_OH_7348', Name: 'Georgetown', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_7349',
    Name: 'Freeburg',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_7350', Name: 'Cotulla', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_7351',
    Name: 'Raymond',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WV_7352',
    Name: 'Sissonville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MI_7353',
    Name: 'East Tawas',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IL_7354', Name: 'Lebanon', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_7355', Name: 'Waverly', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MN_7356',
    Name: 'Park Rapids',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_FL_7357',
    Name: 'Fort Pierce South',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_GA_7358', Name: 'Lyons', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MD_7359',
    Name: 'Pocomoke City',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_VA_7360', Name: 'Verona', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_WA_7361',
    Name: 'Venersborg',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NC_7362',
    Name: 'Bethlehem',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_7363',
    Name: 'Deer Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_7364',
    Name: 'Dorneyville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_7365', Name: 'Hiram', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NJ_7366',
    Name: 'Mountain Lakes',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_7367',
    Name: 'Mount Pleasant',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_7368', Name: 'Lake Darby', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_7369',
    Name: 'Masontown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_7370',
    Name: 'China Grove',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_7371', Name: 'Bellville', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OK_7372',
    Name: 'Broken Bow',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NJ_7373',
    Name: 'Singac',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MO_7374',
    Name: 'Fredericktown',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_7375',
    Name: 'McMurray',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_7376',
    Name: 'Ivanhoe',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_7377',
    Name: 'Brookfield',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NC_7378',
    Name: 'Mayodan',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_7379',
    Name: 'Moyock',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MN_7380',
    Name: 'Lonsdale',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_7381',
    Name: 'Central Gardens',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_MT_7382', Name: 'Libby', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_IA_7383', Name: 'Hampton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_KY_7384',
    Name: 'Greenville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WA_7385',
    Name: 'Esperance',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_AZ_7386', Name: 'Red Rock', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_AL_7387',
    Name: 'Woodstock',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NC_7388',
    Name: 'Spindale',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_KY_7389',
    Name: 'Lancaster',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OK_7390', Name: 'Bristow', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_FL_7391',
    Name: 'South Brooksville',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_7392', Name: 'Comanche', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_7393',
    Name: 'Silver Lake',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_FL_7394', Name: 'Ellenton', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_IL_7395',
    Name: 'Barrington Hills',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_7396',
    Name: 'Prairie Heights',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_AR_7397', Name: 'Lonoke', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_OH_7398', Name: 'Sebring', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_7399',
    Name: 'Thornwood',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_MO_7400', Name: 'Malden', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_NJ_7401',
    Name: 'Golden Triangle',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NY_7402',
    Name: 'University Gardens',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TN_7403',
    Name: 'Trenton',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_CO_7404',
    Name: 'Monte Vista',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MT_7405',
    Name: 'Malmstrom AFB',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_IL_7406',
    Name: 'Hometown',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_7407',
    Name: 'Scandia',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IL_7408', Name: 'Carlyle', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_WV_7409',
    Name: 'Westover',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NC_7410',
    Name: 'Mountain Home',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CT_7411',
    Name: 'Moosup',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_HI_7412',
    Name: 'Captain Cook',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_NM_7413',
    Name: 'El Cerro Mission',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_OH_7414', Name: 'Bluffton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MO_7415',
    Name: 'Whiteman AFB',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_7416', Name: 'Ovilla', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_7417', Name: 'Columbus', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_7418',
    Name: 'Lochmoor Waterway Estates',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IN_7419',
    Name: 'Fortville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_ID_7420', Name: 'Ketchum', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_PA_7421',
    Name: 'Aldan',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_7422',
    Name: 'West Menlo Park',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_7423',
    Name: 'Otisville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IA_7424', Name: 'Tiffin', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_7425',
    Name: 'Minersville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ND_7426',
    Name: 'Grafton',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_CT_7427',
    Name: 'Heritage Village',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_GA_7428', Name: 'Oakwood', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_OH_7429', Name: 'Fruit Hill', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_7430',
    Name: 'Harbison Canyon',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_7431',
    Name: 'Cockrell Hill',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CA_7432',
    Name: 'Carmel Valley Village',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OR_7433', Name: 'Tri-City', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_SC_7434',
    Name: 'Blythewood',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NC_7435',
    Name: 'Burgaw',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IL_7436', Name: 'Virden', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NJ_7437',
    Name: 'Bradley Beach',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OH_7438', Name: 'Barnesville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TN_7439',
    Name: 'Waverly',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MO_7440',
    Name: 'Camdenton',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NJ_7441',
    Name: 'Westville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PR_7442',
    Name: 'Imbéry',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MI_7443',
    Name: 'Constantine',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OH_7444', Name: 'Mulberry', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AL_7445',
    Name: 'Haleyville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_TN_7446',
    Name: 'Louisville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_IA_7447', Name: 'Chariton', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_TX_7448', Name: 'Taft', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MN_7449',
    Name: 'Rice Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MO_7450',
    Name: 'Herculaneum',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MI_7451',
    Name: 'Charlevoix',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_AZ_7452',
    Name: 'Dewey-Humboldt',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_MA_7453',
    Name: 'Northwest Harwich',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_IL_7454', Name: 'Peotone', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NJ_7455',
    Name: 'Riverdale',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WY_7456',
    Name: 'South Greeley',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {Id: 'CIT_US_TN_7457', Name: 'Etowah', stateId: 'TN', stateName: 'Tennessee'},
  {Id: 'CIT_US_MD_7458', Name: 'Riva', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_TX_7459',
    Name: 'Garden Ridge',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MN_7460',
    Name: 'Columbus',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NC_7461',
    Name: 'Swannanoa',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_GA_7462', Name: 'Dawson', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_IN_7463',
    Name: 'Rockville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_UT_7464', Name: 'Maeser', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_WA_7465',
    Name: 'Peaceful Valley',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NY_7466',
    Name: 'Herricks',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_7467',
    Name: 'Lenwood',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IN_7468',
    Name: 'New Carlisle',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OK_7469', Name: 'Pocola', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_MI_7470',
    Name: 'Benton Heights',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_KY_7471', Name: 'Stanton', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_IL_7472',
    Name: 'Christopher',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IN_7473', Name: 'Austin', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NC_7474',
    Name: 'Westport',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NE_7475',
    Name: 'Falls City',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_TN_7476',
    Name: 'New Tazewell',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_MN_7477', Name: 'Grant', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_GA_7478',
    Name: 'Walthourville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_NY_7479', Name: 'Waverly', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_NC_7480',
    Name: 'Randleman',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MO_7481',
    Name: 'Cassville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NJ_7482',
    Name: 'Woodstown',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OR_7483', Name: 'Burns', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NY_7484',
    Name: 'Heritage Hills',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WV_7485',
    Name: 'St. Marys',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IL_7486',
    Name: 'Caseyville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_7487',
    Name: 'Goodview',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NC_7488',
    Name: 'Forest Oaks',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_7489',
    Name: 'Sinking Spring',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MN_7490', Name: 'Wadena', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_CA_7491',
    Name: 'Del Monte Forest',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_7492',
    Name: 'Fleetwood',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_7493', Name: 'Sparks', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_WA_7494', Name: 'Moxee', stateId: 'WA', stateName: 'Washington'},
  {Id: 'CIT_US_MI_7495', Name: 'Perry', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_IA_7496', Name: 'Jefferson', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_7497',
    Name: 'Monongahela',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_7498',
    Name: 'Pearl River',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_TX_7499', Name: 'Dimmitt', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_7500',
    Name: 'Magee',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WI_7501',
    Name: 'West Milwaukee',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NM_7502',
    Name: 'Holloman AFB',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_TX_7503', Name: 'Presidio', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_FL_7504', Name: 'Bonifay', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_PA_7505',
    Name: 'Scottdale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OR_7506',
    Name: 'Wood Village',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MI_7507',
    Name: 'Lathrup Village',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MA_7508',
    Name: 'North Plymouth',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_IL_7509', Name: 'Burnham', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NC_7510',
    Name: 'Rutherfordton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_7511',
    Name: 'Marietta-Alderwood',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_7512',
    Name: 'North Richmond',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_7513',
    Name: 'Lorane',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CT_7514',
    Name: 'Pemberwick',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_FL_7515',
    Name: 'Charlotte Harbor',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PR_7516',
    Name: 'Camuy',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_AR_7517', Name: 'Austin', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_NC_7518',
    Name: 'Whispering Pines',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OH_7519',
    Name: 'Newcomerstown',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_HI_7520',
    Name: 'Waimanalo Beach',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_MO_7521',
    Name: 'Northwoods',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_NE_7522', Name: 'Waverly', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_OR_7523',
    Name: 'Beavercreek',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_PA_7524',
    Name: 'Sharpsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PR_7525',
    Name: 'Punta Santiago',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_NY_7526',
    Name: 'Brewerton',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WV_7527',
    Name: 'Ripley',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WI_7528',
    Name: 'Mosinee',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_7529',
    Name: 'Boyertown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_7530',
    Name: 'Running Springs',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_VA_7531', Name: 'Narrows', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_PR_7532',
    Name: 'Aguilita',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_AL_7533',
    Name: 'Alexandria',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_OH_7534', Name: 'New Lebanon', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MS_7535',
    Name: 'University',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IL_7536',
    Name: 'Rosemont',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_DE_7537',
    Name: 'Rising Sun-Lebanon',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_NJ_7538',
    Name: 'Ho-Ho-Kus',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_FL_7539',
    Name: 'West Bradenton',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_7540',
    Name: 'Rosewood Heights',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_7541', Name: 'La Paloma', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_7542',
    Name: 'Desoto Lakes',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_OK_7543',
    Name: 'Stilwell',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MI_7544',
    Name: 'Marine City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_KY_7545', Name: 'Walton', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_KY_7546',
    Name: 'Crescent Springs',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MN_7547',
    Name: 'Le Sueur',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_7548',
    Name: 'Rio Dell',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_7549',
    Name: 'Ridgway',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_ID_7550', Name: 'Kimberly', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_NY_7551',
    Name: 'Spackenkill',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NJ_7552',
    Name: 'Shrewsbury',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_7553',
    Name: 'Egg Harbor City',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_7554',
    Name: 'Empire',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_7555',
    Name: 'Cannon Falls',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TN_7556',
    Name: 'Livingston',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_SC_7557',
    Name: 'Hampton',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_FL_7558',
    Name: 'Lake Belvedere Estates',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MN_7559',
    Name: 'Pipestone',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_7560',
    Name: 'Marienville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_7561',
    Name: 'County Center',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_7562',
    Name: 'Spring House',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_7563',
    Name: 'Fayetteville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_7564',
    Name: 'Forty Fort',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_7565', Name: 'Jamestown', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_VA_7566',
    Name: 'Loch Lomond',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NC_7567',
    Name: 'Sunset Beach',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MO_7568',
    Name: 'Woodson Terrace',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MI_7569', Name: 'Algonac', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_FL_7570', Name: 'Goulding', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MO_7571',
    Name: 'St. James',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_7572', Name: 'Redwood', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NC_7573',
    Name: 'Red Springs',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MO_7574',
    Name: 'Vandalia',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_7575',
    Name: 'Trevose',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_7576',
    Name: 'Belzoni',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PR_7577',
    Name: 'Hatillo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_VA_7578',
    Name: "Lake Land'Or",
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NC_7579',
    Name: 'Weaverville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_MO_7580', Name: 'Fenton', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_OR_7581',
    Name: 'Crooked River Ranch',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_IA_7582', Name: 'Forest City', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_TX_7583',
    Name: 'Horseshoe Bay',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_AR_7584', Name: 'Gentry', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_CA_7585',
    Name: 'San Joaquin',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_7586',
    Name: 'Trent Woods',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_7587', Name: 'San Carlos', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_ME_7588',
    Name: 'Lisbon Falls',
    stateId: 'ME',
    stateName: 'Maine',
  },
  {Id: 'CIT_US_TX_7589', Name: 'Crandall', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AK_7590', Name: 'Butte', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_PA_7591',
    Name: 'Akron',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_7592', Name: 'Bellaire', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_KY_7593',
    Name: 'Southgate',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CT_7594',
    Name: 'Danielson',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_KY_7595', Name: 'Louisa', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_IL_7596', Name: 'Kildeer', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CO_7597',
    Name: 'Rocky Ford',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_LA_7598',
    Name: 'Rayville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_FL_7599',
    Name: 'Taylor Creek',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MA_7600',
    Name: 'Turners Falls',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_GA_7601',
    Name: 'Rossville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_IA_7602', Name: 'Saylorville', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_VA_7603',
    Name: 'University Center',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_SC_7604',
    Name: 'India Hook',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_GA_7605', Name: 'Ashburn', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_IL_7606',
    Name: 'South Chicago Heights',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_7607',
    Name: 'Centerville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_OK_7608',
    Name: 'Park Hill',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_IL_7609',
    Name: 'Lakewood',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MO_7610', Name: 'Butler', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MI_7611',
    Name: 'Bloomfield Hills',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NC_7612',
    Name: 'Gamewell',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_7613', Name: 'Mabank', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_7614',
    Name: 'Parkesburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_7615',
    Name: 'Lawnton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_LA_7616', Name: 'Jena', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_AR_7617',
    Name: 'Shannon Hills',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_AR_7618',
    Name: 'Southside',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_TX_7619', Name: 'Richwood', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_7620',
    Name: 'Morrison',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_7621',
    Name: 'Greencastle',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_7622',
    Name: 'Marshall',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_7623',
    Name: 'Wrightwood',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KS_7624',
    Name: 'Clay Center',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_NC_7625',
    Name: 'Louisburg',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MS_7626',
    Name: 'Fulton',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_WY_7627', Name: 'Mills', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_PA_7628',
    Name: 'Pocono Woodland Lakes',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_7629',
    Name: 'Shavano Park',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WV_7630',
    Name: 'Madison',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_FL_7631',
    Name: 'High Point',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NY_7632',
    Name: 'Lyncourt',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WA_7633',
    Name: 'Longbranch',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_OR_7634',
    Name: 'Harrisburg',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_TX_7635', Name: 'Nassau Bay', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TN_7636',
    Name: 'Tiptonville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_FL_7637',
    Name: 'West DeLand',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_7638',
    Name: 'Meridian',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_GA_7639',
    Name: 'Manchester',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_KS_7640', Name: 'Rose Hill', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_MO_7641', Name: 'Ashland', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_OK_7642', Name: 'Spencer', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NY_7643',
    Name: 'Bardonia',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NC_7644',
    Name: 'Southport',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_GA_7645',
    Name: 'Blackshear',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CA_7646',
    Name: 'Burney',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_7647', Name: 'Le Claire', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_HI_7648',
    Name: 'Whitmore Village',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_MS_7649',
    Name: 'Winona',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_GA_7650',
    Name: 'Washington',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_MT_7651', Name: 'Lolo', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_KS_7652', Name: 'Fairway', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MD_7653',
    Name: 'Sykesville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_OK_7654',
    Name: 'Fort Gibson',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_FL_7655',
    Name: 'Buckingham',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PR_7656',
    Name: 'Barceloneta',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_CA_7657',
    Name: 'Angels',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_7658', Name: 'Brazoria', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_VA_7659', Name: 'Crewe', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_NJ_7660',
    Name: 'Oaklyn',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NC_7661',
    Name: 'Tyro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IN_7662', Name: 'Roselawn', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_AL_7663', Name: 'Holt', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_WA_7664',
    Name: 'Kalama',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_VA_7665',
    Name: 'Mount Hermon',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NJ_7666',
    Name: 'Lawrenceville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NC_7667',
    Name: 'Elroy',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_7668',
    Name: 'Elverson',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_7669',
    Name: 'Mahanoy City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_GA_7670',
    Name: 'Hephzibah',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_GA_7671', Name: 'Metter', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NY_7672',
    Name: 'West Nyack',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_7673',
    Name: 'Carroll Valley',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_UT_7674', Name: 'Wellsville', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_OH_7675',
    Name: 'Chagrin Falls',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_MI_7676', Name: 'Linden', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_WA_7677',
    Name: 'Bunk Foss',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_OK_7678',
    Name: 'Nichols Hills',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_TX_7679',
    Name: 'Bunker Hill Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_LA_7680', Name: 'Ball', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_CA_7681',
    Name: 'North El Monte',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_7682',
    Name: 'Village Shires',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_7683',
    Name: 'Clearlake Riviera',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OR_7684',
    Name: 'Mount Angel',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_PA_7685',
    Name: 'Skyline View',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_7686',
    Name: 'Bayard',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MN_7687',
    Name: 'Deephaven',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NC_7688',
    Name: 'Jefferson',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_7689',
    Name: 'Maytown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AZ_7690', Name: 'Carefree', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_NJ_7691',
    Name: 'Richwood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PR_7692',
    Name: 'Lares',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_OH_7693', Name: 'Montpelier', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_7694',
    Name: 'Benson',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MD_7695',
    Name: 'Brookmont',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NC_7696',
    Name: 'Dana',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_7697',
    Name: 'Fox Island',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_FL_7698',
    Name: 'Highland Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_SC_7699',
    Name: 'Pageland',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NJ_7700',
    Name: 'Medford Lakes',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OR_7701', Name: 'Boardman', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_OK_7702', Name: 'Roland', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_CO_7703', Name: 'Genesee', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_NJ_7704',
    Name: 'Roebling',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_7705',
    Name: 'Laureldale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_7706',
    Name: 'Lake Park',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MS_7707',
    Name: 'New Hope',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_MT_7708', Name: 'Hardin', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_OH_7709',
    Name: 'Mount Gilead',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_AL_7710',
    Name: 'Hazel Green',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PA_7711',
    Name: 'Charleroi',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_7712',
    Name: 'Ravenswood',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_SC_7713',
    Name: 'Andrews',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NC_7714',
    Name: 'Fairview',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_7715',
    Name: 'Green Oaks',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AL_7716',
    Name: 'Grand Bay',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MI_7717',
    Name: 'Belleville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_7718',
    Name: 'Throop',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_7719',
    Name: 'Odenville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_FL_7720',
    Name: 'Apalachicola',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_VA_7721', Name: 'Elkton', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_HI_7722',
    Name: 'Iroquois Point',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_FL_7723',
    Name: 'Vineyards',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NM_7724',
    Name: 'Bosque Farms',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_CA_7725',
    Name: 'Vine Hill',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OR_7726', Name: 'Metzger', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MI_7727',
    Name: 'Wolf Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NY_7728',
    Name: 'North Great River',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_7729',
    Name: 'Level Green',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_7730', Name: 'Monticello', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_7731',
    Name: 'Baden',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_7732', Name: 'Clyde', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_7733', Name: 'Dupo', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_HI_7734', Name: 'Waikapu', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_FL_7735',
    Name: 'North River Shores',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_7736',
    Name: 'Wheatland',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_CO_7737', Name: 'Niwot', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_AK_7738', Name: 'Nome', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_WI_7739',
    Name: 'Rochester',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_7740',
    Name: 'Washington Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_NY_7741', Name: 'Menands', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_NJ_7742',
    Name: 'Port Reading',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_7743',
    Name: 'Grantley',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_7744',
    Name: 'Rogersville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_DE_7745',
    Name: 'Bridgeville',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_AL_7746',
    Name: 'Citronelle',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_FL_7747', Name: 'Umatilla', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_TX_7748', Name: 'Canton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TN_7749',
    Name: 'Gatlinburg',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MD_7750',
    Name: 'St. James',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WI_7751',
    Name: 'Chilton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IN_7752',
    Name: 'Leo-Cedarville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OH_7753', Name: 'Swanton', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_LA_7754', Name: 'Bunkie', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_IL_7755',
    Name: 'Millstadt',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_7756',
    Name: 'Dallastown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_7757',
    Name: 'Shrewsbury',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AK_7758', Name: 'Valdez', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_PA_7759',
    Name: 'Pennsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_7760',
    Name: 'Mojave',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_7761', Name: 'Rock Valley', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NY_7762',
    Name: 'Wurtsboro',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_7763',
    Name: 'Mount Sterling',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_UT_7764',
    Name: 'Mountain Green',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_OH_7765',
    Name: 'Yellow Springs',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WA_7766',
    Name: 'Cle Elum',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_7767',
    Name: 'Denver',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_7768',
    Name: 'Bethel',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WA_7769',
    Name: 'Larch Way',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_HI_7770', Name: 'Puhi', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_TX_7771', Name: 'Eastland', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_FL_7772', Name: 'Chipley', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_7773',
    Name: 'Shingle Springs',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_7774',
    Name: 'Smithton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_7775',
    Name: 'Glenwood Landing',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_7776',
    Name: 'Mission Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_7777',
    Name: 'French Camp',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_7778', Name: 'South Point', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_7779',
    Name: 'Palmer Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_7780',
    Name: 'Thomasville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_TN_7781',
    Name: 'Huntingdon',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MN_7782',
    Name: 'St. Augusta',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WI_7783',
    Name: 'Abbotsford',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MI_7784',
    Name: 'Level Park-Oak Park',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NM_7785',
    Name: 'Meadow Lake',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_WA_7786',
    Name: 'Granger',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_GA_7787', Name: 'Leesburg', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NY_7788',
    Name: 'Highland Falls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_7789', Name: 'Nash', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SC_7790',
    Name: 'Saxon',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OH_7791', Name: 'LaGrange', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_7792',
    Name: 'River Road',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_SC_7793',
    Name: 'Honea Path',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OH_7794', Name: 'Mogadore', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MD_7795',
    Name: 'Pleasant Hills',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_PA_7796',
    Name: 'Houtzdale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_ID_7797', Name: 'Lincoln', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_IN_7798', Name: 'Ossian', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PA_7799',
    Name: 'Windber',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_7800',
    Name: 'Leland',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OH_7801', Name: 'Greentown', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_LA_7802',
    Name: 'Vidalia',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NJ_7803',
    Name: 'Panther Valley',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_TN_7804',
    Name: 'Hohenwald',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WI_7805',
    Name: 'North Hudson',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_7806',
    Name: 'Carmel-by-the-Sea',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_7807',
    Name: 'Marshall',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_AL_7808', Name: 'Elba', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_CA_7809',
    Name: 'Arbuckle',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_7810',
    Name: 'Winneconne',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_7811', Name: 'Friona', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_7812', Name: 'Barrett', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_7813',
    Name: 'Key Center',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NC_7814',
    Name: 'Midland',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_7815',
    Name: 'Warminster Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_7816',
    Name: 'Elmira Heights',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NJ_7817',
    Name: 'Oakhurst',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NC_7818',
    Name: 'Welcome',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MN_7819',
    Name: 'Independence',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WA_7820',
    Name: 'Longview Heights',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_7821',
    Name: 'Lansford',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MD_7822',
    Name: 'Indian Head',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MI_7823',
    Name: 'Fowlerville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NJ_7824',
    Name: 'Lambertville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_7825',
    Name: 'Brooktrails',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AZ_7826',
    Name: 'Avenue B and C',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NC_7827',
    Name: 'Lillington',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MI_7828',
    Name: 'Roosevelt Park',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MI_7829',
    Name: 'North Muskegon',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MA_7830',
    Name: 'Forestdale',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_MI_7831',
    Name: 'Grayling',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IA_7832', Name: 'Carter Lake', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AR_7833',
    Name: 'Booneville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_7834',
    Name: 'Valley Green',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_7835',
    Name: 'Cedar Springs',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_TX_7836', Name: 'Cisco', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_7837',
    Name: 'Mirrormont',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NC_7838',
    Name: 'Grifton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NJ_7839',
    Name: 'Gibbstown',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_TX_7840', Name: 'Eagle Lake', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MI_7841',
    Name: 'Plainwell',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_7842',
    Name: 'Freedom',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_KS_7843', Name: 'Hugoton', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WA_7844',
    Name: 'Lake Goodwin',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_VA_7845',
    Name: 'Colonial Beach',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MN_7846',
    Name: 'Norwood Young America',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OR_7847', Name: 'Waldport', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_OR_7848', Name: 'Toledo', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NJ_7849',
    Name: 'Country Lake Estates',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_IA_7850',
    Name: 'West Liberty',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_FL_7851',
    Name: 'Graceville',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MN_7852',
    Name: 'Bayport',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AZ_7853',
    Name: 'Quartzsite',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_FL_7854',
    Name: 'Port St. Joe',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NC_7855',
    Name: 'Stanley',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_AL_7856', Name: 'Centre', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_WI_7857', Name: 'Merton', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_NC_7858',
    Name: 'Eastover',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_KY_7859', Name: 'Fulton', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_MI_7860',
    Name: 'Imlay City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_7861',
    Name: 'Forestville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IN_7862',
    Name: 'Melody Hill',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NJ_7863',
    Name: 'North Wildwood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PR_7864',
    Name: 'Celada',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_CA_7865',
    Name: 'Bystrom',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_7866',
    Name: 'St. Charles',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IL_7867', Name: 'Madison', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_HI_7868', Name: 'Haleiwa', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_FL_7869',
    Name: 'San Castle',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_LA_7870',
    Name: 'Brownsville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OH_7871', Name: 'Calcutta', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_UT_7872', Name: 'Manti', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_FL_7873',
    Name: 'Harbour Heights',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_KS_7874',
    Name: 'Scott City',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_LA_7875',
    Name: 'Franklinton',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_LA_7876', Name: 'Mamou', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_PA_7877',
    Name: 'Irwin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OH_7878',
    Name: 'South Russell',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_FL_7879',
    Name: 'Whitfield',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_7880',
    Name: 'Agua Dulce',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AR_7881', Name: 'Johnson', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_KS_7882', Name: 'Hesston', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_AL_7883', Name: 'Sumiton', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_IL_7884',
    Name: 'White Hall',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_7885', Name: 'Cresco', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_FL_7886',
    Name: 'Cross City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_LA_7887',
    Name: 'Farmerville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_GA_7888',
    Name: 'West Point',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_NE_7889', Name: 'Cozad', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_FL_7890', Name: 'Madison', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_IN_7891',
    Name: 'North Webster',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_GA_7892', Name: 'Ocilla', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NM_7893',
    Name: 'La Cienega',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NJ_7894',
    Name: 'Turnersville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NY_7895',
    Name: 'Village Green',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MI_7896',
    Name: 'Boyne City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_LA_7897',
    Name: 'Jackson',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_FL_7898', Name: 'Bagdad', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_TX_7899', Name: 'Bullard', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_7900',
    Name: 'Itta Bena',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_TX_7901',
    Name: 'North Alamo',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IN_7902', Name: 'Upland', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PA_7903',
    Name: 'Trappe',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_7904', Name: 'Lavon', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_7905',
    Name: 'Coal Valley',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WI_7906',
    Name: 'Pulaski',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_7907',
    Name: 'Indian Head Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SC_7908',
    Name: 'Lyman',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_TX_7909', Name: 'Comfort', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_7910',
    Name: 'Boulevard Park',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_TX_7911', Name: 'Sweeny', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NC_7912',
    Name: 'Lowell',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_7913',
    Name: 'Maiden',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_ID_7914',
    Name: 'St. Anthony',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_IL_7915',
    Name: 'Metamora',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WI_7916',
    Name: 'Stanley',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NC_7917',
    Name: 'St. Pauls',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_7918',
    Name: 'Hudson',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_7919',
    Name: 'Rochester',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_7920',
    Name: 'Bryn Mawr',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_7921',
    Name: 'Belvidere',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_FL_7922',
    Name: 'Flagler Estates',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_AR_7923', Name: 'Fordyce', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_WA_7924',
    Name: 'Sunnyslope',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_LA_7925',
    Name: 'Livonia',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WI_7926',
    Name: 'Lancaster',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NC_7927',
    Name: 'Mountain View',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_GA_7928', Name: 'Ellijay', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NJ_7929',
    Name: 'Merchantville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NV_7930',
    Name: 'Battle Mountain',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_NC_7931',
    Name: 'Plymouth',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_7932',
    Name: 'Emerald Isle',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_7933',
    Name: 'SUNY Oswego',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PR_7934',
    Name: 'Candelaria Arenas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_AZ_7935',
    Name: 'San Manuel',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_FL_7936', Name: 'Clarcona', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_PA_7937',
    Name: 'Mount Union',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_7938',
    Name: 'Leisure Village East',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MT_7939',
    Name: 'Wolf Point',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_NY_7940', Name: 'Montauk', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_NJ_7941',
    Name: 'South Toms River',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_7942',
    Name: 'Woodbourne',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_7943', Name: 'Emmetsburg', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_AR_7944', Name: 'McGehee', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_AZ_7945',
    Name: 'Lake of the Woods',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NY_7946',
    Name: 'Maybrook',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NJ_7947',
    Name: 'Beach Haven West',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_SD_7948',
    Name: 'Pine Ridge',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IA_7949', Name: 'Albia', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_7950',
    Name: 'Avalon',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_7951',
    Name: 'Meadow Vista',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_7952', Name: 'Potosi', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IN_7953', Name: 'Aurora', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_FL_7954', Name: 'Lely', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MA_7955',
    Name: 'Sagamore',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_AR_7956',
    Name: 'Brookland',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NY_7957',
    Name: 'Arlington',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_FL_7958',
    Name: 'Feather Sound',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IN_7959', Name: 'Hebron', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_OH_7960',
    Name: 'Wintersville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_NM_7961', Name: 'Milan', stateId: 'NM', stateName: 'New Mexico'},
  {
    Id: 'CIT_US_NC_7962',
    Name: 'Ranlo',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_7963',
    Name: 'Muttontown',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_PR_7964', Name: 'Moca', stateId: 'PR', stateName: 'Puerto Rico'},
  {
    Id: 'CIT_US_CA_7965',
    Name: 'Auburn Lake Trails',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_7966', Name: 'Northfield', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_FL_7967',
    Name: 'Royal Palm Estates',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_7968',
    Name: 'Millvale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_7969',
    Name: 'Mullica Hill',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NY_7970',
    Name: 'Lloyd Harbor',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_7971', Name: 'Fritch', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_7972',
    Name: 'Spencerport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MN_7973',
    Name: 'Melrose',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AZ_7974', Name: 'Oracle', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_NC_7975',
    Name: 'Troy',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_7976',
    Name: 'Thorndale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MD_7977',
    Name: 'Boonsboro',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_FL_7978',
    Name: 'Juno Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_MN_7979', Name: 'Mora', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_TN_7980',
    Name: 'White Bluff',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_GA_7981', Name: 'Quitman', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_PA_7982',
    Name: 'Port Vue',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_7983',
    Name: 'Wills Point',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_7984',
    Name: 'Wadsworth',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_7985',
    Name: 'Fairbury',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_7986',
    Name: 'Squaw Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MN_7987', Name: 'Perham', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_TX_7988',
    Name: 'Old River-Winfree',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WI_7989',
    Name: 'Horicon',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OH_7990', Name: 'Richfield', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_SC_7991',
    Name: 'Northlake',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MD_7992',
    Name: 'North East',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_VA_7993',
    Name: 'Shenandoah Farms',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_7994', Name: 'Ballinger', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_7995',
    Name: 'Maple Heights-Lake Desire',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_OR_7996',
    Name: 'Shady Cove',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_CO_7997',
    Name: 'Meridian',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NJ_7998',
    Name: 'Society Hill',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_TN_7999',
    Name: 'Wildwood Lake',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_OK_8000',
    Name: 'Heavener',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_CO_8001',
    Name: 'Ponderosa Park',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_TX_8002', Name: 'Westway', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_8003',
    Name: 'South Glens Falls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_8004',
    Name: 'Felton',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_8005', Name: 'Blanding', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_TN_8006', Name: 'Camden', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_WA_8007',
    Name: 'Clearview',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_GA_8008', Name: 'Ringgold', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_TX_8009',
    Name: 'Farmersville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MA_8010',
    Name: 'South Duxbury',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_SD_8011',
    Name: 'Dell Rapids',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_FL_8012',
    Name: 'Lake Clarke Shores',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IN_8013', Name: 'Paoli', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_SC_8014',
    Name: 'Duncan',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OH_8015', Name: 'Bolivar', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NE_8016',
    Name: 'Fairbury',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_KS_8017', Name: 'Beloit', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_IA_8018', Name: 'Waukon', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OH_8019', Name: 'Oakwood', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CO_8020',
    Name: 'Las Animas',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CA_8021',
    Name: 'Pajaro',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_8022',
    Name: 'New London',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CO_8023',
    Name: 'Cedaredge',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NY_8024',
    Name: 'Manhasset Hills',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NC_8025',
    Name: 'Northchase',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IN_8026',
    Name: 'Pittsboro',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_DE_8027',
    Name: 'Dover Base Housing',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {Id: 'CIT_US_GA_8028', Name: 'Clayton', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_SC_8029',
    Name: 'Dunean',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MO_8030',
    Name: 'El Dorado Springs',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NY_8031',
    Name: 'Whitesboro',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_8032',
    Name: 'Frackville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_FL_8033', Name: 'Century', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_8034',
    Name: 'Tarpey Village',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_8035',
    Name: 'Mercer',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_8036',
    Name: 'Northumberland',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MI_8037', Name: 'Romeo', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_NY_8038',
    Name: 'Brookville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_SC_8039',
    Name: 'Saluda',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NY_8040',
    Name: 'Cayuga Heights',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_8041',
    Name: 'Kutztown University',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_CA_8042', Name: 'Heber', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_CA_8043',
    Name: 'Moss Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_8044',
    Name: 'Zelienople',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_8045', Name: 'Bonanza', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_UT_8046', Name: 'Delta', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_FL_8047',
    Name: 'Ridgecrest',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WA_8048',
    Name: 'Lake Cassidy',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_ID_8049', Name: 'Gooding', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_MO_8050', Name: 'Palmyra', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_CA_8051',
    Name: 'Lake of the Pines',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_ID_8052', Name: 'McCall', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_NY_8053',
    Name: 'Gordon Heights',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AR_8054', Name: 'Ozark', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_TX_8055', Name: 'Scissors', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_RI_8056',
    Name: 'Narragansett Pier',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {Id: 'CIT_US_TN_8057', Name: 'Unicoi', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_WI_8058',
    Name: 'Combined Locks',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_AL_8059', Name: 'Kimberly', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MO_8060',
    Name: 'Pine Lawn',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_FL_8061',
    Name: 'Loxahatchee Groves',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AK_8062',
    Name: 'Goldstream',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_SC_8063',
    Name: 'Southern Shops',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_TX_8064', Name: 'Elm Creek', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MN_8065',
    Name: 'Newport',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_OH_8066',
    Name: 'North Baltimore',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NM_8067',
    Name: 'Peralta',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_WI_8068', Name: 'Omro', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_CA_8069',
    Name: 'Woodbridge',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_8070',
    Name: 'Saw Creek',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_8071',
    Name: 'Stallion Springs',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TN_8072',
    Name: 'Christiana',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_GA_8073', Name: 'Darien', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_NE_8074', Name: "O'Neill", stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_ID_8075', Name: 'Orofino', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_WI_8076',
    Name: 'Osceola',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OH_8077', Name: 'Golf Manor', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_8078',
    Name: 'Thompsonville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_8079',
    Name: 'Sandia Heights',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_TX_8080',
    Name: 'Taylor Lake Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NC_8081',
    Name: 'Kitty Hawk',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_AL_8082',
    Name: 'Valley Grande',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_HI_8083', Name: 'Volcano', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_OR_8084', Name: 'Hubbard', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_CA_8085',
    Name: 'Meiners Oaks',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_8086',
    Name: 'Eveleth',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OH_8087', Name: 'Greenhills', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_KY_8088',
    Name: 'Rineyville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IL_8089', Name: 'Dixmoor', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NY_8090',
    Name: 'Lake Erie Beach',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_8091',
    Name: 'Riverwoods',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_8092',
    Name: 'Newtown Grant',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_8093',
    Name: 'Kelseyville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IN_8094',
    Name: 'Cambridge City',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_SD_8095',
    Name: 'Canton',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IL_8096', Name: 'Oglesby', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_IL_8097',
    Name: 'Hainesville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_8098',
    Name: 'Brittany Farms-The Highlands',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MT_8099',
    Name: 'Helena Valley Northeast',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_IL_8100',
    Name: 'Long Lake',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_8101',
    Name: 'Northwest Harbor',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_MI_8102', Name: 'Oxford', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_OH_8103', Name: 'Gallipolis', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_8104',
    Name: 'Pen Argyl',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IL_8105', Name: 'Oregon', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_IA_8106', Name: 'Osage', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OH_8107', Name: 'Carey', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MN_8108',
    Name: 'Annandale',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_8109', Name: 'Dublin', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OK_8110', Name: 'Nowata', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_DE_8111', Name: 'Camden', stateId: 'DE', stateName: 'Delaware'},
  {Id: 'CIT_US_GA_8112', Name: 'Vienna', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_OH_8113', Name: 'Baltimore', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_8114', Name: 'Shadyside', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_KS_8115',
    Name: 'Mission Hills',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_NY_8116',
    Name: 'Marlboro',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_8117',
    Name: 'Boiling Springs',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IN_8118', Name: 'Knox', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_OK_8119',
    Name: 'Frederick',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_FL_8120',
    Name: 'Windermere',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_8121',
    Name: 'Nanty-Glo',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_8122',
    Name: 'Frontenac',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_8123', Name: 'Amberley', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MS_8124',
    Name: 'Mississippi State',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_NY_8125', Name: 'Sloan', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_TN_8126',
    Name: 'Centerville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PR_8127',
    Name: 'Breñas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_IA_8128', Name: 'Robins', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NY_8129',
    Name: 'Valhalla',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AZ_8130', Name: 'Willcox', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_SD_8131',
    Name: 'Lead',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_PA_8132',
    Name: 'Berwyn',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IL_8133', Name: 'Momence', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_UT_8134',
    Name: 'Mount Pleasant',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_MN_8135',
    Name: 'Two Harbors',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AR_8136', Name: 'Piggott', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_MA_8137',
    Name: 'Buzzards Bay',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_MS_8138',
    Name: 'Morton',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_AL_8139',
    Name: 'Evergreen',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_GA_8140', Name: 'Unadilla', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WA_8141',
    Name: 'Indianola',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NC_8142',
    Name: 'Rockfish',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_8143',
    Name: 'Lockeford',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_8144',
    Name: 'Holden Heights',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_KY_8145',
    Name: 'West Liberty',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MI_8146',
    Name: 'Paw Paw Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MI_8147',
    Name: 'Harrison',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SC_8148',
    Name: 'Golden Grove',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OH_8149', Name: 'Mariemont', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_8150',
    Name: 'Reliez Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VA_8151',
    Name: 'Southern Gateway',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_AR_8152',
    Name: 'Gravette',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_TX_8153', Name: 'Teague', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_8154',
    Name: 'Silver Creek',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OH_8155', Name: 'Brimfield', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AZ_8156',
    Name: 'St. Johns',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WA_8157',
    Name: 'La Conner',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_DE_8158', Name: 'Clayton', stateId: 'DE', stateName: 'Delaware'},
  {
    Id: 'CIT_US_WI_8159',
    Name: 'Wrightstown',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_8160', Name: 'Poteet', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MN_8161',
    Name: 'Hanover',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MO_8162',
    Name: 'Riverside',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WA_8163',
    Name: 'Goldendale',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_8164',
    Name: 'Bloomer',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_KS_8165', Name: 'Lyons', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WI_8166',
    Name: 'Seymour',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_8167',
    Name: 'Amity Gardens',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SD_8168',
    Name: 'Hot Springs',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_CA_8169',
    Name: 'Valley Springs',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IN_8170', Name: 'Hanover', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NV_8171',
    Name: 'Nellis AFB',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_MN_8172',
    Name: 'Zumbrota',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_MI_8173', Name: 'Vassar', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_CA_8174',
    Name: 'Granite Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NE_8175',
    Name: 'Broken Bow',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_TX_8176', Name: 'Llano', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_8177',
    Name: 'Moscow Mills',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_8178',
    Name: 'Emerald Lakes',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_8179',
    Name: 'Lake Isabella',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_8180',
    Name: 'Glen Raven',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MD_8181',
    Name: 'Grasonville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_IL_8182', Name: 'Le Roy', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MO_8183',
    Name: 'East Prairie',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MI_8184', Name: 'Newaygo', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_FL_8185',
    Name: 'St. James City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_8186', Name: 'Waller', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MI_8187',
    Name: 'West Monroe',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_AR_8188', Name: 'Elkins', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_MD_8189',
    Name: 'Huntingtown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NC_8190',
    Name: 'Lake Royale',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WI_8191',
    Name: 'Sauk City',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_OR_8192',
    Name: 'Myrtle Creek',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_PA_8193',
    Name: 'Kane',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_8194',
    Name: 'Vicksburg',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OH_8195', Name: 'Delshire', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_LA_8196', Name: 'Vivian', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_NY_8197',
    Name: 'Locust Valley',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WA_8198',
    Name: 'Three Lakes',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NY_8199',
    Name: 'Watkins Glen',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IL_8200', Name: 'Red Bud', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_IL_8201', Name: 'Forsyth', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_CO_8202', Name: 'Yuma', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_MS_8203',
    Name: 'Raymond',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NY_8204',
    Name: 'Balmville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_8205',
    Name: 'Cienegas Terrace',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MO_8206',
    Name: 'Carrollton',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_FL_8207', Name: 'Alturas', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MN_8208',
    Name: 'Pine Island',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MI_8209',
    Name: 'Middleville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MA_8210',
    Name: 'East Sandwich',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_AL_8211',
    Name: 'Emerald Mountain',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MD_8212',
    Name: 'Brentwood',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MD_8213',
    Name: 'Marlboro Meadows',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NC_8214',
    Name: 'Red Oak',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_GA_8215', Name: 'Pelham', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_OH_8216', Name: 'Hicksville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_8217',
    Name: 'Johnston City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_8218',
    Name: 'Cedar Grove',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_8219',
    Name: 'Hidden Meadows',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WY_8220',
    Name: 'Wheatland',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_AL_8221',
    Name: 'Hanceville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_KY_8222',
    Name: 'Hodgenville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WV_8223',
    Name: 'Shannondale',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MA_8224',
    Name: 'North Pembroke',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_LA_8225', Name: 'Sunset', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_WI_8226',
    Name: 'Brodhead',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_AR_8227',
    Name: 'Alexander',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_8228',
    Name: 'Loyalhanna',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_8229',
    Name: 'Malvern',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_8230',
    Name: 'Putnam Lake',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_8231',
    Name: 'McLendon-Chisholm',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OR_8232', Name: 'Carlton', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_PA_8233',
    Name: 'Elim',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_8234',
    Name: 'Wilkesboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TX_8235',
    Name: 'Cross Mountain',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_OK_8236',
    Name: 'Pawhuska',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_TX_8237', Name: 'Doolittle', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_8238',
    Name: 'Rollingwood',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NE_8239',
    Name: 'Gothenburg',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_GA_8240',
    Name: 'Hannahs Mill',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_TN_8241', Name: 'Jasper', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_OH_8242',
    Name: 'Mineral Ridge',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_ID_8243', Name: 'Heyburn', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_CA_8244',
    Name: 'Oakhurst',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_8245', Name: 'Clifton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_8246',
    Name: 'Moore Haven',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_DE_8247',
    Name: 'Highland Acres',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_PR_8248',
    Name: 'Aguas Buenas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_CA_8249',
    Name: 'Mono Vista',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_8250', Name: 'Aledo', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_8251', Name: 'Mount Orab', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TX_8252',
    Name: 'Piney Point Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_SD_8253',
    Name: 'Mobridge',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_OH_8254', Name: 'Dillonvale', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_HI_8255', Name: 'Kekaha', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_KS_8256',
    Name: 'Oaklawn-Sunview',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_FL_8257',
    Name: 'Point Baker',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_8258', Name: 'Lockland', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_8259',
    Name: 'Rochester',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_8260',
    Name: 'Treasure Lake',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_8261',
    Name: 'Cottonport',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_FL_8262',
    Name: 'Tierra Verde',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AR_8263',
    Name: 'Landmark',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_LA_8264', Name: 'Iowa', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_IL_8265',
    Name: 'Riverton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_8266',
    Name: 'Canyon Creek',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_8267',
    Name: 'Riverdale',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_8268',
    Name: 'Hoosick Falls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_8269',
    Name: 'San Miguel',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_8270', Name: 'Ross', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_8271',
    Name: 'Yadkinville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_MD_8272', Name: 'La Vale', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_IL_8273',
    Name: 'Gibson City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_8274',
    Name: 'South Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MA_8275',
    Name: 'Dennis Port',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_CA_8276',
    Name: 'Monte Sereno',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MS_8277',
    Name: 'Houston',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OK_8278', Name: 'Hobart', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_PR_8279',
    Name: 'Ingenio',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_FL_8280', Name: 'Montura', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_PA_8281',
    Name: 'Freeland',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_8282',
    Name: 'Smithville-Sanders',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IL_8283',
    Name: 'Germantown Hills',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_8284', Name: 'Paulding', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NJ_8285',
    Name: 'Cape May',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_GA_8286', Name: 'Harlem', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_GA_8287', Name: 'Claxton', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_GA_8288', Name: 'Cuthbert', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MS_8289',
    Name: 'Tutwiler',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_NY_8290', Name: 'Minoa', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_TN_8291',
    Name: 'Oliver Springs',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_GA_8292', Name: 'Baldwin', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CA_8293',
    Name: 'Meadowbrook',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WA_8294',
    Name: 'Hansville',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NJ_8295',
    Name: 'Leisure Village West',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_8296',
    Name: 'High Bridge',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_AL_8297', Name: 'Heflin', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_CA_8298', Name: 'Campo', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_IA_8299', Name: 'Eagle Grove', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_8300', Name: 'New Hampton', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_NY_8301', Name: 'Calcium', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_OH_8302',
    Name: 'Lake Lakengren',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WA_8303',
    Name: 'La Center',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OH_8304', Name: 'Woodlawn', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IN_8305', Name: 'Dunkirk', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_AR_8306',
    Name: 'Marianna',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NJ_8307',
    Name: 'Concordia',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_LA_8308',
    Name: 'Richwood',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WY_8309',
    Name: 'Newcastle',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_PR_8310',
    Name: 'Toa Alta',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_NJ_8311',
    Name: 'Haworth',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_TX_8312', Name: 'Trinity', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MA_8313',
    Name: 'South Dennis',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_IL_8314', Name: 'Tolono', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_LA_8315',
    Name: 'South Vacherie',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_AL_8316', Name: 'Ladonia', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_MO_8317', Name: 'Tipton', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_WA_8318',
    Name: 'Leavenworth',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OK_8319', Name: 'Hominy', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NJ_8320',
    Name: 'Tuckerton',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_KS_8321', Name: 'Frontenac', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_VA_8322', Name: 'Pantops', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_OR_8323', Name: 'Nyssa', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_CA_8324',
    Name: 'Vandenberg AFB',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WA_8325',
    Name: 'Clyde Hill',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_MN_8326', Name: 'Crosby', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_MD_8327',
    Name: 'Crisfield',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OK_8328', Name: 'Dewey', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NC_8329',
    Name: 'Flat Rock',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CT_8330',
    Name: 'Old Mystic',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_TX_8331',
    Name: 'Karnes City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CA_8332',
    Name: 'Coronita',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_8333',
    Name: 'Sylvan Beach',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_HI_8334',
    Name: 'Mountain View',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_MI_8335',
    Name: 'Keego Harbor',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WA_8336',
    Name: 'McChord AFB',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_GA_8337', Name: 'Alma', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_OR_8338', Name: 'Oakridge', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NC_8339',
    Name: 'Elizabethtown',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OH_8340', Name: 'Cleves', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_8341',
    Name: 'Pine Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MT_8342', Name: 'Glasgow', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_AR_8343', Name: 'Waldron', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_NC_8344',
    Name: 'Windsor',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_8345',
    Name: 'Grand Mound',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NY_8346',
    Name: 'Churchville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_8347',
    Name: 'Casa Conejo',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IN_8348',
    Name: 'Georgetown',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_SC_8349',
    Name: 'Arcadia',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MI_8350',
    Name: 'Skidway Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NC_8351',
    Name: 'Mar-Mac',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_8352',
    Name: 'Loyola',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_8353', Name: 'Big Lake', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WV_8354',
    Name: 'Shady Spring',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_AR_8355', Name: 'Paris', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_WA_8356',
    Name: 'North Yelm',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OR_8357', Name: 'Jefferson', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_WV_8358',
    Name: 'Culloden',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_FL_8359',
    Name: 'Tiger Point',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NJ_8360',
    Name: 'Port Monmouth',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_8361',
    Name: 'Eastlawn Gardens',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_8362', Name: 'Cactus', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_8363', Name: 'Bethel', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_8364',
    Name: 'Burbank',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_AR_8365', Name: 'Manila', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_CA_8366',
    Name: 'Kennedy',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WA_8367',
    Name: 'Ahtanum',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MN_8368',
    Name: 'Sleepy Eye',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_MN_8369', Name: 'Ely', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_FL_8370', Name: 'Nokomis', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_MD_8371', Name: 'Delmar', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_MA_8372',
    Name: 'Mattapoisett Center',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_SD_8373',
    Name: 'Hartford',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_GA_8374',
    Name: 'Experiment',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_AZ_8375',
    Name: 'Cornville',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_WA_8376', Name: 'Elma', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_CA_8377',
    Name: 'North Lakeport',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MI_8378',
    Name: 'Houghton Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PR_8379',
    Name: 'Loíza',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_OH_8380',
    Name: 'Lincoln Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_TX_8381', Name: 'Northlake', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_8382',
    Name: 'Pixley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_8383',
    Name: 'Lake Placid',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_8384', Name: 'Howe', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_8385', Name: 'Reno', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TX_8386',
    Name: 'Shallowater',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WI_8387',
    Name: 'Peshtigo',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NJ_8388',
    Name: 'Oak Valley',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_8389',
    Name: 'Manor',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MI_8390', Name: 'Corunna', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_KY_8391',
    Name: 'Crestview Hills',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_TX_8392',
    Name: 'Hollywood Park',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_8393',
    Name: 'Mansfield',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AL_8394', Name: 'Kinsey', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_NE_8395', Name: 'Auburn', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_OH_8396', Name: 'Mayfield', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_8397',
    Name: 'Schnecksville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_8398',
    Name: 'Fairchild AFB',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_IL_8399',
    Name: 'Mackinaw',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_8400',
    Name: 'Dade City North',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_GA_8401', Name: 'Nicholls', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WI_8402',
    Name: 'Waterloo',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WA_8403',
    Name: 'Lake Holm',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WV_8404',
    Name: 'Blennerhassett',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WV_8405',
    Name: 'Kingwood',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WV_8406',
    Name: 'Inwood',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_VA_8407',
    Name: 'Blackstone',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_GA_8408', Name: 'Hardwick', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CA_8409',
    Name: 'Lake California',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_8410',
    Name: 'Pottsgrove',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_8411', Name: 'Sherwood', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CT_8412',
    Name: 'Collinsville',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_AR_8413',
    Name: 'Pottsville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_TN_8414',
    Name: 'Carthage',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_8415',
    Name: 'Hemlock Farms',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_8416',
    Name: 'Upland',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PR_8417',
    Name: 'Bajadero',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_OH_8418', Name: 'Bainbridge', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NJ_8419',
    Name: 'Laurel Lake',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_UT_8420', Name: 'Kamas', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_CA_8421',
    Name: 'Winchester',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_WI_8422', Name: 'Barron', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_IA_8423', Name: 'Story City', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SC_8424',
    Name: 'Graniteville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CA_8425',
    Name: 'Desert Edge',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_8426',
    Name: 'Sharpsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SC_8427',
    Name: 'Cherryvale',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_GA_8428',
    Name: 'Chattahoochee Hills',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_IN_8429', Name: 'Chandler', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_WI_8430',
    Name: 'Ellsworth',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_GA_8431', Name: 'Alamo', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NY_8432',
    Name: 'South Nyack',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IL_8433', Name: 'Fulton', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NY_8434',
    Name: 'Port Ewen',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OH_8435',
    Name: 'Salem Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IL_8436',
    Name: 'New Baden',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_8437', Name: 'Edcouch', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PR_8438',
    Name: 'Patillas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_SC_8439',
    Name: 'Chesnee',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_8440',
    Name: 'Pleasant Gap',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_8441',
    Name: 'Islandia',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_8442',
    Name: 'Etna',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_HI_8443', Name: 'Waialua', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_FL_8444',
    Name: 'Ponce Inlet',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NC_8445',
    Name: 'Lake Junaluska',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OH_8446',
    Name: 'Taylor Creek',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OH_8447', Name: 'Wellsville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NJ_8448',
    Name: 'Stanhope',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_GA_8449',
    Name: 'Greensboro',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CA_8450',
    Name: 'Goshen',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OH_8451',
    Name: 'Moreland Hills',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MI_8452',
    Name: 'Manistique',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_8453',
    Name: 'Spring City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PR_8454',
    Name: 'Santo Domingo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_NY_8455',
    Name: 'Elma Center',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MN_8456',
    Name: 'Long Prairie',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NY_8457',
    Name: 'Haviland',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_8458', Name: 'Winnsboro', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_8459',
    Name: 'Shark River Hills',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MN_8460',
    Name: 'Plainview',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_8461',
    Name: 'Paloma Creek',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_FL_8462',
    Name: 'Melbourne Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_TX_8463',
    Name: 'Balcones Heights',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_LA_8464',
    Name: 'Haughton',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_GA_8465',
    Name: 'Grantville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CA_8466',
    Name: 'El Verano',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VA_8467',
    Name: 'Cloverdale',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_FL_8468',
    Name: 'Pretty Bayou',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MA_8469',
    Name: 'Littleton Common',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_MO_8470',
    Name: 'Hillsboro',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TN_8471',
    Name: 'Newbern',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_MO_8472', Name: 'Cuba', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MO_8473',
    Name: 'Pagedale',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_KS_8474', Name: 'Lindsborg', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OH_8475', Name: 'Orange', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_8476',
    Name: 'Carthage',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_8477',
    Name: 'Citrus City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_8478',
    Name: 'Springdale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_8479',
    Name: 'Medina',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_VA_8480',
    Name: 'Blue Ridge',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_TX_8481',
    Name: 'West Lake Hills',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CA_8482',
    Name: 'Seacliff',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_8483',
    Name: 'Pendleton',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_8484',
    Name: 'Ohioville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NE_8485',
    Name: 'West Point',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_CO_8486',
    Name: 'Lincoln Park',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_GA_8487', Name: 'Lakeland', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_PA_8488',
    Name: 'Mount Oliver',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_HI_8489', Name: 'Ainaloa', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_GA_8490',
    Name: 'Tybee Island',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MI_8491',
    Name: 'Essexville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_8492',
    Name: 'Pine Grove',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IN_8493', Name: 'Lagrange', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_TX_8494', Name: 'Sonterra', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_LA_8495',
    Name: 'Grand Point',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WV_8496',
    Name: 'Summersville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_AL_8497',
    Name: 'West End-Cobb Town',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_OR_8498', Name: 'Dundee', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_TX_8499',
    Name: 'Grand Saline',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WI_8500',
    Name: 'Howards Grove',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_8501',
    Name: 'Esparto',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_8502',
    Name: 'Jones Creek',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NY_8503',
    Name: 'Montrose',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TN_8504',
    Name: 'White Pine',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_KS_8505',
    Name: 'Marysville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_LA_8506',
    Name: 'Hahnville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_8507', Name: 'Agua Dulce', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_8508',
    Name: 'Baldwyn',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_TX_8509',
    Name: 'Mauriceville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MI_8510',
    Name: 'Blissfield',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_8511',
    Name: 'Crockett',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_8512', Name: 'Carrollton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_8513',
    Name: 'North Wales',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TN_8514',
    Name: 'Somerville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NC_8515',
    Name: 'Rural Hall',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_FL_8516', Name: 'Hilliard', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NJ_8517',
    Name: 'Leisuretowne',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_NY_8518', Name: 'Pomona', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_NC_8519',
    Name: 'Denver',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_AL_8520',
    Name: 'Livingston',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MO_8521',
    Name: 'Sugar Creek',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_8522', Name: 'Lordstown', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_8523',
    Name: 'Cambrian Park',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WV_8524',
    Name: 'Philippi',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NC_8525',
    Name: 'Spencer',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_VA_8526',
    Name: 'West Point',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_GA_8527',
    Name: 'Chickamauga',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_TX_8528', Name: 'Spearman', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_8529',
    Name: 'West Wyomissing',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MD_8530',
    Name: 'Berwyn Heights',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MS_8531',
    Name: 'Water Valley',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IN_8532', Name: 'Newburgh', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PA_8533',
    Name: 'Myerstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_8534',
    Name: 'Nesquehoning',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_8535',
    Name: 'North Shore',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_MI_8536', Name: 'Bad Axe', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_AZ_8537', Name: 'Williams', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_TX_8538', Name: 'Annetta', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PR_8539',
    Name: 'Cabán',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MI_8540',
    Name: 'Franklin',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_GA_8541',
    Name: 'Dawsonville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_OH_8542',
    Name: 'Four Bridges',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_AK_8543', Name: 'Kotzebue', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_ID_8544',
    Name: 'Grangeville',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_IL_8545',
    Name: 'Sleepy Hollow',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_8546',
    Name: 'Cottonwood',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NV_8547', Name: 'Yerington', stateId: 'NV', stateName: 'Nevada'},
  {Id: 'CIT_US_OH_8548', Name: 'Uniontown', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AL_8549', Name: 'Moulton', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NJ_8550',
    Name: 'Monmouth Beach',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NC_8551',
    Name: 'Locust',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_8552',
    Name: 'Tipton',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_8553',
    Name: 'Bay Pines',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_KS_8554', Name: 'Garnett', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_WY_8555', Name: 'Kemmerer', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_LA_8556', Name: 'Vinton', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_FL_8557',
    Name: 'Greenbriar',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_TX_8558',
    Name: 'Iowa Colony',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_HI_8559', Name: 'Hauula', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_MS_8560',
    Name: 'Verona',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_TX_8561', Name: 'Wimberley', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NC_8562',
    Name: 'Enochville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_8563',
    Name: 'Port Angeles East',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_KY_8564', Name: 'Russell', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_TN_8565', Name: 'Norris', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_AK_8566',
    Name: 'Eielson AFB',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_CO_8567', Name: 'Silt', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_PA_8568',
    Name: 'Wellsboro',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_LA_8569', Name: 'Welsh', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_NY_8570',
    Name: 'Bedford Hills',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_8571',
    Name: 'Linwood',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OK_8572', Name: 'Wewoka', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_TX_8573',
    Name: 'West Orange',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IA_8574', Name: 'Tipton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_8575',
    Name: 'Mount Morris',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_FL_8576', Name: 'Sharpes', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_OK_8577', Name: 'Hinton', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_WA_8578',
    Name: 'Port Hadlock-Irondale',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MN_8579',
    Name: 'Rush City',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WA_8580',
    Name: 'Canterwood',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PR_8581',
    Name: 'Coquí',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_IL_8582',
    Name: 'Pistakee Highlands',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_8583',
    Name: 'Watergate',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_8584',
    Name: 'East Dundee',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_8585', Name: 'Fairfield', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_8586',
    Name: 'Coplay',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OR_8587',
    Name: 'Warm Springs',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_OH_8588',
    Name: 'West Portsmouth',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_OR_8589',
    Name: 'Three Rivers',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_CO_8590',
    Name: 'Orchard City',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NY_8591',
    Name: 'New York Mills',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AL_8592', Name: 'Warrior', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_NY_8593', Name: 'Wanakah', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_KS_8594', Name: 'Holton', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NC_8595',
    Name: 'Lowesville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_8596',
    Name: 'Algona',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MO_8597',
    Name: 'Louisiana',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_8598',
    Name: 'Steeleville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MA_8599',
    Name: 'Wareham Center',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_OH_8600',
    Name: 'Mingo Junction',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CA_8601',
    Name: 'Colfax',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_8602',
    Name: 'Williston',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WA_8603',
    Name: 'Central Park',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KY_8604',
    Name: 'Cumberland',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OR_8605', Name: 'Bandon', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NM_8606',
    Name: 'El Cerro',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_CA_8607', Name: 'Hoopa', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_OH_8608', Name: 'Millersburg', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_8609',
    Name: 'Cutchogue',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MD_8610',
    Name: 'Springdale',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NY_8611',
    Name: 'Port Washington North',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_8612',
    Name: 'Ingram',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_8613',
    Name: 'Lake Panasoffkee',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_OK_8614',
    Name: 'Mannford',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_OK_8615',
    Name: 'Bethel Acres',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_IN_8616',
    Name: 'Walkerton',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CA_8617',
    Name: 'San Antonio Heights',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_8618', Name: 'Springtown', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SC_8619',
    Name: 'Bamberg',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_8620',
    Name: 'Athens',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_8621',
    Name: 'Lake Orion',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_8622',
    Name: 'Burgettstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_GA_8623',
    Name: 'Chattanooga Valley',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_WV_8624',
    Name: 'Glenville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PR_8625',
    Name: 'Capitanejo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_GA_8626',
    Name: 'Tallapoosa',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_UT_8627', Name: 'Beaver', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_AZ_8628', Name: 'Superior', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_FL_8629', Name: 'Malabar', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_IL_8630',
    Name: 'Georgetown',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_8631',
    Name: 'South Hempstead',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_LA_8632',
    Name: 'Lutcher',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OK_8633', Name: 'Elgin', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CA_8634',
    Name: 'Terra Bella',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_8635', Name: 'Waynesville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_GA_8636', Name: 'Sparta', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_TX_8637', Name: 'Chandler', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TX_8638',
    Name: 'Laguna Vista',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NJ_8639',
    Name: 'North Middletown',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MN_8640',
    Name: 'Jackson',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_8641',
    Name: 'Angwin',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_8642',
    Name: 'Macungie',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_8643',
    Name: 'Loma Rica',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TN_8644',
    Name: 'Colonial Heights',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MA_8645',
    Name: 'Acushnet Center',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_CO_8646', Name: 'Frisco', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_TX_8647', Name: 'Hideaway', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_8648',
    Name: 'Red Oaks Mill',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_8649',
    Name: 'Union City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_8650',
    Name: 'Saddle River',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_TX_8651', Name: 'San Saba', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MN_8652',
    Name: 'Breckenridge',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_8653',
    Name: 'Ingalls Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SC_8654',
    Name: 'Pickens',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_AL_8655',
    Name: 'Underwood-Petersville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_ID_8656', Name: 'Salmon', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_OK_8657', Name: 'Jones', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NY_8658',
    Name: 'Cold Spring',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_8659',
    Name: 'Tunkhannock',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_8660',
    Name: 'Morada',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_8661', Name: 'Madison', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_FL_8662', Name: 'Zellwood', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_UT_8663', Name: 'Parowan', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_IA_8664',
    Name: 'Williamsburg',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_IN_8665',
    Name: 'Greentown',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_ID_8666',
    Name: 'Hidden Springs',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_CA_8667',
    Name: 'Amesti',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_8668',
    Name: 'East Richmond Heights',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_8669',
    Name: 'Oak Ridge North',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OH_8670', Name: 'Cadiz', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_8671', Name: 'West Union', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_FL_8672',
    Name: 'North Brooksville',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_8673', Name: 'El Cenizo', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_ME_8674',
    Name: 'South Eliot',
    stateId: 'ME',
    stateName: 'Maine',
  },
  {Id: 'CIT_US_TX_8675', Name: 'Early', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PR_8676',
    Name: 'Monte Grande',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_CA_8677',
    Name: 'Weaverville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_8678',
    Name: 'Pitcairn',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_GA_8679',
    Name: 'Kings Bay Base',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_IL_8680', Name: 'Galena', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MI_8681',
    Name: 'Rockwood',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WI_8682',
    Name: 'Ladysmith',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WI_8683',
    Name: 'Boscobel',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_SD_8684',
    Name: 'Dakota Dunes',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NY_8685',
    Name: 'South Blooming Grove',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MS_8686',
    Name: 'Newton',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OH_8687', Name: 'Delta', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_SC_8688',
    Name: 'Liberty',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NY_8689',
    Name: 'Wynantskill',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MS_8690',
    Name: 'Lucedale',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OH_8691', Name: 'Walbridge', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_8692', Name: 'Haskell', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MN_8693',
    Name: 'Moose Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_NV_8694', Name: 'Minden', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_WA_8695',
    Name: 'Meadowdale',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PR_8696',
    Name: 'Tierras Nuevas Poniente',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_PA_8697',
    Name: 'Homestead',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_8698', Name: 'Soperton', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WA_8699',
    Name: 'High Bridge',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_8700',
    Name: 'Nevada City',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_VA_8701', Name: 'Lebanon', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_MN_8702',
    Name: 'Pine City',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_HI_8703', Name: 'Eleele', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_NY_8704',
    Name: 'Lake Success',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_8705',
    Name: 'Reamstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_8706',
    Name: 'Pleasant Valley',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WA_8707',
    Name: 'Zillah',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_LA_8708',
    Name: 'Blanchard',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WV_8709',
    Name: 'Granville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WI_8710',
    Name: 'Tomahawk',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CO_8711',
    Name: 'Burlington',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PA_8712',
    Name: 'Salunga',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ND_8713',
    Name: 'Beulah',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_IL_8714', Name: 'Nokomis', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_HI_8715',
    Name: 'Orchidlands Estates',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {Id: 'CIT_US_OH_8716', Name: 'Versailles', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_8717',
    Name: 'Centerville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IN_8718', Name: 'Sheridan', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NC_8719',
    Name: 'Landis',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_AL_8720',
    Name: 'Cottondale',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_AK_8721',
    Name: 'Petersburg',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_PA_8722',
    Name: 'Brackenridge',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SC_8723',
    Name: 'Denmark',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_IN_8724', Name: 'Bicknell', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_OK_8725', Name: 'Okemah', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NJ_8726',
    Name: 'Hamburg',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_8727',
    Name: 'Netcong',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WI_8728',
    Name: 'Yorkville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_GA_8729',
    Name: 'Heron Bay',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_GA_8730',
    Name: 'Avondale Estates',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_GA_8731',
    Name: 'Hogansville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_LA_8732',
    Name: 'Greenwood',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_8733',
    Name: 'Marin City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WA_8734',
    Name: 'Rochester',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_OK_8735',
    Name: 'Cleveland',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WI_8736',
    Name: 'Thiensville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_8737', Name: 'Needville', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_FL_8738', Name: 'Oakland', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_AL_8739', Name: 'Marion', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_PA_8740',
    Name: 'Mount Penn',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_8741',
    Name: 'San Andreas',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_8742', Name: 'Henrietta', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_KS_8743', Name: 'Hiawatha', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_CT_8744',
    Name: 'Lake Pocotopaug',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_PA_8745',
    Name: 'Wesleyville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_8746',
    Name: 'Paddock Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_AR_8747', Name: 'Gosnell', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_IL_8748',
    Name: 'Abingdon',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MA_8749',
    Name: 'North Lakeville',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_ID_8750', Name: 'Kellogg', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_TN_8751',
    Name: 'Bean Station',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_TN_8752',
    Name: 'Shackle Island',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_FL_8753',
    Name: 'Island Walk',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NC_8754',
    Name: 'Mineral Springs',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WI_8755',
    Name: 'Arcadia',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MI_8756',
    Name: 'Beechwood',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MN_8757',
    Name: 'Blue Earth',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NC_8758',
    Name: 'Wallburg',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_8759',
    Name: 'Jamestown',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_8760',
    Name: 'North Cape May',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_OH_8761',
    Name: 'Beechwood Trails',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_SD_8762',
    Name: 'Milbank',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_IL_8763',
    Name: 'South Jacksonville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WI_8764',
    Name: 'New Holstein',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TN_8765',
    Name: 'Blountville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_TX_8766',
    Name: 'Oak Trail Shores',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CO_8767',
    Name: 'Acres Green',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_NM_8768', Name: 'Vado', stateId: 'NM', stateName: 'New Mexico'},
  {Id: 'CIT_US_TX_8769', Name: 'Olney', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MD_8770',
    Name: 'Federalsburg',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_8771',
    Name: 'Bonny Doon',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OK_8772',
    Name: 'Checotah',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NY_8773',
    Name: 'Sloatsburg',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_VA_8774',
    Name: 'Dahlgren',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_WI_8775', Name: 'Lodi', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_MA_8776',
    Name: 'Ocean Grove',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_MO_8777', Name: 'Buffalo', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_KY_8778', Name: 'Cadiz', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_PA_8779',
    Name: 'McSherrystown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_8780',
    Name: 'Morongo Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_8781',
    Name: 'Warsaw',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IN_8782',
    Name: 'Brownstown',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NY_8783',
    Name: 'Greenwood Lake',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MN_8784',
    Name: 'Eagle Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MA_8785',
    Name: 'Fiskdale',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_NC_8786',
    Name: 'East Rockingham',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OK_8787',
    Name: 'Chandler',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_AL_8788', Name: 'Red Bay', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_TX_8789', Name: 'Sonora', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_ID_8790', Name: 'Filer', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_FL_8791',
    Name: 'Watertown',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_OK_8792',
    Name: 'Tishomingo',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_OH_8793', Name: 'Hartville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MD_8794',
    Name: 'Andrews AFB',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_FL_8795',
    Name: 'Cleveland',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WI_8796',
    Name: 'Brillion',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_CA_8797', Name: 'Anza', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_MO_8798', Name: 'Licking', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_NY_8799',
    Name: 'Moriches',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WA_8800',
    Name: 'Eatonville',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_TX_8801',
    Name: 'Clarksville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OH_8802', Name: 'Lakemore', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_8803', Name: 'Jefferson', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_MT_8804', Name: 'Cut Bank', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_IL_8805', Name: 'Savanna', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_TX_8806', Name: 'Double Oak', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OH_8807',
    Name: 'Fairport Harbor',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OH_8808', Name: 'Doylestown', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_ME_8809',
    Name: 'Lake Arrowhead',
    stateId: 'ME',
    stateName: 'Maine',
  },
  {
    Id: 'CIT_US_FL_8810',
    Name: 'Biscayne Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WA_8811',
    Name: 'Navy Yard City',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_MO_8812', Name: 'Bethany', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MD_8813',
    Name: 'Paramount-Long Meadow',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TN_8814', Name: 'Dyer', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_TN_8815',
    Name: 'Monterey',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_AL_8816', Name: 'Weaver', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MD_8817',
    Name: 'Golden Beach',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NY_8818',
    Name: 'Brightwaters',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_MT_8819', Name: 'Big Sky', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_SD_8820',
    Name: 'Blackhawk',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_SC_8821',
    Name: 'Dalzell',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_TX_8822', Name: 'Bishop', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_KY_8823', Name: 'Wilder', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_AR_8824', Name: 'Corning', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_PA_8825',
    Name: 'Wormleysburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_8826', Name: 'Richville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AL_8827',
    Name: 'Dadeville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_KS_8828', Name: 'Columbus', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MN_8829',
    Name: 'Montgomery',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AL_8830', Name: 'Loxley', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NJ_8831',
    Name: 'Wildwood Crest',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_WI_8832', Name: 'Algoma', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_MS_8833',
    Name: 'Beechwood',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NJ_8834',
    Name: 'Ocean Grove',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_8835',
    Name: 'Fayetteville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_8836',
    Name: 'Sherwood',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WI_8837',
    Name: 'Johnson Creek',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_FL_8838',
    Name: "Hill 'n Dale",
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_AZ_8839', Name: 'Wellton', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_CA_8840',
    Name: 'Kings Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MN_8841', Name: 'Benson', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_IL_8842',
    Name: 'Heyworth',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_8843', Name: 'DeCordova', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_8844', Name: 'McDonald', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MO_8845',
    Name: 'Kimberling City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MA_8846',
    Name: 'Cordaville',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_PA_8847',
    Name: 'Trafford',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_8848', Name: 'Genoa', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NM_8849',
    Name: 'Eunice',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MN_8850',
    Name: 'Greenfield',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_HI_8851',
    Name: 'Kaunakakai',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {Id: 'CIT_US_AR_8852', Name: 'Atkins', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_WI_8853',
    Name: 'Mazomanie',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_FL_8854',
    Name: 'Roosevelt Gardens',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_CO_8855', Name: 'Penrose', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_IA_8856', Name: 'Garner', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_8857',
    Name: 'Day Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_8858',
    Name: 'Strathmore',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PR_8859',
    Name: 'Carrizales',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_PA_8860',
    Name: 'Emigsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_8861',
    Name: 'Vails Gate',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_LA_8862', Name: 'Boutte', stateId: 'LA', stateName: 'Louisiana'},
  {Id: 'CIT_US_GA_8863', Name: 'Hahira', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WY_8864',
    Name: 'Thermopolis',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_CO_8865',
    Name: 'Walsenburg',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MN_8866',
    Name: 'Proctor',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MI_8867',
    Name: 'Ferrysburg',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PR_8868',
    Name: 'Luyando',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_MN_8869', Name: 'Afton', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_WI_8870',
    Name: 'Oostburg',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NC_8871',
    Name: 'River Bend',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TX_8872',
    Name: 'Edgecliff Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MO_8873',
    Name: 'Pleasant Valley',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NY_8874',
    Name: 'Lido Beach',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_8875',
    Name: 'Petersburg',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IN_8876', Name: 'Delphi', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_MT_8877', Name: 'Shelby', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_ID_8878',
    Name: 'Soda Springs',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_MD_8879',
    Name: 'Jarrettsville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_MO_8880', Name: 'Buckner', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_AR_8881', Name: 'Dermott', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_PA_8882',
    Name: 'Everett',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_8883',
    Name: 'Mohnton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_8884',
    Name: 'Santa Rosa Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OK_8885', Name: 'Atoka', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_SC_8886',
    Name: 'Lesslie',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NJ_8887',
    Name: 'Kenvil',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CO_8888',
    Name: 'Strasburg',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NC_8889',
    Name: 'Granite Quarry',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_8890', Name: 'Hamilton', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_DE_8891', Name: 'Milton', stateId: 'DE', stateName: 'Delaware'},
  {
    Id: 'CIT_US_IL_8892',
    Name: 'Nashville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_8893',
    Name: 'Wyoming',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PR_8894',
    Name: 'Vieques',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_CT_8895',
    Name: 'Northwest Harwinton',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_AL_8896',
    Name: 'Choccolocco',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_ID_8897',
    Name: 'Mountain Home AFB',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_NM_8898',
    Name: 'Tularosa',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_CA_8899',
    Name: 'Sutter',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_ME_8900', Name: 'Calais', stateId: 'ME', stateName: 'Maine'},
  {Id: 'CIT_US_AR_8901', Name: 'DeWitt', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_MI_8902',
    Name: 'K. I. Sawyer',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_8903',
    Name: 'Edgewood',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_FL_8904', Name: 'Edgewood', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MO_8905',
    Name: 'Byrnes Mill',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_8906', Name: 'Nocona', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TX_8907',
    Name: 'Indian Hills',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MN_8908',
    Name: 'Staples',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_FL_8909', Name: 'Midway', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_TX_8910', Name: 'Stanton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KY_8911',
    Name: 'Providence',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IA_8912', Name: 'Milford', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MI_8913',
    Name: 'Iron River',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CO_8914',
    Name: 'Palmer Lake',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_WI_8915',
    Name: 'Wisconsin Dells',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OH_8916', Name: 'Dry Ridge', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_8917',
    Name: 'Winnebago',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AR_8918',
    Name: 'Prescott',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_GA_8919', Name: 'Royston', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_PR_8920',
    Name: 'Villalba',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_IL_8921', Name: 'Havana', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_TX_8922', Name: 'Shenandoah', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_8923', Name: 'West', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_8924',
    Name: 'Penbrook',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_8925',
    Name: 'Genoa City',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WA_8926',
    Name: 'Fobes Hill',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KY_8927',
    Name: 'Park Hills',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PR_8928',
    Name: 'Luis Lloréns Torres',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_TX_8929', Name: 'Combes', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_8930',
    Name: 'Lake Nacimiento',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OK_8931', Name: 'Tonkawa', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_IL_8932', Name: 'Sumner', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_8933', Name: 'Day Heights', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_FL_8934',
    Name: 'Woodlawn Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_HI_8935',
    Name: 'Hawaiian Acres',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_FL_8936',
    Name: 'Tangelo Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NC_8937',
    Name: 'Nags Head',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_8938',
    Name: 'Sherrill',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_8939', Name: 'Ferris', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MD_8940',
    Name: 'Smithsburg',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WA_8941',
    Name: 'Lochsloy',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_ID_8942', Name: 'Fort Hall', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_CO_8943',
    Name: 'Bayfield',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_OR_8944', Name: 'Redwood', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NY_8945',
    Name: 'Clarence Center',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_NY_8946', Name: 'Akron', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_MO_8947', Name: 'Hayti', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_KY_8948',
    Name: 'Indian Hills',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CA_8949',
    Name: 'Yountville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_8950',
    Name: 'Gloverville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_OH_8951',
    Name: 'New Richmond',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MI_8952',
    Name: 'Newberry',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_KY_8953', Name: 'Irvine', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_NJ_8954',
    Name: 'Woodbury Heights',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WY_8955',
    Name: 'Evansville',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {Id: 'CIT_US_KS_8956', Name: 'Ellsworth', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WV_8957',
    Name: 'Kenova',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NC_8958',
    Name: 'Murfreesboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_KY_8959',
    Name: 'Springfield',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_GA_8960', Name: 'Lula', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_FL_8961',
    Name: 'Bal Harbour',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_8962',
    Name: 'Hartley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_8963',
    Name: 'East Norwich',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NC_8964',
    Name: 'Southern Shores',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_8965', Name: 'Lopezville', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_8966',
    Name: 'Lykens',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_8967',
    Name: 'South Alamo',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_8968',
    Name: 'Summit Hill',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_FL_8969', Name: 'Alva', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_IL_8970',
    Name: 'North Barrington',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_NE_8971', Name: 'Minden', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_MT_8972',
    Name: 'Deer Lodge',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_KY_8973',
    Name: 'Flemingsburg',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NC_8974',
    Name: 'Ramseur',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_KY_8975',
    Name: 'Graymoor-Devondale',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OH_8976', Name: 'The Plains', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_8977',
    Name: 'Westville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NM_8978',
    Name: 'University Park',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_ND_8979',
    Name: 'Horace',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MO_8980',
    Name: 'Portageville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NJ_8981',
    Name: 'National Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_FL_8982', Name: 'Bunnell', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_TX_8983',
    Name: 'Lake Cherokee',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_FL_8984', Name: 'Trenton', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_TX_8985', Name: 'Stamford', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KY_8986',
    Name: 'Pioneer Village',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_8987',
    Name: 'East Greenville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PR_8988',
    Name: 'Galateo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_TX_8989', Name: 'Malakoff', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_FL_8990', Name: 'Dover', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NY_8991',
    Name: 'Munsons Corners',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TN_8992',
    Name: 'Dresden',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_MS_8993', Name: 'Iuka', stateId: 'MS', stateName: 'Mississippi'},
  {Id: 'CIT_US_IL_8994', Name: 'Newton', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_GA_8995',
    Name: 'Watkinsville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_TX_8996', Name: 'Seagraves', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CT_8997',
    Name: 'Niantic',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_IL_8998',
    Name: 'Venetian Village',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_8999',
    Name: 'Port Richey',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NY_9000',
    Name: 'Keeseville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AZ_9001',
    Name: 'Kachina Village',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_SC_9002',
    Name: 'Elgin',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_TX_9003',
    Name: 'Liberty Hill',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_LA_9004',
    Name: 'Sterlington',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_9005', Name: 'Ozona', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MN_9006', Name: 'Milaca', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_IL_9007',
    Name: 'Holiday Shores',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_9008',
    Name: 'Barton Creek',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MD_9009',
    Name: 'Prince Frederick',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_SD_9010',
    Name: 'North Sioux City',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_ME_9011',
    Name: 'Cumberland Center',
    stateId: 'ME',
    stateName: 'Maine',
  },
  {
    Id: 'CIT_US_SC_9012',
    Name: 'Williston',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NY_9013',
    Name: 'Weedsport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_SC_9014',
    Name: 'Allendale',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CA_9015',
    Name: 'Las Lomas',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_9016',
    Name: 'Simpsonville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_9017',
    Name: 'Churchill',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_9018',
    Name: 'Somerset',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_9019',
    Name: 'East Syracuse',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_9020',
    Name: 'Cutten',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NY_9021', Name: 'Florida', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_NY_9022',
    Name: 'Cornwall-on-Hudson',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IN_9023',
    Name: 'Loogootee',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_MO_9024', Name: 'Chaffee', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_TX_9025', Name: 'Hooks', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AL_9026', Name: 'New Hope', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NJ_9027',
    Name: 'Woodlynne',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_HI_9028', Name: 'Wailua', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_OR_9029', Name: 'Lakeview', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_AZ_9030',
    Name: 'Swift Trail Junction',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_FL_9031',
    Name: 'Cabana Colony',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WA_9032',
    Name: 'Castle Rock',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CT_9033',
    Name: 'Blue Hills',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_TX_9034',
    Name: 'Schulenburg',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_9035',
    Name: 'Girard',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_9036',
    Name: 'Hortonville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NJ_9037',
    Name: 'Seaside Heights',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_AL_9038',
    Name: 'La Fayette',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MD_9039',
    Name: 'Braddock Heights',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_TN_9040',
    Name: 'Brighton',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_FL_9041',
    Name: 'Indialantic',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_LA_9042',
    Name: 'Natalbany',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_GA_9043',
    Name: 'Louisville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PA_9044',
    Name: 'Womelsdorf',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_9045',
    Name: 'Monmouth Junction',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_9046',
    Name: 'South Oroville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_9047',
    Name: 'New Madrid',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_VA_9048', Name: 'Wise', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_NY_9049',
    Name: 'Sands Point',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NJ_9050',
    Name: 'Spring Lake',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_FL_9051',
    Name: 'Eagle Lake',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_9052',
    Name: 'Channel Islands Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NY_9053', Name: 'Roslyn', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_PA_9054',
    Name: 'Wyncote',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_9055',
    Name: 'Jacksonwald',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_9056', Name: 'Lodi', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OR_9057',
    Name: 'Jacksonville',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MS_9058',
    Name: 'Poplarville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NJ_9059',
    Name: 'Six Mile Run',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_TX_9060',
    Name: 'Grape Creek',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OK_9061', Name: 'Davis', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CA_9062',
    Name: 'Lucerne',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_9063', Name: 'Hildale', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_MI_9064', Name: 'Gladwin', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_IL_9065',
    Name: 'Scott AFB',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MA_9066',
    Name: 'Pocasset',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_MS_9067',
    Name: 'Marks',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NJ_9068',
    Name: 'Cliffwood Beach',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_NE_9069', Name: 'Valley', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_IA_9070',
    Name: 'West Burlington',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_WA_9071',
    Name: 'Alderton',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_TN_9072',
    Name: 'Woodbury',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_FL_9073',
    Name: 'Bowling Green',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NJ_9074',
    Name: 'Lawnside',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_9075',
    Name: 'Halfway House',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_9076',
    Name: 'Great Neck Estates',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NJ_9077',
    Name: 'Pemberton Heights',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_IL_9078',
    Name: 'Cherry Valley',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_KY_9079',
    Name: 'Brandenburg',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_VA_9080',
    Name: 'Chincoteague',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NE_9081',
    Name: 'Central City',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_TX_9082', Name: 'Bartlett', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_9083',
    Name: 'Colfax',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WV_9084',
    Name: 'Williamson',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_CA_9085',
    Name: 'Ridgemark',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_9086',
    Name: 'Aberdeen Proving Ground',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MI_9087',
    Name: 'Pearl Beach',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MT_9088',
    Name: 'Montana City',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_OH_9089',
    Name: 'Buckeye Lake',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_VA_9090',
    Name: 'Lake Wilderness',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_9091', Name: 'La Villa', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WV_9092',
    Name: 'Williamstown',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_AR_9093',
    Name: 'Bald Knob',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NC_9094',
    Name: 'Fearrington Village',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_9095',
    Name: 'Bushnell',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_9096',
    Name: 'Harbor Bluffs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_TX_9097',
    Name: 'Shady Shores',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CA_9098',
    Name: 'Homestead Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MO_9099', Name: 'Ava', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_AR_9100',
    Name: 'Mountain View',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_IN_9101',
    Name: 'Knightstown',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MT_9102',
    Name: 'North Browning',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_NY_9103',
    Name: 'Shrub Oak',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_9104',
    Name: 'Brackettville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_VA_9105',
    Name: 'Lawrenceville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WA_9106',
    Name: 'Meadow Glade',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_FL_9107',
    Name: 'Zephyrhills North',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_KS_9108', Name: 'Galena', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_TN_9109',
    Name: 'Belle Meade',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_CA_9110', Name: 'Oasis', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_OH_9111', Name: 'Covington', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_9112',
    Name: 'Youngwood',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_9113', Name: 'Fairfax', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NY_9114',
    Name: 'Chenango Bridge',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_9115',
    Name: 'Lake City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_9116',
    Name: 'Montalvin Manor',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NE_9117',
    Name: 'David City',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IL_9118',
    Name: 'Rushville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SC_9119',
    Name: 'Roebuck',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_OR_9120',
    Name: 'Gold Beach',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_CA_9121',
    Name: 'Kelly Ridge',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_9122',
    Name: 'Swartzville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_LA_9123', Name: 'Norco', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_NY_9124',
    Name: 'Crown Heights',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NC_9125',
    Name: 'Biscoe',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WI_9126',
    Name: 'Kewaunee',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_AL_9127', Name: 'Clayton', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_PA_9128',
    Name: 'Mountville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_9129',
    Name: 'Meadowood',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OK_9130', Name: 'Eufaula', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_WA_9131', Name: 'Chico', stateId: 'WA', stateName: 'Washington'},
  {Id: 'CIT_US_MT_9132', Name: 'Poplar', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_WI_9133',
    Name: 'Lake Wissota',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MI_9134', Name: 'Almont', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_KS_9135', Name: 'Kingman', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MI_9136',
    Name: 'Mount Morris',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SC_9137',
    Name: 'Shell Point',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_AZ_9138', Name: 'Ajo', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_OK_9139', Name: 'Watonga', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_KY_9140', Name: 'Marion', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_FL_9141',
    Name: 'De Leon Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_FL_9142', Name: 'Hypoluxo', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_GA_9143', Name: 'Statham', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CA_9144',
    Name: 'East Oakdale',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_9145',
    Name: 'West Grove',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OR_9146',
    Name: 'Eagle Crest',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_LA_9147',
    Name: 'Jean Lafitte',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MD_9148',
    Name: 'Gambrills',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NJ_9149',
    Name: 'Whitesboro',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_MO_9150', Name: 'Thayer', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_NE_9151', Name: 'Ashland', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_OK_9152', Name: 'Stigler', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CO_9153',
    Name: 'Holly Hills',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_OK_9154',
    Name: 'Drumright',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MN_9155',
    Name: 'Mountain Iron',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NJ_9156',
    Name: 'Rio Grande',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_HI_9157', Name: 'Hanapepe', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_LA_9158', Name: 'Homer', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_PR_9159',
    Name: 'Hacienda San José',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_SD_9160',
    Name: 'Winner',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_TX_9161', Name: 'Canadian', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_9162',
    Name: 'Riverview',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IL_9163', Name: 'Arcola', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_PA_9164',
    Name: 'North Catasauqua',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_9165',
    Name: 'St. Clair',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_9166',
    Name: 'Carson',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_GA_9167',
    Name: 'Lake City',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MN_9168',
    Name: 'Chatfield',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WA_9169',
    Name: 'Port Ludlow',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NC_9170',
    Name: 'Holly Ridge',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CT_9171',
    Name: 'Oxoboxo River',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_FL_9172',
    Name: 'Big Coppitt Key',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AZ_9173',
    Name: 'Black Canyon City',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_IN_9174',
    Name: 'Shorewood Forest',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IA_9175', Name: 'Wilton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_9176',
    Name: 'Moscow',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VT_9177',
    Name: 'Lyndonville',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {Id: 'CIT_US_ID_9178', Name: 'Homedale', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_MO_9179',
    Name: 'Castle Point',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_FL_9180',
    Name: 'Carrabelle',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PR_9181',
    Name: 'Potala Pastillo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_IA_9182', Name: 'Park View', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OK_9183', Name: 'Perkins', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_OH_9184', Name: 'Minster', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MO_9185',
    Name: 'Owensville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_KS_9186', Name: 'Hillsboro', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IN_9187',
    Name: 'Country Squire Lakes',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NJ_9188',
    Name: 'Clearbrook Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_WI_9189', Name: 'Amery', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_NH_9190',
    Name: 'Tilton Northfield',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_WA_9191',
    Name: 'Warden',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_WY_9192', Name: 'Bar Nunn', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_PR_9193',
    Name: 'Aguada',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_OK_9194', Name: 'Cache', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CA_9195',
    Name: 'Bayview',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_9196',
    Name: 'Northeast Ithaca',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_KS_9197',
    Name: 'Osage City',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_PA_9198',
    Name: 'Pleasant Hill',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_WA_9199', Name: 'Gleed', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_CA_9200',
    Name: 'Penngrove',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MA_9201',
    Name: 'Monument Beach',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_MI_9202', Name: 'Douglas', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_VA_9203',
    Name: 'Daleville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_9204',
    Name: 'Kenhorst',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MN_9205', Name: 'Roseau', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_UT_9206', Name: 'Richmond', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_AR_9207',
    Name: 'North Crossett',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_FL_9208',
    Name: 'Lake Helen',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_9209',
    Name: 'Brockway',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_9210',
    Name: 'Oak Grove',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_9211',
    Name: 'Wernersville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_CO_9212', Name: 'Fraser', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_CO_9213', Name: 'Bennett', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_AZ_9214', Name: 'Linden', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_NY_9215',
    Name: 'Lakewood',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AK_9216', Name: 'Seward', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_WI_9217',
    Name: 'Oconto Falls',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_LA_9218',
    Name: 'Lake Arthur',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_LA_9219', Name: 'Delhi', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_MI_9220',
    Name: 'Village of Grosse Pointe Shores',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_TX_9221', Name: 'Rosita', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OH_9222',
    Name: 'North Kingsville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MS_9223',
    Name: 'Guntown',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_TX_9224', Name: 'Beach City', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TX_9225',
    Name: 'Circle D-KC Estates',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_TN_9226',
    Name: 'Park City',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_9227',
    Name: 'McGovern',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_9228',
    Name: 'Poydras',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_9229', Name: 'Winnie', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NC_9230',
    Name: 'Marshville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_AR_9231',
    Name: 'Little Flock',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NC_9232',
    Name: 'Sneads Ferry',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MO_9233',
    Name: 'Knob Noster',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MO_9234', Name: 'Windsor', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_IN_9235',
    Name: 'Heritage Lake',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MD_9236',
    Name: 'Rising Sun',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_TN_9237',
    Name: 'Sale Creek',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_OR_9238', Name: 'Sisters', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MI_9239',
    Name: 'Munising',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_9240',
    Name: 'Greenwood',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_9241',
    Name: 'Tyndall AFB',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_9242',
    Name: 'Garden View',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_9243',
    Name: 'South Padre Island',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NM_9244',
    Name: 'West Hammond',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_OK_9245', Name: 'Lindsay', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_AL_9246', Name: 'Brighton', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MA_9247',
    Name: 'Norton Center',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_PA_9248',
    Name: 'Harveys Lake',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AZ_9249',
    Name: 'Central Heights-Midland City',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_CA_9250',
    Name: 'Pine Mountain Lake',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OR_9251', Name: 'Sublimity', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_AR_9252',
    Name: 'Bethel Heights',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NY_9253',
    Name: 'Voorheesville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_9254',
    Name: 'Rose Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_9255',
    Name: 'Plainsboro Center',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_MO_9256', Name: 'Clever', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_NC_9257',
    Name: 'Fairfield Harbour',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_MN_9258', Name: 'Albany', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_NC_9259',
    Name: 'Troutman',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MN_9260',
    Name: 'Dodge Center',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IN_9261',
    Name: 'Fairmount',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_OK_9262',
    Name: 'Marietta',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_CO_9263', Name: 'Hudson', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_MI_9264', Name: 'Ithaca', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_GA_9265', Name: 'Millen', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MI_9266',
    Name: 'Kalkaska',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_CA_9267', Name: 'Crest', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_PA_9268',
    Name: 'Ford City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_9269', Name: 'Onawa', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CT_9270',
    Name: 'West Simsbury',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_IL_9271', Name: 'Pawnee', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_KY_9272',
    Name: 'Lakeside Park',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OR_9273', Name: 'Gervais', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_SC_9274',
    Name: 'Loris',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_LA_9275',
    Name: 'Westminster',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_9276', Name: 'Talty', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CO_9277',
    Name: 'Platteville',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_VA_9278', Name: 'Windsor', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_TX_9279',
    Name: 'Westworth Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NC_9280',
    Name: "Wilson's Mills",
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_9281',
    Name: 'North Shore',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_9282',
    Name: 'East Gaffney',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_9283',
    Name: 'Kulpmont',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_9284',
    Name: 'Sisco Heights',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_FL_9285',
    Name: 'Woodville',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MN_9286',
    Name: 'Caledonia',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MI_9287',
    Name: 'Potterville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_NM_9288', Name: 'Dulce', stateId: 'NM', stateName: 'New Mexico'},
  {
    Id: 'CIT_US_AR_9289',
    Name: 'Green Forest',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_SC_9290',
    Name: 'Lakewood',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_MI_9291', Name: 'Norway', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MD_9292',
    Name: 'Herald Harbor',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_SC_9293',
    Name: 'Burnettown',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NC_9294',
    Name: 'South Rosemary',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OH_9295',
    Name: 'Sixteen Mile Stand',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IL_9296',
    Name: 'McLeansboro',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WV_9297',
    Name: 'Pinch',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_OK_9298',
    Name: 'Longtown',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_TX_9299', Name: 'Refugio', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_9300', Name: 'Ballville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AL_9301', Name: 'Luverne', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_OR_9302', Name: 'Dayton', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_PA_9303',
    Name: 'Chester Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_9304', Name: 'Van', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_9305', Name: 'Odem', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_VA_9306', Name: 'Coeburn', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_PA_9307',
    Name: 'Manchester',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IL_9308', Name: 'Gilman', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_9309', Name: 'Oak Harbor', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_9310',
    Name: 'Vista Santa Rosa',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_HI_9311', Name: 'Koloa', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_WV_9312',
    Name: 'Petersburg',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MI_9313',
    Name: 'West Ishpeming',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_TX_9314',
    Name: 'Mount Vernon',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_MN_9315', Name: 'Osseo', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_PA_9316',
    Name: 'Wind Gap',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_9317',
    Name: 'Sylva',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CT_9318',
    Name: 'Weatogue',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_FL_9319',
    Name: 'Crescent City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NC_9320',
    Name: "Cajah's Mountain",
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CO_9321',
    Name: 'Palisade',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PA_9322',
    Name: 'Seven Fields',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_9323',
    Name: 'Agua Fria',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_CA_9324',
    Name: 'Val Verde',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OK_9325',
    Name: 'New Cordell',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_CA_9326',
    Name: 'West Bishop',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_9327',
    Name: 'Springdale',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_SC_9328',
    Name: 'North Hartsville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_TX_9329', Name: 'Palmhurst', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CO_9330',
    Name: 'Snowmass Village',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_TX_9331',
    Name: 'Val Verde Park',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IL_9332', Name: 'El Paso', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_TX_9333', Name: 'Electra', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_9334',
    Name: 'Pearisburg',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_TN_9335',
    Name: 'Kingston Springs',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_OH_9336', Name: 'Evendale', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_9337',
    Name: 'Knoxville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_KY_9338',
    Name: 'Hartford',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_CA_9339', Name: 'Weed', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_MN_9340',
    Name: 'Cohasset',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_9341',
    Name: 'Reynoldsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OH_9342',
    Name: 'Mount Healthy Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_FL_9343',
    Name: 'Polk City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TN_9344', Name: 'Apison', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_PA_9345',
    Name: 'Ashley',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_9346', Name: 'Santa Rosa', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_9347',
    Name: 'Tangerine',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_KS_9348', Name: 'Norton', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_9349',
    Name: 'Youngsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SC_9350',
    Name: 'Ravenel',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NC_9351',
    Name: 'Wentworth',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WV_9352',
    Name: 'Fayetteville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_SC_9353',
    Name: 'Hopkins',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_IL_9354', Name: 'Lena', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_GA_9355',
    Name: 'Monticello',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_SC_9356',
    Name: 'Wellford',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PR_9357',
    Name: 'Piedra Aguza',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_IN_9358', Name: 'Butler', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NC_9359',
    Name: 'Tobaccoville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_9360',
    Name: 'Skippers Corner',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WI_9361',
    Name: 'Cambridge',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_9362',
    Name: 'Hamilton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_ID_9363', Name: 'Wendell', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_WA_9364',
    Name: 'Desert Aire',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_9365',
    Name: 'Hayfork',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_9366',
    Name: 'Munsey Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_9367',
    Name: 'Halesite',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_ID_9368',
    Name: 'Bonners Ferry',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_WV_9369',
    Name: 'Follansbee',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_IA_9370', Name: 'Clarion', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NC_9371',
    Name: 'Bermuda Run',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_9372',
    Name: 'Newport',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NJ_9373',
    Name: 'Lakehurst',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NE_9374',
    Name: 'Valentine',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_TX_9375', Name: 'Abernathy', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_HI_9376',
    Name: 'Lanai City',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_PA_9377',
    Name: 'Pine Ridge',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_9378',
    Name: 'Brooklyn',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IA_9379', Name: 'Jesup', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NJ_9380',
    Name: 'Moonachie',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OH_9381', Name: 'Devola', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_9382', Name: 'Inez', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AK_9383', Name: 'Ridgeway', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_LA_9384',
    Name: 'Arcadia',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OK_9385', Name: 'Stroud', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_AZ_9386',
    Name: 'White Mountain Lake',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_TX_9387', Name: 'Glen Rose', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_KS_9388', Name: 'St. Marys', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IL_9389',
    Name: 'Fairmont',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_9390',
    Name: 'Gray Summit',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OK_9391', Name: 'Mangum', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_MN_9392',
    Name: 'Granite Falls',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_FL_9393', Name: 'Havana', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_9394',
    Name: 'Collierville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_9395',
    Name: 'West Sand Lake',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_LA_9396',
    Name: 'Bawcomville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_LA_9397', Name: 'Many', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_AZ_9398',
    Name: 'Heber-Overgaard',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NY_9399',
    Name: 'North Boston',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_9400',
    Name: 'Aspinwall',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PR_9401',
    Name: 'La Luisa',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_PA_9402',
    Name: 'Hyde Park',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_9403',
    Name: 'White Salmon',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_VA_9404',
    Name: 'Timberville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WI_9405',
    Name: 'Bohners Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IA_9406', Name: 'Solon', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CT_9407',
    Name: 'Guilford Center',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_MO_9408', Name: 'Fayette', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_AR_9409', Name: 'England', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_TN_9410',
    Name: 'Tusculum',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_OH_9411', Name: 'Strasburg', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_FL_9412',
    Name: 'Seminole Manor',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_NV_9413', Name: 'Hawthorne', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_GA_9414',
    Name: 'Bloomingdale',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_IL_9415', Name: 'Tilton', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_HI_9416', Name: 'Kilauea', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_CA_9417',
    Name: 'Lexington Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_9418',
    Name: 'Riverton',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NH_9419',
    Name: 'Hampton Beach',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_GA_9420',
    Name: 'Abbeville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_OK_9421',
    Name: 'Healdton',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_PA_9422',
    Name: 'Dupont',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ND_9423',
    Name: 'Grand Forks AFB',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_IL_9424',
    Name: 'Ford Heights',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NM_9425',
    Name: 'Clayton',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_IL_9426', Name: 'Casey', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_WI_9427', Name: 'Como', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_CA_9428',
    Name: 'La Selva Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_9429', Name: 'Bloomfield', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_9430',
    Name: 'Montgomery City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_AK_9431', Name: 'Big Lake', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_PA_9432',
    Name: 'Muse',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VT_9433',
    Name: 'West Brattleboro',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_ND_9434',
    Name: 'Stanley',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_OH_9435', Name: 'Dalton', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_9436', Name: 'Floydada', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_9437',
    Name: 'West Wyoming',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_9438',
    Name: 'Chewelah',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_FL_9439',
    Name: 'Hernando Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MO_9440',
    Name: 'Bel-Ridge',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NC_9441',
    Name: 'Avery Creek',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_AZ_9442',
    Name: 'Mohave Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_PA_9443',
    Name: 'Cambridge Springs',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MN_9444', Name: 'Foley', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_AL_9445', Name: 'Jemison', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_PA_9446',
    Name: 'East Lansdowne',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KS_9447', Name: 'Girard', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MI_9448',
    Name: 'St. Helen',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NV_9449',
    Name: 'Washoe Valley',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_FL_9450',
    Name: 'Manatee Road',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_9451',
    Name: 'Morton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IA_9452',
    Name: 'Grundy Center',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_OH_9453', Name: 'Newtown', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_WI_9454', Name: 'Chetek', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_PA_9455',
    Name: 'Bath',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_9456',
    Name: 'Walton Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_9457',
    Name: 'Desert View Highlands',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_WI_9458', Name: 'Juneau', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_PA_9459',
    Name: 'Hokendauqua',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MO_9460', Name: 'Oronogo', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_TX_9461', Name: 'Las Lomas', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_9462', Name: 'Freer', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_GA_9463', Name: 'Trenton', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CA_9464',
    Name: 'Dixon Lane-Meadow Creek',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OK_9465',
    Name: 'Wilburton',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_TX_9466', Name: 'Arcola', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_9467', Name: 'El Lago', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_9468',
    Name: 'Blasdell',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_9469',
    Name: 'Terre du Lac',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WA_9470',
    Name: 'Fords Prairie',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OH_9471', Name: 'Malvern', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_VA_9472',
    Name: 'Hillsville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_SD_9473',
    Name: 'Summerset',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_SC_9474',
    Name: 'Folly Beach',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NJ_9475',
    Name: 'Milford',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_DE_9476',
    Name: 'Townsend',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_NC_9477',
    Name: 'Liberty',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_ID_9478', Name: 'St. Maries', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_MO_9479', Name: 'Warsaw', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_AR_9480',
    Name: 'West Fork',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_TN_9481',
    Name: 'Clifton',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MI_9482',
    Name: 'Rogers City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_AL_9483',
    Name: 'Point Clear',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MN_9484',
    Name: 'Lexington',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_VA_9485',
    Name: 'Merrimac',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IL_9486',
    Name: 'Preston Heights',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_UT_9487', Name: 'Fillmore', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_AR_9488',
    Name: 'Lake City',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_TX_9489', Name: 'Tahoka', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_9490',
    Name: 'Mountain View',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_9491',
    Name: 'Cetronia',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_9492',
    Name: 'Middlebush',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_UT_9493', Name: 'Castle Dale', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_TX_9494', Name: 'Crosby', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_9495',
    Name: 'Hallam',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_9496',
    Name: 'Round Lake Heights',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_9497',
    Name: 'Marquette Heights',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_GA_9498',
    Name: 'Reidsville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NJ_9499',
    Name: 'Rossmoor',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_9500',
    Name: 'Dogtown',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NM_9501',
    Name: 'Chimayo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_LA_9502',
    Name: 'Port Barre',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WI_9503',
    Name: 'Williams Bay',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MN_9504',
    Name: 'Sandstone',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_9505',
    Name: 'Lakeview',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_9506',
    Name: 'Russell Springs',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CA_9507',
    Name: 'Alturas',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MD_9508', Name: 'Dunkirk', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_TX_9509',
    Name: 'Hallettsville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WA_9510',
    Name: 'Brush Prairie',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WA_9511',
    Name: 'South Creek',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NM_9512',
    Name: 'Santa Rosa',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_9513',
    Name: 'Alburtis',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_UT_9514', Name: 'Erda', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_TX_9515',
    Name: 'Hedwig Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PR_9516',
    Name: 'La Dolores',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_WA_9517',
    Name: 'Stansberry Lake',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MA_9518',
    Name: 'White Island Shores',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_NJ_9519',
    Name: 'Whittingham',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_KY_9520',
    Name: 'Dawson Springs',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MD_9521',
    Name: 'University Park',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_9522',
    Name: 'Cayucos',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_9523',
    Name: 'Avoca',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_9524',
    Name: 'Freemansburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_FL_9525', Name: 'Chuluota', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NJ_9526',
    Name: 'Sewaren',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_9527',
    Name: 'Sand Hill',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_9528', Name: 'Glouster', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PR_9529',
    Name: 'Mariano Colón',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_OH_9530', Name: 'New Concord', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_GA_9531', Name: 'Pembroke', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_PA_9532',
    Name: 'Wolfdale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_9533',
    Name: 'Westhampton',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_GA_9534',
    Name: 'Thunderbolt',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MS_9535',
    Name: 'Macon',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CA_9536',
    Name: 'Sutter Creek',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_9537',
    Name: 'Green Island',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WV_9538',
    Name: 'Spencer',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_9539',
    Name: 'Riverview Park',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_9540',
    Name: 'La Grange',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MO_9541',
    Name: 'Green Park',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_9542', Name: 'Loudonville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_9543', Name: 'Merkel', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_LA_9544', Name: 'Brusly', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_PA_9545',
    Name: 'Wayne Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AZ_9546',
    Name: 'Desert Hills',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_IA_9547',
    Name: 'Missouri Valley',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_LA_9548',
    Name: 'Abita Springs',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WA_9549',
    Name: 'Bridgeport',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NY_9550',
    Name: 'Thomaston',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_9551',
    Name: 'Horseheads North',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_HI_9552', Name: 'Keaau', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_FL_9553',
    Name: 'Verona Walk',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IA_9554', Name: 'Eldora', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_UT_9555', Name: 'Salina', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_AL_9556',
    Name: 'Moundville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MN_9557',
    Name: 'Glenwood',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_LA_9558',
    Name: 'Pearl River',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_AL_9559',
    Name: 'Centreville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_AZ_9560', Name: 'Sells', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_MI_9561',
    Name: 'Sandusky',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IL_9562', Name: 'Trenton', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OR_9563', Name: 'Irrigon', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_WI_9564',
    Name: 'Hayward',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MO_9565',
    Name: 'Clarkson Valley',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_9566',
    Name: 'Marietta',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_9567',
    Name: 'Meadow Oaks',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WA_9568',
    Name: 'Warm Beach',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_AL_9569', Name: 'Eutaw', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MO_9570',
    Name: 'Villa Ridge',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_AR_9571', Name: 'Hamburg', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_VA_9572',
    Name: 'Massanutten',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_9573',
    Name: 'Valley View',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_9574',
    Name: 'Barnesville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AL_9575',
    Name: 'Whitesboro',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_AR_9576', Name: 'Hoxie', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_NJ_9577',
    Name: 'East Newark',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_OH_9578',
    Name: 'McConnelsville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IL_9579',
    Name: 'Davis Junction',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NJ_9580',
    Name: 'Peapack and Gladstone',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NC_9581',
    Name: 'Fairmont',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TN_9582',
    Name: 'Mountain City',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WV_9583',
    Name: 'Moorefield',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_SC_9584',
    Name: 'Edisto',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OH_9585', Name: 'Dennison', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OK_9586',
    Name: 'Fairview',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WV_9587',
    Name: 'Bluewell',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_ND_9588',
    Name: 'New Town',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MI_9589',
    Name: 'Hartford',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WA_9590',
    Name: 'Dayton',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MA_9591',
    Name: 'East Douglas',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_SC_9592',
    Name: 'Westminster',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MS_9593',
    Name: 'Okolona',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_AR_9594',
    Name: 'Brinkley',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_ND_9595',
    Name: 'Rugby',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_UT_9596', Name: 'Garland', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_PA_9597',
    Name: 'East Prospect',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_9598',
    Name: 'Okanogan',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_9599',
    Name: 'Cabazon',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_9600',
    Name: 'Inverness Highlands North',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_9601',
    Name: 'Frazier Park',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VT_9602',
    Name: 'Vergennes',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_PA_9603',
    Name: 'Rothsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AZ_9604',
    Name: 'Cordes Lakes',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_TX_9605', Name: 'Lake Kiowa', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MN_9606',
    Name: 'Rockville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_WI_9607', Name: 'Wales', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_NC_9608',
    Name: 'Buies Creek',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_VA_9609', Name: 'Dublin', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_KS_9610', Name: 'Sabetha', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_AL_9611', Name: 'Huguley', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_PA_9612',
    Name: 'Dillsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_9613',
    Name: 'Belmont',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_9614',
    Name: 'Eminence',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WV_9615',
    Name: 'Hooverson Heights',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_9616',
    Name: 'Curwensville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_PA_9617', Name: 'Gap', stateId: 'PA', stateName: 'Pennsylvania'},
  {
    Id: 'CIT_US_PA_9618',
    Name: 'Plumsteadville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TN_9619',
    Name: 'Coalfield',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_GA_9620', Name: 'Deenwood', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_OH_9621', Name: 'Sabina', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IN_9622',
    Name: 'Centerville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_WV_9623',
    Name: 'Crab Orchard',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_AL_9624', Name: 'Hartford', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_AL_9625',
    Name: 'Carlisle-Rockledge',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_CA_9626',
    Name: 'Georgetown',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_9627',
    Name: 'Pennington',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_9628',
    Name: 'Brownstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_WY_9629', Name: 'Glenrock', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_TX_9630', Name: 'Escobares', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MA_9631',
    Name: 'East Dennis',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_PA_9632',
    Name: 'Effort',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_9633',
    Name: 'Ruidoso Downs',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MO_9634',
    Name: 'St. Paul',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_WI_9635', Name: 'Maine', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_IA_9636', Name: 'Sibley', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MI_9637',
    Name: 'Canadian Lakes',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_GA_9638',
    Name: 'Homerville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_WV_9639',
    Name: 'White Sulphur Springs',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_FL_9640',
    Name: 'Lake Mary Jane',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_9641',
    Name: 'Feasterville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_9642',
    Name: 'Lakeland',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WI_9643',
    Name: 'Spooner',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WI_9644',
    Name: 'Luxemburg',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_FL_9645',
    Name: 'Winter Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_9646',
    Name: 'Lake Meade',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OH_9647',
    Name: 'Williamsburg',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_AR_9648',
    Name: 'Huntsville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NY_9649',
    Name: 'Yorkville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NJ_9650',
    Name: 'Swedesboro',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_9651',
    Name: 'Leonardo',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WY_9652',
    Name: 'North Rock Springs',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_PA_9653',
    Name: 'Coopersburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_FL_9654', Name: 'Freeport', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MD_9655',
    Name: 'Forest Heights',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WI_9656',
    Name: 'Mondovi',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IA_9657', Name: 'Bellevue', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_FL_9658',
    Name: 'Villano Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_9659',
    Name: 'Lawson Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_9660',
    Name: 'Martinsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_9661',
    Name: 'George West',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WA_9662',
    Name: 'Riverbend',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WA_9663',
    Name: 'Friday Harbor',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_VA_9664',
    Name: 'Tappahannock',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_GA_9665', Name: 'Sylvania', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NC_9666',
    Name: 'Polkton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_KY_9667',
    Name: 'Mount Vernon',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_AL_9668',
    Name: 'Abbeville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_TX_9669', Name: 'Quitman', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_9670',
    Name: 'Marysville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_9671',
    Name: 'New Palestine',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_OR_9672',
    Name: 'Myrtle Point',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_AL_9673',
    Name: 'Indian Springs Village',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_OK_9674', Name: 'Turley', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CA_9675',
    Name: 'Caruthers',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AZ_9676', Name: 'Pima', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_MI_9677',
    Name: 'Argentine',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WI_9678',
    Name: 'Delavan Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_OR_9679',
    Name: 'Millersburg',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MS_9680',
    Name: 'Ruleville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NC_9681',
    Name: 'Wrightsville Beach',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IA_9682',
    Name: 'Center Point',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_OH_9683', Name: 'St. Henry', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NJ_9684',
    Name: 'Brownville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_GA_9685',
    Name: 'Donalsonville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NC_9686',
    Name: 'Carthage',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_KS_9687',
    Name: 'Clearwater',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_MN_9688',
    Name: 'Pelican Rapids',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_9689',
    Name: 'Colwyn',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_9690',
    Name: 'Mathews',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_SC_9691',
    Name: 'Pacolet',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NY_9692',
    Name: 'East Williston',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_9693', Name: 'Seymour', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_9694', Name: 'Elmwood', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_IA_9695', Name: 'Madrid', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_TX_9696',
    Name: 'Bolivar Peninsula',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_9697',
    Name: 'Bakerstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_9698', Name: 'Putney', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NC_9699',
    Name: 'Edneyville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_9700',
    Name: 'Broad Creek',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WI_9701',
    Name: 'New Lisbon',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_9702',
    Name: 'Edwards AFB',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_9703',
    Name: 'Eddyville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_KS_9704',
    Name: 'Burlington',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_TX_9705', Name: 'Premont', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MD_9706',
    Name: 'Maugansville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OK_9707', Name: 'Jay', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_MO_9708', Name: 'Ironton', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_NY_9709',
    Name: 'Jamestown West',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_9710', Name: 'Nixon', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TN_9711',
    Name: 'Pine Crest',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_TX_9712', Name: 'Lyford', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_DE_9713',
    Name: 'Selbyville',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_CT_9714',
    Name: 'Glenville',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_ID_9715', Name: 'Montpelier', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_IA_9716', Name: 'Kalona', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NJ_9717',
    Name: 'Leisure Knoll',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MD_9718',
    Name: 'Konterra',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NC_9719',
    Name: 'Haw River',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MS_9720',
    Name: 'Nicholson',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_ID_9721',
    Name: 'Spirit Lake',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_WV_9722',
    Name: 'Milton',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MS_9723',
    Name: 'Lynchburg',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_9724',
    Name: 'Imperial',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_9725',
    Name: 'Deerfield',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_9726',
    Name: 'Piermont',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WV_9727',
    Name: 'Wellsburg',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_CT_9728',
    Name: 'Coventry Lake',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_PA_9729',
    Name: 'New Hope',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_9730',
    Name: 'Rockledge',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SC_9731',
    Name: 'Lincolnville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_TX_9732',
    Name: 'Holly Lake Ranch',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IN_9733',
    Name: 'Brookville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_MD_9734', Name: 'Owings', stateId: 'MD', stateName: 'Maryland'},
  {Id: 'CIT_US_CA_9735', Name: 'Nice', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_PA_9736',
    Name: 'Delmont',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OH_9737',
    Name: 'Fredericktown',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NJ_9738',
    Name: 'Mays Landing',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_ID_9739', Name: 'Osburn', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_IL_9740', Name: 'Downs', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_IA_9741', Name: 'Rock Rapids', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_ME_9742',
    Name: 'York Harbor',
    stateId: 'ME',
    stateName: 'Maine',
  },
  {Id: 'CIT_US_OH_9743', Name: 'Burlington', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MN_9744',
    Name: 'Lauderdale',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AR_9745', Name: 'Clinton', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_IL_9746',
    Name: 'Sheridan',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_9747', Name: 'Pantego', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_DE_9748',
    Name: 'Kent Acres',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_OH_9749',
    Name: 'West Lafayette',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MN_9750',
    Name: 'Paynesville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PR_9751',
    Name: 'Jayuya',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MN_9752',
    Name: 'Le Center',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MA_9753',
    Name: 'North Falmouth',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_WA_9754',
    Name: 'Mabton',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_9755',
    Name: 'Yardley',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_9756',
    Name: 'Ahwahnee',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_9757',
    Name: 'Derry',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MD_9758',
    Name: 'Adamstown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_VA_9759',
    Name: 'Chase City',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WI_9760',
    Name: 'Poynette',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_9761',
    Name: 'Idyllwild-Pine Cove',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OK_9762',
    Name: 'Commerce',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_CA_9763',
    Name: 'Rancho Santa Fe',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_GA_9764',
    Name: 'Indian Springs',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CA_9765',
    Name: 'Mission Canyon',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_9766',
    Name: 'White House Station',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IL_9767', Name: 'Diamond', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_TN_9768', Name: 'Halls', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_KY_9769',
    Name: 'Calvert City',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_TN_9770',
    Name: 'Sewanee',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_CA_9771',
    Name: 'Montara',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_9772', Name: 'Overton', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_ID_9773', Name: 'Victor', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_AK_9774', Name: 'Wrangell', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_OR_9775',
    Name: 'Bunker Hill',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_PA_9776',
    Name: 'Lake Wynonah',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_9777',
    Name: 'Coats',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WV_9778',
    Name: 'Alderson',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IN_9779',
    Name: 'Covington',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PA_9780',
    Name: 'Toftrees',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_9781',
    Name: 'Country Club',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MI_9782', Name: 'Clio', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_OH_9783', Name: 'Gibsonburg', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_FL_9784', Name: 'Geneva', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MN_9785',
    Name: 'Lake Crystal',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MS_9786',
    Name: 'Charleston',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WI_9787',
    Name: 'Tainter Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OH_9788', Name: 'Silver Lake', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PR_9789',
    Name: 'Antón Ruíz',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_IA_9790', Name: 'West Branch', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_GA_9791', Name: 'Raoul', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_PA_9792',
    Name: 'State Line',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_VA_9793', Name: 'Matoaca', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_IN_9794',
    Name: 'Oakland City',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_TX_9795', Name: 'Pottsboro', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_9796', Name: 'St. Hedwig', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KS_9797',
    Name: 'South Hutchinson',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_TX_9798', Name: 'Quanah', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_DE_9799',
    Name: 'Long Neck',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {Id: 'CIT_US_TX_9800', Name: 'The Hills', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_GA_9801',
    Name: 'Isle of Hope',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_OH_9802', Name: 'Gambier', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PR_9803',
    Name: 'Río Lajas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_MI_9804', Name: 'Hart', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MI_9805',
    Name: 'Spring Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WI_9806',
    Name: 'Wautoma',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_AR_9807', Name: 'Bono', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_SC_9808',
    Name: 'South Congaree',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_IN_9809', Name: 'Osceola', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PR_9810',
    Name: 'San José',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_SC_9811',
    Name: 'South Sumter',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_AR_9812', Name: 'Lincoln', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_TN_9813',
    Name: 'Walnut Hill',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_TX_9814', Name: 'Cumings', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_KY_9815', Name: 'Brooks', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_CT_9816',
    Name: 'Essex Village',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_OH_9817', Name: 'Richwood', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_9818',
    Name: 'West Newton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_9819',
    Name: 'Bentleyville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_LA_9820', Name: 'Bourg', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_TX_9821',
    Name: 'Hudson Oaks',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AL_9822',
    Name: 'Bayou La Batre',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_SD_9823',
    Name: 'Lennox',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NJ_9824',
    Name: 'Beverly',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CO_9825',
    Name: 'Telluride',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CA_9826',
    Name: 'Aromas',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_9827', Name: 'Galva', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_MT_9828', Name: 'Conrad', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_MO_9829',
    Name: 'Versailles',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IN_9830',
    Name: 'Chesterfield',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_AL_9831', Name: 'Trinity', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_OH_9832', Name: 'Hebron', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_9833',
    Name: 'Kenilworth',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_ND_9834',
    Name: 'Casselton',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NM_9835',
    Name: 'Cannon AFB',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MI_9836',
    Name: 'Orchard Lake Village',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MN_9837',
    Name: 'Wabasha',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AR_9838',
    Name: 'Elm Springs',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NY_9839',
    Name: 'Lake Katrine',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_KS_9840',
    Name: 'Hoisington',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_KS_9841',
    Name: 'Phillipsburg',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_TX_9842', Name: 'Ranger', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_9843',
    Name: 'Midland',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_9844',
    Name: 'Surf City',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IA_9845', Name: 'Hudson', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AR_9846',
    Name: 'Charleston',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_DE_9847',
    Name: 'Greenville',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {Id: 'CIT_US_FL_9848', Name: 'Wallace', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_OK_9849',
    Name: 'Nicoma Park',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_IA_9850', Name: 'Marengo', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_VA_9851',
    Name: 'Gloucester Courthouse',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_9852', Name: 'Olmos Park', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_LA_9853',
    Name: 'Stonewall',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WI_9854',
    Name: 'Mineral Point',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WI_9855',
    Name: 'Belleville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MO_9856',
    Name: 'Strafford',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TX_9857',
    Name: 'Rancho Viejo',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NJ_9858',
    Name: 'New Egypt',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WA_9859',
    Name: 'Geneva',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KY_9860',
    Name: 'Windy Hills',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_9861',
    Name: 'Gastonville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_9862',
    Name: 'Weatherly',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_9863',
    Name: 'Carthage',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WY_9864',
    Name: 'Warren AFB',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_PA_9865',
    Name: 'Oliver',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_9866',
    Name: 'Fennimore',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_ID_9867', Name: 'Bellevue', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_OK_9868', Name: 'Goldsby', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_PA_9869',
    Name: 'Liberty',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AZ_9870',
    Name: 'Window Rock',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_IN_9871', Name: 'Avilla', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_IL_9872',
    Name: 'Fairmont City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IN_9873',
    Name: 'Grissom AFB',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PR_9874',
    Name: 'Alianza',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_PR_9875',
    Name: 'H. Rivera Colón',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_NY_9876',
    Name: 'Harriman',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_CA_9877', Name: 'Ross', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_IN_9878',
    Name: 'Middletown',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CA_9879',
    Name: 'Parksdale',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_WI_9880', Name: 'Lomira', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_NC_9881',
    Name: 'Norwood',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_9882',
    Name: 'Newmanstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_9883', Name: 'Winters', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_GA_9884',
    Name: 'Lakeview Estates',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_TX_9885', Name: 'Blue Mound', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_9886', Name: 'Hawarden', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_9887',
    Name: 'Woodside',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_9888', Name: 'Combine', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TN_9889',
    Name: 'Rural Hill',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_IA_9890',
    Name: 'Belle Plaine',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_MO_9891',
    Name: 'Monroe City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_LA_9892',
    Name: 'Kentwood',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_9893',
    Name: 'Trucksville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_9894',
    Name: 'Shadow Lake',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KY_9895',
    Name: 'Claryville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_KY_9896',
    Name: 'Morgantown',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_AK_9897', Name: 'Deltana', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_SC_9898',
    Name: 'Inman',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_TX_9899', Name: 'Wyldwood', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_9900',
    Name: 'Stormstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IL_9901', Name: 'Wayne', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_TN_9902',
    Name: 'Maynardville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_AL_9903', Name: 'Taylor', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_CA_9904',
    Name: 'Bella Vista',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AR_9905', Name: 'Lavaca', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_DE_9906',
    Name: 'Woodside East',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_WA_9907',
    Name: 'Cathcart',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KY_9908',
    Name: 'Cave City',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WA_9909',
    Name: 'Fall City',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MS_9910',
    Name: 'Collins',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MI_9911',
    Name: 'Pleasant Ridge',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NY_9912',
    Name: 'Melrose Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_9913',
    Name: 'Panorama Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IN_9914', Name: 'Ingalls', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_CA_9915',
    Name: 'Three Rivers',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_9916',
    Name: 'Anchorage',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_OH_9917',
    Name: 'North Zanesville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WI_9918',
    Name: 'Nekoosa',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_AR_9919',
    Name: 'Prairie Creek',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_TX_9920',
    Name: 'Midway South',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AR_9921',
    Name: 'Marked Tree',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MI_9922',
    Name: 'Pinckney',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NM_9923',
    Name: 'Crownpoint',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_TN_9924',
    Name: 'Waynesboro',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_SC_9925',
    Name: 'Cowpens',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_IL_9926',
    Name: 'Villa Grove',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PR_9927',
    Name: 'Tiburones',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_NY_9928',
    Name: 'Barnum Island',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NJ_9929',
    Name: 'Woodbine',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_MO_9930', Name: 'Lawson', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_WA_9931', Name: 'Allyn', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_TN_9932',
    Name: 'Westmoreland',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_CO_9933', Name: 'Laporte', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_TX_9934', Name: 'Shepherd', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MN_9935',
    Name: 'Breezy Point',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_9936',
    Name: 'Eddystone',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AK_9937', Name: 'Houston', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_MD_9938',
    Name: 'Perryman',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_IN_9939',
    Name: 'Santa Claus',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PA_9940',
    Name: 'Verona',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ID_9941',
    Name: 'Dalton Gardens',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_IN_9942',
    Name: 'Bloomfield',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MN_9943',
    Name: 'Spring Valley',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WA_9944',
    Name: 'Arlington Heights',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_9945',
    Name: 'Neillsville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_FL_9946',
    Name: 'Monticello',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_LA_9947',
    Name: 'Chauvin',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_9948',
    Name: 'Coudersport',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_9949',
    Name: 'Lake of the Woods',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_LA_9950',
    Name: 'Lockport',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_9951', Name: 'Junction', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MI_9952',
    Name: 'Spring Arbor',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_9953',
    Name: 'Marcus Hook',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_9954',
    Name: 'Charlotte Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_9955',
    Name: 'Thornton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_9956',
    Name: 'Enfield',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_9957',
    Name: 'Meyersdale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MA_9958',
    Name: 'North Seekonk',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_NM_9959',
    Name: 'Lordsburg',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_FL_9960',
    Name: 'El Portal',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_GA_9961',
    Name: 'East Dublin',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_SD_9962',
    Name: 'Sisseton',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IN_9963', Name: 'Lapel', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PR_9964',
    Name: 'Campo Rico',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_IL_9965',
    Name: 'Carrollton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_9966', Name: 'Bolindale', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MI_9967',
    Name: 'St. Ignace',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SD_9968',
    Name: 'Colonial Pine Hills',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_AR_9969',
    Name: 'Danville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MS_9970',
    Name: 'Hide-A-Way Lake',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MS_9971',
    Name: 'Purvis',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_NE_9972', Name: 'Madison', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_NY_9973', Name: 'Glasco', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_MI_9974',
    Name: 'Reed City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_AR_9975',
    Name: 'Mayflower',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_TN_9976', Name: 'Hunter', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_MS_9977',
    Name: 'Mendenhall',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CA_9978',
    Name: 'Buckhorn',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MO_9979', Name: 'Seneca', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_OH_9980',
    Name: 'Wright-Patterson AFB',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OH_9981', Name: 'Enon', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_9982',
    Name: 'Conneaut Lakeshore',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_ME_9983', Name: 'Hallowell', stateId: 'ME', stateName: 'Maine'},
  {
    Id: 'CIT_US_SC_9984',
    Name: 'Johnston',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OH_9985', Name: 'New Miami', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PR_9986',
    Name: 'Barranquitas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_FL_9987',
    Name: 'Virginia Gardens',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_9988',
    Name: 'Susquehanna Depot',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OR_9989', Name: 'Odell', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NC_9990',
    Name: 'Icard',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WV_9991',
    Name: 'Paden City',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_OH_9992', Name: 'Poland', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AL_9993',
    Name: 'Riverside',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_FL_9994', Name: 'Harlem', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_WV_9995',
    Name: 'Craigsville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MN_9996',
    Name: 'St. Bonifacius',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_NE_9997', Name: 'Kimball', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_PA_9998',
    Name: 'Highspire',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_9999',
    Name: 'Fruitland',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IN_10000',
    Name: 'Albion',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_GA_10001',
    Name: 'Mount Vernon',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NE_10002',
    Name: 'Hickman',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_TN_10003',
    Name: 'Decherd',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WA_10004',
    Name: 'Whidbey Island Station',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OK_10005', Name: 'Calera', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_TX_10006',
    Name: 'Dalworthington Gardens',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_10007', Name: 'Salado', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_10008', Name: 'Newton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_10009',
    Name: 'Pine Canyon',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_10010', Name: 'Ponder', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WV_10011',
    Name: 'Chester',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_LA_10012',
    Name: 'Kinder',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NC_10013',
    Name: 'Horse Shoe',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_10014',
    Name: 'Fife Heights',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OK_10015', Name: 'Prague', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_PA_10016',
    Name: 'Lake Heritage',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_WY_10017', Name: 'Lovell', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_SD_10018',
    Name: 'Chamberlain',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_SC_10019',
    Name: 'Timmonsville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MO_10020',
    Name: 'Concordia',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_SC_10021',
    Name: 'Pine Ridge',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_AL_10022',
    Name: 'Ashville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_AL_10023', Name: 'Henagar', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NY_10024',
    Name: 'Riverside',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AL_10025',
    Name: 'Good Hope',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PA_10026',
    Name: 'Matamoras',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_10027',
    Name: 'Montague',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_10028',
    Name: 'Red Hill',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AK_10029',
    Name: 'Dillingham',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_CA_10030',
    Name: 'Rainbow',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_10031', Name: 'Monroe', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_OH_10032', Name: 'New London', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AL_10033',
    Name: 'Midland City',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_OK_10034',
    Name: 'Walters',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WA_10035',
    Name: 'Brewster',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WV_10036',
    Name: 'Hinton',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_AL_10037',
    Name: 'East Brewton',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_KY_10038',
    Name: 'Raceland',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CT_10039',
    Name: 'Deep River Center',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_MI_10040',
    Name: 'Carleton',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_ID_10041', Name: 'Iona', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_MN_10042',
    Name: 'Crosslake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_10043',
    Name: 'Llano Grande',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AR_10044',
    Name: 'Holiday Island',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_WA_10045',
    Name: 'Gold Bar',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_SC_10046',
    Name: 'McCormick',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_WV_10047',
    Name: 'Winfield',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_OH_10048', Name: 'Byesville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IN_10049', Name: 'Winamac', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PA_10050',
    Name: 'Emsworth',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_10051',
    Name: 'Crompond',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_10052',
    Name: 'New Wilmington',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_CO_10053', Name: 'Wray', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_MI_10054',
    Name: 'East Jordan',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MS_10055',
    Name: 'Lyman',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CO_10056',
    Name: 'Colorado City',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_GA_10057', Name: 'Oxford', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MO_10058',
    Name: 'Moline Acres',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NC_10059',
    Name: 'Maxton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_KS_10060', Name: 'Eureka', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_OK_10061',
    Name: 'Hartshorne',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WI_10062',
    Name: 'Belgium',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_10063',
    Name: 'Emporium',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_10064',
    Name: 'Leith-Hatfield',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_10065', Name: 'Ely', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_10066',
    Name: 'Hermann',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CO_10067',
    Name: 'La Salle',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_TX_10068',
    Name: 'East Bernard',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MN_10069',
    Name: 'Excelsior',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_10070',
    Name: 'Altamont',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_10071', Name: 'Anahuac', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TX_10072',
    Name: 'West Sharyland',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_KS_10073', Name: 'Neodesha', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_KY_10074',
    Name: 'Hardinsburg',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_LA_10075',
    Name: 'Belle Rose',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WI_10076',
    Name: 'Darlington',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_10077',
    Name: 'Brewster',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TN_10078',
    Name: 'Mosheim',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_FL_10079',
    Name: 'Eastpoint',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NJ_10080',
    Name: 'Princeton Junction',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_10081',
    Name: 'Wofford Heights',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_10082',
    Name: 'Olivia',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OR_10083', Name: 'Mulino', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_FL_10084', Name: 'Olga', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NE_10085',
    Name: 'St. Paul',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_VA_10086',
    Name: 'Shenandoah',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_OR_10087',
    Name: 'Rogue River',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_HI_10088',
    Name: 'Wheeler AFB',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_GA_10089',
    Name: 'Lithonia',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PA_10090',
    Name: 'Sun Valley',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_10091',
    Name: 'Laurel Park',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_10092',
    Name: 'Parkside',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_10093', Name: 'Cascade', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MI_10094',
    Name: 'Edgemont Park',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MD_10095',
    Name: 'Kensington',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_10096', Name: 'McQueeney', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_10097',
    Name: 'Dousman',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_10098', Name: 'Abram', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NC_10099',
    Name: 'Green Level',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_10100',
    Name: 'Shenorock',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MS_10101',
    Name: 'Bridgetown',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NJ_10102',
    Name: 'West Belmar',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_SC_10103',
    Name: 'Winnsboro Mills',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_FL_10104',
    Name: 'Campbell',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_VA_10105',
    Name: 'Fairlawn',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_IL_10106', Name: 'Amboy', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_OK_10107',
    Name: 'Antlers',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WV_10108',
    Name: 'Bethlehem',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_SC_10109',
    Name: 'Kershaw',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CA_10110',
    Name: 'Weldon',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_10111',
    Name: 'Lebanon South',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ME_10112',
    Name: 'South Paris',
    stateId: 'ME',
    stateName: 'Maine',
  },
  {
    Id: 'CIT_US_MS_10113',
    Name: 'Kiln',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_MI_10114', Name: 'Bath', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_IN_10115',
    Name: 'Petersburg',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_FL_10116',
    Name: 'Pine Air',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_OH_10117',
    Name: 'Shawnee Hills',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CA_10118',
    Name: 'Richgrove',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SD_10119',
    Name: 'Flandreau',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_TX_10120', Name: 'La Blanca', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TX_10121',
    Name: 'Liberty City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MA_10122',
    Name: 'Marshfield Hills',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_NM_10123',
    Name: 'La Mesilla',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_DE_10124',
    Name: 'Riverview',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_CA_10125',
    Name: 'Portola',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CO_10126',
    Name: 'Coal Creek',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_TX_10127', Name: 'Panhandle', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_ND_10128',
    Name: 'Hazen',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_HI_10129', Name: 'Anahola', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_CA_10130',
    Name: 'Twain Harte',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OH_10131',
    Name: 'Wilberforce',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_VA_10132', Name: 'Ferrum', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_NY_10133', Name: 'Nedrow', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_TN_10134',
    Name: 'Mascot',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_AR_10135',
    Name: 'Harrisburg',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_OH_10136', Name: 'Churchill', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AZ_10137',
    Name: 'Star Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_TX_10138', Name: 'Idalou', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NC_10139',
    Name: 'Navassa',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_KY_10140',
    Name: 'Junction City',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IA_10141', Name: 'West Union', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_TX_10142', Name: 'Mason', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_10143',
    Name: 'Hollandale',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_AZ_10144',
    Name: 'Whetstone',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WA_10145',
    Name: 'Burley',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MT_10146',
    Name: 'Red Lodge',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MI_10147',
    Name: 'Bronson',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IN_10148', Name: 'Fowler', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_TX_10149', Name: 'Tool', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MO_10150', Name: 'Canton', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_HI_10151',
    Name: 'Honaunau-Napoopoo',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_LA_10152',
    Name: 'Milton',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MN_10153',
    Name: 'Pequot Lakes',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IA_10154', Name: 'Hull', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_TX_10155', Name: 'Anson', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AL_10156',
    Name: 'Bridgeport',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PA_10157',
    Name: 'Wrightsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CT_10158',
    Name: 'Old Saybrook Center',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_PA_10159',
    Name: 'Gibsonia',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_10160',
    Name: 'Romoland',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OH_10161',
    Name: 'Terrace Park',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CT_10162',
    Name: 'Salmon Brook',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_PA_10163',
    Name: 'Orchard Hills',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_10164',
    Name: 'Woodland Beach',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_FL_10165',
    Name: 'Redington Shores',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NJ_10166',
    Name: 'Pine Ridge at Crestwood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NM_10167',
    Name: 'Santo Domingo Pueblo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NY_10168',
    Name: 'Lincoln Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_10169',
    Name: 'Arnold',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_10170',
    Name: 'Johnsonburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_10171',
    Name: 'Weedpatch',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MI_10172',
    Name: 'Clinton',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_TN_10173',
    Name: 'Parsons',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_GA_10174', Name: 'Gumlog', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WA_10175',
    Name: 'Rainier',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_OK_10176',
    Name: 'Texanna',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MI_10177',
    Name: 'Croswell',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MO_10178',
    Name: 'Wellston',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_10179', Name: 'Poth', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WV_10180',
    Name: 'Oceana',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_10181',
    Name: 'Slippery Rock University',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_10182',
    Name: 'Greenport West',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_10183',
    Name: 'Sag Harbor',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_GA_10184',
    Name: 'Ludowici',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_WA_10185',
    Name: 'Carnation',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OR_10186', Name: 'Vernonia', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_PR_10187',
    Name: 'Boquerón',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_WI_10188',
    Name: 'Brice Prairie',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IL_10189', Name: 'Ina', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MD_10190',
    Name: 'Hughesville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NC_10191',
    Name: 'Brogden',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MI_10192',
    Name: 'Trowbridge Park',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_10193',
    Name: 'East Sonora',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SD_10194',
    Name: 'North Spearfish',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_WI_10195',
    Name: 'Denmark',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_NV_10196', Name: 'Carlin', stateId: 'NV', stateName: 'Nevada'},
  {Id: 'CIT_US_TN_10197', Name: 'Alamo', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_MI_10198',
    Name: 'Cass City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_TN_10199',
    Name: 'Tazewell',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_AL_10200', Name: 'Guin', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_MT_10201', Name: 'Pablo', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_PA_10202',
    Name: 'Egypt',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SD_10203',
    Name: 'Redfield',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_CA_10204',
    Name: 'Sleepy Hollow',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OK_10205',
    Name: 'Oakhurst',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_PA_10206',
    Name: 'Geistown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ND_10207',
    Name: 'Belcourt',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NC_10208',
    Name: 'Calabash',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OH_10209',
    Name: 'Walton Hills',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_KS_10210',
    Name: 'Herington',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_AL_10211',
    Name: 'Greensboro',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NY_10212',
    Name: 'Brinckerhoff',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_FL_10213',
    Name: 'Quail Ridge',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_OH_10214',
    Name: 'Skyline Acres',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_MN_10215', Name: 'Mayer', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_PR_10216',
    Name: 'Aguas Claras',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_CA_10217',
    Name: 'Clearlake Oaks',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_CO_10218', Name: 'Center', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_PA_10219',
    Name: 'Fairdale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CO_10220',
    Name: 'Rangely',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_LA_10221',
    Name: 'Fort Polk North',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_IA_10222', Name: 'Belmond', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MD_10223',
    Name: 'Wilson-Conococheague',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MS_10224',
    Name: 'Durant',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OH_10225', Name: 'Utica', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MS_10226',
    Name: 'Tunica Resorts',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CA_10227',
    Name: 'Poplar-Cotton Center',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IN_10228',
    Name: 'Waterloo',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MA_10229',
    Name: 'West Wareham',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_IA_10230',
    Name: 'Mitchellville',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_PA_10231',
    Name: 'Zion',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_10232',
    Name: 'Escatawpa',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IN_10233', Name: 'Spencer', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_TX_10234', Name: 'Splendora', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_10235',
    Name: 'Ogdensburg',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_10236',
    Name: 'Oak Hills',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_10237',
    Name: 'Westby',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NJ_10238',
    Name: 'Alpha',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NC_10239',
    Name: 'Four Oaks',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_10240',
    Name: 'Westmorland',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_10241',
    Name: 'Oyster Bay Cove',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MN_10242',
    Name: 'Madelia',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AL_10243',
    Name: 'Aliceville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_HI_10244', Name: 'Honalo', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_VA_10245',
    Name: 'New Market',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WI_10246',
    Name: 'Fredonia',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_FL_10247',
    Name: 'Grove City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_10248',
    Name: 'Watsontown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_10249',
    Name: 'Janesville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_KY_10250',
    Name: 'Tompkinsville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_CO_10251', Name: 'Meeker', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_IL_10252', Name: 'Seneca', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CA_10253',
    Name: 'Aptos Hills-Larkin Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_10254', Name: 'Hunter', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OR_10255',
    Name: 'Willamina',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_IA_10256', Name: 'Lamoni', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MT_10257',
    Name: 'Lakeside',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_PR_10258',
    Name: 'Los Prados',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_AL_10259',
    Name: 'Lineville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_HI_10260', Name: 'Paia', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_HI_10261', Name: 'Keokea', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_NY_10262',
    Name: 'Falconer',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IN_10263',
    Name: 'Ferdinand',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IA_10264', Name: 'Lisbon', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_10265',
    Name: 'Spring Mount',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MT_10266',
    Name: 'Colstrip',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_CA_10267',
    Name: 'Shingletown',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TN_10268',
    Name: 'Fairmount',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PR_10269',
    Name: 'Los Llanos',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_TX_10270',
    Name: 'Laughlin AFB',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CO_10271',
    Name: 'Leadville North',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NC_10272',
    Name: 'Franklinton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NJ_10273',
    Name: 'Vista Center',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NY_10274',
    Name: 'Phoenix',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AR_10275',
    Name: 'Lake Hamilton',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_IA_10276',
    Name: 'La Porte City',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_PA_10277',
    Name: 'Newtown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_10278',
    Name: 'Winsted',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AZ_10279',
    Name: 'Grand Canyon Village',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_AL_10280', Name: 'York', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NC_10281',
    Name: 'Mulberry',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_10282',
    Name: 'East Berwick',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_10283', Name: 'Garfield', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_10284',
    Name: 'Plattsburg',
    stateId: 'TX',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MI_10285',
    Name: 'Chesaning',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WA_10286',
    Name: 'Royal City',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CT_10287',
    Name: 'Westbrook Center',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_PR_10288',
    Name: 'La Fermina',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_MO_10289', Name: 'Holden', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MA_10290',
    Name: 'West Dennis',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_MD_10291',
    Name: 'Jefferson',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OH_10292', Name: 'Sugarcreek', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_10293',
    Name: 'Oakwood',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_10294',
    Name: 'Buchanan',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_KY_10295',
    Name: 'Jackson',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WI_10296',
    Name: 'Park Falls',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WI_10297',
    Name: 'North Prairie',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IN_10298', Name: 'Hope', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_MD_10299',
    Name: 'Drum Point',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_10300',
    Name: 'Sky Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AL_10301',
    Name: 'Uniontown',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_TX_10302', Name: 'Weimar', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_GA_10303', Name: 'Guyton', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_FL_10304',
    Name: "Sewall's Point",
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_10305', Name: 'Centerburg', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_10306',
    Name: 'Columbia',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_10307', Name: 'Elwood', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_KS_10308', Name: 'Fredonia', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NY_10309',
    Name: 'Pulaski',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WA_10310',
    Name: 'Southworth',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_10311',
    Name: 'Brownsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_10312',
    Name: 'Farmington',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OK_10313',
    Name: 'Hennessey',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NC_10314',
    Name: 'Mount Pleasant',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_AZ_10315',
    Name: 'Dolan Springs',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_CA_10316',
    Name: 'Boron',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OR_10317', Name: 'Union', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_SD_10318',
    Name: 'North Eagle Butte',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_FL_10319',
    Name: 'Grenelefe',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_LA_10320',
    Name: 'Baldwin',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_10321',
    Name: 'Cleona',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_GA_10322',
    Name: 'Henderson',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MS_10323',
    Name: 'Lumberton',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NJ_10324',
    Name: 'Gibbsboro',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OH_10325', Name: 'Woodsfield', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TN_10326',
    Name: 'Central',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_OH_10327',
    Name: 'Gates Mills',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_ME_10328',
    Name: 'Cape Neddick',
    stateId: 'ME',
    stateName: 'Maine',
  },
  {
    Id: 'CIT_US_SD_10329',
    Name: 'Fort Pierre',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NM_10330',
    Name: 'San Felipe Pueblo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_CA_10331',
    Name: 'Soulsbyville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_10332',
    Name: 'Jonesville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MI_10333',
    Name: 'Jonesville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_LA_10334',
    Name: 'Amelia',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_OH_10335',
    Name: 'Chesterland',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_FL_10336',
    Name: 'Oak Hill',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_TN_10337',
    Name: 'South Fulton',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_FL_10338',
    Name: 'Eatonville',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_OH_10339',
    Name: 'Green Meadows',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_AL_10340', Name: 'Vincent', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MS_10341',
    Name: 'Magnolia',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MI_10342',
    Name: 'Bridgman',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_LA_10343',
    Name: 'New Llano',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NJ_10344',
    Name: 'Wenonah',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_MI_10345', Name: 'Hudson', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_CA_10346',
    Name: 'East Quincy',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_10347', Name: 'Henry', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_KY_10348', Name: 'Evarts', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_KS_10349', Name: 'Sterling', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_VT_10350',
    Name: 'White River Junction',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_CO_10351',
    Name: 'Holyoke',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_IN_10352',
    Name: 'West Terre Haute',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_TX_10353',
    Name: 'Hudson Bend',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MN_10354',
    Name: 'Gaylord',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_10355',
    Name: 'South Pottstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OH_10356',
    Name: 'Elmwood Place',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_VA_10357',
    Name: 'Crimora',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PR_10358',
    Name: 'Olimpo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_KY_10359',
    Name: 'Dry Ridge',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NY_10360',
    Name: 'Liverpool',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_10361',
    Name: 'Fox Lake Hills',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AR_10362',
    Name: 'Lake Village',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_OR_10363',
    Name: 'North Plains',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_OK_10364',
    Name: 'Wynnewood',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_SC_10365',
    Name: 'St. George',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NY_10366',
    Name: 'Shinnecock Hills',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OH_10367', Name: 'Windham', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_KS_10368', Name: 'Cimarron', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NJ_10369',
    Name: 'Long Valley',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_10370',
    Name: 'Cecil-Bishop',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_10371',
    Name: 'Moorhead',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IL_10372', Name: 'Arthur', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_GA_10373', Name: 'Lavonia', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_VA_10374',
    Name: 'Lovettsville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_10375', Name: 'De Leon', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MN_10376',
    Name: 'Hawley',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CT_10377',
    Name: 'Madison Center',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_NY_10378',
    Name: 'Brewster Hill',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AZ_10379',
    Name: 'Valle Vista',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_GA_10380',
    Name: 'Ball Ground',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PR_10381',
    Name: 'Monserrate',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_WA_10382',
    Name: 'Kingston',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PR_10383',
    Name: 'Palmas del Mar',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_PR_10384',
    Name: 'Corazón',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_IN_10385', Name: 'Fremont', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_TX_10386', Name: 'Waskom', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SC_10387',
    Name: 'Blackville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_AR_10388',
    Name: 'Fairfield Bay',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_CO_10389', Name: 'Lyons', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_CT_10390',
    Name: 'Crystal Lake',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_VA_10391',
    Name: 'Ravensworth',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_10392',
    Name: 'Murphys',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_10393',
    Name: 'Lemont',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_10394',
    Name: 'Belleair Bluffs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NY_10395',
    Name: 'Old Brookville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_FL_10396',
    Name: 'Chiefland',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_10397',
    Name: 'Old Orchard',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SC_10398',
    Name: 'Arial',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_OK_10399',
    Name: 'Lexington',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NJ_10400',
    Name: 'Pine Beach',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_10401',
    Name: 'Parkwood',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OK_10402', Name: 'Pink', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_MO_10403',
    Name: 'Marionville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_AL_10404', Name: 'Ashford', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_OH_10405', Name: 'Glendale', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_10406',
    Name: 'Tullytown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AZ_10407',
    Name: 'Rio Verde',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_LA_10408',
    Name: 'Erwinville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_GA_10409',
    Name: 'Hoschton',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_VA_10410',
    Name: 'Amherst',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NH_10411',
    Name: 'North Conway',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_LA_10412',
    Name: 'Pierre Part',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_AR_10413', Name: 'Earle', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_PA_10414',
    Name: 'Spring Grove',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_10415',
    Name: 'Ranchos de Taos',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_10416',
    Name: 'East Uniontown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_10417',
    Name: 'Pine Island Center',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NY_10418',
    Name: 'Upper Nyack',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_MD_10419', Name: 'Croom', stateId: 'MD', stateName: 'Maryland'},
  {Id: 'CIT_US_AZ_10420', Name: 'Cibecue', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_NM_10421',
    Name: 'Boles Acres',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_IL_10422', Name: 'Polo', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_OK_10423',
    Name: 'Newkirk',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_LA_10424',
    Name: 'Lakeshore',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OH_10425', Name: 'Creston', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AK_10426', Name: 'Cordova', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_MN_10427', Name: 'Wells', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_NY_10428',
    Name: 'South Fallsburg',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AZ_10429', Name: 'Kearny', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_KS_10430', Name: 'Cheney', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WI_10431',
    Name: 'Schofield',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TN_10432',
    Name: 'Jellico',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_TN_10433',
    Name: 'Adamsville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_10434',
    Name: 'Pymatuning Central',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_10435', Name: 'Shiner', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_10436', Name: 'Whitney', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_10437',
    Name: 'Bethel Island',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_10438',
    Name: 'Chinchilla',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OH_10439',
    Name: 'Spencerville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CA_10440',
    Name: 'Corralitos',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_10441',
    Name: 'Rockwell',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_WI_10442', Name: 'Eagle', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_OK_10443', Name: 'Spiro', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_PA_10444',
    Name: 'Messiah College',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AR_10445',
    Name: 'Gassville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_TX_10446',
    Name: 'Patton Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_10447',
    Name: 'Penndel',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OK_10448',
    Name: 'Union City',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_AR_10449',
    Name: 'Carlisle',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_10450',
    Name: 'McAdoo',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TN_10451',
    Name: 'Plainview',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_TN_10452',
    Name: 'Walden',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NJ_10453',
    Name: 'Helmetta',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MS_10454',
    Name: 'Farmington',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MS_10455',
    Name: 'Glendale',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_LA_10456',
    Name: 'Garyville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_GA_10457',
    Name: 'Reed Creek',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_ND_10458',
    Name: 'Bottineau',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_SC_10459',
    Name: 'Ware Shoals',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MI_10460',
    Name: 'Manchester',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WA_10461',
    Name: 'Freeland',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OH_10462', Name: 'Brewster', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MT_10463',
    Name: 'Townsend',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_FL_10464',
    Name: 'Eglin AFB',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_SC_10465',
    Name: 'New Ellenton',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_WI_10466',
    Name: 'New Glarus',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_10467',
    Name: 'Stonybrook',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_10468',
    Name: 'Evansburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_UT_10469', Name: 'Millville', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_WI_10470',
    Name: 'Lake Wazeecha',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_10471', Name: 'Elmendorf', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AL_10472', Name: 'Morris', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MN_10473',
    Name: 'Maple Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TN_10474',
    Name: 'Caryville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_VA_10475',
    Name: 'Laymantown',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_IN_10476', Name: 'Albany', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_ID_10477', Name: 'Parma', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_MO_10478',
    Name: 'Marlborough',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_GA_10479',
    Name: 'Berkeley Lake',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PR_10480',
    Name: 'Barahona',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MI_10481',
    Name: 'Morenci',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_10482',
    Name: 'Lewistown',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_10483',
    Name: 'Arlington',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_10484',
    Name: 'Chester',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IN_10485',
    Name: 'Cloverdale',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CA_10486',
    Name: 'Borrego Springs',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IN_10487',
    Name: 'Rockport',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NV_10488',
    Name: 'Kingsbury',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_MD_10489',
    Name: 'Long Beach',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_IA_10490', Name: 'Toledo', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_FL_10491',
    Name: 'Haverhill',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_LA_10492',
    Name: 'Lafourche Crossing',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_10493', Name: 'Fannett', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_KS_10494', Name: 'Lakin', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OH_10495', Name: 'Piketon', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_10496',
    Name: 'Coaldale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_10497',
    Name: 'Lorenz Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WI_10498',
    Name: 'Clinton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_GA_10499', Name: 'Varnell', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_AL_10500',
    Name: 'Lipscomb',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_IN_10501',
    Name: 'Rising Sun',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_CO_10502', Name: 'Granby', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_KS_10503',
    Name: 'Cherryvale',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_ID_10504',
    Name: 'Malad City',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_TN_10505',
    Name: 'Ridgetop',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MN_10506',
    Name: 'Howard Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_10507',
    Name: 'China Lake Acres',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_10508',
    Name: 'Aviston',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_10509',
    Name: 'Santa Nella',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PR_10510',
    Name: 'Emajagua',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_PA_10511',
    Name: 'Dublin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_10512',
    Name: 'Wewahitchka',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_LA_10513',
    Name: 'Montegut',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_FL_10514',
    Name: 'Tavernier',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MD_10515',
    Name: 'Solomons',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_AR_10516',
    Name: 'Wrightsville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_IL_10517',
    Name: 'Brighton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_10518',
    Name: 'Johnson City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_KY_10519',
    Name: 'Liberty',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_10520',
    Name: 'Reinholds',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_10521',
    Name: 'Owens Cross Roads',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_FL_10522',
    Name: 'Atlantis',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_KY_10523', Name: 'Elkton', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_AK_10524',
    Name: 'Anchor Point',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_NJ_10525',
    Name: 'Belford',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_AR_10526',
    Name: 'Glenwood',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_WI_10527',
    Name: 'Redgranite',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NJ_10528',
    Name: 'North Beach Haven',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NY_10529',
    Name: 'Rouses Point',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IN_10530',
    Name: 'Salt Creek Commons',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_GA_10531',
    Name: 'Demorest',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_IN_10532', Name: 'Orleans', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_TX_10533', Name: 'Palmer', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MA_10534',
    Name: 'Vineyard Haven',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_GA_10535',
    Name: 'Tennille',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_IN_10536',
    Name: 'Jasonville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_KS_10537',
    Name: 'Council Grove',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_MD_10538',
    Name: 'Cabin John',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OR_10539', Name: 'Turner', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_IL_10540',
    Name: 'Tremont',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_10541',
    Name: 'Mason City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_10542',
    Name: 'Country Knolls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_HI_10543', Name: 'Kahuku', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_VA_10544',
    Name: 'Mount Jackson',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WV_10545',
    Name: 'Welch',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_NM_10546', Name: 'Jal', stateId: 'NM', stateName: 'New Mexico'},
  {
    Id: 'CIT_US_WI_10547',
    Name: 'Cedar Grove',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_10548',
    Name: 'Landisville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_10549',
    Name: 'Watchtower',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NC_10550',
    Name: 'Madison',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PR_10551',
    Name: 'Ciales',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MO_10552',
    Name: 'Vinita Park',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MT_10553', Name: 'Ronan', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_MN_10554',
    Name: 'Maple Plain',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_10555',
    Name: 'Hamilton City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MS_10556',
    Name: 'Arnold Line',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NC_10557',
    Name: 'Hertford',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OR_10558',
    Name: 'Stanfield',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_TX_10559',
    Name: 'Huntington',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MT_10560',
    Name: 'East Missoula',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_PA_10561',
    Name: 'Wilmerding',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_10562',
    Name: 'Quitman',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_LA_10563', Name: 'Montz', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_TN_10564',
    Name: 'Unionville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_AR_10565',
    Name: 'Horseshoe Bend',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_KY_10566',
    Name: 'Breckinridge Center',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MO_10567',
    Name: 'Lake Lotawana',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_10568',
    Name: 'Bell Canyon',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_10569',
    Name: 'Hickman',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_TX_10570', Name: 'Kountze', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_10571',
    Name: 'West Hamburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OK_10572', Name: 'Pawnee', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_TN_10573',
    Name: 'Jamestown',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_FL_10574',
    Name: 'Williston Highlands',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AR_10575',
    Name: 'Eureka Springs',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_UT_10576', Name: 'Helper', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_FL_10577',
    Name: 'Cudjoe Key',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NY_10578',
    Name: 'Conesus Lake',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_10579',
    Name: 'Braddock',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_10580', Name: 'Jonestown', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_10581',
    Name: 'Belvedere',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WV_10582',
    Name: 'Shinnston',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_CA_10583',
    Name: 'San Juan Bautista',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_10584',
    Name: 'Belville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MT_10585',
    Name: 'East Helena',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_GA_10586',
    Name: 'Maysville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_WI_10587',
    Name: 'Cumberland',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_KY_10588',
    Name: 'Falmouth',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_NY_10589', Name: 'Angola', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_AL_10590',
    Name: 'Smoke Rise',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MO_10591',
    Name: 'Hanley Hills',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AZ_10592',
    Name: 'Gila Bend',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WA_10593',
    Name: 'Westport',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_GA_10594', Name: 'Bowdon', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MO_10595',
    Name: 'Willow Springs',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_10596', Name: 'New Boston', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CT_10597',
    Name: 'Poquonock Bridge',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_IL_10598',
    Name: 'Oakbrook Terrace',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_10599',
    Name: 'Kure Beach',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MN_10600',
    Name: 'Nisswa',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_OH_10601',
    Name: 'South Zanesville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_AK_10602',
    Name: 'North Pole',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_TX_10603', Name: 'Josephine', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_10604',
    Name: 'Manahawkin',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_OK_10605',
    Name: 'Chouteau',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_AL_10606',
    Name: 'Graysville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_FL_10607', Name: 'Malone', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NE_10608',
    Name: 'Milford',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_TX_10609',
    Name: 'Laguna Heights',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MD_10610',
    Name: 'Williamsport',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_10611',
    Name: 'Calwa',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_10612',
    Name: 'Essex Fells',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_10613',
    Name: 'Walnutport',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_10614',
    Name: 'Greenock',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MT_10615',
    Name: 'Lame Deer',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_TN_10616',
    Name: 'Greenfield',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_NE_10617', Name: 'Geneva', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_AR_10618', Name: 'Gurdon', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_MO_10619',
    Name: 'Marceline',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MO_10620', Name: 'Granby', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_CA_10621',
    Name: 'Patterson Tract',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_10622',
    Name: 'Astatula',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_10623',
    Name: 'New Eagle',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_10624',
    Name: 'Fairbanks Ranch',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VA_10625',
    Name: 'Lake Holiday',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IN_10626',
    Name: 'Versailles',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IL_10627', Name: 'Cairo', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_MI_10628', Name: 'Auburn', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MO_10629',
    Name: 'Houston',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IA_10630',
    Name: 'Rockwell City',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_UT_10631', Name: 'Naples', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_WV_10632',
    Name: 'Mineralwells',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MI_10633',
    Name: 'Galesburg',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_MO_10634', Name: 'Cabool', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_VA_10635',
    Name: 'Horse Pasture',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_AL_10636', Name: 'Cowarts', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MD_10637',
    Name: 'Spencerville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_OH_10638',
    Name: 'Columbus Grove',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_TX_10639',
    Name: 'Fifth Street',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MT_10640',
    Name: 'Columbus',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_HI_10641', Name: 'Honokaa', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_IL_10642',
    Name: 'Cambridge',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_10643',
    Name: 'Pecatonica',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MN_10644', Name: 'Tracy', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_MO_10645',
    Name: 'Elsberry',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_10646',
    Name: 'Southmont',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_NE_10647', Name: 'Ord', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_UT_10648',
    Name: 'River Heights',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {Id: 'CIT_US_KS_10649', Name: 'Seneca', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_KS_10650', Name: 'Oakley', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_AK_10651',
    Name: 'Fritz Creek',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_OH_10652', Name: 'Coal Grove', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_VT_10653',
    Name: 'Morrisville',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {Id: 'CIT_US_KS_10654', Name: 'Holcomb', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MO_10655',
    Name: 'Weatherby Lake',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MT_10656',
    Name: 'Stevensville',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_OR_10657',
    Name: 'Lincoln Beach',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MO_10658',
    Name: 'New Haven',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_10659', Name: 'Cardington', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_10660', Name: 'Blanco', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_WY_10661', Name: 'Lyman', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_KS_10662', Name: 'Anthony', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_10663',
    Name: 'Topton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_10664',
    Name: 'Kirbyville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NY_10665',
    Name: 'Dolgeville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_10666',
    Name: 'Wellington',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NY_10667',
    Name: 'Seneca Knolls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IA_10668', Name: 'Sac City', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_TN_10669',
    Name: 'Pegram',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_10670',
    Name: 'Robesonia',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_10671',
    Name: 'Conway',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_10672',
    Name: 'Greensburg',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_TX_10673',
    Name: 'Porter Heights',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_10674',
    Name: 'Glen Rock',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_10675',
    Name: 'Fairplains',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TX_10676',
    Name: 'Shadybrook',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OH_10677', Name: 'Drexel', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IA_10678', Name: 'Colfax', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_VA_10679',
    Name: 'Stephens City',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WI_10680',
    Name: 'Pardeeville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_VA_10681',
    Name: 'Marshall',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PR_10682',
    Name: 'Las Marías',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_PA_10683',
    Name: 'Midway',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_10684', Name: 'Midway', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NC_10685',
    Name: 'Cape Carteret',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_10686', Name: 'McCamey', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_10687', Name: 'Olton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_10688',
    Name: 'New Stanton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_10689',
    Name: 'San Ysidro',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_LA_10690',
    Name: 'Simmesport',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WI_10691',
    Name: 'Kohler',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MO_10692',
    Name: 'St. George',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TN_10693',
    Name: 'Jacksboro',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_TX_10694', Name: 'Stratford', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_10695',
    Name: 'Lake Summerset',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_10696',
    Name: 'Kerhonkson',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_FL_10697',
    Name: 'Burnt Store Marina',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_AL_10698', Name: 'Thorsby', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_AL_10699',
    Name: 'Wilsonville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MD_10700',
    Name: 'Chevy Chase Village',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NY_10701',
    Name: 'Bellerose Terrace',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_10702', Name: 'Ganado', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NE_10703',
    Name: 'Imperial',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MT_10704',
    Name: 'Three Forks',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_TX_10705', Name: 'Leonard', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TX_10706',
    Name: 'Hale Center',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_GA_10707',
    Name: 'Buena Vista',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PA_10708',
    Name: 'Jermyn',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_10709', Name: 'Rosemount', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IA_10710', Name: 'Postville', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SC_10711',
    Name: 'Tigerville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_MT_10712', Name: 'Clancy', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_IA_10713', Name: 'Ida Grove', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_ND_10714',
    Name: 'Lisbon',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_TX_10715', Name: 'Yorktown', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MI_10716',
    Name: 'West Branch',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_10717',
    Name: 'Hinckley',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MD_10718',
    Name: 'Snow Hill',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_PA_10719',
    Name: 'McDonald',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_10720',
    Name: 'Bellevue',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OH_10721',
    Name: 'Newburgh Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NY_10722',
    Name: 'Bellport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IN_10723',
    Name: 'Veedersburg',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_HI_10724', Name: 'Lawai', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_VA_10725', Name: 'Raven', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_WI_10726',
    Name: 'St. Croix Falls',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_10727',
    Name: 'West End',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NC_10728',
    Name: 'Cherokee',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MA_10729',
    Name: 'Hanscom AFB',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_TX_10730', Name: 'Troup', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_10731',
    Name: 'Hopwood',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MN_10732', Name: 'Esko', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_GA_10733', Name: 'Pearson', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_SC_10734',
    Name: 'Ninety Six',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NY_10735',
    Name: 'Aquebogue',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_10736',
    Name: 'East McKeesport',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_WY_10737', Name: 'Afton', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_MA_10738',
    Name: 'South Deerfield',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_FL_10739', Name: 'Gotha', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_KS_10740', Name: 'Halstead', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MI_10741',
    Name: 'Lake Odessa',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MD_10742',
    Name: 'North Beach',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_LA_10743', Name: 'Erath', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_TX_10744',
    Name: 'Little River-Academy',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MN_10745',
    Name: 'Mountain Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_FL_10746',
    Name: 'Homosassa',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PR_10747',
    Name: 'Rafael González',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_AL_10748', Name: 'Creola', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_PA_10749',
    Name: 'Fairview-Ferndale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_10750',
    Name: 'Washburn',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OR_10751', Name: 'Banks', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NJ_10752',
    Name: 'Ocean Gate',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_KY_10753',
    Name: 'North Corbin',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_TN_10754',
    Name: 'Estill Springs',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_MI_10755', Name: 'Gwinn', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_NJ_10756',
    Name: 'Seabrook Farms',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OH_10757', Name: 'West Hill', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_10758',
    Name: 'Eddington',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_10759',
    Name: 'Laurel Hollow',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_10760',
    Name: 'Rouse',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_10761',
    Name: 'Flying Hills',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AR_10762',
    Name: 'Star City',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CA_10763',
    Name: 'Bodfish',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_10764',
    Name: 'Sea Isle City',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WI_10765',
    Name: 'Cuba City',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_10766',
    Name: 'Hughesville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MO_10767', Name: 'Kahoka', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_IL_10768',
    Name: 'Oakwood Hills',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_10769',
    Name: 'Jonestown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SD_10770',
    Name: 'Volga',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_PA_10771',
    Name: 'Pennville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_10772',
    Name: 'Woodlands',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_10773',
    Name: 'Rankin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_10774',
    Name: 'Pojoaque',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_10775',
    Name: 'North York',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SD_10776',
    Name: 'Beresford',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_IL_10777',
    Name: 'Lexington',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NM_10778',
    Name: 'Jarales',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MD_10779',
    Name: 'Hurlock',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WV_10780',
    Name: 'Mannington',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_OR_10781',
    Name: 'Columbia City',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_TX_10782', Name: 'Vinton', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_10783', Name: 'Leipsic', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_10784', Name: 'Hamlin', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_10785',
    Name: 'Tyler Run',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OK_10786', Name: 'Kiefer', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NC_10787',
    Name: 'Pine Level',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_10788', Name: 'Kemah', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_LA_10789',
    Name: 'Haynesville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_TN_10790',
    Name: 'East Cleveland',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_FL_10791',
    Name: 'Mangonia Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_10792',
    Name: 'South Temple',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_10793',
    Name: 'Valencia',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_CA_10794',
    Name: 'Easton',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_10795',
    Name: 'Southwest Greensburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_10796',
    Name: 'Jemez Pueblo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_IA_10797', Name: 'Sigourney', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OH_10798', Name: 'Woodville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_10799', Name: 'Troy', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_10800',
    Name: 'Walla Walla East',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_GA_10801', Name: 'Sparks', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NC_10802',
    Name: 'Sharpsburg',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_MI_10803', Name: 'Shelby', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_TX_10804', Name: 'Memphis', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_10805',
    Name: 'Hillsboro Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PR_10806',
    Name: 'El Mangó',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_KS_10807', Name: 'Ellis', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OR_10808', Name: 'Rainier', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_CA_10809',
    Name: 'Seeley',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_10810', Name: 'St. Paris', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MT_10811',
    Name: 'Crow Agency',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MO_10812',
    Name: 'Seymour',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NC_10813',
    Name: 'Hildebran',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MO_10814',
    Name: 'Lathrop',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AL_10815',
    Name: 'Collinsville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_CA_10816',
    Name: 'Auberry',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NV_10817', Name: 'Tonopah', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_NM_10818',
    Name: 'El Valle de Arroyo Seco',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_OH_10819', Name: 'Arcanum', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TX_10820',
    Name: 'Roman Forest',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_KS_10821', Name: 'Kechi', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_TX_10822',
    Name: 'Pelican Bay',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MS_10823',
    Name: 'Eupora',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MN_10824',
    Name: 'Springfield',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_10825', Name: 'Goliad', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OK_10826',
    Name: 'Copeland',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_AL_10827',
    Name: 'Level Plains',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_KY_10828', Name: 'Albany', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_IN_10829', Name: 'Flora', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_MN_10830', Name: 'Eyota', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_WA_10831',
    Name: 'Napavine',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_10832',
    Name: 'Shorewood Hills',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_10833',
    Name: 'Laton',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_10834', Name: 'Girard', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_LA_10835',
    Name: 'Jonesville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_IA_10836', Name: 'Wapello', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OH_10837',
    Name: 'Choctaw Lake',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OH_10838', Name: 'Edgerton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_10839',
    Name: 'Port Allegany',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_10840',
    Name: 'Taft Heights',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OH_10841',
    Name: 'Valley View',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_HI_10842',
    Name: 'Fern Acres',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_PR_10843',
    Name: 'Fránquez',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_OR_10844',
    Name: 'Enterprise',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_PA_10845',
    Name: 'South Greensburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_10846',
    Name: 'Wickerham Manor-Fisher',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CT_10847',
    Name: 'Bethlehem Village',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_AR_10848',
    Name: 'Judsonia',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_SC_10849',
    Name: 'Wedgefield',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_LA_10850', Name: 'Ossun', stateId: 'LA', stateName: 'Louisiana'},
  {Id: 'CIT_US_TX_10851', Name: 'Cooper', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_10852',
    Name: 'Burnham',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AR_10853', Name: 'Salem', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_IN_10854',
    Name: 'Trail Creek',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IL_10855',
    Name: 'South Roxana',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PR_10856',
    Name: 'Martorell',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_TX_10857', Name: 'Preston', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_10858',
    Name: 'Roanoke',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_10859',
    Name: 'Leechburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_10860',
    Name: 'Saugerties South',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_10861',
    Name: 'Bertsch-Oceanview',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MS_10862',
    Name: 'Belmont',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_NM_10863', Name: 'Tome', stateId: 'NM', stateName: 'New Mexico'},
  {
    Id: 'CIT_US_TX_10864',
    Name: 'Beverly Hills',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_10865',
    Name: 'Kenilworth',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AK_10866', Name: 'Willow', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_KY_10867',
    Name: 'Carlisle',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_VA_10868',
    Name: 'Mason Neck',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_TN_10869',
    Name: 'Piperton',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_IN_10870', Name: 'Liberty', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_MN_10871',
    Name: 'Aitkin',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NJ_10872',
    Name: 'Sussex',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IA_10873', Name: 'Lake Mills', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OH_10874', Name: 'Batavia', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MI_10875',
    Name: 'Mattawan',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_10876',
    Name: 'Shavertown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ND_10877',
    Name: 'Carrington',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_IA_10878', Name: 'Ogden', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OH_10879',
    Name: 'South Bloomfield',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IN_10880',
    Name: 'Churubusco',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_CO_10881', Name: 'Hayden', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_AZ_10882',
    Name: 'Springerville',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_IA_10883', Name: 'Epworth', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_10884',
    Name: 'Grill',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_10885',
    Name: 'Yorklyn',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ID_10886',
    Name: 'Aberdeen',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_OR_10887',
    Name: 'Cave Junction',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_HI_10888',
    Name: 'Maunawili',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_SC_10889',
    Name: 'Varnville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_VA_10890',
    Name: 'Moorefield Station',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IN_10891',
    Name: 'Battle Ground',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IL_10892', Name: 'Minonk', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MN_10893',
    Name: 'Slayton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_OH_10894',
    Name: 'Loveland Park',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_LA_10895',
    Name: 'Livingston',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OH_10896', Name: 'Camden', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_10897',
    Name: 'Badin',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_10898',
    Name: 'Lofall',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WA_10899',
    Name: 'Coupeville',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_10900',
    Name: 'Houserville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_10901', Name: 'Arcade', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MN_10902',
    Name: 'Coleraine',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CO_10903',
    Name: 'Perry Park',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_IL_10904',
    Name: 'Hodgkins',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MA_10905',
    Name: 'West Falmouth',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_IA_10906', Name: 'Atkins', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SC_10907',
    Name: 'McColl',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CA_10908',
    Name: 'San Pasqual',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MA_10909',
    Name: 'Devens',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_SD_10910',
    Name: 'Custer',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_UT_10911', Name: 'Monticello', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_OR_10912',
    Name: 'Mill City',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MO_10913',
    Name: 'Carterville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_KS_10914', Name: 'Caney', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_TX_10915',
    Name: 'Monte Alto',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PR_10916',
    Name: 'Coto Laurel',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_OR_10917',
    Name: 'Canyonville',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_TX_10918', Name: 'Somerset', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_10919',
    Name: 'Trexlertown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_10920',
    Name: 'Waretown',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NC_10921',
    Name: 'Yanceyville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_10922', Name: 'Mart', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_10923', Name: 'Sumner', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_KY_10924',
    Name: 'Lebanon Junction',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NJ_10925',
    Name: 'Groveville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_NY_10926', Name: 'Clyde', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_TN_10927',
    Name: 'Mowbray Mountain',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_TX_10928',
    Name: 'Quail Creek',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NE_10929',
    Name: 'Syracuse',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_LA_10930',
    Name: 'Golden Meadow',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_HI_10931', Name: 'Mokuleia', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_TX_10932',
    Name: 'Collinsville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_GA_10933',
    Name: 'Davisboro',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_IN_10934', Name: 'Kouts', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_WA_10935',
    Name: 'Fern Prairie',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_UT_10936', Name: 'Willard', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_OR_10937', Name: 'Harbor', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_PA_10938',
    Name: 'Bowmansville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KS_10939', Name: 'Ogden', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IL_10940',
    Name: 'Carbon Cliff',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OK_10941',
    Name: 'Granite',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_FL_10942',
    Name: 'Harlem Heights',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NY_10943',
    Name: 'Stewart Manor',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OR_10944',
    Name: 'Chenoweth',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_FL_10945',
    Name: 'Ocean Ridge',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IA_10946', Name: 'Northwood', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_10947', Name: 'Britt', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OH_10948', Name: 'Manchester', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_FL_10949',
    Name: 'Silver Lake',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_VA_10950',
    Name: 'Waverly',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_10951',
    Name: 'Quincy',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_CO_10952', Name: 'Limon', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_AL_10953',
    Name: 'Stevenson',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_VA_10954',
    Name: 'Weyers Cave',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_WI_10955', Name: 'Colby', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_TX_10956',
    Name: 'Three Rivers',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MS_10957',
    Name: 'Shelby',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NC_10958',
    Name: 'Cricket',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MT_10959',
    Name: 'Frenchtown',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_WV_10960',
    Name: 'Star City',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_AR_10961',
    Name: 'Augusta',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MN_10962',
    Name: 'Hoyt Lakes',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_MO_10963', Name: 'Sparta', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_MI_10964', Name: 'Baraga', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_AR_10965',
    Name: 'Bull Shoals',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_10966',
    Name: 'Inkerman',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OK_10967', Name: 'Krebs', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_FL_10968',
    Name: 'Heritage Pines',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_KS_10969',
    Name: 'Ellinwood',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_OR_10970', Name: 'Warren', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MI_10971',
    Name: 'Prudenville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_AR_10972', Name: 'Goshen', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_IN_10973',
    Name: 'New Chicago',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_GA_10974', Name: 'Wrens', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_IL_10975',
    Name: 'Prophetstown',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_10976',
    Name: 'Mars Hill',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_10977',
    Name: 'Mount Olive',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_10978',
    Name: 'Brookdale',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_10979',
    Name: 'Lima',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IL_10980', Name: 'Fisher', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_LA_10981',
    Name: 'Zwolle',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_10982',
    Name: 'Shippensburg University',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AZ_10983',
    Name: 'First Mesa',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_IL_10984',
    Name: 'Farmer City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_10985',
    Name: 'Blooming Prairie',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NY_10986',
    Name: 'West Carthage',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_UT_10987', Name: 'Huntington', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_TX_10988', Name: 'Albany', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_10989',
    Name: 'Stockton',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_VA_10990',
    Name: 'Patrick Springs',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_OH_10991', Name: 'Bellville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_10992',
    Name: 'Prairie Grove',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_10993',
    Name: 'North Puyallup',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_AZ_10994', Name: 'Mayer', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_OK_10995',
    Name: 'Haskell',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MS_10996',
    Name: 'Rolling Fork',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OR_10997', Name: 'La Pine', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_KY_10998',
    Name: 'Jenkins',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MS_10999',
    Name: 'Tchula',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IA_11000',
    Name: 'Parkersburg',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_MN_11001',
    Name: 'Hinckley',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OR_11002', Name: 'Durham', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_AR_11003',
    Name: 'Cave City',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NC_11004',
    Name: 'Valley Hill',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IL_11005', Name: 'Catlin', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NC_11006',
    Name: 'Princeville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_11007', Name: 'Sour Lake', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SC_11008',
    Name: "Sullivan's Island",
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NY_11009',
    Name: 'Painted Post',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_11010', Name: 'Italy', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_11011', Name: 'Haslet', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VT_11012',
    Name: 'Manchester Center',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_HI_11013',
    Name: 'Kealakekua',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_SD_11014',
    Name: 'Springfield',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_OR_11015', Name: 'Merlin', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_WA_11016',
    Name: 'Enetai',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_TX_11017', Name: 'Sheldon', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_11018', Name: 'Masury', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_11019',
    Name: 'West Hurley',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CT_11020',
    Name: 'Plainfield Village',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_NV_11021',
    Name: 'Stagecoach',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_MO_11022',
    Name: 'Anderson',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MS_11023',
    Name: 'Nettleton',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MD_11024',
    Name: 'Queensland',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MD_11025',
    Name: 'Kingstown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_LA_11026',
    Name: 'Cecilia',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NY_11027',
    Name: 'Eastport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_11028',
    Name: 'Doniphan',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IN_11029',
    Name: 'Shadeland',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_SC_11030',
    Name: 'Privateer',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NY_11031',
    Name: 'Scottsville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_11032', Name: 'Linden', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_11033', Name: 'Jefferson', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IN_11034',
    Name: 'North Liberty',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NJ_11035',
    Name: 'Englishtown',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_SC_11036',
    Name: 'St. Matthews',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NC_11037',
    Name: 'Oakboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NJ_11038',
    Name: 'Glen Gardner',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NY_11039',
    Name: 'Hampton Manor',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WI_11040',
    Name: 'Ixonia',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_11041',
    Name: 'Rockdale',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_11042',
    Name: 'Georgetown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_11043',
    Name: 'St. Bonaventure',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_11044',
    Name: 'Phoenix',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IL_11045', Name: 'Athens', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_OK_11046',
    Name: 'Chelsea',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NJ_11047',
    Name: 'Hopewell',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_AL_11048', Name: 'Florala', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MT_11049',
    Name: 'Manhattan',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_AL_11050', Name: 'Dora', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_TX_11051',
    Name: 'Wild Peach Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_11052',
    Name: 'Richmond',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_GA_11053',
    Name: 'Unionville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_IA_11054', Name: 'Alta', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_11055',
    Name: 'Camino',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_11056',
    Name: 'Holiday Heights',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_AR_11057', Name: 'Eudora', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_WV_11058',
    Name: 'Belington',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NY_11059',
    Name: 'Atlantic Beach',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AL_11060',
    Name: 'Brundidge',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_SC_11061',
    Name: 'Calhoun Falls',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_TX_11062', Name: 'Buffalo', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_11063',
    Name: 'Alorton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_VA_11064',
    Name: 'Saltville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_OK_11065',
    Name: 'Burns Flat',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_KS_11066',
    Name: 'Minneapolis',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_TN_11067',
    Name: 'Hopewell',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NJ_11068',
    Name: 'Victory Lakes',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_IL_11069',
    Name: 'Albion',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_GA_11070',
    Name: 'Union Point',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_OK_11071', Name: 'Arkoma', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_TX_11072',
    Name: 'Lake Dunlap',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NJ_11073',
    Name: 'Brooklawn',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_TX_11074', Name: 'Charlotte', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MI_11075', Name: 'Leslie', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_IA_11076', Name: 'Audubon', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_AL_11077', Name: 'Slocomb', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_UT_11078',
    Name: 'Marriott-Slaterville',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_PR_11079',
    Name: 'Las Carolinas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MO_11080',
    Name: 'Warson Woods',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PR_11081',
    Name: 'Jobos',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MS_11082',
    Name: 'Union',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MI_11083',
    Name: 'St. Charles',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_AL_11084', Name: 'Ashland', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_AL_11085',
    Name: 'Sylvania',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_IA_11086', Name: 'Monroe', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AR_11087',
    Name: 'McAlmont',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_MT_11088', Name: 'Baker', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_WI_11089', Name: 'Adams', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_FL_11090', Name: 'Pierson', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_GA_11091', Name: 'Trion', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_KY_11092', Name: 'Midway', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_ID_11093',
    Name: 'Priest River',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_LA_11094',
    Name: 'Independence',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MN_11095',
    Name: 'Waterville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AL_11096',
    Name: 'Carbon Hill',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_LA_11097',
    Name: "Bayou L'Ourse",
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_11098',
    Name: 'Leesport',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_UT_11099', Name: 'Enterprise', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_KY_11100',
    Name: 'Hurstbourne Acres',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MD_11101',
    Name: 'Derwood',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_11102',
    Name: 'Hidden Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PR_11103',
    Name: 'Aceitunas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MO_11104',
    Name: 'Piedmont',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TN_11105',
    Name: 'Lookout Mountain',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_MO_11106', Name: 'Steele', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_PA_11107',
    Name: 'Lynnwood-Pricedale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_11108',
    Name: 'Roberts',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_11109',
    Name: 'Graton',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_11110',
    Name: 'East Atlantic Beach',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_11111',
    Name: 'San Augustine',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_11112',
    Name: 'Fairchance',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_11113', Name: 'Gregory', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_11114',
    Name: 'Hammond',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_11115',
    Name: 'East Ithaca',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OK_11116',
    Name: 'Waurika',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_AL_11117',
    Name: 'Lookout Mountain',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_IL_11118',
    Name: 'New Athens',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_11119',
    Name: 'Southside Place',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MI_11120',
    Name: 'Indian River',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_MI_11121', Name: 'Evart', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_WI_11122',
    Name: 'Lake Ripley',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_11123',
    Name: 'Remsenburg-Speonk',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MA_11124',
    Name: 'Monson Center',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_SC_11125',
    Name: 'Blacksburg',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_11126',
    Name: 'Adamstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MO_11127', Name: 'Bernie', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_NY_11128',
    Name: 'Youngstown',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AL_11129', Name: 'Linden', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MD_11130',
    Name: 'Arden on the Severn',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_11131',
    Name: 'Biggs',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KS_11132',
    Name: 'Belleville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_OR_11133', Name: 'Barview', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_IN_11134',
    Name: 'Henryville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_WI_11135',
    Name: 'Spencer',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_VA_11136',
    Name: 'Prince George',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NM_11137',
    Name: 'Berino',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MD_11138',
    Name: 'Greensboro',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_SC_11139',
    Name: 'Great Falls',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_NJ_11140', Name: 'Erma', stateId: 'NJ', stateName: 'New Jersey'},
  {
    Id: 'CIT_US_PA_11141',
    Name: 'Mount Cobb',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_11142',
    Name: 'Amboy',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_11143',
    Name: 'Warm Springs',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TN_11144',
    Name: 'New Johnsonville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_SC_11145',
    Name: 'Slater-Marietta',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OK_11146', Name: 'Hollis', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_OH_11147',
    Name: 'Lake Mohawk',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WY_11148',
    Name: 'Pinedale',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_SD_11149',
    Name: 'Elk Point',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_CO_11150', Name: 'Ault', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_IL_11151',
    Name: 'Somonauk',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PR_11152',
    Name: 'Suárez',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_MI_11153', Name: 'Yale', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_TX_11154', Name: 'Van Horn', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_GA_11155', Name: 'Shannon', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_KS_11156',
    Name: 'Moundridge',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_GA_11157',
    Name: 'Nicholson',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PA_11158',
    Name: 'Devon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_11159',
    Name: 'Gate City',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_NE_11160', Name: 'Gibbon', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_WV_11161',
    Name: 'Daniels',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_AR_11162',
    Name: 'Melbourne',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MI_11163',
    Name: 'New Buffalo',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_GA_11164',
    Name: 'Ellaville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MS_11165',
    Name: 'Sumrall',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_11166',
    Name: 'Conyngham',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_11167',
    Name: 'Lavallette',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_11168',
    Name: 'Laurel Springs',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OH_11169', Name: 'Bradford', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_SC_11170',
    Name: 'Seabrook Island',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_WA_11171',
    Name: 'Tenino',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MD_11172',
    Name: 'Charlotte Hall',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_PA_11173',
    Name: 'West Homestead',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TN_11174',
    Name: 'Lakesite',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_11175',
    Name: 'South Connellsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AR_11176',
    Name: 'Leachville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_IA_11177', Name: 'Durant', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_AK_11178', Name: 'Haines', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_PA_11179',
    Name: 'Elysburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_11180',
    Name: 'Flora',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_MT_11181', Name: 'Malta', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_PA_11182',
    Name: 'Canton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OK_11183',
    Name: 'Langston',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_KY_11184',
    Name: 'Ledbetter',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_11185',
    Name: 'Lakemont',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IL_11186', Name: 'Sesser', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NM_11187',
    Name: 'Moriarty',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_TN_11188',
    Name: 'Blaine',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NE_11189',
    Name: 'Dakota City',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_GA_11190', Name: 'Wadley', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_IL_11191', Name: 'Venice', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MI_11192',
    Name: 'Sylvan Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IN_11193',
    Name: 'Edgewood',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IL_11194',
    Name: 'Merrionette Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_11195',
    Name: 'North Belle Vernon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_11196', Name: 'Gordon', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_AL_11197',
    Name: 'Crossville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_SC_11198',
    Name: 'Estill',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_IA_11199', Name: 'Peosta', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MD_11200',
    Name: 'Point of Rocks',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_PA_11201',
    Name: 'Big Beaver',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_11202',
    Name: 'North Vacherie',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_IA_11203', Name: 'Denver', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_11204',
    Name: 'Foresthill',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WV_11205',
    Name: 'Richwood',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NC_11206',
    Name: 'Drexel',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_AL_11207', Name: 'Samson', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_PA_11208',
    Name: 'Riverside',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_11209',
    Name: 'Plain View',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OH_11210', Name: 'Glenmoor', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IN_11211',
    Name: 'Williamsport',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_VA_11212',
    Name: 'Rivanna',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_TN_11213',
    Name: 'Spring City',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_NE_11214', Name: 'Wilber', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_AL_11215', Name: 'Vernon', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_TX_11216', Name: 'Ingram', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MT_11217', Name: 'Roundup', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_IN_11218',
    Name: 'Wakarusa',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_AL_11219', Name: 'Cordova', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_LA_11220',
    Name: 'Delcambre',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_OH_11221',
    Name: 'Five Points',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NY_11222',
    Name: 'Morrisville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IN_11223', Name: 'Milan', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_AL_11224',
    Name: 'Brookwood',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MO_11225',
    Name: 'Merriam Woods',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_KY_11226',
    Name: 'Doe Valley',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NC_11227',
    Name: 'Andrews',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_11228',
    Name: 'Rolling Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OK_11229', Name: 'Hooker', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NJ_11230',
    Name: 'Alpine',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_KY_11231',
    Name: 'Whitesburg',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_11232',
    Name: 'Eagleview',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MI_11233', Name: "L'Anse", stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_SC_11234',
    Name: 'Langley',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PR_11235',
    Name: 'G. L. García',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_TX_11236', Name: 'Milam', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NC_11237',
    Name: 'Scotland Neck',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_FL_11238',
    Name: 'Dunnellon',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_11239',
    Name: 'St. Lawrence',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_11240', Name: 'Roseville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IA_11241', Name: 'New London', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MD_11242',
    Name: 'Myersville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WY_11243',
    Name: 'Greybull',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {Id: 'CIT_US_IA_11244', Name: 'Greenfield', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MI_11245', Name: 'Capac', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_PR_11246',
    Name: 'Corcovado',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_DE_11247',
    Name: 'Delaware City',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {Id: 'CIT_US_CO_11248', Name: 'Olathe', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_IA_11249',
    Name: 'Columbus Junction',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_IN_11250',
    Name: 'Frankton',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PA_11251',
    Name: 'Trainer',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_11252',
    Name: 'Krugerville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_GA_11253', Name: 'Morgan', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_KS_11254',
    Name: 'Medicine Lodge',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_AL_11255',
    Name: 'Sulligent',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_GA_11256',
    Name: 'Colquitt',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PA_11257',
    Name: 'Lincoln University',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_11258',
    Name: 'Brass Castle',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MO_11259',
    Name: 'Cedar Hill',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MO_11260',
    Name: 'Memphis',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_11261',
    Name: 'Jacobus',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MI_11262', Name: 'Bangor', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MO_11263',
    Name: 'Bloomfield',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TX_11264',
    Name: 'Goldthwaite',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_FL_11265',
    Name: 'Christmas',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_11266', Name: 'Jarrell', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_11267', Name: 'Leetonia', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NJ_11268',
    Name: 'Upper Pohatcong',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_OR_11269',
    Name: 'Clatskanie',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_NJ_11270',
    Name: 'Presidential Lakes Estates',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MI_11271',
    Name: 'Howard City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_AZ_11272',
    Name: 'Cienega Springs',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NY_11273',
    Name: 'Washington Heights',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WY_11274',
    Name: 'Arapahoe',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_NM_11275',
    Name: 'Mesilla',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_NV_11276', Name: 'Lovelock', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_MI_11277',
    Name: 'Parchment',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IN_11278',
    Name: 'Burns Harbor',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MO_11279',
    Name: 'Lake Ozark',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WI_11280',
    Name: 'Campbellsport',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TX_11281',
    Name: 'Fort Hancock',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_HI_11282', Name: 'Pahala', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_TN_11283',
    Name: 'Cross Plains',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NY_11284',
    Name: 'Beaver Dam Lake',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_11285', Name: 'Seth Ward', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_11286',
    Name: 'East Bronson',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_AZ_11287', Name: 'Sacaton', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_MD_11288', Name: 'Lusby', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_RI_11289',
    Name: 'Hope Valley',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_AZ_11290',
    Name: 'Second Mesa',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_ID_11291', Name: 'Wilder', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_IA_11292', Name: 'Leon', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OR_11293',
    Name: 'Brownsville',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_AL_11294',
    Name: 'Cedar Bluff',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_DE_11295', Name: 'Delmar', stateId: 'DE', stateName: 'Delaware'},
  {
    Id: 'CIT_US_GA_11296',
    Name: 'Clarkesville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_WA_11297',
    Name: 'South Wenatchee',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_AR_11298', Name: 'Bay', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_PA_11299',
    Name: 'Susquehanna Trails',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_VA_11300', Name: 'Cana', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_VA_11301',
    Name: 'Lyndhurst',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NM_11302',
    Name: 'Nambe',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MN_11303',
    Name: 'Lakeland',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_11304',
    Name: 'Briarcliff',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_LA_11305',
    Name: 'Bayou Gauche',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_TX_11306',
    Name: 'Gardendale',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OR_11307', Name: 'Vale', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MN_11308',
    Name: 'Clearwater',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MS_11309',
    Name: 'Bruce',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_ID_11310', Name: 'Driggs', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_AZ_11311', Name: 'Pine', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_WA_11312',
    Name: 'Bryant',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MN_11313',
    Name: 'Long Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MN_11314',
    Name: 'Braham',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_FL_11315',
    Name: 'Oriole Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MD_11316',
    Name: 'Oakland',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_TN_11317',
    Name: 'Olivet',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_OH_11318', Name: 'Rossmoyne', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WV_11319',
    Name: 'Prosperity',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IL_11320',
    Name: 'Crystal Lawns',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MO_11321', Name: 'Weston', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_GA_11322',
    Name: 'Brooklet',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_SC_11323',
    Name: 'St. Stephen',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_GA_11324',
    Name: 'Mount Zion',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_IL_11325', Name: 'Hudson', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_SC_11326',
    Name: 'Stateburg',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NM_11327',
    Name: 'Arenas Valley',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_11328',
    Name: 'New Oxford',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_11329', Name: 'Morton', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_11330', Name: 'Sunray', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_11331', Name: 'Navarre', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WY_11332',
    Name: 'South Park',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_AL_11333',
    Name: 'Selmont-West Selmont',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_IN_11334',
    Name: 'Lake Dalecarlia',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MA_11335',
    Name: 'East Pepperell',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_PA_11336',
    Name: 'East Washington',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PR_11337',
    Name: 'Maunabo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_NC_11338',
    Name: 'Hemby Bridge',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MA_11339',
    Name: 'Harwich Port',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_MO_11340',
    Name: 'Campbell',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MO_11341', Name: 'Noel', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_UT_11342', Name: 'Mona', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_CA_11343',
    Name: 'Lone Pine',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_11344',
    Name: 'North Warren',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NE_11345',
    Name: 'Superior',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_AR_11346',
    Name: 'Lepanto',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_TX_11347', Name: 'Ralls', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MA_11348',
    Name: 'Shelburne Falls',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_IL_11349',
    Name: 'Marissa',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_CA_11350', Name: 'Piru', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_MI_11351', Name: 'Lawton', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_WV_11352',
    Name: 'Alum Creek',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_KS_11353',
    Name: 'Plainville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_IA_11354', Name: 'Guttenberg', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OK_11355', Name: 'Inola', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_SC_11356',
    Name: 'Jackson',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OH_11357', Name: 'Elida', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_ND_11358',
    Name: 'Mayville',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NC_11359',
    Name: 'Moravian Falls',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MN_11360',
    Name: 'Kenyon',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OH_11361', Name: 'Plymouth', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_HI_11362', Name: 'Kapaau', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_WA_11363',
    Name: 'Millwood',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_11364',
    Name: 'Durand',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WI_11365',
    Name: 'Crandon',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_OH_11366',
    Name: 'West Liberty',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WI_11367',
    Name: 'Browns Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_UT_11368', Name: 'Lewiston', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_IL_11369', Name: 'Orion', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_TX_11370', Name: 'Grandview', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_11371',
    Name: 'Butler',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OR_11372', Name: 'Lakeside', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NY_11373',
    Name: 'Westhampton Beach',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OR_11374', Name: 'Elgin', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MN_11375',
    Name: 'Cologne',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MA_11376',
    Name: 'Weweantic',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_PA_11377',
    Name: 'Spinnerstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_11378', Name: 'Lithopolis', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_11379',
    Name: 'Yacolt',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MO_11380',
    Name: 'Richland',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_LA_11381',
    Name: 'Henderson',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MN_11382',
    Name: 'Warroad',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NM_11383',
    Name: 'Arroyo Seco',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_VA_11384',
    Name: 'Appomattox',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_OH_11385',
    Name: 'Mount Sterling',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NY_11386',
    Name: 'Village of the Branch',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OK_11387',
    Name: 'Coalgate',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NY_11388',
    Name: "Golden's Bridge",
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OH_11389',
    Name: 'Cridersville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_KY_11390',
    Name: 'Jamestown',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PR_11391',
    Name: 'Buena Vista',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_AR_11392',
    Name: 'Decatur',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NY_11393',
    Name: 'South Floral Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_WI_11394', Name: 'King', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_LA_11395',
    Name: 'Port Sulphur',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_11396',
    Name: 'Beech Mountain Lakes',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_11397',
    Name: 'Cameron',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_11398',
    Name: 'Red Corral',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_LA_11399',
    Name: 'Coushatta',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MN_11400',
    Name: 'Red Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_SC_11401',
    Name: 'Bonneau Beach',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CO_11402',
    Name: 'Idaho Springs',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_TX_11403',
    Name: 'Bartonville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NC_11404',
    Name: 'Sea Breeze',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_VA_11405',
    Name: 'Castlewood',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_LA_11406',
    Name: 'Edgard',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MN_11407',
    Name: 'Spring Park',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_KS_11408', Name: 'Edgerton', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_LA_11409',
    Name: 'Basile',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NJ_11410',
    Name: 'Weston',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_TX_11411',
    Name: 'Las Palmas II',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NJ_11412',
    Name: 'Avon-by-the-Sea',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_TX_11413', Name: 'Evadale', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_11414',
    Name: 'Braddock Hills',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_11415',
    Name: 'Bloomington',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MI_11416',
    Name: 'Tawas City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MN_11417',
    Name: 'Ortonville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_KY_11418',
    Name: 'Sturgis',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_TX_11419', Name: 'Clarendon', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KS_11420',
    Name: 'Wellsville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_AZ_11421', Name: 'Miami', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_MN_11422',
    Name: 'Gilbert',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IN_11423', Name: 'Galena', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_FL_11424', Name: 'Sneads', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_IN_11425',
    Name: 'French Lick',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_TX_11426', Name: 'Bovina', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_11427',
    Name: 'Kearney Park',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IA_11428',
    Name: 'Dallas Center',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_KY_11429',
    Name: 'Orchard Grass Hills',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NJ_11430',
    Name: 'Folsom',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_11431',
    Name: 'Port Carbon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MO_11432', Name: 'Milan', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_KS_11433', Name: 'Marion', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NJ_11434',
    Name: 'Allentown',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_FL_11435',
    Name: 'Wabasso Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AL_11436',
    Name: 'Sardis City',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MS_11437',
    Name: 'Morgantown',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_TN_11438',
    Name: 'Lake City',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WA_11439',
    Name: 'Big Lake',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_AZ_11440', Name: 'Bagdad', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_FL_11441',
    Name: 'Zolfo Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_FL_11442', Name: 'Glencoe', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_WA_11443',
    Name: 'Ravensdale',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PR_11444',
    Name: 'Sabana Hoyos',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_TN_11445',
    Name: 'Loretto',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_CA_11446',
    Name: 'Lagunitas-Forest Knolls',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_11447', Name: 'Stinnett', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MT_11448', Name: 'Forsyth', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_IN_11449',
    Name: 'Southport',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_KS_11450', Name: 'Humboldt', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_SC_11451',
    Name: 'Kiawah Island',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_FL_11452',
    Name: 'Fort Denaud',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_LA_11453', Name: 'Duson', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_CA_11454',
    Name: 'South Taft',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OR_11455',
    Name: 'Cannon Beach',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MI_11456',
    Name: 'Marlette',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NY_11457',
    Name: 'Mountain Lodge Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NJ_11458',
    Name: 'Sea Girt',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_AL_11459', Name: 'Camden', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MD_11460',
    Name: 'Mechanicsville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_KS_11461', Name: 'WaKeeney', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NY_11462',
    Name: 'Lattingtown',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WA_11463',
    Name: 'McCleary',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_TX_11464', Name: 'Shamrock', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_11465',
    Name: 'Shannon',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WI_11466',
    Name: 'Port Edwards',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_11467',
    Name: 'Woodville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_11468',
    Name: 'Boronda',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MI_11469',
    Name: 'Stony Point',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_MI_11470', Name: 'Olivet', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MN_11471',
    Name: 'Silver Bay',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_MO_11472', Name: 'Slater', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_FL_11473',
    Name: 'Anna Maria',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_11474',
    Name: 'Pine Grove',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KS_11475',
    Name: 'North Newton',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_NM_11476',
    Name: 'Santa Clara',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_WI_11477',
    Name: 'Weyauwega',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_11478',
    Name: 'Katonah',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TN_11479',
    Name: 'Surgoinsville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_TX_11480', Name: 'Danbury', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_11481',
    Name: 'Belleville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_11482',
    Name: 'Lincoln Park',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_11483', Name: 'Butler', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_OK_11484', Name: 'Luther', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_KS_11485', Name: 'Elkhart', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OH_11486', Name: 'Pomeroy', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_11487',
    Name: 'East Pittsburgh',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_11488',
    Name: 'Palmyra',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NC_11489',
    Name: 'Salem',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_11490',
    Name: 'South Dos Palos',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_11491',
    Name: 'Dover Beaches North',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PR_11492',
    Name: 'Morovis',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_TX_11493', Name: 'Lorena', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_11494',
    Name: 'Lake Camelot',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PR_11495',
    Name: 'Río Blanco',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_NM_11496',
    Name: 'Questa',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_IN_11497', Name: 'Bourbon', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NY_11498',
    Name: 'Cooperstown',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_KY_11499',
    Name: 'Jeffersonville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_WY_11500', Name: 'Wright', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_ND_11501',
    Name: 'Langdon',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_PA_11502',
    Name: 'Bellwood',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_11503',
    Name: 'Pine Mountain Club',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IN_11504',
    Name: 'Tri-Lakes',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NY_11505',
    Name: 'Central Square',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IA_11506', Name: 'Farley', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WY_11507',
    Name: 'Rafter J Ranch',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_MO_11508',
    Name: 'Gallatin',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WI_11509',
    Name: 'Randolph',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_KY_11510',
    Name: 'Catlettsburg',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OH_11511', Name: 'Lewisburg', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AR_11512',
    Name: 'Calico Rock',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_AL_11513', Name: 'Elberta', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_CA_11514',
    Name: 'Los Molinos',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IN_11515', Name: 'Monon', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_WA_11516',
    Name: 'Ocean Park',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WA_11517',
    Name: 'Davenport',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NY_11518',
    Name: 'Upper Brookville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_11519',
    Name: 'Elizabeth Lake',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_11520',
    Name: 'Osakis',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_MI_11521', Name: 'Mio', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_MD_11522', Name: 'Baden', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_NY_11523',
    Name: 'Middleport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_11524',
    Name: 'Unionville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MI_11525',
    Name: 'Detroit Beach',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_11526',
    Name: 'Hampton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_HI_11527', Name: 'Ko Olina', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_WY_11528',
    Name: 'Fort Washakie',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_SC_11529',
    Name: 'City View',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_WI_11530',
    Name: 'Fontana-on-Geneva Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TN_11531',
    Name: 'Pikeville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_11532',
    Name: 'Forest City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_11533',
    Name: 'Bradley',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IN_11534',
    Name: 'South Whitley',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_WI_11535',
    Name: 'Fall River',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_UT_11536', Name: 'Oakley', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_IL_11537', Name: 'Lacon', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_PA_11538',
    Name: 'Elkland',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_11539',
    Name: 'Le Grand',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AL_11540',
    Name: 'Grove Hill',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_WA_11541',
    Name: 'Monroe North',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_TN_11542',
    Name: 'Gruetli-Laager',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_TX_11543',
    Name: 'Honey Grove',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_11544', Name: 'Buna', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AZ_11545',
    Name: 'Huachuca City',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_PR_11546',
    Name: 'Vázquez',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MA_11547',
    Name: 'Baldwinville',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_UT_11548',
    Name: 'Toquerville',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {Id: 'CIT_US_TX_11549', Name: 'Itasca', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IN_11550', Name: 'Eaton', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_VA_11551', Name: 'Louisa', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_OR_11552', Name: 'Stafford', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NY_11553',
    Name: 'Nissequogue',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MT_11554',
    Name: 'Plentywood',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_NC_11555',
    Name: 'Wanchese',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_AR_11556', Name: 'Rector', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_MI_11557',
    Name: 'Decatur',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NY_11558',
    Name: 'Pine Bush',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_11559',
    Name: 'Baiting Hollow',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TN_11560',
    Name: 'McEwen',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_11561',
    Name: 'Ben Avon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_11562',
    Name: 'West Pasco',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_HI_11563',
    Name: 'Makaha Valley',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_TN_11564',
    Name: 'Whitwell',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PR_11565',
    Name: 'El Tumbao',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_KY_11566',
    Name: 'Pineville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_LA_11567',
    Name: 'Oberlin',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_VA_11568',
    Name: 'Hampden-Sydney',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NJ_11569',
    Name: 'Estell Manor',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_IL_11570',
    Name: 'Kirkland',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_11571',
    Name: 'Mont Alto',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_11572',
    Name: 'Cherryville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_NE_11573', Name: 'Pierce', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_OH_11574', Name: 'Bridgeport', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PR_11575',
    Name: 'Stella',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_IA_11576', Name: 'Stuart', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_11577',
    Name: 'Toro Canyon',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AL_11578', Name: 'Lillian', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MN_11579',
    Name: 'Lester Prairie',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_11580',
    Name: 'Lowry Crossing',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NY_11581',
    Name: 'Merritt Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AL_11582',
    Name: 'Harpersville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NV_11583',
    Name: 'Sandy Valley',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_AL_11584',
    Name: 'Westover',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_SD_11585',
    Name: 'Webster',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_UT_11586',
    Name: 'South Willard',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_CA_11587',
    Name: 'Lost Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_11588',
    Name: 'Fredericksburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_11589',
    Name: 'Town Line',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WV_11590',
    Name: 'Coal City',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MI_11591',
    Name: 'Manitou Beach-Devils Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OR_11592', Name: 'Amity', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_CO_11593',
    Name: 'Crested Butte',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PA_11594',
    Name: 'Dravosburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IL_11595', Name: 'Chenoa', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_CO_11596', Name: 'Akron', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_NC_11597',
    Name: 'Sparta',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NM_11598',
    Name: 'Las Maravillas',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_AR_11599', Name: 'Lamar', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_AL_11600', Name: 'Ragland', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MS_11601',
    Name: 'Wesson',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OH_11602', Name: 'Deshler', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_VA_11603',
    Name: 'Pennington Gap',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_AL_11604', Name: 'Butler', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_TX_11605',
    Name: 'Whitewright',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WA_11606',
    Name: 'Lewisville',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_GA_11607',
    Name: 'Warrenton',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PA_11608',
    Name: 'Reynolds Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_FL_11609', Name: 'Holley', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NY_11610',
    Name: 'Greenvale',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IA_11611',
    Name: 'Prairie City',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_AK_11612',
    Name: 'Prudhoe Bay',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_NY_11613',
    Name: 'Trumansburg',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OH_11614', Name: 'Peebles', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MT_11615',
    Name: 'Sun Prairie',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_IN_11616',
    Name: 'Meridian Hills',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_MI_11617', Name: 'Armada', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_IL_11618',
    Name: 'Moweaqua',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WV_11619',
    Name: 'Stonewood',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_FL_11620',
    Name: 'East Palatka',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_11621',
    Name: 'Redwood Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_11622',
    Name: 'Frewsburg',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_11623',
    Name: 'Stockton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_11624',
    Name: 'Zeigler',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IN_11625',
    Name: 'North Judson',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NC_11626',
    Name: 'Chadbourn',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IL_11627', Name: 'Maroa', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_PA_11628',
    Name: 'Industry',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IN_11629', Name: 'Roanoke', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_MT_11630', Name: 'Choteau', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_UT_11631', Name: 'Duchesne', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_PA_11632',
    Name: 'Evans City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_11633', Name: 'Westlake', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TX_11634',
    Name: 'Hughes Springs',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_FL_11635',
    Name: 'Bear Creek',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NE_11636',
    Name: 'Tekamah',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_HI_11637',
    Name: 'Nanawale Estates',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_MI_11638',
    Name: 'Bessemer',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NY_11639',
    Name: 'Clinton',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MD_11640',
    Name: 'Bartonsville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_KS_11641', Name: 'Syracuse', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NM_11642',
    Name: 'Keeler Farm',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_11643',
    Name: 'Shamokin Dam',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_11644',
    Name: 'Biola',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_11645',
    Name: 'Whiting',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_11646',
    Name: 'Bridgeport',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_11647',
    Name: 'Montverde',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MS_11648',
    Name: 'Decatur',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_SC_11649',
    Name: 'Ridgeville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_LA_11650',
    Name: 'Sorrento',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WA_11651',
    Name: 'Kayak Point',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_TX_11652',
    Name: 'Meadowlakes',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_KY_11653', Name: 'Warsaw', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_SD_11654',
    Name: 'Rosebud',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NC_11655',
    Name: 'Richlands',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_VA_11656',
    Name: 'Chilhowie',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_TX_11657',
    Name: 'Archer City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_GA_11658',
    Name: 'Twin City',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_AZ_11659', Name: 'Scenic', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_OH_11660', Name: 'Fairfax', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MT_11661',
    Name: 'Big Timber',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_TX_11662', Name: 'La Grulla', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_ND_11663',
    Name: 'Oakes',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_KS_11664', Name: 'Oberlin', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IL_11665',
    Name: 'Jonesboro',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MI_11666',
    Name: 'Cassopolis',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SC_11667',
    Name: 'Gaston',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_TX_11668',
    Name: 'Caddo Mills',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_LA_11669',
    Name: 'Labadieville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_11670',
    Name: 'Glen Lyon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_11671',
    Name: 'South Bend',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WV_11672',
    Name: 'McMechen',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MA_11673',
    Name: 'Teaticket',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_VT_11674',
    Name: 'North Bennington',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {Id: 'CIT_US_OH_11675', Name: 'Ripley', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TX_11676',
    Name: 'Bruceville-Eddy',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IN_11677',
    Name: 'Haubstadt',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_FL_11678',
    Name: 'Punta Rassa',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IA_11679',
    Name: 'Pleasantville',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_DE_11680',
    Name: 'Cheswold',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_PR_11681',
    Name: 'El Paraiso',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_WI_11682',
    Name: 'Wind Point',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_11683',
    Name: 'Mayfield',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OK_11684', Name: 'Wilson', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_WV_11685',
    Name: 'Romney',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MN_11686',
    Name: 'Rushford',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PR_11687',
    Name: 'Jauca',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_HI_11688', Name: 'Kualapuu', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_NC_11689',
    Name: 'Pumpkin Center',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IN_11690', Name: 'Bristol', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_MN_11691',
    Name: 'Mapleton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AR_11692',
    Name: 'Mulberry',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CT_11693',
    Name: 'Plantsville',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_NY_11694',
    Name: 'Angola on the Lake',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_VT_11695', Name: 'Wilder', stateId: 'VT', stateName: 'Vermont'},
  {
    Id: 'CIT_US_LA_11696',
    Name: 'White Castle',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_TN_11697',
    Name: 'Spencer',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_LA_11698',
    Name: 'Minorca',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OH_11699', Name: 'Dresden', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_11700',
    Name: 'Fort Montgomery',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_11701',
    Name: 'Heritage Lake',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_11702',
    Name: 'London',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_LA_11703',
    Name: 'Des Allemands',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_KY_11704',
    Name: 'Lewisport',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CA_11705',
    Name: 'Searles Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_11706',
    Name: 'Temple',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AR_11707',
    Name: 'Smackover',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NM_11708',
    Name: 'Flora Vista',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_TN_11709',
    Name: 'Three Way',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_IL_11710',
    Name: 'Bunker Hill',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_AZ_11711', Name: 'Mammoth', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_AZ_11712', Name: 'Bylas', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_NC_11713',
    Name: 'Rocky Point',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MT_11714',
    Name: 'King Arthur Park',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_NM_11715',
    Name: 'Upper Fruitland',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MI_11716',
    Name: 'Brownlee Park',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_AZ_11717',
    Name: 'Tonto Basin',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_MS_11718',
    Name: 'Shaw',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_FL_11719',
    Name: 'Washington Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_KS_11720', Name: 'Horton', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MI_11721',
    Name: 'Nashville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MO_11722',
    Name: 'Hamilton',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_11723', Name: 'Antwerp', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_NY_11724', Name: 'Holley', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_VA_11725',
    Name: 'Shawneeland',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_IL_11726', Name: 'De Pue', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_ME_11727',
    Name: 'Steep Falls',
    stateId: 'ME',
    stateName: 'Maine',
  },
  {
    Id: 'CIT_US_VA_11728',
    Name: 'East Lexington',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PR_11729',
    Name: 'Hato Candal',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_PA_11730',
    Name: 'Mundys Corner',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AR_11731',
    Name: 'Tuckerman',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NJ_11732',
    Name: 'Lake Como',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_TX_11733',
    Name: 'Westwood Shores',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_UT_11734', Name: 'Panguitch', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_CA_11735',
    Name: 'Rancho Tehama Reserve',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AL_11736',
    Name: 'Summerdale',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_CO_11737', Name: 'Kersey', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_OR_11738', Name: 'Culver', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_WV_11739',
    Name: 'MacArthur',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_TX_11740',
    Name: 'South Point',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_KS_11741', Name: 'Oswego', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NC_11742',
    Name: 'Denton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NJ_11743',
    Name: 'Island Heights',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_OH_11744',
    Name: 'Commercial Point',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IL_11745',
    Name: 'Roodhouse',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_11746',
    Name: 'Freeport',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_11747',
    Name: 'Lake Sherwood',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_WI_11748', Name: 'Osseo', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_VA_11749',
    Name: 'Haymarket',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_AZ_11750', Name: 'Peridot', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_MI_11751',
    Name: 'Lake Isabella',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_AZ_11752',
    Name: 'North Fork',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_SC_11753',
    Name: 'Fairfax',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_11754',
    Name: 'Cheswick',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_11755', Name: 'Gunter', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OH_11756',
    Name: 'South Amherst',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WA_11757',
    Name: 'Oroville',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_MO_11758', Name: 'Albany', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_OK_11759',
    Name: 'Kingston',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_TX_11760', Name: 'Pleak', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WV_11761',
    Name: 'Harrisville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WV_11762',
    Name: 'Elkview',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_TX_11763', Name: 'Sabinal', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IN_11764',
    Name: 'Hagerstown',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MS_11765',
    Name: 'Walnut Grove',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WA_11766',
    Name: 'Cascade Valley',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_VA_11767',
    Name: 'Stanley',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_OR_11768', Name: 'John Day', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_IL_11769',
    Name: 'Princeville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_11770', Name: 'West Unity', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PR_11771',
    Name: 'Daguao',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_AZ_11772',
    Name: 'Joseph City',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_MN_11773', Name: 'Canby', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_AL_11774',
    Name: 'Blountsville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_WV_11775',
    Name: 'Ronceverte',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_TX_11776', Name: 'Holliday', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TN_11777',
    Name: 'Bluff City',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NY_11778',
    Name: 'Altamont',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_HI_11779',
    Name: 'Princeville',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_MS_11780',
    Name: 'Calhoun City',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MI_11781',
    Name: 'Wakefield',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SC_11782',
    Name: 'Gadsden',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_IN_11783',
    Name: 'Kentland',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IN_11784', Name: 'Dayton', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_MS_11785',
    Name: 'Columbus AFB',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IL_11786', Name: 'Bement', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_TN_11787',
    Name: 'Decatur',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NC_11788',
    Name: 'Bent Creek',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_11789',
    Name: 'Perryopolis',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_11790',
    Name: 'Tremont',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PR_11791',
    Name: 'Parcelas de Navarro',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_IL_11792',
    Name: 'Knollwood',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_11793',
    Name: 'Uvalde Estates',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WI_11794',
    Name: 'Trempealeau',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_AL_11795', Name: 'Coaling', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_PA_11796',
    Name: 'Cementon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PR_11797',
    Name: 'Playita',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_WA_11798',
    Name: 'Cosmopolis',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IN_11799', Name: 'Arcadia', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_IA_11800', Name: 'Blue Grass', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_GA_11801',
    Name: 'Young Harris',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CT_11802',
    Name: 'Southport',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_IL_11803',
    Name: 'Channel Lake',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AZ_11804',
    Name: 'Blackwater',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WY_11805',
    Name: 'Star Valley Ranch',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_KY_11806',
    Name: 'Munfordville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_TN_11807', Name: 'Cowan', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_OR_11808',
    Name: 'Terrebonne',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MS_11809',
    Name: 'Bay Springs',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_KS_11810', Name: 'Sedgwick', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_KS_11811', Name: 'Douglass', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_KY_11812',
    Name: 'Salyersville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NC_11813',
    Name: 'Murphy',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MI_11814',
    Name: 'South Rockwood',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_AL_11815',
    Name: 'Rehobeth',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MD_11816',
    Name: 'Ridgely',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_AZ_11817', Name: 'Kaibito', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_NY_11818',
    Name: 'Tillson',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_11819',
    Name: 'Muhlenberg Park',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_11820',
    Name: 'Black Rock',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_TX_11821', Name: 'Plains', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_LA_11822', Name: 'Cade', stateId: 'LA', stateName: 'Louisiana'},
  {Id: 'CIT_US_OH_11823', Name: 'Holland', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AL_11824', Name: 'Vance', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_AK_11825',
    Name: 'Metlakatla',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_WA_11826',
    Name: 'Ritzville',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_11827',
    Name: 'Del Rey Oaks',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_11828',
    Name: 'Elk Creek',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IN_11829',
    Name: 'Montpelier',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_TX_11830', Name: 'Juarez', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MN_11831',
    Name: 'Rock Creek',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PR_11832',
    Name: 'La Playa',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_CT_11833',
    Name: 'Canton Valley',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_NM_11834',
    Name: 'Hatch',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MO_11835',
    Name: 'Steelville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AK_11836',
    Name: 'Lazy Mountain',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_PA_11837',
    Name: 'Church Hill',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_11838', Name: 'Stockdale', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IN_11839',
    Name: 'Daleville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IN_11840', Name: 'Vevay', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_OK_11841', Name: 'Minco', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NC_11842',
    Name: 'Burnsville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OH_11843', Name: 'Weston', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_11844',
    Name: 'Edgeworth',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_11845',
    Name: 'Greilickville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MS_11846',
    Name: 'Duck Hill',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_ND_11847',
    Name: 'Harvey',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MI_11848',
    Name: 'Watervliet',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MN_11849',
    Name: 'Dundas',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PR_11850',
    Name: 'Pole Ojea',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_NJ_11851',
    Name: 'Annandale',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_MI_11852', Name: 'Homer', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_OK_11853',
    Name: 'Carnegie',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_TX_11854', Name: 'Muenster', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_11855', Name: 'Franklin', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_11856',
    Name: 'Westwood',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_11857', Name: 'Honeyville', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NC_11858',
    Name: 'Connelly Springs',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_GA_11859',
    Name: 'Richland',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_IN_11860',
    Name: 'Jonesboro',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OH_11861', Name: 'McArthur', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IA_11862', Name: 'Lake City', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_11863',
    Name: 'Teutopolis',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_KS_11864', Name: 'Westwood', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WI_11865',
    Name: 'Spring Green',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NM_11866',
    Name: 'Thoreau',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_11867',
    Name: 'Intercourse',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_11868',
    Name: 'Woodburn',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NY_11869',
    Name: 'Houghton',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_11870',
    Name: 'Duquesne',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_11871',
    Name: 'Los Ranchos',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_11872',
    Name: 'Garden City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_11873', Name: 'McComb', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WV_11874',
    Name: 'Boaz',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IN_11875',
    Name: 'Hudson Lake',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_VA_11876', Name: 'Dayton', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_MI_11877',
    Name: 'Caledonia',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MD_11878',
    Name: 'Landover Hills',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WA_11879',
    Name: 'Kettle Falls',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IA_11880', Name: 'Moville', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_TN_11881',
    Name: 'Charlotte',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_11882',
    Name: 'New Berlinville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_11883', Name: 'Walcott', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_11884', Name: 'Pocahontas', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AL_11885',
    Name: 'Stewartville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_OR_11886', Name: 'Gearhart', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_FL_11887',
    Name: 'Buckhead Ridge',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_11888',
    Name: 'Los Alamos',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_11889',
    Name: 'Bayou Vista',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_SC_11890',
    Name: 'Chapin',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_AL_11891', Name: 'Marbury', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_WA_11892',
    Name: 'Wilderness Rim',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_AZ_11893',
    Name: 'Congress',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_FL_11894',
    Name: 'Boulevard Gardens',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MI_11895',
    Name: 'Sebewaing',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NY_11896',
    Name: 'Water Mill',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WA_11897',
    Name: 'Nooksack',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_TX_11898', Name: 'Lockney', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_NY_11899', Name: 'Dundee', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_MA_11900',
    Name: 'South Lancaster',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_CT_11901',
    Name: 'Georgetown',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_NV_11902',
    Name: 'Topaz Ranch Estates',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_AZ_11903',
    Name: 'Canyon Day',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_TX_11904',
    Name: 'Pinewood Estates',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_11905',
    Name: 'Shiremanstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_11906',
    Name: 'East Bangor',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NV_11907',
    Name: 'Smith Valley',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_VA_11908',
    Name: 'Victoria',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_ID_11909', Name: 'Pinehurst', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_MN_11910',
    Name: 'Aurora',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IA_11911', Name: 'Reinbeck', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OH_11912', Name: 'Perry', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_11913',
    Name: 'Penn State Erie',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IN_11914', Name: 'Argos', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_LA_11915',
    Name: 'St. Francisville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MI_11916',
    Name: 'Kingsley',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PR_11917',
    Name: 'San Antonio',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_TX_11918', Name: 'Marfa', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NE_11919',
    Name: 'Springfield',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_ND_11920',
    Name: 'Hillsboro',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_VA_11921',
    Name: 'Gordonsville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NM_11922',
    Name: 'Waterflow',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_TN_11923',
    Name: 'Ridgely',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NE_11924',
    Name: 'Mitchell',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_IN_11925', Name: 'Harlan', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PR_11926',
    Name: 'Jagual',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_CA_11927',
    Name: 'Black Point-Green Point',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AK_11928', Name: 'Ester', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_PR_11929',
    Name: 'Luis M. Cintrón',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_CA_11930',
    Name: 'Temelec',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_11931', Name: 'Wellington', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MT_11932',
    Name: 'West Glendive',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_NE_11933',
    Name: 'Ainsworth',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_TX_11934',
    Name: 'Siesta Acres',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_SC_11935',
    Name: 'Wilkinson Heights',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_IA_11936', Name: 'Mount Ayr', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NC_11937',
    Name: 'Pinebluff',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OH_11938',
    Name: 'South Charleston',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NC_11939',
    Name: 'Rose Hill',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NJ_11940',
    Name: 'Chesilhurst',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IA_11941', Name: 'Remsen', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MI_11942', Name: 'Quincy', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_AR_11943',
    Name: 'Kensett',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NM_11944',
    Name: 'Columbus',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_AL_11945', Name: 'Concord', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_IL_11946',
    Name: 'Delavan',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_11947',
    Name: 'Key Vista',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MN_11948',
    Name: 'Lakefield',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NC_11949',
    Name: 'Tryon',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MI_11950',
    Name: 'Elk Rapids',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WY_11951',
    Name: 'Saratoga',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {Id: 'CIT_US_AL_11952', Name: 'Gordo', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_MI_11953', Name: 'Ovid', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_NC_11954',
    Name: 'Bladenboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PR_11955',
    Name: 'El Negro',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_OH_11956', Name: 'Sawyerwood', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_FL_11957',
    Name: 'Bokeelia',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_SD_11958',
    Name: 'Oglala',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_TX_11959', Name: 'Crosbyton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MA_11960',
    Name: 'North Eastham',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_PA_11961',
    Name: 'Richland',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_11962',
    Name: 'Mars',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_11963',
    Name: 'Fox Chase',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_11964', Name: 'Corrigan', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_WI_11965', Name: 'Thorp', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_MO_11966', Name: 'Senath', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_TX_11967',
    Name: 'Lake Bryan',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NC_11968',
    Name: 'Bethel',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_ME_11969',
    Name: 'Falmouth Foreside',
    stateId: 'ME',
    stateName: 'Maine',
  },
  {
    Id: 'CIT_US_LA_11970',
    Name: 'Maurice',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NM_11971',
    Name: 'Spencerville',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MS_11972',
    Name: 'Drew',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OH_11973', Name: 'Ashley', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_11974',
    Name: 'Mill Hall',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_11975',
    Name: 'Georgiana',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NM_11976',
    Name: 'La Huerta',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IL_11977',
    Name: 'Port Byron',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_11978',
    Name: 'Ladera',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IN_11979',
    Name: 'Fish Lake',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AL_11980',
    Name: 'Ballplay',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_OH_11981',
    Name: 'Aberdeen',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NY_11982',
    Name: 'Montour Falls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_11983',
    Name: 'Peach Lake',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OR_11984',
    Name: 'Grand Ronde',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_FL_11985',
    Name: 'Belleair Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WA_11986',
    Name: 'Soap Lake',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MN_11987',
    Name: 'Waverly',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IL_11988', Name: 'Neoga', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_OH_11989',
    Name: 'Leavittsburg',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_11990',
    Name: 'Towamensing Trails',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_11991',
    Name: 'Espy',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_11992',
    Name: 'Stevenson',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_11993',
    Name: 'Trevorton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_11994', Name: 'Corydon', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NM_11995',
    Name: 'Monterey Park',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IL_11996',
    Name: 'Atlanta',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MT_11997',
    Name: 'South Browning',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_PR_11998',
    Name: 'Naranjito',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_ND_11999',
    Name: 'Bowman',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NC_12000',
    Name: 'Saxapahaw',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_SC_12001',
    Name: 'Eureka Mill',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_MO_12002', Name: 'Adrian', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_IN_12003',
    Name: 'Brooklyn',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_GA_12004', Name: 'Emerson', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WI_12005',
    Name: 'Galesville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_UT_12006', Name: 'Coalville', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_AZ_12007',
    Name: 'Spring Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WA_12008',
    Name: 'Rocky Point',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IA_12009', Name: 'Traer', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AR_12010',
    Name: 'Monette',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_KY_12011',
    Name: 'Edmonton',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_FL_12012', Name: 'Astor', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_LA_12013',
    Name: 'Bernice',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OH_12014', Name: 'Orwell', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_12015', Name: 'Lakeside', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TX_12016',
    Name: 'Shoreacres',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_12017', Name: 'De Kalb', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_12018',
    Name: 'Calumet',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MO_12019', Name: 'Linn', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_OR_12020', Name: 'New Hope', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_UT_12021',
    Name: 'Emigration Canyon',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_WI_12022',
    Name: 'Eagle River',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_12023',
    Name: 'Mayville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_FL_12024', Name: 'Midway', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_12025',
    Name: 'Del Rio',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_12026',
    Name: 'Crooked Lake Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IN_12027',
    Name: 'Aberdeen',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PA_12028',
    Name: 'Cressona',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OH_12029',
    Name: 'Mechanicsburg',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_UT_12030',
    Name: 'Woodland Hills',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_WI_12031',
    Name: 'Darien',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OK_12032', Name: 'Warner', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_MN_12033', Name: 'Avon', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_TX_12034', Name: 'Natalia', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_12035', Name: 'Nashua', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_12036',
    Name: 'Shelbina',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PR_12037',
    Name: 'Palomas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_NE_12038',
    Name: 'Woodland Park',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_IL_12039', Name: 'Jerome', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_12040', Name: 'Union City', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_LA_12041',
    Name: 'Lockport Heights',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_12042',
    Name: 'Homer City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_12043',
    Name: 'Rosedale',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OK_12044', Name: 'McCord', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_PA_12045',
    Name: 'West Milton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_12046',
    Name: 'Eleanor',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IL_12047',
    Name: 'Earlville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_12048', Name: 'Kalida', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MI_12049',
    Name: 'Harbor Beach',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NE_12050',
    Name: 'Tecumseh',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_AR_12051',
    Name: 'Murfreesboro',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NY_12052',
    Name: 'Sparkill',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_LA_12053',
    Name: 'Paradis',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NE_12054',
    Name: 'Albion',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_IN_12055', Name: 'Osgood', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_CA_12056',
    Name: 'Coarsegold',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_12057',
    Name: 'Clark Mills',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_12058',
    Name: 'Rollingwood',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NC_12059',
    Name: 'Kenly',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_12060',
    Name: 'Prestbury',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_12061',
    Name: 'Tuolumne City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_12062',
    Name: 'Bourbon',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AR_12063',
    Name: 'Des Arc',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MS_12064',
    Name: 'Sardis',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IN_12065',
    Name: 'Hamilton',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PA_12066',
    Name: 'Langhorne',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_12067',
    Name: 'Sturgeon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TN_12068',
    Name: 'Graysville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NC_12069',
    Name: 'Belhaven',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_12070', Name: 'Eldorado', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_12071',
    Name: 'Oakland',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_12072',
    Name: 'Foscoe',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TX_12073',
    Name: 'Brookside Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WI_12074',
    Name: 'Stratford',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MO_12075',
    Name: 'Hallsville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_AZ_12076', Name: 'Morenci', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_OH_12077',
    Name: 'East Canton',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_TX_12078',
    Name: 'Runaway Bay',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_TN_12079',
    Name: 'Banner Hill',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_HI_12080',
    Name: 'Leilani Estates',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_WV_12081',
    Name: 'Wayne',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WI_12082',
    Name: 'River Hills',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_GA_12083',
    Name: 'Lookout Mountain',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_UT_12084', Name: 'Francis', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_IA_12085', Name: 'Hartley', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_VA_12086',
    Name: 'Central Garage',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_12087', Name: 'Alvord', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KY_12088',
    Name: 'Owingsville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IN_12089',
    Name: 'Thorntown',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_VA_12090', Name: 'Adwolf', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_CA_12091',
    Name: 'Shasta',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AZ_12092',
    Name: 'Pirtleville',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WI_12093',
    Name: 'Whitehall',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IN_12094',
    Name: 'Warren Park',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OH_12095', Name: 'New Paris', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_12096',
    Name: 'Mount Carroll',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_12097',
    Name: 'Leona Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MI_12098',
    Name: 'Wacousta',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NM_12099',
    Name: 'Estancia',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IL_12100',
    Name: 'Southern View',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_KS_12101',
    Name: 'Smith Center',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_MS_12102',
    Name: 'Summit',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_KY_12103',
    Name: 'Pewee Valley',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_TX_12104', Name: 'Blossom', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_12105',
    Name: 'East Dubuque',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_12106',
    Name: 'Tonka Bay',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CO_12107',
    Name: 'Del Norte',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CA_12108',
    Name: 'Descanso',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_12109', Name: 'Genola', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_PA_12110',
    Name: 'West Lawn',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CO_12111',
    Name: 'Inverness',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_WV_12112',
    Name: 'Fairlea',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NM_12113',
    Name: 'Sausal',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_IA_12114', Name: 'Manson', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CO_12115',
    Name: 'Elizabeth',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CT_12116',
    Name: 'Chester Center',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_IN_12117', Name: 'Milford', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_WI_12118',
    Name: 'Random Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_12119',
    Name: 'Grayville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_GA_12120', Name: 'Chester', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NC_12121',
    Name: 'Toast',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MI_12122',
    Name: 'Union City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_12123',
    Name: 'Dunsmuir',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MN_12124', Name: 'Ada', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_OK_12125',
    Name: 'Crescent',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_AZ_12126', Name: 'LeChee', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_PA_12127',
    Name: 'Baidland',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_12128',
    Name: 'Norwood',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_12129',
    Name: 'Wardsville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OK_12130',
    Name: 'Comanche',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_OH_12131',
    Name: 'Franklin Furnace',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WI_12132',
    Name: 'Sharon',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_KY_12133',
    Name: 'Olive Hill',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_KS_12134',
    Name: 'Belle Plaine',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_WA_12135',
    Name: 'Ames Lake',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_MI_12136', Name: 'Manton', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_ID_12137',
    Name: 'New Plymouth',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_NY_12138',
    Name: 'Ransomville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_LA_12139',
    Name: 'Oak Grove',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_TN_12140',
    Name: 'Vonore',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_CT_12141',
    Name: 'Woodmont',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_IL_12142',
    Name: 'Carrier Mills',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_AL_12143', Name: 'Reform', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_UT_12144', Name: 'Moroni', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MN_12145',
    Name: 'Warren',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WA_12146',
    Name: 'McMillin',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_OK_12147',
    Name: 'Westville',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NC_12148',
    Name: 'East Spencer',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MI_12149',
    Name: 'Schoolcraft',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MO_12150',
    Name: 'Sarcoxie',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NY_12151',
    Name: 'Bay Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OH_12152',
    Name: 'New Middletown',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PR_12153',
    Name: 'Duque',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_KY_12154',
    Name: 'Worthington Hills',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_DE_12155',
    Name: 'Wyoming',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_MI_12156',
    Name: 'Montrose',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NJ_12157',
    Name: 'Cedar Glen Lakes',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NC_12158',
    Name: 'Stanfield',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_12159',
    Name: 'Bressler',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_12160',
    Name: 'Shawsville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_SD_12161',
    Name: 'Wagner',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_CA_12162',
    Name: 'Linden',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_12163',
    Name: 'Lewiston',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_SC_12164',
    Name: 'Judson',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_TN_12165', Name: 'Dover', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_DE_12166',
    Name: 'Rehoboth Beach',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_PA_12167',
    Name: 'Paxtang',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_12168',
    Name: 'Crothersville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PR_12169',
    Name: 'Miranda',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_PA_12170',
    Name: 'East Berlin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OR_12171', Name: 'Hines', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MI_12172',
    Name: 'Three Oaks',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IN_12173',
    Name: 'Rossville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IL_12174',
    Name: 'North Pekin',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_12175',
    Name: 'Nescopeck',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_12176',
    Name: 'New Fairview',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WI_12177',
    Name: 'Niagara',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_VA_12178',
    Name: 'Chatmoss',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_12179',
    Name: 'Tipton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_12180',
    Name: 'Seaside Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NV_12181',
    Name: 'East Valley',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_NJ_12182',
    Name: 'Newfield',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MO_12183',
    Name: 'Hillsdale',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MT_12184',
    Name: 'Fort Belknap Agency',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_PA_12185',
    Name: 'Roseto',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_12186',
    Name: 'Rosepine',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NC_12187',
    Name: 'Dobson',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_SC_12188',
    Name: 'Fairforest',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NJ_12189',
    Name: 'Collings Lakes',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_TN_12190',
    Name: 'Chapel Hill',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_LA_12191',
    Name: 'Logansport',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_OH_12192',
    Name: 'Pleasant Grove',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_12193',
    Name: 'Rheems',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_12194',
    Name: 'Neuse Forest',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_AK_12195',
    Name: 'Kodiak Station',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_PR_12196',
    Name: 'Ramos',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_NY_12197',
    Name: 'Fort Covington Hamlet',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_MI_12198', Name: 'Harvey', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_WA_12199',
    Name: 'Sumas',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_12200',
    Name: 'Gallitzin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TN_12201',
    Name: 'Englewood',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_12202',
    Name: 'Hasson Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_12203',
    Name: 'Owenton',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_FL_12204',
    Name: 'Patrick AFB',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_VA_12205',
    Name: 'Appalachia',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NC_12206',
    Name: 'Walnut Cove',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MS_12207',
    Name: 'Coldwater',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CO_12208',
    Name: 'Nederland',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_IN_12209',
    Name: 'Brookston',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PA_12210',
    Name: 'Mercersburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_12211',
    Name: 'Passapatanzy',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_KS_12212',
    Name: 'McConnell AFB',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_TN_12213', Name: 'Mason', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_TN_12214',
    Name: 'Baxter',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_KY_12215', Name: 'Sebree', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_PA_12216',
    Name: 'Smethport',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_RI_12217',
    Name: 'Chepachet',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_MD_12218',
    Name: 'Crownsville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MO_12219',
    Name: 'Fair Grove',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MI_12220',
    Name: 'Zilwaukee',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CT_12221',
    Name: 'Tariffville',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_MD_12222',
    Name: 'Hancock',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NH_12223',
    Name: 'Contoocook',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_KS_12224',
    Name: 'Grandview Plaza',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_MI_12225',
    Name: 'White Pigeon',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NC_12226',
    Name: 'Stony Point',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_AZ_12227', Name: 'Mescal', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_TX_12228', Name: 'Bangs', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_12229',
    Name: 'Crugers',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_WY_12230', Name: 'Lusk', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_TX_12231', Name: 'Edgewood', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_12232',
    Name: 'Augusta',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_12233',
    Name: 'Port Dickinson',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IL_12234', Name: 'Rome', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_OK_12235',
    Name: 'Stratford',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MN_12236',
    Name: 'Oronoco',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_12237',
    Name: 'Big Pine',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CO_12238',
    Name: 'Kremmling',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_TX_12239', Name: 'Quinlan', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_12240',
    Name: 'McConnellstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_12241',
    Name: 'Lake Caroline',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_TN_12242',
    Name: 'Watertown',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_TX_12243', Name: 'Kerens', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_12244',
    Name: 'Salisbury',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_12245',
    Name: 'Apollo',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KS_12246', Name: 'Meade', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_OH_12247',
    Name: 'Fort Loramie',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MD_12248',
    Name: 'Fairmount Heights',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WI_12249',
    Name: 'Lac du Flambeau',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WV_12250',
    Name: 'Salem',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IL_12251',
    Name: 'Limestone',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_12252',
    Name: 'Hawthorne',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_12253', Name: 'Oak Hill', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_NE_12254', Name: 'Gordon', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_CT_12255',
    Name: 'East Brooklyn',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_IA_12256', Name: 'Avoca', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_TX_12257',
    Name: 'Seven Points',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NE_12258',
    Name: 'Bennington',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_CA_12259',
    Name: 'Home Garden',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VA_12260',
    Name: 'Plum Creek',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_OH_12261',
    Name: 'Powhatan Point',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OH_12262', Name: 'Shreve', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_KY_12263',
    Name: 'Coal Run Village',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OH_12264', Name: 'Anna', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WV_12265',
    Name: 'Rainelle',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_12266',
    Name: 'Bainbridge',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_12267',
    Name: 'Dewart',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_12268',
    Name: 'Nellieburg',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_TX_12269', Name: 'Boyd', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MN_12270', Name: 'Stacy', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_HI_12271', Name: 'Pepeekeo', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_TX_12272',
    Name: 'Laguna Park',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NY_12273',
    Name: 'Waterville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IA_12274', Name: 'Mediapolis', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_TX_12275',
    Name: 'Cross Roads',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NY_12276',
    Name: 'Rock Hill',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_12277', Name: 'Meridian', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_12278',
    Name: 'Dallesport',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_TX_12279', Name: 'Bells', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_12280', Name: 'Sebastian', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_12281',
    Name: 'Claycomo',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AR_12282',
    Name: 'Redfield',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_FL_12283',
    Name: 'Roseland',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_12284', Name: 'Miamitown', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MI_12285',
    Name: 'Twin Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_ME_12286', Name: 'Chisholm', stateId: 'ME', stateName: 'Maine'},
  {
    Id: 'CIT_US_MO_12287',
    Name: 'Winchester',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_12288',
    Name: 'Willow Creek',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WV_12289',
    Name: 'Nutter Fort',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NC_12290',
    Name: 'Atlantic Beach',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_12291',
    Name: 'Hilldale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_12292', Name: 'Aurora', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_12293',
    Name: 'Chualar',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_12294',
    Name: 'Snow Hill',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OH_12295', Name: 'West Salem', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OH_12296',
    Name: 'Kings Mills',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IN_12297',
    Name: 'Bass Lake',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_OH_12298',
    Name: 'Harbor Hills',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_12299',
    Name: 'Russell',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OR_12300',
    Name: 'Pilot Rock',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_NC_12301',
    Name: 'Northlakes',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_12302',
    Name: 'University of Pittsburgh Johnstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_12303', Name: 'Granger', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AL_12304',
    Name: 'Rock Creek',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_WI_12305',
    Name: 'Marathon City',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MO_12306',
    Name: 'Huntsville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MS_12307',
    Name: 'Collinsville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_OH_12308',
    Name: 'Granville South',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IL_12309',
    Name: 'Bannockburn',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_12310', Name: 'Granger', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KY_12311',
    Name: 'Worthington',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_12312',
    Name: 'Highland Park',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PR_12313',
    Name: 'Mora',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_IL_12314',
    Name: 'Herscher',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IN_12315',
    Name: 'Monrovia',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_GA_12316',
    Name: 'Lincolnton',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_OH_12317', Name: 'Lucasville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_12318',
    Name: 'Buxton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IN_12319',
    Name: 'New Paris',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_LA_12320',
    Name: 'Clinton',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NE_12321',
    Name: 'Stanton',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IL_12322',
    Name: 'De Soto',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_ID_12323', Name: 'Shoshone', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_NE_12324', Name: 'Neligh', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_IA_12325', Name: 'Oakland', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_LA_12326',
    Name: 'Charenton',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WV_12327',
    Name: 'Terra Alta',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IL_12328',
    Name: 'Waterman',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OR_12329',
    Name: 'Depoe Bay',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MO_12330',
    Name: 'LaBarque Creek',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IL_12331', Name: 'Erie', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MO_12332',
    Name: 'Winfield',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CO_12333',
    Name: 'Columbine Valley',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PA_12334',
    Name: 'Gold Key Lake',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_12335',
    Name: 'Orfordville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IA_12336', Name: 'Ackley', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_GA_12337', Name: 'Russell', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_IL_12338',
    Name: 'East Hazel Crest',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AZ_12339',
    Name: 'Lukachukai',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_KY_12340',
    Name: 'St. Regis Park',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PR_12341',
    Name: 'Toa Baja',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_WV_12342',
    Name: 'Rand',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WA_12343',
    Name: 'Kittitas',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_OH_12344',
    Name: 'Brooklyn Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_FL_12345',
    Name: 'Lake Hamilton',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_AZ_12346', Name: 'Parks', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_WA_12347',
    Name: 'Long Beach',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PR_12348',
    Name: 'Rafael Capó',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_AL_12349',
    Name: 'Mount Vernon',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MS_12350',
    Name: 'Marion',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_12351',
    Name: 'Ferndale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_UT_12352', Name: 'Ferron', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_UT_12353',
    Name: 'Centerfield',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {Id: 'CIT_US_TX_12354', Name: 'Booker', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_12355',
    Name: 'Elizabethville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_12356', Name: 'Urbana', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IA_12357',
    Name: 'Guthrie Center',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_MI_12358',
    Name: 'Rapid City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_TX_12359',
    Name: 'Copper Canyon',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_12360', Name: 'Seadrift', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AL_12361',
    Name: 'Sylvan Springs',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PR_12362',
    Name: 'Cacao',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_IN_12363', Name: 'Dale', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_MD_12364',
    Name: 'Edmonston',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_IL_12365',
    Name: 'Greenup',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_12366',
    Name: 'Avis',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_12367',
    Name: 'Blue Ridge',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_SC_12368',
    Name: 'Murphys Estates',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_KY_12369',
    Name: 'Audubon Park',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_FL_12370', Name: 'Baldwin', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_PA_12371',
    Name: 'Duncannon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_UT_12372', Name: 'Wendover', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_OK_12373',
    Name: 'Cherokee',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_CA_12374',
    Name: 'Colma',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AR_12375',
    Name: 'McCrory',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_IL_12376',
    Name: 'Port Barrington',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MI_12377',
    Name: 'Au Sable',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_TX_12378', Name: 'Fulton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_ID_12379',
    Name: 'Sun Valley',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_MI_12380',
    Name: 'Shepherd',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_TX_12381', Name: 'Baird', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_12382',
    Name: 'Legend Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_KY_12383',
    Name: 'Pine Knot',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MT_12384',
    Name: 'Bonner-West Riverside',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_IL_12385', Name: 'Manito', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_VA_12386', Name: 'Warsaw', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_WY_12387', Name: 'Ethete', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_FL_12388',
    Name: 'Lacoochee',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_12389',
    Name: 'Oakwood',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_12390',
    Name: 'Windsor',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_12391',
    Name: 'Williamsville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SC_12392',
    Name: 'Johnsonville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CT_12393',
    Name: 'North Granby',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_TX_12394', Name: 'Oak Leaf', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PR_12395',
    Name: 'La Alianza',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_CT_12396',
    Name: 'Quinebaug',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_MN_12397',
    Name: 'Babbitt',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OH_12398', Name: 'Lynchburg', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_12399',
    Name: 'Glen Alpine',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_12400',
    Name: 'Blossburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_12401', Name: 'Lone Star', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OK_12402',
    Name: 'Justice',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_IN_12403',
    Name: 'Cannelton',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IL_12404', Name: 'Warsaw', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_TX_12405',
    Name: 'Wolfe City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_FL_12406', Name: 'Paisley', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NV_12407',
    Name: 'Bunkerville',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_CA_12408',
    Name: 'Airport',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MO_12409', Name: 'Belle', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_OH_12410', Name: 'Brilliant', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_GA_12411',
    Name: 'Porterdale',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MO_12412',
    Name: 'Bismarck',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_12413',
    Name: 'Pine Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SD_12414',
    Name: 'Parkston',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_PA_12415',
    Name: 'Montrose',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_12416', Name: 'Wheeler', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_WA_12417', Name: 'Home', stateId: 'WA', stateName: 'Washington'},
  {Id: 'CIT_US_AL_12418', Name: 'Webb', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_PA_12419',
    Name: 'Elizabeth',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_12420', Name: 'Bertram', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_12421',
    Name: 'North Redington Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MI_12422',
    Name: 'Birch Run',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NM_12423',
    Name: 'Sandia Knolls',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_FL_12424',
    Name: 'San Antonio',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_SD_12425',
    Name: 'Groton',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_PR_12426',
    Name: 'Bartolo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MD_12427',
    Name: 'Pittsville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NC_12428',
    Name: 'Cordova',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_12429',
    Name: 'Green Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NE_12430', Name: 'Hebron', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_GA_12431',
    Name: 'Talahi Island',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PA_12432',
    Name: 'Freedom',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_12433',
    Name: 'Monticello',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NE_12434',
    Name: 'Bridgeport',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_CA_12435',
    Name: 'Lebec',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_12436', Name: 'Monona', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_FL_12437',
    Name: 'Interlachen',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_FL_12438',
    Name: 'Redington Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_TN_12439',
    Name: 'Bon Aqua Junction',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MN_12440',
    Name: 'Richmond',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NJ_12441',
    Name: 'Victory Gardens',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_12442',
    Name: 'Navesink',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NC_12443',
    Name: 'Weldon',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_FL_12444',
    Name: 'South Palm Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_SD_12445',
    Name: 'Porcupine',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_CO_12446', Name: 'Paonia', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_SC_12447',
    Name: 'Mayo',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_FL_12448',
    Name: 'Indian Shores',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_UT_12449', Name: 'Benson', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_KS_12450', Name: 'Colwich', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IL_12451',
    Name: 'Winchester',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TN_12452',
    Name: 'Rockvale',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_HI_12453', Name: 'Omao', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_NM_12454',
    Name: 'Radium Springs',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NY_12455',
    Name: 'Plandome',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_12456',
    Name: 'Monument Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_12457',
    Name: 'Catawissa',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_12458', Name: 'La Pryor', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_12459',
    Name: 'Montello',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_AR_12460', Name: 'Stamps', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_WI_12461',
    Name: 'Brooklyn',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PR_12462',
    Name: 'Central Aguirre',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_TX_12463',
    Name: 'Somerville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_OH_12464',
    Name: 'North Lewisburg',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WI_12465',
    Name: 'Big Bend',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_OH_12466',
    Name: 'Jackson Center',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MO_12467',
    Name: 'Marble Hill',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_KY_12468',
    Name: 'Burkesville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IL_12469',
    Name: 'Mount Pulaski',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_12470',
    Name: 'Fosston',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_12471',
    Name: 'Leland Grove',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_12472',
    Name: 'Hastings',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NE_12473',
    Name: 'Hartington',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_WV_12474',
    Name: 'New Haven',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_LA_12475',
    Name: 'Colfax',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_AL_12476', Name: 'Ardmore', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NY_12477',
    Name: 'Cumberland Head',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IA_12478', Name: 'Akron', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_12479',
    Name: 'Cambria',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_AL_12480', Name: 'Malvern', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_IN_12481',
    Name: 'Clermont',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PR_12482',
    Name: 'Yaurel',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_PA_12483',
    Name: 'Pine Grove Mills',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_WY_12484', Name: 'Wilson', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_ND_12485',
    Name: 'Garrison',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NY_12486',
    Name: 'Castleton-on-Hudson',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_12487',
    Name: 'Versailles',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AZ_12488',
    Name: 'Wagon Wheel',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_IN_12489', Name: 'Culver', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_GA_12490',
    Name: 'Blue Ridge',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_RI_12491',
    Name: 'Harrisville',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_PA_12492',
    Name: 'Brodheadsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_12493',
    Name: 'Center Hill',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_12494',
    Name: 'Lakewood Shores',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CT_12495',
    Name: 'South Windham',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_CA_12496',
    Name: 'Dunnigan',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_12497', Name: 'Slater', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AR_12498',
    Name: 'Perryville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_OH_12499',
    Name: 'Fort Recovery',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CT_12500',
    Name: 'Wauregan',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_AK_12501',
    Name: 'Sutton-Alpine',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_NY_12502',
    Name: 'Head of the Harbor',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TN_12503', Name: 'Burns', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_PA_12504',
    Name: 'South Coatesville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AL_12505', Name: 'Newton', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NY_12506',
    Name: 'Mineville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OH_12507', Name: 'Somerset', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_12508',
    Name: 'Eatons Neck',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MI_12509',
    Name: 'Shorewood-Tower Hills-Harbert',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_TX_12510',
    Name: 'China Spring',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_SC_12511',
    Name: 'Whitmire',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_KS_12512', Name: 'Towanda', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IL_12513',
    Name: 'Lake Catherine',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_DE_12514', Name: 'Blades', stateId: 'DE', stateName: 'Delaware'},
  {Id: 'CIT_US_MO_12515', Name: 'Gower', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_TN_12516', Name: 'Crump', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_WI_12517',
    Name: 'Cleveland',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MD_12518',
    Name: 'Colmar Manor',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_LA_12519',
    Name: 'Pine Prairie',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PR_12520',
    Name: 'Benítez',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MS_12521',
    Name: 'Lexington',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_12522',
    Name: 'Fivepointville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OH_12523',
    Name: 'Roaming Shores',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NY_12524',
    Name: 'Billington Heights',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_ID_12525',
    Name: 'Sugar City',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_NC_12526',
    Name: 'Bryson City',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WI_12527',
    Name: 'Bangor',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OH_12528', Name: 'Bremen', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_SC_12529',
    Name: 'Monarch Mill',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OH_12530', Name: 'Burton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NM_12531',
    Name: 'Navajo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PR_12532',
    Name: 'Guayabal',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_MO_12533', Name: 'Dixon', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_ID_12534', Name: 'Paul', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_OH_12535',
    Name: 'Stony Prairie',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_TN_12536',
    Name: 'Kimball',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MN_12537',
    Name: 'Dassel',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WI_12538',
    Name: 'Fox Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IA_12539', Name: 'Woodward', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NJ_12540',
    Name: 'Vernon Valley',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_KS_12541', Name: 'Leoti', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_TX_12542',
    Name: 'Batesville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PR_12543',
    Name: 'El Ojo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MS_12544',
    Name: 'Ackerman',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IL_12545', Name: 'Dunlap', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_VA_12546',
    Name: 'Rural Retreat',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_FL_12547',
    Name: 'Westlake',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WV_12548',
    Name: 'West Liberty',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_CA_12549',
    Name: 'Irwindale',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_12550',
    Name: 'Hudson',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_12551', Name: 'Walford', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IN_12552',
    Name: 'Koontz Lake',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PA_12553',
    Name: 'Friedens',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_12554',
    Name: 'Ash Grove',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_12555',
    Name: 'Harleigh',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_12556', Name: 'Grapeland', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_12557',
    Name: 'Buttonwillow',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MI_12558',
    Name: 'Ortonville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SC_12559',
    Name: 'Awendaw',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_12560',
    Name: 'Woxall',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_12561',
    Name: 'Raubsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_WI_12562', Name: 'Edgar', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_OH_12563', Name: 'Arlington', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TX_12564',
    Name: 'Woodbranch',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IA_12565', Name: 'Fayette', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_AR_12566', Name: 'Dover', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_IL_12567', Name: 'Vienna', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_PA_12568',
    Name: 'Girardville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_12569',
    Name: 'Northwest Ithaca',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_WY_12570', Name: 'Hoback', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_WA_12571',
    Name: 'Purdy',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_12572',
    Name: 'Saxonburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_12573',
    Name: 'Keshena',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TX_12574',
    Name: 'Cut and Shoot',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_LA_12575',
    Name: 'Grand Isle',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_12576', Name: 'Rotan', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_12577',
    Name: 'Laflin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_12578',
    Name: 'Dunnstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_12579',
    Name: 'Huntington Bay',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AZ_12580',
    Name: 'Pinetop Country Club',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_PA_12581',
    Name: 'Queens Gate',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IA_12582',
    Name: 'State Center',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_MS_12583',
    Name: 'Fayette',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_TX_12584', Name: 'Ivanhoe', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_FL_12585', Name: 'Gretna', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_TX_12586',
    Name: 'Queen City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WI_12587',
    Name: 'Hurley',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_12588',
    Name: 'Virginia',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_12589',
    Name: 'Terre Hill',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_12590',
    Name: 'St. Peter',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IL_12591', Name: 'Roxana', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_NE_12592', Name: 'Sutton', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_NY_12593',
    Name: 'Chappaqua',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IA_12594', Name: 'Corning', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_LA_12595', Name: 'Iota', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_IN_12596',
    Name: 'Darmstadt',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CT_12597',
    Name: 'Noank',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_PA_12598',
    Name: 'Oakdale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MO_12599', Name: 'Tarkio', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_PA_12600',
    Name: 'West Conshohocken',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MT_12601',
    Name: 'Fort Benton',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_TX_12602', Name: 'Flatonia', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NM_12603',
    Name: 'Capitan',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_OH_12604', Name: 'Forest', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PR_12605',
    Name: 'Las Ollas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_CO_12606', Name: 'Mancos', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_OH_12607',
    Name: 'Cinnamon Lake',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WV_12608',
    Name: 'Cassville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MI_12609',
    Name: 'Fennville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MS_12610',
    Name: 'Tylertown',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WA_12611',
    Name: 'Winlock',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PR_12612',
    Name: 'Piedra Gorda',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_TX_12613', Name: 'La Vernia', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_12614',
    Name: 'Washington Mills',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MT_12615',
    Name: 'Thompson Falls',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_VA_12616',
    Name: 'Chatham',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_12617',
    Name: 'Midpines',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_12618',
    Name: 'Castle Hill',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CO_12619',
    Name: 'Mountain Village',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NM_12620',
    Name: 'Taos Pueblo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_TX_12621', Name: 'Sundown', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_12622',
    Name: 'Chevy Chase Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_12623',
    Name: 'Cadott',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_12624',
    Name: 'Langhorne Manor',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_12625',
    Name: 'West Baraboo',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MS_12626',
    Name: 'Centreville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IN_12627',
    Name: 'Westport',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_LA_12628',
    Name: 'Presquille',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NJ_12629',
    Name: 'Ten Mile Run',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_VA_12630',
    Name: 'Greenville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_OH_12631', Name: 'Greenwich', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MI_12632',
    Name: 'Stanton',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IL_12633', Name: 'Benld', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_PA_12634',
    Name: 'Oakland',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_12635',
    Name: 'Eldridge',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_12636', Name: 'Manning', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_12637',
    Name: 'New Beaver',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_12638',
    Name: 'Darrington',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_AL_12639',
    Name: 'Stapleton',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PA_12640',
    Name: 'Linntown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TN_12641',
    Name: 'Celina',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MS_12642',
    Name: 'Walls',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_OH_12643',
    Name: 'Pemberville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_KS_12644', Name: 'Arma', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OK_12645', Name: 'Morris', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_TX_12646', Name: 'Moody', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_12647',
    Name: 'Rustburg',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WI_12648',
    Name: 'Black Earth',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PR_12649',
    Name: 'San Antonio',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MA_12650',
    Name: 'The Pinehills',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_IA_12651', Name: 'Logan', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MN_12652',
    Name: 'Red Lake Falls',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WI_12653',
    Name: 'Bonduel',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_GA_12654',
    Name: 'Pine Mountain',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NC_12655',
    Name: 'Biltmore Forest',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OR_12656',
    Name: 'Rockaway Beach',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_CO_12657',
    Name: 'Aristocrat Ranchettes',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_TX_12658', Name: 'Olmito', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SC_12659',
    Name: 'Chesterfield',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OK_12660', Name: 'Helena', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_WV_12661',
    Name: 'Chesapeake',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NY_12662',
    Name: 'Clintondale',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_DE_12663', Name: 'Felton', stateId: 'DE', stateName: 'Delaware'},
  {Id: 'CIT_US_KY_12664', Name: 'Verona', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_TX_12665', Name: 'Hico', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NM_12666',
    Name: 'San Rafael',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NC_12667',
    Name: 'Pilot Mountain',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WV_12668',
    Name: 'Mount Gay-Shamrock',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_IA_12669', Name: 'Montezuma', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_12670',
    Name: 'Sweet Springs',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_12671', Name: 'Big Sandy', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_12672',
    Name: 'Raleigh',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_MI_12673', Name: 'Coloma', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_TX_12674', Name: 'Woodsboro', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_12675', Name: 'Earlham', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IN_12676',
    Name: 'Dillsboro',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_KS_12677',
    Name: 'Hill City',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_TX_12678',
    Name: 'Lake Brownwood',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AR_12679',
    Name: 'Cedarville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_KY_12680',
    Name: 'Guthrie',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CA_12681',
    Name: 'Janesville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_12682',
    Name: 'Atglen',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_12683',
    Name: 'Millers Creek',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_12684',
    Name: 'Claremont',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CT_12685',
    Name: 'South Woodstock',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_MI_12686',
    Name: 'Sand Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PR_12687',
    Name: 'Lomas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_IN_12688',
    Name: 'Worthington',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_WI_12689',
    Name: 'Cornell',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_VA_12690',
    Name: 'Glade Spring',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MN_12691',
    Name: 'Bagley',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_12692', Name: 'Benavides', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_12693',
    Name: 'Millbrook',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MI_12694',
    Name: 'Centreville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_KS_12695',
    Name: 'Silver Lake',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_MT_12696', Name: 'Eureka', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_OR_12697',
    Name: 'Dunes City',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_TX_12698', Name: 'Hubbard', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MA_12699',
    Name: 'Harwich Center',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_NY_12700',
    Name: 'Great River',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_12701',
    Name: 'Shortsville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OR_12702', Name: 'Bay City', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_PA_12703',
    Name: 'Bryn Athyn',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_12704', Name: 'Serenada', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_12705', Name: 'Menard', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_12706',
    Name: 'St. Elmo',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AR_12707',
    Name: 'Greenland',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_TN_12708',
    Name: 'Fall Branch',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_OH_12709',
    Name: 'Brookfield Center',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_IA_12710', Name: 'Kingsley', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OH_12711',
    Name: 'Crystal Lakes',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_12712',
    Name: 'Shenandoah Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_12713',
    Name: 'Markesan',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_SC_12714',
    Name: 'Pelzer',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_IL_12715',
    Name: 'Twin Grove',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_12716',
    Name: 'Dawson',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IL_12717', Name: 'Philo', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_OH_12718',
    Name: 'New California',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NC_12719',
    Name: 'Lake Waccamaw',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_12720',
    Name: 'Crainville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_12721',
    Name: 'Avondale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_12722',
    Name: 'North DeLand',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_KY_12723',
    Name: 'Vanceburg',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MN_12724',
    Name: 'Madison',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IA_12725', Name: 'Brooklyn', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_KY_12726',
    Name: 'Whitley City',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WV_12727',
    Name: 'Falling Waters',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_OH_12728', Name: 'Pioneer', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OK_12729', Name: 'Beaver', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_OH_12730',
    Name: 'Wetherington',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_12731',
    Name: 'Avon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WY_12732',
    Name: 'Moose Wilson Road',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_MD_12733',
    Name: 'New Windsor',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CO_12734',
    Name: 'Aetna Estates',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_IL_12735',
    Name: 'Gridley',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_12736',
    Name: 'Princeton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_12737',
    Name: 'Montague',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CO_12738',
    Name: 'Indian Hills',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_TN_12739',
    Name: 'South Carthage',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MO_12740',
    Name: 'Bel-Nor',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TN_12741',
    Name: 'Tracy City',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_IN_12742',
    Name: 'Rome City',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NY_12743',
    Name: 'Sackets Harbor',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_SC_12744',
    Name: 'Buffalo',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_UT_12745', Name: 'Mendon', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MI_12746',
    Name: 'Luna Pier',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_OH_12747',
    Name: 'Lake Lorelei',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OH_12748', Name: 'Elmore', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OK_12749', Name: 'Cleora', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_GA_12750', Name: 'Edison', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CA_12751',
    Name: 'Mountain Mesa',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VA_12752',
    Name: 'Middletown',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_OK_12753',
    Name: 'Locust Grove',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WA_12754',
    Name: 'Pomeroy',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NY_12755',
    Name: 'Brocton',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TN_12756',
    Name: 'Bruceton',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_IA_12757', Name: 'Wellman', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SC_12758',
    Name: 'Irwin',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_12759',
    Name: 'Clarks Green',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_12760',
    Name: 'Olivet',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_UT_12761', Name: 'Milford', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_AZ_12762',
    Name: 'Donovan Estates',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_OK_12763', Name: 'Salina', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_IL_12764', Name: 'Durand', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_VA_12765',
    Name: 'Kilmarnock',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PR_12766',
    Name: 'Palmarejo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_WI_12767',
    Name: 'Hillsboro',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_FL_12768',
    Name: 'Five Points',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NM_12769',
    Name: 'Loving',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_ND_12770',
    Name: 'Surrey',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_PA_12771',
    Name: 'New Castle Northwest',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OK_12772', Name: 'Apache', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_AL_12773',
    Name: 'Flomaton',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NY_12774',
    Name: 'Yorktown Heights',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_12775',
    Name: 'Gasport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NC_12776',
    Name: 'West Marion',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MN_12777',
    Name: 'Frazee',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_12778',
    Name: 'Russellton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_12779',
    Name: 'Woodway',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CT_12780',
    Name: 'Moodus',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_MN_12781', Name: 'Rice', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_WV_12782',
    Name: 'Parsons',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_TX_12783', Name: 'Elmo', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_UT_12784', Name: 'Wolf Creek', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_IN_12785',
    Name: 'New Pekin',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OR_12786', Name: 'Williams', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MO_12787',
    Name: 'Lakeshire',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IA_12788', Name: 'Lenox', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_LA_12789',
    Name: 'New Sarpy',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_12790',
    Name: 'Shoemakersville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_12791',
    Name: 'Mishicot',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_12792',
    Name: 'Allendale',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_12793',
    Name: 'Blawnox',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_12794',
    Name: 'White Cloud',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IN_12795',
    Name: 'Kingsford Heights',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CA_12796',
    Name: 'Pioneer',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_GA_12797',
    Name: 'East Griffin',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MA_12798',
    Name: 'Mashpee Neck',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_IA_12799', Name: 'Woodbine', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_12800',
    Name: 'The Hideout',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IN_12801', Name: 'Odon', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PA_12802',
    Name: 'Mount Wolf',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_12803',
    Name: 'Adams Center',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NC_12804',
    Name: 'Swepsonville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_LA_12805',
    Name: 'Hackberry',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_12806', Name: 'Tatum', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_12807', Name: 'Bedford', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_12808',
    Name: 'Lewiston',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_12809',
    Name: 'Grandview',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MI_12810',
    Name: 'Standish',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PR_12811',
    Name: 'Tallaboa Alta',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MS_12812',
    Name: 'Pelahatchie',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IA_12813', Name: 'Sanborn', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_12814',
    Name: 'Gardner',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_12815', Name: 'Lone Tree', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_KY_12816',
    Name: 'Masonville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_KY_12817', Name: 'Auburn', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_PA_12818',
    Name: 'Schwenksville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_12819',
    Name: 'Duenweg',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_12820', Name: 'Wildwood', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_12821',
    Name: 'Stanleytown',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_AZ_12822', Name: 'Salome', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_AL_12823',
    Name: 'Fayetteville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_SC_12824',
    Name: 'Watts Mills',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MT_12825',
    Name: 'West Yellowstone',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_AL_12826',
    Name: 'Shoal Creek',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_TN_12827',
    Name: 'Gleason',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_TN_12828',
    Name: 'New Market',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_CO_12829',
    Name: 'Springfield',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_LA_12830', Name: 'Olla', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_NC_12831',
    Name: 'Maury',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_12832',
    Name: 'Youngsville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_AZ_12833', Name: 'Tubac', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_TX_12834',
    Name: 'Buchanan Dam',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_12835', Name: 'Godley', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_12836',
    Name: 'Cypress Quarters',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MN_12837',
    Name: 'Glyndon',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AZ_12838',
    Name: 'Cactus Flats',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_IL_12839',
    Name: 'Kincaid',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_VA_12840', Name: 'Exmore', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_NJ_12841',
    Name: 'Vernon Center',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IL_12842', Name: 'Oblong', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_WV_12843',
    Name: 'Glen Dale',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_FL_12844', Name: 'St. Leo', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NY_12845',
    Name: 'Northville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_LA_12846',
    Name: 'Ringgold',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_IA_12847', Name: 'Holstein', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MS_12848',
    Name: 'Mound Bayou',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WV_12849',
    Name: 'Fort Ashby',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_VA_12850',
    Name: 'Apple Mountain Lake',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_KS_12851',
    Name: 'Carbondale',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_TX_12852',
    Name: 'Heidelberg',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WY_12853',
    Name: 'Sleepy Hollow',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_MO_12854',
    Name: 'Oakland',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_12855',
    Name: 'Bell Acres',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OK_12856', Name: 'Vian', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_OH_12857',
    Name: 'Eaton Estates',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WV_12858',
    Name: 'Marmet',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MI_12859',
    Name: 'Mancelona',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_AZ_12860',
    Name: 'Centennial Park',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_PR_12861',
    Name: 'Parcelas La Milagrosa',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_OR_12862', Name: 'Glide', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_MN_12863', Name: 'Pierz', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_IL_12864',
    Name: 'Maple Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MI_12865', Name: 'Reese', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_PR_12866',
    Name: 'Lluveras',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_OH_12867',
    Name: 'Holiday Valley',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_AZ_12868', Name: 'Tsaile', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_MI_12869',
    Name: 'Crystal Falls',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_12870',
    Name: 'Walnut Grove',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_12871',
    Name: 'Grayson',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NE_12872',
    Name: 'Wakefield',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_PA_12873',
    Name: 'Valencia',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OK_12874', Name: 'Panama', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_IL_12875', Name: 'Capron', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_AZ_12876', Name: 'Leupp', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_AR_12877',
    Name: 'Clarendon',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_FL_12878',
    Name: 'Callahan',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MO_12879',
    Name: 'Velda City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_12880', Name: 'La Croft', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_12881',
    Name: 'Okawville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_12882', Name: 'Skidmore', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_12883',
    Name: 'Lake Marcel-Stillwater',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IA_12884', Name: 'Tripoli', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_TX_12885',
    Name: 'Montgomery',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_TN_12886',
    Name: 'Powells Crossroads',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_IN_12887', Name: 'Geneva', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_UT_12888', Name: 'Fairview', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NE_12889',
    Name: 'Ravenna',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_PR_12890',
    Name: 'Indios',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_CA_12891',
    Name: 'Redway',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_12892',
    Name: 'Lampeter',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_12893',
    Name: 'Coats Bend',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_WI_12894',
    Name: 'Nashotah',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MO_12895', Name: 'Crane', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MN_12896',
    Name: 'Grand Marais',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_GA_12897',
    Name: 'Walnut Grove',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MN_12898',
    Name: 'Minneota',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MD_12899',
    Name: 'Cottage City',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_VA_12900',
    Name: 'Piney Mountain',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WV_12901',
    Name: 'Lavalette',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_TN_12902',
    Name: 'Rutledge',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_OH_12903', Name: 'Dunlap', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_12904',
    Name: 'Sheridan',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_12905',
    Name: 'Lower Lake',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_12906',
    Name: 'Farmingdale',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_12907',
    Name: 'Lake Riverside',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_12908',
    Name: 'Charlack',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NC_12909',
    Name: 'Barker Heights',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_NV_12910', Name: 'Pioche', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_IA_12911',
    Name: 'Nora Springs',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_GA_12912',
    Name: 'Willacoochee',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_UT_12913', Name: 'Uintah', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_WI_12914',
    Name: 'Woodville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_12915', Name: 'Emory', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_KS_12916', Name: 'Kinsley', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_LA_12917',
    Name: 'Mansura',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_12918',
    Name: 'Ferndale',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_GA_12919',
    Name: 'Woodbine',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NJ_12920',
    Name: 'Frenchtown',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_12921',
    Name: 'Rosenhayn',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_OH_12922',
    Name: 'Monroeville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_GA_12923', Name: 'Enigma', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_KY_12924',
    Name: 'Beechwood Village',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_TN_12925',
    Name: 'Sneedville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_AZ_12926',
    Name: 'Beaver Dam',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_PA_12927',
    Name: 'Hughestown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_12928', Name: 'Rosebud', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_LA_12929',
    Name: 'Gueydan',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WI_12930',
    Name: 'Spring Valley',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_AL_12931', Name: 'Elmore', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_AL_12932', Name: 'Munford', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_KY_12933', Name: 'Plano', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_OR_12934', Name: 'Tangent', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_IN_12935',
    Name: 'Owensville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PA_12936',
    Name: 'Newville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_12937',
    Name: 'Cerro Gordo',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_12938',
    Name: 'Winthrop',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_FL_12939', Name: 'Inglis', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_SD_12940',
    Name: 'Eagle Butte',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_IA_12941',
    Name: 'Clarksville',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_WI_12942', Name: 'Blair', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_UT_12943',
    Name: 'Carbonville',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_IN_12944',
    Name: "Prince's Lakes",
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IN_12945',
    Name: 'Trafalgar',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_AR_12946', Name: 'Hazen', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_IN_12947',
    Name: 'Parker City',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_CO_12948', Name: 'Byers', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_NC_12949',
    Name: 'Robersonville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_AL_12950', Name: 'Hayden', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MS_12951',
    Name: 'Lambert',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_KS_12952',
    Name: 'Johnson City',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_CA_12953',
    Name: 'Shell Ridge',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_12954',
    Name: 'Forreston',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IN_12955',
    Name: 'Monroeville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IL_12956',
    Name: 'Hartford',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_VA_12957',
    Name: 'Claypool Hill',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_12958',
    Name: 'Penn Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_KS_12959', Name: 'Chapman', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IN_12960',
    Name: 'Morristown',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NE_12961',
    Name: 'Wood River',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MN_12962',
    Name: 'Winnebago',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_KS_12963', Name: 'Sublette', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_ND_12964',
    Name: 'Tioga',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_GA_12965',
    Name: 'Robins AFB',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_ND_12966',
    Name: 'New Rockford',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NJ_12967',
    Name: 'Sea Bright',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OK_12968', Name: 'Sperry', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_TX_12969', Name: 'Cresson', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_ND_12970',
    Name: 'Park River',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NY_12971',
    Name: 'Stottville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_12972',
    Name: 'Claysburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_12973',
    Name: 'Weissport East',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_12974',
    Name: 'Hayfield',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AR_12975',
    Name: 'Marshall',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_WI_12976',
    Name: 'Phillips',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WA_12977',
    Name: 'Manson',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_GA_12978', Name: 'Aragon', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MO_12979',
    Name: 'Advance',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_NE_12980', Name: 'Wymore', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_MS_12981',
    Name: 'Wade',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IL_12982',
    Name: 'North Utica',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_NY_12983', Name: 'Shokan', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_NY_12984',
    Name: 'Lincolndale',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AL_12985',
    Name: 'Dauphin Island',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_VA_12986',
    Name: 'Ruckersville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_OH_12987', Name: 'Milan', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_KS_12988', Name: 'Inman', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IL_12989',
    Name: 'The Galena Territory',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SD_12990',
    Name: 'Miller',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_NV_12991', Name: 'Mogul', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_IL_12992',
    Name: 'New Berlin',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TN_12993',
    Name: 'Fincastle',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_TX_12994', Name: 'Tye', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_12995',
    Name: 'Thermal',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_12996', Name: 'Lanark', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MS_12997',
    Name: 'Robinhood',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OH_12998', Name: 'Beverly', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TN_12999',
    Name: 'Tennessee Ridge',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_KY_13000',
    Name: 'Earlington',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MI_13001',
    Name: 'Litchfield',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_OK_13002',
    Name: 'Okarche',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_AK_13003',
    Name: 'Funny River',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_NY_13004',
    Name: 'Bridgeport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_VA_13005',
    Name: 'Nokesville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NY_13006',
    Name: 'Oriskany',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AL_13007',
    Name: 'Redstone Arsenal',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_AL_13008',
    Name: 'Brookside',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_OK_13009',
    Name: 'Laverne',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NY_13010',
    Name: 'Stone Ridge',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MI_13011',
    Name: 'Saranac',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SD_13012',
    Name: 'Crooks',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_CA_13013',
    Name: 'Forest Meadows',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_13014',
    Name: 'Forest Lake',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_13015',
    Name: 'Pine Knoll Shores',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_GA_13016',
    Name: 'Arlington',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PA_13017',
    Name: 'Flemington',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_UT_13018',
    Name: 'Orangeville',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_PA_13019',
    Name: 'South Uniontown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_13020', Name: 'Morrow', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_SC_13021',
    Name: 'Iva',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_OH_13022',
    Name: 'West Alexandria',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WV_13023',
    Name: 'Mullens',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MN_13024',
    Name: 'Appleton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_13025',
    Name: 'Pocono Springs',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_13026',
    Name: 'Blowing Rock',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_13027',
    Name: 'Bridgehampton',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_13028',
    Name: 'Lone Jack',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IN_13029',
    Name: 'Lake Holiday',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OH_13030', Name: 'Leesburg', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AR_13031',
    Name: 'Flippin',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_LA_13032',
    Name: 'Killian',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_OH_13033',
    Name: 'Minerva Park',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_TX_13034', Name: 'Hawkins', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TX_13035',
    Name: 'China Grove',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_KS_13036',
    Name: 'Yates Center',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_IL_13037',
    Name: 'Clifton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TN_13038', Name: 'Troy', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_NE_13039',
    Name: 'Sutherland',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NM_13040',
    Name: 'Pecos',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IL_13041',
    Name: 'St. Jacob',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_ID_13042', Name: 'Marsing', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_VA_13043',
    Name: 'Honaker',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NV_13044',
    Name: 'Ruhenstroth',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_MO_13045',
    Name: 'Rich Hill',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_GA_13046', Name: 'Boston', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_KS_13047', Name: 'Harper', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NY_13048',
    Name: 'Schuylerville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OH_13049',
    Name: 'Frazeysburg',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NE_13050',
    Name: 'Arlington',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_AL_13051',
    Name: 'Goodwater',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NC_13052',
    Name: 'West Jefferson',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_13053',
    Name: 'Conashaugh Lakes',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_13054',
    Name: 'Beaver',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_OH_13055',
    Name: 'Boston Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_LA_13056',
    Name: 'Deville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WA_13057',
    Name: 'Duluth',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_VA_13058',
    Name: 'Bull Run Mountain Estates',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_IA_13059', Name: 'Dysart', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MO_13060', Name: 'Gerald', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_FL_13061',
    Name: 'Babson Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_13062', Name: 'Naples', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_13063',
    Name: 'Sharon',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MO_13064',
    Name: 'Greenfield',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IA_13065',
    Name: 'Arnolds Park',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_PA_13066',
    Name: 'Loganville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_13067',
    Name: 'Berry Creek',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MS_13068',
    Name: 'Port Gibson',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_LA_13069',
    Name: 'Glenmora',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_LA_13070',
    Name: 'Lakeview',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_IN_13071',
    Name: 'Fairview Park',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_ID_13072',
    Name: 'Robie Creek',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_CA_13073',
    Name: 'Avila Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_13074',
    Name: 'Maple Bluff',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_NE_13075', Name: 'Yutan', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_NC_13076',
    Name: 'Spring Hope',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NM_13077',
    Name: 'Elephant Butte',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MI_13078',
    Name: 'Webberville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_13079',
    Name: 'Brickerville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_13080',
    Name: 'Shell Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MO_13081',
    Name: 'Pierce City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MO_13082',
    Name: 'La Plata',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_13083',
    Name: 'Granville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NJ_13084',
    Name: 'Elmer',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_KY_13085',
    Name: 'Taylorsville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WA_13086',
    Name: 'Tieton',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_FL_13087', Name: 'Harold', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_WI_13088',
    Name: 'Black Creek',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_13089',
    Name: 'Bloomfield',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_13090',
    Name: 'Ranchitos del Norte',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AL_13091',
    Name: 'Cleveland',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NE_13092',
    Name: 'Louisville',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_OH_13093',
    Name: 'Green Springs',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PR_13094',
    Name: 'Playita Cortada',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_IL_13095', Name: 'Walnut', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OR_13096', Name: 'Siletz', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_PA_13097',
    Name: 'St. Vincent College',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_13098',
    Name: 'Ansted',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_VA_13099',
    Name: 'Nellysford',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NM_13100',
    Name: 'Carnuel',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NC_13101',
    Name: 'Clyde',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_HI_13102',
    Name: 'Discovery Harbour',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_NC_13103',
    Name: 'Rutherford College',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IL_13104', Name: 'Warren', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NC_13105',
    Name: 'Cedar Point',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_DE_13106',
    Name: 'Rodney Village',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_CA_13107',
    Name: 'Byron',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AZ_13108',
    Name: 'Tombstone',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_RI_13109',
    Name: 'Bradford',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_WI_13110',
    Name: 'Independence',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_KS_13111',
    Name: 'Ness City',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_TX_13112', Name: 'Wallis', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_ID_13113',
    Name: 'Glenns Ferry',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {Id: 'CIT_US_WI_13114', Name: 'Elroy', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_MI_13115',
    Name: 'Bellevue',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MN_13116',
    Name: 'Menahga',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_13117',
    Name: 'Orange Grove',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_NY_13118', Name: 'Jordan', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_TX_13119', Name: 'Thorndale', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_LA_13120',
    Name: 'Urania',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_VA_13121',
    Name: 'Springville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_HI_13122', Name: 'Wainaku', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_TN_13123',
    Name: 'Roan Mountain',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_AZ_13124',
    Name: 'Peach Springs',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NJ_13125',
    Name: 'Cedar Glen West',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OH_13126', Name: 'Stryker', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_13127', Name: 'Elkhart', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AL_13128', Name: 'Coosada', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_TX_13129',
    Name: 'Fairchilds',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_13130',
    Name: 'Richlandtown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_13131',
    Name: 'Swede Heaven',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_SC_13132',
    Name: 'Meggett',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_OK_13133',
    Name: 'Waukomis',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_IA_13134',
    Name: 'Central City',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_OR_13135',
    Name: 'Gold Hill',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_NY_13136',
    Name: 'Plattsburgh West',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WI_13137',
    Name: 'Gillett',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CO_13138',
    Name: 'Alamosa East',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_IA_13139',
    Name: 'Maharishi Vedic City',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_NV_13140',
    Name: 'Golden Valley',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_CA_13141',
    Name: 'Herlong',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_KS_13142', Name: 'Wathena', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_TX_13143', Name: 'Eden', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_NY_13144', Name: 'Milton', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_TX_13145', Name: 'Uhland', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NM_13146',
    Name: 'Fairacres',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_OR_13147', Name: 'Sunriver', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_TX_13148',
    Name: 'Encantada-Ranchito-El Calaboz',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_VA_13149',
    Name: 'Henry Fork',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_IA_13150', Name: 'New Sharon', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SD_13151',
    Name: 'Deadwood',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NC_13152',
    Name: 'Beulaville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_MO_13153', Name: 'Winona', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_KS_13154',
    Name: 'St. Francis',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_SC_13155',
    Name: 'Prosperity',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MI_13156',
    Name: 'Lakewood Club',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_13157',
    Name: 'Harristown',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_13158',
    Name: 'Elm City',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MS_13159',
    Name: 'Hurley',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IA_13160', Name: 'Van Meter', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WY_13161',
    Name: 'Sundance',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {Id: 'CIT_US_TX_13162', Name: 'Farwell', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TN_13163',
    Name: 'Cornersville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MI_13164',
    Name: 'Frankfort',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OR_13165', Name: 'Lyons', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_KY_13166',
    Name: 'Livermore',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_AK_13167', Name: 'Tok', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_MN_13168',
    Name: 'Preston',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MD_13169',
    Name: 'Potomac Heights',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MI_13170',
    Name: 'Lewiston',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WA_13171',
    Name: 'Asotin',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_13172',
    Name: 'Fall Creek',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_13173',
    Name: 'Colony Park',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ND_13174',
    Name: 'Larimore',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_PA_13175',
    Name: 'Browntown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ND_13176',
    Name: 'Parshall',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MN_13177',
    Name: 'Wheaton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_13178',
    Name: 'Colchester',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_NV_13179', Name: 'Verdi', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_OK_13180',
    Name: 'Buffalo',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_TX_13181', Name: 'Von Ormy', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NC_13182',
    Name: 'Broadway',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MT_13183',
    Name: 'Seeley Lake',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MD_13184',
    Name: 'Buckeystown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CT_13185',
    Name: 'Higganum',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_FL_13186',
    Name: 'Limestone Creek',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_SC_13187',
    Name: 'Latta',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_SD_13188',
    Name: 'Salem',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_ND_13189',
    Name: 'Crosby',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MI_13190',
    Name: 'Laingsburg',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_TX_13191', Name: 'Roscoe', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_ID_13192', Name: 'Hansen', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_VA_13193',
    Name: 'Clintwood',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_FL_13194',
    Name: 'Schall Circle',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WV_13195',
    Name: 'Newell',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MA_13196',
    Name: 'Millers Falls',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_TN_13197',
    Name: 'New Union',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_VT_13198',
    Name: 'Enosburg Falls',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_FL_13199',
    Name: 'Kenwood Estates',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IN_13200', Name: 'Redkey', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_NY_13201', Name: 'Olcott', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_WY_13202', Name: 'Basin', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_WV_13203',
    Name: 'Sistersville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_AR_13204',
    Name: 'Caraway',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_FL_13205',
    Name: 'Manasota Key',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_13206',
    Name: 'Toughkenamon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_13207',
    Name: 'Grantsburg',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_AZ_13208',
    Name: 'Fredonia',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_TN_13209', Name: 'Erin', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_OK_13210',
    Name: 'Bushyhead',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WA_13211',
    Name: 'Entiat',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_TN_13212',
    Name: 'Falling Water',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_LA_13213',
    Name: 'Paulina',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WA_13214',
    Name: 'Garrett',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_FL_13215',
    Name: 'Bradenton Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_TX_13216',
    Name: 'Brownsboro',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MS_13217',
    Name: 'Vardaman',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MD_13218',
    Name: 'Morningside',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WV_13219',
    Name: 'Barrackville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_KS_13220', Name: 'Buhler', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_TN_13221',
    Name: 'Benton',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WV_13222',
    Name: 'Benwood',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_IA_13223', Name: 'Manly', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NC_13224',
    Name: 'Hays',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_13225',
    Name: 'Tower City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OK_13226', Name: 'Snyder', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_RI_13227',
    Name: 'Melville',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {Id: 'CIT_US_MT_13228', Name: 'Boulder', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_OH_13229', Name: 'De Graff', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MN_13230',
    Name: 'Clara City',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_SD_13231',
    Name: 'Freeman',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NY_13232',
    Name: 'Walker Valley',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IA_13233', Name: 'Shell Rock', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_TX_13234', Name: 'Munday', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_13235', Name: 'Florence', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MD_13236',
    Name: 'Rock Hall',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_AL_13237', Name: 'Clio', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MI_13238',
    Name: 'Ravenna',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_13239',
    Name: 'Germantown',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WV_13240',
    Name: 'Mount Hope',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_OH_13241',
    Name: 'Tiltonsville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_SD_13242',
    Name: 'Britton',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_IL_13243',
    Name: 'Palestine',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WV_13244',
    Name: 'Ceredo',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IL_13245',
    Name: 'Long Creek',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MD_13246',
    Name: 'Somerset',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_IL_13247',
    Name: 'Wyoming',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MI_13248',
    Name: 'Breckenridge',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NY_13249',
    Name: 'Loch Sheldrake',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_13250', Name: 'Stowell', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_KS_13251', Name: 'Osborne', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NY_13252',
    Name: 'Hewlett Harbor',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_13253',
    Name: 'Salix',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_13254',
    Name: 'Manawa',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_13255',
    Name: 'Hagaman',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IA_13256', Name: 'Buffalo', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_KY_13257',
    Name: 'West Buechel',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IA_13258', Name: 'Dike', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_VA_13259',
    Name: 'Twin Lakes',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MS_13260',
    Name: 'Cleary',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_VA_13261', Name: 'Stuart', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_TX_13262', Name: 'Markham', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MN_13263',
    Name: 'Starbuck',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_13264',
    Name: 'Middleburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_13265',
    Name: 'Enhaut',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OK_13266',
    Name: 'Goodwell',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_PR_13267',
    Name: 'Río Cañas Abajo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MD_13268',
    Name: 'Woodsboro',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_TN_13269',
    Name: 'Huntsville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MO_13270',
    Name: 'Lake Winnebago',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_FL_13271', Name: 'Mayo', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_PA_13272',
    Name: 'Waymart',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IL_13273', Name: 'Toluca', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NE_13274',
    Name: 'North Bend',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_TX_13275',
    Name: 'Lake Medina Shores',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MO_13276',
    Name: 'Calverton Park',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_SC_13277',
    Name: 'Joanna',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_13278',
    Name: 'Clymer',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_13279', Name: 'Lumpkin', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_PA_13280',
    Name: 'Hummels Wharf',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_13281',
    Name: 'Spring Grove',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WI_13282',
    Name: 'Westfield',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OR_13283', Name: 'Heppner', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_OK_13284', Name: 'Geary', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_ME_13285', Name: 'Eastport', stateId: 'ME', stateName: 'Maine'},
  {
    Id: 'CIT_US_IN_13286',
    Name: 'Otterbein',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_AR_13287', Name: 'Waldo', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_OK_13288',
    Name: 'Fairfax',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_GA_13289',
    Name: 'Mount Airy',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_IL_13290',
    Name: 'Chrisman',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_13291',
    Name: 'Nixon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ND_13292',
    Name: 'Washburn',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_PR_13293',
    Name: 'Fuig',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_IL_13294', Name: 'Onarga', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_AK_13295',
    Name: 'Point MacKenzie',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_FL_13296', Name: 'Taft', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_LA_13297',
    Name: 'Wallace',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WV_13298',
    Name: 'Mabscott',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_FL_13299',
    Name: 'Palm Beach Shores',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_LA_13300',
    Name: 'Roseland',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_SD_13301',
    Name: 'Clear Lake',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_AK_13302', Name: 'Craig', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_NC_13303',
    Name: 'Fremont',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OK_13304', Name: 'Mounds', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_TN_13305',
    Name: 'Midtown',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MI_13306',
    Name: 'Hemlock',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_AL_13307',
    Name: 'New Brockton',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PA_13308',
    Name: 'North Charleroi',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_13309',
    Name: 'Del Rey',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_13310',
    Name: 'Pinopolis',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NC_13311',
    Name: 'Harkers Island',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WV_13312',
    Name: 'Buffalo',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IN_13313',
    Name: 'Galveston',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OH_13314', Name: 'Smithville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_13315',
    Name: 'Lauderdale Lakes',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_13316',
    Name: 'Mount Hermon',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OH_13317',
    Name: 'Gnadenhutten',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_SD_13318',
    Name: 'Platte',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NY_13319',
    Name: 'Morrisonville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AR_13320',
    Name: 'Hampton',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CO_13321',
    Name: 'Cripple Creek',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_AL_13322',
    Name: 'Hackleburg',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PR_13323',
    Name: 'Pastos',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_NY_13324',
    Name: 'Philmont',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AL_13325',
    Name: 'Falkville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_TX_13326', Name: 'Nevada', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_GA_13327',
    Name: 'Lumber City',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_SC_13328',
    Name: 'Utica',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MI_13329',
    Name: 'Dimondale',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_AZ_13330',
    Name: 'Fort Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_OK_13331',
    Name: 'Shattuck',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NC_13332',
    Name: 'Stoneville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_KS_13333', Name: 'Stockton', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_SD_13334',
    Name: 'Mission',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_PA_13335',
    Name: 'Bloomfield',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OK_13336',
    Name: 'Rush Springs',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_TX_13337',
    Name: 'Fort Clark Springs',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NM_13338',
    Name: 'Ohkay Owingeh',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MN_13339',
    Name: 'Medford',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NC_13340',
    Name: 'West Canton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_AL_13341',
    Name: 'Cottonwood',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MS_13342',
    Name: 'Hillsboro',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OH_13343', Name: 'Coldstream', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_13344',
    Name: 'Fellsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_13345',
    Name: 'Faxon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_13346',
    Name: 'Emerald Bay',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_13347', Name: 'Manchaca', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_13348', Name: 'Roland', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OH_13349',
    Name: 'Pleasant Hill',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_MT_13350', Name: 'Chinook', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_OK_13351',
    Name: 'Colbert',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_LA_13352',
    Name: 'Watson',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_NV_13353', Name: 'Wells', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_IL_13354',
    Name: 'Oquawka',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_ND_13355',
    Name: 'Rolla',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_LA_13356',
    Name: 'Lawtell',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_13357', Name: 'Rogers', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_13358',
    Name: 'Palmdale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_13359',
    Name: 'West Blocton',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_AZ_13360',
    Name: 'Moenkopi',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NJ_13361',
    Name: 'Crandon Lakes',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_AL_13362',
    Name: 'Rogersville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_WI_13363',
    Name: 'Barneveld',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IN_13364',
    Name: 'Farmland',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AR_13365',
    Name: 'Marmaduke',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_KY_13366',
    Name: 'Clinton',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MI_13367',
    Name: 'Stockbridge',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_KY_13368',
    Name: 'Barbourmeade',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_13369',
    Name: 'West Easton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_13370',
    Name: 'Holiday Lakes',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MI_13371',
    Name: 'Brown City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MI_13372',
    Name: 'Ontonagon',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_13373',
    Name: 'Grant Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_13374',
    Name: 'Goodman',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_13375',
    Name: 'Meadowlands',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AZ_13376',
    Name: 'Arivaca Junction',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_OK_13377',
    Name: 'Dickson',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_IL_13378',
    Name: 'Valmeyer',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_13379', Name: 'Alton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NY_13380',
    Name: 'Cortland West',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OH_13381', Name: 'Millbury', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_13382',
    Name: 'Mitchell',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_ND_13383',
    Name: 'Fort Totten',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_TX_13384', Name: 'LaCoste', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_13385', Name: 'Barry', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_ND_13386',
    Name: 'Ellendale',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NM_13387',
    Name: 'White Sands',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MO_13388',
    Name: 'Country Club Hills',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_13389',
    Name: 'Milroy',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_DE_13390',
    Name: 'Bethany Beach',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_PA_13391',
    Name: 'Bally',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_13392',
    Name: 'Taylorsville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MO_13393',
    Name: 'Mansfield',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_13394',
    Name: 'Blue Lake',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TN_13395',
    Name: 'Gordonsville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_AK_13396',
    Name: 'Diamond Ridge',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_TX_13397', Name: 'Hemphill', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NM_13398',
    Name: 'Mescalero',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_NV_13399', Name: 'Jackpot', stateId: 'NV', stateName: 'Nevada'},
  {Id: 'CIT_US_ID_13400', Name: 'Kamiah', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_IA_13401',
    Name: 'Melcher-Dallas',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_NM_13402',
    Name: 'Dexter',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_WI_13403',
    Name: 'Muscoda',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NJ_13404',
    Name: 'Dover Beaches South',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NY_13405',
    Name: 'Verplanck',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_13406',
    Name: 'Saticoy',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SD_13407',
    Name: 'Gregory',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_AZ_13408',
    Name: 'Six Shooter Canyon',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NY_13409',
    Name: 'Black River',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_13410',
    Name: 'Earlston',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_13411', Name: 'Fayette', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_13412', Name: 'Marion', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NC_13413',
    Name: 'Bayboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_13414', Name: 'Alto', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_13415', Name: 'Curtice', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TX_13416',
    Name: 'Palm Valley',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_13417',
    Name: 'Haverford College',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_13418',
    Name: 'Bethany',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_ND_13419',
    Name: 'Mapleton',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_PA_13420',
    Name: 'Houston',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ND_13421',
    Name: 'Cavalier',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MI_13422',
    Name: 'Edwardsburg',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OH_13423', Name: 'Haskins', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_13424',
    Name: 'Maggie Valley',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CT_13425',
    Name: 'Baltic',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_CO_13426',
    Name: 'Keenesburg',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_GA_13427',
    Name: 'Winterville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_AL_13428',
    Name: 'Deatsville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MD_13429',
    Name: 'Algonquin',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OK_13430', Name: 'Beggs', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_AK_13431',
    Name: 'Talkeetna',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_MI_13432',
    Name: 'South Gull Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MN_13433',
    Name: 'Cottonwood',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NJ_13434',
    Name: 'Avalon',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_TX_13435', Name: 'Kemp', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_13436',
    Name: 'Whitaker',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_13437',
    Name: 'Pinetops',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MS_13438',
    Name: 'Mississippi Valley State University',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_SD_13439',
    Name: 'Fort Thompson',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_AL_13440',
    Name: 'Bon Secour',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_TN_13441',
    Name: 'Monteagle',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_AZ_13442',
    Name: 'St. David',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_WI_13443', Name: 'Loyal', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_NY_13444',
    Name: 'Roslyn Estates',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_13445',
    Name: 'Troy',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CO_13446',
    Name: 'Cascade-Chipita Park',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MN_13447',
    Name: 'Royalton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_UT_13448',
    Name: 'West Mountain',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_KS_13449',
    Name: 'La Crosse',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_TX_13450', Name: 'Newark', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_13451',
    Name: 'Lannon',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IA_13452',
    Name: 'Coon Rapids',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_GA_13453',
    Name: 'Moody AFB',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_ID_13454', Name: 'Moreland', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_TX_13455',
    Name: 'Canyon Creek',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WA_13456',
    Name: 'Waitsburg',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WV_13457',
    Name: 'Sophia',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MN_13458',
    Name: 'Mahnomen',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_13459',
    Name: 'Martindale',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_LA_13460',
    Name: 'Vienna Bend',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MI_13461',
    Name: 'Pinconning',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_GA_13462', Name: 'Omega', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NE_13463',
    Name: 'Atkinson',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_PA_13464',
    Name: 'Centre Hall',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_13465',
    Name: 'Averill Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AZ_13466',
    Name: 'Ak-Chin Village',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_GA_13467', Name: 'Zebulon', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_TX_13468', Name: 'Ore City', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_13469', Name: 'New Vienna', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_13470',
    Name: 'Niverville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AK_13471',
    Name: 'Hooper Bay',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_GA_13472',
    Name: 'Marshallville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_TX_13473',
    Name: 'Cottonwood Shores',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MS_13474',
    Name: 'Terry',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_SD_13475',
    Name: 'Green Valley',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_CT_13476',
    Name: 'Suffield Depot',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_TN_13477',
    Name: 'Telford',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WY_13478',
    Name: 'Mountain View',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_VA_13479',
    Name: 'Belmont Estates',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IL_13480',
    Name: 'Tower Lakes',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_13481',
    Name: 'Hillcrest',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_13482',
    Name: 'Bevil Oaks',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IN_13483', Name: 'Topeka', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_OK_13484', Name: 'Yale', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CT_13485',
    Name: 'South Coventry',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_VA_13486', Name: 'Hurt', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_MN_13487',
    Name: 'New York Mills',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_13488', Name: 'Van Vleck', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AK_13489', Name: 'Cohoe', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_TX_13490',
    Name: 'Rancho Alegre',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_13491', Name: 'Redland', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NE_13492',
    Name: 'Fullerton',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_TX_13493', Name: 'Iraan', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IN_13494',
    Name: 'Converse',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_TX_13495', Name: 'Lexington', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NM_13496',
    Name: 'La Luz',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_MI_13497', Name: 'Fowler', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MN_13498',
    Name: 'Spicer',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_LA_13499',
    Name: 'Empire',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MD_13500',
    Name: 'Keedysville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NC_13501',
    Name: 'Robbins',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_AL_13502',
    Name: 'Tidmore Bend',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_TN_13503',
    Name: 'Ardmore',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_KY_13504',
    Name: 'Beattyville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NH_13505',
    Name: 'Groveton',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {Id: 'CIT_US_OR_13506', Name: 'Riddle', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_PA_13507',
    Name: 'West Leechburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_13508', Name: 'Homer', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NM_13509',
    Name: 'Hagerman',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_SC_13510',
    Name: 'Pamplico',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OK_13511', Name: 'Latta', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_TX_13512', Name: 'Jewett', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_13513',
    Name: 'Hazel Green',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IN_13514',
    Name: 'Mulberry',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_TN_13515',
    Name: 'Greenback',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_13516',
    Name: 'Biglerville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IA_13517',
    Name: 'Strawberry Point',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_MN_13518',
    Name: 'Adrian',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_OH_13519',
    Name: 'Salineville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_KS_13520',
    Name: 'Conway Springs',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_KS_13521', Name: 'Atwood', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MD_13522',
    Name: 'Bowling Green',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_13523', Name: 'Harper', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_13524',
    Name: 'Rossville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_13525', Name: 'Elkader', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IN_13526',
    Name: 'Shelburn',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OK_13527', Name: 'Brushy', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NC_13528',
    Name: 'Balfour',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_AR_13529', Name: 'Hughes', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_VA_13530', Name: 'Arcola', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_CA_13531',
    Name: 'Callender',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_13532',
    Name: 'Clear Lake Shores',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_OK_13533',
    Name: 'Geronimo',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_MO_13534', Name: 'Oran', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_VA_13535',
    Name: 'Courtland',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NY_13536',
    Name: 'Harbor Isle',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WV_13537',
    Name: 'Brush Fork',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IL_13538',
    Name: 'Sandoval',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_13539',
    Name: 'Waterville',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_KS_13540', Name: 'Victoria', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OH_13541', Name: 'Bethesda', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AR_13542', Name: 'Rison', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_TX_13543',
    Name: 'Las Quintas Fronterizas',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MI_13544',
    Name: 'Scottville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_VA_13545',
    Name: 'Onancock',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_VA_13546',
    Name: 'Weber City',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_IN_13547', Name: 'Warren', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_AZ_13548',
    Name: 'Meadview',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_KS_13549', Name: 'Auburn', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MN_13550',
    Name: 'Mantorville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_13551', Name: 'Claude', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_13552',
    Name: 'Byhalia',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_VA_13553',
    Name: 'Halifax',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WA_13554',
    Name: 'Basin City',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_13555',
    Name: 'Newburg',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_AR_13556', Name: 'Ola', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_OH_13557', Name: 'Clinton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_FL_13558',
    Name: 'Palmona Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_MN_13559', Name: 'Fulda', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_AR_13560', Name: 'Diaz', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_MS_13561',
    Name: 'DeLisle',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WY_13562',
    Name: 'Clearview Acres',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_CA_13563',
    Name: 'Newcastle',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_13564',
    Name: 'Heidelberg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_RI_13565',
    Name: 'Harmony',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_MD_13566',
    Name: 'Highland',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_13567', Name: 'China', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_WI_13568', Name: 'Iola', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_GA_13569', Name: 'Bogart', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CA_13570',
    Name: 'Pasatiempo',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_LA_13571',
    Name: 'Paincourtville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WI_13572',
    Name: 'Theresa',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NJ_13573',
    Name: 'Beach Haven',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_13574',
    Name: 'Pocono Ranch Lands',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_13575',
    Name: 'Renovo',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_13576',
    Name: 'Harbor Springs',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OH_13577', Name: 'Highpoint', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_GA_13578',
    Name: 'McCaysville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PR_13579',
    Name: 'Cayuco',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_OH_13580', Name: 'Hanover', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_SC_13581',
    Name: 'Due West',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_OK_13582',
    Name: 'Maysville',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MI_13583',
    Name: 'Coleman',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_13584',
    Name: 'Hallstead',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_13585',
    Name: 'Monticello',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MO_13586', Name: 'Archie', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_FL_13587', Name: 'Molino', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_OK_13588',
    Name: 'Wetumka',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_TX_13589', Name: 'Ames', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_13590',
    Name: 'Glenwood City',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MO_13591',
    Name: 'Marthasville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_ND_13592',
    Name: 'Burlington',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_PA_13593',
    Name: 'North Apollo',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OH_13594',
    Name: 'New Madison',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NY_13595',
    Name: 'Hillside Lake',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_13596',
    Name: 'César Chávez',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_LA_13597',
    Name: 'Barataria',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_AR_13598', Name: 'Avilla', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_TX_13599', Name: 'Spur', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OH_13600',
    Name: 'Geneva-on-the-Lake',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_AR_13601', Name: 'Newark', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_WA_13602',
    Name: 'Morton',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NJ_13603',
    Name: 'Surf City',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_HI_13604',
    Name: 'Kurtistown',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_CA_13605',
    Name: 'Laytonville',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_FL_13606', Name: 'Archer', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_OK_13607', Name: 'Byng', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_GA_13608', Name: 'Alto', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_OK_13609', Name: 'Thomas', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_OK_13610', Name: 'Konawa', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_AL_13611',
    Name: 'Locust Fork',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NJ_13612',
    Name: 'Lake Telemark',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_VA_13613',
    Name: 'Kenbridge',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MD_13614',
    Name: 'Charlestown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_GA_13615', Name: 'Broxton', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WA_13616',
    Name: 'Crocker',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_HI_13617',
    Name: 'Haliimaile',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_RI_13618',
    Name: 'Ashaway',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_TN_13619',
    Name: 'Kenton',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_IL_13620', Name: 'Ladd', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_VA_13621',
    Name: 'Brightwood',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MO_13622',
    Name: 'St. Martins',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_13623',
    Name: 'Grizzly Flats',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_13624',
    Name: 'Laurel Hill',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MO_13625',
    Name: 'Lincoln',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_13626',
    Name: 'Waverly',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CT_13627',
    Name: 'Woodbury Center',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_OR_13628', Name: 'Drain', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_KS_13629', Name: 'Hoxie', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_VA_13630',
    Name: 'Harriston',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IL_13631',
    Name: 'St. Anne',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WV_13632',
    Name: 'Gilbert Creek',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NM_13633',
    Name: 'Atoka',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_WI_13634',
    Name: 'Shullsburg',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TX_13635',
    Name: 'Oyster Creek',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NE_13636',
    Name: 'Plainview',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_KS_13637', Name: 'Elwood', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_CA_13638',
    Name: 'Maricopa',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_ID_13639', Name: 'Tyhee', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_PA_13640',
    Name: 'Plymptonville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_13641',
    Name: 'Hanna City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_GA_13642',
    Name: 'Franklin Springs',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_MI_13643', Name: 'Edmore', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_LA_13644',
    Name: 'French Settlement',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PR_13645',
    Name: 'Rincón',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MN_13646',
    Name: 'Grand Meadow',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MO_13647',
    Name: 'Rock Port',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IL_13648', Name: 'Sidney', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_IL_13649',
    Name: 'Ashland',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_13650',
    Name: 'Loretto',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_13651',
    Name: 'Helena',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IL_13652', Name: 'Minier', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_KY_13653',
    Name: 'Stearns',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OH_13654', Name: 'Blue Jay', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MI_13655',
    Name: 'Lake Michigan Beach',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_VA_13656', Name: 'Gretna', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_IL_13657', Name: 'Toledo', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_ND_13658',
    Name: 'Velva',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NY_13659',
    Name: 'Weston Mills',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_13660',
    Name: 'Kensington',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AR_13661',
    Name: 'Sulphur Springs',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NY_13662',
    Name: 'Port Byron',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MN_13663',
    Name: 'Renville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_KY_13664',
    Name: 'Irvington',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_13665',
    Name: 'Dalton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_13666',
    Name: 'Como',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_TX_13667', Name: 'Muniz', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OH_13668',
    Name: 'Logan Elm Village',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_KS_13669', Name: 'Haven', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_AZ_13670',
    Name: 'Many Farms',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_AZ_13671', Name: 'Dilkon', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_NC_13672',
    Name: 'Franklinville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MI_13673',
    Name: 'Kent City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_CA_13674', Name: 'Clay', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_SC_13675',
    Name: 'East Sumter',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NE_13676',
    Name: 'Battle Creek',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NY_13677',
    Name: 'Richfield Springs',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WV_13678',
    Name: 'Tornado',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_13679',
    Name: 'Boswell',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_13680',
    Name: 'Silver Grove',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MN_13681',
    Name: 'Harris',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_13682',
    Name: 'Malaga',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_13683',
    Name: 'Mariposa',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MS_13684',
    Name: 'Saucier',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NJ_13685',
    Name: 'Kingston',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NM_13686',
    Name: 'El Rancho',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_IL_13687', Name: 'Hebron', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_PA_13688',
    Name: 'Curtisville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AL_13689', Name: 'Chatom', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_PA_13690',
    Name: 'Cornwells Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_LA_13691', Name: 'Hayes', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_PA_13692',
    Name: 'Georgetown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_13693',
    Name: 'New Richland',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_FL_13694',
    Name: 'Howey-in-the-Hills',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_13695',
    Name: 'West Fairview',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_13696',
    Name: 'Anderson Island',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KS_13697',
    Name: 'Lincoln Center',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_TX_13698',
    Name: 'Valley Mills',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_OH_13699',
    Name: 'New Waterford',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_OH_13700',
    Name: 'Apple Creek',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_KY_13701',
    Name: 'Brodhead',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_AL_13702', Name: 'Axis', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_OH_13703',
    Name: 'Westfield Center',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NE_13704',
    Name: 'Oakland',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MI_13705',
    Name: 'Beaverton',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SD_13706',
    Name: 'Baltic',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_GA_13707', Name: 'Comer', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_SC_13708',
    Name: 'Holly Hill',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_AK_13709',
    Name: 'Bear Creek',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_WI_13710',
    Name: 'Marion',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_13711', Name: 'Chico', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MI_13712',
    Name: 'Quinnesec',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PR_13713',
    Name: 'Coto Norte',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_KY_13714',
    Name: 'Hebron Estates',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_13715',
    Name: 'Williamsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_13716',
    Name: 'Goodhue',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MI_13717',
    Name: 'Memphis',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OK_13718', Name: 'Bray', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_LA_13719',
    Name: 'Krotz Springs',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_13720',
    Name: 'Knightsen',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AZ_13721',
    Name: 'Claypool',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_GA_13722',
    Name: 'Buchanan',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NM_13723',
    Name: 'Hurley',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_KS_13724', Name: 'St. John', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OH_13725', Name: 'Reno', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MO_13726',
    Name: 'Stanberry',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OK_13727',
    Name: 'Oologah',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NY_13728',
    Name: 'Palenville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_VA_13729',
    Name: 'Bowling Green',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_13730',
    Name: 'Tatamy',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PR_13731',
    Name: 'Sabana Eneas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_NJ_13732',
    Name: 'Vienna',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OK_13733', Name: 'Boley', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_VA_13734',
    Name: 'Buchanan',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MN_13735',
    Name: 'Madison Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_ND_13736',
    Name: 'Shell Valley',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_SD_13737',
    Name: 'Lemmon',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_VA_13738', Name: 'Dryden', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_OR_13739', Name: 'Lowell', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_OH_13740',
    Name: 'Jeffersonville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_LA_13741', Name: 'Ama', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_PA_13742',
    Name: 'Springmont',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_13743',
    Name: 'Milford',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_13744',
    Name: 'Christiana',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_13745',
    Name: 'Woodacre',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_13746', Name: 'Holland', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_13747',
    Name: 'Duboistown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PR_13748',
    Name: 'Animas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_OR_13749', Name: 'Coburg', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_NE_13750', Name: 'Wisner', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_TX_13751', Name: 'Frankston', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_13752', Name: 'Cuba', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_TX_13753',
    Name: 'New Summerfield',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AK_13754',
    Name: 'Farm Loop',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_MN_13755',
    Name: 'Little Rock',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_13756',
    Name: 'Marion',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_13757',
    Name: 'Silverhill',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_OR_13758',
    Name: 'Rose Lodge',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_OH_13759',
    Name: 'Duncan Falls',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MN_13760',
    Name: 'Nicollet',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_13761',
    Name: 'Yosemite Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_13762',
    Name: 'Kingston',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NE_13763',
    Name: 'Terrytown',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_OK_13764',
    Name: 'Mooreland',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_OR_13765', Name: 'Yamhill', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_WI_13766',
    Name: 'Princeton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_13767',
    Name: 'Hometown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MO_13768', Name: 'Paris', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_NC_13769',
    Name: 'Roseboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_13770',
    Name: 'Factoryville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_HI_13771', Name: 'Hawi', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_UT_13772',
    Name: 'Fountain Green',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_VA_13773',
    Name: 'Clarksville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_13774',
    Name: 'Stiles',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_13775',
    Name: 'Leadwood',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NY_13776',
    Name: 'North Ballston Spa',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_DE_13777',
    Name: 'Bellefonte',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_PA_13778',
    Name: 'West Mayfield',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OK_13779',
    Name: 'Central High',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MS_13780',
    Name: 'Goodman',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CA_13781',
    Name: 'Sunnyside-Tahoe City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CO_13782',
    Name: 'Wiggins',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PA_13783',
    Name: 'Duncansville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_13784',
    Name: 'Bellerose',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_KS_13785',
    Name: 'Pleasanton',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_PA_13786',
    Name: 'Hyde',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_13787', Name: 'Boling', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_GA_13788',
    Name: 'Dutch Island',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NE_13789',
    Name: 'Burwell',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_KY_13790',
    Name: 'Coldstream',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_OR_13791',
    Name: 'Cascade Locks',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_ID_13792', Name: 'Ucon', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_IL_13793', Name: 'Toulon', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_AR_13794',
    Name: 'West Crossett',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_13795',
    Name: 'Marshallton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_13796',
    Name: 'Lecompte',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_LA_13797',
    Name: 'Sibley',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_13798',
    Name: 'Millbourne',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AZ_13799',
    Name: 'Ehrenberg',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_IL_13800', Name: 'Homer', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_WY_13801',
    Name: 'Pine Bluffs',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_TX_13802',
    Name: 'Pine Island',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_LA_13803',
    Name: 'Albany',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OH_13804', Name: 'Hiram', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NM_13805',
    Name: 'Ponderosa Pine',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MO_13806',
    Name: 'Wellsville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_13807', Name: 'Bratenahl', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NM_13808',
    Name: 'Doña Ana',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_IL_13809', Name: 'Atwood', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CA_13810',
    Name: 'Tahoe Vista',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_13811', Name: 'Malta', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_IN_13812',
    Name: 'Long Beach',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MS_13813',
    Name: 'Conehatta',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OH_13814', Name: 'Botkins', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_13815',
    Name: 'Colfax',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_SC_13816',
    Name: 'Lake Secession',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_MI_13817', Name: 'Colon', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_NC_13818',
    Name: 'Advance',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_FL_13819',
    Name: 'Palm Shores',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IA_13820', Name: 'Mapleton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_13821',
    Name: 'Leetsdale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_ID_13822', Name: 'Ponderay', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_WV_13823',
    Name: 'Coal Fork',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_NV_13824', Name: 'Panaca', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_AR_13825',
    Name: 'Yellville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_GA_13826',
    Name: 'Oglethorpe',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_IN_13827',
    Name: 'Remington',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_ND_13828',
    Name: 'Hettinger',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_VA_13829', Name: 'Emory', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_LA_13830',
    Name: 'Midway',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_AL_13831',
    Name: 'Fort Deposit',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PA_13832',
    Name: 'Tresckow',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_LA_13833', Name: 'Dulac', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_PA_13834',
    Name: 'Hastings',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_13835',
    Name: 'Lake Lure',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_13836', Name: 'Lamar', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MA_13837',
    Name: 'West Chatham',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_AL_13838',
    Name: 'Frisco City',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_OR_13839',
    Name: 'Port Orford',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_KS_13840',
    Name: 'Valley Falls',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_PA_13841',
    Name: 'Hawley',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_13842',
    Name: 'Ship Bottom',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MN_13843',
    Name: 'Elbow Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_MO_13844', Name: 'Taos', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_TN_13845', Name: 'Lyles', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_AL_13846',
    Name: 'Ohatchee',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_CO_13847', Name: 'Towaoc', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_CA_13848',
    Name: 'Elkhorn',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_13849',
    Name: 'Cloverport',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_TX_13850',
    Name: 'Ranchos Penitas West',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IN_13851', Name: 'Grabill', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_MO_13852',
    Name: 'Clarkton',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NC_13853',
    Name: 'Lucama',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CO_13854',
    Name: 'Julesburg',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PA_13855',
    Name: 'Republic',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_FL_13856', Name: 'Webster', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_IL_13857',
    Name: 'Windsor',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_13858',
    Name: 'Norris City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NM_13859',
    Name: 'San Antonito',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IL_13860',
    Name: 'Griggsville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_13861',
    Name: 'Great Neck Gardens',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MT_13862',
    Name: 'Whitehall',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_FL_13863',
    Name: 'Navarre Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_OK_13864',
    Name: 'Barnsdall',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NC_13865',
    Name: 'Southmont',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_KY_13866',
    Name: 'Nortonville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_TX_13867', Name: 'Lindsay', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IN_13868', Name: 'Oxford', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_WV_13869',
    Name: 'Monongah',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WA_13870',
    Name: 'Yarrow Point',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_13871',
    Name: 'Big River',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MI_13872',
    Name: 'Fruitport',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NC_13873',
    Name: 'Banner Elk',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_ID_13874', Name: 'Riverside', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_MT_13875',
    Name: 'Helena Flats',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_KY_13876',
    Name: 'Augusta',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_AR_13877',
    Name: 'Mineral Springs',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MI_13878',
    Name: 'Bingham Farms',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_13879',
    Name: 'Andalusia',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_FL_13880', Name: 'Bronson', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_WI_13881',
    Name: 'Reedsville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_SD_13882',
    Name: 'Garretson',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IA_13883', Name: 'Gilbert', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NY_13884',
    Name: 'Union Springs',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_13885',
    Name: 'Third Lake',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OK_13886',
    Name: 'Kellyville',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_LA_13887',
    Name: 'Bayou Country Club',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_13888', Name: 'Tenaha', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_13889',
    Name: 'Chester Gap',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CO_13890',
    Name: 'Minturn',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CA_13891',
    Name: 'Alpaugh',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MI_13892',
    Name: 'Grass Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_13893',
    Name: 'Hasley Canyon',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_13894',
    Name: 'Gorman',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OR_13895', Name: 'Athena', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_IA_13896',
    Name: 'Elk Run Heights',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_ND_13897',
    Name: 'Killdeer',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_KY_13898',
    Name: 'Camargo',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_OK_13899',
    Name: 'Fletcher',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NC_13900',
    Name: 'Boonville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_13901',
    Name: 'La Harpe',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_13902',
    Name: 'Palo Cedro',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MN_13903', Name: 'Redby', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_TX_13904',
    Name: 'McKinney Acres',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_LA_13905',
    Name: 'Banks Springs',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NC_13906',
    Name: 'Mount Gilead',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_LA_13907',
    Name: 'Woodworth',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_IA_13908', Name: 'Villisca', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_VA_13909',
    Name: 'Skyland Estates',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_NE_13910', Name: 'Alma', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_CO_13911', Name: 'Fowler', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_OK_13912', Name: 'Meeker', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_LA_13913', Name: 'Elton', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_PA_13914',
    Name: 'Bradford Woods',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_13915',
    Name: 'Mountain Ranch',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_GA_13916', Name: 'Nahunta', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WA_13917',
    Name: 'Langley',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_13918',
    Name: 'Gerber',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_13919',
    Name: 'Light Oak',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_13920',
    Name: 'Covelo',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_13921', Name: 'Kempner', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OK_13922',
    Name: 'Oakland',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_CA_13923',
    Name: 'Camanche North Shore',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NE_13924',
    Name: 'Stromsburg',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_DE_13925',
    Name: 'Greenwood',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_NY_13926',
    Name: 'Great Bend',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WV_13927',
    Name: 'Washington',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MI_13928',
    Name: 'Marcellus',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_MT_13929', Name: 'Plains', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_OH_13930', Name: 'Payne', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_13931',
    Name: 'Birchwood Lakes',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OK_13932', Name: 'Okeene', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_TX_13933', Name: 'Runge', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_13934', Name: 'Lorenzo', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_13935',
    Name: 'Weeki Wachee Gardens',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_13936', Name: 'Southmayd', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_ID_13937', Name: 'Lapwai', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_MS_13938',
    Name: 'Plantersville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IA_13939',
    Name: 'Springville',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_IN_13940', Name: 'Andrews', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_CA_13941',
    Name: 'Matheny',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_13942', Name: 'Sardinia', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_13943',
    Name: 'Kettleman City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_13944',
    Name: 'Yemassee',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NC_13945',
    Name: 'South Henderson',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_NV_13946', Name: 'Owyhee', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_TX_13947',
    Name: 'Rocksprings',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MO_13948',
    Name: 'La Monte',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_13949', Name: 'Gruver', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OH_13950',
    Name: 'Pheasant Run',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_IL_13951', Name: 'Albers', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_WI_13952', Name: 'Dane', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_WI_13953',
    Name: 'Lake Koshkonong',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_13954',
    Name: 'Forrest',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_13955',
    Name: 'Cole Camp',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IN_13956', Name: 'Oolitic', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_CA_13957',
    Name: 'Sea Ranch',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_13958', Name: 'Timpson', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_13959',
    Name: 'Acalanes Ridge',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MO_13960', Name: 'Horine', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_PR_13961',
    Name: 'Culebra',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_IA_13962', Name: 'Fairbank', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_KY_13963',
    Name: 'Heritage Creek',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_TX_13964', Name: 'Waelder', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_CO_13965', Name: 'Pierce', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_CA_13966',
    Name: 'Johnstonville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_13967',
    Name: 'Petersburg',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_TN_13968',
    Name: 'Red Boiling Springs',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_IA_13969', Name: 'Baxter', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_HI_13970', Name: 'Kaaawa', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_MN_13971',
    Name: 'Fairfax',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IN_13972',
    Name: 'Cordry Sweetwater Lakes',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_KS_13973', Name: 'Satanta', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_CT_13974',
    Name: 'North Grosvenor Dale',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_MO_13975',
    Name: 'Cool Valley',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MI_13976',
    Name: 'Stevensville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_13977',
    Name: 'Big Rock',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_13978',
    Name: 'Mifflinville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_FL_13979', Name: 'Yalaha', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MD_13980',
    Name: 'Cavetown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_IA_13981',
    Name: 'Mechanicsville',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_AR_13982', Name: 'Midway', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_PA_13983',
    Name: 'Woodland Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_NV_13984', Name: 'Alamo', stateId: 'NV', stateName: 'Nevada'},
  {Id: 'CIT_US_MO_13985', Name: 'Purdy', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MI_13986',
    Name: 'Petersburg',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PR_13987',
    Name: 'Liborio Negrón Torres',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_WI_13988',
    Name: 'Boyceville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_13989', Name: 'Calvert', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MN_13990',
    Name: 'Dellwood',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TN_13991',
    Name: 'Lone Oak',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_13992',
    Name: 'Dale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_13993',
    Name: 'Belle',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IL_13994',
    Name: 'Warrensburg',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WV_13995',
    Name: 'Lubeck',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WA_13996',
    Name: 'Clear Lake',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MN_13997',
    Name: 'Edgerton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AZ_13998',
    Name: 'St. Michaels',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WA_13999',
    Name: 'Rock Island',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PR_14000',
    Name: 'Sabana',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_TX_14001',
    Name: 'Ransom Canyon',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_KS_14002',
    Name: 'Rossville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_TX_14003', Name: 'Daisetta', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MO_14004', Name: 'Edina', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_WY_14005',
    Name: 'Guernsey',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {Id: 'CIT_US_OR_14006', Name: 'Joseph', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_ID_14007',
    Name: 'Arbon Valley',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {Id: 'CIT_US_IL_14008', Name: 'Wamac', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_TX_14009',
    Name: 'Randolph AFB',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IA_14010', Name: 'Lake Park', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_14011',
    Name: 'Wallenpaupack Lake Estates',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_14012',
    Name: 'New Market',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_CA_14013',
    Name: 'Santa Margarita',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WA_14014',
    Name: 'Tonasket',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_OH_14015',
    Name: 'Liberty Center',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OH_14016', Name: 'Ansonia', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_MI_14017', Name: 'Pigeon', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_IA_14018', Name: 'Laurens', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_14019', Name: 'St. Ansgar', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_TX_14020', Name: 'Knox City', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_ME_14021',
    Name: 'South Windham',
    stateId: 'ME',
    stateName: 'Maine',
  },
  {Id: 'CIT_US_KS_14022', Name: 'La Cygne', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IN_14023',
    Name: 'Russiaville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MN_14024',
    Name: 'Atwater',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WA_14025',
    Name: 'Puget Island',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_TX_14026', Name: 'Gholson', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_LA_14027',
    Name: 'Lemannville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_14028',
    Name: 'Schaefferstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_14029',
    Name: 'Lexington',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_FL_14030',
    Name: 'Crescent Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_14031',
    Name: 'Royalton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_GA_14032',
    Name: 'Hamilton',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MN_14033',
    Name: 'Shafer',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PR_14034',
    Name: 'Garrochales',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_AZ_14035',
    Name: 'Arizona Village',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NM_14036',
    Name: 'Twin Lakes',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_WA_14037',
    Name: 'Point Roberts',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CO_14038',
    Name: 'Parachute',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_IL_14039',
    Name: 'Chatsworth',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_14040',
    Name: 'Sykesville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_14041', Name: 'Rosharon', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_GA_14042',
    Name: 'Phillipsburg',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_IL_14043',
    Name: 'Central City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_NE_14044', Name: 'Grant', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_IA_14045', Name: 'Malvern', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_TX_14046',
    Name: 'Siesta Shores',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_FL_14047',
    Name: 'Mexico Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AR_14048',
    Name: 'Lewisville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_14049',
    Name: 'Simpson',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_14050',
    Name: 'Hill Country Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_OK_14051',
    Name: 'Kenwood',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_KY_14052',
    Name: 'Greenup',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_AR_14053',
    Name: 'Wooster',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CO_14054',
    Name: 'Georgetown',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_IL_14055', Name: 'Macon', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_WI_14056',
    Name: 'Cashton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_SC_14057',
    Name: 'Riverview',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_TX_14058', Name: 'Clint', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_14059',
    Name: 'Billings',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MS_14060',
    Name: 'Mantachie',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MI_14061',
    Name: 'Carson City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WY_14062',
    Name: 'Marbleton',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_PR_14063',
    Name: 'Quebrada del Agua',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_TN_14064',
    Name: 'Wildwood',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_OH_14065',
    Name: 'Craig Beach',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CA_14066',
    Name: 'Westhaven-Moonstone',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_14067',
    Name: 'Prospect',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IL_14068', Name: 'Energy', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_SC_14069',
    Name: 'Catawba',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NY_14070',
    Name: 'Roslyn Harbor',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_GA_14071',
    Name: 'Mountain City',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PR_14072',
    Name: 'Mucarabones',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_AZ_14073', Name: 'Bouse', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_NE_14074', Name: 'Pender', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_LA_14075',
    Name: 'Merryville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OH_14076', Name: 'Maineville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OH_14077',
    Name: 'Walnut Creek',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_TN_14078',
    Name: 'Riceville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_AR_14079',
    Name: 'Highland',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_14080',
    Name: 'Lenape Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_14081', Name: 'Pandora', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MD_14082',
    Name: 'Lonaconing',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_HI_14083', Name: 'Olinda', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_OH_14084', Name: 'Lakeview', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CO_14085',
    Name: 'Gilcrest',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_IA_14086', Name: 'Glidden', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_14087', Name: 'Sheffield', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MI_14088', Name: 'Burt', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_IL_14089',
    Name: 'Divernon',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_14090', Name: 'Tornillo', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VT_14091',
    Name: 'South Barre',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_CA_14092',
    Name: 'Fairmead',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IN_14093', Name: 'Akron', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_WA_14094',
    Name: 'Dash Point',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_VA_14095',
    Name: 'Union Hall',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_VA_14096', Name: 'Dooms', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_CA_14097',
    Name: 'Taft Mosswood',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OK_14098', Name: 'Caddo', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_IN_14099', Name: 'Cayuga', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_OH_14100', Name: 'Rockford', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_14101',
    Name: 'East Conemaugh',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_14102',
    Name: 'Sweetser',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_WA_14103',
    Name: 'Browns Point',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_VA_14104',
    Name: 'Glasgow',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_14105',
    Name: 'Ellport',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_14106',
    Name: 'Clear Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_UT_14107', Name: 'Elwood', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_GA_14108',
    Name: 'Rochelle',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NY_14109',
    Name: 'Rosendale Hamlet',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_LA_14110',
    Name: 'Leonville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CO_14111',
    Name: 'Floyd Hill',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_TX_14112', Name: 'Redwater', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_14113',
    Name: 'Gratton',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_KS_14114', Name: 'Peabody', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_OH_14115',
    Name: 'Continental',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NJ_14116',
    Name: 'Cedarville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MS_14117',
    Name: 'Jonestown',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_14118',
    Name: 'White Haven',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_14119',
    Name: 'Clendenin',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IL_14120',
    Name: 'Danvers',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_14121',
    Name: 'Lost Creek',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_TX_14122',
    Name: 'Fort Davis',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MO_14123',
    Name: 'Princeton',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_14124',
    Name: 'Big Bass Lake',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AR_14125',
    Name: 'Ash Flat',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_14126',
    Name: 'Vinco',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_14127',
    Name: 'Despard',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_14128',
    Name: 'Point Marion',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_14129',
    Name: 'Nashville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_VA_14130',
    Name: 'Brookneal',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_14131',
    Name: 'Fort Dick',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AZ_14132',
    Name: 'Hotevilla-Bacavi',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_CA_14133',
    Name: 'Inverness',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NE_14134', Name: 'Eagle', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_MD_14135', Name: 'Hebron', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_AR_14136',
    Name: 'Mansfield',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_OR_14137', Name: 'Yoncalla', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_KY_14138', Name: 'Clay', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_NY_14139',
    Name: 'Dover Plains',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_14140',
    Name: 'Louisville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_14141', Name: 'Anton', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AL_14142', Name: 'Berry', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_MT_14143', Name: 'Somers', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_NE_14144',
    Name: 'Weeping Water',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_OH_14145', Name: 'Andover', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_14146',
    Name: 'Powers Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MO_14147', Name: 'Stover', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_TX_14148', Name: 'Paducah', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KY_14149',
    Name: 'Clay City',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IN_14150', Name: 'Morocco', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_WV_14151',
    Name: 'Chapmanville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_GA_14152',
    Name: 'Ray City',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NC_14153',
    Name: 'White Plains',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_14154',
    Name: 'Avonia',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SD_14155',
    Name: 'Kyle',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_IL_14156',
    Name: 'South Pekin',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_14157',
    Name: 'Camp Point',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_14158', Name: 'Atwater', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IN_14159', Name: 'Markle', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_UT_14160', Name: 'Ballard', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_OH_14161',
    Name: 'Lowellville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_ID_14162', Name: 'Challis', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_NY_14163',
    Name: 'Livingston Manor',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_14164',
    Name: 'Shell Knob',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_WI_14165', Name: 'Strum', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_PA_14166',
    Name: 'West Kittanning',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CO_14167',
    Name: 'Winter Park',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_TX_14168',
    Name: 'Coyote Acres',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WI_14169',
    Name: 'Frederic',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_AL_14170', Name: 'Steele', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_IA_14171', Name: 'Winfield', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IN_14172',
    Name: 'Ogden Dunes',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_VA_14173',
    Name: 'Glenvar',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WI_14174',
    Name: 'Oakfield',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_SC_14175',
    Name: 'Oakland',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_IL_14176',
    Name: 'Martinsville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_14177',
    Name: 'Hoopers Creek',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_KS_14178', Name: 'Erie', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MN_14179',
    Name: 'Sherburn',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NE_14180',
    Name: 'Creighton',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_VA_14181',
    Name: 'Occoquan',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_14182',
    Name: 'Foster Brook',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_14183',
    Name: 'West Hills',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_14184',
    Name: 'Hebron',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OK_14185',
    Name: 'Boise City',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_CA_14186',
    Name: 'Shandon',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PR_14187',
    Name: 'Pueblito del Río',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_IA_14188', Name: 'Lake View', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OK_14189',
    Name: 'Talihina',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NY_14190',
    Name: 'Jefferson Heights',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_14191',
    Name: 'Glasgow',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WY_14192',
    Name: 'Moorcroft',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {Id: 'CIT_US_NE_14193', Name: 'Bayard', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_NY_14194',
    Name: 'South Corning',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_14195',
    Name: 'Bull Valley',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_14196',
    Name: 'Thomasboro',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_14197',
    Name: 'Buena',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MD_14198',
    Name: 'Pylesville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MS_14199',
    Name: 'Brooksville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_AR_14200', Name: 'Dierks', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_IL_14201',
    Name: 'Washburn',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_14202',
    Name: 'Marianne',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_14203',
    Name: 'Hilbert',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_VA_14204',
    Name: 'Pembroke',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_SD_14205',
    Name: 'Gettysburg',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MO_14206',
    Name: 'Maysville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_NY_14207', Name: 'Tivoli', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_TX_14208', Name: 'Tom Bean', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_UT_14209',
    Name: 'Spring City',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_SC_14210',
    Name: 'Van Wyck',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MO_14211',
    Name: 'Appleton City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WA_14212',
    Name: 'Coulee Dam',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_TN_14213',
    Name: 'Luttrell',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WI_14214',
    Name: 'Little Round Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MI_14215',
    Name: 'Baldwin',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_MN_14216', Name: 'Elgin', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_GA_14217',
    Name: 'Remerton',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_TX_14218',
    Name: 'Jamaica Beach',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MN_14219',
    Name: 'Wanamingo',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_KY_14220',
    Name: 'Ryland Heights',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CA_14221',
    Name: 'Crowley Lake',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_14222', Name: 'Conrad', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WI_14223',
    Name: 'Athens',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_14224',
    Name: 'New Columbia',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AK_14225', Name: 'Chevak', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_UT_14226', Name: 'Daniel', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_IL_14227',
    Name: 'Shawneetown',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_14228', Name: 'Hackberry', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_14229',
    Name: 'Captains Cove',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_14230',
    Name: 'Galeton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_14231',
    Name: 'Piney View',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_14232',
    Name: 'Rennerdale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_14233',
    Name: 'Milesburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_NV_14234', Name: 'Caliente', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_CA_14235',
    Name: 'Plymouth',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_14236',
    Name: 'Stedman',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OR_14237',
    Name: 'Pacific City',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_PA_14238',
    Name: 'Cochranton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_14239',
    Name: 'Maxwell',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MS_14240',
    Name: 'Mooreville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MA_14241',
    Name: 'Marion Center',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_MN_14242',
    Name: 'Lake St. Croix Beach',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_14243', Name: 'Rio Vista', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NM_14244',
    Name: 'Laguna',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_WA_14245',
    Name: 'Palouse',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_14246',
    Name: 'Hustisford',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NM_14247',
    Name: 'Indian Hills',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_OH_14248',
    Name: 'Millersport',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_LA_14249',
    Name: 'Estherwood',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_AR_14250',
    Name: 'Oak Grove Heights',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_OH_14251',
    Name: 'Williamsport',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CA_14252',
    Name: 'Bolinas',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VA_14253',
    Name: 'Belview',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IL_14254',
    Name: 'Blue Mound',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_KY_14255',
    Name: 'Flat Lick',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_TX_14256',
    Name: 'New Waverly',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AK_14257',
    Name: 'Susitna North',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_HI_14258', Name: 'Punaluu', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_AL_14259',
    Name: 'Phil Campbell',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_CA_14260',
    Name: 'West Park',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IN_14261',
    Name: 'Farmersburg',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_GA_14262',
    Name: 'Cave Spring',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_VA_14263',
    Name: 'Edinburg',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NM_14264',
    Name: 'Angel Fire',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_CA_14265',
    Name: 'Bradbury',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_14266',
    Name: 'Ashippun',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_OK_14267',
    Name: 'Forest Park',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_MN_14268', Name: 'Tyler', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_PA_14269',
    Name: 'Osceola Mills',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_14270',
    Name: 'Knox',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_14271',
    Name: 'Bear Creek',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_AZ_14272',
    Name: 'Dudleyville',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_IA_14273', Name: 'Panora', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_AR_14274', Name: 'London', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_TN_14275',
    Name: 'Rutherford',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_LA_14276', Name: 'Start', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_TN_14277',
    Name: 'Altamont',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NM_14278',
    Name: 'Texico',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_14279',
    Name: 'Kersey',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_14280', Name: 'Palo', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WA_14281',
    Name: 'Republic',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MI_14282',
    Name: 'Bellaire',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_14283',
    Name: 'Assumption',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_KY_14284',
    Name: 'Bloomfield',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_LA_14285',
    Name: 'Cullen',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_14286',
    Name: 'Speers',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_VA_14287', Name: 'Bracey', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_VA_14288',
    Name: 'Bassett',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_TX_14289',
    Name: 'Tiki Island',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AK_14290',
    Name: 'Sand Point',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_IN_14291',
    Name: 'St. Paul',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IL_14292',
    Name: 'Flanagan',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IL_14293', Name: 'Cobden', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_TX_14294',
    Name: 'Berryville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_VA_14295',
    Name: 'Amelia Court House',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_14296', Name: 'Powderly', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_14297', Name: 'Greene', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IL_14298', Name: 'Nauvoo', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CA_14299',
    Name: 'Hydesville',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_14300', Name: 'Bogata', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AL_14301',
    Name: 'Double Springs',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_OK_14302', Name: 'Wister', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_MT_14303',
    Name: 'Absarokee',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_OK_14304', Name: 'Maud', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_SD_14305',
    Name: 'Clark',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_LA_14306', Name: 'Lydia', stateId: 'LA', stateName: 'Louisiana'},
  {Id: 'CIT_US_OR_14307', Name: 'Donald', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_IA_14308', Name: 'Hamburg', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_VA_14309',
    Name: 'Elliston',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_LA_14310',
    Name: 'Choctaw',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_KS_14311',
    Name: 'Washington',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_TX_14312',
    Name: 'Los Indios',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_14313', Name: 'Warren', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_14314',
    Name: 'Goreville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_AK_14315', Name: 'Skagway', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_OH_14316', Name: 'Holgate', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_14317', Name: 'Convoy', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_14318',
    Name: 'Chadwicks',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_14319',
    Name: 'Humansville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MN_14320',
    Name: 'Lake Shore',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_VA_14321',
    Name: 'Meadow View',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MS_14322',
    Name: 'Caledonia',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OH_14323', Name: 'Prospect', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OK_14324',
    Name: 'Cherry Tree',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_KS_14325', Name: 'Quinter', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_KS_14326',
    Name: 'Oskaloosa',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_ND_14327',
    Name: 'Cando',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_KY_14328',
    Name: 'South Shore',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MO_14329',
    Name: 'Lilbourn',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MO_14330',
    Name: 'New Franklin',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_14331', Name: 'Grandview', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_WY_14332', Name: 'Upton', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_VA_14333', Name: 'Riner', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_FL_14334',
    Name: 'Franklin Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_14335',
    Name: 'Greenfields',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OR_14336',
    Name: 'Falls City',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_NE_14337',
    Name: 'Shelton',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NM_14338',
    Name: 'Skyline-Ganipa',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_IN_14339', Name: 'Clayton', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_IA_14340', Name: 'Marcus', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_CO_14341', Name: 'Ordway', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_LA_14342',
    Name: 'Catahoula',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_14343',
    Name: 'Shinglehouse',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_14344',
    Name: 'Homestead Base',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_14345',
    Name: 'Stillman Valley',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_14346', Name: 'Tuscarawas', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_VA_14347',
    Name: 'Penhook',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_TN_14348',
    Name: 'New Hope',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_SD_14349',
    Name: 'De Smet',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_UT_14350', Name: 'Aurora', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NJ_14351',
    Name: 'Port Republic',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_TX_14352', Name: 'St. Paul', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IN_14353',
    Name: 'Greenville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_KY_14354',
    Name: 'Northfield',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MO_14355',
    Name: 'Highlandville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_14356', Name: 'Tioga', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_14357',
    Name: 'Bessemer',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_WI_14358', Name: 'Luck', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_MN_14359',
    Name: 'Hector',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_VA_14360',
    Name: 'Deltaville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_ID_14361', Name: 'Ashton', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_MI_14362',
    Name: 'Roscommon',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IA_14363', Name: 'Aplington', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CT_14364',
    Name: 'Canaan',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_WI_14365',
    Name: 'Lake Nebagamon',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WA_14366',
    Name: 'Neah Bay',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MD_14367',
    Name: 'Garrett Park',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NY_14368',
    Name: 'Baxter Estates',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_14369',
    Name: 'Keuka Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MD_14370',
    Name: 'Queen Anne',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OH_14371', Name: 'Frankfort', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_14372',
    Name: 'Gifford',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_14373',
    Name: 'Robert Lee',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WA_14374',
    Name: 'Grand Coulee',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WV_14375',
    Name: 'Hamlin',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NC_14376',
    Name: 'Norlina',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IA_14377', Name: 'Sidney', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CT_14378',
    Name: 'New Hartford Center',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_OK_14379',
    Name: 'Ninnekah',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_PA_14380',
    Name: 'Mountainhome',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_14381',
    Name: 'Strodes Mills',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_14382',
    Name: 'Rawls Springs',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_LA_14383',
    Name: 'Campti',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_LA_14384',
    Name: 'Arnaudville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NJ_14385',
    Name: 'Califon',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_14386',
    Name: 'McClellan Park',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_14387',
    Name: 'Amherst',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_SD_14388',
    Name: 'Martin',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_AL_14389', Name: 'Fyffe', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_KY_14390', Name: 'Auxier', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_WA_14391',
    Name: 'Lakeview',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_IN_14392',
    Name: 'Wolcottville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_TN_14393',
    Name: 'Dodson Branch',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MI_14394',
    Name: 'Reading',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OH_14395', Name: 'Luckey', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_14396',
    Name: 'McLeansville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_ND_14397',
    Name: 'Beach',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_PA_14398',
    Name: 'Saylorsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_14399', Name: 'Maud', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NC_14400',
    Name: 'Alamance',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MN_14401',
    Name: 'Truman',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NC_14402',
    Name: 'Foxfire',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IA_14403', Name: 'George', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AZ_14404',
    Name: 'Rainbow City',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WY_14405',
    Name: 'Ranchester',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_OH_14406',
    Name: 'Fairfield Beach',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_IL_14407', Name: 'Worden', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_AK_14408', Name: 'Akutan', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_TN_14409', Name: 'Obion', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_NJ_14410',
    Name: 'Elwood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MD_14411',
    Name: 'St. Michaels',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MI_14412',
    Name: 'Concord',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CT_14413',
    Name: 'Lakeville',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_CA_14414',
    Name: 'Parklawn',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OR_14415', Name: 'Aurora', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MO_14416',
    Name: 'Mound City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MO_14417',
    Name: 'Crocker',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_GA_14418',
    Name: 'Clermont',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_AL_14419',
    Name: 'Town Creek',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_DE_14420',
    Name: 'Newport',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_CO_14421',
    Name: 'Ridgway',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_WI_14422', Name: 'Rio', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_MT_14423',
    Name: 'Helena West Side',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MN_14424',
    Name: 'Eden Valley',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MN_14425',
    Name: 'East Gull Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_14426', Name: 'Asherton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_14427',
    Name: 'Eagle Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NJ_14428',
    Name: 'Port Norris',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MS_14429',
    Name: 'Ecru',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_TX_14430', Name: 'Riesel', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_14431',
    Name: 'Apalachin',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AR_14432',
    Name: 'Sweet Home',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_IN_14433',
    Name: 'Poseyville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PR_14434',
    Name: 'Candelero Arriba',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_OH_14435', Name: 'Wolfhurst', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_14436',
    Name: 'Astoria',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_14437',
    Name: 'Westfield',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_14438',
    Name: 'Knights Landing',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_14439',
    Name: 'Kissee Mills',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MT_14440',
    Name: 'Churchill',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_CA_14441',
    Name: 'Lompico',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_14442', Name: 'Gorman', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_14443', Name: 'Chauncey', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AL_14444', Name: 'Egypt', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_CO_14445', Name: 'Ouray', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_MN_14446',
    Name: 'Taylors Falls',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_14447',
    Name: 'Milford Square',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OR_14448',
    Name: 'Island City',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_TX_14449',
    Name: 'Point Venture',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CA_14450',
    Name: 'Riverdale Park',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_14451',
    Name: 'Carlton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_14452',
    Name: 'Thornton',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IN_14453', Name: 'Sunman', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PA_14454',
    Name: 'Fairhope',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_14455', Name: 'Maypearl', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_14456', Name: 'Wakeman', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_SD_14457',
    Name: 'Hill City',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_WI_14458',
    Name: 'Potter Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_14459',
    Name: 'New Bedford',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_14460',
    Name: 'Rosendale',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_GA_14461', Name: 'Meigs', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_PA_14462',
    Name: 'New Philadelphia',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OK_14463',
    Name: 'Fairland',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_PA_14464',
    Name: 'Belle Vernon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AR_14465',
    Name: 'Horatio',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_FL_14466',
    Name: 'Fanning Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_14467', Name: 'Arp', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_14468', Name: 'St. Jo', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_14469',
    Name: 'Velda Village Hills',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_14470',
    Name: 'Central City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_14471',
    Name: 'Melville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_ID_14472', Name: 'Plummer', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_AR_14473', Name: 'Kibler', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_NY_14474',
    Name: 'Alexandria Bay',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_14475', Name: 'Wink', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_14476', Name: 'Newark', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NC_14477',
    Name: 'Castle Hayne',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_14478',
    Name: 'Celoron',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IN_14479', Name: 'Lynn', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_AR_14480', Name: 'Luxora', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_IA_14481',
    Name: 'University Heights',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_IL_14482',
    Name: 'Lovington',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_14483',
    Name: 'Abbottstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_14484',
    Name: 'Point Lookout',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_KY_14485',
    Name: 'Wurtland',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_NV_14486', Name: 'Schurz', stateId: 'NV', stateName: 'Nevada'},
  {Id: 'CIT_US_OH_14487', Name: 'Glandorf', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_ID_14488', Name: 'Cascade', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_IA_14489', Name: 'De Soto', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_14490',
    Name: 'Pen Mar',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_14491',
    Name: 'Rhodhiss',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WI_14492',
    Name: 'Dickeyville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_14493',
    Name: 'Lily Lake',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_14494',
    Name: 'Callender Lake',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_14495', Name: 'Groveton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_14496',
    Name: 'Pocono Pines',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SD_14497',
    Name: 'Parker',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_KS_14498',
    Name: 'Overbrook',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_IA_14499', Name: 'Riverside', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OH_14500',
    Name: 'Lake Waynoka',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_14501',
    Name: 'Royalton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_14502',
    Name: 'Bolivar',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_UT_14503', Name: 'Paradise', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NY_14504',
    Name: 'Delevan',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WV_14505',
    Name: 'Mallory',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_SD_14506',
    Name: 'Tyndall',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_PA_14507',
    Name: 'Lincoln',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KS_14508', Name: 'Lyndon', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_ND_14509',
    Name: 'Thompson',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_IN_14510',
    Name: 'Pierceton',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_KS_14511', Name: 'Plains', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_ND_14512',
    Name: 'Kenmare',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_TX_14513', Name: 'Hart', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OK_14514', Name: 'Afton', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_OH_14515', Name: 'Mantua', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_14516',
    Name: 'Gaston',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_14517',
    Name: 'Beale AFB',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_14518',
    Name: 'Watterson Park',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CA_14519',
    Name: 'McCloud',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_14520',
    Name: 'Baudette',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_KS_14521', Name: 'Altamont', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_VA_14522',
    Name: 'Cape Charles',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_SC_14523',
    Name: 'Heath Springs',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NM_14524',
    Name: 'San Pablo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_CA_14525',
    Name: 'Julian',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_14526',
    Name: 'Plandome Heights',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_KS_14527', Name: 'Chetopa', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_CA_14528',
    Name: 'Silverado Resort',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_14529', Name: 'Bradner', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_14530', Name: 'Kingston', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AZ_14531',
    Name: 'Shongopovi',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WI_14532',
    Name: 'Elkhart Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PR_14533',
    Name: 'María Antonia',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_TN_14534', Name: 'Gray', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_MS_14535',
    Name: 'Friars Point',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IN_14536', Name: 'Walton', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_CA_14537',
    Name: 'Cedar Ridge',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_GA_14538', Name: 'Dasher', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_OH_14539', Name: 'Danville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NH_14540',
    Name: 'Seabrook Beach',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_MT_14541',
    Name: 'Browning',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_IL_14542',
    Name: 'Rosiclare',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_14543',
    Name: 'New London',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_NY_14544', Name: 'Rapids', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_PA_14545',
    Name: 'Yoe',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OK_14546', Name: 'Peggs', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_OK_14547', Name: 'Oilton', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_NY_14548', Name: 'Quogue', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_IL_14549', Name: 'Colfax', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MN_14550',
    Name: 'Keewatin',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AL_14551',
    Name: 'Leesburg',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_TN_14552',
    Name: 'Grimsley',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_AL_14553',
    Name: 'Eclectic',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PA_14554',
    Name: 'Alleghenyville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TN_14555',
    Name: 'Alexandria',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_IL_14556',
    Name: 'Edinburg',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_ND_14557',
    Name: 'Belfield',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MT_14558',
    Name: 'Pinesdale',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_PR_14559',
    Name: 'Palmer',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_IL_14560', Name: 'Ramsey', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_AR_14561',
    Name: 'Coal Hill',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_14562',
    Name: 'Lemont Furnace',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OK_14563', Name: 'Cyril', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_CA_14564', Name: 'Cobb', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_TX_14565', Name: 'Red Lick', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IN_14566',
    Name: 'Seelyville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_KY_14567', Name: 'Goshen', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_WV_14568',
    Name: 'Enterprise',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_IL_14569', Name: 'Odin', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MD_14570',
    Name: 'Willards',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_AZ_14571', Name: 'Wilhoit', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_AL_14572',
    Name: 'Cherokee',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MS_14573',
    Name: 'Richton',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IL_14574',
    Name: 'Beckemeyer',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NM_14575',
    Name: 'Tesuque',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_OH_14576', Name: 'Thornville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_FL_14577', Name: 'Waldo', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_IA_14578', Name: 'Eddyville', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_14579',
    Name: 'McCullom Lake',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_14580',
    Name: 'North Bonneville',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_TX_14581',
    Name: 'Santa Anna',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IA_14582',
    Name: 'North English',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_MS_14583',
    Name: 'De Kalb',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_SD_14584',
    Name: 'Worthing',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_TX_14585', Name: 'Eustace', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MI_14586',
    Name: 'North Branch',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_TN_14587',
    Name: 'Winfield',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WV_14588',
    Name: 'New Cumberland',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_FL_14589',
    Name: 'Masaryktown',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_14590', Name: 'Winchester', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_14591',
    Name: 'Vander',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_AK_14592', Name: 'Salcha', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_CA_14593', Name: 'Pala', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_VA_14594',
    Name: 'Rushmere',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_AR_14595',
    Name: 'Mount Ida',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CA_14596',
    Name: 'Chalfant',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_14597',
    Name: 'Tilghman Island',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MS_14598',
    Name: 'Edwards',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OH_14599', Name: 'Yorkville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IN_14600', Name: 'Wanatah', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PA_14601',
    Name: 'South Waverly',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OH_14602',
    Name: 'Grand Rapids',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_TX_14603', Name: 'Tolar', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AZ_14604',
    Name: 'Casa Blanca',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_CA_14605',
    Name: 'Talmage',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_14606',
    Name: 'Tuttletown',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WV_14607',
    Name: 'Pennsboro',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_OH_14608',
    Name: 'Farmersville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_IA_14609', Name: 'Beaverdale', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MI_14610',
    Name: 'Lakeview',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NE_14611',
    Name: 'Cambridge',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_PA_14612',
    Name: 'McConnellsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_14613',
    Name: 'Sultana',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_14614',
    Name: 'Audubon Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OR_14615', Name: 'Scio', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_WA_14616',
    Name: 'Electric City',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_VA_14617',
    Name: 'Cedar Bluff',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CO_14618',
    Name: 'Manassa',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NJ_14619',
    Name: 'West Cape May',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OR_14620', Name: 'Halsey', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_MD_14621', Name: 'Trappe', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_MS_14622',
    Name: 'Bude',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_LA_14623',
    Name: 'Clarks',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_AK_14624',
    Name: 'King Cove',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_KS_14625', Name: 'Solomon', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_14626',
    Name: 'McAlisterville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_14627',
    Name: 'Cove Creek',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_14628', Name: 'Bronte', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OK_14629', Name: 'Erick', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_PA_14630',
    Name: 'Hulmeville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OK_14631',
    Name: 'Shady Point',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_PA_14632',
    Name: 'Falls Creek',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_14633',
    Name: 'Lucerne Mines',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_14634',
    Name: 'East Marion',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_KS_14635', Name: 'Oxford', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_14636',
    Name: 'Enlow',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_14637',
    Name: 'Washington Boro',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KS_14638',
    Name: 'Nickerson',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_KS_14639', Name: 'Sedan', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_KS_14640',
    Name: 'St. George',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_VA_14641',
    Name: 'Wattsville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WI_14642',
    Name: 'Greenwood',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NC_14643',
    Name: 'Columbus',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_14644',
    Name: 'Morrisonville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_14645',
    Name: 'Sterling City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_UT_14646', Name: 'Hideout', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MN_14647',
    Name: 'Parkers Prairie',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MI_14648',
    Name: 'Millington',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IN_14649', Name: 'Ladoga', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_FL_14650', Name: 'Waverly', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_OH_14651', Name: 'Thornport', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_NY_14652', Name: 'Dexter', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_MT_14653', Name: 'Scobey', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_TX_14654', Name: 'Wortham', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MI_14655', Name: 'Athens', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_VA_14656',
    Name: 'Pastoria',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_14657', Name: 'Lakeport', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_14658',
    Name: 'Pickens',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_KS_14659', Name: 'Highland', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_UT_14660',
    Name: 'Spring Glen',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_MN_14661',
    Name: 'Morristown',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_FL_14662',
    Name: 'Chumuckla',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_LA_14663',
    Name: 'Maringouin',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WV_14664',
    Name: 'Chelyan',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MO_14665',
    Name: 'Doe Run',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NM_14666',
    Name: 'La Union',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_CO_14667',
    Name: 'Aspen Park',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_MT_14668', Name: 'Ennis', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_WI_14669',
    Name: 'Albany',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MD_14670',
    Name: "Martin's Additions",
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OH_14671', Name: 'Urbancrest', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_HI_14672', Name: 'Poipu', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_NV_14673',
    Name: 'Round Hill Village',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_MD_14674',
    Name: 'Libertytown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OH_14675', Name: 'Kidron', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_14676',
    Name: 'Wayne City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_ME_14677',
    Name: 'West Kennebunk',
    stateId: 'ME',
    stateName: 'Maine',
  },
  {
    Id: 'CIT_US_NC_14678',
    Name: 'Rowland',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MO_14679',
    Name: 'King City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NM_14680',
    Name: 'Chama',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_KY_14681', Name: 'Burgin', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_AL_14682',
    Name: 'Littleville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PA_14683',
    Name: 'Almedia',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AK_14684',
    Name: 'Salamatof',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_PA_14685',
    Name: 'Penryn',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_14686',
    Name: 'Turtle Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WA_14687',
    Name: 'Ilwaco',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_AR_14688', Name: 'Parkin', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_IA_14689', Name: 'Rockwell', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_KY_14690',
    Name: 'Hawesville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IN_14691',
    Name: 'Goodland',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IL_14692', Name: 'Payson', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_KY_14693',
    Name: 'Rolling Hills',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_ND_14694',
    Name: 'New Salem',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_CA_14695',
    Name: 'March ARB',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_14696', Name: 'Tabor', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WI_14697',
    Name: 'Wittenberg',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_14698',
    Name: 'Saddle Rock',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OH_14699',
    Name: 'North Randall',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WV_14700',
    Name: 'Sutton',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_NE_14701', Name: 'Macy', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_IL_14702',
    Name: 'Greenfield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_DE_14703',
    Name: 'Frankford',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {Id: 'CIT_US_IA_14704', Name: 'Sloan', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_KS_14705', Name: 'Andale', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_KS_14706', Name: 'Caldwell', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MO_14707',
    Name: 'New London',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MN_14708',
    Name: 'Harmony',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_RI_14709',
    Name: 'Greene',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_NY_14710',
    Name: 'Sanborn',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MT_14711',
    Name: 'Harlowton',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_IN_14712',
    Name: 'Montezuma',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NM_14713',
    Name: 'Sedillo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_KY_14714',
    Name: 'Muldraugh',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_TX_14715',
    Name: 'Cross Plains',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WI_14716',
    Name: 'Blue Mounds',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_14717',
    Name: 'Goodfield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OR_14718', Name: 'Ruch', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_IA_14719', Name: 'Janesville', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_FL_14720',
    Name: 'Gulf Stream',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_OK_14721',
    Name: 'Quinton',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_TX_14722',
    Name: 'East Tawakoni',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NE_14723',
    Name: 'Henderson',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_PR_14724',
    Name: 'Quebrada',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_ME_14725',
    Name: 'Kittery Point',
    stateId: 'ME',
    stateName: 'Maine',
  },
  {
    Id: 'CIT_US_AR_14726',
    Name: 'Tumbling Shoals',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_OR_14727',
    Name: 'Lookingglass',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MI_14728',
    Name: 'Chums Corner',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OH_14729', Name: 'Etna', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AL_14730',
    Name: 'Hollywood',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PA_14731',
    Name: 'Seneca',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_14732',
    Name: 'Morgantown',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_KY_14733',
    Name: 'Ironville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_14734',
    Name: 'Lime Ridge',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_14735',
    Name: 'Cooleemee',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IN_14736',
    Name: 'Summitville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NY_14737',
    Name: 'Hillburn',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_14738',
    Name: 'Milford',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_14739', Name: 'Fruitland', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WI_14740',
    Name: 'Balsam Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TX_14741',
    Name: 'Taft Southwest',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_14742',
    Name: 'Coyne Center',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_14743',
    Name: 'Highlands',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OH_14744', Name: 'Beach City', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_14745',
    Name: 'Squirrel Mountain Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_14746',
    Name: 'Mustang Ridge',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_LA_14747',
    Name: 'Choudrant',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_IN_14748', Name: 'Ashley', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_WA_14749',
    Name: 'Machias',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_VA_14750',
    Name: 'Westlake Corner',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_14751', Name: 'Omaha', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_FL_14752', Name: 'Bristol', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MD_14753',
    Name: 'Chevy Chase View',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_PA_14754',
    Name: 'Stoneboro',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NE_14755',
    Name: 'Fort Calhoun',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NJ_14756',
    Name: 'Fairton',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_AL_14757',
    Name: 'Millport',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_AZ_14758', Name: 'Ganado', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_IA_14759', Name: 'Lawton', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_LA_14760', Name: 'Boyce', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_WV_14761',
    Name: 'Accoville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IN_14762',
    Name: 'Taylorsville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_SD_14763',
    Name: 'Whitewood',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_TN_14764',
    Name: 'Scotts Hill',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NM_14765',
    Name: 'Logan',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_UT_14766', Name: 'Henefer', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_CO_14767',
    Name: 'Lazy Acres',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PA_14768',
    Name: 'Lightstreet',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ND_14769',
    Name: 'Linton',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NM_14770',
    Name: 'San Miguel',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_TN_14771',
    Name: 'Bradford',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MS_14772',
    Name: 'Sunflower',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_14773',
    Name: 'Palo Alto',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_14774',
    Name: 'Tulelake',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_14775',
    Name: 'Auxvasse',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_ID_14776', Name: 'Kootenai', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_SC_14777',
    Name: 'Cane Savannah',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_MI_14778', Name: 'Elsie', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_NC_14779',
    Name: 'Ellerbe',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IN_14780', Name: 'Mexico', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NJ_14781',
    Name: 'Bay Head',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_CA_14782',
    Name: 'Monte Rio',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_14783', Name: 'Paullina', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AL_14784',
    Name: 'Sterrett',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NC_14785',
    Name: 'Hightsville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_14786', Name: 'Thrall', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_NE_14787', Name: 'Bennet', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_PA_14788',
    Name: 'Linesville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MT_14789',
    Name: 'Corvallis',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_NE_14790',
    Name: 'Arapahoe',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MI_14791',
    Name: 'Lawrence',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WI_14792',
    Name: 'Belmont',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IA_14793', Name: 'Clarence', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_NY_14794', Name: 'McGraw', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_MS_14795',
    Name: 'Stonewall',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_SD_14796',
    Name: 'Wanblee',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_AK_14797',
    Name: 'Buffalo Soapstone',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_HI_14798',
    Name: 'Kaumakani',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_MI_14799',
    Name: 'Napoleon',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MS_14800',
    Name: 'Derma',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_TX_14801', Name: 'Rice', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WV_14802',
    Name: 'Poca',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MS_14803',
    Name: 'Alcorn State University',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_AZ_14804',
    Name: 'Bluewater',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_CA_14805',
    Name: 'Tahoma',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_14806', Name: 'Dunlap', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_GA_14807', Name: 'Roberta', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_IA_14808', Name: 'Allison', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NE_14809',
    Name: 'Loup City',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_LA_14810',
    Name: 'St. Joseph',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_MN_14811', Name: 'Buhl', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_PA_14812',
    Name: 'Laurys Station',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_14813', Name: 'Shellsburg', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_14814',
    Name: 'Sunrise Lake',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MO_14815', Name: 'Jasper', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_IL_14816',
    Name: 'Meredosia',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MO_14817', Name: 'Gideon', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_TX_14818', Name: 'Earth', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_14819', Name: 'Keota', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_HI_14820', Name: 'Papaikou', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_IL_14821',
    Name: 'Chebanse',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_GA_14822',
    Name: 'Franklin',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NY_14823',
    Name: 'Jamesport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_LA_14824',
    Name: 'Oil City',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_NY_14825', Name: 'Laurel', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_OR_14826',
    Name: 'South Lebanon',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_PA_14827',
    Name: 'Scotland',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IN_14828', Name: 'Wolcott', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_MN_14829',
    Name: 'Biwabik',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_14830',
    Name: 'Collinsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IN_14831', Name: 'Mentone', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_CA_14832',
    Name: 'Cleone',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_14833',
    Name: 'Westley',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AK_14834', Name: 'Healy', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_GA_14835',
    Name: 'Sunnyside',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PA_14836',
    Name: 'Chase',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IL_14837', Name: 'Mazon', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_AL_14838', Name: 'Powell', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_KY_14839',
    Name: 'Woodlawn Park',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IL_14840',
    Name: 'Rapids City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MO_14841', Name: 'Laurie', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_SC_14842',
    Name: 'Aynor',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CA_14843',
    Name: 'Herald',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_14844',
    Name: 'Mill Neck',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_CO_14845', Name: 'Dillon', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_MD_14846',
    Name: 'Mount Vernon',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_14847', Name: 'Bremond', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MT_14848', Name: 'Marion', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_MN_14849',
    Name: 'Houston',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_GA_14850', Name: 'Hagan', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_ID_14851', Name: 'Genesee', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_WI_14852',
    Name: 'Green Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_14853',
    Name: 'Springville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_14854',
    Name: 'Vanceboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IA_14855', Name: 'Treynor', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_NE_14856', Name: 'Friend', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_PA_14857',
    Name: 'White Mills',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_14858',
    Name: 'Metcalfe',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_KS_14859',
    Name: 'Montezuma',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_MT_14860', Name: 'Troy', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_IL_14861', Name: 'Odell', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_AL_14862', Name: 'Leroy', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_CO_14863',
    Name: 'Kittredge',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_OH_14864', Name: 'Ottoville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NE_14865',
    Name: 'Harvard',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_AL_14866', Name: 'Coker', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_IL_14867',
    Name: 'Atkinson',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_14868',
    Name: 'Lakewood Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_14869',
    Name: 'Farmington',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_14870', Name: 'Waleska', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_PA_14871',
    Name: 'Beavertown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_14872',
    Name: 'Newellton',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_GA_14873',
    Name: 'Fort Gaines',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_UT_14874', Name: 'Mantua', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NC_14875',
    Name: 'Dortches',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_14876',
    Name: 'Five Points',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_14877',
    Name: 'Ellsworth',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_14878', Name: 'Louise', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MD_14879',
    Name: 'Union Bridge',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_14880', Name: 'Hargill', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_14881',
    Name: 'Twisp',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_14882',
    Name: 'Mertztown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_14883', Name: 'Coahoma', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_14884', Name: 'Magnolia', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_14885',
    Name: 'Sugar Notch',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_HI_14886', Name: 'Naalehu', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_GA_14887',
    Name: 'Norman Park',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_TN_14888',
    Name: 'Rossville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NE_14889',
    Name: 'Kenesaw',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IN_14890',
    Name: 'Waynetown',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AZ_14891',
    Name: 'Mesa del Caballo',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_SC_14892',
    Name: 'Swansea',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_IL_14893', Name: 'Mendon', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_KS_14894',
    Name: 'Blue Rapids',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_WV_14895',
    Name: 'Marlinton',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PR_14896',
    Name: 'Playa Fortuna',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_CA_14897',
    Name: 'Fruitdale',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PR_14898',
    Name: 'Cerrillos Hoyos',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MD_14899',
    Name: 'Calvert Beach',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MI_14900',
    Name: 'Saugatuck',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_GA_14901',
    Name: 'Reynolds',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_KS_14902', Name: 'Troy', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_GA_14903', Name: 'Sardis', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_VA_14904',
    Name: 'Fort Chiswell',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IN_14905',
    Name: 'Lynnville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_OH_14906',
    Name: 'Highland Hills',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CA_14907',
    Name: 'La Honda',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_14908', Name: 'Benjamin', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_AR_14909',
    Name: 'Mammoth Spring',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MN_14910',
    Name: 'Scanlon',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_KY_14911',
    Name: 'La Center',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_WY_14912', Name: 'Dubois', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_OH_14913',
    Name: 'Pleasantville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_TN_14914',
    Name: 'Gainesboro',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_14915',
    Name: 'Arendtsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_14916', Name: 'Preston', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CO_14917',
    Name: 'Oak Creek',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MN_14918',
    Name: 'Bird Island',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_14919',
    Name: 'West Brownsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_14920', Name: 'Aurelia', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_ME_14921',
    Name: 'Little Falls',
    stateId: 'ME',
    stateName: 'Maine',
  },
  {
    Id: 'CIT_US_WI_14922',
    Name: 'Benton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WA_14923',
    Name: 'Roslyn',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PR_14924',
    Name: 'Parcelas Peñuelas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_CA_14925',
    Name: 'Butte Creek Canyon',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_14926',
    Name: 'Minkler',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_14927',
    Name: 'Lakeside City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_14928',
    Name: 'Glasford',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AL_14929',
    Name: 'Dunnavant',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_OK_14930',
    Name: 'Sequoyah',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MS_14931',
    Name: 'Vaiden',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MD_14932',
    Name: 'Aquasco',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_MT_14933', Name: 'Ashland', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_PA_14934',
    Name: 'Chicora',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_14935', Name: 'Underwood', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_14936',
    Name: 'Southwest City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TX_14937',
    Name: 'Westminster',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CO_14938',
    Name: 'Poncha Springs',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_IN_14939', Name: 'Palmyra', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_TN_14940',
    Name: 'Linden',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NC_14941',
    Name: 'Faison',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CT_14942',
    Name: 'Terramuggus',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_NC_14943',
    Name: 'Germanton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TX_14944',
    Name: 'White Deer',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CO_14945',
    Name: 'Dolores',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_VA_14946',
    Name: 'Maurertown',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_IN_14947', Name: 'Brook', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_AL_14948', Name: 'Killen', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_CO_14949',
    Name: 'Ellicott',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MO_14950',
    Name: 'Norwood Court',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OK_14951',
    Name: 'Ringling',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_FL_14952',
    Name: 'Lake Kathryn',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_UT_14953', Name: 'Levan', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_SC_14954',
    Name: 'Startex',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_OK_14955',
    Name: 'Weleetka',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NC_14956',
    Name: 'Belwood',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WI_14957',
    Name: 'Buffalo City',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PR_14958',
    Name: 'Rafael Hernández',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_OR_14959', Name: 'Oakland', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_IA_14960', Name: 'Gowrie', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_VA_14961', Name: 'Basye', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_SC_14962',
    Name: 'Branchville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MN_14963',
    Name: 'Watkins',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_14964', Name: 'Coolidge', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_14965', Name: 'Swisher', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_14966',
    Name: 'Hiller',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_14967',
    Name: 'Magnolia',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_KS_14968', Name: 'Riley', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_14969',
    Name: 'Avonmore',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_14970',
    Name: 'Nashwauk',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_14971',
    Name: 'Milledgeville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_14972',
    Name: 'Mountain Gate',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NM_14973',
    Name: 'La Puebla',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_14974',
    Name: 'Tuscarora',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_14975',
    Name: 'Millersburg',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OK_14976', Name: 'Gore', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_IN_14977',
    Name: 'Hoagland',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PA_14978',
    Name: 'New Tripoli',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_14979',
    Name: 'Ramblewood',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_14980',
    Name: 'Sam Rayburn',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AZ_14981',
    Name: 'Seven Mile',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NV_14982',
    Name: 'Stateline',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {Id: 'CIT_US_AR_14983', Name: 'Cotter', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_MI_14984', Name: 'Onsted', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_AL_14985',
    Name: 'Camp Hill',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PA_14986',
    Name: 'Bechtelsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_14987',
    Name: 'Sheldon',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_KS_14988', Name: 'Stafford', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_AL_14989',
    Name: 'Vandiver',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_AL_14990', Name: 'Triana', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NC_14991',
    Name: 'Silver City',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NE_14992',
    Name: 'Elm Creek',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_OH_14993', Name: 'Newport', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_14994',
    Name: 'Mansfield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_14995', Name: 'Hinton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MS_14996',
    Name: 'Blue Mountain',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_14997',
    Name: 'Rose Valley',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_NE_14998', Name: 'Genoa', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_NJ_14999',
    Name: 'Silver Ridge',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_KY_15000',
    Name: 'Ferguson',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CA_15001',
    Name: 'Boonville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_15002',
    Name: 'Sturgeon',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_VA_15003',
    Name: 'Shenandoah Shores',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_AZ_15004',
    Name: 'Strawberry',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NY_15005',
    Name: 'Russell Gardens',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_SC_15006',
    Name: 'West Pelzer',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_15007',
    Name: 'Saegertown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_15008', Name: 'Princeton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MI_15009',
    Name: 'Lake Linden',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_15010',
    Name: 'Timberlane',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_15011',
    Name: 'Bernville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_15012',
    Name: 'Buchanan Lake Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AZ_15013',
    Name: 'Golden Shores',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_TN_15014',
    Name: 'Collinwood',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_LA_15015',
    Name: 'Plain Dealing',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_MO_15016', Name: 'Drexel', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_IA_15017', Name: 'Griswold', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_15018',
    Name: 'Wallace',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AL_15019',
    Name: 'Carrollton',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_IA_15020', Name: 'Odebolt', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_15021', Name: 'Wayland', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WI_15022',
    Name: 'Crivitz',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_15023',
    Name: 'Raymond',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CO_15024',
    Name: 'Bow Mar',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_NY_15025', Name: 'Zena', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_PA_15026',
    Name: 'Goldsboro',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_15027',
    Name: 'Ivyland',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_15028',
    Name: 'Pringle',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_GA_15029',
    Name: 'Homeland',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_CO_15030', Name: 'Loma', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_TN_15031',
    Name: 'Orlinda',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NE_15032',
    Name: 'Bloomfield',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_ID_15033',
    Name: 'Cottonwood',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_PR_15034',
    Name: 'Parcelas Nuevas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_IN_15035',
    Name: 'Spiceland',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_OH_15036',
    Name: 'South Canal',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_OK_15037',
    Name: 'Medford',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_GA_15038',
    Name: 'Jeffersonville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CT_15039',
    Name: 'Mansfield Center',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_NY_15040',
    Name: 'Napanoch',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NM_15041',
    Name: 'McIntosh',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MI_15042',
    Name: 'Central Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_KY_15043',
    Name: 'New Castle',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NM_15044',
    Name: 'Zia Pueblo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IL_15045',
    Name: 'Franklin Grove',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IL_15046', Name: 'Wenona', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_PR_15047',
    Name: 'Magas Arriba',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_MI_15048', Name: 'Dryden', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_VA_15049',
    Name: 'Camptown',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_ND_15050',
    Name: 'Gwinner',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_AL_15051', Name: 'Parrish', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_OK_15052', Name: 'Hydro', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_LA_15053',
    Name: 'Wallace Ridge',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_IN_15054', Name: 'Borden', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_WV_15055',
    Name: 'Mason',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_OH_15056', Name: 'Addyston', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_LA_15057',
    Name: 'Cotton Valley',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_UT_15058',
    Name: 'Green River',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_NM_15059',
    Name: 'Carrizozo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_15060',
    Name: 'Trumbauersville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SC_15061',
    Name: 'Lamar',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_TX_15062', Name: 'Hardin', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_15063',
    Name: 'Farmersville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_15064',
    Name: 'Maysville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_ID_15065', Name: 'Grace', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_PA_15066',
    Name: 'Paint',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_15067',
    Name: 'Sister Bay',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_ND_15068',
    Name: 'Cannon Ball',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_CA_15069',
    Name: 'Santa Susana',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_15070', Name: 'Lansing', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WA_15071',
    Name: 'Lake Bosworth',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_MO_15072', Name: 'Miner', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_IN_15073', Name: 'Utica', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_MN_15074',
    Name: 'Walker',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_15075',
    Name: 'Clarksville City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MS_15076',
    Name: 'Elliott',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MS_15077',
    Name: 'Burnsville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NM_15078',
    Name: 'Talpa',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_AZ_15079', Name: 'Hondah', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_KY_15080',
    Name: 'Lynnview',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NC_15081',
    Name: 'Brunswick',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MS_15082',
    Name: 'Prentiss',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_GA_15083',
    Name: 'Shellman',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_FL_15084',
    Name: 'Golden Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MI_15085',
    Name: 'Westphalia',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CT_15086',
    Name: 'Saybrook Manor',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_KS_15087',
    Name: 'Lake Quivira',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_OK_15088',
    Name: 'Grandfield',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_TN_15089',
    Name: 'Clarkrange',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MN_15090',
    Name: 'Le Roy',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_15091',
    Name: 'Coldspring',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_WI_15092', Name: 'Eden', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_MT_15093',
    Name: 'White Sulphur Springs',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_CA_15094',
    Name: 'Lake of the Woods',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VA_15095',
    Name: 'Craigsville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_AZ_15096', Name: 'Naco', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_KS_15097', Name: 'Kiowa', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_TN_15098',
    Name: 'Henning',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MN_15099',
    Name: 'Henderson',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PR_15100',
    Name: 'Palmas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MN_15101',
    Name: 'Deer River',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NE_15102',
    Name: 'Crawford',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_MT_15103', Name: 'Hays', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_KY_15104',
    Name: 'Uniontown',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_NE_15105', Name: 'Tilden', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_HI_15106',
    Name: 'Kaanapali',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {Id: 'CIT_US_IA_15107', Name: 'West Point', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_UT_15108', Name: 'Liberty', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_AZ_15109', Name: 'Cameron', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_PA_15110',
    Name: 'Greens Landing',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_15111',
    Name: 'Hesperia',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_FL_15112',
    Name: 'Jupiter Island',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MN_15113',
    Name: 'Battle Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_FL_15114',
    Name: 'Black Hammock',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_15115',
    Name: 'Linnell Camp',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_WI_15116', Name: 'Owen', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_VA_15117',
    Name: 'Jonesville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MO_15118',
    Name: 'Ellington',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NY_15119',
    Name: 'Poquott',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MS_15120',
    Name: 'Toomsuba',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_AK_15121',
    Name: 'Delta Junction',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_VA_15122', Name: 'Boston', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_CO_15123',
    Name: 'Hotchkiss',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MN_15124',
    Name: 'Pine River',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_DE_15125',
    Name: 'Dagsboro',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_SC_15126',
    Name: 'Summerton',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_FL_15127',
    Name: 'Gun Club Estates',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OK_15128', Name: 'Pettit', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_AZ_15129',
    Name: 'Chilchinbito',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_PA_15130',
    Name: 'Stockertown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_15131',
    Name: 'Jolivue',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_15132', Name: 'Point', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AR_15133',
    Name: 'Foreman',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_OK_15134', Name: 'Allen', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_TX_15135',
    Name: 'Blue Ridge',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MS_15136',
    Name: 'Big Point',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_TN_15137',
    Name: 'Walnut Grove',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MI_15138',
    Name: 'Hubbell',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_15139',
    Name: 'Pleasant Hill',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_15140',
    Name: 'Millerton',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CO_15141',
    Name: 'Upper Bear Creek',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CO_15142',
    Name: 'Pine Brook Hill',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_TX_15143', Name: 'Vega', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TN_15144',
    Name: 'Wartburg',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NC_15145',
    Name: 'Old Fort',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_WY_15146', Name: 'Alpine', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_MA_15147',
    Name: 'South Ashburnham',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_IN_15148',
    Name: 'Jamestown',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OH_15149', Name: 'Beloit', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MT_15150',
    Name: 'Philipsburg',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_IA_15151',
    Name: 'Fredericksburg',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_IL_15152', Name: 'Wyanet', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_IN_15153',
    Name: 'Silver Lake',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MT_15154',
    Name: 'Black Eagle',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_FL_15155',
    Name: 'DeLand Southwest',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MO_15156',
    Name: 'Fremont Hills',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IL_15157', Name: 'Marine', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_PA_15158',
    Name: 'Millville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SC_15159',
    Name: 'Quinby',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MI_15160',
    Name: 'Village of Clarkston',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_15161',
    Name: 'McClure',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_15162', Name: 'Wayne', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CO_15163',
    Name: 'Blue River',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_GA_15164', Name: 'Appling', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_OK_15165',
    Name: 'North Enid',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MN_15166',
    Name: 'Hallock',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_UT_15167',
    Name: 'Minersville',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_KS_15168',
    Name: 'Wakefield',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_CA_15169',
    Name: 'Hopland',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_15170',
    Name: 'Shabbona',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PR_15171',
    Name: 'Las Croabas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_WI_15172',
    Name: 'Valders',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_FL_15173',
    Name: 'Pomona Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_OH_15174',
    Name: 'New Washington',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OH_15175', Name: 'Glenwillow', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TN_15176',
    Name: 'Atwood',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_IL_15177', Name: 'Percy', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NM_15178',
    Name: 'Adelino',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MS_15179',
    Name: 'Woodville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OH_15180', Name: 'Killbuck', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_15181',
    Name: 'Necedah',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NC_15182',
    Name: 'Garysburg',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TN_15183',
    Name: 'Helenwood',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_VA_15184', Name: 'Pound', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_IA_15185', Name: 'Elkhart', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WI_15186',
    Name: 'Greenleaf',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_15187',
    Name: 'New Bethlehem',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_15188', Name: 'Calmar', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_TN_15189',
    Name: 'Sharon',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_15190',
    Name: 'Spring Ridge',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_15191',
    Name: 'Prices Fork',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_15192',
    Name: 'Yeagertown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_HI_15193',
    Name: 'Mahinahina',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {Id: 'CIT_US_NE_15194', Name: 'Laurel', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_AL_15195', Name: 'Grant', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_AR_15196',
    Name: 'Marvell',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NE_15197',
    Name: 'Red Cloud',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NE_15198',
    Name: 'Waterloo',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_IA_15199', Name: 'Eldon', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SC_15200',
    Name: 'Warrenville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PR_15201',
    Name: 'Marueño',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_KY_15202',
    Name: 'Langdon Place',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_UT_15203', Name: 'Goshen', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_AZ_15204', Name: 'Komatke', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_OH_15205',
    Name: 'Bloomingburg',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_OK_15206',
    Name: 'Waynoka',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WA_15207',
    Name: 'Port Gamble Tribal Community',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_LA_15208',
    Name: 'Washington',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_GA_15209', Name: 'Ivey', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_IL_15210', Name: 'Leland', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MS_15211',
    Name: 'Beaumont',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IA_15212', Name: 'Le Grand', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_LA_15213',
    Name: 'Fordoche',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_15214',
    Name: 'Garberville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_15215',
    Name: 'Shiocton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_AL_15216', Name: 'Altoona', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_PA_15217',
    Name: 'DeSales University',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_15218',
    Name: 'Mesa Verde',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_15219', Name: 'Hilltop', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_NE_15220', Name: 'Ponca', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_MS_15221',
    Name: 'Mount Olive',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_MI_15222', Name: 'Grawn', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_OK_15223',
    Name: 'Lost City',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_FL_15224', Name: 'Coleman', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_GA_15225',
    Name: 'Hiawassee',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_IN_15226', Name: 'Swayzee', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_IL_15227',
    Name: 'Clay City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SD_15228',
    Name: 'Wessington Springs',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_TX_15229',
    Name: 'Sherwood Shores',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_FL_15230',
    Name: 'Garden Grove',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MS_15231',
    Name: 'Pearlington',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OH_15232', Name: 'Bloomville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_KS_15233', Name: 'Perry', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WI_15234',
    Name: 'Centuria',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_FL_15235', Name: 'Panacea', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MO_15236',
    Name: 'Diamond',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_GA_15237',
    Name: 'Tunnel Hill',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MI_15238',
    Name: 'Morrice',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NE_15239',
    Name: 'Franklin',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NM_15240',
    Name: 'High Rolls',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NC_15241',
    Name: 'Barker Ten Mile',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_KS_15242',
    Name: 'Garden Plain',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_NY_15243',
    Name: 'Old Field',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NC_15244',
    Name: 'Pinnacle',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TN_15245',
    Name: 'Tellico Plains',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NC_15246',
    Name: 'Marshall',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CO_15247',
    Name: 'Keystone',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PA_15248',
    Name: 'Port Royal',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_HI_15249', Name: 'Puako', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_CO_15250',
    Name: 'Ignacio',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_IA_15251', Name: 'Keosauqua', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_15252',
    Name: 'Osceola',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_15253', Name: 'Waynesburg', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OK_15254', Name: 'Temple', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_ND_15255',
    Name: 'Walhalla',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_OR_15256',
    Name: 'Bayside Gardens',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_AZ_15257',
    Name: 'Pimaco Two',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_VA_15258',
    Name: 'Fieldale',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_TX_15259',
    Name: 'Centerville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_15260', Name: 'Celeste', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_LA_15261',
    Name: 'Dubach',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WV_15262',
    Name: 'Cedar Grove',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NM_15263',
    Name: 'Springer',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_15264',
    Name: 'East Brady',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_CO_15265', Name: 'Howard', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_KY_15266',
    Name: 'East Bernstadt',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_LA_15267',
    Name: 'Slaughter',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_LA_15268',
    Name: 'Grand Coteau',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WV_15269',
    Name: 'Carpendale',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_VA_15270',
    Name: 'Arrington',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_15271',
    Name: 'Schoeneck',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NH_15272',
    Name: 'North Haverhill',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {Id: 'CIT_US_NV_15273', Name: 'McGill', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_WI_15274',
    Name: 'Butte des Morts',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_15275',
    Name: 'Cattaraugus',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_CO_15276', Name: 'Haxtun', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_IA_15277', Name: 'Maxwell', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OH_15278', Name: 'Hopedale', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_SD_15279',
    Name: 'Piedmont',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MD_15280',
    Name: 'Monrovia',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_ND_15281',
    Name: 'Cooperstown',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_CA_15282',
    Name: 'Camanche Village',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NM_15283',
    Name: 'Paraje',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_TN_15284',
    Name: 'Friendsville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WV_15285',
    Name: 'Rivesville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NJ_15286',
    Name: 'Far Hills',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_ID_15287', Name: 'Inkom', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_OH_15288',
    Name: 'Milford Center',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NY_15289',
    Name: 'Plandome Manor',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_GA_15290',
    Name: 'Woodbury',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_AK_15291',
    Name: 'Womens Bay',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_AR_15292', Name: 'Dyer', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_NY_15293',
    Name: 'Belmont',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_KS_15294', Name: 'Dighton', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_GA_15295', Name: 'Eton', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WA_15296',
    Name: 'Brady',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_ND_15297',
    Name: 'Ray',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_KS_15298',
    Name: 'Burlingame',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_CA_15299',
    Name: 'Lake Don Pedro',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OK_15300',
    Name: 'Texhoma',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WV_15301',
    Name: 'Stanaford',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_CA_15302',
    Name: 'Stratford',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_15303', Name: 'Bandera', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SD_15304',
    Name: 'Antelope',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IA_15305', Name: 'Anita', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IL_15306', Name: 'Hardin', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_TX_15307', Name: 'Glidden', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_LA_15308',
    Name: 'Wisner',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_OH_15309',
    Name: 'Hide-A-Way Hills',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_SC_15310',
    Name: 'Santee',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_TX_15311', Name: 'Sandia', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AZ_15312',
    Name: 'Dennehotso',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_FL_15313',
    Name: 'Lake Mack-Forest Hills',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_15314', Name: 'Moulton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MI_15315',
    Name: 'Augusta',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MO_15316',
    Name: 'Lockwood',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_VA_15317', Name: 'Grundy', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_CT_15318',
    Name: 'Gales Ferry',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_IA_15319', Name: 'Danville', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NE_15320',
    Name: 'Ceresco',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NM_15321',
    Name: 'Chamita',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_OH_15322',
    Name: 'New Baltimore',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_VA_15323',
    Name: 'Sugar Grove',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CO_15324',
    Name: 'Dotsero',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MI_15325',
    Name: 'Ossineke',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_UT_15326',
    Name: 'Bear River City',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_IL_15327',
    Name: 'Roseville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AL_15328',
    Name: 'Geraldine',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_KY_15329',
    Name: 'McRoberts',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_VA_15330',
    Name: 'Goochland',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_FL_15331', Name: 'Seville', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NM_15332',
    Name: 'Fort Sumner',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_WI_15333',
    Name: 'Kekoskee',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_15334',
    Name: 'Norris Canyon',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OR_15335', Name: 'Glendale', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_CA_15336',
    Name: 'Dorris',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SD_15337',
    Name: 'Lower Brule',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_TX_15338', Name: 'Mikes', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KY_15339',
    Name: 'White Plains',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WV_15340',
    Name: 'Athens',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_OK_15341', Name: 'Brent', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NH_15342',
    Name: 'West Swanzey',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_CA_15343',
    Name: 'Upper Lake',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SD_15344',
    Name: 'Lake Madison',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_ND_15345',
    Name: 'Northwood',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_OH_15346', Name: 'Seaman', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_LA_15347',
    Name: 'Reddell',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_15348', Name: 'Sudan', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_15349',
    Name: 'Cottondale',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_ID_15350', Name: 'Troy', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_MT_15351',
    Name: 'Shepherd',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_IL_15352',
    Name: 'Coulterville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_ID_15353', Name: 'Council', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_MI_15354',
    Name: 'Hubbard Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_FL_15355', Name: 'Solana', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MS_15356',
    Name: 'Leakesville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WA_15357',
    Name: 'Odessa',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KY_15358',
    Name: 'New Haven',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OH_15359', Name: 'Butler', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WV_15360',
    Name: 'Rupert',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_ID_15361', Name: 'Hagerman', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_OK_15362',
    Name: 'Empire City',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_KY_15363',
    Name: 'Annville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_OH_15364',
    Name: 'Williamsdale',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_VA_15365',
    Name: 'Independence',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NY_15366',
    Name: 'North Haven',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_15367',
    Name: 'La Grange',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_15368',
    Name: 'Bodega',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_15369', Name: 'Garrett', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_15370',
    Name: 'McKenna',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OH_15371', Name: 'Albany', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_15372',
    Name: 'Iron Ridge',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MO_15373',
    Name: 'Pasadena Hills',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IA_15374', Name: 'Larchwood', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_15375', Name: 'Neola', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SD_15376',
    Name: 'Ipswich',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IL_15377', Name: 'Ashton', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_ND_15378',
    Name: 'Wishek',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_WA_15379',
    Name: 'Clinton',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IN_15380', Name: 'Memphis', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NE_15381',
    Name: 'Randolph',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_CA_15382',
    Name: 'Whitewater',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_15383',
    Name: 'Whitney Point',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_KY_15384',
    Name: 'Elkhorn City',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_AZ_15385', Name: 'Pinon', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_PA_15386',
    Name: 'Rouzerville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_15387',
    Name: 'Carthage',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NE_15388',
    Name: 'Morrill',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_CA_15389',
    Name: 'Diablo Grande',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_15390',
    Name: 'Pembroke',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_NE_15391', Name: 'Curtis', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_WA_15392',
    Name: 'Lake Ketchum',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_15393',
    Name: 'Allenton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_15394',
    Name: 'Montandon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IL_15395', Name: 'Viola', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_AZ_15396', Name: 'Aguila', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_TN_15397',
    Name: 'Lobelville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_SC_15398',
    Name: 'Bowman',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_IN_15399', Name: 'Shirley', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PA_15400',
    Name: 'Avella',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_15401',
    Name: 'Church Rock',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_CO_15402',
    Name: 'Sanford',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CA_15403',
    Name: 'Plainview',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_KS_15404', Name: 'Lebo', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_AL_15405', Name: 'Ivalee', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_KY_15406',
    Name: 'Clarkson',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_TX_15407',
    Name: 'Lake Tanglewood',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MS_15408',
    Name: 'North Tunica',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_KY_15409',
    Name: 'Bellemeade',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_AZ_15410',
    Name: 'Parker Strip',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_MO_15411',
    Name: 'Truesdale',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_ID_15412', Name: 'Greenleaf', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_SC_15413',
    Name: 'Fort Lawn',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_FL_15414',
    Name: 'North Key Largo',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_KY_15415',
    Name: 'Adairville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_ND_15416',
    Name: 'Hankinson',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_AL_15417', Name: 'Shelby', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MI_15418',
    Name: 'Mayville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WA_15419',
    Name: 'Seabeck',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_15420',
    Name: 'Fuller Acres',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MI_15421', Name: 'Grant', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_OH_15422',
    Name: 'North Perry',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MT_15423',
    Name: 'Gallatin Gateway',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_TX_15424', Name: 'Simonton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_15425',
    Name: 'Bowmanstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_15426',
    Name: "Port O'Connor",
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_LA_15427',
    Name: 'Welcome',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_ND_15428',
    Name: 'LaMoure',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_FL_15429', Name: 'Medley', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_UT_15430', Name: 'Central', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MS_15431',
    Name: 'Utica',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IN_15432', Name: 'Monroe', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NJ_15433',
    Name: 'Allenwood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_KY_15434',
    Name: 'Pleasureville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MN_15435',
    Name: 'Birchwood Village',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WI_15436',
    Name: 'Elk Mound',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MI_15437', Name: 'Baroda', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_UT_15438', Name: 'Elsinore', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NC_15439',
    Name: 'Fair Bluff',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_AZ_15440',
    Name: 'Kykotsmovi Village',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_IL_15441', Name: 'Tilden', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_KY_15442', Name: 'Dixon', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_PA_15443',
    Name: 'Rimersburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_15444',
    Name: 'Cimarron',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_CA_15445',
    Name: 'Teviston',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_15446',
    Name: 'Cassville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NV_15447',
    Name: 'Indian Springs',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_CA_15448',
    Name: 'Fieldbrook',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_15449',
    Name: 'Lilly',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AZ_15450',
    Name: 'Munds Park',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_ID_15451', Name: 'Arco', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_IL_15452',
    Name: 'Galatia',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_15453',
    Name: 'Millheim',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_15454',
    Name: 'North Edwards',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_15455',
    Name: 'Mi-Wuk Village',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PR_15456',
    Name: 'Las Ochenta',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_NE_15457',
    Name: 'Rushville',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_KS_15458', Name: 'Americus', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_CO_15459', Name: 'Blende', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_MT_15460',
    Name: 'Gardiner',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_MI_15461', Name: 'Delton', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_AR_15462', Name: 'Fouke', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_NM_15463',
    Name: 'Magdalena',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_LA_15464',
    Name: 'Crescent',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_15465',
    Name: 'Soda Bay',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_15466',
    Name: 'Oklahoma',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_15467',
    Name: 'Kapp Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_15468',
    Name: 'Gibsland',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_AR_15469', Name: 'Kirby', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_WI_15470',
    Name: 'Milltown',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IN_15471',
    Name: 'Roachdale',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AK_15472',
    Name: 'Mountain Village',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_IL_15473',
    Name: 'Argenta',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OR_15474',
    Name: 'Prairie City',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_KY_15475',
    Name: 'West Point',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OK_15476', Name: 'Quapaw', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CO_15477',
    Name: 'Mount Crested Butte',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NY_15478',
    Name: 'Durhamville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_KS_15479', Name: 'Galva', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_AZ_15480',
    Name: 'Cactus Forest',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_SD_15481',
    Name: 'Wall',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_OR_15482',
    Name: 'Adair Village',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_AZ_15483',
    Name: 'Patagonia',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_AL_15484', Name: 'Mosses', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_ID_15485', Name: 'Dover', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_TX_15486',
    Name: 'Reid Hope King',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_SC_15487',
    Name: 'Arcadia Lakes',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_AL_15488',
    Name: 'Brilliant',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_TX_15489', Name: 'Garrison', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MO_15490', Name: 'Benton', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MO_15491',
    Name: 'Lake Waukomis',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NM_15492',
    Name: 'Mountainair',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IN_15493',
    Name: 'Wheatfield',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IN_15494', Name: 'Gaston', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_UT_15495', Name: 'Leeds', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_OH_15496', Name: 'North Bend', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_15497', Name: 'Miles', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AK_15498',
    Name: 'Denali Park',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_NC_15499',
    Name: 'Cleveland',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IN_15500', Name: 'Dugger', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_SD_15501',
    Name: 'Centerville',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IA_15502', Name: 'Long Grove', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_TX_15503', Name: 'Savoy', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_15504',
    Name: 'Dallas City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_15505',
    Name: 'Grand Ridge',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_KS_15506', Name: 'Benton', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_AL_15507',
    Name: 'Autaugaville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_AZ_15508', Name: 'Houck', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_AZ_15509',
    Name: 'Whitecone',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_MI_15510',
    Name: 'Farwell',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MD_15511',
    Name: 'Funkstown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_OK_15512',
    Name: 'Cashion',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_GA_15513', Name: 'Poulan', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NC_15514',
    Name: 'Montreat',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_15515', Name: 'Trinidad', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_15516',
    Name: 'Rockaway Beach',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_15517',
    Name: 'Deer Park',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MS_15518',
    Name: 'Gloster',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WV_15519',
    Name: 'East Bank',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IN_15520',
    Name: 'St. Mary of the Woods',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PR_15521',
    Name: 'Orocovis',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_KS_15522',
    Name: 'Linn Valley',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_TX_15523',
    Name: 'Walnut Springs',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MO_15524',
    Name: 'Airport Drive',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IA_15525', Name: 'Victor', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MD_15526',
    Name: 'Tilghmanton',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MO_15527',
    Name: 'Reeds Spring',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MT_15528',
    Name: 'Superior',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_FL_15529',
    Name: 'Black Diamond',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WI_15530',
    Name: 'Dresser',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NE_15531',
    Name: 'Blue Hill',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_WI_15532',
    Name: 'Hingham',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_OH_15533',
    Name: 'New Knoxville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PR_15534',
    Name: 'Bajandas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MS_15535',
    Name: 'Inverness',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_AK_15536',
    Name: 'Knik River',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_PA_15537',
    Name: 'Silkworth',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_15538', Name: 'Matamoras', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MD_15539',
    Name: 'Grantsville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_PA_15540',
    Name: 'Port Trevorton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_15541',
    Name: 'Morehouse',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MN_15542',
    Name: 'St. Stephen',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WI_15543',
    Name: 'Dorchester',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_VT_15544',
    Name: 'Graniteville',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_MS_15545',
    Name: 'Tunica',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MN_15546',
    Name: 'Onamia',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MT_15547',
    Name: 'Fairview',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_OH_15548', Name: 'Marblehead', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_15549', Name: 'Hamden', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_15550',
    Name: 'Harwick',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_15551',
    Name: 'Ventress',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_FL_15552',
    Name: 'St. George Island',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_15553',
    Name: 'Sheffield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WI_15554',
    Name: 'Rib Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IL_15555', Name: 'Albany', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_PA_15556',
    Name: 'Stony Creek Mills',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_15557',
    Name: 'Elkville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_KS_15558',
    Name: 'Cottonwood Falls',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_WV_15559',
    Name: 'Belmont',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_FL_15560',
    Name: 'Jennings',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_TN_15561',
    Name: 'Decaturville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_LA_15562',
    Name: 'Folsom',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OH_15563', Name: 'Darbydale', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IN_15564',
    Name: 'Darlington',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_SC_15565',
    Name: 'Scranton',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_OK_15566',
    Name: 'Mannsville',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WI_15567',
    Name: 'Fountain City',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MN_15568',
    Name: 'Vineland',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OH_15569', Name: 'Attica', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_15570', Name: 'Lometa', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OK_15571', Name: 'Dibble', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_TX_15572',
    Name: 'East Mountain',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NC_15573',
    Name: 'Walnut Creek',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NM_15574',
    Name: 'Midway',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_IA_15575', Name: 'Edgewood', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OH_15576', Name: 'Sciotodale', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_15577',
    Name: 'Oriental',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WI_15578',
    Name: "Chain O' Lakes",
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WA_15579',
    Name: 'Wilbur',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_IA_15580',
    Name: 'Lake Panorama',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_KS_15581', Name: 'Burrton', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_AL_15582',
    Name: 'Brantleyville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_IN_15583',
    Name: 'Lake Santee',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_OK_15584',
    Name: 'West Siloam Springs',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_SD_15585',
    Name: 'Arlington',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NJ_15586',
    Name: 'Hi-Nella',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NC_15587',
    Name: 'Taylortown',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IA_15588', Name: 'Newhall', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NY_15589',
    Name: 'Sodus Point',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_15590',
    Name: 'Harrisville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_15591', Name: 'Felicity', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IA_15592', Name: 'Gladbrook', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AR_15593',
    Name: 'Bearden',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_UT_15594',
    Name: 'Rocky Ridge',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_CA_15595',
    Name: 'Walker',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_15596', Name: 'Schleswig', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_15597',
    Name: 'Hyndman',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_15598',
    Name: 'Moreauville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_MT_15599', Name: 'Lincoln', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_MI_15600',
    Name: 'Deerfield',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MO_15601',
    Name: 'Grantwood Village',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CT_15602',
    Name: 'New Preston',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_WY_15603',
    Name: 'Vista West',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_AR_15604',
    Name: 'Greers Ferry',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NY_15605',
    Name: 'Hillside',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_FL_15606', Name: 'Paxton', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_MI_15607', Name: 'Marion', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_PA_15608',
    Name: 'Export',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_15609', Name: 'Primghar', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_15610',
    Name: 'Mendocino',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IA_15611',
    Name: 'Buffalo Center',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_NY_15612',
    Name: 'West Winfield',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OR_15613', Name: 'Merrill', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_OK_15614',
    Name: 'Seiling',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_TX_15615',
    Name: 'Indian Lake',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_15616',
    Name: 'Oakland',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_15617',
    Name: 'La Fargeville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_15618',
    Name: 'Silverdale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_15619',
    Name: 'Plantation',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IA_15620', Name: 'Donnellson', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SC_15621',
    Name: 'McBee',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_UT_15622',
    Name: 'Oljato-Monument Valley',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {Id: 'CIT_US_OH_15623', Name: 'Woodmere', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_LA_15624',
    Name: 'Tangipahoa',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_15625',
    Name: 'East Tulare Villa',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MT_15626', Name: 'Chester', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_MI_15627',
    Name: 'Dollar Bay',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_FL_15628', Name: 'Rio', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_OK_15629', Name: 'Dewar', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_WI_15630',
    Name: 'Lake Arrowhead',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_15631', Name: 'Lasara', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_15632',
    Name: 'Brandon',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_15633',
    Name: 'Annawan',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WV_15634',
    Name: 'Wiley Ford',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_AL_15635',
    Name: 'Pine Hill',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_TN_15636',
    Name: 'Rockford',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_IA_15637', Name: 'Newell', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_15638',
    Name: 'Riegelsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MT_15639',
    Name: 'Rocky Boy West',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_OH_15640',
    Name: 'New Holland',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WA_15641',
    Name: 'White Swan',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_TX_15642', Name: 'Rankin', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_15643',
    Name: 'West Salem',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NH_15644',
    Name: 'Woodsville',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_NM_15645',
    Name: 'Alamo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_VA_15646',
    Name: 'St. Paul',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NJ_15647',
    Name: 'Longport',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_MI_15648', Name: 'Mendon', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_IN_15649',
    Name: 'Bunker Hill',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CA_15650',
    Name: 'Blacklake',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_GA_15651', Name: 'Lenox', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_TX_15652',
    Name: 'Indian Springs',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AL_15653',
    Name: 'White Plains',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_AL_15654', Name: 'Mignon', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_CA_15655',
    Name: 'Sunol',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OR_15656', Name: 'Mission', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_IL_15657',
    Name: 'Spaulding',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_15658', Name: 'Winthrop', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_15659',
    Name: 'Gouldsboro',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_15660', Name: 'Dunkerton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OR_15661',
    Name: 'Maywood Park',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_IA_15662', Name: 'Inwood', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NC_15663',
    Name: 'Star',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_DE_15664',
    Name: 'Frederica',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_NE_15665',
    Name: 'Chappell',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_KS_15666', Name: 'McLouth', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_15667',
    Name: 'Mount Jewett',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CO_15668',
    Name: 'Log Lane Village',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_SC_15669',
    Name: 'Inman Mills',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_OK_15670',
    Name: 'Colcord',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_KY_15671',
    Name: 'South Wallins',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_LA_15672',
    Name: 'Madisonville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_GA_15673',
    Name: 'Talbotton',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MI_15674',
    Name: 'Pentwater',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OH_15675', Name: 'Neffs', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_15676',
    Name: 'Paw Paw',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_15677',
    Name: 'Naches',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NY_15678',
    Name: 'Hailesboro',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_15679',
    Name: 'Stonington',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MO_15680', Name: 'Puxico', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_OH_15681',
    Name: 'Bentleyville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WV_15682',
    Name: 'Gassaway',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_IA_15683', Name: 'Readlyn', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_15684',
    Name: 'Fordland',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MO_15685',
    Name: 'Braymer',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MN_15686',
    Name: 'Lilydale',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MD_15687',
    Name: 'Cobb Island',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MI_15688',
    Name: 'Caspian',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PR_15689',
    Name: 'Maguayo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_OK_15690',
    Name: 'Sentinel',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MS_15691',
    Name: 'Crenshaw',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_GA_15692',
    Name: 'Greenville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_TX_15693',
    Name: 'Valley View',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WA_15694',
    Name: 'Ruston',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MN_15695',
    Name: 'Mazeppa',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_15696',
    Name: 'Heidlersburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TN_15697',
    Name: 'Huntland',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_CA_15698',
    Name: 'Summerland',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_15699',
    Name: 'Isleton',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_15700',
    Name: 'Lake Latonka',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AR_15701',
    Name: 'Hackett',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_AZ_15702',
    Name: 'Elephant Head',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_CA_15703',
    Name: 'Happy Camp',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_15704',
    Name: 'Fincastle',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WV_15705',
    Name: 'Bruno',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MI_15706',
    Name: 'Sanford',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_UT_15707',
    Name: 'Apple Valley',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_NC_15708',
    Name: 'Rich Square',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OH_15709', Name: 'Gratis', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AL_15710', Name: 'Minor', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_LA_15711',
    Name: 'Forest Hill',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MI_15712',
    Name: 'Pellston',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IL_15713', Name: 'Witt', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_KY_15714',
    Name: 'Burnside',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WV_15715',
    Name: 'Cameron',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PR_15716',
    Name: 'Betances',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_NE_15717',
    Name: 'Osceola',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_PA_15718',
    Name: 'Paris',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_15719',
    Name: 'Illiopolis',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_GA_15720',
    Name: 'Rutledge',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PA_15721',
    Name: 'South Fork',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SC_15722',
    Name: 'Gayle Mill',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NC_15723',
    Name: 'Kenansville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MO_15724',
    Name: 'Seligman',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_VA_15725',
    Name: 'Laurel Park',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_AK_15726', Name: 'Togiak', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_MI_15727',
    Name: 'Lake Victoria',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_15728',
    Name: 'Potrero',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_15729',
    Name: 'Eastover',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_FL_15730',
    Name: 'Crystal Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_AK_15731', Name: 'Selawik', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_WA_15732',
    Name: 'Malott',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MS_15733',
    Name: 'Maben',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_15734',
    Name: 'Harmony',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_15735', Name: 'Dunkirk', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MN_15736',
    Name: 'Morgan',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_MN_15737', Name: 'Emily', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_NC_15738',
    Name: 'Riegelwood',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_15739',
    Name: 'South Weldon',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WV_15740',
    Name: 'Lumberport',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_IA_15741', Name: 'Armstrong', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_LA_15742',
    Name: 'Loreauville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OH_15743', Name: 'Alger', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_15744',
    Name: 'Dobbins Heights',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NJ_15745',
    Name: 'Bloomsbury',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_LA_15746',
    Name: 'Supreme',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_IA_15747', Name: 'McGregor', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SC_15748',
    Name: 'Jonesville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_AZ_15749',
    Name: 'Teec Nos Pos',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_TN_15750',
    Name: 'Trezevant',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MO_15751',
    Name: 'Waverly',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WV_15752',
    Name: 'Elizabeth',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_AL_15753', Name: 'Snead', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_NE_15754', Name: 'Hooper', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_ND_15755',
    Name: 'Enderlin',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_IN_15756',
    Name: 'Hatfield',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_AL_15757', Name: 'Skyline', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MI_15758',
    Name: 'Lake City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SC_15759',
    Name: 'Wagener',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_TX_15760', Name: "O'Donnell", stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OR_15761', Name: 'Wallowa', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_AK_15762',
    Name: 'Ninilchik',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_GA_15763',
    Name: 'Crawford',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_WI_15764',
    Name: 'Plainfield',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MN_15765',
    Name: 'Rushford Village',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_FL_15766',
    Name: 'Shalimar',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NJ_15767',
    Name: 'Roosevelt',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MI_15768',
    Name: 'Otisville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_TX_15769',
    Name: 'Blooming Grove',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_15770', Name: 'Aspermont', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_ID_15771', Name: 'Franklin', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_WY_15772',
    Name: 'Casper Mountain',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {Id: 'CIT_US_NY_15773', Name: 'Orient', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_WI_15774',
    Name: 'Lone Rock',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_UT_15775', Name: 'Copperton', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_MT_15776', Name: 'Harlem', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_LA_15777',
    Name: 'Simsboro',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_TN_15778',
    Name: 'Coalmont',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_MO_15779', Name: 'Alton', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_SD_15780',
    Name: 'Aurora',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MI_15781',
    Name: 'Middletown',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_15782',
    Name: 'Beaver Meadows',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OK_15783',
    Name: 'Pond Creek',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_VA_15784',
    Name: 'Middleburg',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_CO_15785', Name: 'Calhan', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_IN_15786',
    Name: 'Royal Center',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_AL_15787', Name: 'Gallant', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_IA_15788', Name: 'Hazleton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WV_15789',
    Name: 'Glasgow',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_IL_15790', Name: 'Newman', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NC_15791',
    Name: 'Warrenton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_15792',
    Name: 'Matinecock',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MN_15793',
    Name: 'Blackduck',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_FL_15794',
    Name: 'Penney Farms',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OR_15795', Name: 'Malin', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NE_15796',
    Name: 'Pawnee City',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MI_15797',
    Name: 'Shoreham',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NC_15798',
    Name: 'Ellenboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NH_15799',
    Name: 'North Walpole',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {Id: 'CIT_US_OH_15800', Name: 'Flushing', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_GA_15801',
    Name: 'Luthersville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_IA_15802', Name: 'Sully', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_ID_15803', Name: 'McCammon', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_NE_15804', Name: 'Utica', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_PA_15805',
    Name: 'Spring Hill',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_15806',
    Name: 'Richland',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WI_15807',
    Name: 'Footville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WA_15808',
    Name: 'Marrowstone',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_15809',
    Name: 'Chester Hill',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_15810',
    Name: 'Stoddard',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NE_15811',
    Name: 'Doniphan',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_AR_15812',
    Name: 'Magazine',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PR_15813',
    Name: 'Lamboglia',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_TX_15814',
    Name: 'Chula Vista',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_TN_15815',
    Name: 'St. Joseph',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_AL_15816',
    Name: 'New Union',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_ND_15817',
    Name: 'Harwood',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MI_15818',
    Name: 'Au Gres',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_TX_15819', Name: 'Fabrica', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_15820',
    Name: 'Edmundson',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NM_15821',
    Name: 'Tatum',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_OH_15822', Name: 'Owensville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_15823',
    Name: 'Brimfield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IN_15824',
    Name: 'La Fontaine',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_WY_15825', Name: 'Dayton', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_MI_15826', Name: 'Onaway', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_CO_15827',
    Name: 'Cheyenne Wells',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MT_15828',
    Name: 'St. Ignatius',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_IN_15829', Name: 'Winslow', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_OH_15830',
    Name: 'Pleasant Hills',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_MI_15831', Name: 'Attica', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_VA_15832', Name: 'Esmont', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_TX_15833', Name: 'Crawford', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_WI_15834', Name: 'Arena', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_TX_15835',
    Name: 'Sand Springs',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AL_15836',
    Name: "Jacksons' Gap",
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_GA_15837',
    Name: 'Baconton',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_IN_15838',
    Name: 'Clay City',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_TX_15839', Name: 'New Deal', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OH_15840',
    Name: 'Pigeon Creek',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_15841',
    Name: 'Blue Ball',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_15842',
    Name: 'Coffeeville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_LA_15843',
    Name: 'Sarepta',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_15844',
    Name: 'Smithfield',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_15845',
    Name: 'Highland',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_KY_15846',
    Name: 'Brownsville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_VT_15847',
    Name: 'Island Pond',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_PA_15848',
    Name: 'New Berlin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_15849',
    Name: 'Mortons Gap',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IL_15850',
    Name: 'Brookport',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AL_15851',
    Name: 'Holly Pond',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MI_15852',
    Name: 'Stephenson',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SD_15853',
    Name: 'Eureka',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_KY_15854',
    Name: 'Crab Orchard',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NC_15855',
    Name: 'Middlesex',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_15856',
    Name: 'Hopedale',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_15857',
    Name: 'Elrod',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NM_15858',
    Name: 'Mesita',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_TX_15859',
    Name: 'San Felipe',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IA_15860',
    Name: 'Dakota City',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_MN_15861',
    Name: 'St. Clair',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_SC_15862',
    Name: 'Norris',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_WI_15863',
    Name: 'Pittsville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_FL_15864',
    Name: 'Greenville',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AL_15865',
    Name: 'Magnolia Springs',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NE_15866',
    Name: 'Juniata',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_CA_15867',
    Name: 'Occidental',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_15868', Name: 'Beckville', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AL_15869',
    Name: 'Notasulga',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PA_15870',
    Name: 'Fort Loudon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OK_15871',
    Name: 'Langley',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_UT_15872', Name: 'Veyo', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_WA_15873', Name: 'Roy', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_NJ_15874',
    Name: 'Brookfield',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IA_15875', Name: 'Rockford', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_KY_15876',
    Name: 'Bellefonte',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_OR_15877',
    Name: 'Garibaldi',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MO_15878',
    Name: 'Russellville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MS_15879',
    Name: 'Polkville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_SD_15880',
    Name: 'Lake Andes',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_ID_15881', Name: 'Hazelton', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_SC_15882',
    Name: 'Gray Court',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_TX_15883',
    Name: 'Rising Star',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WA_15884',
    Name: 'Cowiche',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WA_15885',
    Name: 'Mossyrock',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_15886',
    Name: 'Arlington',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NM_15887',
    Name: 'Santa Clara Pueblo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_15888',
    Name: 'Rockwood',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KS_15889', Name: 'Pomona', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_IA_15890', Name: 'Colo', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_AK_15891', Name: 'Emmonak', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_MN_15892',
    Name: 'Fertile',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_OH_15893',
    Name: 'Belle Center',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_15894',
    Name: 'Claysville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AR_15895',
    Name: 'East Camden',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CA_15896',
    Name: 'Penryn',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_15897',
    Name: 'Potomac Park',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WY_15898',
    Name: 'Purple Sage',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_OH_15899',
    Name: 'Waynesfield',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CO_15900',
    Name: 'La Jara',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CA_15901',
    Name: 'Greenville',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_15902', Name: 'Crowell', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_UT_15903', Name: 'Newton', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NC_15904',
    Name: 'Candor',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NE_15905',
    Name: 'Humphrey',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MI_15906',
    Name: 'Weidman',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_LA_15907',
    Name: 'Lafitte',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_IA_15908', Name: 'Redfield', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IN_15909', Name: 'Avoca', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_LA_15910',
    Name: 'Killona',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MA_15911',
    Name: 'Woods Hole',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_KS_15912', Name: 'Mankato', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OH_15913', Name: 'Syracuse', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_15914',
    Name: 'Friedensburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_15915',
    Name: 'Juno Ridge',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_15916', Name: 'Castalia', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IA_15917', Name: 'Afton', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_AR_15918', Name: 'Wilson', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_WY_15919',
    Name: 'Red Butte',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_NY_15920',
    Name: 'Lime Lake',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MN_15921',
    Name: 'Silver Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MO_15922',
    Name: 'Brunswick',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_ID_15923', Name: 'Potlatch', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_PA_15924',
    Name: 'Rural Valley',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KS_15925', Name: 'Downs', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OH_15926', Name: 'Sycamore', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_15927',
    Name: 'Atlas',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_LA_15928', Name: 'Epps', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_MT_15929',
    Name: 'Pioneer Junction',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MT_15930',
    Name: 'Park City',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_PA_15931',
    Name: 'Shickshinny',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KS_15932', Name: 'Jetmore', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IL_15933',
    Name: 'Ringwood',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_ID_15934', Name: 'Oakley', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_IL_15935',
    Name: 'Ridge Farm',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_15936',
    Name: 'Pleasantville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_15937',
    Name: 'Peconic',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IN_15938',
    Name: 'Van Buren',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_AL_15939', Name: 'Gurley', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_OK_15940', Name: 'Adair', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CO_15941',
    Name: 'La Veta',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_AR_15942',
    Name: 'Altheimer',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_WI_15943',
    Name: 'Argyle',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MO_15944',
    Name: 'Loch Lloyd',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IN_15945', Name: 'Selma', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_IL_15946', Name: 'Oreana', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_15947', Name: 'Morgandale', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IN_15948',
    Name: 'Milltown',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IL_15949', Name: 'Hamel', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_AL_15950', Name: 'Mulga', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_UT_15951', Name: 'Riverside', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NJ_15952',
    Name: 'Stone Harbor',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MT_15953',
    Name: 'Woods Bay',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_TX_15954',
    Name: 'Mountain City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_UT_15955', Name: 'Annabella', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NE_15956',
    Name: 'Humboldt',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_GA_15957',
    Name: 'East Newnan',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_OH_15958', Name: 'Edon', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_15959',
    Name: 'Benton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PR_15960',
    Name: 'Juncal',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_LA_15961',
    Name: 'Fifth Ward',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_15962',
    Name: 'Tranquillity',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_15963',
    Name: 'Highfield-Cascade',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NC_15964',
    Name: 'Camden',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IA_15965', Name: 'Carson', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_TX_15966',
    Name: 'Hilshire Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MN_15967',
    Name: 'Henning',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WV_15968',
    Name: 'Hilltop',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WY_15969',
    Name: 'Hartrandt',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_VA_15970',
    Name: 'Parksley',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_GA_15971', Name: 'Bowman', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NM_15972',
    Name: 'El Rito',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_OK_15973', Name: 'Garber', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_MI_15974', Name: 'Lyons', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_OK_15975',
    Name: 'Davenport',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_PA_15976',
    Name: 'Sewickley Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IN_15977', Name: 'Marengo', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_GA_15978',
    Name: 'Waverly Hall',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_NM_15979', Name: 'Mora', stateId: 'NM', stateName: 'New Mexico'},
  {Id: 'CIT_US_MT_15980', Name: 'Vaughn', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_MO_15981',
    Name: 'Pineville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MN_15982',
    Name: 'Walnut Grove',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_KY_15983',
    Name: 'Hollow Creek',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MN_15984',
    Name: 'Martin Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_15985',
    Name: 'Soudersburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_15986',
    Name: 'Faith',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MI_15987',
    Name: 'Burr Oak',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IA_15988',
    Name: 'Correctionville',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_PA_15989',
    Name: 'Colver',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OH_15990',
    Name: 'South Webster',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_TX_15991', Name: 'Joaquin', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_15992',
    Name: 'Aguanga',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_15993',
    Name: 'Dollar Point',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OK_15994',
    Name: 'Limestone',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_OH_15995',
    Name: 'Junction City',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MO_15996',
    Name: 'Wellington',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_15997',
    Name: 'Harrison',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_15998',
    Name: 'Earlville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MI_15999',
    Name: 'Lakes of the North',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_HI_16000', Name: 'Pahoa', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_PA_16001',
    Name: 'Davidsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SD_16002',
    Name: 'Scotland',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_IL_16003',
    Name: 'Livingston',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MO_16004', Name: 'Orrick', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_ID_16005', Name: 'Menan', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_NV_16006', Name: 'Beatty', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_KY_16007',
    Name: 'Lewisburg',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_AZ_16008',
    Name: 'Mountainaire',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_IA_16009', Name: 'Hills', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_RI_16010',
    Name: 'Carolina',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_PA_16011',
    Name: 'Saltsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_16012', Name: 'Resaca', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_FL_16013',
    Name: 'Key Colony Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_16014',
    Name: 'West Middlesex',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OK_16015',
    Name: 'Arapaho',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NM_16016',
    Name: 'San Ildefonso Pueblo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_16017',
    Name: 'Parker',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_16018',
    Name: 'Bethany',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WV_16019',
    Name: 'Piedmont',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_KY_16020',
    Name: 'Stamping Ground',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_LA_16021', Name: 'Morse', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_MO_16022',
    Name: 'Flordell Hills',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WV_16023',
    Name: 'Shrewsbury',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_CO_16024',
    Name: 'Paragon Estates',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_IA_16025', Name: 'Hubbard', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OR_16026', Name: 'Metolius', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_KS_16027',
    Name: 'Mount Hope',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_AZ_16028', Name: 'Sonoita', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_TX_16029', Name: 'Dawson', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_16030',
    Name: 'Dauphin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OK_16031',
    Name: 'Flint Creek',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_PA_16032',
    Name: 'Wiconsico',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_UT_16033', Name: 'Granite', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_IL_16034', Name: 'Ogden', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CO_16035',
    Name: 'Deer Trail',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_OK_16036', Name: 'Kansas', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_TX_16037', Name: 'Pineland', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_16038',
    Name: 'Lakeville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AR_16039',
    Name: 'Diamond City',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_16040',
    Name: 'Grassflat',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_16041',
    Name: 'Freelandville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_OH_16042',
    Name: 'East Sparta',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_NE_16043', Name: 'Lyons', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_PA_16044',
    Name: 'Rutledge',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NE_16045',
    Name: 'Scribner',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_KS_16046', Name: 'Vassar', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WV_16047',
    Name: 'West Union',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WA_16048',
    Name: 'Tekoa',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_IN_16049',
    Name: 'Tecumseh',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_TX_16050', Name: 'Cumby', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_UT_16051', Name: 'Escalante', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_IN_16052',
    Name: 'Lakeville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_KY_16053',
    Name: 'Smiths Grove',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NE_16054',
    Name: 'Walthill',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MN_16055',
    Name: 'Kimball',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_MT_16056', Name: 'Darby', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_MO_16057',
    Name: 'Ferrelview',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MN_16058', Name: 'Isle', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_CA_16059',
    Name: 'East Orosi',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_16060',
    Name: 'Van Buren',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MI_16061', Name: 'Gobles', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_WI_16062',
    Name: 'Elmwood',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IN_16063',
    Name: 'Francesville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_KY_16064',
    Name: 'Bromley',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NC_16065',
    Name: 'Prospect',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IN_16066', Name: 'Gosport', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_AL_16067', Name: 'Hollins', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_TX_16068',
    Name: 'Sunrise Beach Village',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IA_16069', Name: 'Cambridge', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NY_16070',
    Name: 'Brasher Falls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IA_16071',
    Name: 'Morning Sun',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_PA_16072',
    Name: 'Grazierville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MT_16073',
    Name: 'Culbertson',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_TX_16074',
    Name: 'Cedar Point',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IL_16075', Name: 'McLean', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_AL_16076', Name: 'Perdido', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_TN_16077',
    Name: 'Lakewood Park',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_IA_16078',
    Name: 'Graettinger',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_MI_16079',
    Name: 'Mackinaw City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_AK_16080',
    Name: 'Moose Creek',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_PA_16081',
    Name: 'Bear Rocks',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_16082',
    Name: 'Zayante',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_16083', Name: 'Eden', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MD_16084',
    Name: 'Chevy Chase Section Three',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_AL_16085', Name: 'Wedowee', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_IA_16086', Name: 'Melbourne', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MD_16087',
    Name: 'Piney Point',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OH_16088', Name: 'Goshen', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IA_16089', Name: 'Raymond', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_16090',
    Name: 'Morrisdale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_16091',
    Name: 'Manila',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_16092',
    Name: 'Roscoe',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_16093',
    Name: 'Lake Park',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_NE_16094', Name: 'Cairo', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_ID_16095', Name: 'Athol', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_IA_16096', Name: 'Okoboji', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_TX_16097', Name: 'Wells', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NV_16098',
    Name: 'Wadsworth',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_CA_16099',
    Name: 'Clyde',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_16100', Name: 'Oxford', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NE_16101',
    Name: 'Emerson',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NY_16102',
    Name: 'Arkport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AK_16103', Name: 'Hoonah', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_PA_16104',
    Name: 'New Salem',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_16105',
    Name: 'Grant City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_16106',
    Name: 'Pleasant Plains',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WI_16107',
    Name: 'Blanchardville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_16108',
    Name: 'Los Olivos',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NE_16109',
    Name: 'Benkelman',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_OH_16110', Name: 'Smithfield', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TX_16111',
    Name: 'Hilltop Lakes',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_MN_16112', Name: 'Adams', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_IA_16113', Name: 'Walker', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_LA_16114', Name: 'Buras', stateId: 'LA', stateName: 'Louisiana'},
  {Id: 'CIT_US_OH_16115', Name: 'Spencer', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NE_16116',
    Name: 'Minatare',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NJ_16117',
    Name: 'Interlaken',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OK_16118', Name: 'Howe', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_KY_16119',
    Name: 'Campbellsburg',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NC_16120',
    Name: 'Vass',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_16121',
    Name: 'Stronghurst',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IN_16122', Name: 'Shoals', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PA_16123',
    Name: 'East Freedom',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_16124',
    Name: 'Chocowinity',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_AZ_16125', Name: 'Duncan', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_WI_16126',
    Name: 'Wonewoc',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MT_16127', Name: 'Wye', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_CT_16128',
    Name: 'Wilton Center',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_WV_16129',
    Name: 'Gary',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_AR_16130', Name: 'Guy', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_PA_16131',
    Name: 'North Irwin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_16132',
    Name: 'Morningside',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MN_16133',
    Name: 'Clarkfield',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WI_16134',
    Name: 'Coon Valley',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_HI_16135',
    Name: 'Fern Forest',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {Id: 'CIT_US_AK_16136', Name: 'Klawock', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_NE_16137',
    Name: 'Winnebago',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MI_16138',
    Name: 'Springport',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SC_16139',
    Name: 'Turbeville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_ID_16140',
    Name: 'Horseshoe Bend',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_AR_16141',
    Name: 'Stephens',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CO_16142',
    Name: 'Fairplay',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_WI_16143',
    Name: 'Lake Camelot',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_16144',
    Name: 'Belle Terre',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_UT_16145',
    Name: 'Dammeron Valley',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_CA_16146',
    Name: 'Littlerock',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_16147', Name: 'Wataga', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_IL_16148', Name: 'Hurst', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_OK_16149',
    Name: 'Clayton',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NC_16150',
    Name: 'Northwest',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_GA_16151', Name: 'Canon', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_MI_16152', Name: 'Ubly', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_AZ_16153',
    Name: 'Willow Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WA_16154',
    Name: 'Brinnon',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KS_16155',
    Name: 'Enterprise',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_WA_16156',
    Name: 'Grapeview',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_16157',
    Name: 'Carrolltown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MN_16158', Name: 'Bovey', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_OH_16159', Name: 'Seven Mile', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OR_16160', Name: 'Yachats', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_KS_16161',
    Name: 'Spearville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_AL_16162',
    Name: 'Hayneville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NM_16163',
    Name: 'Tierra Amarilla',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NC_16164',
    Name: 'Aulander',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_16165',
    Name: 'Woodhull',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AR_16166',
    Name: 'Mountain Pine',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_TX_16167', Name: 'Knippa', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_16168',
    Name: 'Fultonville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_GA_16169', Name: 'Newborn', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_MI_16170', Name: 'Climax', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_CA_16171',
    Name: 'Alhambra Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_16172',
    Name: 'Pearl City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_LA_16173',
    Name: 'Anacoco',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_AZ_16174',
    Name: 'Gila Crossing',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_TN_16175',
    Name: 'Byrdstown',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_VA_16176',
    Name: 'Mountain Road',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_ID_16177', Name: 'Wallace', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_IN_16178',
    Name: 'Windfall City',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AR_16179',
    Name: 'Norphlet',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_FL_16180',
    Name: 'Steinhatchee',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_LA_16181',
    Name: 'Doyline',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CO_16182',
    Name: 'Foxfield',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CA_16183',
    Name: 'Indio Hills',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_16184',
    Name: 'Sheatown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_16185',
    Name: 'Birnamwood',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NC_16186',
    Name: 'Manns Harbor',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_HI_16187', Name: 'Hana', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_WI_16188', Name: 'Pepin', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_MO_16189',
    Name: 'Walnut Grove',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NY_16190',
    Name: 'Lyndonville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_ND_16191',
    Name: 'Kindred',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_OH_16192', Name: 'Rio Grande', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_16193', Name: 'Scurry', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_16194',
    Name: 'Keysville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_FL_16195',
    Name: 'Allentown',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MI_16196',
    Name: 'Deckerville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_16197',
    Name: 'Gibraltar',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CO_16198',
    Name: 'Beulah Valley',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NY_16199',
    Name: 'Woodsburgh',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_KS_16200', Name: 'Meriden', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WA_16201',
    Name: 'Loon Lake',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_KS_16202', Name: 'Alma', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_KY_16203',
    Name: 'Millersburg',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_NY_16204', Name: 'Savona', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_SD_16205',
    Name: 'Marion',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_MT_16206', Name: 'Worden', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_NJ_16207',
    Name: 'Branchville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NC_16208',
    Name: 'Stantonsburg',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NV_16209',
    Name: 'Virginia City',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_MN_16210',
    Name: 'Belgrade',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_16211',
    Name: 'Cissna Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_KY_16212', Name: 'McKee', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_KS_16213',
    Name: 'Greensburg',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_GA_16214',
    Name: 'Riceboro',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PA_16215',
    Name: 'Beaverdale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_16216',
    Name: 'Hewitt',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WV_16217',
    Name: 'Lesage',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_OR_16218', Name: 'Netarts', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_AL_16219', Name: 'Douglas', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_IA_16220', Name: 'Montrose', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_16221', Name: 'Ossian', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IA_16222',
    Name: 'Middle Amana',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_NC_16223',
    Name: 'Stokes',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IL_16224', Name: 'Alexis', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NC_16225',
    Name: 'Wagram',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PR_16226',
    Name: 'Maricao',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MO_16227',
    Name: 'Flint Hill',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IA_16228', Name: 'Hartford', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_KY_16229',
    Name: 'Blue Ridge Manor',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NE_16230',
    Name: 'Oshkosh',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_CO_16231', Name: 'Hugo', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_NY_16232',
    Name: 'Woodridge',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_16233',
    Name: 'Loma Linda',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MO_16234', Name: 'Conway', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_AZ_16235',
    Name: 'Seligman',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_OH_16236',
    Name: 'Marshallville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NJ_16237',
    Name: 'Wrightstown',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_KY_16238',
    Name: 'Garrison',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OH_16239', Name: 'Baltic', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_16240',
    Name: 'York Springs',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_16241',
    Name: 'Susan Moore',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_WI_16242',
    Name: 'Neopit',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_KS_16243', Name: 'Ashland', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_VA_16244',
    Name: 'Damascus',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_IA_16245', Name: 'Dayton', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_AZ_16246', Name: 'Sawmill', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_WV_16247',
    Name: 'North Hills',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MD_16248',
    Name: 'Bishopville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MN_16249',
    Name: 'Warsaw',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_OK_16250',
    Name: 'Wellston',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_CO_16251', Name: 'Holly', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_IL_16252',
    Name: 'East Galesburg',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_WI_16253', Name: 'Siren', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_AK_16254', Name: 'Alakanuk', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_TX_16255', Name: 'Sanderson', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OK_16256',
    Name: 'Cheyenne',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NM_16257',
    Name: 'Happy Valley',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IA_16258',
    Name: 'Grand Junction',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_NY_16259',
    Name: 'Breesport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OK_16260',
    Name: 'Sterling',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NE_16261',
    Name: 'Hemingford',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_IA_16262', Name: 'Lowden', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_VA_16263',
    Name: 'Atlantic',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_TX_16264',
    Name: "Bailey's Prairie",
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AL_16265',
    Name: 'Somerville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NM_16266',
    Name: 'Cedar Hill',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IN_16267',
    Name: 'Lake Village',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_TX_16268', Name: 'Log Cabin', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IN_16269', Name: 'Kirklin', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_AR_16270',
    Name: 'Plumerville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NY_16271',
    Name: 'Copenhagen',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IN_16272', Name: 'Patoka', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_WI_16273', Name: 'Biron', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_CA_16274',
    Name: 'Forest Ranch',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VA_16275',
    Name: 'Blue Ridge Shores',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_MI_16276', Name: 'Vernon', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_IA_16277', Name: 'Earlville', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AR_16278',
    Name: 'Hermitage',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_OK_16279',
    Name: 'Valley Brook',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_KS_16280', Name: 'Bucklin', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_SC_16281',
    Name: 'Coward',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_GA_16282', Name: 'Screven', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_KY_16283', Name: 'Phelps', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_WA_16284',
    Name: 'Toledo',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MI_16285',
    Name: 'Vermontville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_TX_16286',
    Name: 'Valle Vista',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CO_16287',
    Name: 'Central City',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PA_16288',
    Name: 'Chalfant',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_16289',
    Name: 'Black Creek',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PR_16290',
    Name: 'Espino',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_NY_16291',
    Name: 'Round Lake',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_MN_16292', Name: 'Dover', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_PA_16293',
    Name: 'Norvelt',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_16294', Name: 'Wall Lake', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_16295', Name: 'Walnut', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IN_16296', Name: 'Boswell', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NC_16297',
    Name: 'Pleasant Hill',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IA_16298', Name: 'Exira', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_WY_16299', Name: 'Hanna', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_NC_16300',
    Name: 'White Lake',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_MO_16301', Name: 'Exeter', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_NY_16302',
    Name: 'Schroon Lake',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_16303',
    Name: 'Payne Springs',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_16304',
    Name: 'Hanover',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_16305',
    Name: 'Lamberton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_16306',
    Name: 'Polk',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_16307',
    Name: 'Brantley',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MN_16308',
    Name: 'West Concord',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_VT_16309',
    Name: 'Jeffersonville',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_DE_16310',
    Name: 'St. Georges',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_NY_16311',
    Name: 'Quiogue',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_MD_16312', Name: 'Finzel', stateId: 'MD', stateName: 'Maryland'},
  {Id: 'CIT_US_IN_16313', Name: 'Linden', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_IN_16314',
    Name: 'Knightsville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_SD_16315',
    Name: 'Viborg',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_LA_16316',
    Name: 'Kraemer',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_SD_16317',
    Name: 'Ashland Heights',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_IL_16318',
    Name: 'Tiskilwa',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_FL_16319', Name: 'Raleigh', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_FL_16320',
    Name: 'White Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_WI_16321', Name: 'Plain', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_WA_16322',
    Name: 'May Creek',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_SD_16323',
    Name: 'Philip',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_AR_16324', Name: 'Hardy', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_CA_16325',
    Name: 'Geyserville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_16326',
    Name: 'Elysian',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_MD_16327', Name: 'Eden', stateId: 'MD', stateName: 'Maryland'},
  {Id: 'CIT_US_MO_16328', Name: 'Oregon', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MO_16329',
    Name: 'Lancaster',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IA_16330', Name: 'Riceville', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_VA_16331',
    Name: 'Cluster Springs',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_HI_16332', Name: 'Eden Roc', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_NY_16333',
    Name: 'Hannawa Falls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_ND_16334',
    Name: 'Dunseith',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_AZ_16335',
    Name: 'Miracle Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_TX_16336',
    Name: 'Sierra Blanca',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MI_16337',
    Name: 'Alanson',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_LA_16338',
    Name: 'Tickfaw',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NC_16339',
    Name: 'Mamers',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MI_16340',
    Name: 'Columbiaville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MD_16341',
    Name: 'Port Deposit',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WV_16342',
    Name: 'Powellton',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_TN_16343',
    Name: 'Eagleville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_OH_16344',
    Name: 'Andersonville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_UT_16345', Name: 'Corinne', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_IN_16346',
    Name: 'Homecroft',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NJ_16347',
    Name: 'Lopatcong Overlook',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WV_16348',
    Name: 'Triadelphia',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_TX_16349',
    Name: 'Garza-Salinas II',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_AK_16350', Name: 'Kwethluk', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_SD_16351',
    Name: 'Shindler',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_CO_16352', Name: 'Kiowa', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_IA_16353', Name: 'Milo', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_16354',
    Name: 'Pilot Grove',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TX_16355',
    Name: 'Throckmorton',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IN_16356', Name: 'Hymera', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_ID_16357',
    Name: 'Moyie Springs',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_NC_16358',
    Name: 'Red Cross',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_16359',
    Name: 'Eldred',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IN_16360', Name: 'Hamlet', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_WY_16361', Name: 'Cowley', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_KS_16362',
    Name: 'Sharon Springs',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_NV_16363',
    Name: 'Grass Valley',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_AL_16364',
    Name: 'New Site',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_IN_16365',
    Name: 'Montgomery',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OK_16366', Name: 'Tipton', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_WI_16367',
    Name: 'La Farge',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_KY_16368',
    Name: 'Meadow Vale',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NC_16369',
    Name: 'Columbia',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_16370',
    Name: 'Braceville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_16371', Name: 'Bixby', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PR_16372',
    Name: 'Comunas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_SC_16373',
    Name: 'Lake View',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_IL_16374', Name: 'Essex', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_VA_16375',
    Name: 'Riverdale',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NC_16376',
    Name: 'Alliance',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WV_16377',
    Name: 'Scarbro',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_NM_16378', Name: 'Cuba', stateId: 'NM', stateName: 'New Mexico'},
  {
    Id: 'CIT_US_WY_16379',
    Name: 'Diamondville',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_SD_16380',
    Name: 'Howard',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MN_16381',
    Name: 'Hilltop',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OR_16382', Name: 'Tetherow', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_MT_16383', Name: 'Clinton', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_AR_16384',
    Name: 'Knoxville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_TN_16385',
    Name: 'Ooltewah',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_16386',
    Name: 'Ringtown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_16387',
    Name: 'Leighton',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MN_16388',
    Name: 'Raymond',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_16389',
    Name: 'Upper Exeter',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IL_16390', Name: 'Godley', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_IN_16391',
    Name: 'Fountain City',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NC_16392',
    Name: 'Misenheimer',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_16393',
    Name: 'Gratz',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_16394',
    Name: 'Cass Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_LA_16395',
    Name: 'Port Vincent',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MN_16396',
    Name: 'Twin Valley',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_NE_16397', Name: 'Peru', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_WV_16398',
    Name: 'Boomer',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IL_16399',
    Name: 'Kinmundy',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OR_16400',
    Name: 'Chiloquin',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_MT_16401', Name: 'Bridger', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_SD_16402',
    Name: 'Alcester',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_AL_16403',
    Name: 'Hobson City',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_GA_16404',
    Name: 'Patterson',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MN_16405',
    Name: 'Gibbon',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IA_16406', Name: 'Hedrick', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_16407',
    Name: 'Traver',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_GA_16408',
    Name: 'Pine Lake',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MS_16409',
    Name: 'Walnut',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_AZ_16410',
    Name: 'Round Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_MA_16411',
    Name: 'Onset',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_AK_16412', Name: 'Kasilof', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_PA_16413',
    Name: 'Ravine',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MD_16414',
    Name: 'Church Hill',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_IN_16415',
    Name: 'New Harmony',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MA_16416',
    Name: 'Housatonic',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_WI_16417',
    Name: 'Montreal',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WI_16418',
    Name: 'Mount Calvary',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_ND_16419',
    Name: 'Napoleon',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MO_16420',
    Name: 'Olympian Village',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_16421',
    Name: 'Orangeville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_NY_16422', Name: 'Fonda', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_OH_16423', Name: 'Gettysburg', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TX_16424',
    Name: 'Point Blank',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MI_16425',
    Name: 'Robin Glen-Indiantown',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_16426',
    Name: 'Delaware Water Gap',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_16427',
    Name: 'Greenwood',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_NE_16428', Name: 'Axtell', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_OK_16429',
    Name: 'Haileyville',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_AL_16430',
    Name: 'White Hall',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_TX_16431',
    Name: 'Santa Clara',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_TN_16432',
    Name: 'Crab Orchard',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_16433',
    Name: 'Slatedale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_16434',
    Name: 'Hernandez',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_IL_16435', Name: 'Loami', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OK_16436', Name: 'Ryan', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_MN_16437',
    Name: 'Brooten',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_LA_16438',
    Name: 'Hessmer',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_LA_16439',
    Name: 'Mooringsport',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_MT_16440', Name: 'Carlton', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_IL_16441',
    Name: 'Piper City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_16442',
    Name: 'Clarence',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WI_16443',
    Name: 'St. Nazianz',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TX_16444',
    Name: 'East Alto Bonito',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_OH_16445',
    Name: 'Washingtonville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_VA_16446', Name: 'Atkins', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_PA_16447',
    Name: 'Renningers',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MN_16448', Name: 'Mabel', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_KY_16449',
    Name: 'Perryville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OH_16450', Name: 'Ostrander', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_MO_16451', Name: 'Iberia', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_IL_16452',
    Name: 'Ashmore',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MI_16453', Name: 'Elkton', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_NE_16454', Name: 'Osmond', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_PA_16455',
    Name: 'Everson',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MT_16456', Name: 'Pray', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_WI_16457',
    Name: 'Cambria',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IA_16458', Name: 'West Bend', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NY_16459',
    Name: 'Palatine Bridge',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NC_16460',
    Name: 'High Shoals',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_SC_16461',
    Name: 'Ridge Spring',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CA_16462',
    Name: 'Greeley Hill',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IN_16463',
    Name: 'Ridgeville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IN_16464', Name: 'Dublin', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_OK_16465',
    Name: 'Burneyville',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_AR_16466', Name: 'Wickes', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_KY_16467',
    Name: 'Green Spring',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_UT_16468', Name: 'Redmond', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MS_16469',
    Name: 'Renova',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_ND_16470',
    Name: 'Drayton',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_IL_16471',
    Name: 'Ridgway',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_16472', Name: 'Milford', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MD_16473',
    Name: 'Mount Savage',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CO_16474',
    Name: 'Antonito',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PR_16475',
    Name: 'Parcelas Viejas Borinquen',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_TX_16476', Name: 'Driscoll', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_16477',
    Name: 'Concord',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MO_16478',
    Name: 'Iron Mountain Lake',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IN_16479',
    Name: 'Bainbridge',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_SD_16480',
    Name: 'Estelline',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IA_16481', Name: 'Manilla', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WI_16482',
    Name: 'Woodruff',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_KS_16483', Name: 'Tribune', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_OH_16484',
    Name: 'Chippewa Lake',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_IN_16485', Name: 'Atlanta', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_SC_16486',
    Name: 'Cottageville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_AL_16487',
    Name: 'South Vinemont',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MN_16488',
    Name: 'Hancock',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WA_16489',
    Name: 'Carlsborg',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OH_16490', Name: 'Midvale', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_16491', Name: 'McLean', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MI_16492', Name: 'Parma', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_IA_16493',
    Name: 'Sioux Rapids',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_MN_16494',
    Name: 'Browerville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AR_16495', Name: 'Oppelo', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_NC_16496',
    Name: 'North Topsail Beach',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_16497', Name: 'Mertzon', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_16498',
    Name: 'Bertrand',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_ND_16499',
    Name: 'Hatton',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_OH_16500',
    Name: 'Maple Ridge',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CA_16501',
    Name: 'Bootjack',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_LA_16502',
    Name: 'Rosedale',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NM_16503',
    Name: 'El Duende',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_MI_16504', Name: 'Oscoda', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_OK_16505', Name: 'Blair', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_WA_16506',
    Name: 'Bingen',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_16507',
    Name: 'Langeloth',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MT_16508',
    Name: 'Sheridan',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_TX_16509', Name: 'Oak Ridge', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_16510', Name: 'Galena', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_KS_16511',
    Name: 'Westmoreland',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_TX_16512', Name: 'Lott', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MN_16513',
    Name: 'Ponemah',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_16514',
    Name: 'Lakeshore Gardens-Hidden Acres',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OH_16515', Name: 'Lakeside', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_KS_16516', Name: 'Riverton', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NM_16517',
    Name: 'Tohatchi',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_FL_16518',
    Name: 'Duck Key',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OK_16519', Name: 'Tyrone', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_VA_16520',
    Name: 'Rich Creek',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_OH_16521',
    Name: 'Arlington Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WI_16522',
    Name: 'Whitelaw',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_ID_16523', Name: 'Teton', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_UT_16524', Name: 'Clarkston', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_AL_16525', Name: 'Section', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MI_16526',
    Name: 'Parkdale',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_AL_16527', Name: 'Ariton', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_ID_16528', Name: 'Hauser', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_IA_16529', Name: 'Essex', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_AZ_16530', Name: 'Yarnell', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_CA_16531',
    Name: 'Middletown',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_16532',
    Name: 'Amagansett',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_16533', Name: 'Tuscola', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_16534',
    Name: 'Indianola',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AL_16535',
    Name: 'Edgewater',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_IA_16536', Name: 'Alden', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OH_16537', Name: 'Amanda', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_LA_16538',
    Name: 'Marion',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WV_16539',
    Name: 'Middlebourne',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_KY_16540',
    Name: 'Woodland Hills',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MD_16541',
    Name: 'New Market',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_OK_16542',
    Name: 'Valliant',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NC_16543',
    Name: 'Clarkton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OR_16544', Name: 'Glasgow', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MN_16545',
    Name: 'Cleveland',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WA_16546',
    Name: 'Concrete',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WA_16547',
    Name: 'Cherry Grove',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CO_16548',
    Name: 'Grand View Estates',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_TX_16549',
    Name: 'Falcon Lake Estates',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OK_16550', Name: 'Copan', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_GA_16551', Name: 'Ty Ty', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NE_16552',
    Name: 'Deshler',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MN_16553',
    Name: 'Stockton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AL_16554',
    Name: 'Columbia',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NY_16555',
    Name: 'North Hornell',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_16556',
    Name: 'Pueblo Nuevo',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MS_16557',
    Name: 'Smithville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_KY_16558',
    Name: 'Powderly',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_GA_16559',
    Name: 'Fairmount',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_GA_16560', Name: 'Doerun', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_TX_16561', Name: 'Ector', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AR_16562',
    Name: 'Bradford',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CA_16563',
    Name: 'Butte Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_16564',
    Name: 'Nittany',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KS_16565',
    Name: 'Coldwater',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_NY_16566',
    Name: 'Crystal Beach',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_ND_16567',
    Name: 'Underwood',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_WI_16568',
    Name: 'Wauzeka',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_OK_16569',
    Name: 'Mountain View',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WA_16570',
    Name: 'Carbonado',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MI_16571',
    Name: 'Caseville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_OK_16572',
    Name: 'Wright City',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_SC_16573',
    Name: 'Bucksport',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_16574',
    Name: 'Mocanaqua',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_16575', Name: 'Chilton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KY_16576',
    Name: 'Calhoun',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_OH_16577',
    Name: 'Lake Tomahawk',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_LA_16578',
    Name: 'St. James',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_LA_16579',
    Name: 'Montgomery',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WV_16580',
    Name: 'Anmoore',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WV_16581',
    Name: 'Smithers',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_AR_16582',
    Name: 'Swifton',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CA_16583',
    Name: 'Glen Ellen',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OK_16584',
    Name: 'South Coffeyville',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_NE_16585', Name: 'Oxford', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_MO_16586',
    Name: 'Stewartsville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_16587', Name: 'Millville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PR_16588',
    Name: 'La Parguera',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_OH_16589',
    Name: 'Concorde Hills',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_KS_16590', Name: 'Leon', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_TN_16591', Name: 'Niota', stateId: 'TN', stateName: 'Tennessee'},
  {Id: 'CIT_US_TX_16592', Name: "D'Hanis", stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CO_16593',
    Name: 'Hot Sulphur Springs',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_IL_16594', Name: 'Watson', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_FL_16595',
    Name: 'Branford',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NY_16596',
    Name: 'Silver Springs',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_16597',
    Name: 'New Market',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AZ_16598',
    Name: 'Burnside',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_OH_16599',
    Name: 'Marble Cliff',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_16600',
    Name: 'Jefferson',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KS_16601',
    Name: 'Eastborough',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_AR_16602',
    Name: 'Higginson',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_TX_16603', Name: 'Camp Wood', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_16604', Name: 'Merrill', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_16605',
    Name: 'Pocahontas',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_16606',
    Name: 'Pocono Mountain Lake Estates',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_16607',
    Name: 'Tucker',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MO_16608',
    Name: 'Fairdealing',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NY_16609',
    Name: 'Shelter Island Heights',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NM_16610',
    Name: 'Peña Blanca',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_SD_16611',
    Name: 'Valley Springs',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_OK_16612', Name: 'Wayne', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_SD_16613',
    Name: 'Plankinton',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NC_16614',
    Name: 'Conway',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MI_16615',
    Name: 'South Range',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_16616',
    Name: 'Tampico',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_AR_16617', Name: 'Altus', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_TN_16618',
    Name: 'Philadelphia',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_OH_16619', Name: 'Nevada', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_16620',
    Name: 'Conneautville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_16621',
    Name: 'Rougemont',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OH_16622', Name: 'Philo', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NM_16623',
    Name: 'Napi Headquarters',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IA_16624',
    Name: 'Gilbertville',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_MS_16625',
    Name: 'Sandersville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MN_16626',
    Name: 'Landfall',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_16627',
    Name: 'Confluence',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_16628', Name: 'Burke', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CT_16629',
    Name: 'Bantam',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_TN_16630',
    Name: 'Bethel Springs',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_TX_16631', Name: 'Como', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_16632',
    Name: 'Brownstown',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IN_16633',
    Name: 'Clarks Hill',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_SD_16634',
    Name: 'Highmore',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_SD_16635',
    Name: 'Elkton',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_TX_16636',
    Name: 'Plum Grove',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_VT_16637', Name: 'Orleans', stateId: 'VT', stateName: 'Vermont'},
  {Id: 'CIT_US_GA_16638', Name: 'White', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_FL_16639', Name: 'Lawtey', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_GA_16640',
    Name: 'Bethlehem',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_WI_16641',
    Name: 'Wyocena',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MI_16642',
    Name: 'Tekonsha',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_TX_16643',
    Name: 'Rancho Chico',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MN_16644',
    Name: 'Lanesboro',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_16645',
    Name: 'Edenburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AR_16646', Name: 'Gould', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_PA_16647',
    Name: 'Gordon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KS_16648', Name: 'Wilson', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_16649',
    Name: 'Kreamer',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_16650',
    Name: 'Greenview',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_16651',
    Name: 'New London',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NE_16652',
    Name: 'Clay Center',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_ND_16653',
    Name: 'Mott',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_AL_16654', Name: 'Oakman', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_AR_16655',
    Name: 'Lockesburg',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_IA_16656', Name: 'Garnavillo', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_16657',
    Name: 'Bella Villa',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AZ_16658',
    Name: 'Low Mountain',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_IA_16659', Name: 'Whiting', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_16660', Name: 'Schaller', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WV_16661',
    Name: 'Gallipolis Ferry',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MI_16662',
    Name: 'Merrill',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MO_16663',
    Name: 'Gainesville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MT_16664',
    Name: 'Fairfield',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_KY_16665', Name: 'Salem', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_PA_16666',
    Name: 'Delta',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_16667', Name: 'Wheatland', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_16668',
    Name: 'Gilberton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AZ_16669', Name: 'Arivaca', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_AL_16670',
    Name: 'Woodville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_ND_16671',
    Name: 'Mohall',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_PA_16672',
    Name: 'Jerome',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CO_16673',
    Name: 'Green Mountain Falls',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NY_16674',
    Name: 'Heuvelton',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TN_16675',
    Name: 'Morrison',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_IL_16676',
    Name: 'Elizabeth',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_16677',
    Name: 'Winton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IA_16678', Name: 'Coalville', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WA_16679',
    Name: 'Grayland',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OR_16680', Name: 'Gaston', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_TX_16681', Name: 'Noonday', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_16682',
    Name: 'Stewardson',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_GA_16683',
    Name: 'North High Shoals',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MN_16684',
    Name: 'Holdingford',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_KS_16685',
    Name: 'Whitewater',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_TN_16686',
    Name: 'Bulls Gap',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_AR_16687',
    Name: 'Tyronza',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_IA_16688', Name: 'Fremont', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_16689',
    Name: 'Koppel',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_16690',
    Name: 'Shipshewana',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PR_16691',
    Name: 'Búfalo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_OH_16692', Name: 'Berlin', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MO_16693',
    Name: 'Liberal',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_FL_16694',
    Name: 'Cedar Key',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NC_16695',
    Name: 'Peletier',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MN_16696',
    Name: 'Brownton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WI_16697',
    Name: 'Forest Junction',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IN_16698',
    Name: 'New Washington',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_WI_16699', Name: 'Alma', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_MO_16700',
    Name: 'Golden City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IN_16701',
    Name: 'Grandview',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AR_16702',
    Name: 'Cammack Village',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_SD_16703',
    Name: 'Edgemont',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_AR_16704',
    Name: 'Lakeview',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MO_16705',
    Name: 'Lake Tapawingo',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MN_16706',
    Name: 'Karlstad',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AR_16707',
    Name: 'Quitman',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_NJ_16708', Name: 'Deal', stateId: 'NJ', stateName: 'New Jersey'},
  {Id: 'CIT_US_IL_16709', Name: 'Kansas', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CA_16710',
    Name: 'Challenge-Brownsville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MI_16711',
    Name: 'Buckley',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_CA_16712', Name: 'Etna', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_MO_16713',
    Name: 'New Florence',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AZ_16714',
    Name: 'San Jose',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_AL_16715', Name: 'Addison', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_AL_16716', Name: 'Ider', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NY_16717',
    Name: 'Fair Haven',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_SD_16718',
    Name: 'Kadoka',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_GA_16719',
    Name: 'Flemington',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_ND_16720',
    Name: 'Glen Ullin',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_OH_16721',
    Name: 'Perrysville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_FL_16722', Name: 'Welaka', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_SD_16723',
    Name: 'Parmelee',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IN_16724', Name: 'Lyons', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_OH_16725', Name: 'Scio', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_16726',
    Name: 'Cleveland',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_16727',
    Name: 'Kenwood',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_GA_16728', Name: 'Plains', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_PA_16729',
    Name: 'Courtdale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_GA_16730',
    Name: 'Allenhurst',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_UT_16731', Name: 'Hinckley', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_ND_16732',
    Name: 'Wilton',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_AK_16733',
    Name: 'Point Hope',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_SC_16734',
    Name: 'Mayesville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_TN_16735',
    Name: 'Petros',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MO_16736',
    Name: 'Pilot Knob',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OH_16737',
    Name: 'Hunting Valley',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_IL_16738', Name: 'Tonica', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CA_16739',
    Name: 'Delft Colony',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_16740',
    Name: 'Pine Harbor',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MO_16741',
    Name: 'Jonesburg',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WA_16742',
    Name: 'Pateros',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NM_16743',
    Name: 'Ventura',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_AL_16744', Name: 'Wadley', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_IL_16745', Name: 'Avon', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_16746', Name: 'La Rue', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WV_16747',
    Name: 'West Hamlin',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_CO_16748', Name: 'Nucla', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_CO_16749',
    Name: 'Williamsburg',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_OK_16750', Name: 'Roff', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_PA_16751',
    Name: 'Mount Morris',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OH_16752',
    Name: 'Berlin Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_16753',
    Name: 'North Philipsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_16754', Name: 'Adair', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_16755', Name: 'Ventura', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_16756',
    Name: 'Hickman',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_16757', Name: 'Hillcrest', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WV_16758',
    Name: 'East Dailey',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MO_16759',
    Name: 'Hawk Point',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MN_16760',
    Name: 'Marine on St. Croix',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_OK_16761',
    Name: 'Elmore City',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_SC_16762',
    Name: 'Jefferson',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_16763',
    Name: 'Vicksburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OR_16764', Name: 'Crabtree', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_GA_16765', Name: 'Blythe', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_SC_16766',
    Name: 'Pelion',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_VA_16767',
    Name: 'Riverview',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_ND_16768',
    Name: 'Steele',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_WI_16769', Name: 'Rome', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_VT_16770',
    Name: 'East Barre',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {Id: 'CIT_US_TX_16771', Name: 'Zavalla', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_KY_16772', Name: 'Hanson', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_OH_16773',
    Name: 'New Straitsville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OR_16774', Name: 'Echo', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NV_16775',
    Name: 'Moapa Town',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_MA_16776',
    Name: 'New Seabury',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_AL_16777',
    Name: 'Lexington',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_SD_16778',
    Name: 'Faulkton',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NC_16779',
    Name: 'Moncure',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NE_16780',
    Name: 'Bertrand',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_PA_16781',
    Name: 'New Kingstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_16782',
    Name: 'Seneca Gardens',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_KS_16783', Name: 'Cherokee', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_OH_16784',
    Name: 'Mineral City',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_16785',
    Name: 'Nicholson',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MT_16786',
    Name: 'Walkerville',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_SC_16787',
    Name: 'North',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CO_16788',
    Name: 'Avondale',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MN_16789',
    Name: 'Clear Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_OK_16790',
    Name: 'Woodall',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_KY_16791',
    Name: 'Crofton',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_UT_16792', Name: 'Eureka', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_TN_16793',
    Name: 'Eastview',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_KS_16794', Name: 'Udall', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_OK_16795',
    Name: 'Morrison',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_AK_16796',
    Name: 'Quinhagak',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_IN_16797',
    Name: 'Rosedale',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_FL_16798', Name: 'Vernon', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NM_16799',
    Name: 'Livingston Wheeler',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NC_16800',
    Name: 'Ocracoke',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_16801', Name: 'Normangee', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MN_16802',
    Name: 'Kerkhoven',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AK_16803', Name: 'Savoonga', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_IL_16804',
    Name: 'Hennepin',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_16805',
    Name: 'Vandling',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_16806',
    Name: 'Tigerton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_WI_16807', Name: 'Bruce', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_OH_16808', Name: 'Adena', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_16809',
    Name: 'Grover',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NM_16810',
    Name: 'Algodones',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_TX_16811',
    Name: 'Big Thicket Lake Estates',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OH_16812', Name: 'Quincy', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_UT_16813', Name: 'Lake Shore', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_MT_16814', Name: 'Victor', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_MS_16815',
    Name: 'Sherman',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_AR_16816',
    Name: 'Rockport',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_FL_16817', Name: 'Hosford', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_VA_16818',
    Name: 'New River',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_OK_16819', Name: 'Vici', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NM_16820',
    Name: 'Cordova',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MN_16821',
    Name: 'Westbrook',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MD_16822',
    Name: 'Preston',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MI_16823',
    Name: 'Bay Shore',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NE_16824',
    Name: 'Newman Grove',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NC_16825',
    Name: 'Bogue',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_16826',
    Name: 'Woodland',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MI_16827',
    Name: 'Atlanta',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NY_16828',
    Name: 'Forestville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OK_16829', Name: 'Okay', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_TX_16830', Name: 'Big Wells', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OH_16831',
    Name: 'Chippewa Park',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MD_16832',
    Name: 'Chevy Chase Section Five',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NC_16833',
    Name: 'Whitakers',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IA_16834', Name: 'Murray', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OH_16835', Name: 'Chesapeake', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_16836',
    Name: 'Loyalton',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NE_16837', Name: 'Shelby', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_OK_16838', Name: 'Porum', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_IA_16839', Name: 'Seymour', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_VA_16840',
    Name: 'Tangier',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_NY_16841', Name: 'Corfu', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_PA_16842',
    Name: 'Unionville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_16843',
    Name: 'Cloudcroft',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MN_16844',
    Name: 'Trimont',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AL_16845',
    Name: 'Walnut Grove',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_AK_16846', Name: 'Gambell', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_AL_16847',
    Name: 'Maplesville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PA_16848',
    Name: 'Scalp Level',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_VA_16849', Name: 'Motley', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_FL_16850', Name: 'Wiscon', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_ND_16851',
    Name: "Reile's Acres",
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_IA_16852', Name: 'Stratford', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_16853', Name: 'Alburnett', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_16854',
    Name: 'New Milford',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SC_16855',
    Name: 'Harleyville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_LA_16856', Name: 'Parks', stateId: 'LA', stateName: 'Louisiana'},
  {Id: 'CIT_US_KY_16857', Name: 'Upton', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_IL_16858',
    Name: 'Brooklyn',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_16859',
    Name: 'Wood Heights',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TX_16860',
    Name: 'Holiday Beach',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CO_16861',
    Name: 'Collbran',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_AZ_16862',
    Name: 'East Fork',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_MN_16863',
    Name: 'Greenbush',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AZ_16864',
    Name: 'Ash Fork',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_IN_16865', Name: 'Buffalo', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NY_16866',
    Name: 'North Creek',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TN_16867',
    Name: 'Charleston',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WI_16868',
    Name: 'North Freedom',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NC_16869',
    Name: 'Saluda',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_16870',
    Name: 'Vaughn',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MN_16871',
    Name: 'Hampton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_FL_16872',
    Name: 'East Williston',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_16873',
    Name: 'Deemston',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OR_16874', Name: 'Condon', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_WA_16875',
    Name: 'Quilcene',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IN_16876', Name: 'Medora', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_IA_16877', Name: 'Hospers', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MN_16878',
    Name: 'Courtland',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WI_16879',
    Name: 'Bloomington',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_KY_16880',
    Name: 'Science Hill',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CA_16881',
    Name: 'Inyokern',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_16882', Name: 'Bancroft', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MO_16883', Name: 'Perry', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_WI_16884',
    Name: 'Mellen',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_KS_16885',
    Name: 'Frankfort',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_PR_16886',
    Name: 'Pueblito del Carmen',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_AK_16887',
    Name: 'Unalakleet',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_KS_16888', Name: 'Canton', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MD_16889',
    Name: 'Queenstown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_TN_16890',
    Name: 'Wartrace',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_TX_16891',
    Name: 'San Ygnacio',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_16892',
    Name: 'Foot of Ten',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_16893',
    Name: 'River Road',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_TX_16894',
    Name: 'Pecan Hill',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_FL_16895',
    Name: 'Melbourne Village',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_UT_16896', Name: 'West Wood', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NC_16897',
    Name: 'Jackson Heights',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_KY_16898',
    Name: 'Loretto',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IA_16899',
    Name: 'Battle Creek',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_WV_16900',
    Name: 'Holden',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_OH_16901', Name: 'Warsaw', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_16902',
    Name: 'York Haven',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_16903',
    Name: 'Ridgeway',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_16904', Name: 'Kenefick', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_16905', Name: 'Linn', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AZ_16906',
    Name: 'Wittmann',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_IL_16907', Name: 'Ashkum', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_PA_16908',
    Name: 'Lorain',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_ID_16909', Name: 'Mullan', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_KY_16910',
    Name: 'Corydon',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_TX_16911', Name: 'Lone Oak', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AL_16912', Name: 'Pisgah', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_WA_16913',
    Name: 'Oakville',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_16914',
    Name: 'Cascade',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_16915',
    Name: 'Fort Jones',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IN_16916',
    Name: 'West Lebanon',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AR_16917',
    Name: 'Natural Steps',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_GA_16918', Name: 'Portal', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MD_16919',
    Name: 'McCoole',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_MO_16920', Name: 'Bevier', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MD_16921',
    Name: 'Chesapeake City',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MN_16922',
    Name: 'Brownsdale',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_OK_16923',
    Name: 'Springer',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NM_16924',
    Name: 'Cedar Crest',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IN_16925',
    Name: 'Town of Pines',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_WV_16926',
    Name: 'Mill Creek',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_16927',
    Name: 'Pleasant View',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_16928',
    Name: 'Mokelumne Hill',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WA_16929',
    Name: 'Cohassett Beach',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_16930',
    Name: 'Sewickley Hills',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_16931',
    Name: 'Oriskany Falls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_KS_16932',
    Name: 'Deerfield',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_IL_16933',
    Name: 'Potomac',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_16934',
    Name: 'Bairdford',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_VA_16935', Name: 'Opal', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_MS_16936',
    Name: 'Algoma',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IA_16937', Name: 'Lakeside', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MD_16938',
    Name: 'Mount Aetna',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_TX_16939',
    Name: 'Falls City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_SC_16940',
    Name: 'Wallace',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_IL_16941',
    Name: 'Cedarville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_16942',
    Name: 'Crossville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WV_16943',
    Name: 'Fort Gay',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NC_16944',
    Name: 'Hollister',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_16945', Name: 'Bardwell', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_16946',
    Name: 'Shannon',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_16947',
    Name: 'Wheaton',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AL_16948',
    Name: 'Our Town',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_LA_16949',
    Name: 'Pleasant Hill',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NY_16950',
    Name: 'Copake Lake',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_16951',
    Name: 'Gordonville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_16952',
    Name: 'Coleman',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WI_16953',
    Name: 'Montfort',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MI_16954',
    Name: 'Calumet',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MN_16955',
    Name: 'Clarks Grove',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_OK_16956',
    Name: 'Bokchito',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_IA_16957', Name: 'Olin', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_16958', Name: 'Wellsburg', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_AZ_16959', Name: 'York', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_MS_16960',
    Name: 'Scooba',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IN_16961',
    Name: 'Harrodsburg',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MS_16962',
    Name: 'Thaxton',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MN_16963',
    Name: 'Buffalo Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_16964', Name: 'Silverton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AR_16965',
    Name: 'Madison',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NC_16966',
    Name: 'Newland',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_16967',
    Name: 'Sand Ridge',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OK_16968',
    Name: 'Tenkiller',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NY_16969',
    Name: 'Taconic Shores',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_16970',
    Name: 'North Gate',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_16971',
    Name: 'Gasquet',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WV_16972',
    Name: 'War',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_AR_16973', Name: 'Amity', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_NJ_16974',
    Name: 'Blackwells Mills',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IL_16975', Name: 'Mounds', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_WI_16976',
    Name: 'Wild Rose',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NC_16977',
    Name: 'Sedalia',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_16978',
    Name: 'Nemacolin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_16979',
    Name: 'East Butler',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_16980',
    Name: 'North Pearsall',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PR_16981',
    Name: 'Tallaboa',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_TX_16982',
    Name: 'Westover Hills',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_TN_16983',
    Name: 'Chesterfield',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_ME_16984',
    Name: 'Passamaquoddy Pleasant Point',
    stateId: 'ME',
    stateName: 'Maine',
  },
  {
    Id: 'CIT_US_CA_16985',
    Name: 'Tres Pinos',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_16986', Name: 'Trenton', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_16987', Name: 'Detroit', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KY_16988',
    Name: 'Spring Valley',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_NE_16989', Name: 'Elwood', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_OH_16990', Name: 'Bloomdale', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_16991',
    Name: 'Bradford',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NM_16992',
    Name: 'Santa Ana Pueblo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IL_16993',
    Name: 'Woodlawn',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OK_16994', Name: 'Porter', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_KS_16995', Name: 'Scranton', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_CA_16996',
    Name: 'Calpella',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OK_16997',
    Name: 'Boswell',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_UT_16998', Name: 'Stockton', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_KS_16999', Name: 'Onaga', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IL_17000',
    Name: 'Aroma Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_17001', Name: 'Ruthven', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MN_17002',
    Name: 'Hoffman',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_OH_17003',
    Name: 'Wayne Lakes',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CA_17004',
    Name: 'Junction City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_17005',
    Name: 'Saxton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AL_17006', Name: 'Wilton', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_PA_17007',
    Name: 'Millerstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_ID_17008', Name: 'Ririe', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_TX_17009', Name: 'Kress', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MO_17010', Name: 'Miller', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MI_17011',
    Name: 'Maple Rapids',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_TX_17012', Name: 'McDade', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_17013',
    Name: 'Waubeka',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NM_17014',
    Name: 'San Jose',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_AR_17015',
    Name: 'Gillett',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_OH_17016', Name: 'McClure', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_17017',
    Name: 'Reeseville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NM_17018',
    Name: 'Tyrone',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_OR_17019', Name: 'Powers', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_GA_17020',
    Name: 'Ochlocknee',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_OH_17021',
    Name: 'Rocky Fork Point',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_KS_17022', Name: 'Minneola', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_OH_17023',
    Name: 'Twinsburg Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_IN_17024', Name: 'Colfax', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_IA_17025', Name: 'Boyden', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AL_17026',
    Name: 'Hollis Crossroads',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_GA_17027',
    Name: 'Kingston',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_MT_17028', Name: 'Cascade', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_IN_17029',
    Name: 'Van Bibber Lake',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IL_17030',
    Name: 'La Moille',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TN_17031',
    Name: 'Hollow Rock',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_AR_17032', Name: 'Oxford', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_PA_17033',
    Name: 'West Pittsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_17034',
    Name: 'Timber Hills',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_17035',
    Name: 'Hindman',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_KS_17036',
    Name: 'Mound City',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_OH_17037',
    Name: 'West Mansfield',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IL_17038',
    Name: 'Dongola',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_17039',
    Name: 'Millville',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IN_17040', Name: 'Paragon', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_SC_17041',
    Name: 'Reidville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OH_17042', Name: 'Ohio City', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_17043',
    Name: 'Nuangola',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_17044',
    Name: 'Point Comfort',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_KY_17045',
    Name: 'Fleming-Neon',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NC_17046',
    Name: 'Kingstown',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_17047', Name: 'Beasley', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_17048', Name: 'Sardis', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AR_17049', Name: 'Keiser', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_IN_17050', Name: 'Elnora', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_OK_17051', Name: 'Kiowa', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_WI_17052',
    Name: 'Rockland',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_17053',
    Name: 'Morgantown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_17054',
    Name: 'West Alto Bonito',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NC_17055',
    Name: 'Ingold',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MN_17056',
    Name: 'Hendricks',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_17057',
    Name: 'Salisbury',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_17058',
    Name: 'Leawood',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_FL_17059', Name: 'Andrews', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_AK_17060', Name: 'Noorvik', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_AK_17061',
    Name: 'Tuntutuliak',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_ND_17062',
    Name: 'Hebron',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_GA_17063', Name: 'Alapaha', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_IN_17064',
    Name: 'Pennville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IL_17065',
    Name: 'Kirkwood',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_17066',
    Name: 'Pe Ell',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_AR_17067', Name: 'Weiner', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_NC_17068',
    Name: 'Pikeville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_17069',
    Name: 'Pine Valley',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_17070',
    Name: 'Nocona Hills',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_17071',
    Name: 'Penns Creek',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_17072', Name: 'Ricardo', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_17073',
    Name: 'Clayton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_17074',
    Name: 'New Bloomfield',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AK_17075',
    Name: 'Toksook Bay',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_MD_17076',
    Name: 'Upper Marlboro',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_SD_17077',
    Name: 'Kimball',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_KY_17078',
    Name: 'Bardwell',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NC_17079',
    Name: 'McAdenville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TX_17080',
    Name: 'Chillicothe',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_TX_17081',
    Name: 'Carrizo Hill',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CA_17082',
    Name: 'Lytle Creek',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SD_17083',
    Name: 'New Underwood',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_KS_17084', Name: 'Haviland', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_TX_17085',
    Name: 'Sunset Valley',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_KY_17086',
    Name: 'Brownsboro Farm',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MN_17087',
    Name: 'Kasota',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AZ_17088', Name: 'McNary', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_PA_17089',
    Name: 'Blue Ridge Summit',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_17090',
    Name: 'Ellendale',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_SC_17091',
    Name: 'Six Mile',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_17092',
    Name: 'Rowes Run',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_GA_17093',
    Name: 'Sunset Village',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_TN_17094', Name: 'Adams', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_IL_17095',
    Name: 'Farmersville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_17096', Name: 'Strawn', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MT_17097',
    Name: 'South Hills',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MN_17098',
    Name: 'Marble',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_MO_17099', Name: 'Morley', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_KY_17100',
    Name: 'Kuttawa',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_TX_17101', Name: 'Petrolia', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CO_17102',
    Name: 'Watkins',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MD_17103',
    Name: 'Cecilton',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_AL_17104',
    Name: 'St. Florian',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_CA_17105',
    Name: 'Lakehead',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WA_17106',
    Name: 'Vader',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_17107',
    Name: 'Waterloo',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MI_17108',
    Name: 'Hillman',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_AZ_17109',
    Name: 'Walnut Creek',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_SD_17110',
    Name: 'Armour',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_AR_17111', Name: 'McRae', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_TX_17112', Name: 'Oak Grove', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NJ_17113',
    Name: 'Rocky Hill',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_TX_17114', Name: 'Hull', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SD_17115',
    Name: 'Colton',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MN_17116',
    Name: 'Freeport',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_17117',
    Name: 'Bluford',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_WY_17118', Name: 'Osmond', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_TN_17119',
    Name: 'Palmer',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_MI_17120', Name: 'McBain', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_VT_17121',
    Name: 'South Royalton',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_IL_17122',
    Name: 'Saybrook',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AL_17123',
    Name: 'Bristow Cove',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NC_17124',
    Name: 'Caroleen',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_17125', Name: 'Happy', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_GA_17126', Name: 'Adrian', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WV_17127',
    Name: 'Chattaroy',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NC_17128',
    Name: 'Efland',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IA_17129', Name: 'Coggon', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CO_17130',
    Name: 'Silver Cliff',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MS_17131',
    Name: 'Liberty',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NM_17132',
    Name: 'Peñasco',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_17133',
    Name: 'Sligo',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_17134',
    Name: 'Beverly',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NY_17135',
    Name: 'Rushville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MN_17136',
    Name: 'Sebeka',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_17137',
    Name: 'La Tina Ranch',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MS_17138',
    Name: 'Cloverdale',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WV_17139',
    Name: 'White Hall',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_OR_17140',
    Name: 'Canyon City',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_IL_17141',
    Name: 'Forest View',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MD_17142',
    Name: 'Eckhart Mines',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WI_17143',
    Name: 'Potosi',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_17144',
    Name: 'Turbotville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IA_17145',
    Name: 'Albert City',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_IL_17146',
    Name: 'Chemung',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_WI_17147', Name: 'Eleva', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_WI_17148',
    Name: 'Sullivan',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WA_17149',
    Name: 'Taholah',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_17150',
    Name: 'Bodega Bay',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_17151',
    Name: 'Ocean Isle Beach',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WI_17152',
    Name: 'Auburndale',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NC_17153',
    Name: 'Cliffside',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TX_17154',
    Name: 'Pleasant Hill',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MS_17155',
    Name: 'Mathiston',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_17156',
    Name: 'Thompsontown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_17157',
    Name: 'South New Castle',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_17158', Name: 'Russia', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MN_17159',
    Name: 'Loretto',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NC_17160',
    Name: 'Holden Beach',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_17161',
    Name: 'Potters Hill',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_AL_17162', Name: 'Joppa', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_OH_17163', Name: 'Racine', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TN_17164',
    Name: 'Friendship',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MN_17165',
    Name: 'Center City',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_GA_17166',
    Name: 'Sycamore',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_ID_17167', Name: 'Kooskia', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_WV_17168',
    Name: 'Bolt',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IL_17169',
    Name: 'Deer Creek',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_17170',
    Name: 'Kernville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NE_17171',
    Name: 'Crofton',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_CO_17172',
    Name: 'Silverton',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_IN_17173',
    Name: 'St. Leon',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IN_17174',
    Name: 'Oldenburg',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AK_17175',
    Name: 'Two Rivers',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_WY_17176',
    Name: 'James Town',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_TN_17177',
    Name: 'Puryear',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WV_17178',
    Name: 'Addison',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PR_17179',
    Name: 'Vayas',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_PA_17180',
    Name: 'Lake Winola',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_17181',
    Name: 'Greenwood',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_17182',
    Name: 'Matherville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_WI_17183', Name: 'Viola', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_NH_17184',
    Name: 'Mountain Lakes',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {Id: 'CIT_US_IL_17185', Name: 'Oneida', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_17186', Name: 'Austinburg', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_NE_17187', Name: 'Alda', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_WA_17188',
    Name: 'Bay View',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KY_17189',
    Name: 'Rolling Fields',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CO_17190',
    Name: 'Loghill Village',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NY_17191',
    Name: 'Strykersville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_UT_17192',
    Name: 'Timber Lakes',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_OR_17193',
    Name: 'Manzanita',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MO_17194',
    Name: 'Cleveland',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_ND_17195',
    Name: 'Ashley',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_TX_17196',
    Name: 'Thunderbird Bay',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NE_17197',
    Name: 'Elmwood',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IL_17198',
    Name: 'Mechanicsburg',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_KY_17199',
    Name: 'Strathmoor Village',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MN_17200',
    Name: 'La Prairie',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_17201',
    Name: 'New Cuyama',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_17202',
    Name: 'Arroyo Colorado Estates',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_GA_17203',
    Name: 'Flovilla',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MS_17204',
    Name: 'Meridian Station',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_MO_17205', Name: 'Agency', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_IL_17206', Name: 'Cisne', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_KY_17207',
    Name: 'Parkway Village',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MN_17208',
    Name: 'Motley',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_OK_17209',
    Name: 'Welling',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_TX_17210', Name: 'Amherst', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_WY_17211', Name: 'Story', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_PA_17212',
    Name: 'Deer Lake',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NE_17213',
    Name: 'St. Edward',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_NV_17214', Name: 'Genoa', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_MS_17215',
    Name: 'Heidelberg',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IL_17216',
    Name: 'Palmyra',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_17217',
    Name: 'Millington',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_KY_17218',
    Name: 'Pippa Passes',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IL_17219',
    Name: 'Dalzell',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_17220', Name: 'Blairstown', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_TN_17221',
    Name: 'Maury City',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_IL_17222',
    Name: 'Taylor Springs',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IL_17223', Name: 'Bluffs', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MN_17224',
    Name: 'Wabasso',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PR_17225',
    Name: 'Santa Clara',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_SC_17226',
    Name: 'Clio',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CO_17227',
    Name: 'San Luis',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_IA_17228', Name: 'Stanwood', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_FL_17229', Name: 'Ona', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MD_17230',
    Name: 'Sharptown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_VA_17231',
    Name: 'Remington',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_KS_17232', Name: 'Clyde', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_UT_17233', Name: 'Virgin', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_IN_17234',
    Name: 'St. Meinrad',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IN_17235',
    Name: 'Carlisle',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PA_17236',
    Name: 'Wampum',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_17237',
    Name: 'San Simeon',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_17238',
    Name: 'Friendship',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_GA_17239', Name: 'Milan', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_IN_17240',
    Name: 'Elberfeld',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_KY_17241',
    Name: 'McDowell',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IA_17242', Name: 'Van Horne', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NE_17243',
    Name: 'Hershey',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_CA_17244',
    Name: 'Valley Acres',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VA_17245',
    Name: 'Round Hill',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_17246',
    Name: 'Fulton',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_17247', Name: 'Thayer', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_NE_17248', Name: 'Beemer', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_MT_17249', Name: 'Joliet', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_IA_17250', Name: 'Shueyville', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_17251',
    Name: 'Niantic',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_17252',
    Name: 'Greendale',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_GA_17253', Name: 'Milner', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_KS_17254',
    Name: 'Lecompton',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_AZ_17255',
    Name: 'Round Rock',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WV_17256',
    Name: 'Justice',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_CO_17257',
    Name: 'Eldorado Springs',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_OR_17258', Name: 'Monroe', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MN_17259',
    Name: 'Clarissa',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_17260',
    Name: 'Pennsbury Village',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_17261',
    Name: 'Lemitar',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_OR_17262', Name: 'Cove', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_PA_17263',
    Name: 'Bendersville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_17264',
    Name: 'Sycamore Hills',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_SD_17265',
    Name: 'McLaughlin',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_TX_17266', Name: 'Bluetown', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_17267',
    Name: 'Schenevus',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_17268',
    Name: 'Yates City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_17269',
    Name: 'Grantsboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TX_17270',
    Name: 'El Rancho Vela',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IN_17271',
    Name: 'Rocky Ripple',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_LA_17272',
    Name: 'Greensburg',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_GA_17273',
    Name: 'Glenwood',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_VA_17274',
    Name: 'Oak Level',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_OH_17275', Name: 'Jewett', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_17276',
    Name: 'Verlot',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OR_17277', Name: 'Weston', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_WI_17278',
    Name: 'Fremont',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MO_17279',
    Name: 'Viburnum',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TN_17280',
    Name: 'Summertown',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_MN_17281', Name: 'Hills', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_AL_17282', Name: 'Allgood', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_IL_17283',
    Name: 'New Boston',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MI_17284',
    Name: 'Sheridan',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MS_17285',
    Name: 'Crawford',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IN_17286', Name: 'Holland', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_IL_17287',
    Name: 'Alhambra',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_AK_17288', Name: 'Yakutat', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_MN_17289',
    Name: 'Welcome',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AL_17290',
    Name: 'Perdido Beach',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_KS_17291',
    Name: 'Pretty Prairie',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_OR_17292', Name: 'Melrose', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_UT_17293', Name: 'Oak City', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_KY_17294',
    Name: 'Wickliffe',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MO_17295',
    Name: 'Birch Tree',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_KS_17296', Name: 'Hanover', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WA_17297',
    Name: 'Lake Roesiger',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MN_17298',
    Name: 'Heron Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NY_17299',
    Name: 'Port Leyden',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_SD_17300',
    Name: 'Woonsocket',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_OH_17301', Name: 'Timberlake', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_17302',
    Name: 'Dauberville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_17303',
    Name: 'Ottertail',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_VA_17304',
    Name: 'New Hope',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MD_17305',
    Name: 'Sharpsburg',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_OH_17306',
    Name: 'Little Hocking',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_AZ_17307',
    Name: 'Vicksburg',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_IN_17308', Name: 'Waldron', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_IL_17309',
    Name: 'Creston',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_17310',
    Name: 'Norwood',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MO_17311',
    Name: 'Norborne',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TN_17312',
    Name: 'Gallaway',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_IL_17313',
    Name: 'Evansville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_17314',
    Name: 'Lake Arthur Estates',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MT_17315', Name: 'Busby', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_NC_17316',
    Name: 'Greenevers',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_17317',
    Name: 'New Florence',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_17318',
    Name: 'Asharoken',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_KY_17319',
    Name: 'North Middletown',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_17320',
    Name: 'Clarence',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_17321',
    Name: 'East Pecos',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_TX_17322',
    Name: 'New Chapel Hill',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NC_17323',
    Name: 'Woodlawn',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_17324',
    Name: 'Satsop',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MD_17325',
    Name: 'Galesville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_OK_17326',
    Name: 'Savanna',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_IL_17327',
    Name: 'Cordova',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_ID_17328', Name: 'Carey', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_MN_17329',
    Name: 'Stephen',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_17330',
    Name: 'North San Pedro',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MN_17331',
    Name: 'Minnesota Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_17332',
    Name: 'South Wilmington',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WY_17333',
    Name: 'Shoshoni',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {Id: 'CIT_US_OH_17334', Name: 'Minford', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_VT_17335',
    Name: 'North Springfield',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_OH_17336',
    Name: 'Kirtland Hills',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WI_17337',
    Name: 'Taycheedah',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_KY_17338', Name: 'Barlow', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_KY_17339', Name: 'Lynch', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_PA_17340',
    Name: 'Picture Rocks',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SD_17341',
    Name: 'Alexandria',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NM_17342',
    Name: 'Nenahnezad',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IL_17343',
    Name: 'Coffeen',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OK_17344', Name: 'Remy', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_TX_17345', Name: 'Sunset', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SC_17346',
    Name: 'Gilbert',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_IA_17347', Name: 'Stanton', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_17348', Name: 'Agency', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_17349',
    Name: 'Green Valley',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_GA_17350',
    Name: 'Blairsville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_OK_17351',
    Name: 'Rock Island',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MT_17352',
    Name: 'Florence',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_IA_17353', Name: 'Washburn', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AZ_17354',
    Name: 'Winslow West',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_PA_17355',
    Name: 'Rossiter',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_17356', Name: 'Shawnee', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PR_17357',
    Name: 'Palo Seco',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MI_17358',
    Name: 'Vineyard Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WV_17359',
    Name: 'Franklin',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_AR_17360',
    Name: 'Caddo Valley',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_UT_17361', Name: 'Huntsville', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MS_17362',
    Name: 'Redwater',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MN_17363',
    Name: 'Argyle',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_SC_17364',
    Name: 'Elloree',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CA_17365',
    Name: 'Dustin Acres',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_17366', Name: 'Noble', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_TX_17367', Name: 'Frost', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SD_17368',
    Name: 'Castlewood',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_OH_17369', Name: 'Mendon', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_17370',
    Name: 'Almena',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TN_17371',
    Name: 'Flintville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NC_17372',
    Name: 'Bolton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IN_17373', Name: 'Harmony', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PA_17374',
    Name: 'Tidioute',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MT_17375', Name: 'Arlee', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_PA_17376',
    Name: 'Jennerstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_17377',
    Name: 'Clayton',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_CO_17378', Name: 'Simla', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_OH_17379', Name: 'Malta', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_17380',
    Name: 'Francis Creek',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_17381',
    Name: 'Vermont',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_17382',
    Name: 'Harrah',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_IL_17383',
    Name: 'Grafton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_17384', Name: 'Brighton', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_HI_17385', Name: 'Halaula', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_MN_17386',
    Name: 'Rollingstone',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NC_17387',
    Name: 'Whitsett',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_17388', Name: 'Roxton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KY_17389',
    Name: 'Cannonsburg',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OR_17390', Name: 'Dufur', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_IL_17391',
    Name: 'Lost Nation',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CO_17392',
    Name: 'Stratton',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MS_17393',
    Name: 'Crowder',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_VA_17394', Name: 'Saluda', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_MO_17395',
    Name: 'Cardwell',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MN_17396', Name: 'Alden', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_ID_17397', Name: 'Notus', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_IL_17398',
    Name: 'Findlay',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_17399',
    Name: 'St. Lucie Village',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_17400',
    Name: 'West City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_AK_17401', Name: 'Kotlik', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_NM_17402',
    Name: 'La Mesa',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MD_17403',
    Name: 'Bryantown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_ID_17404', Name: 'Roberts', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_GA_17405', Name: 'Cohutta', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_HI_17406', Name: 'Paukaa', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_WI_17407',
    Name: 'Iron River',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NM_17408',
    Name: 'Ojo Amarillo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NC_17409',
    Name: 'Aquadale',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IL_17410', Name: 'Valier', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_AZ_17411', Name: 'Tacna', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_NC_17412',
    Name: 'Robbinsville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_KS_17413',
    Name: 'Waterville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_CA_17414',
    Name: 'Ducor',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_KS_17415', Name: 'Weir', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_GA_17416',
    Name: 'Smithville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NY_17417',
    Name: 'East Avon',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IA_17418', Name: 'Moravia', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SD_17419',
    Name: 'Canistota',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_IN_17420',
    Name: 'Roseland',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OR_17421', Name: "O'Brien", stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MO_17422',
    Name: 'Bull Creek',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IL_17423', Name: 'Alpha', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MN_17424',
    Name: 'Lake Benton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AL_17425', Name: 'Lynn', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_FL_17426',
    Name: 'Micanopy',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AR_17427',
    Name: 'Highfill',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_OH_17428',
    Name: 'Lake Milton',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_17429',
    Name: 'East Vandergrift',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_17430',
    Name: 'Winchester',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TN_17431',
    Name: 'Middleton',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_17432',
    Name: 'Cochranville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CO_17433',
    Name: 'Dove Creek',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_IA_17434', Name: 'Elgin', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_UT_17435', Name: 'Thatcher', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_FL_17436', Name: 'Jay', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_ND_17437',
    Name: 'Milnor',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_SD_17438',
    Name: 'Onida',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MT_17439',
    Name: 'Hungry Horse',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_WI_17440',
    Name: 'Livingston',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NE_17441',
    Name: 'Clarkson',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_AZ_17442',
    Name: 'Tees Toh',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_ID_17443', Name: 'Downey', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_MN_17444',
    Name: 'Taconite',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_17445',
    Name: 'Dicksonville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AL_17446', Name: 'Excel', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_TX_17447', Name: 'Campbell', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_17448',
    Name: 'Klamath',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OK_17449', Name: 'Binger', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_WI_17450',
    Name: 'Norwalk',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MI_17451',
    Name: 'Rose City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_17452',
    Name: 'Loleta',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_17453',
    Name: 'Nelsonville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_17454',
    Name: 'Ackermanville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KY_17455', Name: 'Wingo', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_IL_17456',
    Name: 'Golconda',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WV_17457',
    Name: 'Davis',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MN_17458',
    Name: 'The Lakes',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_17459',
    Name: 'Niland',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WA_17460',
    Name: 'Nisqually Indian Community',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_17461',
    Name: 'Cohasset',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_LA_17462',
    Name: 'Mangham',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_KY_17463',
    Name: 'Brooksville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_AZ_17464', Name: 'Hayden', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_GA_17465', Name: 'Gibson', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_KS_17466', Name: 'Hoyt', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WV_17467',
    Name: 'Jefferson',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_AL_17468',
    Name: 'Pinckard',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_VA_17469', Name: 'Selma', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_WV_17470',
    Name: 'Man',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WI_17471',
    Name: 'Ridgeway',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_17472',
    Name: 'Patton Village',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_17473', Name: 'Geronimo', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OH_17474',
    Name: 'Candlewood Lake',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NM_17475',
    Name: 'Melrose',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_UT_17476', Name: 'Springdale', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_LA_17477',
    Name: 'Grosse Tete',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_OR_17478',
    Name: 'Johnson City',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_LA_17479',
    Name: 'Mermentau',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_AR_17480',
    Name: 'Hartford',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_VA_17481',
    Name: 'Hamilton',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_OH_17482', Name: 'Risingsun', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_VA_17483',
    Name: 'Dahlgren Center',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_17484', Name: 'New Hope', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CO_17485',
    Name: 'Westcliffe',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_TX_17486',
    Name: 'Santa Maria',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_NE_17487', Name: 'Firth', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_PA_17488',
    Name: 'Winterstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_17489', Name: 'Graford', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SD_17490',
    Name: 'Selby',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_FL_17491',
    Name: 'Laurel Hill',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AL_17492',
    Name: 'Reece City',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_TX_17493', Name: 'Lovelady', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_17494',
    Name: 'Westwood',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_VA_17495', Name: 'Boyce', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_TX_17496', Name: 'Ladonia', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_17497', Name: 'Davis', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_TN_17498',
    Name: 'Allardt',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_AL_17499',
    Name: 'Stockton',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NM_17500',
    Name: 'Kirtland',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_17501',
    Name: 'Arcadia University',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_17502',
    Name: 'Shenandoah Retreat',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WA_17503',
    Name: 'Fairfield',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_17504',
    Name: 'Wanamie',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OK_17505',
    Name: 'Cartwright',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_DE_17506',
    Name: 'Millville',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_IN_17507',
    Name: 'West College Corner',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MO_17508',
    Name: 'Queen City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_SC_17509',
    Name: 'Sharon',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MO_17510',
    Name: 'La Belle',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_ND_17511',
    Name: 'Lakota',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_AK_17512',
    Name: 'Pilot Station',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_TN_17513',
    Name: 'Michie',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_IN_17514', Name: 'English', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PA_17515',
    Name: 'Atlasburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MT_17516',
    Name: 'Parker School',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MS_17517',
    Name: 'Anguilla',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CA_17518',
    Name: 'Douglas City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NM_17519',
    Name: 'Chamberino',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MN_17520',
    Name: 'Randall',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MN_17521',
    Name: 'Balaton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_VT_17522',
    Name: 'Derby Line',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_NC_17523',
    Name: 'Catawba',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_17524',
    Name: 'Trout Lake',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_TX_17525',
    Name: 'New Berlin',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IA_17526', Name: 'What Cheer', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_ID_17527', Name: 'Groveland', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_ID_17528',
    Name: 'Hayden Lake',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_AL_17529',
    Name: 'Dodge City',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_CA_17530',
    Name: 'Peters',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WV_17531',
    Name: 'Waverly',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_TX_17532', Name: 'Hawley', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IN_17533',
    Name: 'New Market',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_SD_17534',
    Name: 'Tripp',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_FL_17535',
    Name: 'Bradley Junction',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OK_17536', Name: 'Carney', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_WI_17537',
    Name: 'Camp Douglas',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_17538',
    Name: 'Pultneyville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_WI_17539', Name: 'Avoca', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_TX_17540',
    Name: 'Blue Berry Hill',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_17541',
    Name: 'St. Francisville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_17542', Name: 'Farmington', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NY_17543',
    Name: 'Oneida Castle',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MN_17544',
    Name: 'Glenville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_OH_17545',
    Name: 'Lake Buckhorn',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OH_17546', Name: 'Bettsville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IA_17547', Name: 'Fontanelle', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MI_17548',
    Name: 'Port Austin',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_17549',
    Name: 'Doyle',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_17550',
    Name: 'Hammondsport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NC_17551',
    Name: 'Richfield',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_17552',
    Name: 'Paul Smiths',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IN_17553',
    Name: 'Zanesville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_OK_17554',
    Name: 'Earlsboro',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_ID_17555',
    Name: 'Smelterville',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_WV_17556',
    Name: 'Peterstown',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NY_17557',
    Name: 'East Randolph',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AR_17558',
    Name: 'Huntington',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NC_17559',
    Name: 'Patterson Springs',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_AR_17560',
    Name: 'Imboden',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_OR_17561',
    Name: 'Foots Creek',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_IL_17562',
    Name: 'Burlington',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CO_17563',
    Name: "St. Mary's",
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PA_17564',
    Name: 'Mifflin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_17565', Name: 'Cushing', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_KS_17566', Name: 'Ozawkie', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_TX_17567', Name: 'Pattison', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OH_17568',
    Name: 'Holiday Lakes',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_AL_17569',
    Name: 'North Courtland',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PA_17570',
    Name: 'Pardeesville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_17571',
    Name: 'Ridgeley',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_KY_17572', Name: 'Inez', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_SC_17573',
    Name: 'Enoree',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_AL_17574',
    Name: 'Baileyton',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_OH_17575', Name: 'Shiloh', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_17576', Name: 'Lolita', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MO_17577', Name: 'Parma', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_WI_17578',
    Name: 'Webster',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_FL_17579',
    Name: 'Sea Ranch Lakes',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MN_17580',
    Name: 'Grove City',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IA_17581',
    Name: 'St. Charles',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_TX_17582', Name: 'Roby', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IN_17583', Name: 'Camden', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PA_17584',
    Name: 'Conneaut Lake',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_17585',
    Name: 'Suttons Bay',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_17586',
    Name: 'Walnuttown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_17587',
    Name: 'Scottsville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_OH_17588', Name: 'Cygnet', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NM_17589',
    Name: 'Glorieta',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_KS_17590', Name: 'Madison', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IN_17591',
    Name: 'Moores Hill',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_KY_17592',
    Name: 'Cecilia',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_NE_17593', Name: 'Elgin', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_UT_17594',
    Name: 'Garden City',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_AL_17595',
    Name: 'Trafford',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_NE_17596', Name: 'Adams', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_NC_17597',
    Name: 'Garland',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MI_17598',
    Name: 'Oak Hill',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OK_17599', Name: 'Lahoma', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_MT_17600', Name: 'Muddy', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_IL_17601', Name: 'Golden', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NY_17602',
    Name: 'Smallwood',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_17603', Name: 'Riviera', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_17604', Name: 'Crescent', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_KY_17605',
    Name: 'Magnolia',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MO_17606',
    Name: 'Edgerton',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_LA_17607', Name: 'Egan', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_MN_17608',
    Name: 'Elmore',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WV_17609',
    Name: 'Idamay',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WV_17610',
    Name: 'McConnell',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_ND_17611',
    Name: 'Lidgerwood',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_KY_17612',
    Name: 'Artemus',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NC_17613',
    Name: 'Rosman',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OH_17614', Name: 'Bergholz', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_FL_17615',
    Name: 'Greensboro',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AK_17616',
    Name: 'Pleasant Valley',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_WI_17617',
    Name: 'Stockbridge',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MT_17618',
    Name: 'Havre North',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_NY_17619',
    Name: 'Redwood',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_SD_17620',
    Name: 'Menno',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_PA_17621',
    Name: 'Sankertown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_UT_17622', Name: 'Wanship', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_CA_17623',
    Name: 'Seville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_17624',
    Name: 'Hunterstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_17625',
    Name: 'Irvington',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_17626',
    Name: 'Trimble',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AR_17627',
    Name: 'Mountainburg',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_OK_17628', Name: 'Paoli', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_IA_17629', Name: 'Pomeroy', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_17630',
    Name: 'Lowry City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WA_17631',
    Name: 'Sunday Lake',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_AK_17632',
    Name: 'Shishmaref',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_TX_17633',
    Name: 'La Paloma-Lost Creek',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_OK_17634',
    Name: 'Fairfield',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_KS_17635',
    Name: 'Bennington',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_PA_17636',
    Name: 'Dry Tavern',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_17637',
    Name: 'Lawrenceville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_17638',
    Name: 'Villa Pancho',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IA_17639', Name: 'Shelby', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_TN_17640', Name: 'Gates', stateId: 'TN', stateName: 'Tennessee'},
  {Id: 'CIT_US_IL_17641', Name: 'Ava', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_IA_17642', Name: 'Keystone', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_17643',
    Name: 'Jacksonville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MD_17644',
    Name: 'Cordova',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NJ_17645',
    Name: 'Griggstown',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_17646',
    Name: 'Irvona',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_17647',
    Name: 'Isola',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_TN_17648',
    Name: 'Mooresburg',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_GA_17649',
    Name: 'Whitesburg',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_TX_17650', Name: 'Matador', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TN_17651',
    Name: 'Trimble',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_17652',
    Name: 'Yatesville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_17653',
    Name: 'Wampsville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_MI_17654', Name: 'Muir', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_CA_17655', Name: 'Alto', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_NC_17656',
    Name: 'Bunnlevel',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_17657',
    Name: 'Winfall',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_17658',
    Name: 'Reardan',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KS_17659',
    Name: 'Nortonville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_IA_17660', Name: 'Elk Horn', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IL_17661', Name: 'Lyndon', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_IL_17662',
    Name: 'Bartelso',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_17663',
    Name: 'Curlew Lake',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WA_17664',
    Name: 'Garfield',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WA_17665',
    Name: 'Skokomish',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KY_17666',
    Name: 'Sandy Hook',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_AL_17667',
    Name: 'West Point',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_OK_17668',
    Name: 'Fort Cobb',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_AL_17669', Name: 'Calvert', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_FL_17670',
    Name: 'Paradise Heights',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CA_17671',
    Name: 'Caspar',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_17672', Name: 'Winona', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AZ_17673', Name: 'Sanders', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_OH_17674', Name: 'Metamora', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_VA_17675',
    Name: 'Montvale',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WA_17676',
    Name: 'Bell Hill',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KY_17677',
    Name: 'Caneyville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_LA_17678',
    Name: 'Provencal',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PR_17679',
    Name: 'Rosa Sánchez',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_TX_17680',
    Name: 'Ingleside on the Bay',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MS_17681',
    Name: 'New Houlka',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_OH_17682',
    Name: 'Cuyahoga Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_OK_17683',
    Name: 'Glencoe',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MO_17684',
    Name: 'Hartville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MI_17685',
    Name: 'Hopkins',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WV_17686',
    Name: 'West Milford',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_AZ_17687',
    Name: 'Maricopa Colony',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_AZ_17688',
    Name: 'Stotonic Village',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_AZ_17689',
    Name: 'Upper Santan Village',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_OH_17690',
    Name: 'Mount Victory',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WV_17691',
    Name: 'Hometown',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_IL_17692', Name: 'Elsah', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_KS_17693', Name: 'Howard', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_KS_17694',
    Name: 'Maple Hill',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_CA_17695',
    Name: 'Cedarville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VA_17696',
    Name: 'Seven Mile Ford',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_MD_17697', Name: 'Oxford', stateId: 'MD', stateName: 'Maryland'},
  {Id: 'CIT_US_UT_17698', Name: 'Myton', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MO_17699',
    Name: 'Foristell',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CO_17700',
    Name: 'North Washington',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_KY_17701',
    Name: 'Chaplin',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_MT_17702', Name: 'Circle', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_IL_17703', Name: 'Ursa', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_GA_17704',
    Name: 'McIntyre',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_KY_17705',
    Name: 'Murray Hill',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CA_17706',
    Name: 'Miranda',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_17707',
    Name: 'Fallston',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_17708',
    Name: 'Cokeburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_17709',
    Name: 'McIntosh',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_OK_17710',
    Name: 'Calumet',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NY_17711',
    Name: 'Harrisville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NM_17712',
    Name: 'Los Luceros',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_WY_17713', Name: 'Byron', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_IL_17714',
    Name: 'Bedford Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CO_17715',
    Name: 'Cattle Creek',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CA_17716',
    Name: 'Catheys Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_KY_17717', Name: 'Hardin', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_MO_17718',
    Name: 'Pleasant Hope',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_GA_17719', Name: 'Pavo', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WA_17720',
    Name: 'Albion',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WV_17721',
    Name: 'Reedsville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_OH_17722', Name: 'Thurston', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_17723',
    Name: 'Friant',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IN_17724',
    Name: 'Burlington',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MD_17725',
    Name: 'Millington',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NC_17726',
    Name: 'Henrietta',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_LA_17727',
    Name: 'Ridgecrest',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_VA_17728',
    Name: 'Villa Heights',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WI_17729',
    Name: 'Plum City',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WI_17730',
    Name: 'Fairchild',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MN_17731',
    Name: 'Jasper',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_17732',
    Name: 'Independence',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_17733',
    Name: 'Poplar',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_ND_17734',
    Name: 'Elgin',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_UT_17735', Name: 'Marion', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_IL_17736',
    Name: 'St. Libory',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WI_17737',
    Name: 'Oxford',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WI_17738',
    Name: 'Chenequa',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_CO_17739', Name: 'Eads', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_WV_17740',
    Name: 'Danville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_OH_17741', Name: 'Bay View', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IA_17742', Name: 'Dumont', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MD_17743',
    Name: 'Tall Timbers',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_AR_17744',
    Name: 'Black Rock',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_TX_17745',
    Name: 'Mi Ranchito Estate',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NY_17746',
    Name: 'Forest Home',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_17747',
    Name: 'Masthope',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KY_17748', Name: 'Loyall', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_GA_17749',
    Name: 'Carnesville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_LA_17750',
    Name: 'Natchez',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MO_17751',
    Name: 'Doolittle',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MS_17752',
    Name: 'New Augusta',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WI_17753',
    Name: 'Eureka',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IA_17754', Name: 'Doon', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_ID_17755', Name: 'Juliaetta', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_NC_17756',
    Name: 'East Bend',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_17757',
    Name: 'Star Lake',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_KY_17758', Name: 'Milton', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_MO_17759', Name: 'Verona', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_OK_17760',
    Name: 'Washington',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_KS_17761', Name: 'Claflin', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MN_17762',
    Name: 'Barnum',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_FL_17763',
    Name: 'Fort White',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WI_17764',
    Name: 'Oconomowoc Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_ND_17765',
    Name: 'New England',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_OR_17766',
    Name: 'Arlington',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_NY_17767',
    Name: 'Tuxedo Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MT_17768',
    Name: 'Heart Butte',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_CA_17769',
    Name: 'Stinson Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_17770',
    Name: 'Interlaken',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_LA_17771',
    Name: 'Florien',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_IA_17772', Name: 'Ireton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IN_17773',
    Name: 'Beverly Shores',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_TX_17774', Name: 'Murchison', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KS_17775',
    Name: 'Marquette',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_PA_17776',
    Name: 'Knoxville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_17777',
    Name: 'Niederwald',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OK_17778', Name: 'Cole', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_PA_17779',
    Name: 'Quentin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_17780', Name: 'Rule', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_17781', Name: 'Volente', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_CO_17782', Name: 'Walden', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_PA_17783',
    Name: 'Big Run',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_17784',
    Name: 'Grindstone',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_17785', Name: 'Minden', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_GA_17786', Name: 'Colbert', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_IL_17787',
    Name: 'Hopkins Park',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_17788',
    Name: 'Leadington',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_17789',
    Name: 'Stanford',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_17790',
    Name: 'Bayview',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MN_17791',
    Name: 'Evansville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_17792',
    Name: 'Franklin',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WI_17793',
    Name: 'Prentice',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MI_17794',
    Name: 'Eau Claire',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IA_17795', Name: 'Hopkinton', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_KS_17796', Name: 'St. Paul', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WI_17797',
    Name: 'Hatley',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_17798',
    Name: 'Tamaroa',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_17799',
    Name: 'Patterson Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SD_17800',
    Name: 'Johnson Siding',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_SD_17801',
    Name: 'Newell',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NC_17802',
    Name: 'Varnamtown',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OH_17803', Name: 'Clarktown', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IA_17804', Name: 'Sutherland', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_17805',
    Name: 'Fairmount',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_17806',
    Name: 'Park Crest',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_17807',
    Name: 'Bath',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_AL_17808', Name: 'Babbie', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_IA_17809', Name: 'Kanawha', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NC_17810',
    Name: 'Harmony',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_ND_17811',
    Name: 'Portland',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_IL_17812',
    Name: 'Blandinsville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_17813',
    Name: 'Matthews',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_17814',
    Name: 'Cranesville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_17815',
    Name: 'Dieterich',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NE_17816',
    Name: 'Greenwood',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_KY_17817',
    Name: 'Diablock',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_TX_17818', Name: 'Nome', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_17819', Name: 'Balmorhea', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MI_17820', Name: 'Peck', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_OK_17821', Name: 'Velma', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_IN_17822', Name: 'Oaktown', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_MD_17823',
    Name: 'North Chevy Chase',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_IN_17824', Name: 'Kewanna', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_CA_17825',
    Name: 'Buena Vista',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WV_17826',
    Name: 'Hartford City',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_OH_17827', Name: 'Lansing', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MO_17828',
    Name: 'Hornersville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_17829',
    Name: 'San Jose',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_GA_17830',
    Name: 'Danielsville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_IL_17831',
    Name: 'Sherrard',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MO_17832', Name: 'Vienna', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_LA_17833',
    Name: 'Boothville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_UT_17834',
    Name: 'Fort Duchesne',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_NM_17835',
    Name: 'Rodey',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_17836',
    Name: 'Hooversville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_17837',
    Name: 'Plantersville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NY_17838',
    Name: 'Unionville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WV_17839',
    Name: 'Grant Town',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_AZ_17840',
    Name: 'Rock Point',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NY_17841',
    Name: 'Scotts Corners',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_ND_17842',
    Name: 'Rolette',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_NE_17843', Name: 'Dodge', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_OK_17844',
    Name: 'Webbers Falls',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_IL_17845', Name: 'Como', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_SC_17846',
    Name: 'Briarcliffe Acres',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MN_17847',
    Name: 'Racine',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IA_17848', Name: 'Goldfield', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NY_17849',
    Name: 'Cassadaga',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_SC_17850',
    Name: 'Campobello',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MT_17851',
    Name: 'Piltzville',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_PA_17852',
    Name: 'Heilwood',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IA_17853',
    Name: 'Grand Mound',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_TX_17854',
    Name: 'Stagecoach',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MN_17855',
    Name: 'Littlefork',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_CO_17856', Name: 'Swink', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_OH_17857', Name: 'Lucas', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_VA_17858', Name: 'Blairs', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_MT_17859', Name: 'Wibaux', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_UT_17860', Name: 'Orderville', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_WI_17861',
    Name: 'Solon Springs',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WA_17862',
    Name: 'Bucoda',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NC_17863',
    Name: 'Lawndale',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_17864',
    Name: 'Clark',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_17865', Name: 'Stonewall', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_17866',
    Name: 'Sunset Bay',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_ND_17867',
    Name: 'Minto',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_IL_17868',
    Name: 'Oak Grove',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WV_17869',
    Name: 'Bancroft',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MD_17870',
    Name: 'Fairlee',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MS_17871',
    Name: 'Mayersville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NC_17872',
    Name: 'Ossipee',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TX_17873',
    Name: 'Colmesneil',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_17874', Name: 'Encinal', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MT_17875', Name: 'Ulm', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_OR_17876', Name: 'Selma', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_SD_17877',
    Name: 'Avon',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NM_17878',
    Name: 'Madrone',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_ID_17879', Name: 'Pierce', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_LA_17880',
    Name: 'Napoleonville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_AR_17881', Name: 'Summit', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_IN_17882',
    Name: 'New Salisbury',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_WV_17883',
    Name: 'Shenandoah Junction',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_KS_17884', Name: 'Franklin', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_AR_17885', Name: 'Roland', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_PA_17886',
    Name: 'Pomeroy',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_17887',
    Name: 'Hurtsboro',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NC_17888',
    Name: 'Ansonville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MN_17889',
    Name: 'Butterfield',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MI_17890',
    Name: 'Sunfield',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IN_17891', Name: 'Hayden', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PA_17892',
    Name: 'Mohrsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_17893',
    Name: 'Green City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IN_17894',
    Name: 'Etna Green',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_TX_17895', Name: 'Golinda', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OK_17896', Name: 'Canton', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_PA_17897',
    Name: 'Worthington',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_17898',
    Name: 'Littleton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IA_17899', Name: 'Kellogg', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_17900',
    Name: 'Innsbrook',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AK_17901',
    Name: 'Happy Valley',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_AL_17902',
    Name: 'Courtland',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_OH_17903', Name: 'Arcadia', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_LA_17904',
    Name: 'Pitkin',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_KY_17905',
    Name: 'Fancy Farm',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MS_17906',
    Name: 'Boyle',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IL_17907',
    Name: 'Tower Hill',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PR_17908',
    Name: 'Pajonal',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_AL_17909',
    Name: 'Forkland',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_AZ_17910', Name: 'Nelson', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_IL_17911',
    Name: 'Willisville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_17912', Name: 'Orleans', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OH_17913', Name: 'Oakwood', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TX_17914',
    Name: 'Post Oak Bend City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NJ_17915',
    Name: 'Barnegat Light',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_MO_17916', Name: 'Kelso', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_IL_17917',
    Name: 'Shipman',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_17918',
    Name: 'Rauchtown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_17919',
    Name: 'La Hacienda',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_AK_17920',
    Name: 'Wainwright',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_PA_17921',
    Name: 'Port Matilda',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_17922',
    Name: 'Horseshoe Bend',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_LA_17923',
    Name: 'Simpson',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OK_17924', Name: 'Akins', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_SD_17925',
    Name: 'Burke',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_OR_17926', Name: 'Gardiner', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_PA_17927',
    Name: 'Revloc',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_HI_17928', Name: 'Paauilo', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_MN_17929',
    Name: 'Hill City',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AL_17930',
    Name: 'Clayhatchee',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_WI_17931', Name: 'Casco', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_TX_17932', Name: 'Meadow', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_17933', Name: 'Dexter', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_LA_17934',
    Name: 'Cheneyville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OK_17935', Name: 'Welch', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_KY_17936', Name: 'Kevil', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_PA_17937',
    Name: 'Yukon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_17938',
    Name: 'Brownsville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_NE_17939', Name: 'Stuart', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_ID_17940', Name: 'Dubois', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_NE_17941',
    Name: 'Cedar Bluffs',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_AR_17942',
    Name: 'Cherry Valley',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CO_17943',
    Name: 'St. Ann Highlands',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_IA_17944', Name: 'Clermont', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MI_17945',
    Name: 'Addison',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_KY_17946', Name: 'Butler', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_MO_17947', Name: 'Naylor', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_NC_17948',
    Name: 'Wade',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_17949',
    Name: 'Altamont',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OH_17950',
    Name: 'Kinsman Center',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_OK_17951',
    Name: 'Hulbert',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_GA_17952', Name: 'Uvalda', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CA_17953',
    Name: 'Cold Springs',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MT_17954',
    Name: 'Hot Springs',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_MD_17955', Name: 'Galena', stateId: 'MD', stateName: 'Maryland'},
  {Id: 'CIT_US_TX_17956', Name: 'Leming', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_17957',
    Name: 'Wheatland',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_17958',
    Name: 'Tribes Hill',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OH_17959',
    Name: 'Richmond Dale',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MN_17960',
    Name: 'Minnetonka Beach',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MI_17961',
    Name: 'Port Sanilac',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_17962',
    Name: 'Warrior Run',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_17963',
    Name: 'Madison',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KS_17964',
    Name: 'White City',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_MT_17965',
    Name: 'Jefferson City',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_PA_17966',
    Name: 'Beaver Springs',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_17967',
    Name: 'Keithsburg',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_17968',
    Name: 'Mingoville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AZ_17969', Name: 'Tusayan', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_PA_17970',
    Name: 'Goodville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_17971',
    Name: 'South Dayton',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OK_17972',
    Name: 'Bernice',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_CA_17973',
    Name: 'Shelter Cove',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_17974',
    Name: 'Bolton Landing',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NC_17975',
    Name: 'Iron Station',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TX_17976',
    Name: 'Surfside Beach',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AR_17977',
    Name: 'Pangburn',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_IN_17978',
    Name: 'Fairland',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IL_17979',
    Name: 'Holiday Hills',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_17980', Name: 'Ainsworth', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MI_17981',
    Name: 'Presque Isle Harbor',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_ID_17982',
    Name: 'Clark Fork',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_CO_17983',
    Name: 'Norwood',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_AR_17984',
    Name: 'Plainview',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PR_17985',
    Name: 'Esperanza',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_SD_17986',
    Name: 'Colman',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IL_17987', Name: 'Cowden', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_IN_17988',
    Name: 'Campbellsburg',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_KY_17989',
    Name: 'Petersburg',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_FL_17990',
    Name: 'Briny Breezes',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_17991',
    Name: 'Marion Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_17992', Name: 'Moulton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NY_17993',
    Name: 'Chaumont',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OR_17994', Name: 'Kerby', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_ND_17995',
    Name: 'Center',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MO_17996',
    Name: 'Eminence',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AR_17997',
    Name: 'Garfield',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_WA_17998',
    Name: 'Copalis Beach',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_AL_17999',
    Name: 'Pickensville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_UT_18000',
    Name: 'Spanish Valley',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {Id: 'CIT_US_TN_18001', Name: 'Doyle', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_AR_18002',
    Name: 'Holland',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MO_18003',
    Name: 'Glenaire',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NC_18004',
    Name: 'Hot Springs',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WI_18005',
    Name: 'Kieler',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_UT_18006', Name: 'Dugway', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_AR_18007',
    Name: 'Palestine',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_WI_18008',
    Name: 'New Auburn',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_KY_18009',
    Name: 'Bedford',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MO_18010',
    Name: 'Holcomb',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IA_18011', Name: 'Fonda', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_UT_18012', Name: 'Loa', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_LA_18013',
    Name: 'Morganza',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_IL_18014',
    Name: 'Mulberry Grove',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SD_18015',
    Name: 'Corsica',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NE_18016',
    Name: 'Beaver City',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_OH_18017', Name: 'Brookside', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_VA_18018',
    Name: 'La Crosse',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_AK_18019', Name: 'Stebbins', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_CA_18020',
    Name: 'Allensworth',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_GA_18021', Name: 'Newton', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MO_18022',
    Name: 'Fairfax',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IA_18023', Name: 'Richland', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NM_18024',
    Name: 'Acomita Lake',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NE_18025',
    Name: 'Dorchester',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_AR_18026',
    Name: 'Cotton Plant',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_SD_18027',
    Name: 'White River',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_KY_18028',
    Name: 'Wellington',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WV_18029',
    Name: 'Pineville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_18030',
    Name: 'Wyano',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IN_18031', Name: 'Kimmell', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_CA_18032',
    Name: 'Desert Shores',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AR_18033',
    Name: 'Grannis',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_VT_18034',
    Name: 'North Troy',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_PA_18035',
    Name: 'Emlenton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_18036', Name: 'Newcastle', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AR_18037',
    Name: 'Fountain Lake',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NM_18038',
    Name: 'La Villita',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_TX_18039',
    Name: 'Annetta South',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_18040',
    Name: 'Jamestown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_18041',
    Name: 'Startup',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MS_18042',
    Name: 'New Hamilton',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_18043',
    Name: 'Sugar Grove',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AK_18044', Name: 'Akiachak', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_NY_18045',
    Name: 'Stannards',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AL_18046',
    Name: 'McDonald Chapel',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_TX_18047', Name: 'Bristol', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_18048',
    Name: 'Andover',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NM_18049',
    Name: 'Mimbres',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_WA_18050',
    Name: 'Cathlamet',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_VA_18051',
    Name: 'Jarratt',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_FL_18052',
    Name: 'Hillsboro Pines',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NC_18053',
    Name: 'Hoffman',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WV_18054',
    Name: 'Rowlesburg',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_CO_18055',
    Name: 'Salt Creek',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_MD_18056', Name: 'Newark', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_PA_18057',
    Name: 'Wakefield',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_18058', Name: 'Collins', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_AZ_18059',
    Name: 'Rancho Mesa Verde',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_MD_18060',
    Name: 'Bowmans Addition',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MO_18061',
    Name: 'Smithton',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CO_18062',
    Name: 'Copper Mountain',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_SC_18063',
    Name: 'Converse',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_UT_18064',
    Name: 'Central Valley',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_TN_18065',
    Name: 'Pittman Center',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NY_18066',
    Name: 'Nelliston',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_18067', Name: 'Smiley', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IN_18068', Name: 'Dana', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_MN_18069',
    Name: 'Verndale',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_18070',
    Name: 'Sutersville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_18071',
    Name: 'South Williamson',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_AZ_18072',
    Name: 'Wellton Hills',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_VA_18073',
    Name: 'Lafayette',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_18074',
    Name: 'Rebersburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_18075',
    Name: 'Gilbertsville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_LA_18076',
    Name: 'Gillis',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WY_18077',
    Name: 'La Barge',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_GA_18078',
    Name: 'East Ellijay',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MI_18079',
    Name: 'Dansville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NY_18080',
    Name: 'East Nassau',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_18081',
    Name: 'North City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AL_18082',
    Name: 'Sand Rock',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_MT_18083', Name: 'Trego', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_VA_18084',
    Name: 'Sherando',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_VA_18085',
    Name: 'Mathews',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_FL_18086',
    Name: 'Roeville',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_NE_18087', Name: 'Arnold', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_AK_18088',
    Name: "St. Mary's",
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_FL_18089',
    Name: 'Stacey Street',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OR_18090', Name: 'Dillard', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_TX_18091',
    Name: 'Annetta North',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OH_18092', Name: 'Corning', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_18093',
    Name: 'Honeoye',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TN_18094',
    Name: 'Petersburg',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NE_18095',
    Name: 'Overton',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_SD_18096',
    Name: 'Humboldt',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_OH_18097', Name: 'Buchtel', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IN_18098',
    Name: 'Sharpsville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_OH_18099',
    Name: 'Harveysburg',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_FL_18100',
    Name: 'Ponce de Leon',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NC_18101',
    Name: 'Stem',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_18102',
    Name: 'Lewis Run',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_NE_18103', Name: 'Mead', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_AR_18104', Name: 'Taylor', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_LA_18105',
    Name: 'Wilson',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MN_18106',
    Name: 'White Earth',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_CA_18107', Name: 'Alta', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_SD_18108',
    Name: 'St. Francis',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_CA_18109',
    Name: 'River Pines',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_18110',
    Name: 'Bird-in-Hand',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_18111',
    Name: 'Freeburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_18112',
    Name: 'Newell',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_18113', Name: 'Carroll', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IL_18114', Name: 'Dayton', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_AZ_18115',
    Name: 'Queen Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_OH_18116',
    Name: 'Stoutsville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_OH_18117',
    Name: 'Meyers Lake',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MN_18118',
    Name: 'Good Thunder',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NE_18119',
    Name: 'Palmyra',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IN_18120',
    Name: 'Medaryville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NC_18121',
    Name: 'Newton Grove',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OH_18122', Name: 'Riverlea', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IN_18123',
    Name: 'Lanesville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_OH_18124',
    Name: 'Port Washington',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WI_18125',
    Name: 'Chief Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TX_18126',
    Name: 'Spring Gardens',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_KS_18127', Name: 'Chicopee', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MI_18128',
    Name: 'Vanderbilt',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NC_18129',
    Name: 'Belvoir',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_18130', Name: 'Loraine', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_18131',
    Name: 'Gresham',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_AR_18132',
    Name: 'Bonanza',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_GA_18133', Name: 'Arabi', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NE_18134',
    Name: 'Howells',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_TX_18135',
    Name: 'Grey Forest',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WA_18136',
    Name: 'Coulee City',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NV_18137',
    Name: 'Fish Springs',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_AL_18138',
    Name: 'Belle Fontaine',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_MT_18139', Name: 'Belt', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_IA_18140', Name: 'Quasqueton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IN_18141',
    Name: 'Coatesville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IL_18142',
    Name: 'Grand Tower',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_WY_18143', Name: 'Ralston', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_UT_18144', Name: 'Peter', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_TX_18145',
    Name: 'Myrtle Springs',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_FL_18146',
    Name: 'Canal Point',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IL_18147', Name: 'Sidell', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MI_18148',
    Name: 'Fostoria',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MI_18149',
    Name: 'Metamora',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PR_18150',
    Name: 'Bairoa La Veinticinco',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_WA_18151', Name: 'Lind', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_MI_18152',
    Name: 'Britton',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_GA_18153',
    Name: 'Pendergrass',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_MN_18154', Name: 'Cook', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_MO_18155',
    Name: 'Grayhawk',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NE_18156',
    Name: 'Boys Town',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NC_18157',
    Name: 'Bailey',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_18158',
    Name: 'Oakland',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_18159', Name: 'Dexter', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MT_18160',
    Name: 'Big Sandy',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_OH_18161',
    Name: 'Middle Point',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MI_18162',
    Name: 'Mulliken',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_GA_18163', Name: 'Dudley', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MD_18164',
    Name: 'Washington Grove',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_IA_18165', Name: 'Anthon', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_18166',
    Name: 'Hickory',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_18167',
    Name: 'West Baden Springs',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_TX_18168', Name: 'Paradise', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_18169',
    Name: 'West Valley',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_ND_18170',
    Name: 'Richardton',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_CA_18171',
    Name: 'Avery',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SD_18172',
    Name: 'Waubay',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_PA_18173',
    Name: 'Hostetter',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TN_18174',
    Name: 'Andersonville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_GA_18175', Name: 'Helen', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_PR_18176',
    Name: 'La Plena',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_NY_18177', Name: 'Accord', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_WI_18178',
    Name: 'Lily Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MN_18179',
    Name: 'Graceville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WA_18180',
    Name: 'St. John',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_GA_18181', Name: 'Leary', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_IN_18182',
    Name: 'Matthews',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_KY_18183',
    Name: 'Park City',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_NE_18184', Name: 'Wausa', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_GA_18185', Name: 'Brooks', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_TX_18186', Name: 'Cove', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_18187',
    Name: 'Cairnbrook',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NE_18188',
    Name: 'Culbertson',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_KY_18189',
    Name: 'Ravenna',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MO_18190',
    Name: 'Arcadia',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_KY_18191',
    Name: 'Beechmont',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_TX_18192', Name: 'Wickett', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_18193',
    Name: 'South Cle Elum',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MD_18194',
    Name: 'Corriganville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_MN_18195', Name: 'Sabin', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_ID_18196', Name: 'Melba', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_TX_18197', Name: 'Deport', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MN_18198',
    Name: 'Green Isle',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_18199',
    Name: 'Westfield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AR_18200',
    Name: 'Subiaco',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_WA_18201',
    Name: 'Rosalia',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_UT_18202', Name: 'Amalga', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_KY_18203', Name: 'Emlyn', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_IL_18204',
    Name: 'Prairie du Rocher',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_18205',
    Name: 'Lincoln Park',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MS_18206',
    Name: 'Kilmichael',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_18207',
    Name: 'Wall',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KS_18208', Name: 'Attica', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OH_18209', Name: 'Caledonia', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_SC_18210',
    Name: 'Olanta',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_IL_18211',
    Name: 'Carlock',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OK_18212', Name: 'Hammon', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_TX_18213', Name: 'Hawk Cove', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TX_18214',
    Name: 'Buffalo Gap',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OH_18215', Name: 'Rawson', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_VA_18216', Name: 'Dante', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_TN_18217',
    Name: 'Moscow',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_TX_18218',
    Name: 'San Perlita',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WI_18219',
    Name: 'Star Prairie',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_18220',
    Name: 'Crescent City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_18221',
    Name: 'Evans Mills',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MS_18222',
    Name: 'State Line',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_AL_18223', Name: 'Grimes', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NE_18224',
    Name: 'Wauneta',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MN_18225',
    Name: 'Ironton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AK_18226',
    Name: 'Big Delta',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_AR_18227',
    Name: 'Hartman',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MN_18228',
    Name: 'Ranier',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_MO_18229', Name: 'Leeton', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_IL_18230',
    Name: 'Oak Run',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WV_18231',
    Name: 'Pratt',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_18232',
    Name: 'Dushore',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ND_18233',
    Name: 'Edgeley',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_FL_18234', Name: 'Avalon', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_TN_18235',
    Name: 'Pleasant Hill',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_MT_18236', Name: 'Terry', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_KY_18237',
    Name: 'Whitesville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_18238',
    Name: 'Fawn Lake Forest',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_18239',
    Name: 'Hollyvilla',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_LA_18240',
    Name: 'Waterproof',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_AR_18241',
    Name: 'De Valls Bluff',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_OK_18242',
    Name: 'Olustee',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WI_18243',
    Name: 'Ontario',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MO_18244',
    Name: 'Bloomsdale',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_18245', Name: 'Hamler', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AR_18246',
    Name: 'Turrell',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_SD_18247',
    Name: 'Lake Preston',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_GA_18248',
    Name: 'Irwinton',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NH_18249',
    Name: 'Sanbornville',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_NJ_18250',
    Name: 'Belleplain',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_GA_18251', Name: 'Berlin', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_MN_18252', Name: 'Lyle', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_MS_18253',
    Name: 'Standing Pine',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IA_18254', Name: 'Sabula', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WY_18255',
    Name: 'Cokeville',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_MO_18256',
    Name: 'Beverly Hills',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WI_18257',
    Name: 'Wausaukee',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OH_18258', Name: 'Peninsula', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IN_18259',
    Name: 'Francisco',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PA_18260',
    Name: 'Hopeland',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ND_18261',
    Name: 'Turtle Lake',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MI_18262',
    Name: 'New Lothrop',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_LA_18263',
    Name: 'Junction City',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_FL_18264',
    Name: 'Spring Lake',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_LA_18265',
    Name: 'Martin',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MN_18266',
    Name: 'Halstad',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_KY_18267', Name: 'Martin', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_WV_18268',
    Name: 'Gauley Bridge',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_CA_18269',
    Name: 'Concow',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_LA_18270',
    Name: 'Gilbert',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_IL_18271', Name: 'Union', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_IL_18272',
    Name: 'Wilsonville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_VA_18273',
    Name: 'Quantico',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MS_18274',
    Name: 'Bogue Chitto',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_UT_18275', Name: 'Aneth', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NJ_18276',
    Name: 'Fieldsboro',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_NY_18277', Name: 'Odessa', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_OH_18278',
    Name: 'Kirkersville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IL_18279',
    Name: 'Danforth',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OR_18280', Name: 'Trail', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_PA_18281',
    Name: 'Fredericktown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_18282',
    Name: 'Leesburg',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_UT_18283', Name: 'Trenton', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NY_18284',
    Name: 'Claverack-Red Mills',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NM_18285',
    Name: 'Ribera',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IL_18286',
    Name: 'Enfield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_UT_18287', Name: 'Mayfield', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_PA_18288',
    Name: 'Fayette City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MD_18289',
    Name: 'North Brentwood',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_WI_18290', Name: 'Dale', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_WI_18291', Name: 'Cecil', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_TX_18292', Name: 'Carlsbad', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_18293',
    Name: 'Neosho',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_18294', Name: 'Liverpool', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_18295',
    Name: 'Pittsburg',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_18296',
    Name: 'Thomson',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_VA_18297',
    Name: 'Benns Church',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_FL_18298', Name: 'Reddick', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_SC_18299',
    Name: 'Hickory Grove',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NE_18300',
    Name: 'Indianola',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_LA_18301',
    Name: 'Chatham',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NJ_18302',
    Name: 'West Wildwood',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IA_18303', Name: 'Everly', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WV_18304',
    Name: 'Thomas',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_OH_18305',
    Name: 'Phillipsburg',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_TX_18306', Name: 'Groom', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MO_18307', Name: 'Higbee', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_TX_18308', Name: 'Miami', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_18309',
    Name: 'Allenport',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_18310',
    Name: 'Hickory Flat',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CA_18311',
    Name: 'Paradise Park',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_18312',
    Name: 'Schram City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_18313', Name: 'Edroy', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_GA_18314', Name: 'Morven', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_TX_18315', Name: 'Riverside', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AL_18316',
    Name: 'Valley Head',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_OH_18317',
    Name: 'Clarksville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_IA_18318', Name: 'Batavia', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NE_18319',
    Name: 'Glenwood',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_VT_18320',
    Name: 'Derby Center',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_OH_18321',
    Name: 'Jeromesville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_LA_18322',
    Name: 'Mer Rouge',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OK_18323', Name: 'Keota', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CO_18324',
    Name: 'Flagler',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NY_18325',
    Name: 'Lyons Falls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_MI_18326', Name: 'Byron', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_AK_18327', Name: 'Aniak', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_NM_18328',
    Name: 'Chili',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_IN_18329', Name: 'La Paz', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_AR_18330',
    Name: 'Bauxite',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_WY_18331',
    Name: 'Pine Haven',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_AR_18332',
    Name: 'Traskwood',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_IL_18333',
    Name: 'Murrayville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_18334',
    Name: 'Slovan',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_18335',
    Name: 'Vesper',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OK_18336', Name: 'Alex', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NM_18337',
    Name: 'Yah-ta-hey',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MO_18338',
    Name: 'Camden Point',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_18339', Name: 'Lyons', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_18340',
    Name: 'Clayton',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NY_18341',
    Name: 'Hemlock',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AL_18342', Name: 'Kinston', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_IL_18343', Name: 'Patoka', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_FL_18344',
    Name: 'Lake Mystic',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NY_18345',
    Name: 'Sinclairville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_MN_18346', Name: 'Hokah', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_MO_18347',
    Name: 'Hayti Heights',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IN_18348',
    Name: 'Collegeville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_NY_18349', Name: 'Lacona', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_PA_18350',
    Name: 'Millwood',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OH_18351',
    Name: 'Vienna Center',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NY_18352',
    Name: 'Margaretville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_18353',
    Name: 'Scotia',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OR_18354',
    Name: 'Oceanside',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_OH_18355', Name: 'Rudolph', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_18356',
    Name: 'Rote',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_18357',
    Name: 'Frenchburg',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OK_18358', Name: 'Ramona', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_TX_18359',
    Name: 'Seco Mines',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_UT_18360', Name: 'Paragonah', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_WI_18361',
    Name: 'Clayton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_18362',
    Name: 'Williamsfield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_KS_18363', Name: 'Waverly', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_MI_18364', Name: 'Ashley', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MT_18365',
    Name: 'Wheatland',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_TX_18366', Name: 'Alba', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_18367',
    Name: 'Onalaska',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_LA_18368',
    Name: 'Richmond',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_AK_18369', Name: 'Kake', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_MN_18370', Name: 'Ulen', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_MN_18371',
    Name: 'Sunfish Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_18372',
    Name: 'Pierron',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_ND_18373',
    Name: 'Mandaree',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_AR_18374',
    Name: 'Magnet Cove',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NC_18375',
    Name: 'Polkville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_ND_18376',
    Name: 'Pembina',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_KY_18377',
    Name: 'Glenview',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NC_18378',
    Name: 'Seaboard',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CO_18379',
    Name: 'Downieville-Lawson-Dumont',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_TN_18380',
    Name: 'Bell Buckle',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NE_18381',
    Name: 'Valparaiso',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IL_18382',
    Name: 'Augusta',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_18383',
    Name: 'San Lucas',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AK_18384', Name: 'Kasigluk', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_IL_18385',
    Name: 'Mettawa',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_18386',
    Name: 'San Ardo',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_18387',
    Name: 'Bridgeport',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_18388', Name: 'Alexandria', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IN_18389', Name: 'Laurel', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_SC_18390',
    Name: 'McClellanville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_IL_18391',
    Name: 'Buckley',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OH_18392',
    Name: 'Proctorville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_MO_18393', Name: 'Alba', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MO_18394',
    Name: 'Sunrise Beach',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_SC_18395',
    Name: 'Promised Land',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CA_18396',
    Name: 'Baker',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_18397',
    Name: 'Soldiers Grove',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TX_18398',
    Name: 'Thorntonville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_18399',
    Name: 'Blanchard',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_18400', Name: 'Snook', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_18401',
    Name: 'Nuremberg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_18402',
    Name: 'Madison',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OH_18403',
    Name: 'McKinley Heights',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_AL_18404',
    Name: 'Castleberry',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_OK_18405',
    Name: 'Achille',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WV_18406',
    Name: 'Union',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NE_18407',
    Name: 'Bassett',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_OH_18408',
    Name: 'Lake Seneca',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_FL_18409',
    Name: 'Pine Lakes',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NE_18410',
    Name: 'Hay Springs',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_GA_18411', Name: 'Cadwell', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_SD_18412',
    Name: 'Meadow View Addition',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_TX_18413', Name: 'Weston', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_18414', Name: 'Marne', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_18415',
    Name: 'Fort Washington',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_18416',
    Name: 'Pleasant Plains',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_MT_18417', Name: 'Pryor', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_VT_18418',
    Name: 'Saxtons River',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_NC_18419',
    Name: 'Roper',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IL_18420', Name: 'Mark', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MN_18421',
    Name: 'Stewart',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_WI_18422', Name: 'Lena', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_TX_18423', Name: 'Texline', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OK_18424',
    Name: 'Eagletown',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MD_18425',
    Name: 'Cedarville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_PA_18426',
    Name: 'Lenkerville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KS_18427', Name: 'LeRoy', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IN_18428',
    Name: 'Elizabethtown',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_TN_18429',
    Name: 'Saltillo',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_ND_18430',
    Name: 'East Dunseith',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_WV_18431',
    Name: 'Masontown',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_HI_18432',
    Name: 'Launiupoko',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_IN_18433',
    Name: 'North Salem',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CA_18434',
    Name: 'Ballico',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_18435',
    Name: 'Pennwyn',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_18436',
    Name: 'Graeagle',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_18437',
    Name: 'Glen Osborne',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_18438', Name: 'Blessing', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OK_18439',
    Name: 'Pin Oak Acres',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_OH_18440',
    Name: 'Pettisville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_SD_18441',
    Name: 'Veblen',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_OK_18442', Name: 'Verden', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NC_18443',
    Name: 'Micro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CO_18444',
    Name: 'Mountain View',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MN_18445',
    Name: 'Geneva',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_18446',
    Name: 'Garden City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_LA_18447',
    Name: 'Elizabeth',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_ID_18448',
    Name: 'New Meadows',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {Id: 'CIT_US_MO_18449', Name: 'Hardin', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_IN_18450',
    Name: 'Parkers Settlement',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_FL_18451',
    Name: 'Matlacha',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MN_18452',
    Name: 'Claremont',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_18453', Name: 'Bryson', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AR_18454',
    Name: 'Norfork',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NY_18455',
    Name: 'Wellsburg',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IA_18456', Name: 'Russell', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_GA_18457', Name: 'Hoboken', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NE_18458',
    Name: 'Callaway',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_WV_18459',
    Name: 'Ghent',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_TX_18460',
    Name: 'K-Bar Ranch',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_GA_18461', Name: 'Ailey', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_IN_18462',
    Name: 'Cynthiana',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AZ_18463',
    Name: 'Santa Rosa',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NE_18464',
    Name: 'Trenton',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_KS_18465', Name: 'Olpe', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MI_18466',
    Name: 'South Boardman',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NM_18467',
    Name: 'Cedar Grove',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_18468',
    Name: 'Juniata Terrace',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_RI_18469',
    Name: 'Quonochontaug',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {Id: 'CIT_US_OR_18470', Name: 'Tumalo', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_AL_18471',
    Name: 'River Falls',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_IL_18472',
    Name: 'Indian Creek',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_18473',
    Name: 'Arapahoe',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NM_18474',
    Name: 'Tijeras',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_AK_18475',
    Name: 'Scammon Bay',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_TN_18476',
    Name: 'Baneberry',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NJ_18477',
    Name: 'Burleigh',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_TX_18478',
    Name: 'Cape Royale',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_SC_18479',
    Name: 'Pinewood',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_AK_18480',
    Name: 'Fort Yukon',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_KS_18481', Name: 'Logan', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_OK_18482',
    Name: 'Covington',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_OH_18483',
    Name: 'North Fairfield',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OR_18484', Name: 'Marion', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_OK_18485',
    Name: 'Dill City',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_VA_18486',
    Name: 'Wintergreen',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_18487',
    Name: 'Allison',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_18488',
    Name: 'Fillmore',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NY_18489',
    Name: 'Titusville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MN_18490',
    Name: 'Foreston',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_OK_18491',
    Name: 'Shady Grove',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MN_18492',
    Name: 'Deerwood',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_18493',
    Name: 'Hawk Run',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_18494',
    Name: 'Saddle Rock Estates',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_MI_18495', Name: 'Maybee', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_OH_18496', Name: 'Homeworth', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_GA_18497', Name: 'Dearing', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_IA_18498', Name: 'Badger', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_18499', Name: 'Rolfe', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IN_18500',
    Name: 'Painted Hills',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CO_18501',
    Name: 'Naturita',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_WI_18502', Name: 'Boyd', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_LA_18503',
    Name: 'Siracusaville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_18504', Name: 'Lake City', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_18505',
    Name: 'Shoreham',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TN_18506',
    Name: 'Minor Hill',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_18507',
    Name: 'Pikes Creek',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_HI_18508', Name: 'Waikane', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_PA_18509',
    Name: 'East Rochester',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_18510',
    Name: 'Bayou Goula',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WA_18511',
    Name: 'Ashford',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_18512',
    Name: 'Noblestown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_18513',
    Name: 'Ivanhoe',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OH_18514', Name: 'Lowell', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MS_18515',
    Name: 'Bolton',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_18516',
    Name: 'Austin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_DE_18517',
    Name: 'South Bethany',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_AK_18518',
    Name: 'Nunapitchuk',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_TX_18519', Name: 'Weir', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TX_18520',
    Name: 'South La Paloma',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AZ_18521',
    Name: 'El Prado Estates',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_KS_18522', Name: 'La Harpe', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_OH_18523',
    Name: 'Rushsylvania',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MD_18524',
    Name: 'Loch Lynn Heights',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_IL_18525',
    Name: 'Bismarck',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_18526',
    Name: 'Makanda',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_KY_18527',
    Name: 'Fordsville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_18528',
    Name: 'Modena',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KS_18529',
    Name: 'Macksville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_FL_18530', Name: 'Wabasso', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_FL_18531',
    Name: 'Yankeetown',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_MI_18532', Name: 'Galien', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_VA_18533',
    Name: 'Gasburg',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_GA_18534',
    Name: 'Coolidge',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_OH_18535', Name: 'Iberia', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MS_18536',
    Name: 'Hickory',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OK_18537', Name: 'Forgan', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_WA_18538',
    Name: 'Wishram',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NJ_18539',
    Name: 'Rockleigh',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WV_18540',
    Name: 'Switzer',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_CA_18541',
    Name: 'Ballard',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_18542',
    Name: 'Plum Springs',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IN_18543',
    Name: 'Reynolds',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IN_18544',
    Name: 'Monroe City',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IA_18545', Name: 'Norway', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SC_18546',
    Name: 'Society Hill',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_WI_18547',
    Name: 'Linden',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MN_18548',
    Name: 'Browns Valley',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AR_18549',
    Name: 'Holly Grove',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_MO_18550', Name: 'Bland', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_MO_18551', Name: 'Polo', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_CA_18552',
    Name: 'Los Berros',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AR_18553', Name: 'Huttig', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_HI_18554',
    Name: 'Laupahoehoe',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_AR_18555',
    Name: 'Bradley',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NE_18556',
    Name: 'Verdigre',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IL_18557',
    Name: 'Creal Springs',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_18558',
    Name: 'West Alton',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_18559',
    Name: 'St. David',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_18560', Name: 'Tivoli', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_18561',
    Name: 'Englewood',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_18562', Name: 'Elma', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_18563',
    Name: 'Thompsonville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WI_18564',
    Name: 'Merrillan',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_ND_18565',
    Name: 'Towner',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_LA_18566',
    Name: 'Springfield',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_UT_18567',
    Name: 'Spring Lake',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_PA_18568',
    Name: 'New Alexandria',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_18569',
    Name: 'Calypso',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_18570', Name: 'Dell City', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KS_18571',
    Name: 'Winchester',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_VA_18572',
    Name: 'Boykins',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_KS_18573', Name: 'Burden', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_IA_18574', Name: 'Grandview', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MI_18575',
    Name: 'Northport',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NC_18576',
    Name: 'Gloucester',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WI_18577',
    Name: 'Dellwood',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_18578',
    Name: 'Grand Ridge',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_18579',
    Name: 'High Falls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_18580',
    Name: 'Sharon Springs',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_KS_18581',
    Name: 'Centralia',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_NY_18582',
    Name: 'Downsville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OK_18583', Name: 'Ravia', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_MI_18584',
    Name: 'Fairgrove',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_18585',
    Name: 'Pesotum',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_VA_18586',
    Name: 'Horntown',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_GA_18587',
    Name: 'Lincoln Park',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_IA_18588', Name: 'Scranton', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_GA_18589', Name: 'Waco', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_TX_18590', Name: 'Thornton', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_18591', Name: 'Radcliffe', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WA_18592',
    Name: 'Malone',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_TN_18593',
    Name: 'Elkton',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_AR_18594',
    Name: 'Junction City',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_OH_18595', Name: 'Cairo', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MO_18596',
    Name: 'Indian Point',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MI_18597',
    Name: 'Waldron',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_AZ_18598',
    Name: 'Clay Springs',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_MO_18599',
    Name: 'Dearborn',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_VA_18600',
    Name: 'Mineral',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_IL_18601', Name: 'Cullom', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_TX_18602', Name: 'Buckholts', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AZ_18603', Name: 'Solomon', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_SD_18604',
    Name: 'Lake Norden',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_WI_18605',
    Name: 'Suring',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MN_18606',
    Name: 'Floodwood',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_KS_18607',
    Name: 'Little River',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_WI_18608',
    Name: 'Bluffview',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NH_18609',
    Name: 'Center Ossipee',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {Id: 'CIT_US_TX_18610', Name: 'Devers', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OK_18611',
    Name: 'Redbird Smith',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_KS_18612',
    Name: 'Effingham',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_NE_18613', Name: 'Exeter', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_IL_18614',
    Name: 'Hutsonville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_18615',
    Name: 'Atlantic',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_KY_18616',
    Name: 'Symsonia',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IL_18617',
    Name: 'Wapella',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_AZ_18618', Name: 'Nazlini', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_NE_18619', Name: 'Homer', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_SC_18620',
    Name: 'Clifton',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MN_18621',
    Name: 'Clearbrook',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TN_18622',
    Name: 'Big Sandy',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_18623',
    Name: 'Canadohta Lake',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OK_18624',
    Name: 'Stonewall',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_AL_18625',
    Name: 'Oak Grove',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_ID_18626', Name: 'Paris', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_AL_18627',
    Name: 'Hillsboro',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_AZ_18628',
    Name: 'Peeples Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_KS_18629', Name: 'Mulberry', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_OH_18630',
    Name: 'Stony Ridge',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_OH_18631',
    Name: 'Valley City',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_ID_18632',
    Name: 'Lewisville',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_SD_18633',
    Name: 'Lake Poinsett',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_CA_18634',
    Name: 'Lake Hughes',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_18635',
    Name: 'Sheldon',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_18636', Name: 'Winfield', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_18637', Name: 'Republic', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_18638',
    Name: 'Bancroft',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MI_18639',
    Name: 'Republic',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IA_18640', Name: 'Arcadia', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_KY_18641', Name: 'Sonora', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_AZ_18642',
    Name: 'La Paz Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_MN_18643',
    Name: 'Audubon',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MI_18644',
    Name: 'Bay Port',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_NY_18645', Name: 'Roscoe', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_WI_18646',
    Name: 'Stetsonville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CO_18647',
    Name: 'Rockvale',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MN_18648',
    Name: 'Eagle Bend',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WI_18649',
    Name: 'Ettrick',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_NY_18650', Name: 'Cayuga', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_OK_18651', Name: 'Canute', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_MS_18652',
    Name: 'Ashland',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_TX_18653',
    Name: 'Pine Forest',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WV_18654',
    Name: 'Amherstdale',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_TN_18655',
    Name: 'Sunbright',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_TX_18656', Name: 'Morgan', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_NV_18657', Name: 'Osino', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_MD_18658',
    Name: 'Pomfret',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_18659',
    Name: 'Wilkerson',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_18660',
    Name: 'Fairfield',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SD_18661',
    Name: 'Dupree',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_GA_18662',
    Name: 'Stillmore',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NY_18663',
    Name: 'Fillmore',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IA_18664', Name: 'Zearing', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_LA_18665', Name: 'Union', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_TX_18666',
    Name: 'Deweyville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CO_18667',
    Name: 'De Beque',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_OR_18668',
    Name: 'Labish Village',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_WV_18669',
    Name: 'Hepzibah',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IL_18670',
    Name: 'Equality',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MA_18671',
    Name: 'Seabrook',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_PA_18672',
    Name: 'Reinerton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KS_18673', Name: 'Fowler', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_KS_18674', Name: 'Bentley', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_AK_18675',
    Name: 'Fox River',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_AR_18676', Name: 'Avoca', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_WY_18677',
    Name: 'Big Piney',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_MN_18678',
    Name: 'Hamburg',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MI_18679',
    Name: 'Hardwood Acres',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_AZ_18680',
    Name: 'Cedar Creek',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_CA_18681',
    Name: 'Lockwood',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WA_18682',
    Name: 'George',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NY_18683',
    Name: 'Tannersville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_ID_18684', Name: 'Deary', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_IN_18685', Name: 'Hudson', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_IL_18686',
    Name: 'Chadwick',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_18687',
    Name: 'Yettem',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_CO_18688', Name: 'Walsh', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_NY_18689',
    Name: 'Caroga Lake',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_18690', Name: 'Oakwood', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_18691', Name: 'Early', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_18692',
    Name: 'Jamesport',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IN_18693',
    Name: 'La Crosse',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AZ_18694',
    Name: 'Stanfield',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NE_18695',
    Name: 'Fairmont',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_IA_18696', Name: 'Wyoming', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_TX_18697', Name: 'Easton', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AZ_18698', Name: 'Shonto', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_TX_18699', Name: 'Placedo', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_UT_18700', Name: 'Tropic', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_IN_18701', Name: 'Advance', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_OK_18702',
    Name: 'Rocky Mountain',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_GA_18703', Name: 'Lyerly', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_GA_18704',
    Name: 'Williamson',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_WA_18705',
    Name: 'Wilkeson',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_OK_18706',
    Name: 'Thackerville',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WI_18707',
    Name: 'Mole Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IL_18708', Name: 'Rankin', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_PA_18709',
    Name: 'Portland',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_18710',
    Name: 'Grayson',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_18711', Name: 'Sarita', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WV_18712',
    Name: 'Grantsville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MO_18713',
    Name: 'Shelbyville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OR_18714', Name: 'Gates', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MN_18715',
    Name: 'Pennock',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IN_18716',
    Name: 'Markleville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_CO_18717', Name: 'Altona', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_IL_18718',
    Name: 'Trout Valley',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_18719',
    Name: 'Felton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_18720',
    Name: 'Lewistown',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OH_18721',
    Name: 'Russellville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WA_18722',
    Name: 'East Cathlamet',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_WI_18723', Name: 'Adell', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_KY_18724',
    Name: 'Bancroft',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NJ_18725',
    Name: 'Stockton',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_KY_18726', Name: 'Utica', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_AL_18727',
    Name: 'Newville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_PA_18728',
    Name: 'Monroe',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OR_18729',
    Name: 'Mount Vernon',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_AL_18730',
    Name: 'Franklin',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_VA_18731',
    Name: 'Charlotte Court House',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IL_18732',
    Name: 'Apple Canyon Lake',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OK_18733',
    Name: 'Carlisle',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WI_18734',
    Name: 'Polonia',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WI_18735',
    Name: 'Gays Mills',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_SD_18736',
    Name: 'Jefferson',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MS_18737',
    Name: 'Courtland',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_18738',
    Name: 'Smock',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_18739',
    Name: 'Seven Valleys',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MD_18740',
    Name: 'Kent Narrows',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_OH_18741',
    Name: 'New Bloomington',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_IN_18742', Name: 'Milroy', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_KS_18743', Name: 'Milford', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NM_18744',
    Name: 'Cañada de los Alamos',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_UT_18745', Name: 'Big Water', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_AK_18746', Name: 'Kipnuk', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_NC_18747',
    Name: 'Coinjock',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_AL_18748', Name: 'Elkmont', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_KS_18749', Name: 'Moran', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_AR_18750',
    Name: 'Bergman',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_IN_18751',
    Name: 'Staunton',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NC_18752',
    Name: 'Aurora',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OH_18753',
    Name: 'Highland Holiday',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NY_18754',
    Name: 'Hopewell Junction',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AL_18755', Name: 'Avon', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_PA_18756',
    Name: 'Dayton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AZ_18757', Name: 'Gadsden', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_CO_18758',
    Name: 'Mulford',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MI_18759',
    Name: 'Horton Bay',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MN_18760',
    Name: 'Willernie',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_MO_18761', Name: 'Eolia', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_PA_18762',
    Name: 'Laurel Run',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OK_18763',
    Name: 'Ringwood',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_AK_18764',
    Name: 'New Stuyahok',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_NY_18765',
    Name: 'Canaseraga',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_18766',
    Name: 'Round Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_18767', Name: 'Lovilia', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_18768',
    Name: 'Parkway',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IN_18769',
    Name: 'Cromwell',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_UT_18770', Name: 'Randolph', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_PA_18771',
    Name: 'Franklintown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KS_18772',
    Name: 'Cedar Vale',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_OK_18773',
    Name: 'Winchester',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_TN_18774',
    Name: 'Berry Hill',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_18775',
    Name: 'Hydetown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_WA_18776', Name: 'Mesa', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_MO_18777',
    Name: 'New Melle',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TX_18778',
    Name: 'San Leanna',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_18779',
    Name: 'Reynolds',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MN_18780', Name: 'Amboy', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_IA_18781', Name: 'Allerton', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_18782', Name: 'Otho', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OH_18783', Name: 'Rock Creek', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CT_18784',
    Name: 'Groton Long Point',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_IA_18785', Name: 'Swea City', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_TX_18786', Name: 'Briaroaks', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MT_18787',
    Name: 'The Silos',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_NC_18788',
    Name: 'Pink Hill',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MI_18789',
    Name: 'Eastlake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WI_18790',
    Name: 'St. Joseph',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_18791',
    Name: 'Newell',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_18792',
    Name: 'Rock Springs',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MI_18793',
    Name: 'Benzonia',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NE_18794',
    Name: 'Sargent',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_WY_18795',
    Name: 'Bear River',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_CO_18796',
    Name: 'Grand Lake',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_MO_18797', Name: 'Center', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_TX_18798',
    Name: 'Ranchitos East',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_18799',
    Name: 'Green Lane',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_18800',
    Name: 'Bonnetsville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_KS_18801', Name: 'Clifton', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_LA_18802',
    Name: 'Cankton',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_IL_18803',
    Name: 'Hillsdale',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_18804',
    Name: 'Summerville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_18805',
    Name: 'Brownsville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_SC_18806',
    Name: 'Cross Hill',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CO_18807',
    Name: 'Saguache',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PA_18808',
    Name: 'Cross Roads',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OH_18809',
    Name: 'Christiansburg',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_KS_18810', Name: 'Eskridge', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_AR_18811', Name: 'Strong', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_IA_18812', Name: 'Dows', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AL_18813',
    Name: 'Lockhart',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_IL_18814',
    Name: 'Mound City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_ND_18815',
    Name: 'Forman',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_LA_18816',
    Name: 'Sorrel',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_KY_18817',
    Name: 'Houston Acres',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IA_18818', Name: 'Runnells', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_18819',
    Name: 'South Philipsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_18820',
    Name: 'Metompkin',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IL_18821',
    Name: 'Liberty',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_18822',
    Name: 'Ryderwood',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_OH_18823',
    Name: 'Laurelville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WI_18824',
    Name: 'Northport',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CO_18825',
    Name: 'Granada',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_WI_18826',
    Name: 'Minong',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_GA_18827', Name: 'Odum', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_KY_18828',
    Name: 'Wheelwright',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NM_18829',
    Name: 'La Plata',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MN_18830',
    Name: 'Sacred Heart',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_18831',
    Name: 'Dahlgren',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_18832', Name: 'Leary', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OK_18833',
    Name: 'McCurtain',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_MO_18834', Name: 'Holt', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_TX_18835', Name: 'Jayton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AR_18836',
    Name: 'Central City',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_OH_18837',
    Name: 'Hamersville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_FL_18838', Name: 'Hampton', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_PA_18839',
    Name: 'Parryville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_18840',
    Name: 'Robards',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MN_18841',
    Name: 'Bethel',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_VA_18842',
    Name: 'Drakes Branch',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_KS_18843', Name: 'Goessel', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_KS_18844', Name: 'Gas', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_ID_18845', Name: 'Mackay', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_TX_18846', Name: 'Solis', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_18847',
    Name: 'Lemon Grove',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IL_18848', Name: 'Buda', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_IL_18849',
    Name: 'Dalton City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AZ_18850',
    Name: 'Red Mesa',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_SD_18851',
    Name: 'Wilmot',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_OK_18852', Name: 'Tryon', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_AL_18853',
    Name: 'Garden City',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MS_18854',
    Name: 'Oakland',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MO_18855',
    Name: 'Wayland',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IN_18856',
    Name: 'Hillsboro',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_MI_18857', Name: 'Camden', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_KY_18858', Name: 'Cawood', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_WI_18859', Name: 'Waldo', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_IL_18860',
    Name: 'Olive Branch',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_18861', Name: 'Van Buren', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_GA_18862',
    Name: 'Byromville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PA_18863',
    Name: 'Mattawana',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_18864',
    Name: 'Freeport',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_AR_18865', Name: 'Leola', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_LA_18866',
    Name: 'Triumph',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_VA_18867', Name: 'Onley', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_MD_18868',
    Name: 'Secretary',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MN_18869',
    Name: 'Gem Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WI_18870',
    Name: 'Wilton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_AR_18871', Name: 'Joiner', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_NC_18872',
    Name: 'Gibson',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TN_18873',
    Name: 'Calhoun',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NM_18874',
    Name: 'Sanostee',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_18875',
    Name: 'Byrnedale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AR_18876', Name: 'Elaine', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_MI_18877',
    Name: 'Sterling',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_AL_18878', Name: 'Eva', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_OH_18879',
    Name: 'Spring Valley',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MD_18880',
    Name: 'Darlington',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_SD_18881',
    Name: 'Timber Lake',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_CA_18882',
    Name: 'Rough and Ready',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_18883', Name: 'New Albin', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IL_18884', Name: 'Farina', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_PA_18885',
    Name: 'Stockdale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_ID_18886', Name: 'Firth', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_VA_18887',
    Name: 'Hot Springs',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_18888',
    Name: 'Richfield',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_18889', Name: 'Burt', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IN_18890',
    Name: 'Plainville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_KY_18891',
    Name: 'Drakesboro',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_CA_18892', Name: 'Bend', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_TN_18893',
    Name: 'Wrigley',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_CA_18894',
    Name: 'Cowan',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_18895',
    Name: 'Strattanville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_18896',
    Name: 'Smith River',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_18897',
    Name: 'Laddonia',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NE_18898',
    Name: 'De Witt',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MS_18899',
    Name: 'Myrtle',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MN_18900',
    Name: 'Prinsburg',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_FL_18901', Name: 'Bell', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_18902',
    Name: 'Potter Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_18903',
    Name: 'Clinton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_18904',
    Name: 'Bancroft',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WV_18905',
    Name: 'Delbarton',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WA_18906',
    Name: 'Clallam Bay',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_OK_18907',
    Name: 'Bokoshe',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_IL_18908', Name: 'Altona', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_TX_18909', Name: 'Talco', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_18910', Name: 'Dow City', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OK_18911',
    Name: 'New Eucha',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WV_18912',
    Name: 'Valley Bend',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_AL_18913', Name: 'Millry', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_TX_18914', Name: 'La Puerta', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_GA_18915', Name: 'Tignall', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_TX_18916', Name: 'Hartley', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_ID_18917', Name: 'Richfield', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_OK_18918',
    Name: 'Schulter',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_SC_18919',
    Name: 'Brunson',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_IL_18920',
    Name: 'Sun River Terrace',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_GA_18921', Name: 'Yonah', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_FL_18922',
    Name: 'McIntosh',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_OK_18923',
    Name: 'Billings',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_CA_18924',
    Name: 'Hamilton Branch',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_18925',
    Name: 'Green Ridge',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AL_18926',
    Name: 'Hammondville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MI_18927',
    Name: 'Carsonville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_UT_18928',
    Name: 'Rush Valley',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_WI_18929',
    Name: 'Park Ridge',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_VA_18930',
    Name: 'Lovingston',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_ID_18931', Name: 'Dayton', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_KY_18932',
    Name: 'Crestview',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_ID_18933',
    Name: 'Georgetown',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {Id: 'CIT_US_OH_18934', Name: 'Coolville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_18935',
    Name: 'Bay City',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_18936',
    Name: 'Hoyleton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MD_18937',
    Name: 'St. Leonard',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OH_18938', Name: 'Laura', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AZ_18939', Name: 'Brenda', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_IL_18940',
    Name: 'Chandlerville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_18941',
    Name: 'West Elizabeth',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_18942',
    Name: 'Alma Center',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MN_18943',
    Name: 'Lafayette',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IN_18944',
    Name: 'Chalmers',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_TN_18945',
    Name: 'Fairgarden',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WI_18946',
    Name: 'Elmwood Park',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MI_18947', Name: 'Morley', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_TX_18948',
    Name: 'Lake Colorado City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AK_18949',
    Name: 'South Van Horn',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_IL_18950', Name: 'Golf', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_AL_18951',
    Name: 'Rock Mills',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_IA_18952', Name: 'Garwin', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_AL_18953', Name: 'Lisman', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_WV_18954',
    Name: 'Paw Paw',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_OH_18955',
    Name: 'Westminster',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OR_18956', Name: 'Brooks', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_AR_18957', Name: 'Wilmot', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_PA_18958',
    Name: 'Reedsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_18959',
    Name: 'Congerville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AL_18960',
    Name: 'McKenzie',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_FL_18961',
    Name: 'Sopchoppy',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_FL_18962', Name: 'Altha', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_OH_18963', Name: 'Darrtown', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OK_18964', Name: 'Zeb', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_IA_18965',
    Name: 'New Virginia',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_PA_18966',
    Name: 'Coalport',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_18967',
    Name: 'Bruin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_18968',
    Name: 'Sandy Level',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NJ_18969',
    Name: 'Corbin City',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IA_18970', Name: 'Truro', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MN_18971',
    Name: 'Carlos',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_FL_18972',
    Name: 'Page Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MO_18973',
    Name: 'Summersville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IA_18974',
    Name: 'Albion',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_PA_18975',
    Name: 'Alsace Manor',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_NE_18976', Name: 'Paxton', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_NY_18977',
    Name: 'Red Creek',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_18978',
    Name: 'Refton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_18979',
    Name: 'Buckatunna',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NY_18980',
    Name: 'Freeville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IL_18981', Name: 'Ashley', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_AK_18982', Name: 'Kachemak', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_TX_18983', Name: 'Wilson', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_18984',
    Name: 'Four Lakes',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_18985',
    Name: 'Lavelle',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TN_18986',
    Name: 'Cumberland Gap',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_OH_18987', Name: 'Eldorado', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_SD_18988',
    Name: 'Presho',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_VT_18989',
    Name: 'East Middlebury',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {Id: 'CIT_US_ID_18990', Name: 'Craigmont', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_IL_18991',
    Name: 'Damiansville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_LA_18992', Name: 'Sun', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_SD_18993',
    Name: 'White',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_VA_18994',
    Name: 'McKenney',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_IN_18995', Name: 'Lizton', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_OK_18996',
    Name: 'Fort Towson',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_UT_18997',
    Name: 'Circleville',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_MS_18998',
    Name: 'Falkner',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MI_18999',
    Name: 'Manistee Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IA_19000', Name: 'Ellsworth', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_GA_19001',
    Name: 'Pineview',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_TX_19002', Name: 'Knollwood', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SD_19003',
    Name: 'Blucksberg Mountain',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_ID_19004',
    Name: 'Garden Valley',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_AR_19005',
    Name: 'Rose Bud',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_IA_19006', Name: 'Maynard', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_AR_19007', Name: 'Jasper', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_IA_19008',
    Name: 'New Hartford',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_KY_19009',
    Name: 'Mayking',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MS_19010',
    Name: 'Jumpertown',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_OR_19011',
    Name: 'Pine Hollow',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_TX_19012', Name: 'Lipan', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OH_19013',
    Name: 'Fort Jennings',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_HI_19014', Name: 'Honomu', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_MT_19015',
    Name: 'Ponderosa Pines',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_TX_19016',
    Name: 'Green Valley Farms',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_19017',
    Name: 'Grapeville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_19018',
    Name: 'Mappsville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NJ_19019',
    Name: 'Shiloh',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_KY_19020',
    Name: 'Fox Chase',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NY_19021',
    Name: 'Middleville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_KS_19022',
    Name: 'Miltonvale',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_IL_19023',
    Name: 'Lenzburg',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_19024',
    Name: 'Aaronsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_19025',
    Name: 'Kelayres',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_19026', Name: 'McGuffey', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_UT_19027', Name: 'Charleston', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_IA_19028', Name: 'Beacon', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NM_19029',
    Name: 'Cochiti Lake',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MI_19030',
    Name: 'New Troy',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WI_19031',
    Name: 'Rosholt',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_OH_19032',
    Name: 'Mount Blanchard',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MN_19033',
    Name: 'Woodland',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TN_19034',
    Name: 'Ethridge',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_GA_19035', Name: 'Shiloh', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_MT_19036', Name: 'Valier', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_OH_19037',
    Name: 'Quaker City',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_TX_19038',
    Name: 'Western Lake',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_CO_19039', Name: 'Otis', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_LA_19040',
    Name: 'Center Point',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MO_19041',
    Name: 'Crystal Lake Park',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_GA_19042',
    Name: 'Toomsboro',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_WA_19043',
    Name: 'Rockford',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OH_19044', Name: 'Brecon', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_19045', Name: 'Gordon', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_19046',
    Name: 'Smith Corner',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_19047',
    Name: 'Bonneau',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_MN_19048', Name: 'Tower', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_WV_19049',
    Name: 'Junior',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_TX_19050',
    Name: 'Webberville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_19051',
    Name: 'Woodson',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_19052',
    Name: 'Brandon',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AR_19053', Name: 'Wilmar', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_NE_19054', Name: 'Murray', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_VA_19055',
    Name: 'Accomac',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MO_19056',
    Name: 'Freeman',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_UT_19057', Name: 'Kanosh', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_GA_19058',
    Name: 'Bowersville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_VA_19059',
    Name: 'New Kent',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_AR_19060',
    Name: 'College Station',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_IA_19061', Name: 'Humeston', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_TX_19062', Name: 'Rose City', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_LA_19063',
    Name: 'Clarence',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_MI_19064', Name: 'Pewamo', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_IA_19065', Name: 'Gilman', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MO_19066', Name: 'Urich', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_WI_19067',
    Name: 'Lyndon Station',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MI_19068', Name: 'Haring', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_OH_19069',
    Name: 'West Farmington',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_SC_19070',
    Name: 'Springfield',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_TX_19071',
    Name: 'Rancho Banquete',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WI_19072',
    Name: 'New Odanah',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IN_19073',
    Name: 'Owensburg',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OH_19074', Name: 'Fletcher', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_19075',
    Name: 'Lilesville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OH_19076', Name: 'Stockport', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_19077',
    Name: 'Hayesville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_19078',
    Name: 'Hoffman',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_19079',
    Name: 'Lake Meredith Estates',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IA_19080',
    Name: 'Oxford Junction',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_IA_19081', Name: 'Delmar', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MN_19082',
    Name: 'Pillager',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MO_19083',
    Name: 'Fair Play',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WY_19084',
    Name: 'Big Horn',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_KY_19085',
    Name: 'Pleasant View',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NJ_19086',
    Name: 'Allenhurst',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NC_19087',
    Name: 'Lewiston Woodville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_SC_19088',
    Name: 'Ehrhardt',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_KY_19089',
    Name: 'Melbourne',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IL_19090', Name: 'Dawson', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_WV_19091',
    Name: 'Pine Grove',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MN_19092',
    Name: 'Bertha',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_UT_19093', Name: 'Fielding', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_IN_19094', Name: 'Newport', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NC_19095',
    Name: 'Deercroft',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WV_19096',
    Name: 'Clearview',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_AZ_19097', Name: 'Young', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_NC_19098',
    Name: 'Simpson',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CO_19099',
    Name: 'Tabernash',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_MI_19100', Name: 'Lennon', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_AK_19101', Name: 'St. Paul', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_MN_19102',
    Name: 'Kandiyohi',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_LA_19103',
    Name: 'Monterey',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OK_19104', Name: 'Arnett', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CO_19105',
    Name: 'Fort Garland',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PA_19106',
    Name: 'Bobtown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_19107',
    Name: 'Scotch Meadows',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_ND_19108',
    Name: 'Berthold',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_OR_19109', Name: 'Lacomb', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NC_19110',
    Name: 'Old Hundred',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MO_19111',
    Name: 'Burlington Junction',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_WY_19112', Name: 'Auburn', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_IL_19113',
    Name: 'Kaneville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IL_19114', Name: 'Ohio', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CO_19115',
    Name: 'Aguilar',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_IL_19116',
    Name: 'Lostant',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_19117',
    Name: 'Buffalo',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MI_19118',
    Name: 'Fife Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OH_19119', Name: 'Portage', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OK_19120', Name: 'Amber', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_AL_19121',
    Name: 'Glen Allen',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_TX_19122', Name: 'Lefors', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OH_19123',
    Name: 'Jacksonville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_TX_19124',
    Name: 'Bear Creek',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_TX_19125',
    Name: 'Los Ebanos',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OH_19126', Name: 'Collins', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_19127', Name: 'Pettus', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_19128',
    Name: 'Arnot',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AR_19129',
    Name: 'Humphrey',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_19130',
    Name: 'Orangeville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_19131',
    Name: 'Pollock',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OK_19132', Name: 'Depew', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_IL_19133',
    Name: 'Hammond',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_VA_19134',
    Name: 'Fairview Beach',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MD_19135',
    Name: 'Friendsville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WI_19136',
    Name: 'Lake Ivanhoe',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_19137',
    Name: 'West Alexander',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_19138',
    Name: 'Lyons',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_19139',
    Name: 'Old Forge',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AR_19140',
    Name: 'Bellefonte',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_OK_19141',
    Name: 'Red Oak',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_AK_19142',
    Name: 'Cooper Landing',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_WV_19143',
    Name: 'Omar',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_VA_19144',
    Name: 'Nassawadox',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IA_19145',
    Name: 'Charter Oak',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_PA_19146',
    Name: 'Loganton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IL_19147', Name: 'Hecker', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_19148', Name: 'Verona', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AL_19149',
    Name: 'Red Level',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_CA_19150', Name: 'Onyx', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_PA_19151',
    Name: 'Falmouth',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AZ_19152',
    Name: 'Icehouse Canyon',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_TX_19153', Name: 'Smyer', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_19154',
    Name: 'Webb',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IL_19155', Name: 'Chapin', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_TX_19156',
    Name: 'Star Harbor',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WV_19157',
    Name: 'Burnsville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_AR_19158',
    Name: 'Gateway',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_WI_19159',
    Name: 'Melrose',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_19160',
    Name: 'Allendale',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_NY_19161', Name: 'Bliss', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_PA_19162',
    Name: 'Mather',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_19163',
    Name: 'Delleker',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_UT_19164', Name: 'Glenwood', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_IA_19165', Name: 'Breda', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_19166', Name: 'Whittemore', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WI_19167',
    Name: 'Withee',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_AL_19168',
    Name: 'Nances Creek',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MO_19169',
    Name: 'Josephville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NC_19170',
    Name: 'Bennett',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MN_19171',
    Name: 'Altura',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MS_19172',
    Name: 'Sledge',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IL_19173', Name: 'Tovey', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_SC_19174',
    Name: 'Summit',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MO_19175',
    Name: 'Hopkins',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MD_19176',
    Name: 'Pleasant Grove',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OR_19177', Name: 'Prospect', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MN_19178',
    Name: 'Erskine',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_19179',
    Name: 'Morning Glory',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OK_19180', Name: 'Dover', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_IN_19181',
    Name: 'Bruceville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OH_19182', Name: 'Neapolis', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_19183',
    Name: 'Dyckesville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OK_19184', Name: 'Cement', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_MS_19185',
    Name: 'Enterprise',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WI_19186',
    Name: 'Taylor',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MT_19187',
    Name: 'White Haven',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MO_19188',
    Name: 'Butterfield',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_19189',
    Name: 'Baumstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OK_19190',
    Name: 'Wauhillau',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_IA_19191',
    Name: 'Lime Springs',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_TX_19192',
    Name: 'Brazos Country',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MO_19193',
    Name: 'Neelyville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_19194',
    Name: 'Tallula',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SC_19195',
    Name: 'Fair Play',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MD_19196',
    Name: 'Sudlersville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_19197', Name: 'Scotland', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NM_19198',
    Name: 'San Fidel',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_OH_19199', Name: 'Coalton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_19200',
    Name: 'Lyman',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_19201',
    Name: 'South Wayne',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OH_19202', Name: 'Corwin', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IN_19203', Name: 'St. Joe', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_CA_19204',
    Name: 'Shaver Lake',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_GA_19205',
    Name: 'Crawfordville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_PA_19206',
    Name: 'Rouseville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_19207',
    Name: 'East Carondelet',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_19208',
    Name: 'Winthrop',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OH_19209', Name: 'Amsterdam', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_WA_19210', Name: 'Ione', stateId: 'WA', stateName: 'Washington'},
  {Id: 'CIT_US_IL_19211', Name: 'Tamms', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OK_19212', Name: 'Corn', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NY_19213',
    Name: 'Coopers Plains',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_19214',
    Name: 'Hewlett Neck',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_19215',
    Name: 'Oak Grove Village',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IN_19216',
    Name: 'Chrisney',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NE_19217',
    Name: 'Cortland',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_KY_19218', Name: 'Kenvir', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_PA_19219',
    Name: 'Millsboro',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_19220',
    Name: 'Kendall',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_KS_19221', Name: 'Argonia', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MI_19222',
    Name: 'Unionville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_19223',
    Name: 'Marianna',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_19224',
    Name: 'Mackinac Island',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_19225',
    Name: 'Fairview',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_19226',
    Name: 'Timbercreek Canyon',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MN_19227',
    Name: 'Rothsay',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AK_19228',
    Name: 'Chefornak',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_WI_19229',
    Name: 'Bayfield',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_19230',
    Name: 'Glen Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_SD_19231',
    Name: 'Oacoma',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NJ_19232',
    Name: 'East Millstone',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NJ_19233',
    Name: 'Mountain Lake',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_TX_19234', Name: 'Bedias', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_KS_19235', Name: 'Thayer', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_AR_19236', Name: 'McNeil', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_MT_19237',
    Name: 'St. Marie',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_GA_19238',
    Name: 'Oak Park',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_IL_19239',
    Name: 'Naplate',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_ID_19240', Name: 'Weston', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_TN_19241',
    Name: 'Hornbeak',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_IN_19242',
    Name: 'Wheatland',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OK_19243', Name: 'Prue', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_MD_19244', Name: 'Chance', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_SD_19245',
    Name: 'Wounded Knee',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IA_19246', Name: 'Latimer', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IA_19247',
    Name: 'University Park',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_IA_19248', Name: 'Thompson', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MD_19249',
    Name: 'Rawlings',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_PA_19250',
    Name: 'Shartlesville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_19251',
    Name: 'Franklin',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IA_19252',
    Name: 'Gilmore City',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_MN_19253',
    Name: 'Jenkins',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IA_19254', Name: 'Delhi', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MS_19255',
    Name: 'Foxworth',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IL_19256',
    Name: 'Sorento',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WV_19257',
    Name: 'Beech Bottom',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_ID_19258',
    Name: 'Grand View',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_WI_19259',
    Name: 'Mercer',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_19260',
    Name: 'East Kingston',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_SD_19261',
    Name: 'Warner',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_TX_19262', Name: 'Hallsburg', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SD_19263',
    Name: 'Bowdle',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_WV_19264',
    Name: 'Cowen',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_IL_19265', Name: 'Dakota', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_ND_19266',
    Name: 'Argusville',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_UT_19267', Name: 'Palmyra', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_TX_19268',
    Name: 'South Toledo Bend',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_19269', Name: 'Gustine', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OH_19270',
    Name: 'North Hampton',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_AK_19271', Name: 'Naknek', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_AK_19272',
    Name: 'Thorne Bay',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_TN_19273',
    Name: 'Ducktown',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_NE_19274', Name: 'Palmer', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_IN_19275', Name: 'Denver', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_TX_19276', Name: 'Blum', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_ID_19277',
    Name: 'Idaho City',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_MS_19278',
    Name: 'Potts Camp',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MI_19279',
    Name: 'Harrisville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MT_19280',
    Name: 'Spokane Creek',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_AR_19281',
    Name: 'Sulphur Rock',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_AR_19282',
    Name: 'Maysville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_WY_19283',
    Name: 'Wamsutter',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_NC_19284',
    Name: 'East Arcadia',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MN_19285',
    Name: 'Brewster',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IA_19286', Name: 'Collins', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_19287',
    Name: 'Mammoth',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_19288',
    Name: 'Roxie',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IL_19289',
    Name: 'Plymouth',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_19290',
    Name: 'Millingport',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_MN_19291', Name: 'Lynd', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_IL_19292',
    Name: 'Junction City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_19293',
    Name: 'Manalapan',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NY_19294',
    Name: 'North Rose',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_19295',
    Name: 'Clintonville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AZ_19296', Name: 'Pinal', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_KY_19297',
    Name: 'Riverwood',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_TX_19298', Name: 'Kosse', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AL_19299', Name: 'Midway', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NE_19300',
    Name: 'Bancroft',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_AL_19301', Name: 'Bellamy', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NC_19302',
    Name: 'Morven',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WI_19303',
    Name: 'St. Cloud',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MO_19304',
    Name: 'Wilbur Park',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_19305',
    Name: 'Forestville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OR_19306', Name: 'Takilma', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_AR_19307', Name: 'Hector', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_WI_19308',
    Name: 'Endeavor',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_19309',
    Name: 'Penn',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_19310',
    Name: 'Great Cacapon',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_TX_19311', Name: 'Oglesby', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_19312',
    Name: 'Weedville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_19313',
    Name: 'Sicily Island',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_19314',
    Name: 'Beallsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_19315',
    Name: 'West Point',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_VA_19316', Name: 'Haysi', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_OH_19317', Name: 'Waterford', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_WI_19318', Name: 'Knapp', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_MT_19319',
    Name: 'Fromberg',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_NY_19320',
    Name: 'North Lynbrook',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_19321',
    Name: 'Hermitage',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TN_19322',
    Name: 'Gadsden',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_CA_19323',
    Name: 'Coleville',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_WA_19324', Name: 'Lyle', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_WI_19325',
    Name: 'Glenbeulah',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_CO_19326', Name: 'Nunn', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_MS_19327',
    Name: 'Sidon',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IL_19328', Name: 'Iuka', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_WV_19329',
    Name: 'Lost Creek',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NY_19330',
    Name: 'Fort Johnson',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_GA_19331', Name: 'Whigham', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_LA_19332',
    Name: 'Turkey Creek',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OR_19333', Name: 'Mosier', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_PA_19334',
    Name: 'West Nanticoke',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_19335',
    Name: 'Petersburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_CO_19336', Name: 'Yampa', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_PA_19337',
    Name: 'Allensville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_19338',
    Name: 'Pathfork',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_FL_19339', Name: 'Alford', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NE_19340',
    Name: 'Decatur',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NY_19341',
    Name: 'Munnsville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IN_19342',
    Name: 'Rolling Prairie',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NM_19343',
    Name: 'Red River',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_NE_19344', Name: 'Mullen', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_IA_19345', Name: 'Stacyville', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_TX_19346', Name: 'Dean', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_19347',
    Name: 'Greenville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_SD_19348',
    Name: 'Allen',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MS_19349',
    Name: 'Tremont',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IA_19350', Name: 'Klemme', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AL_19351',
    Name: 'Fairview',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_IN_19352',
    Name: 'St. Bernice',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_KS_19353',
    Name: 'Protection',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_WV_19354',
    Name: 'Big Chimney',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MN_19355',
    Name: 'Naytahwaush',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_19356',
    Name: 'Spring Bay',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MO_19357', Name: 'Arbyrd', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_AL_19358',
    Name: 'Louisville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_AR_19359', Name: 'Tull', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_IL_19360', Name: 'Irving', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_TN_19361',
    Name: 'Townsend',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_FL_19362',
    Name: 'Ferndale',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NV_19363',
    Name: 'Crescent Valley',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {Id: 'CIT_US_WY_19364', Name: 'Lucerne', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_NV_19365', Name: 'Eureka', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_PA_19366',
    Name: 'Seward',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_19367',
    Name: 'Kiester',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WV_19368',
    Name: 'Robinette',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IL_19369',
    Name: 'Towanda',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_19370',
    Name: 'Eighty Four',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MT_19371', Name: 'Broadus', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_AK_19372', Name: 'Marshall', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_AR_19373', Name: 'Emmet', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_TX_19374', Name: 'Millsap', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SD_19375',
    Name: 'Bridgewater',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_GA_19376', Name: 'Ephesus', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WI_19377',
    Name: 'Abrams',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IN_19378', Name: 'Kingman', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_AR_19379', Name: 'Lafe', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_IA_19380',
    Name: 'Martensdale',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_MI_19381',
    Name: 'North Adams',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_TX_19382',
    Name: 'El Refugio',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_TN_19383',
    Name: 'Beersheba Springs',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_OR_19384',
    Name: 'North Powder',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_ND_19385',
    Name: 'Leeds',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MS_19386',
    Name: 'Hatley',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_AR_19387',
    Name: 'Salesville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MD_19388',
    Name: 'Leitersburg',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_FL_19389',
    Name: 'Glen St. Mary',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_UT_19390', Name: 'Plymouth', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_MI_19391', Name: 'Kaleva', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_AL_19392', Name: 'Berlin', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_WA_19393',
    Name: 'Sprague',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IL_19394', Name: 'Emden', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_WY_19395', Name: 'Alta', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_NC_19396',
    Name: 'Hatteras',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NE_19397',
    Name: 'Sterling',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IL_19398',
    Name: 'Norwood',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AR_19399',
    Name: 'Cushman',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_IA_19400', Name: 'Ocheyedan', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_VA_19401',
    Name: 'Ivanhoe',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_AK_19402', Name: 'Angoon', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_MO_19403',
    Name: 'Greentop',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_VA_19404', Name: 'Ivy', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_PA_19405',
    Name: 'Hawthorn',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_19406', Name: 'Hayesville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_KY_19407',
    Name: 'Forest Hills',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MN_19408',
    Name: 'Danube',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_ID_19409', Name: 'Nezperce', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_WA_19410',
    Name: 'Colton',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_AZ_19411',
    Name: 'Mesquite Creek',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_VA_19412',
    Name: 'Urbanna',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_19413',
    Name: 'Mad River',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AZ_19414', Name: 'Gisela', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_IL_19415',
    Name: 'Versailles',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_VA_19416',
    Name: 'Mount Crawford',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_IA_19417', Name: 'Lamont', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SC_19418',
    Name: 'Lockhart',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_OR_19419',
    Name: 'Butte Falls',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_IL_19420', Name: 'Bowen', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_KY_19421',
    Name: 'Summersville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WI_19422',
    Name: 'Florence',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_AR_19423',
    Name: 'Caldwell',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_SD_19424',
    Name: 'Big Stone City',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_WY_19425', Name: 'Lingle', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_KS_19426', Name: 'Richmond', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OK_19427', Name: 'Paden', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_MS_19428',
    Name: 'Dumas',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CA_19429',
    Name: 'Clarksburg',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MO_19430', Name: 'Qulin', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_AR_19431',
    Name: 'Crawfordsville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MO_19432',
    Name: 'Pasadena Park',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_GA_19433', Name: 'Menlo', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MN_19434',
    Name: 'Ellsworth',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NC_19435',
    Name: 'South Mills',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_KS_19436',
    Name: 'Strong City',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_OK_19437',
    Name: 'Drummond',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_IN_19438',
    Name: 'Fontanet',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NY_19439',
    Name: 'Valley Falls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OH_19440', Name: 'Waite Hill', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_19441',
    Name: 'Cornell',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_KS_19442', Name: 'Glasco', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_19443',
    Name: 'Fredonia',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AZ_19444', Name: 'Jerome', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_OR_19445', Name: 'St. Paul', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_TN_19446',
    Name: 'Gilt Edge',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MI_19447',
    Name: 'White Pine',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_TX_19448', Name: 'Kendleton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NC_19449',
    Name: 'Jackson',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OK_19450',
    Name: 'Proctor',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_AZ_19451',
    Name: 'Greasewood',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_SD_19452',
    Name: 'Bryant',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_FL_19453', Name: 'Orchid', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_IN_19454', Name: 'Laketon', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_IN_19455',
    Name: 'Wilkinson',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CA_19456',
    Name: 'West Point',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_19457',
    Name: 'Lanesboro',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_19458',
    Name: 'Mecosta',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_VA_19459',
    Name: 'Nelsonia',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MN_19460',
    Name: 'Kellogg',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MO_19461',
    Name: 'Calhoun',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_19462',
    Name: 'Beecher City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OK_19463',
    Name: 'Chattanooga',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MO_19464',
    Name: 'Otterville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MD_19465', Name: 'Allen', stateId: 'MD', stateName: 'Maryland'},
  {Id: 'CIT_US_IL_19466', Name: 'Cherry', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_FL_19467',
    Name: 'Jupiter Inlet Colony',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NC_19468',
    Name: 'Ashley Heights',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MI_19469',
    Name: 'Thompsonville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_KS_19470',
    Name: 'Kanopolis',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_OK_19471',
    Name: 'Medicine Park',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_TX_19472', Name: 'Byers', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OH_19473',
    Name: 'Martinsville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_SC_19474',
    Name: 'Lane',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_IA_19475', Name: 'Alleman', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IN_19476',
    Name: 'Michigantown',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OR_19477', Name: 'Wimer', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_GA_19478',
    Name: 'Crooked Creek',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MO_19479',
    Name: 'Avondale',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_19480',
    Name: 'Fawn Grove',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_19481',
    Name: 'Lake Hart',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IN_19482', Name: 'Roann', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_CA_19483',
    Name: 'Carnelian Bay',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TN_19484',
    Name: 'Baileyton',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MD_19485',
    Name: 'Mount Lena',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NM_19486',
    Name: 'Highland Meadows',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_KY_19487', Name: 'Island', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_AR_19488',
    Name: 'Ravenden',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_ME_19489',
    Name: 'Cousins Island',
    stateId: 'ME',
    stateName: 'Maine',
  },
  {Id: 'CIT_US_AR_19490', Name: 'Reyno', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_PR_19491',
    Name: 'Peña Pobre',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_CT_19492',
    Name: 'Falls Village',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {Id: 'CIT_US_IA_19493', Name: 'Bayard', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WV_19494',
    Name: 'Carolina',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NY_19495',
    Name: 'Newfield Hamlet',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_SD_19496',
    Name: 'Emery',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_NE_19497', Name: 'Edgar', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_GA_19498', Name: 'Funston', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_PA_19499',
    Name: 'Fernville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AK_19500', Name: 'Galena', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_IN_19501', Name: 'Holton', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NE_19502',
    Name: 'Coleridge',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NM_19503',
    Name: 'Pleasanton',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_19504',
    Name: 'Vanderbilt',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_19505',
    Name: 'Mount Auburn',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_19506',
    Name: 'Hallsboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_19507',
    Name: 'Fithian',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_WI_19508', Name: 'Cobb', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_MN_19509',
    Name: 'Cosmos',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_19510',
    Name: 'Skellytown',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_KY_19511',
    Name: 'Norbourne Estates',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_AL_19512',
    Name: 'Rutledge',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_OH_19513',
    Name: 'Senecaville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_IA_19514', Name: 'Emerson', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_19515',
    Name: 'Point Arena',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KS_19516',
    Name: 'Arlington',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_IL_19517',
    Name: 'Lake Petersburg',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_19518',
    Name: 'Touchet',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_ND_19519',
    Name: 'Emerado',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_CA_19520',
    Name: 'Bass Lake',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VA_19521',
    Name: 'Cheriton',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WV_19522',
    Name: 'Montcalm',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_AK_19523', Name: 'Fox', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_WI_19524',
    Name: 'Coloma',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MI_19525',
    Name: 'Rothbury',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_TN_19526', Name: 'Elgin', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_NC_19527',
    Name: 'Lattimore',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_HI_19528', Name: 'Ualapue', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_MO_19529', Name: 'Essex', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_CA_19530',
    Name: 'Diablo',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AK_19531', Name: 'Gustavus', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_FL_19532', Name: 'Wacissa', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MT_19533',
    Name: 'Lodge Grass',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_FL_19534',
    Name: 'Tildenville',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NC_19535',
    Name: 'Jamesville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_19536',
    Name: 'Broad Top City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_19537',
    Name: 'Camargo',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TN_19538', Name: 'Henry', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_MO_19539',
    Name: 'Keytesville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_19540',
    Name: 'South Bethlehem',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_19541',
    Name: 'Randolph',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MS_19542',
    Name: 'Lauderdale',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IN_19543', Name: 'Milton', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_MT_19544',
    Name: 'Alberton',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MI_19545',
    Name: 'Woodland',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MO_19546',
    Name: 'Branson West',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_19547', Name: 'Richmond', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_LA_19548',
    Name: 'Convent',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_IA_19549', Name: 'Farragut', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IN_19550', Name: 'Kennard', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NV_19551',
    Name: 'Carter Springs',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {Id: 'CIT_US_TX_19552', Name: 'Appleby', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_19553',
    Name: 'Marston',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_19554',
    Name: 'Springboro',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_19555',
    Name: 'Terminous',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_VA_19556', Name: 'Fries', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_LA_19557',
    Name: 'Point Place',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_OH_19558',
    Name: 'West Manchester',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OH_19559', Name: 'Huntsville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_FL_19560',
    Name: 'Brownsdale',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_WV_19561',
    Name: 'Kopperston',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_CA_19562',
    Name: 'McArthur',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KS_19563',
    Name: 'Wilroads Gardens',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_WY_19564', Name: 'Hudson', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_OH_19565',
    Name: 'Gloria Glens Park',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_FL_19566',
    Name: 'Cinco Bayou',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_NE_19567', Name: 'Nelson', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_SC_19568',
    Name: 'Atlantic Beach',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_19569',
    Name: 'Gouglersville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_19570',
    Name: 'Moorland',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_KY_19571',
    Name: 'Briarwood',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NM_19572',
    Name: 'Cochiti',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_DE_19573',
    Name: 'Fenwick Island',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_NC_19574',
    Name: 'Bakersville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_AR_19575', Name: 'Calion', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_ID_19576', Name: 'Weippe', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_MS_19577',
    Name: 'Taylor',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OH_19578', Name: 'Clarksburg', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AR_19579',
    Name: 'Evening Shade',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_KS_19580', Name: 'Scammon', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_TX_19581', Name: 'Avery', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_19582',
    Name: 'Holland Patent',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_GA_19583',
    Name: 'Moreland',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NC_19584',
    Name: 'Elk Park',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_NY_19585', Name: 'Panama', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_PA_19586',
    Name: 'Robinson',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TN_19587',
    Name: 'Bransford',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_CA_19588',
    Name: 'Ripley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_19589',
    Name: 'Summerhill',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_19590',
    Name: 'Greenview',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_OH_19591',
    Name: 'Mount Pleasant',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WA_19592',
    Name: 'South Prairie',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_UT_19593', Name: 'Sigurd', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_SD_19594',
    Name: 'Montrose',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IA_19595', Name: 'Titonka', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_ID_19596',
    Name: 'Winchester',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_MN_19597',
    Name: 'Akeley',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_19598',
    Name: 'Highland Haven',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OH_19599', Name: 'Edison', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OK_19600',
    Name: 'Wapanucka',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_PA_19601',
    Name: 'South Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OK_19602', Name: 'Leedey', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_WV_19603',
    Name: 'Lashmeet',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_19604',
    Name: 'Douglassville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_19605',
    Name: 'JAARS',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_VA_19606',
    Name: 'Mount Sidney',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MN_19607',
    Name: 'Bigfork',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NC_19608',
    Name: 'Grimesland',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_GA_19609',
    Name: 'Harrison',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_TX_19610',
    Name: 'Buffalo Springs',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_19611',
    Name: 'Shippenville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_19612',
    Name: 'River Bluff',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_GA_19613',
    Name: 'Mansfield',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_WY_19614',
    Name: 'La Grange',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_PA_19615',
    Name: 'Carmichaels',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_19616',
    Name: 'Dillwyn',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_KY_19617',
    Name: 'Spottsville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_MN_19618', Name: 'Ashby', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_CO_19619', Name: 'Heeney', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_AL_19620',
    Name: 'St. Stephens',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_WV_19621',
    Name: 'Clay',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_OR_19622', Name: 'Maupin', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_TX_19623', Name: 'Follett', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TN_19624',
    Name: 'Ridgeside',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NC_19625',
    Name: 'Bridgeton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_19626', Name: 'Bigfoot', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_GA_19627',
    Name: 'Offerman',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_TX_19628',
    Name: 'Tierra Verde',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NE_19629',
    Name: 'Spalding',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_DE_19630', Name: 'Arden', stateId: 'DE', stateName: 'Delaware'},
  {Id: 'CIT_US_KY_19631', Name: 'Arjay', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_KY_19632',
    Name: 'Sacramento',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MN_19633',
    Name: 'Herman',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_VA_19634',
    Name: 'Max Meadows',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_IL_19635', Name: 'Dix', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_WY_19636',
    Name: 'Teton Village',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_WI_19637',
    Name: 'Merrimac',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MO_19638',
    Name: 'Irondale',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AK_19639',
    Name: 'Manokotak',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_UT_19640', Name: 'Neola', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_LA_19641',
    Name: 'Roanoke',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MI_19642',
    Name: 'New Era',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_AZ_19643',
    Name: 'Fort Thomas',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_OR_19644', Name: 'Wheeler', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_KS_19645', Name: 'Norwich', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_UT_19646', Name: 'Cleveland', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_IL_19647',
    Name: 'Buckner',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_19648',
    Name: 'Strasburg',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_19649',
    Name: 'Oak Island',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NC_19650',
    Name: 'Parkton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NJ_19651',
    Name: 'Stewartsville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MI_19652',
    Name: 'Bloomingdale',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WA_19653',
    Name: 'Oakesdale',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_19654',
    Name: 'Junction City',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_GA_19655', Name: 'Ideal', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_TX_19656', Name: 'DISH', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_19657',
    Name: 'Prairie Farm',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NJ_19658',
    Name: 'New Village',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OR_19659', Name: 'Fossil', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_WY_19660', Name: 'Grover', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_WI_19661',
    Name: 'Bear Creek',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_LA_19662',
    Name: 'Dry Prong',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_KS_19663', Name: 'Chase', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_TX_19664', Name: 'Leakey', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_19665',
    Name: 'McMullin',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_SD_19666',
    Name: 'Murdo',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_TX_19667',
    Name: 'Manuel Garcia',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_KS_19668',
    Name: 'Cunningham',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_PA_19669',
    Name: 'Stouchsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_19670',
    Name: 'Montrose Manor',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_19671',
    Name: 'Industry',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_19672', Name: 'San Pedro', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AZ_19673',
    Name: 'Bitter Springs',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WV_19674',
    Name: 'Nettie',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MS_19675',
    Name: 'Noxapater',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NV_19676',
    Name: 'Fallon Station',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_WI_19677',
    Name: 'Sextonville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_19678',
    Name: 'Dalmatia',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MO_19679', Name: 'Galena', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MS_19680',
    Name: "D'Lo",
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_KS_19681', Name: 'Potwin', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MN_19682',
    Name: 'New Auburn',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_KS_19683',
    Name: 'Kensington',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_IA_19684', Name: 'Miles', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_UT_19685', Name: 'Marysvale', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_LA_19686',
    Name: 'Hornbeck',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WA_19687',
    Name: 'North Omak',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_19688',
    Name: 'Sidman',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_19689',
    Name: 'Martins Creek',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_19690',
    Name: 'Meadville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_19691',
    Name: 'Neponset',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OR_19692',
    Name: 'Huntington',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_OH_19693', Name: 'Jerry City', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_LA_19694', Name: 'Hodge', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_MO_19695',
    Name: 'Bucklin',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MS_19696',
    Name: 'McLain',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_AL_19697',
    Name: 'Rockford',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MO_19698',
    Name: 'Newburg',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_19699',
    Name: 'Bolivar',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_19700',
    Name: 'Clara',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MS_19701',
    Name: 'Shuqualak',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_TX_19702',
    Name: 'Mount Enterprise',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AZ_19703',
    Name: 'Drysdale',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_TX_19704',
    Name: 'Rose Hill Acres',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_VA_19705', Name: 'Floyd', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_MD_19706',
    Name: 'Kennedyville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_19707', Name: 'Garceno', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MT_19708', Name: 'Batavia', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_SD_19709',
    Name: 'Mount Vernon',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_PA_19710',
    Name: 'Mechanicsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_19711',
    Name: 'Fountain',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IA_19712', Name: 'Riverdale', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_TN_19713', Name: 'Guys', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_MD_19714',
    Name: 'Fairplay',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_OH_19715',
    Name: 'Murray City',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_KS_19716', Name: 'Kismet', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_19717',
    Name: 'West Decatur',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_19718',
    Name: 'Hunts Point',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_KS_19719', Name: 'Florence', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WI_19720',
    Name: 'Gibbsville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_19721',
    Name: 'Monroe Center',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IN_19722',
    Name: 'Stockwell',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_TX_19723', Name: 'Gallatin', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_19724', Name: 'Karnak', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OK_19725', Name: 'Keys', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_IN_19726',
    Name: 'New Goshen',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_KY_19727',
    Name: 'Manitou',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_MT_19728', Name: 'Coram', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_AZ_19729',
    Name: 'Dateland',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_IN_19730',
    Name: 'Claypool',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_WA_19731',
    Name: 'Raft Island',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_DE_19732',
    Name: 'Ellendale',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {Id: 'CIT_US_TX_19733', Name: 'Matagorda', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_19734', Name: 'Belmont', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OK_19735', Name: 'Wynona', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_WI_19736', Name: 'Laona', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_VT_19737',
    Name: 'Websterville',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {Id: 'CIT_US_VA_19738', Name: 'Tasley', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_MO_19739',
    Name: 'Lake Viking',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NV_19740',
    Name: 'Zephyr Cove',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_CO_19741',
    Name: 'Allenspark',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_IA_19742',
    Name: 'Little Rock',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_AK_19743',
    Name: 'Kongiganak',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_NE_19744',
    Name: 'Hampton',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_CO_19745',
    Name: 'Crawford',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_IN_19746',
    Name: 'Perrysville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MO_19747',
    Name: 'Novinger',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IA_19748', Name: 'Marquette', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_19749',
    Name: 'Three Springs',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_19750',
    Name: 'Converse',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_KS_19751',
    Name: 'Cawker City',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_ND_19752',
    Name: 'Fessenden',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_ND_19753',
    Name: 'Green Acres',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_KY_19754',
    Name: 'Sedalia',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IL_19755', Name: 'Hull', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CA_19756',
    Name: 'French Gulch',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_19757',
    Name: 'York Hamlet',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_19758',
    Name: 'Brushton',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_ID_19759',
    Name: 'Lava Hot Springs',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_KS_19760',
    Name: 'Bird City',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_WI_19761',
    Name: 'Almond',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MO_19762',
    Name: 'Ridgeway',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_19763',
    Name: 'Long Branch',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_19764',
    Name: 'Washburn',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_19765',
    Name: 'Garrett',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_19766', Name: 'B and E', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_19767', Name: 'Beaver', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_19768',
    Name: 'Waltonville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_19769',
    Name: 'California Pines',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NM_19770',
    Name: 'Cuyamungue',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_19771',
    Name: 'Ernest',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IN_19772', Name: 'Amo', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NC_19773',
    Name: 'Caswell Beach',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_19774',
    Name: 'Bondville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_19775',
    Name: 'Clarksville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_19776',
    Name: 'Herrick',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_KS_19777',
    Name: 'Leonardville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_KY_19778',
    Name: 'Centertown',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_FL_19779',
    Name: 'Beverly Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MN_19780',
    Name: 'Wykoff',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WA_19781',
    Name: 'Keyport',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_ND_19782',
    Name: 'Glenburn',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NY_19783',
    Name: 'Chestertown',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OK_19784',
    Name: 'Shidler',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_MO_19785', Name: 'Ewing', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_AL_19786', Name: 'Silas', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_AZ_19787', Name: 'Picacho', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_IL_19788',
    Name: 'Edgewood',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_19789',
    Name: 'Oak Glen',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_19790',
    Name: 'Alcolu',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_IA_19791', Name: 'Denmark', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SD_19792',
    Name: 'Rosholt',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MO_19793',
    Name: 'Ellsinore',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_19794',
    Name: 'Ramey',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OK_19795',
    Name: 'Spavinaw',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_ND_19796',
    Name: 'South Heart',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NE_19797',
    Name: 'McCool Junction',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_MN_19798', Name: 'Oklee', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_TX_19799', Name: 'Angus', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_19800',
    Name: 'Rhinecliff',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WI_19801',
    Name: 'Neshkoro',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TX_19802',
    Name: 'Vanderbilt',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NY_19803',
    Name: 'Springwater Hamlet',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_19804',
    Name: 'Bangor',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_19805',
    Name: 'Stevens',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_19806',
    Name: 'Hewlett Bay Park',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MN_19807',
    Name: 'Sturgeon Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MI_19808',
    Name: 'Brethren',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MS_19809',
    Name: 'North Carrollton',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WA_19810',
    Name: 'Union',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NE_19811',
    Name: 'Greeley Center',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NC_19812',
    Name: 'Washington Park',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MO_19813',
    Name: 'Purcell',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MT_19814',
    Name: 'Beaver Creek',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_PA_19815',
    Name: 'Churchtown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AZ_19816',
    Name: 'Rough Rock',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_TX_19817', Name: 'Fruitvale', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_19818', Name: 'Yantis', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_19819', Name: 'Lago', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_19820',
    Name: 'Birchwood',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TN_19821',
    Name: 'Castalian Springs',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_IN_19822',
    Name: 'Williams Creek',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_ID_19823', Name: 'Eden', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_WY_19824', Name: 'Hulett', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_WV_19825',
    Name: 'Whitesville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_IA_19826', Name: 'Conesville', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_19827',
    Name: 'West Goshen',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VA_19828',
    Name: 'Troutville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MI_19829',
    Name: 'Hanover',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MI_19830',
    Name: 'Cement City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SD_19831',
    Name: 'Leola',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NC_19832',
    Name: 'Topsail Beach',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MS_19833',
    Name: 'Weir',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CA_19834',
    Name: 'Coloma',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NM_19835',
    Name: 'Ramah',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_TX_19836',
    Name: 'Ropesville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_KY_19837', Name: 'Oneida', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_AZ_19838',
    Name: 'Turkey Creek',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_PA_19839',
    Name: 'South Renovo',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WY_19840',
    Name: 'Encampment',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_NY_19841',
    Name: 'Prospect',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_19842',
    Name: 'Niangua',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OR_19843', Name: 'Bonanza', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NC_19844',
    Name: 'Macclesfield',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OH_19845',
    Name: 'Sugar Grove',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_SD_19846',
    Name: 'Anderson',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_CO_19847', Name: 'Victor', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_TX_19848', Name: 'Christine', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SC_19849',
    Name: 'Lydia',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_MO_19850', Name: 'Delta', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MS_19851',
    Name: 'Artesia',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_GA_19852',
    Name: 'Attapulgus',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_AK_19853', Name: 'Nuiqsut', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_IL_19854',
    Name: 'German Valley',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_NE_19855', Name: 'Ansley', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_KY_19856', Name: 'Benham', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_IL_19857',
    Name: 'De Land',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_19858',
    Name: 'Lowgap',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_SD_19859',
    Name: 'Hermosa',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_CO_19860',
    Name: 'South Fork',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MN_19861',
    Name: 'Rose Creek',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_19862', Name: 'Iola', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KS_19863',
    Name: 'Alta Vista',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_WI_19864',
    Name: 'Hixton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MN_19865',
    Name: 'Upsala',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NC_19866',
    Name: 'St. Helena',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NM_19867',
    Name: 'Rio Lucio',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MN_19868',
    Name: 'Wrenshall',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_19869',
    Name: 'Muir',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_19870',
    Name: 'Savageville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MN_19871',
    Name: 'Miltona',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_19872',
    Name: 'Rices Landing',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_19873',
    Name: 'Matewan',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_MI_19874', Name: 'Oden', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_OK_19875',
    Name: 'Ochelata',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MO_19876',
    Name: 'Uplands Park',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AR_19877',
    Name: 'Winslow',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_WY_19878',
    Name: 'Rolling Hills',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_PA_19879',
    Name: 'Starbrick',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CO_19880',
    Name: 'Morrison',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PA_19881',
    Name: 'North Vandergrift',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_19882', Name: 'Sumner', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_IN_19883', Name: 'Orland', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PA_19884',
    Name: 'New Jerusalem',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_19885',
    Name: 'Clarendon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_19886',
    Name: 'Au Sable Forks',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OH_19887', Name: 'Williston', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_19888',
    Name: 'Minnesott Beach',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MS_19889',
    Name: 'Benoit',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MS_19890',
    Name: 'Abbeville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_KS_19891',
    Name: 'Glen Elder',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_LA_19892',
    Name: 'Goldonna',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MO_19893',
    Name: 'Freeburg',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OH_19894',
    Name: 'Pleasant City',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_KY_19895',
    Name: 'Poplar Hills',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NC_19896',
    Name: 'Teachey',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_MD_19897', Name: 'Barton', stateId: 'MD', stateName: 'Maryland'},
  {Id: 'CIT_US_MI_19898', Name: 'Palmer', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_CA_19899',
    Name: 'Mexican Colony',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_19900',
    Name: 'Chilcoot-Vinton',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NM_19901',
    Name: 'Pueblo of Sandia Village',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_KY_19902',
    Name: 'Campton',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_AL_19903', Name: 'Hytop', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_OR_19904', Name: 'Haines', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_WV_19905',
    Name: 'Chauncey',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_AK_19906', Name: 'Buckland', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_TN_19907',
    Name: 'Vanleer',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_OH_19908', Name: 'Lindsey', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MO_19909',
    Name: 'Bell City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OK_19910',
    Name: 'Fort Coffee',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_OH_19911',
    Name: 'New Pittsburg',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_AZ_19912',
    Name: 'Sacaton Flats Village',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WI_19913',
    Name: 'Blue River',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_AL_19914', Name: 'Kennedy', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MD_19915',
    Name: 'Broomes Island',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MS_19916',
    Name: 'New Hebron',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IL_19917', Name: 'Ipava', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_MT_19918', Name: 'Fortine', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_TX_19919', Name: 'Avinger', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_19920',
    Name: 'Naselle',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IA_19921', Name: 'Birmingham', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MS_19922',
    Name: 'Meadville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_VT_19923',
    Name: 'Ascutney',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_TX_19924',
    Name: 'Oak Valley',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_19925',
    Name: 'Sublette',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OK_19926',
    Name: 'Ketchum',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_AL_19927',
    Name: 'West Jefferson',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NC_19928',
    Name: 'Avon',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_19929', Name: 'Milano', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_19930',
    Name: 'Robbins',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_FL_19931',
    Name: 'Everglades',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NM_19932',
    Name: 'Lake Arthur',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NE_19933',
    Name: 'Bellwood',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NC_19934',
    Name: 'Stovall',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_19935',
    Name: 'Rosslyn Farms',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TN_19936',
    Name: 'Stanton',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_CA_19937',
    Name: 'Meadow Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MD_19938',
    Name: 'Parsonsburg',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_FL_19939', Name: 'Pioneer', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_PA_19940',
    Name: 'Cooperstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_19941',
    Name: 'Marlin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_19942', Name: 'Ashton', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_NE_19943', Name: 'Brady', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_IN_19944',
    Name: 'Somerset',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_KS_19945', Name: 'Linwood', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MN_19946',
    Name: 'Fisher',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MN_19947',
    Name: 'New Germany',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MN_19948',
    Name: 'Vermillion',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IA_19949', Name: 'Lawler', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_19950', Name: 'Galva', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MS_19951',
    Name: 'Bentonia',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_OH_19952',
    Name: 'Haydenville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_ID_19953', Name: 'Mud Lake', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_NY_19954',
    Name: 'Richburg',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WA_19955',
    Name: 'Harrington',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_IL_19956',
    Name: 'Humboldt',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_19957',
    Name: 'Sandyfield',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_AR_19958',
    Name: 'Belleville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_19959',
    Name: 'Marion Center',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_19960',
    Name: 'Waveland',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IA_19961', Name: 'Vail', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IN_19962',
    Name: 'Birdseye',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OH_19963', Name: 'Lafayette', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_WY_19964', Name: 'Baggs', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_MT_19965',
    Name: 'Twin Bridges',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_AR_19966',
    Name: 'Maynard',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_LA_19967',
    Name: 'Prospect',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NY_19968',
    Name: 'Port Gibson',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WV_19969',
    Name: 'Raysal',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MO_19970',
    Name: 'Union Star',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OH_19971',
    Name: 'College Corner',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CO_19972',
    Name: 'Manzanola',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_NE_19973', Name: 'Leigh', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_NY_19974',
    Name: 'Wyoming',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_RI_19975',
    Name: 'Weekapaug',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {Id: 'CIT_US_ID_19976', Name: 'Riggins', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_NE_19977',
    Name: 'Spencer',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_AZ_19978',
    Name: 'Wheatfields',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_AL_19979',
    Name: 'Hackneyville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_WI_19980',
    Name: 'Readstown',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NE_19981',
    Name: 'Beaver Crossing',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_PA_19982',
    Name: 'Commodore',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_19983',
    Name: 'Strausstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SD_19984',
    Name: 'Herreid',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_WA_19985',
    Name: 'Rosburg',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_19986',
    Name: 'Rudolph',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_ND_19987',
    Name: 'Finley',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_TX_19988',
    Name: 'El Chaparral',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_OK_19989',
    Name: 'Belfonte',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MN_19990',
    Name: 'Fairhaven',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_KS_19991', Name: 'Lewis', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NJ_19992',
    Name: 'Harlingen',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_KS_19993',
    Name: 'Parkerfield',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_AR_19994',
    Name: 'Mount Pleasant',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MN_19995',
    Name: 'Long Beach',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_MO_19996', Name: 'Urbana', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_PA_19997',
    Name: 'Frizzleburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KS_19998', Name: 'Holyrood', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_AK_19999', Name: 'Noatak', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_MO_20000', Name: 'Golden', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_SC_20001',
    Name: 'Salley',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OH_20002', Name: 'Savannah', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_20003',
    Name: 'Snydertown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_20004',
    Name: 'Laurelton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_20005',
    Name: 'Joffre',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AL_20006', Name: 'Hissop', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_AR_20007', Name: 'Leslie', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_GA_20008', Name: 'Tiger', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_MN_20009', Name: 'Nevis', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_AK_20010',
    Name: 'St. Michael',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_KS_20011', Name: 'Colony', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_CA_20012',
    Name: 'Spreckels',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_20013',
    Name: 'Salemburg',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_20014',
    Name: 'Beckwourth',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MT_20015', Name: 'Huntley', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_OK_20016',
    Name: 'Tribbey',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_IA_20017', Name: 'Plainfield', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_20018',
    Name: 'Mapleton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MI_20019', Name: 'Kinde', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MS_20020',
    Name: 'Glen',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CA_20021',
    Name: 'Raisin City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OR_20022',
    Name: 'Mount Hood',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MD_20023',
    Name: 'Midland',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MO_20024',
    Name: 'Deepwater',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_20025',
    Name: 'Thornburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_20026', Name: 'Bussey', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OK_20027',
    Name: 'Randlett',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NC_20028',
    Name: 'Ruth',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_20029',
    Name: 'Forbestown',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_20030',
    Name: 'Clinton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IL_20031', Name: 'Cutler', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_LA_20032',
    Name: 'Fort Jesup',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MT_20033',
    Name: 'Finley Point',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_NC_20034',
    Name: 'Engelhard',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_KY_20035',
    Name: 'Richlawn',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NY_20036',
    Name: 'Bloomingburg',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_20037',
    Name: 'Boys Ranch',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_20038', Name: 'Whiteface', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_20039',
    Name: 'Mattoon',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OK_20040', Name: 'Turpin', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_SC_20041',
    Name: 'Carlisle',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OR_20042', Name: 'Wasco', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_OK_20043', Name: 'Asher', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_OH_20044',
    Name: 'Fredericksburg',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_IL_20045', Name: 'Weldon', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_UT_20046', Name: 'Elberta', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_KS_20047', Name: 'McCune', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IL_20048',
    Name: 'Bay View Gardens',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_VT_20049',
    Name: 'South Shaftsbury',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_WI_20050',
    Name: 'Cochrane',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_AK_20051',
    Name: 'Glennallen',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_NC_20052',
    Name: 'Ronda',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_CO_20053', Name: 'Romeo', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_IA_20054', Name: 'New Vienna', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_20055',
    Name: 'Union Deposit',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_20056',
    Name: 'Osyka',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_VA_20057',
    Name: 'Boydton',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_20058',
    Name: 'Kirkwood',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_20059',
    Name: 'Oliver',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OH_20060', Name: 'Stewart', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_SD_20061',
    Name: 'Faith',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MI_20062',
    Name: 'Dowling',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_20063',
    Name: 'Harmonsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IL_20064', Name: 'Melvin', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_TX_20065', Name: 'Evant', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AK_20066',
    Name: 'Napaskiak',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_MO_20067',
    Name: 'Weaubleau',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AL_20068',
    Name: 'Highland Lake',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NE_20069',
    Name: 'Cedar Creek',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_OH_20070', Name: 'Hartford', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TN_20071',
    Name: 'Burlison',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WV_20072',
    Name: 'Middleway',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IL_20073',
    Name: 'Waynesville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MI_20074', Name: 'Martin', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_IA_20075', Name: 'Earling', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_20076', Name: 'Mystic', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_20077', Name: 'Royal', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MI_20078',
    Name: 'Kingston',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OK_20079', Name: 'Ripley', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_TX_20080', Name: 'Kurten', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_20081',
    Name: 'Centre Island',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NC_20082',
    Name: 'Kelly',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_MO_20083', Name: 'Brewer', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MI_20084',
    Name: 'Leonard',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IL_20085', Name: 'Benson', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_OR_20086',
    Name: 'Crawfordsville',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MT_20087',
    Name: 'Lindisfarne',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_NM_20088',
    Name: 'Peak Place',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MS_20089',
    Name: 'Gunnison',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_UT_20090', Name: 'Halchita', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_TN_20091',
    Name: 'Pelham',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_IA_20092', Name: 'Hawkeye', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OK_20093', Name: 'Gage', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NM_20094',
    Name: 'Williamsburg',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_CA_20095',
    Name: 'Lake Almanor Country Club',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_20096',
    Name: 'Hilda',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_KS_20097', Name: 'Assaria', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NM_20098',
    Name: 'Mesquite',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_IA_20099', Name: 'Andrew', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_20100',
    Name: 'Dunlevy',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_20101',
    Name: 'Forestville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_ID_20102', Name: 'Basalt', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_WI_20103',
    Name: 'Three Lakes',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MN_20104',
    Name: 'Barrett',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_20105',
    Name: 'St. Rose',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_UT_20106', Name: 'Glendale', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_KY_20107', Name: 'Maceo', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_TX_20108', Name: 'Orchard', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OH_20109',
    Name: 'Rocky Ridge',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_UT_20110',
    Name: 'Kanarraville',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {Id: 'CIT_US_MO_20111', Name: 'Osborn', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_SD_20112',
    Name: 'Long Hollow',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_OK_20113',
    Name: 'Eldorado',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_IA_20114',
    Name: 'Lost Nation',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_ND_20115',
    Name: 'Wyndmere',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MS_20116',
    Name: 'Shubuta',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_NY_20117', Name: 'Altmar', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_SD_20118',
    Name: 'Irene',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_IL_20119',
    Name: 'Leaf River',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_CO_20120', Name: 'Blanca', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_NE_20121',
    Name: 'Malcolm',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_SC_20122',
    Name: 'Edisto Beach',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OH_20123', Name: 'New Haven', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_FL_20124', Name: 'Lisbon', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MO_20125',
    Name: 'Platte Woods',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_GA_20126',
    Name: 'Warm Springs',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CA_20127',
    Name: 'Boulevard',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_20128',
    Name: 'Hancock',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MN_20129',
    Name: 'Waubun',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IA_20130', Name: 'Cumming', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_AR_20131', Name: 'Portia', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_MI_20132', Name: 'Mesick', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_DE_20133',
    Name: 'Houston',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_MO_20134',
    Name: 'Lanagan',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OK_20135',
    Name: 'Fanshawe',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_PA_20136',
    Name: 'Rome',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_20137',
    Name: 'Keyesport',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_20138',
    Name: 'Rosebud',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MI_20139', Name: 'Powers', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_VA_20140', Name: 'Sedley', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_IL_20141', Name: 'Kane', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_IL_20142',
    Name: 'Saunemin',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OK_20143',
    Name: 'Crowder',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MN_20144',
    Name: 'Fifty Lakes',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IN_20145', Name: 'Orestes', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_WV_20146',
    Name: 'Fairview',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_MO_20147', Name: 'Cooter', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_OH_20148', Name: 'Derby', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IN_20149',
    Name: 'Sandborn',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NC_20150',
    Name: 'Saratoga',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CO_20151',
    Name: 'Fleming',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_OH_20152', Name: 'Trimble', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_KY_20153',
    Name: 'Beech Grove',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MI_20154',
    Name: 'Onekama',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_AL_20155',
    Name: 'Pleasant Groves',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_AZ_20156', Name: 'Elfrida', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_TX_20157',
    Name: 'Grandfalls',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CA_20158',
    Name: 'Garden Farms',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_20159', Name: 'Bonaparte', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_TX_20160',
    Name: 'Mirando City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_OK_20161',
    Name: 'Stringtown',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_KY_20162', Name: 'Hazel', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_PA_20163',
    Name: 'Orbisonia',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_20164', Name: 'Harrod', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_ND_20165',
    Name: 'Westhope',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_WI_20166',
    Name: 'Eastman',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_KS_20167', Name: 'Axtell', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PR_20168',
    Name: 'Corral Viejo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_OH_20169',
    Name: 'Holmesville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_KS_20170', Name: 'Jewell', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_SD_20171',
    Name: 'Tabor',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IA_20172', Name: 'Thornton', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_KS_20173', Name: 'Edna', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MT_20174',
    Name: "Rocky Boy's Agency",
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_OH_20175', Name: 'Crown City', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TN_20176',
    Name: 'Dowelltown',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_UT_20177', Name: 'Elmo', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NC_20178',
    Name: 'Marshallberg',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NM_20179',
    Name: 'Isleta Village Proper',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NM_20180',
    Name: 'Cedro',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_WA_20181',
    Name: 'Green Bluff',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NC_20182',
    Name: 'Peachland',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OH_20183',
    Name: 'Grand River',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OR_20184', Name: 'Holley', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_IA_20185', Name: 'Casey', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WI_20186',
    Name: 'Clyman',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NJ_20187',
    Name: 'Skillman',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_AR_20188',
    Name: 'Yarborough Landing',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MN_20189',
    Name: 'Wood Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IA_20190', Name: 'New Market', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MO_20191', Name: 'Corder', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_NE_20192',
    Name: 'Winside',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_OH_20193',
    Name: 'Tuppers Plains',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_IA_20194', Name: 'Calamus', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_20195',
    Name: 'Ravenwood',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AK_20196',
    Name: 'Brevig Mission',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_PR_20197',
    Name: 'La Yuca',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_IN_20198', Name: 'Manilla', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_IL_20199', Name: 'Lomax', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_AK_20200', Name: 'Tununak', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_AL_20201', Name: 'Sipsey', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_OH_20202',
    Name: 'South Vienna',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_AZ_20203', Name: 'Topawa', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_GA_20204', Name: 'Molena', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NE_20205',
    Name: 'Clearwater',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NY_20206',
    Name: 'Harbor Hills',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_20207',
    Name: 'Platea',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_20208',
    Name: 'Martell',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MI_20209',
    Name: 'Hubbardston',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WI_20210',
    Name: 'Pigeon Falls',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IL_20211', Name: 'Ullin', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_TX_20212', Name: 'Rosser', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_DE_20213',
    Name: 'Dewey Beach',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_IN_20214',
    Name: 'Hartsville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MN_20215',
    Name: 'Willow River',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AR_20216',
    Name: 'Kingsland',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MI_20217',
    Name: 'Watersmeet',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_MT_20218', Name: 'Azure', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_TX_20219',
    Name: 'Ranchitos Las Lomas',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AK_20220',
    Name: 'Kwigillingok',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_OK_20221',
    Name: 'Lone Wolf',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_OK_20222',
    Name: 'Delaware',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_CA_20223',
    Name: 'Sand City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_20224',
    Name: 'Jacksonboro',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NY_20225',
    Name: 'St. Regis Falls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MN_20226',
    Name: 'Fountain',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_20227',
    Name: 'Lake Bridgeport',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_SD_20228',
    Name: 'Wolsey',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IA_20229', Name: 'Arlington', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_AK_20230', Name: 'Tyonek', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_SD_20231',
    Name: 'Hurley',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_FL_20232', Name: 'Wausau', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_VA_20233',
    Name: 'Burkeville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CA_20234',
    Name: 'San Geronimo',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_20235', Name: 'Lewis', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WV_20236',
    Name: 'Harts',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NC_20237',
    Name: 'Swan Quarter',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IA_20238', Name: 'Milton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_20239',
    Name: 'Grenada',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SC_20240',
    Name: 'Cameron',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_20241',
    Name: 'Holiday Pocono',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IN_20242', Name: 'Troy', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_TX_20243',
    Name: 'Union Valley',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_MN_20244', Name: 'Remer', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_NC_20245',
    Name: 'Staley',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_VA_20246', Name: 'Keokee', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_IL_20247', Name: 'Loda', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MO_20248',
    Name: 'Jamestown',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AL_20249',
    Name: 'Ranburne',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_RI_20250',
    Name: 'Misquamicut',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_VA_20251',
    Name: 'Irvington',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NY_20252',
    Name: 'West Chazy',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_20253',
    Name: 'Hopewell',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NM_20254',
    Name: 'Vaughn',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IN_20255',
    Name: 'Idaville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PA_20256',
    Name: 'Weissport',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_20257',
    Name: 'Estral Beach',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_20258',
    Name: 'Allport',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_20259',
    Name: 'La Crosse',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_AR_20260', Name: 'Alpena', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_MO_20261', Name: 'Hale', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_IN_20262', Name: 'Shelby', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_IA_20263',
    Name: 'Holiday Lake',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_IA_20264', Name: 'Stanhope', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MI_20265',
    Name: 'Clarksville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_OK_20266',
    Name: 'Old Green',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_SD_20267',
    Name: 'Gayville',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MO_20268',
    Name: 'Twin Oaks',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WV_20269',
    Name: 'Glen Fork',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_KY_20270',
    Name: 'Fredonia',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_VA_20271',
    Name: 'Cumberland',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NC_20272',
    Name: 'Duck',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_SC_20273',
    Name: 'Hemingway',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_WY_20274',
    Name: 'Sinclair',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_IA_20275',
    Name: 'Worthington',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_PA_20276',
    Name: 'Lattimer',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_20277',
    Name: 'Summerfield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_20278', Name: 'Reklaw', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AL_20279', Name: 'Shorter', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_ID_20280', Name: 'Fairfield', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_GA_20281',
    Name: 'Bostwick',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_UT_20282', Name: 'Cedar Fort', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_OR_20283',
    Name: 'Gopher Flats',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_WA_20284',
    Name: 'Jamestown',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_AR_20285',
    Name: 'Oak Grove',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CO_20286',
    Name: 'Lake City',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MS_20287',
    Name: 'Soso',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_AK_20288', Name: 'Akiak', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_OH_20289', Name: 'McDermott', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_WY_20290', Name: 'Midwest', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_OK_20291', Name: 'Tushka', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_WY_20292', Name: 'Thayne', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_TX_20293', Name: 'Loop', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_ID_20294', Name: 'Bancroft', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_PA_20295',
    Name: 'Wylandville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OR_20296', Name: 'Shedd', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_AZ_20297',
    Name: 'Littlefield',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_OH_20298', Name: 'Rayland', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_GA_20299',
    Name: 'Stapleton',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_UT_20300', Name: 'Holden', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_IA_20301', Name: 'Lynnville', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_TX_20302', Name: 'Bloomburg', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OR_20303',
    Name: 'Scotts Mills',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_PA_20304',
    Name: 'Finleyville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AZ_20305',
    Name: 'Chloride',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_TX_20306',
    Name: 'Howardwick',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_CO_20307', Name: 'Wiley', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_OH_20308',
    Name: 'Canal Lewisville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OK_20309', Name: 'Lamont', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_MI_20310',
    Name: 'Perrinton',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_UT_20311', Name: 'Hoytsville', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_WI_20312',
    Name: 'Gilman',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MN_20313',
    Name: 'St. Marys Point',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_20314', Name: 'Edom', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_20315',
    Name: 'Morrisville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_AL_20316', Name: 'Twin', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_ND_20317',
    Name: 'Logan',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_IA_20318', Name: 'Lehigh', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_TX_20319', Name: 'Mildred', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_20320', Name: 'Medora', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_WA_20321',
    Name: 'Nespelem',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MN_20322',
    Name: 'Pine Springs',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_20323', Name: 'Higgins', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AR_20324',
    Name: 'Edmondson',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_KS_20325',
    Name: 'Williamsburg',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_AR_20326', Name: 'Grady', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_KS_20327',
    Name: 'Westwood Hills',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_MT_20328',
    Name: 'Stanford',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_KS_20329', Name: 'Lucas', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_AR_20330',
    Name: 'Patterson',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_IN_20331', Name: 'Lagro', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_KS_20332', Name: 'Gypsum', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OH_20333', Name: 'Barnhill', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_LA_20334',
    Name: 'Tullos',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_IN_20335',
    Name: 'Richland',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_NY_20336', Name: 'Hobart', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_MI_20337',
    Name: 'Advance',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NC_20338',
    Name: 'Vann Crossroads',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_20339',
    Name: 'Sadorus',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_20340',
    Name: 'Oakview',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_VA_20341',
    Name: 'Montross',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MD_20342',
    Name: 'Ellerslie',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NC_20343',
    Name: 'Marble',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_20344',
    Name: 'Elkhart',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IN_20345', Name: 'Otwell', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_KY_20346',
    Name: 'Kingsley',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CA_20347',
    Name: 'June Lake',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_20348', Name: 'Dodd City', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MI_20349', Name: 'Zeba', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_TX_20350', Name: 'Bayview', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_20351',
    Name: 'Lohrville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_20352', Name: 'Quitaque', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_20353',
    Name: 'Orick',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WV_20354',
    Name: 'Mount Carbon',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_OH_20355', Name: 'Hanoverton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AR_20356',
    Name: 'Hatfield',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NM_20357',
    Name: 'Pueblo Pintado',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NC_20358',
    Name: 'Cove City',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_20359',
    Name: 'Hookerton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OK_20360',
    Name: 'Bowlegs',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MO_20361',
    Name: 'Huntleigh',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_20362',
    Name: 'Brisbin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MD_20363',
    Name: 'Midlothian',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_20364', Name: 'Camargito', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OK_20365', Name: 'Oktaha', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_AR_20366',
    Name: 'Portland',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CA_20367',
    Name: 'Tonyville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_20368',
    Name: 'McHenry',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IN_20369',
    Name: 'Vallonia',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PA_20370',
    Name: 'Maitland',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MT_20371',
    Name: 'East Glacier Park Village',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_NC_20372',
    Name: 'Webster',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OK_20373',
    Name: 'East Duke',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WV_20374',
    Name: 'Gilbert',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_IA_20375', Name: 'Salix', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OK_20376',
    Name: 'Whitefield',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_IN_20377',
    Name: 'Greens Fork',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PA_20378',
    Name: 'Oval',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_20379',
    Name: 'Worthington Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_VA_20380', Name: 'Melfa', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_NM_20381',
    Name: 'Jacona',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_GA_20382', Name: 'Warwick', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_UT_20383',
    Name: 'Montezuma Creek',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_OH_20384',
    Name: 'St. Louisville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_TX_20385', Name: 'Barstow', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_20386',
    Name: 'Derby Acres',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_20387', Name: 'Marathon', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NC_20388',
    Name: 'Dover',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TN_20389',
    Name: 'Gibson',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WV_20390',
    Name: 'Jane Lew',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MN_20391',
    Name: 'Chokio',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WY_20392',
    Name: 'Reliance',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_VA_20393',
    Name: 'Stanardsville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_MT_20394', Name: 'Jordan', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_MD_20395',
    Name: 'Bagtown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CO_20396',
    Name: 'Johnson Village',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_IL_20397', Name: 'Dowell', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NM_20398',
    Name: 'Dixon',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_OH_20399',
    Name: 'East Fultonham',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NC_20400',
    Name: 'Bunn',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_AK_20401', Name: 'Saxman', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_PA_20402',
    Name: 'Manorville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MT_20403', Name: 'Kila', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_WV_20404',
    Name: 'Charlton Heights',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_TX_20405',
    Name: 'Union Grove',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_LA_20406',
    Name: 'Calhoun',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_20407',
    Name: 'Mill Village',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_20408',
    Name: 'Ethel',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_20409',
    Name: 'Mount Pleasant Mills',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_20410',
    Name: 'McElhattan',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_20411',
    Name: 'Lakewood',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_UT_20412', Name: 'Wallsburg', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_TX_20413', Name: 'Alma', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OK_20414', Name: 'Terral', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CO_20415',
    Name: 'Red Feather Lakes',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_TX_20416', Name: 'Retreat', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_GA_20417', Name: 'Concord', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_TX_20418', Name: 'Chireno', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WV_20419',
    Name: 'Berwind',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NY_20420',
    Name: 'Delanson',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_20421', Name: 'Montague', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_20422', Name: 'Salem', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MT_20423',
    Name: 'Martin City',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_GA_20424', Name: 'Martin', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_AL_20425',
    Name: 'Vinegar Bend',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_TX_20426',
    Name: 'Callisburg',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_20427', Name: 'Sadler', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_20428', Name: 'Duncombe', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_VT_20429',
    Name: 'Wells River',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_FL_20430',
    Name: 'Fisher Island',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_MO_20431', Name: 'Alma', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_GA_20432',
    Name: 'Braswell',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NM_20433',
    Name: 'Naschitti',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_AR_20434',
    Name: 'Thornton',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_MI_20435', Name: 'Jasper', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_TX_20436', Name: 'Relampago', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_20437',
    Name: 'Amherst Junction',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_20438',
    Name: 'Table Grove',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SD_20439',
    Name: 'Hayti',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_ID_20440', Name: 'Culdesac', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_AR_20441',
    Name: 'Damascus',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_LA_20442',
    Name: 'Kilbourne',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NY_20443',
    Name: 'Kennedy',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OH_20444', Name: 'Morral', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_20445',
    Name: 'Princeton',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_20446', Name: 'Perry', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_IL_20447', Name: 'Joy', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OR_20448', Name: 'Tutuilla', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_KY_20449',
    Name: 'Norwood',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IL_20450',
    Name: 'New Haven',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_KS_20451', Name: 'Linn', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_20452',
    Name: 'Stonerstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_20453', Name: 'Huxley', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_GA_20454',
    Name: 'Woodville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_TN_20455',
    Name: 'Sardis',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_OH_20456', Name: 'Tontogany', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_GA_20457', Name: 'Salem', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_PA_20458',
    Name: 'Wattsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_20459', Name: 'Union', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OK_20460',
    Name: 'Mountain Park',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_AL_20461',
    Name: 'Thomaston',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_KY_20462',
    Name: 'Wayland',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_FL_20463',
    Name: 'Acacia Villas',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OH_20464', Name: 'Willshire', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MD_20465',
    Name: 'Laytonsville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_20466', Name: 'Banquete', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_20467',
    Name: 'Ocean Breeze',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IL_20468', Name: 'Bonnie', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_SC_20469',
    Name: 'Greeleyville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_TN_20470',
    Name: 'Hillsboro',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_20471',
    Name: 'Tharptown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_20472',
    Name: 'Brookford',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_KS_20473',
    Name: 'New Strawn',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_MO_20474',
    Name: 'Atlanta',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_20475',
    Name: 'Southview',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_20476',
    Name: 'Shannon',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_GA_20477',
    Name: 'Mineral Bluff',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CO_20478',
    Name: 'Midland',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_OH_20479',
    Name: 'Beallsville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MS_20480',
    Name: 'Duncan',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_OK_20481',
    Name: 'Custer City',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WV_20482',
    Name: 'Windsor Heights',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_GA_20483', Name: 'Ambrose', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NC_20484',
    Name: 'Bostic',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_KS_20485', Name: 'Altoona', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_CA_20486',
    Name: 'Centerville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NM_20487',
    Name: 'Paguate',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MO_20488',
    Name: 'Chamois',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MO_20489',
    Name: 'Westphalia',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MN_20490',
    Name: 'Emmons',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TN_20491',
    Name: 'Clarksburg',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_KS_20492', Name: 'Dearing', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_MO_20493', Name: 'Bunker', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_AK_20494', Name: 'Hydaburg', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_PA_20495',
    Name: 'Callery',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_20496',
    Name: 'Cantua Creek',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_20497',
    Name: 'Windthorst',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OK_20498', Name: 'Bell', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_UT_20499', Name: 'Alta', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_AK_20500', Name: 'Kivalina', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_KS_20501', Name: 'Almena', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OH_20502', Name: 'Rutland', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PR_20503',
    Name: 'Hato Arriba',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_PA_20504',
    Name: 'Indian Lake',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CO_20505',
    Name: 'Louviers',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_TX_20506', Name: 'Turkey', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OK_20507', Name: 'Foyil', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_ND_20508',
    Name: 'Maddock',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NH_20509',
    Name: 'North Woodstock',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {Id: 'CIT_US_IA_20510', Name: 'Bennett', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_20511',
    Name: 'Montrose',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MN_20512',
    Name: 'Soudan',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_20513', Name: 'Bruni', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MI_20514',
    Name: 'Otter Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OH_20515', Name: 'Bowerston', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_20516',
    Name: 'Crenshaw',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_20517',
    Name: 'Fairview',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IA_20518', Name: 'Orient', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NE_20519',
    Name: 'Plymouth',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NY_20520',
    Name: 'Smithville Flats',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_GA_20521', Name: 'Dillard', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_LA_20522',
    Name: 'Bordelonville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_TN_20523',
    Name: 'Williston',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_KS_20524', Name: 'Quenemo', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_20525',
    Name: 'Middleport',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NE_20526',
    Name: 'Big Springs',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_FL_20527',
    Name: 'Garcon Point',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IN_20528', Name: 'Carbon', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_MS_20529',
    Name: 'Merigold',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_GA_20530',
    Name: 'Centralhatchee',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_OK_20531',
    Name: 'Peavine',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MD_20532',
    Name: 'East New Market',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_IA_20533', Name: 'Colesburg', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_20534',
    Name: 'Fountain Springs',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OH_20535',
    Name: 'Grover Hill',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_MI_20536', Name: 'Empire', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_AK_20537', Name: 'Tazlina', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_GA_20538',
    Name: 'Jenkinsburg',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_NE_20539', Name: 'Loomis', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_OH_20540',
    Name: 'Newtonsville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_LA_20541',
    Name: 'North Hodge',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_20542',
    Name: 'Westover',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_20543', Name: 'Hermleigh', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_20544',
    Name: 'London Mills',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_KY_20545',
    Name: 'Bandana',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_GA_20546', Name: 'Empire', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MO_20547',
    Name: 'Macks Creek',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MI_20548',
    Name: 'Whittemore',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_ND_20549',
    Name: 'Alexander',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_OH_20550', Name: 'Adelphi', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_20551',
    Name: 'Vintondale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AZ_20552', Name: 'Wenden', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_TX_20553',
    Name: 'San Patricio',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_KY_20554',
    Name: 'Trenton',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_AR_20555',
    Name: 'Sparkman',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MS_20556',
    Name: 'Hamilton',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MN_20557',
    Name: 'Hanska',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_GA_20558', Name: 'Rhine', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_MI_20559', Name: 'Akron', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_KS_20560', Name: 'Melvern', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_TN_20561',
    Name: 'Watauga',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NC_20562',
    Name: 'Forest Hills',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IA_20563', Name: 'Mondamin', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_20564',
    Name: 'Stirling City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_LA_20565',
    Name: 'Vienna',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PR_20566',
    Name: 'Tibes',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MN_20567',
    Name: 'Ogilvie',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_20568',
    Name: 'Laguna Seca',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_DE_20569', Name: 'Bowers', stateId: 'DE', stateName: 'Delaware'},
  {
    Id: 'CIT_US_MN_20570',
    Name: 'Morton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MN_20571',
    Name: 'Medicine Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_20572',
    Name: 'Barrville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_20573', Name: 'Between', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_AR_20574', Name: 'Cove', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_NE_20575', Name: 'Eustis', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_IA_20576', Name: 'Letts', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_20577',
    Name: 'West Falls',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_UT_20578', Name: 'Deweyville', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NY_20579',
    Name: 'Gilbertsville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_20580',
    Name: 'Tehama',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_20581',
    Name: 'Smithton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_UT_20582', Name: 'Wales', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MN_20583',
    Name: 'Pine Point',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_20584',
    Name: 'Grays Prairie',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_KS_20585',
    Name: 'Mound Valley',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_MT_20586', Name: 'Ekalaka', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_NC_20587',
    Name: 'Rennert',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OK_20588', Name: 'Optima', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_CO_20589', Name: 'Chacra', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_OK_20590', Name: 'Coyle', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_IA_20591',
    Name: 'Columbus City',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_OR_20592',
    Name: 'Rivergrove',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_NE_20593', Name: 'Ewing', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_TX_20594', Name: 'Bonney', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NM_20595',
    Name: 'Pinos Altos',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_GA_20596', Name: 'Barwick', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NY_20597',
    Name: 'Farnham',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_KS_20598', Name: 'Turon', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_20599',
    Name: 'Berrysburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_20600', Name: 'Beaverdam', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MO_20601',
    Name: 'Gordonville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_FL_20602',
    Name: 'Spring Ridge',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_OK_20603', Name: 'Gowen', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_GA_20604', Name: 'Leslie', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NE_20605',
    Name: 'Orleans',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MI_20606',
    Name: 'Elberta',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IN_20607',
    Name: 'Sulphur Springs',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_AL_20608', Name: 'Maytown', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_KS_20609', Name: 'Hartford', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_20610',
    Name: 'Virginville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OR_20611',
    Name: 'Seventh Mountain',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_VA_20612', Name: 'Draper', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_CA_20613',
    Name: 'Meridian',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OK_20614', Name: 'Dustin', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_IA_20615', Name: 'Ringsted', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_NY_20616', Name: 'Gilgo', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_NC_20617',
    Name: 'Hamilton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NE_20618',
    Name: 'Lakeview',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_AK_20619', Name: 'Napakiak', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_WI_20620',
    Name: 'Embarrass',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MI_20621',
    Name: 'Henderson',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MD_20622',
    Name: 'Zihlman',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_ND_20623',
    Name: 'Manvel',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NE_20624',
    Name: 'Fairfield',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_KY_20625',
    Name: 'Hustonville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NY_20626',
    Name: 'Redford',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_SD_20627',
    Name: 'White Lake',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_SD_20628',
    Name: 'Buffalo',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NC_20629',
    Name: 'Cofield',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_20630',
    Name: 'Hickory Hills',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NE_20631',
    Name: 'Nickerson',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_NE_20632', Name: 'Duncan', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_MO_20633', Name: 'Pomona', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_ID_20634', Name: 'Declo', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_TX_20635', Name: 'Abbott', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_UT_20636', Name: 'Samak', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NC_20637',
    Name: 'Bell Arthur',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NV_20638',
    Name: 'Mount Charleston',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_PA_20639',
    Name: 'Crucible',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_20640', Name: 'Blanket', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NM_20641',
    Name: 'Seama',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MD_20642',
    Name: 'Deer Park',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_VA_20643', Name: 'Clover', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_MS_20644',
    Name: 'Holcomb',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_20645',
    Name: 'Mount Aetna',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_20646',
    Name: 'St. Maurice',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_AL_20647', Name: 'Mentone', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_OK_20648',
    Name: 'North Miami',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_IA_20649', Name: 'Donahue', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_20650',
    Name: 'St. Petersburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_20651',
    Name: 'Davenport',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_20652',
    Name: 'Round Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IL_20653', Name: 'Xenia', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MI_20654',
    Name: 'Bear Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MI_20655',
    Name: 'Carp Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_MI_20656', Name: 'Snover', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_VA_20657', Name: 'Goshen', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_IA_20658', Name: 'Holy Cross', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_20659',
    Name: 'Sparland',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_KY_20660',
    Name: 'Grand Rivers',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_OK_20661',
    Name: 'Kaw City',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_GA_20662',
    Name: 'Bronwood',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_KY_20663', Name: 'Ages', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_CO_20664', Name: 'Boone', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_NE_20665',
    Name: 'Giltner',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_OH_20666', Name: 'Friendship', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_20667',
    Name: 'Bagley',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_20668',
    Name: 'Arenzville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WV_20669',
    Name: 'Bud',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_20670',
    Name: 'Madison',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_20671',
    Name: 'Belgium',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_20672',
    Name: 'Mexico',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NV_20673',
    Name: 'Lakeridge',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_MD_20674',
    Name: 'Fairmount',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_NY_20675', Name: 'Dalton', stateId: 'NY', stateName: 'New York'},
  {Id: 'CIT_US_AK_20676', Name: 'Koyuk', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_OH_20677', Name: 'Sterling', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_KY_20678',
    Name: 'Glencoe',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_KS_20679', Name: 'Wetmore', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_VA_20680', Name: 'Bloxom', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_WV_20681',
    Name: 'Verdunville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WV_20682',
    Name: 'Capon Bridge',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MO_20683',
    Name: 'Bellflower',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_FL_20684',
    Name: 'Sorrento',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NE_20685',
    Name: 'Kennard',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_KY_20686',
    Name: 'Old Brownsboro Place',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MO_20687',
    Name: 'Wheatland',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_20688',
    Name: 'Lake Almanor Peninsula',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SD_20689',
    Name: 'Hoven',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_OK_20690',
    Name: 'Bennington',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_MT_20691', Name: 'Turah', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_GA_20692',
    Name: 'Arnoldsville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_MO_20693', Name: 'Sibley', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_AR_20694',
    Name: 'Woodson',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_LA_20695',
    Name: 'Columbia',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_MN_20696', Name: 'Ghent', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_TX_20697',
    Name: 'South Mountain',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MN_20698',
    Name: 'Underwood',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OK_20699', Name: 'Silo', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_WV_20700',
    Name: 'Farmington',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_OH_20701', Name: 'Pitsburg', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OH_20702',
    Name: 'Ridgeville Corners',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MO_20703',
    Name: 'Northmoor',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OH_20704',
    Name: 'Bailey Lakes',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_IA_20705', Name: 'Wesley', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NM_20706',
    Name: 'Rincon',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_KY_20707',
    Name: 'Woodburn',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WV_20708',
    Name: 'New Richmond',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_AR_20709',
    Name: 'Center Ridge',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_ID_20710', Name: 'Arimo', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_OR_20711', Name: 'Westport', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_ND_20712',
    Name: 'McClusky',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_CA_20713',
    Name: 'Plumas Eureka',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AR_20714',
    Name: 'Pleasant Plains',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_IL_20715',
    Name: 'Good Hope',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_20716',
    Name: 'Bethania',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OH_20717',
    Name: 'South Solon',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_20718',
    Name: 'Bradenville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IL_20719', Name: 'Latham', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_VA_20720',
    Name: 'King William',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_IA_20721', Name: 'Churdan', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WV_20722',
    Name: 'West Logan',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_OH_20723', Name: 'Clarington', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_20724',
    Name: 'Centerport',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_20725',
    Name: 'Newfolden',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_NE_20726', Name: 'Merna', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_PA_20727',
    Name: 'Ben Avon Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_20728',
    Name: 'Coyote Flats',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_20729', Name: 'New Home', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WV_20730',
    Name: 'Ellenboro',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IL_20731',
    Name: 'Langleyville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_AR_20732', Name: 'Havana', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_IL_20733', Name: 'Boody', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MI_20734',
    Name: 'Gagetown',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_AR_20735', Name: 'Dyess', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_MN_20736',
    Name: 'McGregor',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_SD_20737',
    Name: 'Bullhead',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MD_20738',
    Name: 'Clear Spring',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_IA_20739', Name: 'Nichols', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_20740', Name: 'Livermore', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_20741',
    Name: 'Alto Pass',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_20742', Name: 'Remington', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_ND_20743',
    Name: 'Strasburg',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_KY_20744',
    Name: 'Sadieville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WV_20745',
    Name: 'Red Jacket',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_AR_20746', Name: 'Norman', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_MN_20747',
    Name: 'Alvarado',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_KY_20748',
    Name: 'Hardyville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IA_20749', Name: 'Plymouth', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_20750',
    Name: 'Taneyville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PR_20751',
    Name: 'Parcelas Mandry',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_PA_20752',
    Name: 'Rockhill',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_20753',
    Name: 'Rosebush',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_GA_20754',
    Name: 'Sharpsburg',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_AR_20755',
    Name: 'Western Grove',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_IA_20756', Name: 'Charlotte', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_WI_20757', Name: 'Pound', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_OR_20758', Name: 'Umapine', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_OH_20759',
    Name: 'Port Jefferson',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WA_20760',
    Name: 'Easton',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_20761',
    Name: 'La Valle',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WV_20762',
    Name: 'Racine',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_AK_20763',
    Name: 'King Salmon',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_WI_20764',
    Name: 'Lake Sherwood',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_VA_20765',
    Name: 'Nickelsville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_LA_20766',
    Name: 'Chataignier',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MN_20767',
    Name: 'Cuyuna',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AR_20768',
    Name: 'Clarkedale',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_WI_20769',
    Name: 'Rock Springs',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_DE_20770', Name: 'Odessa', stateId: 'DE', stateName: 'Delaware'},
  {
    Id: 'CIT_US_LA_20771',
    Name: 'Spokane',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_AR_20772', Name: 'Cash', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_WV_20773',
    Name: 'Glen White',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_GA_20774',
    Name: 'Yatesville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NE_20775',
    Name: 'Cedar Rapids',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_VA_20776', Name: 'Bland', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_IA_20777', Name: 'Fertile', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NY_20778',
    Name: 'Central Bridge',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_SD_20779',
    Name: 'Florence',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IA_20780', Name: 'Garrison', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NJ_20781',
    Name: 'Pedricktown',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_NE_20782',
    Name: 'Pleasanton',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NM_20783',
    Name: 'Newcomb',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IL_20784',
    Name: 'Jeffersonville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_LA_20785',
    Name: 'Fenton',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_20786',
    Name: 'Mineral',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_20787',
    Name: 'Spring Mills',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_20788',
    Name: 'Wardell',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_ND_20789',
    Name: 'Fairmount',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_OK_20790',
    Name: 'Wanette',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_GA_20791', Name: 'Ila', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NC_20792',
    Name: 'Centerville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_KY_20793', Name: 'Belfry', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_OK_20794', Name: 'Carmen', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_SD_20795',
    Name: 'Chester',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_UT_20796', Name: 'Joseph', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_AK_20797',
    Name: 'Trapper Creek',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_OK_20798',
    Name: 'Grandview',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_OH_20799', Name: 'Irondale', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MN_20800',
    Name: 'Mahtowa',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_NE_20801', Name: 'Pilger', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_KY_20802',
    Name: 'Hunters Hollow',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_KY_20803',
    Name: 'Gamaliel',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NY_20804',
    Name: 'Mariaville Lake',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_20805',
    Name: 'Wilcox',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IN_20806', Name: 'Amboy', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PA_20807',
    Name: 'Glendon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MI_20808', Name: 'Gaines', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MO_20809',
    Name: 'Bunceton',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OH_20810',
    Name: 'Tremont City',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_LA_20811',
    Name: 'South Mansfield',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MO_20812',
    Name: 'Henrietta',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NE_20813',
    Name: 'Silver Creek',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_AK_20814', Name: 'Nenana', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_VA_20815',
    Name: 'Iron Gate',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_GA_20816',
    Name: 'Cobbtown',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MO_20817',
    Name: 'Gilman City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NY_20818',
    Name: 'Limestone',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_20819',
    Name: 'Chewton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MD_20820',
    Name: 'St. George Island',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_AR_20821', Name: 'Viola', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_WI_20822',
    Name: 'Fairwater',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TN_20823',
    Name: 'Walland',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_TN_20824',
    Name: 'Woodland Mills',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_OR_20825', Name: 'Adams', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_CA_20826',
    Name: 'Edmundson Acres',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_20827',
    Name: 'Cerulean',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_TX_20828',
    Name: 'Brazos Bend',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IL_20829', Name: 'Ransom', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OK_20830', Name: 'Rose', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NC_20831',
    Name: 'Atkinson',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MS_20832',
    Name: 'Tishomingo',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_FL_20833', Name: 'Esto', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_UT_20834', Name: 'Vernon', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_KS_20835', Name: 'Rolla', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IL_20836',
    Name: 'Scales Mound',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_AL_20837', Name: 'Vina', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_PA_20838',
    Name: 'Lewisberry',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_GA_20839', Name: 'Satilla', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_IL_20840', Name: 'Ludlow', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_IN_20841',
    Name: 'Russellville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IL_20842',
    Name: 'Bonfield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_VA_20843', Name: 'Sussex', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_IN_20844', Name: 'Norway', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_TX_20845',
    Name: 'Cashion Community',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CA_20846',
    Name: 'Trinidad',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_20847',
    Name: 'Crystal Lakes',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_20848', Name: 'Green Camp', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IL_20849', Name: 'Secor', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_AR_20850', Name: 'Enola', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_GA_20851',
    Name: 'Pinehurst',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_IL_20852', Name: 'Sigel', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MN_20853',
    Name: 'Badger',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_20854',
    Name: 'Smoketown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_20855', Name: 'Vanlue', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IL_20856', Name: 'Olivet', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_WI_20857',
    Name: 'Butternut',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TN_20858',
    Name: 'Bethpage',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_AZ_20859', Name: 'Cibola', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_MO_20860',
    Name: 'Mineral Point',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OK_20861', Name: 'Katie', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_OH_20862', Name: 'Athalia', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_SD_20863',
    Name: 'Blunt',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_MN_20864', Name: 'Milan', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_OK_20865',
    Name: 'Jennings',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MN_20866',
    Name: 'Belview',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_20867',
    Name: 'Scottsville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NY_20868',
    Name: 'Conesus Hamlet',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_20869',
    Name: 'Christoval',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_MT_20870', Name: 'Boneau', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_KY_20871',
    Name: 'Freeburn',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_AL_20872', Name: 'Malcolm', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_IA_20873', Name: 'Pierson', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MN_20874',
    Name: 'Comfrey',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OH_20875', Name: 'Hooven', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MD_20876',
    Name: 'Sabillasville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WI_20877',
    Name: 'Birch Hill',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MN_20878', Name: 'Askov', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_MN_20879',
    Name: 'Darwin',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MO_20880',
    Name: 'Breckenridge',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_AR_20881', Name: 'Grubbs', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_GA_20882',
    Name: 'Woodland',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_TX_20883', Name: 'Palisades', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_VA_20884', Name: 'Gwynn', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_MI_20885',
    Name: 'Barryton',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_VA_20886',
    Name: 'Pocahontas',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_20887',
    Name: 'Cumbola',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CO_20888',
    Name: 'Sugarloaf',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_IN_20889',
    Name: 'Mooreland',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IA_20890', Name: 'Minburn', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_20891',
    Name: 'Raymondville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OH_20892', Name: 'Mowrystown', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NE_20893',
    Name: 'Hildreth',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_IA_20894', Name: 'Lacona', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WI_20895',
    Name: 'White Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WI_20896',
    Name: 'Scandinavia',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_SD_20897',
    Name: 'Renner Corner',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MN_20898',
    Name: 'Vergas',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WA_20899',
    Name: 'Eschbach',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NE_20900',
    Name: 'Rising City',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_PA_20901',
    Name: 'Mahaffey',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_20902',
    Name: 'New Galilee',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_20903',
    Name: 'Anchor Bay',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_HI_20904',
    Name: 'Pakala Village',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_AZ_20905',
    Name: 'Winkelman',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_MD_20906',
    Name: 'Mardela Springs',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_IN_20907',
    Name: 'East Germantown',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NJ_20908',
    Name: 'Anderson',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_MD_20909', Name: 'Shaft', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_TX_20910',
    Name: 'Fronton Ranchettes',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MO_20911',
    Name: 'Downing',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MN_20912',
    Name: 'Calumet',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_20913',
    Name: 'Oberlin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_20914',
    Name: 'Prairie City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MT_20915', Name: 'Sangrey', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_OK_20916', Name: 'Grant', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_PA_20917',
    Name: 'Elrama',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_20918',
    Name: 'White Oak',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MD_20919',
    Name: 'Deal Island',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_SC_20920',
    Name: 'Bethune',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_ND_20921',
    Name: 'St. John',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_CO_20922',
    Name: 'Coaldale',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PA_20923',
    Name: 'Ford Cliff',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MD_20924',
    Name: 'Tolchester',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_20925', Name: 'Thompsons', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_20926',
    Name: 'Clarksburg',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_20927',
    Name: 'Belle Rive',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IN_20928',
    Name: 'Lewisville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_NE_20929', Name: 'Roca', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_MN_20930',
    Name: 'Garfield',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_20931',
    Name: 'Manton',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_UT_20932',
    Name: 'Castle Valley',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_WY_20933',
    Name: 'Carpenter',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_CA_20934',
    Name: 'Rio Oso',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_20935', Name: 'Freeport', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_20936',
    Name: 'Buckhorn',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OK_20937',
    Name: 'Titanic',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WI_20938',
    Name: 'Wheeler',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_ND_20939',
    Name: 'Max',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NY_20940',
    Name: 'Wainscott',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_20941', Name: 'Trent', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_20942', Name: 'Cumberland', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_20943',
    Name: 'Warrens',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_AR_20944',
    Name: 'Fredonia',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CA_20945',
    Name: 'Nipinnawasee',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_KS_20946', Name: 'Lowell', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_NE_20947', Name: 'Allen', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_WV_20948',
    Name: 'Kermit',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IN_20949',
    Name: 'Montmorenci',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IL_20950',
    Name: 'Ellis Grove',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MS_20951',
    Name: 'Puckett',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_CA_20952', Name: 'Mesa', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_ND_20953',
    Name: 'Gladstone',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_OR_20954',
    Name: 'Jeffers Gardens',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MN_20955',
    Name: 'St. Martin',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_20956',
    Name: 'Laceyville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_20957',
    Name: 'Valley Grove',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NM_20958',
    Name: 'Chamisal',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_CA_20959',
    Name: 'Oak Shores',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VA_20960',
    Name: 'Powhatan',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_IA_20961', Name: 'Menlo', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_AR_20962', Name: 'Wilton', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_IL_20963', Name: 'Hume', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MN_20964',
    Name: 'Dexter',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IL_20965', Name: 'Varna', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NY_20966',
    Name: 'Bemus Point',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AZ_20967',
    Name: 'Del Muerto',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_TX_20968', Name: 'Bellevue', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_GA_20969', Name: 'Turin', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MI_20970',
    Name: 'Ellsworth',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_20971',
    Name: 'Dawson',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_HI_20972',
    Name: 'Kukuihaele',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_MO_20973',
    Name: 'Mindenmines',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_20974', Name: 'Leroy', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NM_20975',
    Name: 'San Mateo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_OH_20976', Name: 'Somerville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_MI_20977', Name: 'Hersey', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_IL_20978',
    Name: 'New Canton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_20979',
    Name: 'Jeffers',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_20980',
    Name: 'Dobbins',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_20981', Name: 'Ryan', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_20982',
    Name: 'Arona',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_20983', Name: 'Rudd', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_20984',
    Name: 'St. Mary',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_KS_20985',
    Name: 'South Haven',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_WV_20986',
    Name: 'Meadow Bridge',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_CA_20987',
    Name: 'Grangeville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_20988',
    Name: 'St. Elizabeth',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_KS_20989', Name: 'Mayetta', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NE_20990',
    Name: 'Newcastle',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_IA_20991', Name: 'Callender', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WI_20992',
    Name: 'Krakow',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_20993',
    Name: 'Defiance',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MT_20994',
    Name: 'South Glastonbury',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_PA_20995',
    Name: 'St. Michael',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_NE_20996', Name: 'Wilcox', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_IA_20997', Name: 'Wahpeton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NM_20998',
    Name: 'Brimhall Nizhoni',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_WI_20999',
    Name: 'Friesland',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WI_21000',
    Name: 'Dallas',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IA_21001', Name: 'Lohrville', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AR_21002',
    Name: 'Bigelow',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_OR_21003',
    Name: 'Sodaville',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_NJ_21004',
    Name: 'Harvey Cedars',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MO_21005',
    Name: 'Pattonsburg',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_21006',
    Name: 'Grand Detour',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SD_21007',
    Name: 'Hudson',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NE_21008',
    Name: 'Orchard',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_MI_21009', Name: 'Beulah', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_AL_21010', Name: 'Arley', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_KY_21011',
    Name: 'Betsy Layne',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NM_21012',
    Name: 'Crystal',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MN_21013',
    Name: 'Maynard',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IA_21014', Name: 'Spillville', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OK_21015', Name: 'Agra', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_TX_21016', Name: 'Channing', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_NE_21017', Name: 'Clarks', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_OH_21018', Name: 'Marlboro', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_21019',
    Name: 'Granton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_21020',
    Name: 'Corsica',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NE_21021',
    Name: 'Wallace',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IA_21022',
    Name: 'Farnhamville',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_GA_21023',
    Name: 'Sale City',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_AL_21024',
    Name: 'Napier Field',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MN_21025',
    Name: 'Canton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WA_21026',
    Name: 'Uniontown',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OK_21027', Name: 'Fargo', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NV_21028',
    Name: 'Fort McDermitt',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_IN_21029',
    Name: 'Universal',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_WI_21030',
    Name: 'Nelson',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IN_21031',
    Name: 'Clear Lake',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IL_21032',
    Name: 'Bellflower',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_21033', Name: 'Cincinnati', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MT_21034', Name: 'Frazer', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_NY_21035',
    Name: 'Castorland',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IL_21036', Name: 'Orient', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_KY_21037',
    Name: 'Big Clifty',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_SD_21038',
    Name: 'Keystone',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_AK_21039',
    Name: 'Russian Mission',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_IA_21040', Name: 'Lorimor', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_ND_21041',
    Name: 'Neche',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_KY_21042',
    Name: 'Wallins Creek',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_AR_21043',
    Name: 'Emerson',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MT_21044',
    Name: 'St. Pierre',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_IN_21045',
    Name: 'Earl Park',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AK_21046',
    Name: 'Anaktuvuk Pass',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_IL_21047',
    Name: 'Martinton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_21048', Name: 'Conesville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_21049',
    Name: 'Mansfield',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IA_21050', Name: 'Clearfield', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_21051',
    Name: 'Apple River',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_21052',
    Name: 'Annapolis',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_21053',
    Name: 'Lawrence',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_21054',
    Name: 'Grantfork',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IN_21055',
    Name: 'New Ross',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_MT_21056', Name: 'Evaro', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_MN_21057',
    Name: 'Swanville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_MT_21058', Name: 'Simms', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_OR_21059',
    Name: 'Fair Oaks',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MN_21060',
    Name: 'Russell',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_SC_21061',
    Name: 'Neeses',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_IA_21062',
    Name: 'Pacific Junction',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_GA_21063',
    Name: 'White Plains',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MO_21064',
    Name: 'Creighton',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_21065',
    Name: 'Mount Gretna Heights',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_21066',
    Name: 'Tennyson',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NY_21067',
    Name: 'Greigsville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_21068', Name: 'Iredell', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MI_21069', Name: 'Alba', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_WY_21070',
    Name: 'Burlington',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_WA_21071',
    Name: 'Wallula',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_TX_21072',
    Name: "Morgan's Point",
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_KS_21073', Name: 'Scandia', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_TX_21074',
    Name: 'Darrouzett',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IA_21075',
    Name: 'Libertyville',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_KY_21076',
    Name: 'Strathmoor Manor',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_TX_21077',
    Name: 'Enchanted Oaks',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_AK_21078', Name: 'Elim', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_OK_21079', Name: 'Briggs', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_AL_21080', Name: 'Nectar', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_IL_21081',
    Name: 'St. Peter',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_21082', Name: 'Ney', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IN_21083',
    Name: 'New Point',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OH_21084', Name: 'Marengo', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AR_21085', Name: 'Branch', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_IL_21086', Name: 'Malden', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_21087', Name: 'Harrisburg', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_ID_21088', Name: 'Dietrich', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_VA_21089',
    Name: 'Fincastle',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_OH_21090', Name: 'Buffalo', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_21091',
    Name: 'Cameron',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_SC_21092',
    Name: 'Patrick',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_IA_21093', Name: 'Danbury', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_NE_21094', Name: 'Santee', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_CA_21095',
    Name: 'Cazadero',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MI_21096',
    Name: 'Lost Lake Woods',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CO_21097',
    Name: 'Olney Springs',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_IL_21098',
    Name: 'Baldwin',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_AK_21099', Name: 'Anderson', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_AZ_21100',
    Name: 'Clacks Canyon',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_FL_21101',
    Name: 'Chokoloskee',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_FL_21102', Name: 'Gardner', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_RI_21103',
    Name: 'Wyoming',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {
    Id: 'CIT_US_CA_21104',
    Name: 'Cressey',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NY_21105',
    Name: 'Clayville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_SC_21106',
    Name: 'Ruby',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_AR_21107',
    Name: 'Biggers',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MN_21108',
    Name: 'Ceylon',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_21109',
    Name: 'Camptonville',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_21110', Name: 'Ute', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MT_21111',
    Name: 'Wineglass',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_ND_21112',
    Name: 'Stanton',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_IL_21113',
    Name: 'Fayetteville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WV_21114',
    Name: 'Wallace',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_VA_21115',
    Name: 'Eastville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_TX_21116',
    Name: 'Mount Calm',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_21117',
    Name: 'Bulger',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_21118',
    Name: 'Burnettsville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AR_21119',
    Name: 'Mitchellville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_TX_21120', Name: 'Zuehl', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_21121',
    Name: 'Howardville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_KY_21122',
    Name: 'Sharpsburg',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_AK_21123', Name: 'Kiana', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_OH_21124', Name: 'Wharton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_21125',
    Name: 'Franklin',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AZ_21126', Name: 'Chuichu', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_PA_21127',
    Name: 'New Albany',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_21128',
    Name: 'El Nido',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_21129', Name: 'Amana', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_TN_21130',
    Name: 'Flat Top Mountain',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NE_21131',
    Name: 'Niobrara',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_OH_21132', Name: 'Aquilla', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TX_21133',
    Name: 'La Paloma Addition',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NJ_21134',
    Name: 'East Rocky Hill',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OH_21135', Name: 'Vincent', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WV_21136',
    Name: 'Davy',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WA_21137',
    Name: 'Aberdeen Gardens',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_OR_21138',
    Name: 'Butteville',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_AR_21139',
    Name: 'Twin Groves',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_GA_21140',
    Name: 'Scotland',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_KS_21141', Name: 'Gorham', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_21142',
    Name: 'Kratzerville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AZ_21143', Name: 'So-Hi', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_UT_21144', Name: 'Cornish', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_LA_21145',
    Name: 'Forest',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_IN_21146',
    Name: 'Stilesville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PA_21147',
    Name: 'Sandy Ridge',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_WY_21148', Name: 'Nordic', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_NM_21149',
    Name: 'Canjilon',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_WV_21150',
    Name: 'Bramwell',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_MT_21151', Name: 'Wilsall', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_OR_21152',
    Name: 'Winchester Bay',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_IL_21153',
    Name: 'Broadlands',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_21154',
    Name: 'Bricelyn',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IN_21155',
    Name: 'Freetown',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_MO_21156', Name: 'Tracy', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_CO_21157',
    Name: 'North La Junta',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_WV_21158',
    Name: 'Monaville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_FL_21159',
    Name: 'Goodland',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MO_21160',
    Name: 'Lake Lafayette',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MI_21161',
    Name: 'Beal City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_FL_21162', Name: 'Brooker', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_WV_21163',
    Name: 'Northfork',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_IA_21164', Name: 'Terril', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NY_21165',
    Name: 'Jeffersonville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_UT_21166', Name: 'Bicknell', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_VA_21167',
    Name: 'Claremont',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NE_21168',
    Name: 'Platte Center',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_IL_21169', Name: 'Mason', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_ND_21170',
    Name: 'Bowbells',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_OK_21171',
    Name: 'Bridge Creek',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_KY_21172',
    Name: 'Belleview',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_FL_21173', Name: 'Trilby', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_21174',
    Name: 'Muir Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KY_21175',
    Name: 'Mount Olivet',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IA_21176', Name: 'Massena', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WV_21177',
    Name: 'Reader',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_TX_21178',
    Name: 'Villa del Sol',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_HI_21179', Name: 'Maunaloa', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_NM_21180',
    Name: 'Edith Endave',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MS_21181',
    Name: 'Cruger',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_VA_21182',
    Name: 'Flint Hill',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MT_21183',
    Name: 'Drummond',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_PA_21184',
    Name: 'Newburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_21185',
    Name: 'Carbon Hill',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_21186',
    Name: 'Mannsville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MN_21187',
    Name: 'Rushmore',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_21188',
    Name: 'Elderton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_21189', Name: 'Middletown', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AZ_21190',
    Name: 'Tolani Lake',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_SC_21191',
    Name: 'Lynchburg',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_SC_21192',
    Name: 'Nichols',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CA_21193',
    Name: 'Clipper Mills',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OH_21194',
    Name: 'Camp Dennison',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MD_21195',
    Name: 'Chewsville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OK_21196', Name: 'Ballou', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_ND_21197',
    Name: 'Kulm',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_LA_21198',
    Name: 'Varnado',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TN_21199', Name: 'Toone', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_SC_21200',
    Name: 'West Union',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_KY_21201',
    Name: 'Buffalo',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_21202',
    Name: 'Blandburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_21203',
    Name: 'Tierra Grande',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_21204',
    Name: 'Manlius',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_21205', Name: 'Templeton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OK_21206',
    Name: 'Cleo Springs',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_MN_21207', Name: 'Orr', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_MO_21208', Name: 'Fisk', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MN_21209',
    Name: 'Lancaster',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AZ_21210',
    Name: 'Cane Beds',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_TX_21211', Name: 'Lueders', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_21212',
    Name: 'Altenburg',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MD_21213',
    Name: 'San Mar',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NY_21214',
    Name: 'Felts Mills',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_21215',
    Name: 'Windsor Place',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NM_21216',
    Name: 'Cotton City',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IN_21217',
    Name: 'New Richmond',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_WI_21218',
    Name: 'Maribel',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_KY_21219', Name: 'Hyden', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_ND_21220',
    Name: 'Grenora',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_GA_21221',
    Name: 'Keysville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MO_21222',
    Name: 'Kingston',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WA_21223',
    Name: 'Snoqualmie Pass',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OK_21224', Name: 'Lehigh', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_UT_21225', Name: 'Scipio', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_VA_21226',
    Name: 'Shipman',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_ND_21227',
    Name: 'Arthur',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MO_21228',
    Name: 'Williamsville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_21229',
    Name: 'Branchdale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MT_21230',
    Name: 'Sunburst',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_CO_21231',
    Name: 'Dinosaur',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_VA_21232', Name: 'Quinby', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_MI_21233', Name: 'Honor', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_PA_21234',
    Name: 'Tunnelhill',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_SC_21235',
    Name: 'Donalds',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_IN_21236',
    Name: 'Mount Summit',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MI_21237',
    Name: 'Montgomery',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_VA_21238',
    Name: 'White Stone',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_21239',
    Name: 'McVeytown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_21240',
    Name: 'Alexandria',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_21241',
    Name: 'Saltillo',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_21242',
    Name: 'West Waynesburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_21243',
    Name: 'Cubero',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_VA_21244',
    Name: 'Chase Crossing',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_21245',
    Name: 'West Liberty',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TN_21246',
    Name: 'Liberty',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_WI_21247', Name: 'Unity', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_KS_21248', Name: 'Delphos', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NE_21249',
    Name: 'Palisade',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MI_21250',
    Name: 'Clayton',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OH_21251', Name: 'Polk', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_NE_21252', Name: 'Weston', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_NC_21253',
    Name: 'Ruffin',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MD_21254',
    Name: 'Nanticoke',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_KY_21255', Name: 'Eubank', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_OK_21256',
    Name: 'Cedar Valley',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WI_21257',
    Name: 'Reserve',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_SD_21258',
    Name: 'Pine Lakes Addition',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_OK_21259',
    Name: 'Indiahoma',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_UT_21260', Name: 'Koosharem', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_KY_21261',
    Name: 'Glenview Hills',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OH_21262', Name: 'Dellroy', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OK_21263',
    Name: 'Sand Hill',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_OH_21264', Name: 'Bascom', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TX_21265',
    Name: 'Pleasant Valley',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_OH_21266',
    Name: 'Bowersville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_OH_21267',
    Name: 'New Marshfield',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_21268',
    Name: 'Frystown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AR_21269', Name: 'Colt', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_IN_21270',
    Name: 'Bloomingdale',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MT_21271',
    Name: 'Starr School',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_AL_21272', Name: 'Epes', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_KS_21273', Name: 'Hope', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IL_21274',
    Name: 'Raleigh',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_21275',
    Name: 'Roberts',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_21276',
    Name: 'Grey Eagle',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OH_21277', Name: 'Ridgeway', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OK_21278',
    Name: 'Arpelar',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_UT_21279', Name: 'Meadow', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_VT_21280',
    Name: 'West Burke',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {Id: 'CIT_US_IN_21281', Name: 'Dupont', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_OH_21282', Name: 'Millfield', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_21283', Name: 'Midway', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IL_21284', Name: 'Nebo', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MO_21285',
    Name: 'Portage Des Sioux',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_ID_21286',
    Name: 'Rockford Bay',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {Id: 'CIT_US_OR_21287', Name: 'Ione', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_AL_21288', Name: 'Akron', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_FL_21289', Name: 'Lee', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_CA_21290',
    Name: 'Benton',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_21291',
    Name: 'Millbrook',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NM_21292',
    Name: 'Sunshine',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_OR_21293', Name: 'Moro', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_KY_21294',
    Name: 'Salt Lick',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_TX_21295',
    Name: 'Morgan Farm',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_LA_21296',
    Name: 'Hester',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_IL_21297', Name: 'Virgil', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_KY_21298',
    Name: 'Bellewood',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CA_21299',
    Name: 'Twain',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SD_21300',
    Name: 'Little Eagle',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IN_21301', Name: 'Fulton', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_IL_21302', Name: 'Lee', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_OK_21303',
    Name: 'Evening Shade',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_AR_21304',
    Name: 'Dennard',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_21305',
    Name: 'Moshannon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_UT_21306', Name: 'Avon', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_GA_21307',
    Name: 'Chauncey',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_WA_21308', Name: 'Oso', stateId: 'WA', stateName: 'Washington'},
  {
    Id: 'CIT_US_OH_21309',
    Name: 'Cherry Fork',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IL_21310',
    Name: 'Beaverville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_ND_21311',
    Name: 'Sawyer',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_KY_21312',
    Name: 'Brownsboro Village',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OH_21313', Name: 'Kunkle', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NE_21314',
    Name: 'Johnson',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MO_21315',
    Name: 'Shoal Creek Drive',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MN_21316',
    Name: 'Nett Lake',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IN_21317', Name: 'Mecca', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_OK_21318', Name: 'Wakita', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_TN_21319',
    Name: 'McLemoresville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_AK_21320', Name: 'Adak', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_WI_21321',
    Name: 'Minocqua',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_SD_21322',
    Name: 'Langford',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_CO_21323',
    Name: 'Arboles',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_WA_21324',
    Name: 'Inchelium',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MN_21325',
    Name: 'Wilmont',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NC_21326',
    Name: 'Ivanhoe',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NV_21327',
    Name: 'Blue Diamond',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_PA_21328',
    Name: 'Grampian',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_21329', Name: 'Industry', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OK_21330', Name: 'Eakly', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_TX_21331', Name: 'Havana', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MI_21332',
    Name: 'Gaastra',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_WA_21333',
    Name: 'Skamokawa Valley',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_21334',
    Name: 'Lowell',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_21335',
    Name: 'Garden Prairie',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MS_21336',
    Name: 'Beulah',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_TX_21337', Name: 'Kennard', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_21338',
    Name: 'Seymour',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_21339',
    Name: 'Leasburg',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_FL_21340',
    Name: 'Berrydale',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NM_21341',
    Name: 'La Jara',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_WV_21342',
    Name: 'Spelter',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_AL_21343', Name: 'Gordon', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_OH_21344', Name: 'Hannibal', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NE_21345',
    Name: 'Stratton',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_FL_21346',
    Name: 'St. Marks',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MS_21347',
    Name: 'Lake',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NC_21348',
    Name: 'Beech Mountain',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_NV_21349', Name: 'Dyer', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_MS_21350',
    Name: 'Beauregard',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_GA_21351',
    Name: 'Hiltonia',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_KS_21352', Name: 'Gridley', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_CA_21353',
    Name: 'Fiddletown',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WA_21354',
    Name: 'Beaux Arts Village',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MN_21355',
    Name: 'New Munich',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OR_21356', Name: 'Parkdale', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_MI_21357', Name: 'Luther', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_OH_21358', Name: 'Waldo', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OK_21359',
    Name: 'Wyandotte',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_IA_21360',
    Name: 'Harpers Ferry',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_VT_21361',
    Name: 'Proctorsville',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_MD_21362',
    Name: 'West Pocomoke',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MO_21363',
    Name: 'Diggins',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_KS_21364', Name: 'Moline', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NY_21365',
    Name: 'Sagaponack',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MN_21366',
    Name: 'Riverland',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_21367', Name: 'Texhoma', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_21368',
    Name: 'Mize',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_AZ_21369', Name: 'Central', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_IL_21370',
    Name: 'Armington',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_21371', Name: 'Goodrich', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_NE_21372', Name: 'Lyman', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_NM_21373',
    Name: 'Jaconita',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_AR_21374',
    Name: 'Midland',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MO_21375',
    Name: 'Chilhowee',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WY_21376',
    Name: 'Meeteetse',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_GA_21377',
    Name: 'Morganton',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_OK_21378', Name: 'Greasy', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_MN_21379', Name: 'Oslo', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_AL_21380',
    Name: 'Coffeeville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MO_21381',
    Name: 'Laclede',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_SD_21382',
    Name: 'Bison',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MD_21383',
    Name: 'Rosemont',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_IN_21384',
    Name: 'Spring Grove',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_SD_21385',
    Name: 'Ethan',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_ND_21386',
    Name: 'McVille',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_PA_21387',
    Name: 'Aristes',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NE_21388',
    Name: 'Unadilla',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_KS_21389', Name: 'Bucyrus', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IL_21390',
    Name: 'Hanaford',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_21391',
    Name: 'Troxelville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_21392',
    Name: 'West Union',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TN_21393',
    Name: 'Copperhill',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MO_21394',
    Name: 'Amazonia',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WI_21395',
    Name: 'Helenville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_OK_21396',
    Name: 'Bluejacket',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MN_21397',
    Name: 'Deer Creek',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_LA_21398',
    Name: 'Gloster',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_21399', Name: 'Sanctuary', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_21400', Name: 'Thebes', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_IA_21401', Name: 'Sheldahl', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MI_21402',
    Name: 'Casnovia',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_NV_21403', Name: 'Skyland', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_MS_21404',
    Name: 'Arcola',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_TX_21405', Name: 'Lawn', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AZ_21406',
    Name: 'Kino Springs',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_IA_21407', Name: 'Williams', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MS_21408',
    Name: 'Coahoma',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_LA_21409',
    Name: 'Georgetown',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_AZ_21410',
    Name: 'Lazy Y U',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WI_21411',
    Name: 'Kingston',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_ID_21412', Name: 'Cambridge', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_AL_21413', Name: 'Dozier', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_UT_21414', Name: 'Sterling', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_OK_21415',
    Name: 'Fort Supply',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_KY_21416',
    Name: 'Creekside',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NE_21417',
    Name: 'Inglewood',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_FL_21418',
    Name: 'Pineland',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_OK_21419',
    Name: 'Francis',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_TX_21420', Name: 'Bayside', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AZ_21421', Name: 'Tonalea', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_SC_21422',
    Name: 'Clarks Hill',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_ND_21423',
    Name: 'Buxton',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_OH_21424',
    Name: 'East Liberty',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NM_21425',
    Name: 'Pulpotio Bareas',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IA_21426',
    Name: 'Fort Atkinson',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_IA_21427',
    Name: 'McCallsburg',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_IA_21428', Name: 'Brandon', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_TN_21429',
    Name: 'Cottontown',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_IA_21430', Name: 'Bronson', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OR_21431', Name: 'Imbler', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_NY_21432', Name: 'Leeds', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_CO_21433',
    Name: 'Catherine',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_OK_21434',
    Name: 'Ralston',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_AL_21435', Name: 'Rosa', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_WA_21436',
    Name: 'Spangle',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_21437',
    Name: 'Ranshaw',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IL_21438', Name: 'Panama', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NE_21439',
    Name: 'Shickley',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MD_21440',
    Name: 'Garretts Mill',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_MT_21441', Name: 'Dutton', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_MT_21442',
    Name: 'Brockton',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_IA_21443',
    Name: 'Panorama Park',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_OK_21444',
    Name: 'Mill Creek',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MI_21445',
    Name: 'Clifford',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_NM_21446', Name: 'Rowe', stateId: 'NM', stateName: 'New Mexico'},
  {
    Id: 'CIT_US_NE_21447',
    Name: 'Blue Springs',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_PA_21448',
    Name: 'Stoystown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_21449',
    Name: 'LaCrosse',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MO_21450',
    Name: 'Junction City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NM_21451',
    Name: 'Las Palomas',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_OK_21452',
    Name: 'Milburn',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NY_21453',
    Name: 'Glen Aubrey',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NC_21454',
    Name: 'Altamahaw',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_AK_21455', Name: 'Tuluksak', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_PA_21456',
    Name: 'Schellsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AL_21457', Name: 'Uriah', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_IA_21458', Name: 'Elliott', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_GA_21459',
    Name: 'Plainville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_IA_21460', Name: 'Delta', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NV_21461',
    Name: 'Searchlight',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_WV_21462',
    Name: 'Hico',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WI_21463',
    Name: 'Humbird',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_WY_21464', Name: 'Urie', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_MT_21465', Name: 'Augusta', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_MT_21466',
    Name: 'Turtle Lake',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MD_21467',
    Name: 'Downsville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WI_21468',
    Name: 'Kellnersville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NM_21469',
    Name: 'Tesuque Pueblo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_21470',
    Name: 'Mill Creek',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IN_21471', Name: 'Vernon', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_IN_21472',
    Name: 'Marshall',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_LA_21473',
    Name: 'Harrisonburg',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MI_21474',
    Name: 'De Tour Village',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_AR_21475', Name: 'Etowah', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_OH_21476', Name: 'Hoytville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_21477',
    Name: 'Staatsburg',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_MO_21478', Name: 'Kidder', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_OH_21479', Name: 'Holloway', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_21480', Name: 'Coupland', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NC_21481',
    Name: 'Hobgood',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_AR_21482',
    Name: 'Menifee',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_VA_21483',
    Name: 'Yorktown',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_AK_21484',
    Name: 'Nightmute',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_MN_21485', Name: 'Osage', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_MN_21486',
    Name: 'Garden City',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IA_21487', Name: 'Diagonal', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_21488',
    Name: 'Cromberg',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_GA_21489', Name: 'Fargo', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WI_21490',
    Name: 'Wabeno',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MD_21491',
    Name: 'Betterton',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_AZ_21492',
    Name: 'Steamboat',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_PA_21493',
    Name: 'Blooming Valley',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_21494',
    Name: 'Burdett',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OK_21495', Name: 'Kinta', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_VA_21496',
    Name: 'Makemie Park',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_AK_21497', Name: 'McGrath', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_VA_21498', Name: 'Ivor', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_SD_21499',
    Name: 'Marty',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_OH_21500',
    Name: 'Fayetteville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_21501',
    Name: 'Kistler',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MT_21502',
    Name: 'Bainville',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_WI_21503',
    Name: 'Glidden',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_21504', Name: 'Granjeno', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_21505',
    Name: 'Packwood',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_IN_21506',
    Name: 'Uniondale',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MS_21507',
    Name: 'Chunky',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WV_21508',
    Name: 'Newburg',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_21509',
    Name: 'Cheyney University',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_21510',
    Name: 'Dublin',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_AZ_21511',
    Name: 'Top-of-the-World',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_AK_21512', Name: 'Eek', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_SC_21513',
    Name: 'McConnells',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_OK_21514',
    Name: 'Millerton',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_FL_21515', Name: 'Pittman', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NM_21516',
    Name: 'Vadito',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_WV_21517',
    Name: 'Rossmore',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_AK_21518', Name: 'Newtok', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_AR_21519',
    Name: 'Arkansas City',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_21520',
    Name: 'Arnold City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_21521', Name: 'Floyd', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AK_21522',
    Name: 'Moose Pass',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_OR_21523',
    Name: 'Nesika Beach',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_ID_21524', Name: 'Newdale', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_CO_21525',
    Name: 'Vineland',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MI_21526',
    Name: 'Lincoln',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_MO_21527', Name: 'Hume', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_WV_21528',
    Name: 'Lester',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MO_21529',
    Name: 'Frankford',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NC_21530',
    Name: 'Waco',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_AK_21531',
    Name: 'Lower Kalskag',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_AL_21532', Name: 'Dutton', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_TX_21533', Name: 'Latexo', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NH_21534',
    Name: 'West Stewartstown',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_NE_21535',
    Name: 'Brainard',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NM_21536',
    Name: 'Pueblitos',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_KS_21537',
    Name: 'The Highlands',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_LA_21538',
    Name: 'Singer',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_21539', Name: 'Tira', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WV_21540',
    Name: 'Deep Water',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_OK_21541', Name: 'Watts', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_KY_21542',
    Name: 'Druid Hills',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CO_21543',
    Name: 'City of Creede',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_MO_21544', Name: 'Farber', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MN_21545',
    Name: 'Vernon Center',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IL_21546', Name: 'Kenney', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_CO_21547', Name: 'Alma', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_PA_21548',
    Name: 'Hop Bottom',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OK_21549', Name: 'Sawyer', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_TN_21550',
    Name: "Parker's Crossroads",
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_CA_21551', Name: 'Hood', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_IN_21552', Name: 'Wheeler', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_IA_21553',
    Name: 'Diamondhead Lake',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_WY_21554', Name: 'Burns', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_NE_21555',
    Name: 'Petersburg',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_OK_21556', Name: 'Taloga', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_TX_21557',
    Name: 'Cross Timber',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_AZ_21558', Name: 'Bowie', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_MN_21559',
    Name: 'Lakeland Shores',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_21560',
    Name: 'Dunlo',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_21561',
    Name: 'Winslow',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MD_21562',
    Name: 'Accident',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NC_21563',
    Name: 'Mooresboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_CA_21564', Name: 'Nord', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_TN_21565',
    Name: 'Cedar Hill',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_OR_21566', Name: 'Idaville', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_MO_21567', Name: 'Jane', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_IN_21568',
    Name: 'Arlington',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_GA_21569',
    Name: 'Higgston',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_WV_21570',
    Name: 'Handley',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WY_21571',
    Name: 'Superior',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_CA_21572',
    Name: 'Artois',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NM_21573',
    Name: 'Salem',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_ND_21574',
    Name: 'St. Thomas',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_OH_21575',
    Name: 'Kelleys Island',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_KS_21576',
    Name: 'Hillsdale',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_IL_21577',
    Name: 'Little York',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_21578', Name: 'McCausland', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_21579', Name: 'Irwin', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_TN_21580', Name: 'Rives', stateId: 'TN', stateName: 'Tennessee'},
  {Id: 'CIT_US_TX_21581', Name: 'Chester', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MT_21582',
    Name: 'Clyde Park',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_NM_21583',
    Name: 'Manzano Springs',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NV_21584',
    Name: 'Walker Lake',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_NY_21585',
    Name: 'Fleischmanns',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_VA_21586',
    Name: 'Hiwassee',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IL_21587',
    Name: 'Campbell Hill',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_21588',
    Name: 'Fillmore',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OK_21589',
    Name: 'Liberty',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_OH_21590', Name: 'Mitiwanga', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_MO_21591', Name: 'Risco', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_WI_21592',
    Name: 'Cazenovia',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MN_21593',
    Name: 'Sanborn',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_21594', Name: 'Blackwell', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_21595',
    Name: 'Hamilton',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MN_21596',
    Name: 'Hackensack',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_21597',
    Name: 'Richland Springs',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IA_21598', Name: 'Mingo', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_21599',
    Name: 'Townville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_21600', Name: 'Peterson', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OH_21601', Name: 'Rushville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_WI_21602', Name: 'Arpin', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_IN_21603', Name: 'Kempton', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NM_21604',
    Name: 'Villanueva',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_KS_21605', Name: 'Arcadia', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_ND_21606',
    Name: 'Four Bears Village',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_NE_21607', Name: 'Prague', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_ID_21608', Name: 'Hollister', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_SC_21609',
    Name: 'Norway',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_21610',
    Name: 'Penn Lake Park',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OK_21611',
    Name: 'Sportsmen Acres',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_SD_21612',
    Name: 'Bristol',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_KS_21613', Name: 'Longton', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WY_21614',
    Name: 'Centennial',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_MT_21615',
    Name: 'Lodge Pole',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MS_21616',
    Name: 'Rienzi',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_OH_21617', Name: 'Lore City', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IL_21618', Name: 'Lisbon', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_21619', Name: 'New Athens', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MO_21620',
    Name: 'Maitland',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NJ_21621',
    Name: 'Juliustown',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_IA_21622', Name: 'Atalissa', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IL_21623', Name: 'Bath', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_21624', Name: 'Midland', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_21625', Name: 'Imperial', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TX_21626',
    Name: 'La Feria North',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_TN_21627',
    Name: 'Cumberland City',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_IL_21628',
    Name: 'Flat Rock',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_21629', Name: 'Eureka', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_21630',
    Name: 'New Douglas',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MI_21631',
    Name: 'Sherwood',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MN_21632',
    Name: 'Finlayson',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OK_21633', Name: 'Avant', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_OK_21634',
    Name: 'Marshall',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_PA_21635',
    Name: 'Port Clinton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_21636',
    Name: 'Hartsburg',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_21637', Name: 'Rochester', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MI_21638',
    Name: 'Barton Hills',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MT_21639',
    Name: 'Reed Point',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MI_21640',
    Name: 'Lake Angelus',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_UT_21641', Name: 'Manila', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NY_21642',
    Name: 'Rensselaer Falls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_CA_21643',
    Name: 'Jacumba',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_21644', Name: 'Plumwood', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_21645',
    Name: 'Cold Brook',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_ND_21646',
    Name: 'Oxbow',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MN_21647',
    Name: 'Dakota',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AR_21648',
    Name: 'Prattsville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_LA_21649',
    Name: 'Oretta',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_21650', Name: 'Poynor', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OK_21651',
    Name: 'Gracemont',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MN_21652',
    Name: 'Hartland',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WV_21653',
    Name: 'Flemington',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_21654',
    Name: 'Elco',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_21655',
    Name: 'San Carlos II',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_NE_21656', Name: 'Hadar', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_SD_21657',
    Name: 'Pukwana',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_NE_21658', Name: 'Polk', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_UT_21659', Name: 'Deseret', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_TX_21660', Name: 'Bailey', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MD_21661',
    Name: 'Grahamtown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NE_21662',
    Name: 'Arcadia',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_TN_21663',
    Name: 'Lynnville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_WA_21664',
    Name: 'Endicott',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_NE_21665', Name: 'Brule', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_TX_21666',
    Name: 'Warren City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_KS_21667', Name: 'Bronson', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_ID_21668', Name: 'Bliss', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_WA_21669',
    Name: 'Prescott',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_ID_21670', Name: 'Kendrick', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_PA_21671',
    Name: 'Youngstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KS_21672',
    Name: 'Greenleaf',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_NE_21673',
    Name: 'Dannebrog',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MO_21674',
    Name: 'East Lynne',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_21675',
    Name: 'Grimes',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AZ_21676',
    Name: 'Keams Canyon',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_IA_21677', Name: 'Auburn', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_KY_21678', Name: 'Ghent', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_CA_21679',
    Name: 'Crows Landing',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MT_21680', Name: 'Belfry', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_IL_21681',
    Name: 'Kampsville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IL_21682', Name: 'Ruma', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_IL_21683',
    Name: 'Rutland',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_21684',
    Name: 'Sandy Creek',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_ID_21685', Name: 'Clifton', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_MN_21686', Name: 'Plato', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_CA_21687',
    Name: 'Weott',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_21688', Name: 'Granville', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IN_21689',
    Name: 'Coalmont',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_LA_21690',
    Name: 'Belmont',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_21691',
    Name: 'Wallaceton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MT_21692', Name: 'Hysham', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_OH_21693', Name: 'Wilmot', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MD_21694',
    Name: 'Beaver Creek',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_PA_21695',
    Name: 'Boston',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_CO_21696', Name: 'Empire', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_IL_21697',
    Name: 'Middletown',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AR_21698',
    Name: 'Montrose',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MN_21699',
    Name: 'Kensington',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IL_21700', Name: 'Alma', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OK_21701', Name: 'Disney', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_LA_21702',
    Name: 'Keachi',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_TX_21703', Name: 'Nordheim', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KY_21704',
    Name: 'Goose Creek',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_LA_21705',
    Name: 'Starks',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_IL_21706', Name: 'Joppa', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OK_21707', Name: 'Tupelo', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_NE_21708', Name: 'Butte', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_NY_21709',
    Name: 'Freedom Plains',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MD_21710',
    Name: 'Kitzmiller',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OH_21711', Name: 'Dupont', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_21712',
    Name: 'Gerton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_21713',
    Name: 'Richville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OH_21714',
    Name: 'Clay Center',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_NE_21715', Name: 'Cook', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_NM_21716',
    Name: 'Velarde',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IL_21717',
    Name: 'Loraine',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_VA_21718',
    Name: 'Southampton Meadows',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_IA_21719', Name: 'Lester', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_21720',
    Name: 'Saginaw',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IA_21721',
    Name: 'Steamboat Rock',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_MD_21722',
    Name: 'Butlertown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_TX_21723',
    Name: 'San Carlos I',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AZ_21724',
    Name: 'Pisinemo',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_OK_21725', Name: 'Gans', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_KY_21726',
    Name: 'Arlington',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_KY_21727', Name: 'Vicco', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_NY_21728',
    Name: 'Cove Neck',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NE_21729',
    Name: 'Campbell',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NY_21730',
    Name: 'Salisbury Mills',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MN_21731',
    Name: 'Middle River',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_MO_21732', Name: 'Mercer', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_IL_21733',
    Name: 'McClure',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_21734',
    Name: 'Northport',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MO_21735',
    Name: 'Canalou',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AR_21736',
    Name: 'Blevins',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_TX_21737',
    Name: 'New Falcon',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NC_21738',
    Name: 'Goldston',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_MO_21739', Name: 'Farley', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_PA_21740',
    Name: 'East Side',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AZ_21741',
    Name: 'Indian Wells',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_GA_21742',
    Name: 'Good Hope',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_IA_21743', Name: 'Kelley', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MN_21744', Name: 'Lowry', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_AR_21745',
    Name: 'Donaldson',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NC_21746',
    Name: 'Cedar Rock',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_21747', Name: 'Gary City', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_21748',
    Name: 'Eagleville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WA_21749',
    Name: 'Springdale',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_LA_21750',
    Name: 'Dodson',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_AL_21751', Name: 'Ray', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_WI_21752',
    Name: 'Poy Sippi',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MN_21753',
    Name: 'Skyline',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_CO_21754', Name: 'Ovid', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_IN_21755',
    Name: 'Tennyson',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PA_21756',
    Name: 'Herndon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_21757',
    Name: 'Winter',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MT_21758', Name: 'Yaak', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_CA_21759',
    Name: 'Richfield',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SD_21760',
    Name: 'Soldier Creek',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NC_21761',
    Name: 'Sunbury',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_WI_21762', Name: 'Juda', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_NJ_21763',
    Name: 'Delaware Park',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MD_21764',
    Name: 'Mapleville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MO_21765',
    Name: 'McCord Bend',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_21766',
    Name: 'Burnt Ranch',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_21767',
    Name: 'Gatesville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NE_21768',
    Name: 'Hoskins',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_OH_21769', Name: 'Uniopolis', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_ND_21770',
    Name: 'Reynolds',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_LA_21771',
    Name: 'Hosston',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MN_21772',
    Name: 'Hollandale',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_21773', Name: 'Amargosa', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_21774',
    Name: 'Lac La Belle',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_AL_21775',
    Name: 'Weogufka',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_KS_21776', Name: 'Moscow', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MO_21777',
    Name: 'Everton',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NY_21778',
    Name: 'Speculator',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OH_21779',
    Name: 'Elizabethtown',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_HI_21780', Name: 'Hanalei', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_MT_21781', Name: 'Roberts', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_NE_21782',
    Name: 'Stapleton',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_TX_21783', Name: 'Damon', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NE_21784',
    Name: 'Phillips',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_TX_21785',
    Name: 'North Cleveland',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_VT_21786', Name: 'Quechee', stateId: 'VT', stateName: 'Vermont'},
  {
    Id: 'CIT_US_FL_21787',
    Name: 'Hillcrest Heights',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_PA_21788',
    Name: 'Millerton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TN_21789',
    Name: 'Garland',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NV_21790',
    Name: 'Goldfield',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_WI_21791',
    Name: 'Loganville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_OH_21792',
    Name: 'Magnetic Springs',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_AR_21793',
    Name: 'Wheatley',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_VA_21794',
    Name: 'Dungannon',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_OK_21795', Name: 'Short', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_MD_21796',
    Name: 'Breathedsville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_IA_21797', Name: 'Kellerton', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OH_21798', Name: 'Glenmont', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_VA_21799',
    Name: 'Newsoms',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IL_21800',
    Name: 'Woodland',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WI_21801',
    Name: 'Doylestown',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_KY_21802',
    Name: 'Bradfordsville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IN_21803',
    Name: 'Edwardsport',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IN_21804',
    Name: 'Michiana Shores',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OH_21805', Name: 'Potsdam', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IA_21806', Name: 'Rhodes', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_21807',
    Name: 'Pymatuning South',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_21808',
    Name: 'Bombay Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NM_21809',
    Name: 'Nakaibito',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MI_21810',
    Name: 'Boyne Falls',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_21811',
    Name: 'Pillow',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OR_21812', Name: 'Nehalem', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_WA_21813',
    Name: 'Lower Elochoman',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NY_21814',
    Name: 'Grand View-on-Hudson',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_KY_21815',
    Name: 'Rockholds',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WV_21816',
    Name: 'Hedgesville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_KS_21817', Name: 'Copeland', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MO_21818',
    Name: 'Missouri City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IL_21819', Name: 'Ewing', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OK_21820', Name: 'Rattan', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_KY_21821',
    Name: 'Spring Mill',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_TX_21822',
    Name: 'Paint Rock',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_OK_21823',
    Name: 'Westport',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_IL_21824',
    Name: 'Hindsboro',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AZ_21825',
    Name: 'South Komelik',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_IL_21826',
    Name: 'Seatonville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OK_21827',
    Name: 'Haworth',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NY_21828',
    Name: 'Preston-Potter Hollow',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_21829',
    Name: 'Eau Claire',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MN_21830', Name: 'Vesta', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_PA_21831',
    Name: 'Grier City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_21832',
    Name: 'Loma Linda East',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_KS_21833', Name: 'Greeley', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_TN_21834', Name: 'Ramer', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_TX_21835',
    Name: 'Sandy Hollow-Escondidas',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MN_21836',
    Name: 'Wilton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NM_21837',
    Name: 'North Light Plant',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_WV_21838',
    Name: 'Tunnelton',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MO_21839',
    Name: 'Martinsburg',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AL_21840',
    Name: 'Carolina',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NE_21841',
    Name: 'Maxwell',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_AR_21842',
    Name: 'Carthage',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_KS_21843', Name: 'Natoma', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MO_21844',
    Name: 'Mine La Motte',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OK_21845', Name: 'Butler', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NH_21846',
    Name: 'Suissevale',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {Id: 'CIT_US_IN_21847', Name: 'Hanna', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NM_21848',
    Name: 'Beclabito',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MD_21849',
    Name: 'Friendship',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_SD_21850',
    Name: 'Wakonda',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MN_21851',
    Name: 'Nerstrand',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_SC_21852',
    Name: 'Little Mountain',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NM_21853',
    Name: 'Nadine',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_21854',
    Name: 'Daisytown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_21855',
    Name: 'Templeton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_21856', Name: 'Goldsmith', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WV_21857',
    Name: 'Prichard',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WV_21858',
    Name: 'Durbin',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_VA_21859',
    Name: 'Clinchco',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IL_21860',
    Name: 'Olmsted',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TX_21861',
    Name: 'Coffee City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_KY_21862',
    Name: 'Mays Lick',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_GA_21863', Name: 'Pitts', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_SC_21864',
    Name: 'Glendale',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NV_21865',
    Name: 'Sutcliffe',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_NY_21866',
    Name: 'Fire Island',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TN_21867',
    Name: 'Parrottsville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_IA_21868', Name: 'Liscomb', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MO_21869', Name: 'Callao', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_OK_21870',
    Name: 'Cameron',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WI_21871',
    Name: 'Oakdale',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NE_21872',
    Name: 'Oakdale',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_MI_21873', Name: 'Lupton', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_PA_21874',
    Name: 'Franklin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_21875',
    Name: 'Abeytas',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_21876',
    Name: 'Witmer',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_21877',
    Name: 'Casar',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_21878',
    Name: 'Brocton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_21879',
    Name: 'Turkey',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NE_21880',
    Name: 'North Loup',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IL_21881',
    Name: 'Goofy Ridge',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_VA_21882',
    Name: 'Clifton',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_SC_21883',
    Name: 'Oswego',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_KS_21884', Name: 'Elk City', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_21885',
    Name: 'Orwin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OR_21886', Name: 'Halfway', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NE_21887',
    Name: 'Glenvil',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IN_21888',
    Name: 'Americus',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AZ_21889',
    Name: 'San Miguel',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_CA_21890',
    Name: 'Sierra Brooks',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MO_21891', Name: 'Silex', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_NE_21892', Name: 'Potter', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_IA_21893', Name: 'Quimby', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_KS_21894', Name: 'Bazine', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_CA_21895',
    Name: 'Bloomfield',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_KS_21896', Name: 'Brewster', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WI_21897',
    Name: 'Chaseburg',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_OH_21898',
    Name: 'Benton Ridge',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_OK_21899',
    Name: 'Lebanon',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_AR_21900',
    Name: 'Lost Bridge Village',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_TX_21901', Name: 'Burton', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_21902', Name: 'Chickasaw', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WI_21903',
    Name: 'Post Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_KS_21904', Name: 'Tescott', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IA_21905',
    Name: 'West Okoboji',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_OH_21906', Name: 'Woodstock', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_ID_21907', Name: 'Parker', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_HI_21908', Name: 'Kapalua', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_AZ_21909',
    Name: 'Goodyear Village',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_LA_21910',
    Name: 'Norwood',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_FL_21911',
    Name: 'Caryville',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_TX_21912', Name: 'Nazareth', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OK_21913',
    Name: 'Freedom',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_TX_21914',
    Name: 'Progreso Lakes',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_KY_21915',
    Name: 'Waverly',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MN_21916',
    Name: 'Plummer',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_ND_21917',
    Name: 'Medina',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_NE_21918', Name: 'Odell', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_SC_21919',
    Name: 'Ridgeway',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_OH_21920',
    Name: 'Carbon Hill',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_UT_21921', Name: 'La Sal', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_OH_21922',
    Name: 'Whites Landing',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OH_21923', Name: 'Tarlton', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AL_21924', Name: 'Cuba', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_SC_21925',
    Name: 'Eutawville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_AL_21926',
    Name: 'Vredenburgh',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MO_21927',
    Name: 'Bosworth',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IA_21928', Name: 'Bode', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_AL_21929', Name: 'Madrid', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_IA_21930', Name: 'Bertram', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_21931', Name: 'Lockridge', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_21932',
    Name: 'Kinloch',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TX_21933',
    Name: 'Iglesia Antigua',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_21934', Name: 'Tilden', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_21935',
    Name: 'Copake Falls',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WI_21936',
    Name: 'Bowler',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MO_21937', Name: 'Clark', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_GA_21938', Name: 'Graham', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_IN_21939',
    Name: 'Switz City',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_TX_21940', Name: 'Hedley', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_KS_21941', Name: 'Ingalls', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_IA_21942', Name: 'Lytton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OK_21943',
    Name: 'Red Rock',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_SD_21944',
    Name: 'Summit',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_SD_21945',
    Name: 'Roscoe',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_MI_21946', Name: 'Omer', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_IA_21947', Name: 'Westside', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_WY_21948', Name: 'Bedford', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_GA_21949',
    Name: 'Iron City',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_DE_21950',
    Name: 'Magnolia',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {Id: 'CIT_US_IL_21951', Name: 'Opdyke', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MO_21952',
    Name: 'Conception',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OK_21953',
    Name: 'Alderson',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NY_21954',
    Name: 'Natural Bridge',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_21955',
    Name: 'Creekside',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_RI_21956',
    Name: 'Foster Center',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {Id: 'CIT_US_NE_21957', Name: 'Snyder', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_IA_21958', Name: 'Pulaski', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_KY_21959', Name: 'Dexter', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_MS_21960',
    Name: 'Snow Lake Shores',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_NE_21961', Name: 'Scotia', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_IN_21962',
    Name: 'Somerville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_WV_21963',
    Name: 'Rachel',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NE_21964',
    Name: 'Meadow Grove',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IL_21965',
    Name: 'Victoria',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_21966',
    Name: 'Keswick',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AL_21967', Name: 'Colony', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_LA_21968',
    Name: 'Evergreen',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_OH_21969', Name: 'Morristown', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OK_21970',
    Name: 'Davidson',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MS_21971',
    Name: 'Lyon',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_TX_21972', Name: 'Annona', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NE_21973',
    Name: 'Long Pine',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_TN_21974',
    Name: 'Finger',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NY_21975',
    Name: 'Meridian',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_KY_21976',
    Name: 'Smithland',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MS_21977',
    Name: 'Silver City',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CA_21978',
    Name: 'Point Reyes Station',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_21979',
    Name: 'Cherokee Pass',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_ID_21980', Name: 'Tetonia', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_MD_21981',
    Name: 'Charlton',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_IA_21982', Name: 'Persia', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_LA_21983',
    Name: 'Hall Summit',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_21984',
    Name: 'Plumville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MT_21985', Name: 'Nashua', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_IA_21986', Name: 'Ridgeway', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IA_21987',
    Name: 'Marble Rock',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_IN_21988', Name: 'Colburn', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_MS_21989',
    Name: 'Schlater',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NE_21990',
    Name: 'Chapman',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NY_21991',
    Name: 'Belleville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NY_21992',
    Name: 'Millport',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WI_21993',
    Name: 'Hager City',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MO_21994',
    Name: 'Centertown',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IL_21995', Name: 'Easton', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_LA_21996',
    Name: 'Pleasure Bend',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NC_21997',
    Name: 'Oak City',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NM_21998',
    Name: 'Wagon Mound',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MO_21999',
    Name: 'Old Monroe',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_22000',
    Name: 'Lindcove',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_22001',
    Name: 'Sims',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NE_22002',
    Name: 'Yankee Hill',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IL_22003',
    Name: 'East Cape Girardeau',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SC_22004',
    Name: 'Rembert',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_22005',
    Name: 'Newry',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_22006',
    Name: 'Spartansburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_22007', Name: 'Bagley', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_22008',
    Name: 'Applewold',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_22009',
    Name: 'Coalton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_22010',
    Name: 'Piffard',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_22011',
    Name: 'Harrisburg',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MT_22012', Name: 'Charlo', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_OH_22013',
    Name: 'Maplewood Park',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_ND_22014',
    Name: 'Powers Lake',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_WI_22015',
    Name: 'Nichols',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_22016',
    Name: 'Stonefort',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_22017', Name: 'Blakesburg', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MI_22018', Name: 'Custer', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_AR_22019', Name: 'Garner', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_CO_22020', Name: 'Jansen', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_KY_22021',
    Name: 'Willisburg',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_GA_22022', Name: 'Rentz', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_SD_22023',
    Name: 'Alpena',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NC_22024',
    Name: 'Davis',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MN_22025',
    Name: 'Granada',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_22026', Name: 'Ross', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_22027',
    Name: 'Manns Choice',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_22028',
    Name: 'Lumber City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_22029',
    Name: 'Seminary',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_22030',
    Name: 'Enon Valley',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_22031',
    Name: 'Pine Ridge',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MD_22032',
    Name: 'Jugtown',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NM_22033',
    Name: 'Encinal',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_IN_22034', Name: 'Larwill', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_CA_22035',
    Name: 'Pajaro Dunes',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_RI_22036',
    Name: 'Clayville',
    stateId: 'RI',
    stateName: 'Rhode Island',
  },
  {Id: 'CIT_US_GA_22037', Name: 'Cecil', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_MO_22038', Name: 'Wyatt', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_ID_22039', Name: 'Rockland', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_AL_22040', Name: 'Hodges', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_IL_22041',
    Name: 'Dunfermline',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_22042',
    Name: 'Carytown',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NE_22043',
    Name: 'Davenport',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NY_22044',
    Name: 'Deferiet',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_22045',
    Name: 'Loma Vista',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IN_22046',
    Name: 'New Trenton',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IL_22047',
    Name: 'Compton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_22048',
    Name: 'Rock City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_22049',
    Name: 'Monson',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_22050', Name: 'Malone', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_22051',
    Name: 'Callaghan',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_OK_22052', Name: 'Oaks', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_PA_22053',
    Name: 'Potlicker Flats',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_22054', Name: 'Tynan', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KS_22055',
    Name: 'Lancaster',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_AL_22056', Name: 'Delta', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_CO_22057',
    Name: 'Red Cliff',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_TX_22058',
    Name: 'Cranfills Gap',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_WI_22059', Name: 'Rewey', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_KY_22060',
    Name: 'Payne Gap',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CA_22061',
    Name: 'Canby',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MI_22062',
    Name: 'Vandalia',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_AR_22063', Name: 'Poyen', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_GA_22064',
    Name: 'Ellenton',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MO_22065',
    Name: 'Rayville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_KY_22066',
    Name: 'Broeck Pointe',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OH_22067', Name: 'Osgood', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WV_22068',
    Name: 'Albright',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_22069',
    Name: 'Kylertown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_22070',
    Name: 'Harpers Ferry',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_NE_22071', Name: 'Monroe', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_TX_22072',
    Name: 'Eugenio Saenz',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_22073',
    Name: 'Kingston Mines',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MT_22074',
    Name: 'Bear Dance',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MT_22075',
    Name: 'Rader Creek',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_AR_22076', Name: 'Perry', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_IL_22077',
    Name: 'Vergennes',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_22078',
    Name: 'Markleysburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_22079',
    Name: 'Hendrum',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MO_22080',
    Name: 'Westwood',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NE_22081',
    Name: 'Lawrence',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_AL_22082', Name: 'Shiloh', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NC_22083',
    Name: 'Pollocksville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_22084',
    Name: 'Neilton',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_CO_22085', Name: 'Merino', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_FL_22086',
    Name: 'Westville',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MI_22087',
    Name: 'Grand Beach',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_22088',
    Name: 'Seltzer',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_22089',
    Name: 'Fruithurst',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MO_22090',
    Name: 'Prairie Home',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_AL_22091', Name: 'Whatley', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_WV_22092',
    Name: 'Dixie',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IL_22093',
    Name: 'Cave-In-Rock',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_22094',
    Name: 'Beaver Creek',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WI_22095',
    Name: 'Ephraim',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_OH_22096',
    Name: 'Sherrodsville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WA_22097',
    Name: 'Almira',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_FL_22098',
    Name: 'Waukeenah',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_MO_22099',
    Name: 'Rushville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AR_22100',
    Name: 'Strawberry',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_IN_22101',
    Name: 'West Harrison',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_AL_22102', Name: 'Boligee', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_OK_22103',
    Name: 'River Bottom',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_IA_22104', Name: 'Kimballton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SD_22105',
    Name: 'Colome',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_OH_22106', Name: 'Empire', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_22107',
    Name: 'Railroad',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_FL_22108', Name: 'Ebro', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_OK_22109',
    Name: 'Lenapah',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WI_22110',
    Name: 'Hawkins',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NC_22111',
    Name: 'Trenton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OH_22112',
    Name: 'New Hampshire',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NJ_22113',
    Name: 'Finesville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WI_22114',
    Name: 'De Soto',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MO_22115', Name: 'Cairo', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MN_22116',
    Name: 'Gonvick',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_OK_22117',
    Name: 'West Peavine',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_SC_22118',
    Name: 'Rowesville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_IA_22119', Name: 'Lakota', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MN_22120',
    Name: 'Bowlus',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_22121',
    Name: 'Airport Road Addition',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IA_22122', Name: 'Luana', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_22123',
    Name: 'Yankee Hill',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_22124', Name: 'Covington', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_TX_22125', Name: 'Tehuacana', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SD_22126',
    Name: 'Manderson-White Horse Creek',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NC_22127',
    Name: 'East Laurinburg',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_22128',
    Name: 'Freeman Spur',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_22129', Name: 'Riverton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MI_22130',
    Name: 'Wedgewood',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_GA_22131',
    Name: 'Sautee-Nacoochee',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_OH_22132', Name: 'Casstown', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OK_22133', Name: 'Talala', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_IA_22134', Name: 'Harcourt', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_22135',
    Name: 'Buckingham',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_22136',
    Name: 'Pacific Beach',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OK_22137', Name: 'Simms', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CA_22138',
    Name: 'Dorrington',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_HI_22139', Name: 'Waiohinu', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_IL_22140',
    Name: 'Biggsville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_LA_22141',
    Name: 'Lacassine',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_WY_22142', Name: 'Kaycee', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_NE_22143', Name: 'Dalton', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_OH_22144', Name: 'Damascus', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MI_22145',
    Name: 'Burlington',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_22146',
    Name: 'Hartleton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_22147', Name: 'La Carla', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NY_22148',
    Name: 'Cuylerville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_KY_22149',
    Name: 'Anthoston',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_AL_22150',
    Name: 'Gordonville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MD_22151',
    Name: 'Quantico',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_AL_22152',
    Name: 'Beatrice',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_AK_22153',
    Name: 'Goodnews Bay',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_KY_22154',
    Name: 'Hickory',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MO_22155',
    Name: 'Climax Springs',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OK_22156',
    Name: 'Coleman',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_OK_22157', Name: 'Nicut', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_TX_22158', Name: 'Staples', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_22159', Name: 'Royal', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_LA_22160',
    Name: 'Saline',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_GA_22161', Name: 'Siloam', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_OK_22162', Name: 'Keyes', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_UT_22163', Name: 'Altamont', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NM_22164',
    Name: 'Reserve',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MN_22165',
    Name: 'Hanley Falls',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IA_22166', Name: 'Holland', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_KY_22167', Name: 'Sparta', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_MN_22168',
    Name: 'Lake George',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OK_22169', Name: 'Leach', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_UT_22170', Name: 'Laketown', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_PA_22171',
    Name: 'Le Raysville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_22172',
    Name: 'Oakford',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_22173',
    Name: 'Armstrong',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MN_22174',
    Name: 'Buckman',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NY_22175',
    Name: 'Malverne Park Oaks',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_22176',
    Name: 'Chula Vista',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IA_22177', Name: 'Stockport', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MN_22178', Name: 'Utica', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_MS_22179',
    Name: 'Crosby',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WI_22180',
    Name: 'Ridgeland',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CO_22181',
    Name: 'Redvale',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MS_22182',
    Name: 'Sebastopol',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NY_22183',
    Name: 'Depauville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_MN_22184', Name: 'Cyrus', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_IL_22185',
    Name: 'Arrowsmith',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OH_22186',
    Name: 'Olde West Chester',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IL_22187',
    Name: 'Kilbourne',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WI_22188',
    Name: 'Browntown',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_NV_22189', Name: 'Lamoille', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_ID_22190',
    Name: 'Albion',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_WV_22191',
    Name: 'Matheny',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_AK_22192', Name: 'Seldovia', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_OH_22193', Name: 'Stratton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MD_22194',
    Name: 'Yarrowsburg',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_UT_22195', Name: 'Whiterocks', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_IA_22196', Name: 'Ionia', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_LA_22197',
    Name: 'East Hodge',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NV_22198',
    Name: 'Double Spring',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_NM_22199',
    Name: 'Timberon',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_AR_22200',
    Name: 'Shirley',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_WI_22201',
    Name: 'Hollandale',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NJ_22202',
    Name: 'Cape May Point',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_OK_22203', Name: 'Calvin', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_MS_22204',
    Name: 'White Oak',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_22205',
    Name: 'Washingtonville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NE_22206',
    Name: 'Lodgepole',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_OH_22207',
    Name: 'Chesterhill',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MA_22208',
    Name: 'Madaket',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {Id: 'CIT_US_IA_22209', Name: 'Ladora', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_KS_22210', Name: 'Dexter', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OR_22211', Name: 'Annex', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MN_22212',
    Name: 'Freeborn',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_GA_22213', Name: 'Bishop', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_NE_22214', Name: 'Panama', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_TX_22215', Name: 'Marquez', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OK_22216',
    Name: 'Cromwell',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_MI_22217', Name: 'Oakley', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_AL_22218',
    Name: 'Reeltown',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_IL_22219',
    Name: 'Donovan',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_22220',
    Name: 'St. Francisville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AR_22221',
    Name: 'Humnoke',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_22222',
    Name: 'Numidia',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_22223',
    Name: 'Star Junction',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_22224',
    Name: 'Weston',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AR_22225',
    Name: 'Oil Trough',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_KS_22226', Name: 'Everest', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WI_22227',
    Name: 'Haugen',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TX_22228',
    Name: 'Loma Grande',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_PA_22229',
    Name: 'Crown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_22230',
    Name: 'Fall River Mills',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_22231', Name: 'Richland', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_22232', Name: 'Catawba', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_UT_22233', Name: 'Portage', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MO_22234',
    Name: 'Cainsville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_SD_22235',
    Name: 'Egan',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IA_22236', Name: 'Rippey', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SD_22237',
    Name: 'Bonesteel',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_GA_22238',
    Name: 'Sky Valley',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_ND_22239',
    Name: 'Gackle',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_WV_22240',
    Name: 'Mitchell Heights',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WA_22241',
    Name: 'Riverside',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NM_22242',
    Name: 'North Acomita Village',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_OK_22243', Name: 'Byars', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_MO_22244',
    Name: 'Centerview',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IL_22245', Name: 'Lerna', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_OH_22246',
    Name: 'Burkettsville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CA_22247',
    Name: 'East Nicolaus',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_22248',
    Name: 'Elizabethtown',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_22249', Name: 'Scott', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TN_22250',
    Name: 'New Deal',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_UT_22251',
    Name: 'Navajo Mountain',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_PA_22252',
    Name: 'Hunker',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ND_22253',
    Name: 'Davenport',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_SD_22254',
    Name: 'Stickney',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_PA_22255',
    Name: 'McEwensville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_22256',
    Name: 'Pryorsburg',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MN_22257',
    Name: 'Silver Creek',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IA_22258', Name: 'Malcom', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MT_22259',
    Name: 'Riverbend',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_TX_22260', Name: 'Carbon', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AL_22261', Name: 'Waldo', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_CA_22262',
    Name: 'Bowles',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MS_22263',
    Name: 'Cary',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_AR_22264',
    Name: 'Horseshoe Lake',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CA_22265',
    Name: 'Monterey Park Tract',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VA_22266',
    Name: 'Toms Brook',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_VA_22267',
    Name: 'Bayside',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_SD_22268',
    Name: 'Spring Creek',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_AR_22269',
    Name: 'Delight',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_VT_22270',
    Name: 'Newport Center',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_PA_22271',
    Name: 'Allenwood',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_NE_22272', Name: 'Herman', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_IA_22273', Name: 'Leland', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WV_22274',
    Name: 'Bradshaw',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NY_22275',
    Name: 'Pamelia Center',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_DE_22276', Name: 'Kenton', stateId: 'DE', stateName: 'Delaware'},
  {
    Id: 'CIT_US_MT_22277',
    Name: 'Emigrant',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MO_22278',
    Name: 'St. Thomas',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TN_22279',
    Name: 'Grand Junction',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_OH_22280', Name: 'Zaleski', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IA_22281', Name: 'Maurice', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_22282',
    Name: 'New Hampton',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MI_22283',
    Name: 'Lake Ann',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CO_22284',
    Name: 'Rollinsville',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_VA_22285',
    Name: 'Alberta',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NC_22286',
    Name: 'Stonewall',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_DE_22287',
    Name: 'Ardentown',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {Id: 'CIT_US_IA_22288', Name: 'Rowley', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MD_22289',
    Name: 'Glen Echo',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_KS_22290',
    Name: 'Blue Mound',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_IA_22291', Name: 'Deep River', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MN_22292',
    Name: 'Searles',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_OK_22293',
    Name: 'Arcadia',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NC_22294',
    Name: 'Castalia',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IA_22295', Name: 'Protivin', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NC_22296',
    Name: 'Falcon',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IN_22297', Name: 'Howe', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_KY_22298',
    Name: 'St. Charles',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IL_22299',
    Name: 'Elliott',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_AL_22300', Name: 'Boykin', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_TX_22301', Name: 'Hays', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_22302',
    Name: 'Grays River',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KY_22303',
    Name: 'Wildwood',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_UT_22304', Name: 'Rockville', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_PA_22305',
    Name: 'Thompson',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NE_22306',
    Name: 'Bartley',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NY_22307',
    Name: 'Narrowsburg',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_OK_22308',
    Name: 'Lyons Switch',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_ND_22309',
    Name: 'Carson',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_IL_22310',
    Name: 'Mapleton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_22311',
    Name: 'Manchester',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_LA_22312',
    Name: 'Athens',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NE_22313',
    Name: 'Bruning',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_FL_22314',
    Name: 'Homeland',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_KS_22315',
    Name: 'Princeton',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_MT_22316', Name: 'Winston', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_FL_22317', Name: 'Golf', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_IA_22318', Name: 'Conroy', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MT_22319', Name: 'Savage', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_VA_22320',
    Name: 'Brodnax',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_22321',
    Name: 'Cedar Crest',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_UT_22322', Name: 'Emery', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MS_22323',
    Name: 'Georgetown',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MO_22324',
    Name: 'Fidelity',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_WY_22325', Name: 'Freedom', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_KS_22326', Name: 'Prescott', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_OH_22327',
    Name: 'Old Washington',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IL_22328',
    Name: 'New Holland',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WI_22329',
    Name: 'Lake Lorraine',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_ND_22330',
    Name: 'Drake',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_PA_22331',
    Name: 'Paxtonville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IA_22332',
    Name: 'Farmersburg',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_NE_22333',
    Name: 'Bradshaw',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_CO_22334', Name: 'Iliff', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_IN_22335',
    Name: 'Hazleton',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MI_22336',
    Name: 'Interlochen',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_ND_22337',
    Name: 'Michigan City',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_TX_22338', Name: 'Fowlerton', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OR_22339', Name: 'Westfir', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_IL_22340', Name: 'Bush', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_CO_22341',
    Name: 'Garden City',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NM_22342',
    Name: 'Jemez Springs',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_TX_22343', Name: 'Oakhurst', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_22344', Name: 'Lewistown', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_UT_22345', Name: 'Newcastle', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_AL_22346',
    Name: 'Anderson',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MO_22347',
    Name: 'Augusta',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_KS_22348', Name: 'Palco', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NE_22349',
    Name: 'Roseland',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_CA_22350',
    Name: 'Smartsville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_22351',
    Name: 'Kelliher',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MO_22352',
    Name: 'Theodosia',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MS_22353',
    Name: 'Sumner',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IL_22354',
    Name: 'Bellmont',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IL_22355', Name: 'Milton', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_NE_22356', Name: 'Hallam', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_ID_22357',
    Name: 'Island Park',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_NE_22358',
    Name: 'Milligan',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_KS_22359', Name: 'Parker', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WI_22360',
    Name: 'Sobieski',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IL_22361',
    Name: 'Sawyerville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AK_22362',
    Name: 'Mentasta Lake',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_IN_22363', Name: 'Wingate', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_MI_22364',
    Name: 'Jennings',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_IA_22365', Name: 'Kiron', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NM_22366',
    Name: 'Tajique',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MT_22367',
    Name: 'Lewistown Heights',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_TN_22368',
    Name: 'Auburntown',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NC_22369',
    Name: 'Conetoe',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NC_22370',
    Name: 'Hiddenite',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IL_22371',
    Name: 'Allerton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_KS_22372',
    Name: 'Sylvan Grove',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_KY_22373', Name: 'Crayne', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_NM_22374',
    Name: 'Pajarito Mesa',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NM_22375',
    Name: 'Navajo Dam',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IN_22376',
    Name: 'Ragsdale',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NC_22377',
    Name: 'Fairfield',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_GA_22378',
    Name: 'Gumbranch',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_NY_22379',
    Name: 'Groveland Station',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_AL_22380',
    Name: 'County Line',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_IN_22381',
    Name: 'Gentryville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_IA_22382',
    Name: 'Crawfordsville',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_OK_22383',
    Name: 'Longdale',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WV_22384',
    Name: 'Flatwoods',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_AR_22385',
    Name: 'Lead Hill',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_SD_22386',
    Name: 'Henry',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_KY_22387',
    Name: 'Rockport',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MO_22388',
    Name: 'Big Spring',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_ND_22389',
    Name: 'Tower City',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_MD_22390', Name: 'Vienna', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_PA_22391',
    Name: 'Woodbury',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_22392',
    Name: 'Gladstone',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_KY_22393',
    Name: 'Bonnieville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_22394',
    Name: 'James City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KS_22395',
    Name: 'Uniontown',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_KS_22396',
    Name: 'Courtland',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_NY_22397',
    Name: 'Wadsworth',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OH_22398', Name: 'Miamiville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AR_22399', Name: 'Lynn', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_OH_22400',
    Name: 'East Springfield',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_IA_22401', Name: 'Modale', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OR_22402',
    Name: 'Days Creek',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_IA_22403', Name: 'Parnell', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_TN_22404',
    Name: 'Milledgeville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_AK_22405', Name: 'Shungnak', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_SD_22406',
    Name: 'Chancellor',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MN_22407',
    Name: 'St. Hilaire',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AR_22408',
    Name: 'Black Oak',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_TX_22409', Name: 'Barry', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_22410',
    Name: 'Mount Clare',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_KS_22411', Name: 'Toronto', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_UT_22412', Name: 'Fayette', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_PA_22413',
    Name: 'Blain',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_22414',
    Name: 'Dixie Inn',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_IA_22415', Name: 'Defiance', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MN_22416',
    Name: 'Climax',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_GA_22417', Name: 'Pulaski', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_TN_22418',
    Name: 'Braden',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MO_22419',
    Name: 'Wheeling',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IA_22420', Name: 'Coulter', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MS_22421',
    Name: 'Sturgis',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_KS_22422',
    Name: 'Jamestown',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_MN_22423',
    Name: 'Murdock',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_LA_22424',
    Name: 'Ashland',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WV_22425',
    Name: 'Quinwood',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_OK_22426',
    Name: 'Stoney Point',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_SC_22427',
    Name: 'Gifford',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NY_22428',
    Name: 'Cragsmoor',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_VT_22429',
    Name: 'Greensboro Bend',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_WI_22430',
    Name: 'Downing',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TX_22431',
    Name: 'Los Alvarez',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_KY_22432',
    Name: 'High Bridge',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_GA_22433',
    Name: 'Newington',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_AL_22434',
    Name: 'Langston',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_ID_22435', Name: 'Worley', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_CO_22436',
    Name: 'Brookside',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_AK_22437', Name: 'Ambler', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_NE_22438',
    Name: 'Lindsay',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_TX_22439',
    Name: 'La Escondida',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_UT_22440', Name: 'Cove', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_KS_22441',
    Name: 'Neosho Rapids',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_PA_22442',
    Name: 'New Ringgold',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_22443',
    Name: 'Hundred',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_TN_22444',
    Name: 'Hornsby',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_IA_22445', Name: 'Renwick', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_GA_22446', Name: 'Climax', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_AR_22447', Name: 'Almyra', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_IL_22448',
    Name: 'East Gillespie',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_22449', Name: 'Tiro', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_UT_22450', Name: 'Garden', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_HI_22451', Name: 'Maalaea', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_AR_22452', Name: 'Knobel', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_TN_22453',
    Name: 'Stantonville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_LA_22454',
    Name: 'Glencoe',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_WI_22455', Name: 'Genoa', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_TX_22456', Name: 'Los Altos', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OH_22457',
    Name: 'Donnelsville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_TN_22458',
    Name: 'Walterhill',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MO_22459',
    Name: 'Vanduser',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_LA_22460',
    Name: 'Longville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WI_22461',
    Name: 'Milladore',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_LA_22462',
    Name: 'Rodessa',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_22463',
    Name: 'Salton Sea Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_VA_22464',
    Name: 'Free Union',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CO_22465',
    Name: 'El Moro',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_WV_22466',
    Name: 'Bayard',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_KY_22467',
    Name: 'Westport',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_22468',
    Name: 'Donaldson',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_22469',
    Name: 'Schneider',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_KS_22470', Name: 'Ransom', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_KY_22471', Name: 'Berry', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_GA_22472', Name: 'Carlton', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_CO_22473',
    Name: 'Hillrose',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_AZ_22474',
    Name: 'Cornfields',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_MI_22475', Name: 'Emmett', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_AR_22476',
    Name: 'Chidester',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_KS_22477', Name: 'Otis', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_IL_22478', Name: 'McNabb', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_SC_22479',
    Name: 'Shiloh',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OH_22480', Name: 'Melrose', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IL_22481', Name: 'Maquon', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_SC_22482',
    Name: 'Richburg',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NC_22483',
    Name: 'Earl',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_OH_22484',
    Name: 'Robertsville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_TX_22485', Name: 'Moran', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_22486',
    Name: 'Orrstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_22487',
    Name: 'Walkerville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_TX_22488',
    Name: 'Prado Verde',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WY_22489',
    Name: 'Medicine Bow',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_MS_22490',
    Name: 'Louin',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WV_22491',
    Name: 'Henderson',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MO_22492',
    Name: 'Linneus',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MO_22493',
    Name: 'Lake Tekakwitha',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_22494', Name: 'Dickens', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_KS_22495', Name: 'Dwight', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_OK_22496',
    Name: 'Mulhall',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_IL_22497',
    Name: 'Sammons Point',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_22498',
    Name: 'Kingsville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AK_22499',
    Name: 'Shaktoolik',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_AL_22500',
    Name: 'Gulfcrest',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_SD_22501',
    Name: 'Iroquois',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_AR_22502',
    Name: 'Perrytown',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_OK_22503',
    Name: 'White Oak',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MO_22504',
    Name: 'Brashear',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AL_22505',
    Name: 'East Point',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_IA_22506', Name: 'Mallard', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_WI_22507', Name: 'Brule', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_CO_22508', Name: 'Alpine', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_MS_22509',
    Name: 'Toccopola',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_KS_22510', Name: 'Easton', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_ID_22511', Name: 'Bovill', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_CO_22512', Name: 'Eckley', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_OR_22513', Name: 'Rowena', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_VA_22514',
    Name: 'Temperanceville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NV_22515',
    Name: 'Glenbrook',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_AZ_22516',
    Name: 'Dripping Springs',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_SC_22517',
    Name: 'Snelling',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_AK_22518', Name: 'Huslia', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_WV_22519',
    Name: 'Wardensville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WV_22520',
    Name: 'Itmann',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_AZ_22521',
    Name: 'Klagetoh',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_AL_22522', Name: 'Repton', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MT_22523',
    Name: 'Willow Creek',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_GA_22524', Name: 'Talmo', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_PA_22525',
    Name: 'Dryville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_22526',
    Name: 'Hendricks',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_TX_22527',
    Name: 'Fayetteville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_UT_22528', Name: 'Lyman', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MO_22529',
    Name: 'Skidmore',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_KS_22530', Name: 'Geneseo', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_TX_22531', Name: 'Mingus', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_LA_22532',
    Name: 'Dubberly',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_FL_22533',
    Name: 'Floridatown',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IL_22534',
    Name: 'Indianola',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_22535',
    Name: 'Copake Hamlet',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IN_22536', Name: 'Corunna', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_OK_22537',
    Name: 'Johnson',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_MO_22538', Name: 'Walker', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_IA_22539', Name: 'Halbur', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MI_22540',
    Name: 'Bay View',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_IL_22541',
    Name: 'Plattville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_22542', Name: 'Dedham', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_22543',
    Name: 'Cedar Point',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_22544',
    Name: 'Hewitt',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AL_22545', Name: 'Heath', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_LA_22546', Name: 'Lucky', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_NE_22547',
    Name: 'Chambers',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_IA_22548', Name: 'Kensett', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_FL_22549',
    Name: 'Matlacha Isles-Matlacha Shores',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_NY_22550',
    Name: 'Witherbee',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_OR_22551', Name: 'Waterloo', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_VA_22552',
    Name: 'Parrott',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MS_22553',
    Name: 'Marietta',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CA_22554',
    Name: 'Greenhorn',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_ND_22555',
    Name: 'Scranton',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_IL_22556',
    Name: 'Plainville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_KS_22557', Name: 'Bushton', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MI_22558',
    Name: 'Arcadia',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_GA_22559', Name: 'Sasser', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_OK_22560', Name: 'Garvin', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_AL_22561', Name: 'Goshen', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_TN_22562',
    Name: 'Yorkville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_IA_22563', Name: 'Deloit', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_22564',
    Name: 'Clarksdale',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NM_22565',
    Name: 'Ponderosa',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_OK_22566',
    Name: 'Kremlin',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_OH_22567', Name: 'Fulton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_FL_22568',
    Name: 'Charleston Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_AZ_22569', Name: 'Valle', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_MO_22570',
    Name: 'Rocheport',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_NE_22571', Name: 'Diller', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_AZ_22572',
    Name: 'Freedom Acres',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_IA_22573', Name: 'Corwith', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MN_22574', Name: 'Echo', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_UT_22575', Name: 'Howell', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_PA_22576',
    Name: 'Herminie',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_22577',
    Name: 'Albion',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AL_22578',
    Name: 'Morrison Crossroads',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_WA_22579',
    Name: 'Glenwood',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_AL_22580', Name: 'Sanford', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NE_22581',
    Name: 'Wolbach',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_PA_22582',
    Name: 'Wellsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_22583', Name: 'La Motte', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NY_22584',
    Name: 'Davenport Center',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_GA_22585',
    Name: 'Midville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_WY_22586',
    Name: 'Rock River',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_CA_22587',
    Name: 'Trowbridge',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AK_22588', Name: 'Kaktovik', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_MI_22589',
    Name: 'Prescott',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_TX_22590',
    Name: 'Spring Branch',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IL_22591', Name: 'Gays', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_AZ_22592',
    Name: 'White Hills',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_OK_22593', Name: 'Long', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_TX_22594',
    Name: 'Alice Acres',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WV_22595',
    Name: 'Cairo',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_CA_22596',
    Name: 'Dillon Beach',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NM_22597',
    Name: 'Tecolotito',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_TX_22598', Name: 'Benjamin', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OR_22599', Name: 'Rufus', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_PA_22600',
    Name: 'Buck Run',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MD_22601',
    Name: 'Greensburg',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_IL_22602',
    Name: 'Waggoner',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NE_22603',
    Name: 'Amherst',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IN_22604',
    Name: 'Fowlerton',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NC_22605',
    Name: 'Parmele',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_MD_22606', Name: 'Moscow', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_MO_22607',
    Name: 'Winston',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MO_22608',
    Name: 'Linn Creek',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_ND_22609',
    Name: 'Granville',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_OK_22610', Name: 'Braggs', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_TX_22611',
    Name: 'Wixon Valley',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MN_22612',
    Name: 'Villard',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OK_22613', Name: 'Goltry', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CA_22614',
    Name: 'Trinity Village',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_22615',
    Name: 'Jefferson',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OK_22616', Name: 'Soper', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_KY_22617',
    Name: 'Fairview',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_KS_22618',
    Name: 'Brookville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_CO_22619',
    Name: 'Sugar City',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NE_22620',
    Name: 'Table Rock',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_TX_22621', Name: 'Tuleta', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_TN_22622',
    Name: 'Centertown',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_AL_22623',
    Name: 'Bakerhill',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_OH_22624', Name: 'Malinta', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AR_22625',
    Name: 'Parkdale',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_WI_22626',
    Name: 'Ironton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_22627', Name: 'Carmine', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MN_22628',
    Name: 'Backus',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_NE_22629', Name: 'Avoca', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_IL_22630', Name: 'Sibley', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_ND_22631',
    Name: 'Hunter',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_ND_22632',
    Name: 'Hope',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_OH_22633',
    Name: 'New Alexandria',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IL_22634',
    Name: 'Ivesdale',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_LA_22635', Name: 'Delta', stateId: 'LA', stateName: 'Louisiana'},
  {Id: 'CIT_US_IA_22636', Name: 'Alta Vista', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MI_22637', Name: 'Le Roy', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_OK_22638', Name: 'Carter', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_IN_22639',
    Name: 'Clifford',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_AR_22640', Name: 'Keo', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_OH_22641', Name: 'Fresno', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_22642',
    Name: 'Fields Landing',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WA_22643',
    Name: 'Metaline Falls',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NM_22644',
    Name: 'Eagle Nest',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_LA_22645',
    Name: 'Collinston',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_MO_22646', Name: 'Utica', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_IA_22647', Name: 'Dixon', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_22648',
    Name: 'Swall Meadows',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_ND_22649',
    Name: 'Walcott',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_OH_22650', Name: 'Highland', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AR_22651',
    Name: 'Tollette',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_WY_22652',
    Name: 'Ten Sleep',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {Id: 'CIT_US_IA_22653', Name: 'Martelle', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_ID_22654',
    Name: 'Castleford',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_WY_22655',
    Name: 'Boulder Flats',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_OK_22656',
    Name: 'Platter',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_GA_22657', Name: 'Bartow', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NM_22658',
    Name: 'Sheep Springs',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_OK_22659',
    Name: 'Big Cabin',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MT_22660',
    Name: 'Santa Rita',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_NY_22661',
    Name: 'New Suffolk',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NC_22662',
    Name: 'Delco',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_22663', Name: 'Redfield', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NE_22664',
    Name: 'Maywood',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NE_22665',
    Name: 'Litchfield',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NJ_22666',
    Name: 'Mantoloking',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {Id: 'CIT_US_WI_22667', Name: 'Aniwa', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_MT_22668',
    Name: 'Geraldine',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MS_22669',
    Name: 'Lula',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_IL_22670', Name: 'Alvan', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_PA_22671',
    Name: 'Bear Creek Village',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_22672', Name: 'Lu Verne', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_22673', Name: 'Chelsea', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CO_22674',
    Name: 'Jamestown',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_SD_22675',
    Name: 'New Effington',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MN_22676',
    Name: 'Eitzen',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_ND_22677',
    Name: 'Abercrombie',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MI_22678',
    Name: 'Port Hope',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_22679',
    Name: 'Venango',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_22680',
    Name: 'Dalton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_CO_22681', Name: 'Cheraw', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_LA_22682', Name: 'Angie', stateId: 'LA', stateName: 'Louisiana'},
  {Id: 'CIT_US_AK_22683', Name: 'Tanana', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_WI_22684',
    Name: 'Lebanon',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NM_22685',
    Name: 'Cruzville',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_OH_22686', Name: 'Lockbourne', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OK_22687',
    Name: 'Marble City',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_PA_22688',
    Name: 'Brandonville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_22689',
    Name: 'Three Rocks',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NM_22690',
    Name: 'Santa Cruz',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_WI_22691', Name: 'Elcho', stateId: 'WI', stateName: 'Wisconsin'},
  {Id: 'CIT_US_KS_22692', Name: 'Fairview', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_SC_22693',
    Name: 'Perry',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NC_22694',
    Name: 'Dillsboro',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MN_22695',
    Name: 'Ostrander',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IN_22696',
    Name: 'Wynnedale',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_OH_22697',
    Name: 'Port William',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_KY_22698', Name: 'Dover', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_AR_22699',
    Name: 'Denning',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_AR_22700',
    Name: 'Harrell',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_KS_22701',
    Name: 'Harveyville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_WA_22702',
    Name: 'Elmer City',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KS_22703',
    Name: 'McFarland',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_MS_22704',
    Name: 'Pope',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IL_22705',
    Name: 'Magnolia',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_LA_22706',
    Name: 'Belcher',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_AK_22707', Name: 'Atqasuk', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_OH_22708',
    Name: 'Summerfield',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_GA_22709', Name: 'Denton', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MI_22710',
    Name: 'Canada Creek Ranch',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_AR_22711', Name: 'Perla', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_SD_22712',
    Name: 'La Plant',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_SD_22713',
    Name: 'Kennebec',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_PA_22714',
    Name: 'Little Meadows',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_22715', Name: 'Luxemburg', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_22716',
    Name: 'Houston Lake',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NE_22717',
    Name: 'Murdock',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_UT_22718', Name: 'Peoa', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_KY_22719',
    Name: 'Cunningham',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IN_22720', Name: 'Decker', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_OK_22721', Name: 'Vera', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_IA_22722', Name: 'Cumberland', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_22723',
    Name: 'Cassel',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MT_22724', Name: 'Condon', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_IA_22725', Name: 'Low Moor', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_ND_22726',
    Name: 'Porcupine',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_CA_22727',
    Name: 'Groveland',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MO_22728', Name: 'Galt', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_ID_22729', Name: 'Irwin', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_MD_22730',
    Name: 'Pinesburg',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CT_22731',
    Name: 'Mashantucket',
    stateId: 'CT',
    stateName: 'Connecticut',
  },
  {
    Id: 'CIT_US_VA_22732',
    Name: 'Dendron',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MN_22733',
    Name: 'Lewisville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AR_22734',
    Name: 'Gilmore',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MN_22735',
    Name: 'Chandler',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AL_22736', Name: 'Fulton', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_IA_22737',
    Name: 'Silver City',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_MI_22738',
    Name: 'Wellston',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SD_22739',
    Name: 'Two Strike',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_AR_22740',
    Name: 'Widener',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_TX_22741', Name: 'Streetman', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_LA_22742',
    Name: 'Bonita',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_ID_22743',
    Name: 'Yellow Pine',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_AZ_22744',
    Name: 'Pinedale',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_MO_22745',
    Name: 'Forest City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MO_22746',
    Name: 'Wortham',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IL_22747', Name: 'Cabery', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MO_22748',
    Name: 'Greencastle',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MO_22749', Name: 'Eugene', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_FL_22750',
    Name: 'Jacob City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AK_22751',
    Name: 'Nikolaevsk',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_UT_22752', Name: 'Woodland', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_OK_22753',
    Name: 'Breckenridge',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MO_22754',
    Name: 'Browning',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NY_22755',
    Name: 'Lyon Mountain',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_MO_22756', Name: 'Frohna', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_IN_22757', Name: 'Bryant', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_IA_22758', Name: 'Waucoma', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_KY_22759', Name: 'Ewing', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_AK_22760',
    Name: 'Copper Center',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_UT_22761', Name: 'Bluff', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_ID_22762',
    Name: 'Swan Valley',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_SD_22763',
    Name: 'Okreek',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MI_22764',
    Name: 'Eastport',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_KS_22765',
    Name: 'Grainfield',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_IA_22766', Name: 'Grafton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_LA_22767',
    Name: 'Baskin',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_IL_22768',
    Name: 'Henderson',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_22769',
    Name: 'Saddlebrooke',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_22770',
    Name: 'Thawville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AL_22771',
    Name: 'Sweet Water',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_ND_22772',
    Name: 'Anamoose',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NC_22773',
    Name: 'Powellsville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_AR_22774', Name: 'Letona', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_MD_22775',
    Name: 'Edgemont',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NC_22776',
    Name: 'Vandemere',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_LA_22777',
    Name: 'Reeves',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_UT_22778', Name: 'Torrey', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MI_22779',
    Name: 'Daggett',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_LA_22780', Name: 'Noble', stateId: 'LA', stateName: 'Louisiana'},
  {Id: 'CIT_US_IA_22781', Name: 'Harvey', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NM_22782',
    Name: 'White Signal',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_CA_22783',
    Name: 'Elmira',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_22784', Name: 'Westbrook', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OK_22785',
    Name: 'Drowning Creek',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_PA_22786',
    Name: 'Fallston',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MD_22787',
    Name: 'Pecktonville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CA_22788',
    Name: 'Hiouchi',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AK_22789',
    Name: 'Mosquito Lake',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_WI_22790',
    Name: 'Sandy Hook',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MO_22791',
    Name: 'Emerald Beach',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IA_22792', Name: 'Macedonia', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AZ_22793',
    Name: 'Wall Lane',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_AK_22794', Name: 'Nulato', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_MO_22795',
    Name: 'Spickard',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_22796',
    Name: 'Richview',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_22797', Name: 'Millican', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_SD_22798',
    Name: 'Willow Lake',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IN_22799', Name: 'Newtown', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_WA_22800',
    Name: 'Humptulips',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IA_22801', Name: 'Montour', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OK_22802', Name: 'Lane', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_GA_22803', Name: 'Oconee', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_FL_22804',
    Name: 'Glen Ridge',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_FL_22805', Name: 'Mulat', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_WA_22806',
    Name: 'Klickitat',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_NE_22807', Name: 'Union', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_WV_22808',
    Name: 'Iaeger',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MT_22809',
    Name: 'Hinsdale',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_OH_22810',
    Name: 'Higginsport',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_NE_22811', Name: 'Dix', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_SC_22812',
    Name: 'Modoc',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MN_22813',
    Name: 'Hayward',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_KS_22814', Name: 'Hamilton', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NM_22815',
    Name: 'Willard',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_LA_22816',
    Name: 'Castor',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PR_22817',
    Name: 'La Liga',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_TX_22818', Name: 'Anderson', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_22819', Name: 'Cisco', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_AZ_22820', Name: 'Poston', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_IA_22821', Name: 'Greeley', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SD_22822',
    Name: 'Agency Village',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_OR_22823', Name: 'Paisley', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_OH_22824', Name: 'Old Fort', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_KS_22825',
    Name: 'Mullinville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_IL_22826',
    Name: 'Steward',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_22827', Name: 'Woodson', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NC_22828',
    Name: 'Bath',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_KS_22829', Name: 'Agra', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_VA_22830',
    Name: 'Vansant',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_22831', Name: 'Hilltop', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_LA_22832',
    Name: 'Montpelier',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_OK_22833',
    Name: 'Boynton',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_UT_22834', Name: 'Boulder', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_OH_22835',
    Name: 'Connorville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_VA_22836',
    Name: 'Madison',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NE_22837',
    Name: 'Talmage',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MS_22838',
    Name: 'Blue Springs',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_DE_22839',
    Name: 'Slaughter Beach',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_MN_22840',
    Name: 'Pemberton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_VA_22841',
    Name: 'Schuyler',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WA_22842',
    Name: 'Thorp',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IA_22843', Name: 'Keswick', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SC_22844',
    Name: 'Central Pacolet',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OK_22845', Name: 'Ames', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_AR_22846',
    Name: 'Hickory Ridge',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_22847',
    Name: 'Greensboro',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_22848',
    Name: 'Pottersville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MS_22849',
    Name: 'Pace',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_MI_22850', Name: 'Boon', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_IA_22851', Name: 'Pisgah', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IL_22852', Name: 'Sims', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_IA_22853', Name: 'Wadena', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_KS_22854',
    Name: 'Partridge',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_SC_22855',
    Name: 'Monetta',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_TX_22856',
    Name: 'Taylor Landing',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_ND_22857',
    Name: 'Lansford',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NM_22858',
    Name: 'Young Place',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_IL_22859', Name: 'Kappa', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_GA_22860',
    Name: 'Gillsville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_IL_22861',
    Name: 'Menominee',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WV_22862',
    Name: 'Womelsdorf',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_AR_22863',
    Name: 'Briarcliff',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_LA_22864',
    Name: 'Grand Cane',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NC_22865',
    Name: 'Watha',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IA_22866', Name: 'Yale', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_FL_22867', Name: 'Raiford', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_MN_22868',
    Name: 'Zumbro Falls',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IN_22869',
    Name: 'Kingsbury',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_WY_22870',
    Name: 'Homa Hills',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_ID_22871',
    Name: 'Sweetwater',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_OH_22872',
    Name: 'Nettle Lake',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IN_22873',
    Name: 'Hardinsburg',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NC_22874',
    Name: 'Crossnore',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_UT_22875', Name: 'Leamington', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NE_22876',
    Name: 'Marquette',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MT_22877',
    Name: 'Fort Peck',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MO_22878',
    Name: 'Malta Bend',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_22879',
    Name: 'Schubert',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_22880',
    Name: 'Hopewell',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ND_22881',
    Name: 'Harmon',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_PA_22882',
    Name: 'Union Dale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_22883',
    Name: 'Ranchette Estates',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NC_22884',
    Name: 'Severn',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_KS_22885', Name: 'Fontana', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OH_22886', Name: 'Winesburg', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_22887',
    Name: 'Troy Grove',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NE_22888',
    Name: 'Holstein',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_WY_22889',
    Name: 'East Thermopolis',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_MO_22890',
    Name: 'Schell City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_AR_22891', Name: 'Lexa', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_IA_22892', Name: 'Meservey', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_KY_22893',
    Name: 'Woodlawn',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MD_22894',
    Name: 'Flintstone',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OK_22895', Name: 'Taft', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NY_22896',
    Name: 'Livonia Center',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IL_22897',
    Name: 'Addieville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MD_22898',
    Name: 'Edesville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WV_22899',
    Name: 'Hillsboro',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_VA_22900', Name: 'Ebony', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_MS_22901',
    Name: 'Morgan City',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_22902',
    Name: 'Bowers',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_22903',
    Name: 'Mountain',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TX_22904',
    Name: 'Country Acres',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_22905',
    Name: 'Ste. Marie',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WI_22906',
    Name: 'Potter',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OK_22907', Name: 'Meno', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_IA_22908', Name: 'Prescott', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AR_22909',
    Name: 'Rosston',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MO_22910',
    Name: 'Blackburn',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MN_22911',
    Name: 'Milroy',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AK_22912', Name: 'Teller', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_CA_22913',
    Name: 'Guinda',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OH_22914',
    Name: 'Bentonville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OR_22915', Name: 'Peoria', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_WA_22916',
    Name: 'North Sultan',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_NM_22917',
    Name: 'Sandia Park',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NY_22918',
    Name: 'Constableville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_TX_22919',
    Name: 'Del Mar Heights',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IN_22920',
    Name: 'Saratoga',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_NJ_22921',
    Name: 'Brainards',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_AR_22922',
    Name: 'Garland',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_TX_22923', Name: 'Midway', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_GA_22924',
    Name: 'Shady Dale',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_NE_22925', Name: 'Waco', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_OH_22926', Name: 'Berkey', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TX_22927',
    Name: 'Sandy Point',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_VA_22928',
    Name: 'The Plains',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_NY_22929', Name: 'Parc', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_NE_22930',
    Name: 'Springview',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_OH_22931', Name: 'New Riegel', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IN_22932',
    Name: 'Glenwood',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_OR_22933',
    Name: 'Lexington',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_MI_22934',
    Name: 'Michigamme',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_CO_22935', Name: 'Peoria', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_PA_22936',
    Name: 'Newtown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_UT_22937', Name: 'Bluebell', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_MN_22938',
    Name: 'Lake Lillian',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AK_22939',
    Name: 'Upper Kalskag',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_GA_22940', Name: 'Carl', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_IA_22941', Name: 'Fredonia', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WA_22942',
    Name: 'Gorst',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CO_22943',
    Name: 'Bethune',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_PA_22944',
    Name: 'Cashtown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_22945',
    Name: 'Bronaugh',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_DE_22946',
    Name: 'Little Creek',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_IL_22947',
    Name: 'Cypress',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_UT_22948', Name: 'Interlaken', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_KY_22949',
    Name: 'Sanders',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IN_22950',
    Name: 'Williams',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_CO_22951', Name: 'Peetz', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_MN_22952',
    Name: 'Twin Lakes',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OH_22953', Name: 'Orient', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IA_22954', Name: 'Twin Lakes', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_UT_22955',
    Name: 'New Harmony',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_OH_22956',
    Name: 'Mount Eaton',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WI_22957',
    Name: 'North Bay',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WI_22958',
    Name: 'Radisson',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MI_22959',
    Name: 'Wolverine',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MD_22960',
    Name: 'Sandy Hook',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_MT_22961', Name: 'Ryegate', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_IL_22962',
    Name: 'Henning',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_22963',
    Name: 'Creswell',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_22964',
    Name: 'Liberty',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_22965',
    Name: 'Vallecito',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PR_22966',
    Name: 'Candelero Abajo',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_MN_22967',
    Name: 'Donnelly',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_22968',
    Name: 'Prompton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_22969',
    Name: 'Bone Gap',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OR_22970', Name: 'Alsea', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NM_22971',
    Name: 'Galisteo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IL_22972',
    Name: 'Kangley',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MO_22973', Name: 'Easton', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_KS_22974', Name: 'Walton', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MS_22975',
    Name: 'Pachuta',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_VA_22976',
    Name: 'Boones Mill',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_TX_22977', Name: 'E. Lopez', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_22978', Name: 'Fenton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OH_22979',
    Name: 'Martinsburg',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OH_22980', Name: 'Kipton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AR_22981',
    Name: 'Concord',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_IL_22982', Name: 'Nason', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_KY_22983',
    Name: 'Manor Creek',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_KS_22984', Name: 'Bison', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_MT_22985', Name: 'Denton', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_IN_22986',
    Name: 'Leavenworth',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MO_22987',
    Name: 'Oak Ridge',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OH_22988',
    Name: 'McCutchenville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_KY_22989',
    Name: 'Summer Shade',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_SC_22990',
    Name: 'Mount Carmel',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_KY_22991',
    Name: 'Rabbit Hash',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_NJ_22992',
    Name: 'Clyde',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_22993',
    Name: 'Aspers',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NY_22994',
    Name: 'Duane Lake',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WY_22995',
    Name: 'Bessemer Bend',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_NE_22996',
    Name: 'Western',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IA_22997',
    Name: 'Crystal Lake',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_NE_22998',
    Name: 'Woodland Hills',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MI_22999',
    Name: 'Applegate',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_ND_23000',
    Name: 'Gilby',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MO_23001',
    Name: 'Pontiac',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_GA_23002',
    Name: 'Orchard Hill',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MN_23003',
    Name: 'Cromwell',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_LA_23004',
    Name: 'Venice',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_ID_23005', Name: 'Stites', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_IL_23006',
    Name: 'Bardolph',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_OH_23007',
    Name: 'Chesterville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_KY_23008',
    Name: 'Coldiron',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WV_23009',
    Name: 'Springfield',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_23010',
    Name: 'Rogersville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_TX_23011',
    Name: 'Alto Bonito Heights',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_23012', Name: 'Macdona', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MT_23013', Name: 'Basin', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_WI_23014',
    Name: 'New Post',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MD_23015',
    Name: 'Jennings',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_TX_23016', Name: 'Ackerly', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NE_23017',
    Name: 'Staplehurst',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_CA_23018',
    Name: 'Lemon Cove',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_23019',
    Name: 'Bigelow',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_KY_23020',
    Name: 'Warfield',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_KS_23021', Name: 'Severy', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_NV_23022', Name: 'Nixon', stateId: 'NV', stateName: 'Nevada'},
  {
    Id: 'CIT_US_CO_23023',
    Name: 'Kit Carson',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_VA_23024', Name: 'Saxis', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_KS_23025',
    Name: 'Pawnee Rock',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_CO_23026', Name: 'Rico', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_MO_23027',
    Name: 'Jerico Springs',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_LA_23028',
    Name: 'Branch',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_IN_23029',
    Name: 'Center Point',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OK_23030', Name: 'Tamaha', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_NE_23031', Name: 'Madrid', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_OK_23032',
    Name: 'Elm Grove',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_IA_23033', Name: 'Laurel', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MT_23034', Name: 'Hobson', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_TX_23035',
    Name: 'Roaring Springs',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MN_23036',
    Name: 'Callaway',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_ND_23037',
    Name: 'Leonard',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_CA_23038', Name: 'Vina', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_WY_23039',
    Name: 'Pavillion',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_KY_23040',
    Name: 'Crossgate',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MD_23041',
    Name: 'Goldsboro',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MS_23042',
    Name: 'Darling',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_MO_23043',
    Name: 'Rensselaer',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_KS_23044', Name: 'Lenora', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_GA_23045', Name: 'Oliver', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WV_23046',
    Name: 'Kistler',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_OK_23047',
    Name: 'Roosevelt',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_OK_23048',
    Name: 'Fitzhugh',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_IL_23049',
    Name: 'White City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_KS_23050', Name: 'Grinnell', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_AR_23051', Name: 'Pyatt', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_VA_23052',
    Name: 'Pungoteague',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_ND_23053',
    Name: 'Page',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_SD_23054',
    Name: 'Trent',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_AR_23055',
    Name: 'Scranton',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_IA_23056', Name: 'Paton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_23057',
    Name: 'Greenview',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OR_23058', Name: 'Seneca', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_AL_23059',
    Name: 'Cullomburg',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_GA_23060',
    Name: 'Roopville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_LA_23061',
    Name: 'Plaucheville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_MN_23062', Name: 'Pease', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_TX_23063', Name: 'Forsan', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_23064',
    Name: 'Yorkana',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AL_23065',
    Name: 'Fredonia',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_ID_23066', Name: 'Rockford', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_MT_23067',
    Name: 'Kings Point',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_CA_23068',
    Name: 'Dos Palos Y',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OH_23069',
    Name: 'Unionville Center',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_AR_23070',
    Name: 'Buckner',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_MO_23071', Name: 'Emma', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MO_23072',
    Name: 'Amsterdam',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WA_23073',
    Name: 'Creston',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MO_23074',
    Name: 'Grandin',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_23075',
    Name: 'Nilwood',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AR_23076',
    Name: 'Campbell Station',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_TX_23077',
    Name: 'Miguel Barrera',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CA_23078',
    Name: 'Olancha',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NC_23079',
    Name: 'Seagrove',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_OH_23080', Name: 'Darbyville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_SD_23081',
    Name: 'Gary',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_AL_23082',
    Name: 'Horn Hill',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_WI_23083',
    Name: 'Caroline',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_23084', Name: 'Orason', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_GA_23085',
    Name: 'Lexington',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_AR_23086',
    Name: 'St. Francis',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NE_23087',
    Name: 'Benedict',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_UT_23088', Name: 'Summit', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_SC_23089',
    Name: 'Olar',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_VA_23090',
    Name: 'Pamplin City',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_VT_23091',
    Name: 'North Hartland',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_NC_23092',
    Name: 'Momeyer',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_TX_23093', Name: 'Catarina', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_23094',
    Name: 'Mesa Vista',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_23095',
    Name: 'Elrosa',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WI_23096',
    Name: 'Holcombe',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IN_23097',
    Name: 'Spring Lake',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_CA_23098',
    Name: 'Keene',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_23099',
    Name: 'Kirbyville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_23100', Name: 'Barrera', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_23101', Name: 'Omaha', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_VA_23102',
    Name: 'Sperryville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IL_23103',
    Name: 'Willow Hill',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IN_23104',
    Name: 'Napoleon',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AK_23105',
    Name: 'Clam Gulch',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_KS_23106', Name: 'Walnut', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_GA_23107', Name: 'Kite', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WY_23108',
    Name: 'Fort Laramie',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {Id: 'CIT_US_MT_23109', Name: 'Lima', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_MN_23110',
    Name: 'Frontenac',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CO_23111',
    Name: 'Phippsburg',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_IA_23112', Name: 'Smithland', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_23113',
    Name: 'Cedar Hill Lakes',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IA_23114', Name: 'Packwood', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MN_23115',
    Name: 'Lake Wilson',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_23116',
    Name: 'New Columbus',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_23117',
    Name: 'Glen Campbell',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_23118',
    Name: 'Flensburg',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_ND_23119',
    Name: 'Hoople',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_KS_23120', Name: 'Morrill', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_LA_23121',
    Name: 'Creola',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_IA_23122', Name: 'Marathon', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_23123',
    Name: 'Bakersfield',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AK_23124',
    Name: 'Point Lay',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_TX_23125', Name: 'La Presa', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IN_23126', Name: 'Ambia', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_MT_23127', Name: 'Belknap', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_KS_23128', Name: 'Reading', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MN_23129',
    Name: 'Waldorf',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NC_23130',
    Name: 'Valle Crucis',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_KY_23131', Name: 'Nebo', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_CA_23132',
    Name: 'Lanare',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_23133',
    Name: 'Greenwood',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_TN_23134',
    Name: 'Darden',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_ND_23135',
    Name: 'Sherwood',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_KS_23136', Name: 'Elbing', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_FL_23137',
    Name: 'Fort Green',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_MI_23138', Name: 'Leland', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_IN_23139',
    Name: 'Blanford',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_WI_23140',
    Name: 'Gratiot',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_23141',
    Name: 'Sweden Valley',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CO_23142',
    Name: 'Bonanza Mountain Estates',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_KS_23143', Name: 'Wakarusa', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OK_23144', Name: 'Blue', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_NM_23145', Name: 'Gila', stateId: 'NM', stateName: 'New Mexico'},
  {
    Id: 'CIT_US_KY_23146',
    Name: 'Corinth',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_CO_23147',
    Name: 'East Pleasant View',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NJ_23148',
    Name: 'Asbury',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_OK_23149',
    Name: 'Snake Creek',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_TX_23150', Name: 'Ravenna', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KS_23151',
    Name: 'Louisville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_NE_23152', Name: 'Elba', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_FL_23153', Name: 'Munson', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_KS_23154', Name: 'Rexford', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_GA_23155', Name: 'Avera', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_GA_23156', Name: 'Maxeys', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NY_23157',
    Name: 'Three Mile Bay',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_SD_23158',
    Name: 'Nisland',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_PA_23159',
    Name: 'Salladasburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AR_23160',
    Name: 'Griffithville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_KS_23161', Name: 'Robinson', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IL_23162',
    Name: 'Forest City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_UT_23163',
    Name: 'Henrieville',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {Id: 'CIT_US_OR_23164', Name: 'Detroit', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_ID_23165', Name: 'East Hope', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_ND_23166',
    Name: 'Minnewaukan',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_PA_23167',
    Name: 'Twilight',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_HI_23168',
    Name: 'Kawela Bay',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_PA_23169',
    Name: 'Troutville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KS_23170', Name: 'Lane', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_TX_23171', Name: 'Fronton', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MT_23172',
    Name: 'Ballantine',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_SD_23173',
    Name: 'Pollock',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_NY_23174', Name: 'Rifton', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_MI_23175',
    Name: 'Owendale',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_23176',
    Name: 'Portersville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OR_23177', Name: 'Mehama', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_VA_23178',
    Name: 'Painter',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_KY_23179',
    Name: 'Lakeview Heights',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_GA_23180',
    Name: 'Taylorsville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_IN_23181',
    Name: 'Pottawattamie Park',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_PA_23182',
    Name: 'Bethany',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_23183', Name: 'Rogers', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_23184', Name: 'Creedmoor', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MI_23185',
    Name: 'Walloon Lake',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MO_23186',
    Name: 'Dadeville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_KY_23187', Name: 'Burna', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_PA_23188',
    Name: 'Pymatuning North',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IN_23189',
    Name: 'Losantville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_MO_23190', Name: 'Cobalt', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_NE_23191',
    Name: 'Hubbard',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_IA_23192', Name: 'Mineola', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_23193',
    Name: 'Lake Almanor West',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_23194', Name: 'La Ward', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_GA_23195',
    Name: 'Damascus',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CA_23196',
    Name: 'Whitley Gardens',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_23197',
    Name: 'Goodman',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_KY_23198',
    Name: 'Mackville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_AR_23199',
    Name: 'Pineville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_IL_23200',
    Name: 'Owaneco',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SD_23201',
    Name: 'Pickstown',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_ND_23202',
    Name: 'Lignite',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MO_23203',
    Name: 'Curryville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IA_23204',
    Name: 'New Providence',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_MO_23205', Name: 'Craig', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_AK_23206', Name: 'Tetlin', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_VA_23207',
    Name: 'Wachapreague',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_OH_23208',
    Name: 'Harrisville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_IA_23209', Name: 'Fostoria', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_KS_23210', Name: 'Healy', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_23211',
    Name: 'Madisonburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_23212', Name: 'Washta', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_23213', Name: 'Olds', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WV_23214',
    Name: 'Keystone',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_KY_23215', Name: 'Mentor', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_NE_23216',
    Name: 'Harrison',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_OK_23217',
    Name: 'Marland',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MT_23218',
    Name: 'Medicine Lake',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_OH_23219',
    Name: 'Bourneville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_VA_23220',
    Name: 'Hanover',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_MT_23221', Name: 'Noxon', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_SD_23222',
    Name: 'Bath',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_PA_23223',
    Name: 'Burnside',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OR_23224', Name: 'Lostine', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_ND_23225',
    Name: 'Riverdale',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NC_23226',
    Name: 'Kelford',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_23227',
    Name: 'Rupert',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MO_23228', Name: 'Dudley', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_OK_23229',
    Name: 'Dougherty',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_UT_23230', Name: 'Jensen', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_NE_23231',
    Name: 'Uehling',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MN_23232',
    Name: 'Winger',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_23233',
    Name: 'Mineral',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_23234',
    Name: 'De Kalb',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CO_23235',
    Name: 'Mountain Meadows',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_UT_23236',
    Name: 'Bryce Canyon City',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {Id: 'CIT_US_OH_23237', Name: 'Buckland', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_GA_23238', Name: 'Norwood', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_NE_23239',
    Name: 'Chester',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NY_23240',
    Name: 'Malden-on-Hudson',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MD_23241',
    Name: 'Gilmore',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MT_23242',
    Name: 'West Havre',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MN_23243',
    Name: 'Greenwald',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_23244',
    Name: 'Sheppton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_23245',
    Name: 'Harborton',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_KS_23246', Name: 'Burdett', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MN_23247',
    Name: 'Lismore',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_LA_23248',
    Name: 'Heflin',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_OH_23249',
    Name: 'Hills and Dales',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OH_23250', Name: 'North Star', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_MO_23251', Name: 'Berger', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MN_23252',
    Name: 'Rutledge',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_23253',
    Name: 'East Salem',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_23254',
    Name: 'Ruthton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MD_23255',
    Name: 'Taylors Island',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_IL_23256',
    Name: 'Smithfield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_FL_23257',
    Name: 'Campbellton',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_GA_23258',
    Name: 'Andersonville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_IL_23259',
    Name: 'Lake Ka-Ho',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_AZ_23260', Name: 'Jeddito', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_WA_23261',
    Name: 'Skykomish',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OH_23262', Name: 'Gratiot', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NE_23263',
    Name: 'Clatonia',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_FL_23264',
    Name: 'Highland Park',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_AK_23265',
    Name: 'Glacier View',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_AL_23266',
    Name: 'Chunchula',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_IA_23267', Name: 'Goose Lake', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_TX_23268', Name: 'Navarro', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_23269', Name: 'Jewett', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_IA_23270', Name: 'Lineville', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_TX_23271',
    Name: 'Caney City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_MO_23272', Name: 'Meta', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_UT_23273', Name: 'Hanksville', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_IL_23274',
    Name: 'Clear Lake',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_23275', Name: 'Hanlontown', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IL_23276', Name: 'McCook', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_AK_23277',
    Name: 'Aleknagik',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_MT_23278', Name: 'Wyola', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_AL_23279', Name: 'Detroit', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NC_23280',
    Name: 'Askewville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WI_23281',
    Name: 'Waukau',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IA_23282',
    Name: 'Sun Valley Lake',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_NE_23283', Name: 'Lynch', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_TX_23284',
    Name: 'Moore Station',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CA_23285',
    Name: 'El Portal',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NE_23286', Name: 'Sumner', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_PA_23287',
    Name: 'Landisburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_23288',
    Name: 'Preston',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MN_23289',
    Name: 'Wahkon',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IL_23290', Name: 'Gorham', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_SC_23291',
    Name: 'Stuckey',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_TX_23292', Name: 'Welch', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_NE_23293', Name: 'Bladen', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_LA_23294',
    Name: 'Fisher',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NE_23295',
    Name: 'Thedford',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_LA_23296',
    Name: 'Calvin',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_CA_23297',
    Name: 'Pescadero',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_23298',
    Name: 'Houstonia',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MN_23299',
    Name: 'Gilman',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IN_23300',
    Name: 'East Enterprise',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_OH_23301',
    Name: 'Belle Valley',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_UT_23302', Name: 'Teasdale', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_IA_23303', Name: 'Hornick', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SC_23304',
    Name: 'Furman',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_WI_23305',
    Name: 'Van Dyne',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MI_23306', Name: 'Posen', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_IN_23307', Name: 'Merom', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_KY_23308',
    Name: 'Oakland',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_VA_23309', Name: 'Surry', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_TX_23310',
    Name: 'Loma Linda West',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IN_23311',
    Name: 'Stinesville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AR_23312',
    Name: 'Wabbaseka',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_TX_23313',
    Name: 'Douglassville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_NM_23314',
    Name: 'Madrid',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_IL_23315',
    Name: 'Fieldon',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_ID_23316', Name: 'Donnelly', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_NV_23317', Name: 'Imlay', stateId: 'NV', stateName: 'Nevada'},
  {Id: 'CIT_US_OH_23318', Name: 'Valley Hi', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_23319',
    Name: 'Manchester',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_23320', Name: 'Jamaica', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_FL_23321', Name: 'Windsor', stateId: 'FL', stateName: 'Florida'},
  {Id: 'CIT_US_MT_23322', Name: 'Joplin', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_PA_23323',
    Name: 'Clarksville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_23324', Name: 'Sandyville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_KS_23325', Name: 'Olsburg', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NY_23326',
    Name: 'Phoenicia',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MO_23327',
    Name: 'Bates City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MN_23328',
    Name: 'Mendota',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WA_23329',
    Name: 'Cusick',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_AR_23330',
    Name: 'Russell',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_MI_23331', Name: 'Tustin', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_FL_23332',
    Name: 'Limestone',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_WY_23333', Name: 'Smoot', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_OK_23334',
    Name: 'Kenefic',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_SD_23335',
    Name: 'Delmont',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IA_23336', Name: 'Cushing', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_ND_23337',
    Name: 'Zap',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_OH_23338', Name: 'Lakeline', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OK_23339', Name: 'Jet', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NM_23340',
    Name: 'Cuyamungue Grant',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MO_23341',
    Name: 'Stotts City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TN_23342',
    Name: 'Graball',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_PA_23343',
    Name: 'Hartstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IN_23344', Name: 'Riley', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_MO_23345',
    Name: 'Scotsdale',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NJ_23346',
    Name: 'Upper Stewartsville',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_OK_23347',
    Name: 'Whitesboro',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_DE_23348',
    Name: 'Ardencroft',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_NC_23349',
    Name: 'Seven Devils',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IA_23350', Name: 'Cleghorn', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_ID_23351', Name: 'Harrison', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_WI_23352',
    Name: 'Rockdale',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_23353',
    Name: 'Idaville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WY_23354',
    Name: 'Chugcreek',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_OH_23355',
    Name: 'Hollansburg',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_PA_23356',
    Name: 'Taylorstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KS_23357', Name: 'Ford', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MT_23358',
    Name: 'Virginia City',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_TX_23359', Name: 'Perrin', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MN_23360',
    Name: 'Currie',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IN_23361',
    Name: 'Shamrock Lakes',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_SD_23362',
    Name: 'Hecla',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_IL_23363',
    Name: 'Wellington',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WI_23364',
    Name: 'Mount Hope',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MT_23365', Name: 'Dixon', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_OH_23366',
    Name: 'Ludlow Falls',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_CA_23367',
    Name: 'Desert Center',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IN_23368',
    Name: 'Toad Hop',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OH_23369', Name: 'Jenera', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_MO_23370', Name: 'Asbury', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_CA_23371',
    Name: 'Victor',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AL_23372', Name: 'Gantt', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_WA_23373',
    Name: 'Wilson Creek',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KY_23374',
    Name: 'Livingston',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_23375',
    Name: 'Slickville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_23376',
    Name: 'Weyerhaeuser',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_AK_23377',
    Name: 'Old Harbor',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_KS_23378', Name: 'Paxico', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_GA_23379', Name: 'Avalon', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_OH_23380', Name: 'Roswell', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_KY_23381', Name: 'Virgie', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_IL_23382',
    Name: 'Williamson',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SD_23383',
    Name: 'South Shore',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_AL_23384',
    Name: 'McIntosh',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MO_23385',
    Name: 'Gasconade',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_23386', Name: 'Hebron', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AR_23387', Name: 'Oden', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_OR_23388',
    Name: 'Camp Sherman',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_NY_23389',
    Name: 'Pine Hill',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_IA_23390',
    Name: 'Grand River',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_CO_23391',
    Name: 'Southern Ute',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_OH_23392', Name: 'Florida', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_GA_23393',
    Name: 'Meansville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_AL_23394',
    Name: 'Coffee Springs',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MN_23395',
    Name: 'Dennison',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IL_23396',
    Name: 'Long Point',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_23397', Name: 'Thurman', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MN_23398',
    Name: 'Bluffton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WI_23399',
    Name: 'Elderon',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MS_23400',
    Name: 'Mantee',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IL_23401',
    Name: 'New Burnside',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WV_23402',
    Name: 'Hambleton',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WI_23403',
    Name: 'Sheldon',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_SD_23404',
    Name: 'Mellette',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MD_23405',
    Name: 'Jesterville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_MD_23406', Name: 'Bier', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_CA_23407',
    Name: 'Cherokee Strip',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AZ_23408',
    Name: 'Lower Santan Village',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_MO_23409',
    Name: 'Napoleon',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IN_23410',
    Name: 'Straughn',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_VA_23411', Name: 'Phenix', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_ND_23412',
    Name: 'Frontier',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_CO_23413',
    Name: 'Seibert',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_NE_23414',
    Name: 'Garland',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_AR_23415', Name: 'Fisher', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_NY_23416',
    Name: 'Pierrepont Manor',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_MN_23417',
    Name: 'Lutsen',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_23418',
    Name: 'Villa Verde',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MN_23419',
    Name: 'Northrop',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NE_23420',
    Name: 'Carroll',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_IL_23421', Name: 'Bryant', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_KY_23422', Name: 'Dunmor', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_WA_23423',
    Name: 'Ocean City',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_TX_23424', Name: 'Realitos', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AZ_23425',
    Name: 'Oxbow Estates',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WI_23426',
    Name: 'Deer Park',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IA_23427', Name: 'Stout', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MS_23428',
    Name: 'Bassfield',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_NE_23429',
    Name: 'Nehawka',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_AR_23430', Name: 'Tillar', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_WI_23431',
    Name: 'Curtiss',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IN_23432', Name: 'Jalapa', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_SD_23433',
    Name: 'Reliance',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_OK_23434', Name: 'Aline', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NC_23435',
    Name: 'Roxobel',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NE_23436',
    Name: 'Gresham',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MN_23437',
    Name: 'Beardsley',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AZ_23438',
    Name: 'Sunizona',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_CA_23439',
    Name: 'Samoa',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_23440',
    Name: 'Shanksville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_23441',
    Name: 'San Luis',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_GA_23442',
    Name: 'Montrose',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_CO_23443',
    Name: 'Larkspur',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MI_23444',
    Name: 'Stanwood',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_GA_23445',
    Name: 'Danville',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_TX_23446', Name: 'Delmita', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AR_23447',
    Name: 'St. Charles',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_UT_23448', Name: 'Woodruff', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_CA_23449',
    Name: 'Little River',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WV_23450',
    Name: 'Elk Garden',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NM_23451',
    Name: 'Maxwell',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_OH_23452', Name: 'Parral', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MO_23453',
    Name: 'Mayview',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_AK_23454', Name: 'Nanwalek', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_ND_23455',
    Name: 'White Shield',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_MI_23456', Name: 'Garden', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_AL_23457', Name: 'Kansas', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_WI_23458',
    Name: 'Burnett',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_NM_23459', Name: 'Roy', stateId: 'NM', stateName: 'New Mexico'},
  {
    Id: 'CIT_US_AR_23460',
    Name: 'Magness',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_KS_23461', Name: 'Selden', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_SD_23462',
    Name: 'Tulare',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_CA_23463',
    Name: 'La Vina',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_23464',
    Name: 'Wind Ridge',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_23465',
    Name: 'Grygla',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_23466',
    Name: 'Hornbrook',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IL_23467', Name: 'Harvel', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_WA_23468',
    Name: 'Index',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MO_23469',
    Name: 'Wyaconda',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IL_23470', Name: 'Radom', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MO_23471',
    Name: 'Cross Timbers',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_ND_23472',
    Name: 'Hazelton',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_PR_23473',
    Name: 'Rodríguez Hevia',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_SC_23474',
    Name: 'Mulberry',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MN_23475',
    Name: 'Hitterdal',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IL_23476', Name: 'Alsey', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_WV_23477',
    Name: 'Huttonsville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_23478',
    Name: 'Ashville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ND_23479',
    Name: 'Aneta',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_LA_23480',
    Name: 'McNary',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_23481',
    Name: 'Ehrenfeld',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MT_23482',
    Name: 'St. Regis',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_AK_23483',
    Name: 'Chickaloon',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_MT_23484',
    Name: 'Little Browning',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_NC_23485',
    Name: 'Mesic',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_TX_23486',
    Name: 'Mobile City',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IA_23487', Name: 'Ollie', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_23488',
    Name: 'Clear Creek',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_23489',
    Name: 'Arkansaw',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IL_23490', Name: 'Colp', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_OH_23491', Name: 'Vinton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OK_23492',
    Name: 'Hardesty',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_PA_23493',
    Name: 'Sylvania',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_23494', Name: 'Joice', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WA_23495',
    Name: 'Keller',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_AL_23496',
    Name: 'Daviston',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_VA_23497',
    Name: 'Augusta Springs',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WI_23498',
    Name: 'Jim Falls',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MT_23499',
    Name: 'Sand Coulee',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_NM_23500',
    Name: 'Organ',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_OH_23501',
    Name: 'Hanging Rock',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OH_23502', Name: 'St. Martin', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_KS_23503', Name: 'Burns', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_KS_23504', Name: 'Buffalo', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_SD_23505',
    Name: 'Toronto',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IA_23506', Name: 'Rake', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_23507', Name: 'Lucas', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OK_23508', Name: 'Gotebo', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CA_23509',
    Name: 'Richvale',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_23510',
    Name: 'Daphnedale Park',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IN_23511', Name: 'Patriot', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_ID_23512',
    Name: 'Bloomington',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_AR_23513',
    Name: 'Caulksville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MN_23514',
    Name: 'Magnolia',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AL_23515', Name: 'Union', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_IA_23516', Name: 'Burr Oak', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AZ_23517',
    Name: 'Morristown',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_KY_23518',
    Name: 'Fountain Run',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OH_23519', Name: 'Helena', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_23520',
    Name: 'Mount Gretna',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_23521', Name: 'Dundee', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_23522',
    Name: 'Washtucna',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MN_23523',
    Name: 'Big Falls',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_LA_23524', Name: 'Ida', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_PA_23525',
    Name: 'Ursina',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_23526', Name: 'Linden', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_GA_23527', Name: 'Argyle', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_IA_23528', Name: 'Panama', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_23529',
    Name: 'Rackerby',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_23530',
    Name: 'Hatfield',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_OK_23531',
    Name: 'Pocasset',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_IL_23532',
    Name: 'St. Johns',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NC_23533',
    Name: 'Walstonburg',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MO_23534',
    Name: 'Anniston',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_23535', Name: 'Woodloch', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NE_23536',
    Name: 'Pleasant Dale',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_WA_23537',
    Name: 'Conconully',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OR_23538', Name: 'Ukiah', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_IL_23539',
    Name: 'Kinderhook',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_23540', Name: 'Gordon', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IL_23541', Name: 'Gilson', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MS_23542',
    Name: 'Kossuth',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WI_23543',
    Name: 'Franks Field',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_23544',
    Name: 'Eastvale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ND_23545',
    Name: 'Plaza',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_PA_23546',
    Name: 'Jackson Center',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_23547',
    Name: 'Garrison',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_CO_23548', Name: 'Arriba', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_AK_23549',
    Name: 'Nunam Iqua',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_IA_23550',
    Name: 'South English',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_IL_23551', Name: 'Kell', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_IA_23552', Name: 'Cantril', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_VA_23553',
    Name: 'Low Moor',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NY_23554',
    Name: 'South Lima',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_IL_23555', Name: 'Curran', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_TX_23556', Name: 'Bynum', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_23557', Name: 'Rio', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MT_23558',
    Name: 'Elliston',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_OK_23559', Name: 'Monroe', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_TN_23560',
    Name: 'Oakdale',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_SD_23561',
    Name: 'Geddes',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IN_23562', Name: 'Dubois', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NV_23563',
    Name: 'Topaz Lake',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {Id: 'CIT_US_TX_23564', Name: 'Penelope', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_23565',
    Name: 'Malden',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OR_23566', Name: 'Sumpter', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_WV_23567',
    Name: 'Falls View',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_NE_23568', Name: 'Denton', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_WY_23569', Name: 'Albin', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_MO_23570',
    Name: 'Glenwood',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_SD_23571',
    Name: 'Ramona',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_ND_23572',
    Name: 'New Leipzig',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_IL_23573',
    Name: 'New Minden',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_23574', Name: 'Browndell', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VT_23575',
    Name: 'Jacksonville',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_IN_23576',
    Name: 'Elizabeth',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_AR_23577', Name: 'Adona', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_MO_23578',
    Name: 'Knox City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NY_23579',
    Name: 'Napeague',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_TX_23580', Name: 'Gail', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_23581',
    Name: 'Chapman',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_23582', Name: 'Woden', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IL_23583', Name: 'Verona', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_VA_23584',
    Name: 'Port Royal',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IL_23585',
    Name: 'Kempton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NH_23586',
    Name: 'Milton Mills',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_CA_23587',
    Name: 'Milford',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OR_23588',
    Name: 'Black Butte Ranch',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_OK_23589', Name: 'Reydon', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NY_23590',
    Name: 'Salt Point',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_WV_23591',
    Name: 'Aurora',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_ID_23592', Name: 'Malta', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_WA_23593',
    Name: 'Mineral',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KS_23594',
    Name: 'Schoenchen',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_MN_23595',
    Name: 'Lake Bronson',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_KS_23596', Name: 'Sylvia', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WV_23597',
    Name: 'Stollings',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_KY_23598',
    Name: 'Slaughters',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IL_23599', Name: 'Palmer', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_DE_23600',
    Name: 'Leipsic',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_PA_23601',
    Name: 'Elgin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_23602',
    Name: 'Birmingham',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_LA_23603',
    Name: 'Frierson',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NM_23604',
    Name: 'Ute Park',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_23605',
    Name: 'Wilmore',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AK_23606', Name: 'Whittier', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_AK_23607', Name: 'Mekoryuk', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_MI_23608',
    Name: 'McBride',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_FL_23609', Name: 'Noma', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_ND_23610',
    Name: 'Flasher',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_CA_23611',
    Name: 'Litchfield',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SD_23612',
    Name: 'Bruce',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_TN_23613',
    Name: 'Slayden',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_MI_23614',
    Name: 'Chatham',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_23615',
    Name: 'Stillwater',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MO_23616', Name: 'Chula', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_NM_23617',
    Name: 'Hanover',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_WI_23618',
    Name: 'Little Sturgeon',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_23619', Name: 'Windom', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_23620', Name: 'Adair', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_KY_23621',
    Name: 'Hiseville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_ND_23622',
    Name: 'Halliday',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_IA_23623', Name: 'Van Wert', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_UT_23624', Name: 'Kenilworth', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_WY_23625',
    Name: 'Edgerton',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_MO_23626',
    Name: 'Blodgett',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NM_23627',
    Name: 'Chupadero',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MN_23628',
    Name: 'Storden',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_SC_23629',
    Name: 'Reevesville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_IN_23630',
    Name: 'Spurgeon',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IA_23631', Name: 'Clutier', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_23632',
    Name: 'Karns City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KS_23633', Name: 'Smolan', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_IA_23634', Name: 'Moorhead', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AL_23635',
    Name: 'Pennington',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_CO_23636',
    Name: 'Gold Hill',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_OR_23637', Name: 'Summit', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_AL_23638', Name: 'Belk', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_MD_23639',
    Name: 'Madison',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NC_23640',
    Name: 'Halifax',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_CA_23641',
    Name: 'Acampo',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NJ_23642',
    Name: 'Belle Mead',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_OK_23643',
    Name: 'Canadian',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_MT_23644', Name: 'Froid', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_IL_23645',
    Name: 'Buncombe',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_23646', Name: 'Frederika', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MD_23647',
    Name: 'Benedict',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_NE_23648',
    Name: 'Creston',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_LA_23649',
    Name: 'Cameron',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_AR_23650',
    Name: 'Pollard',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_AL_23651', Name: 'Black', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_NE_23652',
    Name: 'Jackson',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IL_23653',
    Name: 'Standard',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_23654', Name: 'Goree', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NE_23655',
    Name: 'Hayes Center',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MO_23656',
    Name: 'Tuscumbia',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_23657', Name: 'Wellman', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_VA_23658',
    Name: 'Big Island',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_VA_23659',
    Name: 'Lunenburg',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_FL_23660',
    Name: 'Yeehaw Junction',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_IA_23661',
    Name: 'College Springs',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_GA_23662', Name: 'Brinson', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WV_23663',
    Name: 'Falling Spring',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_MN_23664', Name: 'Gary', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_IL_23665',
    Name: 'Vermilion',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NE_23666',
    Name: 'Guide Rock',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_AL_23667', Name: 'Nauvoo', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_OK_23668', Name: 'Braman', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CA_23669',
    Name: 'Industry',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_TX_23670',
    Name: 'Cottonwood',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_ND_23671',
    Name: 'Fort Yates',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MO_23672',
    Name: 'Haywood City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NM_23673',
    Name: 'Bluewater Acres',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_AL_23674',
    Name: 'Yellow Bluff',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_WI_23675',
    Name: 'Egg Harbor',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_ND_23676',
    Name: 'Tappen',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_WV_23677',
    Name: 'Matoaka',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_AK_23678',
    Name: 'Koliganek',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_CO_23679',
    Name: 'Capulin',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_GA_23680',
    Name: 'Surrency',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_CO_23681', Name: 'Elbert', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_CO_23682',
    Name: 'Trail Side',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_ID_23683', Name: 'De Smet', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_IN_23684',
    Name: 'Pine Village',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IL_23685', Name: 'Seaton', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NM_23686',
    Name: 'San Jon',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_OH_23687', Name: 'St. Johns', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_WY_23688', Name: 'Garland', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_MT_23689',
    Name: 'Highwood',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_DE_23690', Name: 'Bethel', stateId: 'DE', stateName: 'Delaware'},
  {
    Id: 'CIT_US_IN_23691',
    Name: 'Monterey',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AK_23692',
    Name: 'White Mountain',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_MO_23693',
    Name: 'Barnett',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_WY_23694', Name: 'Glendo', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_IL_23695',
    Name: 'Bulpitt',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_ID_23696', Name: 'Oldtown', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_KS_23697', Name: 'Hanston', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MO_23698',
    Name: 'Altamont',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_23699',
    Name: 'Glenfield',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MT_23700', Name: 'Rollins', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_WY_23701',
    Name: 'Chugwater',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {Id: 'CIT_US_OH_23702', Name: 'Haviland', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_GA_23703', Name: 'Hull', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_IA_23704', Name: 'Alvord', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_23705',
    Name: 'Stoutland',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_KS_23706',
    Name: 'Grantville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_TN_23707',
    Name: 'Samburg',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_IL_23708',
    Name: 'Keensburg',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_ND_23709',
    Name: 'Des Lacs',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_KS_23710',
    Name: 'Baileyville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_ID_23711', Name: 'Blanchard', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_GA_23712',
    Name: 'Garfield',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MN_23713',
    Name: 'Sobieski',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_OH_23714',
    Name: 'South Salem',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_VA_23715',
    Name: 'Nathalie',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MD_23716',
    Name: 'Danville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MT_23717',
    Name: 'Gibson Flats',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MO_23718',
    Name: 'Koshkonong',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AL_23719',
    Name: 'Macedonia',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MN_23720',
    Name: 'Tenstrike',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WA_23721',
    Name: 'Tokeland',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_SC_23722',
    Name: 'Sellers',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_MO_23723', Name: 'Mosby', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_IL_23724',
    Name: 'Oakdale',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NM_23725',
    Name: 'Torreon',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_TX_23726',
    Name: 'La Paloma Ranchettes',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IA_23727', Name: 'Blairsburg', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_KS_23728', Name: 'Belvue', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_23729',
    Name: 'Barkeyville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_23730',
    Name: 'Parkersburg',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_VA_23731',
    Name: 'Hallwood',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_CO_23732',
    Name: 'Westcreek',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_NE_23733', Name: 'Taylor', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_IL_23734',
    Name: 'La Fayette',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IL_23735', Name: 'Norris', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_MI_23736', Name: 'Amasa', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_TX_23737', Name: 'Moore', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AR_23738',
    Name: 'Jacksonport',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_NM_23739',
    Name: 'Lyden',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MO_23740',
    Name: 'Phillipsburg',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NC_23741',
    Name: 'Wakulla',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_IN_23742', Name: 'Macy', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NE_23743',
    Name: 'Lewellen',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_OR_23744', Name: 'Elkton', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_NC_23745',
    Name: 'Autryville',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_VA_23746',
    Name: 'Belspring',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NC_23747',
    Name: 'Butters',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NY_23748',
    Name: 'Blodgett Mills',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_PA_23749',
    Name: 'Edenborn',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NJ_23750',
    Name: 'Hancocks Bridge',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WI_23751',
    Name: 'Mount Sterling',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IA_23752', Name: 'Melvin', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_AK_23753', Name: 'Grayling', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_AL_23754',
    Name: 'Kellyton',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MO_23755',
    Name: 'Barnard',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_UT_23756',
    Name: 'Independence',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_MN_23757',
    Name: 'Peterson',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_TX_23758',
    Name: 'Thompsonville',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OK_23759', Name: 'Caney', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CA_23760',
    Name: 'Big Creek',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_23761',
    Name: 'Oakwood',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_ND_23762',
    Name: 'Fordville',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_TX_23763', Name: 'Goodlow', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_23764',
    Name: 'Silver Creek',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_KY_23765', Name: 'Jeff', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_IA_23766', Name: 'Westgate', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_FL_23767', Name: 'Aucilla', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_IL_23768',
    Name: 'Batchtown',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_23769', Name: 'Burbank', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NM_23770',
    Name: 'Cañon',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_KS_23771', Name: 'Lebanon', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IL_23772',
    Name: 'Ellsworth',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AL_23773',
    Name: 'Loachapoka',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_LA_23774',
    Name: 'Bienville',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_OH_23775',
    Name: 'North Robinson',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_VA_23776', Name: 'Ewing', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_CA_23777',
    Name: 'Taylorsville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WA_23778',
    Name: 'Bay Center',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_PA_23779',
    Name: 'Petrolia',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_23780',
    Name: 'Fountain',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SC_23781',
    Name: 'Trenton',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_AR_23782',
    Name: 'New Edinburg',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_AL_23783',
    Name: 'Paint Rock',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_OK_23784',
    Name: 'Pittsburg',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_IL_23785',
    Name: 'Mill Shoals',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_23786', Name: 'Broaddus', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_23787',
    Name: 'Oakwood Park',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MD_23788',
    Name: 'Fishing Creek',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_OH_23789',
    Name: 'Vaughnsville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_WI_23790', Name: 'Eland', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_CA_23791',
    Name: 'Coffee Creek',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AL_23792',
    Name: 'Waterloo',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_AR_23793', Name: "O'Kean", stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_MO_23794',
    Name: 'Marquand',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IN_23795', Name: 'Altona', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NC_23796',
    Name: 'Harrells',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IA_23797',
    Name: 'Garden Grove',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_GA_23798', Name: 'Lilly', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_SC_23799',
    Name: 'Scotia',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_IA_23800', Name: 'Blencoe', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AL_23801',
    Name: 'Providence',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MO_23802',
    Name: 'Holland',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WV_23803',
    Name: 'Glen Jean',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_SC_23804',
    Name: 'Bradley',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CA_23805',
    Name: 'Bear Valley',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_23806',
    Name: 'Meire Grove',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NC_23807',
    Name: 'Sugar Mountain',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_KS_23808', Name: 'Tyro', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_PA_23809',
    Name: 'Iola',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_23810', Name: 'Pecan Gap', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_23811',
    Name: 'Pine Glen',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_23812',
    Name: 'Raemon',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_PA_23813',
    Name: 'Summit Station',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_23814',
    Name: 'Newton Hamilton',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_23815', Name: 'Mount Cory', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_23816',
    Name: 'Spillertown',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SD_23817',
    Name: 'Frederick',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_WI_23818',
    Name: 'Dalton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_TN_23819',
    Name: 'Mitchellville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_NC_23820',
    Name: 'Eureka',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NE_23821',
    Name: 'Trumbull',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_TX_23822',
    Name: 'Radar Base',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_IA_23823', Name: 'Volga', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OH_23824', Name: 'Zanesfield', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NY_23825',
    Name: 'Plessis',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_LA_23826',
    Name: 'Sugartown',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_AL_23827',
    Name: 'Edwardsville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_IL_23828', Name: 'Baylis', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_KY_23829',
    Name: 'Glenview Manor',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IA_23830', Name: 'Hancock', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_ID_23831', Name: 'Peck', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_PA_23832',
    Name: 'Marklesburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_TX_23833', Name: 'Ramos', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AR_23834', Name: 'Fulton', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_SC_23835',
    Name: 'Russellville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_IL_23836',
    Name: 'Donnellson',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IN_23837', Name: 'Burket', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_IL_23838',
    Name: 'Shumway',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_23839',
    Name: 'Okabena',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MO_23840',
    Name: 'Edgar Springs',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MO_23841',
    Name: 'Neck City',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_23842',
    Name: 'Hendersonville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_23843',
    Name: 'Cerro Gordo',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_SC_23844',
    Name: 'Cokesbury',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_MI_23845',
    Name: 'Norwood',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_OK_23846',
    Name: 'Scraper',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MT_23847',
    Name: 'Winifred',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_ND_23848',
    Name: 'Munich',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MO_23849',
    Name: 'Newtonia',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_GA_23850', Name: 'Epworth', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_IL_23851',
    Name: 'Montrose',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_WY_23852', Name: 'Eden', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_PA_23853',
    Name: 'Lincolnville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OK_23854', Name: 'Nash', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_IN_23855',
    Name: 'Jonesville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IA_23856', Name: 'Woodburn', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SD_23857',
    Name: 'Wentworth',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IA_23858', Name: 'Rembrandt', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_TX_23859',
    Name: 'Opdyke West',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_WI_23860',
    Name: 'Baileys Harbor',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MI_23861',
    Name: 'Millersburg',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_ND_23862',
    Name: 'Ruthville',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_IA_23863', Name: 'Arthur', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MN_23864',
    Name: 'Nelson',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OH_23865', Name: 'Rosewood', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NE_23866',
    Name: 'Holbrook',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_WI_23867',
    Name: 'Port Wing',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_DE_23868',
    Name: 'Woodside',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_IL_23869',
    Name: 'Caledonia',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IN_23870', Name: 'Poneto', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_KS_23871', Name: 'Munjor', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_KS_23872',
    Name: 'Mission Woods',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_NY_23873',
    Name: 'Hortonville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AK_23874', Name: 'Hollis', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_KS_23875',
    Name: 'Lincolnville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_MN_23876',
    Name: 'South Haven',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_KS_23877', Name: 'Tipton', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_MN_23878', Name: 'Homer', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_SC_23879',
    Name: 'Lowrys',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_CA_23880', Name: 'Yolo', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_MN_23881', Name: 'Inger', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_AZ_23882',
    Name: 'Tonto Village',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_IN_23883', Name: 'Mellott', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_FL_23884',
    Name: 'Okahumpka',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IA_23885', Name: 'Onslow', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_AL_23886',
    Name: 'Gilbertown',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_VT_23887',
    Name: 'Beecher Falls',
    stateId: 'VT',
    stateName: 'Vermont',
  },
  {
    Id: 'CIT_US_WA_23888',
    Name: 'Kahlotus',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_CO_23889', Name: 'Atwood', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_NE_23890', Name: 'Dunbar', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_MI_23891',
    Name: 'Copemish',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_NH_23892',
    Name: 'Union',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_IN_23893',
    Name: 'Scotland',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_WI_23894',
    Name: 'Union Center',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_WI_23895',
    Name: 'Patch Grove',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_LA_23896',
    Name: 'Edgefield',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_TN_23897',
    Name: 'Enville',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_GA_23898',
    Name: 'Haralson',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_MS_23899',
    Name: 'Pittsboro',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_MN_23900', Name: 'Leota', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_CA_23901',
    Name: 'Fort Bidwell',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_OH_23902', Name: 'Harpster', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_23903',
    Name: 'Callensburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IL_23904', Name: 'Beason', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MD_23905',
    Name: 'Bloomington',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WA_23906',
    Name: 'Queets',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_CA_23907',
    Name: 'Winterhaven',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AR_23908', Name: 'Reader', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_CO_23909',
    Name: 'Woody Creek',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_IL_23910',
    Name: 'Hooppole',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_23911', Name: 'Stockton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SC_23912',
    Name: 'Newry',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NE_23913',
    Name: 'Pickrell',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MS_23914',
    Name: 'Farrell',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_CA_23915',
    Name: 'Tomales',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AR_23916',
    Name: 'Franklin',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_OH_23917', Name: 'Moscow', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_ND_23918',
    Name: 'Sheyenne',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_ND_23919',
    Name: 'Dunn Center',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_CA_23920',
    Name: 'Garey',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MI_23921',
    Name: 'Breedsville',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_23922',
    Name: 'Iron Horse',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OK_23923',
    Name: 'Iron Post',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_CA_23924',
    Name: 'Rail Road Flat',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_ND_23925',
    Name: 'Wimbledon',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_NE_23926', Name: 'Dwight', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_MN_23927',
    Name: 'Forada',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AR_23928',
    Name: 'Friendship',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_IA_23929', Name: 'Kamrar', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MI_23930', Name: 'Allen', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_MO_23931', Name: 'Laredo', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_AL_23932', Name: 'Eunola', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_AR_23933', Name: 'Sunset', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_IA_23934',
    Name: 'Drakesville',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_WI_23935',
    Name: 'Exeland',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IN_23936',
    Name: 'Little York',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_WA_23937',
    Name: 'Steptoe',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MO_23938',
    Name: 'Gilliam',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OR_23939',
    Name: 'Long Creek',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_SC_23940',
    Name: 'Starr',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_CA_23941',
    Name: 'Amador City',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_KY_23942', Name: 'Bremen', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_OH_23943',
    Name: 'West Leipsic',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_KS_23944',
    Name: 'Morganville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_WA_23945',
    Name: 'Dixie',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OH_23946', Name: 'Buford', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_23947', Name: 'Radnor', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AZ_23948',
    Name: 'Fort Apache',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NJ_23949',
    Name: 'Diamond Beach',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_MI_23950',
    Name: 'Big Bay',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_CA_23951',
    Name: 'Wawona',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_23952',
    Name: 'Hughesville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MO_23953',
    Name: 'Bolckow',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_KS_23954', Name: 'Beattie', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_AK_23955',
    Name: 'Atmautluak',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_ND_23956',
    Name: 'Mooreton',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_AR_23957',
    Name: 'Greenway',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_23958',
    Name: 'Shippingport',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MT_23959', Name: 'Herron', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_GA_23960',
    Name: 'Register',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_IL_23961',
    Name: 'Du Bois',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_23962',
    Name: 'Brave',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AR_23963', Name: 'Sidney', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_OK_23964', Name: 'Tiawah', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_HI_23965',
    Name: 'Kalihiwai',
    stateId: 'HI',
    stateName: 'Hawaii',
  },
  {
    Id: 'CIT_US_MN_23966',
    Name: 'Watson',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_23967',
    Name: 'East Waterford',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_GA_23968',
    Name: 'Mitchell',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_OH_23969', Name: 'Tedrow', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_TX_23970',
    Name: 'Study Butte',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CA_23971',
    Name: 'Verdi',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OH_23972',
    Name: 'Bloomingdale',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_IA_23973',
    Name: 'Prairieburg',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_KS_23974', Name: 'Grenola', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OH_23975', Name: 'Negley', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IA_23976', Name: 'Elberon', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WI_23977',
    Name: 'Hustler',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_TX_23978', Name: 'Megargel', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MO_23979',
    Name: 'Fillmore',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_ID_23980', Name: 'Onaway', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_TX_23981',
    Name: 'Olmito and Olmito',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MS_23982',
    Name: 'Golden',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_MO_23983', Name: 'Camden', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_WI_23984',
    Name: 'Lake Tomahawk',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MN_23985',
    Name: 'Northome',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_23986',
    Name: 'Heckscherville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OH_23987',
    Name: 'West Elkton',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_TX_23988', Name: 'Lopeño', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_AR_23989', Name: 'Dell', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_OK_23990',
    Name: 'Lequire',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_NE_23991', Name: 'Gurley', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_KY_23992',
    Name: 'Water Valley',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_MT_23993', Name: 'Saco', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_PA_23994',
    Name: 'Coral',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_23995', Name: 'Nashville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AK_23996', Name: 'Newhalen', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_AL_23997',
    Name: 'Beaverton',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_NE_23998', Name: 'Craig', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_AL_23999',
    Name: 'Spring Garden',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MI_24000',
    Name: 'Stronach',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_TX_24001', Name: 'Cool', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_24002',
    Name: 'Locustdale',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_24003',
    Name: 'Minnesota City',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_KY_24004',
    Name: 'Thornhill',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WA_24005',
    Name: 'Latah',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_OH_24006', Name: 'Wren', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NE_24007',
    Name: 'Howard City',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MO_24008',
    Name: 'New Cambria',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MS_24009',
    Name: 'Alligator',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_24010',
    Name: 'Benson',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_24011', Name: 'Boxholm', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MN_24012', Name: 'Dent', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_IL_24013',
    Name: 'Broughton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_AL_24014',
    Name: 'Millerville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_GA_24015',
    Name: 'Culloden',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_UT_24016', Name: 'Clawson', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_AL_24017', Name: 'Onycha', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_KS_24018', Name: 'Emmett', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_SD_24019',
    Name: 'Hosmer',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_MT_24020', Name: 'Crane', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_IL_24021', Name: 'Eldred', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NM_24022',
    Name: 'Tse Bonito',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_KS_24023', Name: 'Rantoul', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NJ_24024',
    Name: 'Broadway',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_KY_24025',
    Name: 'Worthville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WA_24026',
    Name: 'Deep River',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_AZ_24027',
    Name: 'Oljato-Monument Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_ND_24028',
    Name: 'Wing',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MD_24029',
    Name: 'Franklin',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_CO_24030',
    Name: 'Franktown',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MO_24031',
    Name: 'Homestead',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IA_24032', Name: 'Henderson', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WY_24033',
    Name: 'Elk Mountain',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {Id: 'CIT_US_NE_24034', Name: 'Ashton', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_IA_24035', Name: 'Maysville', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MN_24036', Name: 'Ogema', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_GA_24037', Name: 'Rayle', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_OH_24038', Name: 'Rossburg', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_SD_24039',
    Name: 'St. Lawrence',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_IL_24040',
    Name: 'Royal Lakes',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NE_24041',
    Name: 'Raymond',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_WI_24042',
    Name: 'Wilson',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_AK_24043',
    Name: 'Arctic Village',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_IA_24044', Name: 'Blockton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_24045',
    Name: 'Valley Home',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IN_24046',
    Name: 'Newberry',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_MO_24047',
    Name: 'Blythedale',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NM_24048',
    Name: 'Twin Forks',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_MT_24049', Name: 'Winnett', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_SC_24050',
    Name: 'Mount Croghan',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_SC_24051',
    Name: 'Boykin',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_GA_24052',
    Name: 'Dewy Rose',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_OH_24053', Name: 'Raymond', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_24054', Name: 'Palestine', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AK_24055',
    Name: 'Port Lions',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_NE_24056', Name: 'Bee', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_KS_24057', Name: 'Milton', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_CO_24058', Name: 'Ophir', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_IA_24059',
    Name: 'Decatur City',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_KS_24060', Name: 'Luray', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OR_24061', Name: 'Helix', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_MI_24062',
    Name: 'Minden City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PA_24063',
    Name: 'Roseville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NV_24064',
    Name: 'Crystal Bay',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_ND_24065',
    Name: 'Buffalo',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_NM_24066',
    Name: 'San Cristobal',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_IA_24067', Name: 'Davis City', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OH_24068', Name: 'Custar', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OH_24069',
    Name: 'Fort Seneca',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_NE_24070',
    Name: 'Hyannis',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_PA_24071',
    Name: 'Fairview',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_24072',
    Name: 'Easton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WA_24073',
    Name: 'Alger',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KY_24074',
    Name: 'Maryhill Estates',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_OH_24075', Name: 'Trinway', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WY_24076',
    Name: 'Johnstown',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_IA_24077',
    Name: 'Garden City',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_MT_24078',
    Name: 'Broadview',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_ID_24079', Name: 'Crouch', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_MT_24080', Name: 'Rudyard', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_NE_24081', Name: 'Funk', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_MN_24082',
    Name: 'Hardwick',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AL_24083',
    Name: 'Gainesville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NC_24084',
    Name: 'Danbury',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_AR_24085',
    Name: 'Ratcliff',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_AZ_24086',
    Name: 'San Simon',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_KS_24087', Name: 'Offerle', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WI_24088',
    Name: 'Amberg',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_OH_24089', Name: 'Howard', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_24090', Name: 'Latty', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_24091',
    Name: 'Elk Creek',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MO_24092', Name: 'Nelson', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_KS_24093', Name: 'Ensign', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_NE_24094', Name: 'Wynot', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_MO_24095',
    Name: 'Grayridge',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OH_24096',
    Name: 'Sugar Bush Knolls',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_TN_24097', Name: 'Medon', stateId: 'TN', stateName: 'Tennessee'},
  {
    Id: 'CIT_US_NM_24098',
    Name: 'Nageezi',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_GA_24099',
    Name: 'Buckhead',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_AL_24100',
    Name: 'Glenwood',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_OR_24101', Name: 'Alpine', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_WA_24102',
    Name: 'Metaline',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_WI_24103',
    Name: 'Middle Village',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IA_24104', Name: 'Beaman', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MD_24105',
    Name: 'Choptank',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_KS_24106', Name: 'Whiting', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_MI_24107', Name: 'Carney', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_CA_24108',
    Name: 'Calpine',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IN_24109',
    Name: 'Dune Acres',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_OK_24110', Name: 'Slick', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_MO_24111',
    Name: 'Cowgill',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WI_24112',
    Name: 'Hebron',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_AL_24113',
    Name: 'Woodland',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_IL_24114', Name: 'Banner', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_NV_24115', Name: 'Mina', stateId: 'NV', stateName: 'Nevada'},
  {Id: 'CIT_US_OH_24116', Name: 'Macksburg', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OR_24117', Name: 'Hebo', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_MN_24118', Name: 'Frost', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_FL_24119', Name: 'Layton', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_AZ_24120',
    Name: 'Deer Creek',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_CA_24121',
    Name: 'Proberta',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_24122',
    Name: "Fountain N' Lakes",
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IL_24123',
    Name: 'Cleveland',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CO_24124',
    Name: 'Weldona',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_WV_24125',
    Name: 'Green Bank',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MO_24126',
    Name: 'Conception Junction',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NJ_24127',
    Name: 'Columbia',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_IL_24128',
    Name: 'New Grand Chain',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MN_24129', Name: 'Warba', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_MI_24130',
    Name: 'Copper City',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_VA_24131',
    Name: 'Greenbush',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NC_24132',
    Name: 'Bald Head Island',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_NM_24133',
    Name: 'Los Cerrillos',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_CO_24134',
    Name: 'Comanche Creek',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_IN_24135', Name: 'Modoc', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_PA_24136',
    Name: 'West Sunbury',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_24137', Name: 'Barnum', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_24138',
    Name: 'Bellerive Acres',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MO_24139',
    Name: 'Centerville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MO_24140', Name: 'Mokane', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_IN_24141',
    Name: 'Butlerville',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_WV_24142',
    Name: 'Valley Head',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_OK_24143',
    Name: 'Camargo',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MI_24144',
    Name: 'Michiana',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_WY_24145', Name: 'Deaver', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_ND_24146',
    Name: 'Edinburg',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_SD_24147',
    Name: 'Doland',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_SC_24148',
    Name: 'Kline',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_WY_24149',
    Name: 'Fort Bridger',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {Id: 'CIT_US_KS_24150', Name: 'Atlanta', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IL_24151',
    Name: 'Arlington',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_IA_24152', Name: 'Thor', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MI_24153', Name: 'Bendon', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_WI_24154',
    Name: 'Sayner',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_AL_24155',
    Name: 'Riverview',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_IA_24156', Name: 'Gravity', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_24157',
    Name: 'Mulkeytown',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_24158', Name: 'Gilboa', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_TX_24159', Name: 'Leona', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WV_24160',
    Name: 'Blacksville',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_AR_24161', Name: 'Watson', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_MN_24162',
    Name: 'Kennedy',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NJ_24163',
    Name: 'Loch Arbour',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_WY_24164',
    Name: 'Brookhurst',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_CA_24165',
    Name: 'Port Costa',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WI_24166',
    Name: 'Iron Belt',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MO_24167', Name: 'Amoret', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_WA_24168',
    Name: 'Nespelem Community',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MI_24169',
    Name: 'Maple Grove',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_PR_24170',
    Name: 'El Combate',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    Id: 'CIT_US_AR_24171',
    Name: 'Winthrop',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_24172',
    Name: 'Dudley',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_24173',
    Name: 'South Carrollton',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_OR_24174',
    Name: 'Tygh Valley',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_OK_24175', Name: 'Dale', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CO_24176',
    Name: 'Gerrard',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MT_24177',
    Name: 'Stockett',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_AK_24178', Name: 'Kokhanok', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_KY_24179',
    Name: 'Cambridge',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MD_24180',
    Name: 'Barnesville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_GA_24181',
    Name: 'The Rock',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_IN_24182',
    Name: 'Point Isabel',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IN_24183', Name: 'Crane', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_SC_24184',
    Name: 'Elko',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_NE_24185', Name: 'Walton', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_SD_24186',
    Name: 'Brant Lake South',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_KS_24187', Name: 'Dorrance', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MS_24188',
    Name: 'Braxton',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_WV_24189',
    Name: 'Anawalt',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_24190',
    Name: 'Van Voorhis',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_24191', Name: 'Shambaugh', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MN_24192',
    Name: 'Shelly',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MO_24193',
    Name: 'Sheridan',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_24194',
    Name: 'Courtland',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NE_24195',
    Name: 'Riverdale',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IL_24196',
    Name: 'Cooksville',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_24197',
    Name: 'Lake Cavanaugh',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_MO_24198',
    Name: 'Bennett Springs',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_SC_24199',
    Name: 'Paxville',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NE_24200',
    Name: 'Douglas',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_WI_24201',
    Name: 'Gotham',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MN_24202',
    Name: 'Finland',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_KS_24203', Name: 'Yoder', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_OK_24204', Name: 'Chewey', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_PA_24205',
    Name: 'Wilburton Number One',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AR_24206', Name: 'Moro', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_AK_24207', Name: 'Cantwell', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_AZ_24208',
    Name: 'Ali Chuk',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_TX_24209',
    Name: 'Bishop Hills',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_TX_24210', Name: 'Lasana', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_24211',
    Name: 'Modesto',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WV_24212',
    Name: 'Van',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MN_24213',
    Name: 'Millville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_OH_24214', Name: 'Cecil', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_24215',
    Name: 'Dutch Flat',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_24216', Name: 'Sherrill', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_24217', Name: 'Portsmouth', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MT_24218',
    Name: 'Trout Creek',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_VA_24219',
    Name: 'Stony Creek',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_KS_24220', Name: 'Detroit', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_NE_24221', Name: 'Otoe', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_OH_24222', Name: 'Zoar', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OH_24223', Name: 'Chatfield', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AL_24224',
    Name: 'Spruce Pine',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_AR_24225',
    Name: 'West Point',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_CA_24226',
    Name: 'North San Juan',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AZ_24227',
    Name: 'Palominas',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_CO_24228',
    Name: 'Amherst',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_TX_24229', Name: 'La Rosita', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NC_24230',
    Name: 'Colerain',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_KS_24231', Name: 'Ramona', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MO_24232',
    Name: 'Halfway',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MT_24233', Name: 'Power', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_CO_24234',
    Name: 'Silver Plume',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {Id: 'CIT_US_KS_24235', Name: 'Denison', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_OR_24236',
    Name: 'Jordan Valley',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_OR_24237', Name: 'Richland', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_AK_24238',
    Name: 'Port Graham',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_OH_24239', Name: 'Flat Rock', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IL_24240',
    Name: 'Hillview',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_SC_24241',
    Name: 'Pomaria',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_MO_24242', Name: 'Purdin', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_TX_24243', Name: 'Lozano', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_IL_24244',
    Name: 'Chesterfield',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_CA_24245',
    Name: 'Fairhaven',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_24246',
    Name: 'High Hill',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AK_24247',
    Name: 'Lowell Point',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_MO_24248', Name: 'Wasola', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_MO_24249', Name: 'Hurley', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_MT_24250', Name: 'Moore', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_NM_24251',
    Name: 'Quemado',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_24252',
    Name: 'Kennerdell',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_24253', Name: 'Congress', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_OR_24254',
    Name: 'Cloverdale',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_SD_24255',
    Name: 'Wessington',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_AK_24256', Name: 'Minto', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_IA_24257', Name: 'Hillsboro', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_24258',
    Name: 'Alfarata',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CA_24259',
    Name: 'Stevinson',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_NM_24260',
    Name: 'Arroyo Hondo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_OK_24261',
    Name: 'Gregory',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MO_24262',
    Name: 'Mill Spring',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AK_24263',
    Name: 'Coffman Cove',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_WI_24264', Name: 'Trego', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_NC_24265',
    Name: 'Pantego',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_IA_24266',
    Name: 'Rickardsville',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_TX_24267', Name: 'Mullin', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_OH_24268',
    Name: 'West Millgrove',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_IA_24269', Name: 'Magnolia', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_KS_24270',
    Name: 'Geuda Springs',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_KS_24271',
    Name: 'McCracken',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_NM_24272',
    Name: 'Elida',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_AL_24273', Name: 'Abanda', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_TX_24274',
    Name: 'Los Barreras',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IL_24275',
    Name: 'De Witt',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_ID_24276', Name: 'Wardner', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_ND_24277',
    Name: 'Edmore',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MS_24278',
    Name: 'Carrollton',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_AK_24279',
    Name: 'Alcan Border',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_SD_24280',
    Name: 'Letcher',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_WI_24281',
    Name: 'Ferryville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_AL_24282',
    Name: 'Broomtown',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {Id: 'CIT_US_KS_24283', Name: 'Delia', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_OR_24284',
    Name: 'Government Camp',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {
    Id: 'CIT_US_AZ_24285',
    Name: 'Jakes Corner',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NM_24286',
    Name: 'Truchas',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_TX_24287',
    Name: 'Campo Verde',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_KY_24288', Name: 'Rosine', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_TX_24289', Name: 'Driftwood', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MN_24290',
    Name: 'Shevlin',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WI_24291',
    Name: 'Westboro',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_MN_24292', Name: 'Lucan', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_MN_24293',
    Name: 'Mapleview',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MO_24294',
    Name: 'Lamar Heights',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IN_24295', Name: 'Economy', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_FL_24296', Name: 'Captiva', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_OK_24297',
    Name: 'Le Flore',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_OK_24298',
    Name: 'Cimarron City',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_KY_24299',
    Name: 'Cleaton',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MI_24300',
    Name: 'Pierson',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_ND_24301',
    Name: 'Arnegard',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_IA_24302', Name: 'Kirkville', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OH_24303', Name: 'Rochester', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CA_24304',
    Name: 'Philo',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_24305', Name: 'Pena', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_OH_24306', Name: 'Plainville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CO_24307',
    Name: 'Crowley',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_VA_24308',
    Name: 'Cleveland',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_AR_24309',
    Name: 'Houston',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_IL_24310',
    Name: 'Pulaski',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NE_24311',
    Name: 'Stamford',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_AZ_24312',
    Name: 'Sacate Village',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_IL_24313',
    Name: 'North Henderson',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OK_24314', Name: 'Bessie', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_MN_24315',
    Name: 'Williams',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IA_24316', Name: 'Coin', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SD_24317',
    Name: 'Kranzburg',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_CA_24318',
    Name: 'Chinese Camp',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_OH_24319',
    Name: 'Orangeville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_TX_24320', Name: 'Salineño', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_AR_24321',
    Name: 'Fifty-Six',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_IL_24322', Name: 'Berlin', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MN_24323',
    Name: 'Bellechester',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AL_24324', Name: 'Gu-Win', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_LA_24325',
    Name: 'Quitman',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_VA_24326',
    Name: 'Carrsville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_24327',
    Name: 'Foxburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_CO_24328',
    Name: 'Jackson Lake',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_VA_24329',
    Name: 'Churchville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_IA_24330',
    Name: 'Wallingford',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_UT_24331', Name: 'White Mesa', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_AL_24332', Name: 'Newbern', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_AK_24333', Name: 'Kaltag', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_OK_24334',
    Name: 'Spaulding',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_MN_24335',
    Name: 'Holland',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MT_24336',
    Name: 'Amsterdam',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_IA_24337', Name: 'Tingley', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_UT_24338',
    Name: 'Cannonville',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {Id: 'CIT_US_OH_24339', Name: 'Good Hope', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AR_24340', Name: 'Omaha', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_MO_24341', Name: 'Foley', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_OR_24342', Name: 'Adrian', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_WI_24343',
    Name: 'Ogdensburg',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MO_24344',
    Name: 'Hunnewell',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_ND_24345',
    Name: 'Petersburg',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_UT_24346', Name: 'Snowville', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_GA_24347', Name: 'De Soto', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_WV_24348',
    Name: 'Beards Fork',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_24349',
    Name: 'New Paris',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WY_24350',
    Name: 'Alpine Northwest',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_OK_24351',
    Name: 'Dry Creek',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WI_24352',
    Name: 'Cataract',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IL_24353', Name: 'Oconee', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MN_24354',
    Name: 'Felton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_KY_24355',
    Name: 'Millstone',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MN_24356',
    Name: 'Kettle River',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_KS_24357', Name: 'Allen', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_VA_24358',
    Name: 'Whitesville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WI_24359',
    Name: 'Boulder Junction',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_NE_24360', Name: 'Manley', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_ID_24361', Name: 'Murtaugh', stateId: 'ID', stateName: 'Idaho'},
  {Id: 'CIT_US_CA_24362', Name: 'Edna', stateId: 'CA', stateName: 'California'},
  {Id: 'CIT_US_UT_24363', Name: 'Junction', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_OK_24364',
    Name: 'Teresita',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_CA_24365',
    Name: 'Loma Mar',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_WA_24366',
    Name: 'Marcus',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_TX_24367', Name: 'El Quiote', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_KY_24368', Name: 'Allen', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_CA_24369',
    Name: 'Farmington',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_24370', Name: 'Lidderdale', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WI_24371',
    Name: 'Packwaukee',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MN_24372',
    Name: 'Elizabeth',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_IN_24373',
    Name: 'Star City',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_CA_24374', Name: 'Adin', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_MO_24375',
    Name: 'Halltown',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MO_24376',
    Name: 'Syracuse',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IA_24377', Name: 'Dickens', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MD_24378',
    Name: 'Mount Briar',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_GA_24379',
    Name: 'Tallulah Falls',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_OK_24380', Name: 'Sparks', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_OH_24381',
    Name: 'Stone Creek',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_SC_24382',
    Name: 'Woodford',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_PA_24383',
    Name: 'Lenhartsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_PA_24384',
    Name: 'Wellersburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MI_24385',
    Name: 'St. James',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_MN_24386',
    Name: 'Beaver Bay',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MO_24387',
    Name: 'Parnell',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_MN_24388',
    Name: 'Porter',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_AZ_24389',
    Name: 'East Globe',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_ID_24390',
    Name: 'Fernan Lake Village',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {Id: 'CIT_US_MT_24391', Name: 'Richey', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_IL_24392',
    Name: 'Hettick',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_VA_24393',
    Name: 'Gargatha',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_NJ_24394',
    Name: 'Strathmere',
    stateId: 'NJ',
    stateName: 'New Jersey',
  },
  {
    Id: 'CIT_US_PA_24395',
    Name: 'New Lebanon',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NE_24396',
    Name: 'Alexandria',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IL_24397',
    Name: 'Metcalf',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NM_24398',
    Name: 'Glen Acres',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MT_24399',
    Name: 'Gildford',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_KS_24400',
    Name: 'West Mineral',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_ND_24401',
    Name: 'Christine',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_IL_24402',
    Name: 'Claremont',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_TX_24403', Name: 'Adrian', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MI_24404', Name: 'Conway', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MO_24405',
    Name: 'Glen Echo Park',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_MO_24406', Name: 'Leslie', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_WI_24407',
    Name: 'Diamond Bluff',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_ND_24408',
    Name: 'Sanborn',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_CA_24409',
    Name: 'College City',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_ND_24410',
    Name: 'Taylor',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_CA_24411', Name: 'Ruth', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_MO_24412',
    Name: 'Eagle Rock',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OH_24413',
    Name: 'Kettlersville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_ID_24414', Name: 'Moore', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_OK_24415',
    Name: 'Notchietown',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_OR_24416',
    Name: 'Neahkahnie',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_KS_24417', Name: 'Garfield', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_MO_24418', Name: 'Miami', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MS_24419',
    Name: 'Louise',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_IA_24420',
    Name: 'Lambs Grove',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_IA_24421',
    Name: 'Barnes City',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_IN_24422', Name: 'Griffin', stateId: 'IN', stateName: 'Indiana'},
  {
    Id: 'CIT_US_NM_24423',
    Name: 'Seboyeta',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_OH_24424', Name: 'Glenford', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AR_24425',
    Name: 'Valley Springs',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_24426',
    Name: 'Utica',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MO_24427', Name: 'Renick', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_IN_24428',
    Name: 'Dunreith',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_AR_24429', Name: 'Tupelo', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_ND_24430',
    Name: 'Grandin',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_TX_24431',
    Name: 'Round Mountain',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_CO_24432',
    Name: 'Morgan Heights',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CA_24433',
    Name: 'Sierra Village',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CA_24434',
    Name: 'Sisquoc',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MT_24435', Name: 'Big Arm', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_NE_24436',
    Name: 'Mason City',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_VA_24437',
    Name: 'Hillsboro',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_SD_24438',
    Name: 'Norris',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_OH_24439',
    Name: 'Sarahsville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OH_24440', Name: 'Linndale', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_24441',
    Name: 'Albrightsville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_KS_24442', Name: 'Lehigh', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_ND_24443',
    Name: 'Binford',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MN_24444',
    Name: 'Palisade',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WV_24445',
    Name: 'Brenton',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_IA_24446', Name: 'Oakville', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_VA_24447', Name: 'Keller', stateId: 'VA', stateName: 'Virginia'},
  {Id: 'CIT_US_OK_24448', Name: 'Eldon', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CA_24449',
    Name: 'Lodoga',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_KS_24450',
    Name: 'White Cloud',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_CA_24451',
    Name: 'Tecopa',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_UT_24452',
    Name: 'Pine Valley',
    stateId: 'UT',
    stateName: 'Utah',
  },
  {
    Id: 'CIT_US_MI_24453',
    Name: 'Twining',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_SD_24454',
    Name: 'Roslyn',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_OH_24455',
    Name: 'Pleasant Plain',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_AL_24456',
    Name: 'Standing Rock',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_AL_24457',
    Name: 'Orrville',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_NC_24458',
    Name: 'Marietta',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_MI_24459', Name: 'Melvin', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_TX_24460',
    Name: 'El Camino Angosto',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_IN_24461',
    Name: 'Fredericksburg',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_AR_24462',
    Name: 'Washington',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_PA_24463',
    Name: 'New Baltimore',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IA_24464',
    Name: 'Pilot Mound',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_LA_24465',
    Name: 'Shongaloo',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_MO_24466',
    Name: 'Newtown',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_24467', Name: 'Melvin', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_FL_24468',
    Name: 'Horseshoe Beach',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_CO_24469',
    Name: 'Sunshine',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_CO_24470',
    Name: 'Idledale',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_KS_24471',
    Name: 'Fort Dodge',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {Id: 'CIT_US_IL_24472', Name: 'Sauget', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MT_24473',
    Name: 'Swan Lake',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_IL_24474',
    Name: 'Old Mill Creek',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_AR_24475', Name: 'Casa', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_SC_24476',
    Name: 'Silverstreet',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_NM_24477',
    Name: 'Soham',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MO_24478',
    Name: 'Sedgewickville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OK_24479',
    Name: 'Orlando',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_AR_24480',
    Name: 'McDougal',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_IA_24481', Name: 'Haverhill', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OK_24482', Name: 'Hunter', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_CA_24483',
    Name: 'Tooleville',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_MT_24484', Name: 'Lavina', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_SD_24485',
    Name: 'McIntosh',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_KY_24486',
    Name: 'Meadowview Estates',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MN_24487',
    Name: 'Winton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_24488', Name: 'Utopia', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_MS_24489',
    Name: 'Winstonville',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_KS_24490', Name: 'Muscotah', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_AR_24491',
    Name: 'Burdette',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MO_24492',
    Name: 'La Tour',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_TN_24493',
    Name: 'Iron City',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {Id: 'CIT_US_GA_24494', Name: 'Woolsey', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_AR_24495',
    Name: 'Sedgwick',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_LA_24496',
    Name: 'Lisbon',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_OK_24497',
    Name: 'Bromide',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NM_24498',
    Name: 'San Lorenzo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MN_24499',
    Name: 'Delavan',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AR_24500', Name: 'Rondo', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_AZ_24501', Name: 'Antares', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_PA_24502',
    Name: 'Monument',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_ND_24503',
    Name: 'Selfridge',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_IL_24504',
    Name: 'Calhoun',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_MO_24505', Name: 'Lohman', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_HI_24506', Name: 'Wainiha', stateId: 'HI', stateName: 'Hawaii'},
  {
    Id: 'CIT_US_PA_24507',
    Name: 'Force',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_24508',
    Name: 'Robeline',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NE_24509',
    Name: 'St. Libory',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_OK_24510', Name: 'Stuart', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_TX_24511',
    Name: 'Falcon Mesa',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_MT_24512',
    Name: 'West Glacier',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_WV_24513',
    Name: 'Salt Rock',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_VA_24514',
    Name: 'Troutdale',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_OH_24515', Name: 'Hessville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_CO_24516',
    Name: 'Florissant',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MO_24517',
    Name: 'Parkdale',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OK_24518',
    Name: 'Pump Back',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_MD_24519', Name: 'Dargan', stateId: 'MD', stateName: 'Maryland'},
  {
    Id: 'CIT_US_OK_24520',
    Name: 'Gene Autry',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_GA_24521', Name: 'Rebecca', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_OR_24522',
    Name: 'Grass Valley',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_IL_24523', Name: 'Butler', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_WI_24524',
    Name: 'Danbury',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_CO_24525', Name: 'Rye', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_KY_24526', Name: 'Coxton', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_OR_24527',
    Name: 'Riverside',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_KS_24528', Name: 'Woodbine', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MD_24529',
    Name: 'Barclay',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_MN_24530', Name: 'Steen', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_KY_24531',
    Name: 'Sycamore',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_IL_24532',
    Name: 'Smithboro',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_PA_24533',
    Name: 'Starrucca',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MN_24534',
    Name: 'Ball Club',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_SD_24535',
    Name: 'Mansfield',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_MS_24536',
    Name: 'French Camp',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_MT_24537', Name: 'Elmo', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_NE_24538', Name: 'Farnam', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_MD_24539',
    Name: 'Burkittsville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_DE_24540', Name: 'Viola', stateId: 'DE', stateName: 'Delaware'},
  {
    Id: 'CIT_US_ND_24541',
    Name: 'Rhame',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_IL_24542',
    Name: 'Old Shawneetown',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_KS_24543', Name: 'Albert', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_OH_24544',
    Name: 'North Lawrence',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_OH_24545', Name: 'Lewisville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_KY_24546',
    Name: 'Mockingbird Valley',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_KS_24547', Name: 'Bern', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_IA_24548', Name: 'Soldier', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_KY_24549', Name: 'Combs', stateId: 'KY', stateName: 'Kentucky'},
  {
    Id: 'CIT_US_AK_24550',
    Name: 'Holy Cross',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_OH_24551', Name: 'Burgoon', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OR_24552', Name: 'Neskowin', stateId: 'OR', stateName: 'Oregon'},
  {
    Id: 'CIT_US_SD_24553',
    Name: 'Richland',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_OH_24554', Name: 'Alvordton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_PA_24555',
    Name: 'Coburn',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_AL_24556', Name: 'Banks', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_KS_24557', Name: 'Corning', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IN_24558',
    Name: 'Cannelburg',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_SD_24559',
    Name: 'Peever',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_SD_24560',
    Name: 'Mission Hill',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_MO_24561', Name: 'Coffey', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_OK_24562',
    Name: 'Manitou',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_NC_24563',
    Name: 'Evergreen',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_24564',
    Name: 'Deming',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IA_24565', Name: 'Randall', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_ND_24566',
    Name: 'Golden Valley',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_IA_24567', Name: 'Dundee', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_24568',
    Name: 'West Point',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_AZ_24569', Name: 'Vernon', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_IA_24570', Name: 'Rose Hill', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IL_24571', Name: 'Huey', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_MI_24572', Name: 'Loomis', stateId: 'MI', stateName: 'Michigan'},
  {Id: 'CIT_US_TX_24573', Name: 'Jolly', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NM_24574',
    Name: 'Corona',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_MN_24575',
    Name: 'Wendell',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_CA_24576',
    Name: 'Bluewater',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_SD_24577',
    Name: 'Volin',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_WI_24578',
    Name: 'Lime Ridge',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_24579',
    Name: 'Rehrersburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_VA_24580',
    Name: 'Scotland',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_PA_24581',
    Name: 'McKnightstown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_FL_24582',
    Name: 'Istachatta',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {
    Id: 'CIT_US_KS_24583',
    Name: 'Westphalia',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_PA_24584',
    Name: 'Adamsburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_24585',
    Name: 'Des Arc',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_TX_24586', Name: 'Weinert', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_PA_24587',
    Name: 'Georgetown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_24588',
    Name: 'Baring',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_IN_24589',
    Name: 'Buck Creek',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_VA_24590',
    Name: 'Catlett',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_OK_24591',
    Name: 'Lookeba',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WI_24592',
    Name: 'Barronett',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_LA_24593',
    Name: 'Longstreet',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_AZ_24594',
    Name: 'Pinion Pines',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_NE_24595',
    Name: 'Rosalie',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IL_24596',
    Name: 'Maeystown',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_ND_24597',
    Name: 'Streeter',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_MN_24598',
    Name: 'Kingston',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_FL_24599', Name: 'Fidelis', stateId: 'FL', stateName: 'Florida'},
  {
    Id: 'CIT_US_NC_24600',
    Name: 'Kittrell',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_WI_24601', Name: 'Ogema', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_OH_24602',
    Name: 'Butlerville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_ID_24603', Name: 'Ferdinand', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_SD_24604',
    Name: 'Whitehorse',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IA_24605', Name: 'Alexander', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IA_24606',
    Name: 'Little Sioux',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_MO_24607', Name: 'Mendon', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_KS_24608', Name: 'Burr Oak', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_GA_24609',
    Name: 'Junction City',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {Id: 'CIT_US_CO_24610', Name: 'Ward', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_IA_24611', Name: 'Aurora', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_24612',
    Name: 'Blue Eye',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_FL_24613',
    Name: 'Mount Carmel',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_IA_24614', Name: 'Woolstock', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_OK_24615', Name: 'Foster', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_AK_24616', Name: 'Golovin', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_WV_24617',
    Name: 'St. George',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_24618',
    Name: 'Hannasville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KS_24619',
    Name: 'Circleville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_MO_24620',
    Name: 'Freistatt',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_AK_24621',
    Name: 'Allakaket',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_OK_24622',
    Name: 'St. Louis',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WV_24623',
    Name: 'Reedy',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_OH_24624', Name: 'Fultonham', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_OR_24625', Name: 'Idanha', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_IA_24626', Name: 'Patterson', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WA_24627',
    Name: 'Alder',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IA_24628', Name: 'Vincent', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NE_24629',
    Name: 'Venango',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IN_24630',
    Name: 'Salamonia',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_KS_24631', Name: 'Ada', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_NE_24632', Name: 'Rulo', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_LA_24633',
    Name: 'Palmetto',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_WV_24634',
    Name: 'Green Spring',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IL_24635',
    Name: 'Littleton',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_24636', Name: 'Sparta', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MT_24637',
    Name: 'Lonepine',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_WV_24638',
    Name: 'Rhodell',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_OH_24639', Name: 'Cloverdale', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AK_24640',
    Name: 'Seldovia Village',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_MI_24641',
    Name: 'Lake Leelanau',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {
    Id: 'CIT_US_OK_24642',
    Name: 'Wainwright',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_SC_24643',
    Name: 'Cordova',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_MO_24644', Name: 'Argyle', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_TX_24645',
    Name: "Miller's Cove",
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_AZ_24646',
    Name: 'Arlington',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_LA_24647',
    Name: 'Moonshine',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_PA_24648',
    Name: 'Longfellow',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_KY_24649',
    Name: 'LaFayette',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WA_24650',
    Name: 'Willapa',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_NM_24651', Name: 'Lamy', stateId: 'NM', stateName: 'New Mexico'},
  {
    Id: 'CIT_US_NM_24652',
    Name: 'Blanco',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_NM_24653',
    Name: 'Alcalde',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_OK_24654',
    Name: 'Burlington',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_UT_24655', Name: 'Tabiona', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_AR_24656',
    Name: 'Winchester',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_IA_24657',
    Name: 'Oakland Acres',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_GA_24658',
    Name: 'Santa Claus',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_AL_24659',
    Name: 'Talladega Springs',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_AR_24660',
    Name: 'Bassett',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_KY_24661',
    Name: 'Prestonville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IL_24662', Name: 'Nelson', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_WY_24663', Name: 'Frannie', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_NE_24664', Name: 'Daykin', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_FL_24665',
    Name: 'Fort Green Springs',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_ID_24666', Name: 'Midvale', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_MI_24667',
    Name: 'Hickory Corners',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_KS_24668', Name: 'Winona', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NE_24669',
    Name: 'Ulysses',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_GA_24670', Name: 'Alston', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_AZ_24671', Name: 'McNeal', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_CA_24672', Name: 'Pike', stateId: 'CA', stateName: 'California'},
  {
    Id: 'CIT_US_WI_24673',
    Name: 'Ellison Bay',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_PA_24674',
    Name: 'Rew',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_AR_24675',
    Name: 'Fountain Hill',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_IL_24676', Name: 'Redmon', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_NV_24677',
    Name: 'Humboldt River Ranch',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_NE_24678',
    Name: 'Goehner',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_NY_24679',
    Name: 'Bloomville',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_NE_24680', Name: 'Page', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_KY_24681',
    Name: 'Booneville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_WV_24682',
    Name: 'Fenwick',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_MT_24683', Name: 'Rexford', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_OH_24684', Name: 'Plainfield', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_KS_24685', Name: 'Hardtner', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_NE_24686', Name: 'Verdon', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_IA_24687', Name: 'Leighton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_24688',
    Name: 'Garrett',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WA_24689',
    Name: 'Pine Grove',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_VA_24690',
    Name: 'Templeton',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_MT_24691',
    Name: 'Paradise',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {Id: 'CIT_US_IN_24692', Name: 'Scipio', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_WI_24693', Name: 'Chili', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_MN_24694',
    Name: 'Blomkest',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_24695',
    Name: 'Northwood',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_IA_24696', Name: 'Castalia', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_AK_24697', Name: 'Lutak', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_IA_24698', Name: 'Swaledale', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NV_24699',
    Name: 'Silver City',
    stateId: 'NV',
    stateName: 'Nevada',
  },
  {
    Id: 'CIT_US_CA_24700',
    Name: 'Downieville',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_GA_24701',
    Name: 'Summertown',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_AZ_24702',
    Name: 'Kohls Ranch',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {
    Id: 'CIT_US_WI_24703',
    Name: 'Greenbush',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NE_24704',
    Name: 'La Platte',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_KS_24705',
    Name: 'Kickapoo Tribal Center',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_AR_24706',
    Name: 'Chester',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_MN_24707', Name: 'Boyd', stateId: 'MN', stateName: 'Minnesota'},
  {Id: 'CIT_US_KS_24708', Name: 'McDonald', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MO_24709',
    Name: 'Rockville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IL_24710', Name: 'Campus', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_ND_24711',
    Name: 'Regent',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_IL_24712',
    Name: 'Reddick',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_IL_24713',
    Name: 'Rockbridge',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_24714', Name: 'Montezuma', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_IA_24715', Name: 'Calumet', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OK_24716',
    Name: 'Cedar Crest',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_IA_24717', Name: 'Harris', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_CA_24718',
    Name: 'Mettler',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MN_24719',
    Name: 'Clontarf',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MO_24720',
    Name: 'Hurdland',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WV_24721',
    Name: 'Page',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_NE_24722',
    Name: 'Washington',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_WY_24723',
    Name: 'Clearmont',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_TX_24724',
    Name: 'Villarreal',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {
    Id: 'CIT_US_OK_24725',
    Name: 'Twin Oaks',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_KS_24726',
    Name: 'Rush Center',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_AR_24727',
    Name: 'Gillham',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_MT_24728',
    Name: 'Maxville',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_NC_24729',
    Name: 'Gulf',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_KS_24730', Name: 'Randolph', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_TN_24731',
    Name: 'Bowman',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_CA_24732',
    Name: 'Bieber',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_AK_24733', Name: 'Coldfoot', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_OK_24734',
    Name: 'Grayson',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_KS_24735', Name: 'Iuka', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_ND_24736',
    Name: 'Rutland',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_CA_24737',
    Name: 'Alderpoint',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_TX_24738', Name: 'Sanford', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WI_24739',
    Name: 'Arkdale',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_KS_24740', Name: 'Kirwin', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_MN_24741', Name: 'Elba', stateId: 'MN', stateName: 'Minnesota'},
  {
    Id: 'CIT_US_MD_24742',
    Name: 'Hillsboro',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_MN_24743',
    Name: 'Coates',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AL_24744', Name: 'Hobson', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_WV_24745',
    Name: 'Pentress',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_IA_24746', Name: 'Moorland', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_ND_24747',
    Name: 'Brooktree Park',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_CO_24748',
    Name: 'Tall Timber',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_ND_24749',
    Name: 'Kensal',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_MT_24750', Name: 'Avon', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_IA_24751', Name: 'Randolph', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_MO_24752',
    Name: 'Clearmont',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WI_24753',
    Name: 'Marquette',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_KY_24754',
    Name: 'Allensville',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_PA_24755',
    Name: 'Ronco',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_OK_24756',
    Name: 'Cornish',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_VA_24757',
    Name: 'Yogaville',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_OK_24758', Name: 'Osage', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_AR_24759', Name: 'Ogden', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_MS_24760',
    Name: 'Henderson Point',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {Id: 'CIT_US_KS_24761', Name: 'Fulton', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_SD_24762',
    Name: 'Monroe',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_IA_24763', Name: 'Reasnor', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_24764', Name: 'McClelland', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WI_24765',
    Name: 'Fenwood',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_HI_24766', Name: 'Haena', stateId: 'HI', stateName: 'Hawaii'},
  {Id: 'CIT_US_MO_24767', Name: 'Graham', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_CA_24768',
    Name: 'Flournoy',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_AK_24769',
    Name: 'Pitkas Point',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_UT_24770', Name: 'Kingston', stateId: 'UT', stateName: 'Utah'},
  {
    Id: 'CIT_US_KS_24771',
    Name: 'Havensville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_IL_24772',
    Name: 'Crab Orchard',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NY_24773',
    Name: 'Cranberry Lake',
    stateId: 'NY',
    stateName: 'New York',
  },
  {Id: 'CIT_US_AR_24774', Name: 'Ulm', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_PA_24775',
    Name: 'Bear Lake',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NC_24776',
    Name: 'Bolivia',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MO_24777',
    Name: 'Middletown',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OK_24778',
    Name: 'Redbird',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_IA_24779', Name: 'Monmouth', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_AK_24780', Name: 'Ruby', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_AZ_24781', Name: 'Alpine', stateId: 'AZ', stateName: 'Arizona'},
  {Id: 'CIT_US_NE_24782', Name: 'Davey', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_KS_24783', Name: 'Manter', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_WV_24784',
    Name: 'Comfort',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {Id: 'CIT_US_WY_24785', Name: 'Yoder', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_OH_24786', Name: 'Glencoe', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_AK_24787',
    Name: 'Harding-Birch Lakes',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {Id: 'CIT_US_MT_24788', Name: 'Westby', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_VA_24789',
    Name: 'Thynedale',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_WA_24790',
    Name: 'Hartline',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IA_24791', Name: 'Exline', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_WA_24792',
    Name: 'Lake McMurray',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_KS_24793', Name: 'Kanorado', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_MO_24794', Name: 'Bogard', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_OK_24795', Name: 'Norge', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_GA_24796',
    Name: 'Allentown',
    stateId: 'GA',
    stateName: 'Georgia',
  },
  {
    Id: 'CIT_US_WA_24797',
    Name: 'Chinook',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_AK_24798', Name: 'Gakona', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_PA_24799',
    Name: 'Laurel Mountain',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_IL_24800',
    Name: 'Concord',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_NE_24801',
    Name: 'Republican City',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_IL_24802',
    Name: 'White Heath',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_OH_24803', Name: 'Parkman', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_MO_24804', Name: 'Dawn', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_CA_24805',
    Name: 'Trinity Center',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_CO_24806',
    Name: 'Blue Sky',
    stateId: 'CO',
    stateName: 'Colorado',
  },
  {
    Id: 'CIT_US_MT_24807',
    Name: 'Little Bitterroot Lake',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_NH_24808',
    Name: 'Center Sandwich',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {Id: 'CIT_US_WI_24809', Name: 'Lewis', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_NY_24810',
    Name: 'Winthrop',
    stateId: 'NY',
    stateName: 'New York',
  },
  {
    Id: 'CIT_US_NM_24811',
    Name: 'Hillsboro',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_PA_24812',
    Name: 'Webster',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WA_24813',
    Name: 'Glacier',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_IA_24814', Name: 'Williamson', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_24815',
    Name: 'Elvaston',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_AK_24816', Name: 'Kobuk', stateId: 'AK', stateName: 'Alaska'},
  {Id: 'CIT_US_AK_24817', Name: 'Ouzinkie', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_SC_24818',
    Name: 'Sycamore',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OH_24819', Name: 'Amesville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_IA_24820',
    Name: 'Mount Union',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_OK_24821',
    Name: 'Steely Hollow',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_VA_24822',
    Name: 'New Castle',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {
    Id: 'CIT_US_KY_24823',
    Name: 'Columbus',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_IA_24824', Name: 'Brunsville', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_MO_24825', Name: 'Stella', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_WV_24826',
    Name: 'Montrose',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_WV_24827',
    Name: 'Kimball',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_SC_24828',
    Name: 'Vance',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {
    Id: 'CIT_US_WI_24829',
    Name: 'Houlton',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NC_24830',
    Name: 'Lansing',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_CO_24831', Name: 'Grover', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_KY_24832',
    Name: 'Wheatcroft',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {Id: 'CIT_US_NE_24833', Name: 'Cody', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_KY_24834',
    Name: 'Farmington',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MN_24835',
    Name: 'Grasston',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_TX_24836', Name: 'Oilton', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IL_24837', Name: 'Dover', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_VA_24838',
    Name: 'Fairview',
    stateId: 'VA',
    stateName: 'Virginia',
  },
  {Id: 'CIT_US_IA_24839', Name: 'Geneva', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_ID_24840',
    Name: 'St. Charles',
    stateId: 'ID',
    stateName: 'Idaho',
  },
  {
    Id: 'CIT_US_AK_24841',
    Name: 'Tanacross',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_FL_24842',
    Name: 'Dickerson City',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_KS_24843', Name: 'Barnes', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_NC_24844',
    Name: 'Boardman',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_MO_24845',
    Name: 'Collins',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_IA_24846',
    Name: 'Braddyville',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_WY_24847', Name: 'Osage', stateId: 'WY', stateName: 'Wyoming'},
  {Id: 'CIT_US_IL_24848', Name: 'Coleta', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_SD_24849',
    Name: 'Isabel',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_PA_24850',
    Name: 'Slabtown',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_LA_24851',
    Name: 'Gilliam',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_NE_24852',
    Name: 'Concord',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_OK_24853', Name: 'Rocky', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_KS_24854', Name: 'Admire', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_ND_24855',
    Name: 'Colfax',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_OK_24856',
    Name: 'Cowlington',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WV_24857',
    Name: 'Pax',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_SC_24858',
    Name: 'Salem',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_OH_24859', Name: 'Hemlock', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_24860',
    Name: 'Farmington',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_IN_24861',
    Name: 'Cedar Grove',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_ND_24862',
    Name: 'Tolna',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_IA_24863', Name: 'Hastings', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OK_24864',
    Name: 'Woodlawn Park',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_IA_24865', Name: 'Homestead', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_24866',
    Name: 'Crabtree',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WV_24867',
    Name: 'Arbovale',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_PA_24868',
    Name: 'Leeper',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_24869',
    Name: 'Brandsville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_AL_24870', Name: 'Geiger', stateId: 'AL', stateName: 'Alabama'},
  {
    Id: 'CIT_US_OK_24871',
    Name: 'Council Hill',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_IN_24872',
    Name: 'Crandall',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {Id: 'CIT_US_IA_24873', Name: 'Delaware', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_NE_24874',
    Name: 'Sprague',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_AZ_24875',
    Name: 'Beaver Valley',
    stateId: 'AZ',
    stateName: 'Arizona',
  },
  {Id: 'CIT_US_VA_24876', Name: 'Capron', stateId: 'VA', stateName: 'Virginia'},
  {
    Id: 'CIT_US_KY_24877',
    Name: 'Rochester',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_MO_24878',
    Name: 'Diehlstadt',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_SD_24879',
    Name: 'Frankfort',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_LA_24880', Name: 'Eros', stateId: 'LA', stateName: 'Louisiana'},
  {
    Id: 'CIT_US_IN_24881',
    Name: 'Metamora',
    stateId: 'IN',
    stateName: 'Indiana',
  },
  {
    Id: 'CIT_US_OK_24882',
    Name: 'Indianola',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_MO_24883', Name: 'Ozora', stateId: 'MO', stateName: 'Missouri'},
  {Id: 'CIT_US_AK_24884', Name: 'Wales', stateId: 'AK', stateName: 'Alaska'},
  {
    Id: 'CIT_US_MN_24885',
    Name: 'Campbell',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WI_24886',
    Name: 'Nelsonville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_IL_24887', Name: 'Ridott', stateId: 'IL', stateName: 'Illinois'},
  {Id: 'CIT_US_AR_24888', Name: 'Louann', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_WI_24889',
    Name: 'Summit Lake',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_NH_24890',
    Name: 'Melvin Village',
    stateId: 'NH',
    stateName: 'New Hampshire',
  },
  {
    Id: 'CIT_US_PA_24891',
    Name: 'Volant',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_NM_24892',
    Name: 'Abiquiu',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_WI_24893',
    Name: 'Navarino',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_IA_24894',
    Name: 'Bridgewater',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_IA_24895',
    Name: 'Green Mountain',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_MO_24896',
    Name: 'Blackwater',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_OK_24897',
    Name: 'Warwick',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WY_24898',
    Name: 'Crowheart',
    stateId: 'WY',
    stateName: 'Wyoming',
  },
  {
    Id: 'CIT_US_MN_24899',
    Name: 'Waltham',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_IA_24900', Name: 'Rowan', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_KY_24901',
    Name: 'Lincolnshire',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_ND_24902',
    Name: 'Litchville',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_OK_24903', Name: 'Lamar', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_MN_24904',
    Name: 'Longville',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MA_24905',
    Name: 'Popponesset',
    stateId: 'MA',
    stateName: 'Massachusetts',
  },
  {
    Id: 'CIT_US_SC_24906',
    Name: 'Waterloo',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_IL_24907', Name: 'Dana', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MO_24908',
    Name: 'Redings Mill',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_WI_24909', Name: 'Cable', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_MO_24910',
    Name: 'St. Clement',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_NY_24911', Name: 'Hall', stateId: 'NY', stateName: 'New York'},
  {
    Id: 'CIT_US_MN_24912',
    Name: 'Bellingham',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_NM_24913',
    Name: 'Buckhorn',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_OK_24914',
    Name: 'Connerville',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {Id: 'CIT_US_OH_24915', Name: 'Jerusalem', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_ND_24916',
    Name: 'Willow City',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_PA_24917',
    Name: 'Landingville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_MO_24918', Name: 'Elmo', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MO_24919',
    Name: 'Alexandria',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_KY_24920', Name: 'Ekron', stateId: 'KY', stateName: 'Kentucky'},
  {Id: 'CIT_US_AR_24921', Name: 'Rudy', stateId: 'AR', stateName: 'Arkansas'},
  {
    Id: 'CIT_US_IL_24922',
    Name: 'Papineau',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_KY_24923',
    Name: 'Germantown',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_AR_24924',
    Name: 'Mount Vernon',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {Id: 'CIT_US_MT_24925', Name: 'Brady', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_OK_24926', Name: 'Tatums', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_OK_24927', Name: 'Martha', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_NM_24928',
    Name: 'Tecolote',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {
    Id: 'CIT_US_CA_24929',
    Name: 'Waukena',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_MO_24930',
    Name: 'Tallapoosa',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_PA_24931',
    Name: 'McKeansburg',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MS_24932',
    Name: 'West',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_ND_24933',
    Name: 'Wildrose',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_IA_24934', Name: 'Bristow', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_24935', Name: 'Palmer', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_PA_24936',
    Name: 'Kiskimere',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_24937', Name: 'Dola', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_MD_24938',
    Name: 'Smith Island',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_PR_24939',
    Name: 'Anon Raices',
    stateId: 'PR',
    stateName: 'Puerto Rico',
  },
  {Id: 'CIT_US_CO_24940', Name: 'Lewis', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_MO_24941', Name: 'Turney', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MO_24942',
    Name: 'Flemington',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OR_24943', Name: 'Spray', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_IA_24944', Name: 'Meriden', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_CO_24945', Name: 'Genoa', stateId: 'CO', stateName: 'Colorado'},
  {Id: 'CIT_US_IL_24946', Name: 'Lima', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_MO_24947',
    Name: 'Gravois Mills',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IA_24948', Name: 'Lincoln', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_IL_24949',
    Name: 'Longview',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_24950',
    Name: 'Excelsior Estates',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_WV_24951',
    Name: 'Sand Fork',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_CA_24952',
    Name: 'Nicolaus',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NE_24953', Name: 'Ithaca', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_ND_24954',
    Name: 'Reeder',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {Id: 'CIT_US_GA_24955', Name: 'Girard', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_SC_24956',
    Name: 'Hodges',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_MO_24957', Name: 'Tina', stateId: 'MO', stateName: 'Missouri'},
  {
    Id: 'CIT_US_MN_24958',
    Name: 'Elkton',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_WV_24959',
    Name: 'Leon',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MD_24960',
    Name: 'Tyaskin',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_WV_24961',
    Name: 'Worthington',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_CA_24962',
    Name: 'Bear Creek',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_IA_24963', Name: 'Welton', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SD_24964',
    Name: 'Goodwin',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_NE_24965',
    Name: 'Hordville',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {Id: 'CIT_US_AZ_24966', Name: 'Why', stateId: 'AZ', stateName: 'Arizona'},
  {
    Id: 'CIT_US_IL_24967',
    Name: 'Whiteash',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_WI_24968',
    Name: 'Pine River',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_SD_24969',
    Name: 'Spencer',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_MT_24970', Name: 'Plevna', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_LA_24971',
    Name: 'Pioneer',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {
    Id: 'CIT_US_AR_24972',
    Name: 'Anthonyville',
    stateId: 'AR',
    stateName: 'Arkansas',
  },
  {
    Id: 'CIT_US_AL_24973',
    Name: 'Peterman',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MN_24974',
    Name: 'Mentor',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_AR_24975', Name: 'Reed', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_IA_24976', Name: 'Searsboro', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_OR_24977',
    Name: 'Deer Island',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_OH_24978', Name: 'Rarden', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_KS_24979', Name: 'Beverly', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_TX_24980',
    Name: 'Palo Pinto',
    stateId: 'TX',
    stateName: 'Texas',
  },
  {Id: 'CIT_US_OR_24981', Name: 'Barlow', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_OH_24982', Name: 'Clifton', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_NC_24983',
    Name: 'Milton',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {
    Id: 'CIT_US_WA_24984',
    Name: 'Edison',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {
    Id: 'CIT_US_KS_24985',
    Name: 'Morrowville',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_IA_24986',
    Name: 'West Chester',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_MS_24987',
    Name: 'Big Creek',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_TN_24988',
    Name: 'Normandy',
    stateId: 'TN',
    stateName: 'Tennessee',
  },
  {
    Id: 'CIT_US_KS_24989',
    Name: 'Summerfield',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_OH_24990',
    Name: 'Pottery Addition',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {Id: 'CIT_US_ID_24991', Name: 'Elk City', stateId: 'ID', stateName: 'Idaho'},
  {
    Id: 'CIT_US_MT_24992',
    Name: 'Gallatin River Ranch',
    stateId: 'MT',
    stateName: 'Montana',
  },
  {
    Id: 'CIT_US_MI_24993',
    Name: 'Harrietta',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_TX_24994', Name: 'Powell', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_IA_24995', Name: 'Linn Grove', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_TX_24996', Name: 'Nesbitt', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_WA_24997',
    Name: 'Maple Falls',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_WI_24998', Name: 'Boaz', stateId: 'WI', stateName: 'Wisconsin'},
  {
    Id: 'CIT_US_KY_24999',
    Name: 'Hills and Dales',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_OH_25000',
    Name: 'Milton Center',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MN_25001',
    Name: 'Dunnell',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MD_25002',
    Name: 'National',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {
    Id: 'CIT_US_ND_25003',
    Name: 'Crary',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_WV_25004',
    Name: 'Smithfield',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_MO_25005',
    Name: 'Rosendale',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_OR_25006', Name: 'Dayville', stateId: 'OR', stateName: 'Oregon'},
  {Id: 'CIT_US_KS_25007', Name: 'Morland', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_IA_25008',
    Name: 'Springbrook',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {
    Id: 'CIT_US_LA_25009',
    Name: 'Atlanta',
    stateId: 'LA',
    stateName: 'Louisiana',
  },
  {Id: 'CIT_US_KS_25010', Name: 'Preston', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_CA_25011',
    Name: 'Pine Flat',
    stateId: 'CA',
    stateName: 'California',
  },
  {Id: 'CIT_US_NE_25012', Name: 'Hardy', stateId: 'NE', stateName: 'Nebraska'},
  {Id: 'CIT_US_GA_25013', Name: 'Jakin', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_IL_25014',
    Name: 'Wheeler',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {Id: 'CIT_US_GA_25015', Name: 'Jersey', stateId: 'GA', stateName: 'Georgia'},
  {
    Id: 'CIT_US_MN_25016',
    Name: 'Erhard',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_PA_25017',
    Name: 'Timblin',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {Id: 'CIT_US_OH_25018', Name: 'Leesville', stateId: 'OH', stateName: 'Ohio'},
  {
    Id: 'CIT_US_WA_25019',
    Name: 'Banks Lake South',
    stateId: 'WA',
    stateName: 'Washington',
  },
  {Id: 'CIT_US_MT_25020', Name: 'Olney', stateId: 'MT', stateName: 'Montana'},
  {Id: 'CIT_US_TX_25021', Name: 'Emhouse', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_KS_25022',
    Name: 'Fall River',
    stateId: 'KS',
    stateName: 'Kansas',
  },
  {
    Id: 'CIT_US_IL_25023',
    Name: 'Standard City',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MO_25024',
    Name: 'Big Lake',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IA_25025', Name: 'Whitten', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_SC_25026',
    Name: 'Windsor',
    stateId: 'SC',
    stateName: 'South Carolina',
  },
  {Id: 'CIT_US_UT_25027', Name: 'Fairfield', stateId: 'UT', stateName: 'Utah'},
  {Id: 'CIT_US_GA_25028', Name: 'Nunez', stateId: 'GA', stateName: 'Georgia'},
  {Id: 'CIT_US_OK_25029', Name: 'Foss', stateId: 'OK', stateName: 'Oklahoma'},
  {Id: 'CIT_US_TX_25030', Name: 'Austwell', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_CA_25031',
    Name: 'Montgomery Creek',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_PA_25032',
    Name: 'Alba',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_25033',
    Name: 'Jacksonville',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_SD_25034',
    Name: 'Northville',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {
    Id: 'CIT_US_OK_25035',
    Name: 'Lawrence Creek',
    stateId: 'OK',
    stateName: 'Oklahoma',
  },
  {
    Id: 'CIT_US_WI_25036',
    Name: 'Grand Marsh',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {Id: 'CIT_US_CO_25037', Name: 'Snyder', stateId: 'CO', stateName: 'Colorado'},
  {
    Id: 'CIT_US_AL_25038',
    Name: 'North Johns',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_WV_25039',
    Name: 'Camden-on-Gauley',
    stateId: 'WV',
    stateName: 'West Virginia',
  },
  {
    Id: 'CIT_US_IA_25040',
    Name: 'Mount Auburn',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_IA_25041', Name: 'Mona', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IA_25042', Name: 'Waterville', stateId: 'IA', stateName: 'Iowa'},
  {
    Id: 'CIT_US_FL_25043',
    Name: 'Cloud Lake',
    stateId: 'FL',
    stateName: 'Florida',
  },
  {Id: 'CIT_US_KS_25044', Name: 'Sharon', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_AR_25045', Name: 'Staves', stateId: 'AR', stateName: 'Arkansas'},
  {Id: 'CIT_US_OK_25046', Name: 'Kemp', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_SD_25047',
    Name: 'Buffalo Gap',
    stateId: 'SD',
    stateName: 'South Dakota',
  },
  {Id: 'CIT_US_NE_25048', Name: 'Oconto', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_OH_25049',
    Name: 'Wilkesville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_WI_25050',
    Name: 'Drummond',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_CA_25051',
    Name: 'Casmalia',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IA_25052',
    Name: 'New Liberty',
    stateId: 'IA',
    stateName: 'Iowa',
  },
  {Id: 'CIT_US_KS_25053', Name: 'Cuba', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MN_25054',
    Name: 'Kilkenny',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_DE_25055',
    Name: 'Henlopen Acres',
    stateId: 'DE',
    stateName: 'Delaware',
  },
  {
    Id: 'CIT_US_ND_25056',
    Name: 'Makoti',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
  {
    Id: 'CIT_US_OR_25057',
    Name: 'Summerville',
    stateId: 'OR',
    stateName: 'Oregon',
  },
  {Id: 'CIT_US_MI_25058', Name: 'Ahmeek', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_NC_25059',
    Name: 'Keener',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_MI_25060', Name: 'Omena', stateId: 'MI', stateName: 'Michigan'},
  {
    Id: 'CIT_US_MD_25061',
    Name: 'Brookeville',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_OH_25062', Name: 'Belmore', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_AL_25063', Name: 'Waverly', stateId: 'AL', stateName: 'Alabama'},
  {Id: 'CIT_US_OK_25064', Name: 'Dennis', stateId: 'OK', stateName: 'Oklahoma'},
  {
    Id: 'CIT_US_AK_25065',
    Name: 'Fort Greely',
    stateId: 'AK',
    stateName: 'Alaska',
  },
  {
    Id: 'CIT_US_PA_25066',
    Name: 'Jamison City',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_MO_25067',
    Name: 'Wentworth',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_CA_25068',
    Name: 'Tupman',
    stateId: 'CA',
    stateName: 'California',
  },
  {
    Id: 'CIT_US_IL_25069',
    Name: 'LaPlace',
    stateId: 'IL',
    stateName: 'Illinois',
  },
  {
    Id: 'CIT_US_MN_25070',
    Name: 'Conger',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {Id: 'CIT_US_KS_25071', Name: 'Norcatur', stateId: 'KS', stateName: 'Kansas'},
  {
    Id: 'CIT_US_MI_25072',
    Name: 'Free Soil',
    stateId: 'MI',
    stateName: 'Michigan',
  },
  {Id: 'CIT_US_OH_25073', Name: 'Limaville', stateId: 'OH', stateName: 'Ohio'},
  {Id: 'CIT_US_NE_25074', Name: 'Stella', stateId: 'NE', stateName: 'Nebraska'},
  {
    Id: 'CIT_US_MD_25075',
    Name: 'Henderson',
    stateId: 'MD',
    stateName: 'Maryland',
  },
  {Id: 'CIT_US_WY_25076', Name: 'Farson', stateId: 'WY', stateName: 'Wyoming'},
  {
    Id: 'CIT_US_OH_25077',
    Name: 'West Rushville',
    stateId: 'OH',
    stateName: 'Ohio',
  },
  {
    Id: 'CIT_US_MO_25078',
    Name: 'Rush Hill',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {Id: 'CIT_US_IA_25079', Name: 'Rock Falls', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_IN_25080', Name: 'Herbst', stateId: 'IN', stateName: 'Indiana'},
  {Id: 'CIT_US_TX_25081', Name: 'Pawnee', stateId: 'TX', stateName: 'Texas'},
  {
    Id: 'CIT_US_NM_25082',
    Name: 'Alamillo',
    stateId: 'NM',
    stateName: 'New Mexico',
  },
  {Id: 'CIT_US_IA_25083', Name: 'Floris', stateId: 'IA', stateName: 'Iowa'},
  {Id: 'CIT_US_KS_25084', Name: 'Redfield', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_IL_25085', Name: 'Ferris', stateId: 'IL', stateName: 'Illinois'},
  {
    Id: 'CIT_US_KY_25086',
    Name: 'Blackey',
    stateId: 'KY',
    stateName: 'Kentucky',
  },
  {
    Id: 'CIT_US_AL_25087',
    Name: 'Five Points',
    stateId: 'AL',
    stateName: 'Alabama',
  },
  {
    Id: 'CIT_US_MN_25088',
    Name: 'Kinney',
    stateId: 'MN',
    stateName: 'Minnesota',
  },
  {
    Id: 'CIT_US_MS_25089',
    Name: 'Falcon',
    stateId: 'MS',
    stateName: 'Mississippi',
  },
  {
    Id: 'CIT_US_PA_25090',
    Name: 'Siglerville',
    stateId: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    Id: 'CIT_US_WI_25091',
    Name: 'Wyeville',
    stateId: 'WI',
    stateName: 'Wisconsin',
  },
  {
    Id: 'CIT_US_MO_25092',
    Name: 'Kimmswick',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_NC_25093',
    Name: 'Godwin',
    stateId: 'NC',
    stateName: 'North Carolina',
  },
  {Id: 'CIT_US_KS_25094', Name: 'Wilsey', stateId: 'KS', stateName: 'Kansas'},
  {Id: 'CIT_US_TX_25095', Name: 'Quemado', stateId: 'TX', stateName: 'Texas'},
  {Id: 'CIT_US_MT_25096', Name: 'Jette', stateId: 'MT', stateName: 'Montana'},
  {
    Id: 'CIT_US_NE_25097',
    Name: 'Morse Bluff',
    stateId: 'NE',
    stateName: 'Nebraska',
  },
  {
    Id: 'CIT_US_MO_25098',
    Name: 'Strasburg',
    stateId: 'MO',
    stateName: 'Missouri',
  },
  {
    Id: 'CIT_US_ND_25099',
    Name: 'Portal',
    stateId: 'ND',
    stateName: 'North Dakota',
  },
];

const TW_CITIES = [
  {Id: 'CIT_TW_1', Name: 'Taichung'},
  {Id: 'CIT_TW_2', Name: 'Kaohsiung'},
  {Id: 'CIT_TW_3', Name: 'Taipei'},
  {Id: 'CIT_TW_4', Name: 'Tainan'},
  {Id: 'CIT_TW_6', Name: 'Changhua'},
  {Id: 'CIT_TW_7', Name: 'Pingtung'},
  {Id: 'CIT_TW_8', Name: 'Hsinchu'},
  {Id: 'CIT_TW_9', Name: 'Taoyuan'},
  {Id: 'CIT_TW_10', Name: 'Keelung'},
  {Id: 'CIT_TW_11', Name: 'Chiayi'},
  {Id: 'CIT_TW_12', Name: 'Zhubei'},
  {Id: 'CIT_TW_13', Name: 'Nantou'},
  {Id: 'CIT_TW_14', Name: 'Yilan'},
  {Id: 'CIT_TW_15', Name: 'Hualien'},
  {Id: 'CIT_TW_16', Name: 'Taitung'},
  {Id: 'CIT_TW_17', Name: 'Douliu'},
  {Id: 'CIT_TW_18', Name: 'Miaoli'},
  {Id: 'CIT_TW_19', Name: 'Magong'},
  {Id: 'CIT_TW_20', Name: 'Taibao'},
  {Id: 'CIT_TW_24', Name: 'New Taipei'},
  {Id: 'CIT_TW_25', Name: 'Puzi'},
  {Id: 'CIT_TW_26', Name: 'Toufen'},
  {Id: 'CIT_TW_27', Name: 'Yuanlin'},
];

const AU_CITIES = [
  {Id: 'CIT_AU_1', Name: 'Sydney'},
  {Id: 'CIT_AU_2', Name: 'Melbourne'},
  {Id: 'CIT_AU_3', Name: 'Brisbane'},
  {Id: 'CIT_AU_4', Name: 'Perth'},
  {Id: 'CIT_AU_5', Name: 'Adelaide'},
  {Id: 'CIT_AU_6', Name: 'Gold Coast'},
  {Id: 'CIT_AU_7', Name: 'Canberra'},
  {Id: 'CIT_AU_8', Name: 'Newcastle'},
  {Id: 'CIT_AU_9', Name: 'Wollongong'},
  {Id: 'CIT_AU_10', Name: 'Geelong'},
  {Id: 'CIT_AU_11', Name: 'Hobart'},
  {Id: 'CIT_AU_12', Name: 'Townsville'},
  {Id: 'CIT_AU_13', Name: 'Ipswich'},
  {Id: 'CIT_AU_14', Name: 'Cairns'},
  {Id: 'CIT_AU_15', Name: 'Toowoomba'},
  {Id: 'CIT_AU_16', Name: 'Darwin'},
  {Id: 'CIT_AU_17', Name: 'Ballarat'},
  {Id: 'CIT_AU_18', Name: 'Bendigo'},
  {Id: 'CIT_AU_19', Name: 'Launceston'},
  {Id: 'CIT_AU_20', Name: 'Mackay'},
  {Id: 'CIT_AU_21', Name: 'Rockhampton'},
  {Id: 'CIT_AU_22', Name: 'Maitland'},
  {Id: 'CIT_AU_23', Name: 'Bunbury'},
  {Id: 'CIT_AU_24', Name: 'Coffs Harbour'},
  {Id: 'CIT_AU_25', Name: 'Bundaberg'},
  {Id: 'CIT_AU_26', Name: 'Wagga Wagga'},
  {Id: 'CIT_AU_27', Name: 'Hervey Bay'},
  {Id: 'CIT_AU_28', Name: 'Shepparton'},
  {Id: 'CIT_AU_29', Name: 'Albury'},
  {Id: 'CIT_AU_30', Name: 'Port Macquarie'},
  {Id: 'CIT_AU_31', Name: 'Pakenham'},
  {Id: 'CIT_AU_32', Name: 'Tamworth'},
  {Id: 'CIT_AU_33', Name: 'Caloundra'},
  {Id: 'CIT_AU_34', Name: 'Orange'},
  {Id: 'CIT_AU_35', Name: 'Dubbo'},
  {Id: 'CIT_AU_36', Name: 'Geraldton'},
  {Id: 'CIT_AU_37', Name: 'Nowra'},
  {Id: 'CIT_AU_38', Name: 'Bathurst'},
  {Id: 'CIT_AU_39', Name: 'Queanbeyan'},
  {Id: 'CIT_AU_40', Name: 'Frankston'},
  {Id: 'CIT_AU_41', Name: 'Sunbury'},
  {Id: 'CIT_AU_42', Name: 'Warrnambool'},
  {Id: 'CIT_AU_43', Name: 'Wodonga'},
  {Id: 'CIT_AU_44', Name: 'Albany'},
  {Id: 'CIT_AU_45', Name: 'Gladstone'},
  {Id: 'CIT_AU_46', Name: 'Mildura'},
  {Id: 'CIT_AU_47', Name: 'Southport'},
  {Id: 'CIT_AU_48', Name: 'Dandenong'},
  {Id: 'CIT_AU_49', Name: 'Kalgoorlie'},
  {Id: 'CIT_AU_50', Name: 'Mount Gambier'},
  {Id: 'CIT_AU_51', Name: 'Lismore'},
  {Id: 'CIT_AU_52', Name: 'Maryborough'},
  {Id: 'CIT_AU_53', Name: 'Quakers Hill'},
  {Id: 'CIT_AU_54', Name: 'Gawler'},
  {Id: 'CIT_AU_55', Name: 'Caboolture'},
  {Id: 'CIT_AU_56', Name: 'Ballina'},
  {Id: 'CIT_AU_57', Name: 'Taree'},
  {Id: 'CIT_AU_58', Name: 'Traralgon'},
  {Id: 'CIT_AU_59', Name: 'Busselton'},
  {Id: 'CIT_AU_60', Name: 'Armidale'},
  {Id: 'CIT_AU_61', Name: 'Goulburn'},
  {Id: 'CIT_AU_62', Name: 'Alice Springs'},
  {Id: 'CIT_AU_63', Name: 'Devonport'},
  {Id: 'CIT_AU_64', Name: 'Bacchus Marsh'},
  {Id: 'CIT_AU_65', Name: 'Whyalla'},
  {Id: 'CIT_AU_66', Name: 'Cessnock'},
  {Id: 'CIT_AU_67', Name: 'Gympie'},
  {Id: 'CIT_AU_68', Name: 'Griffith'},
  {Id: 'CIT_AU_69', Name: 'Kwinana'},
  {Id: 'CIT_AU_70', Name: 'Burnie'},
  {Id: 'CIT_AU_71', Name: 'Wangaratta'},
  {Id: 'CIT_AU_72', Name: 'Grafton'},
  {Id: 'CIT_AU_73', Name: 'Yeppoon'},
  {Id: 'CIT_AU_74', Name: 'Murray Bridge'},
  {Id: 'CIT_AU_75', Name: 'Mount Isa'},
  {Id: 'CIT_AU_76', Name: 'Narangba'},
  {Id: 'CIT_AU_77', Name: 'Mount Eliza'},
  {Id: 'CIT_AU_78', Name: 'Broken Hill'},
  {Id: 'CIT_AU_79', Name: 'Nerang'},
  {Id: 'CIT_AU_80', Name: 'Karratha'},
  {Id: 'CIT_AU_81', Name: 'Mount Barker'},
  {Id: 'CIT_AU_82', Name: 'Horsham'},
  {Id: 'CIT_AU_83', Name: 'Lara'},
  {Id: 'CIT_AU_84', Name: 'Singleton'},
  {Id: 'CIT_AU_85', Name: 'Port Lincoln'},
  {Id: 'CIT_AU_86', Name: 'Banora Point'},
  {Id: 'CIT_AU_87', Name: 'Warwick'},
  {Id: 'CIT_AU_88', Name: 'Rochedale'},
  {Id: 'CIT_AU_89', Name: 'Kempsey'},
  {Id: 'CIT_AU_90', Name: 'Ulladulla'},
  {Id: 'CIT_AU_91', Name: 'Sale'},
  {Id: 'CIT_AU_92', Name: 'Echuca'},
  {Id: 'CIT_AU_93', Name: 'Australind'},
  {Id: 'CIT_AU_94', Name: 'Broome'},
  {Id: 'CIT_AU_95', Name: 'Port Hedland'},
  {Id: 'CIT_AU_96', Name: 'Warragul'},
  {Id: 'CIT_AU_97', Name: 'Port Pirie'},
  {Id: 'CIT_AU_98', Name: 'Barwon Heads'},
  {Id: 'CIT_AU_99', Name: 'Emerald'},
  {Id: 'CIT_AU_100', Name: 'Ulverstone'},
  {Id: 'CIT_AU_101', Name: 'Morwell'},
  {Id: 'CIT_AU_102', Name: 'Gisborne'},
  {Id: 'CIT_AU_103', Name: 'Port Augusta'},
  {Id: 'CIT_AU_104', Name: 'Forster'},
  {Id: 'CIT_AU_105', Name: 'Penrith'},
  {Id: 'CIT_AU_106', Name: 'Torquay'},
  {Id: 'CIT_AU_107', Name: 'Jimboomba'},
  {Id: 'CIT_AU_108', Name: 'Lithgow'},
  {Id: 'CIT_AU_109', Name: 'Bowral'},
  {Id: 'CIT_AU_110', Name: 'Goonellabah'},
  {Id: 'CIT_AU_111', Name: 'Raymond Terrace'},
  {Id: 'CIT_AU_112', Name: 'Leopold'},
  {Id: 'CIT_AU_113', Name: 'Dalby'},
  {Id: 'CIT_AU_114', Name: 'Campbelltown'},
  {Id: 'CIT_AU_115', Name: 'Colac'},
  {Id: 'CIT_AU_116', Name: 'Muswellbrook'},
  {Id: 'CIT_AU_117', Name: 'Esperance'},
  {Id: 'CIT_AU_118', Name: 'Drouin'},
  {Id: 'CIT_AU_119', Name: 'Rutherford'},
  {Id: 'CIT_AU_120', Name: 'Nelson Bay'},
  {Id: 'CIT_AU_121', Name: 'Inverell'},
  {Id: 'CIT_AU_122', Name: 'Parkes'},
  {Id: 'CIT_AU_123', Name: 'Somerville'},
  {Id: 'CIT_AU_124', Name: 'Gracemere'},
  {Id: 'CIT_AU_125', Name: 'Batemans Bay'},
  {Id: 'CIT_AU_126', Name: 'Nambour'},
  {Id: 'CIT_AU_127', Name: 'Swan Hill'},
  {Id: 'CIT_AU_128', Name: 'Mareeba'},
  {Id: 'CIT_AU_129', Name: 'Corinda'},
  {Id: 'CIT_AU_130', Name: 'Samford'},
  {Id: 'CIT_AU_131', Name: 'Mudgee'},
  {Id: 'CIT_AU_132', Name: 'Casino'},
  {Id: 'CIT_AU_133', Name: 'Portland'},
  {Id: 'CIT_AU_134', Name: 'Kingston'},
  {Id: 'CIT_AU_135', Name: 'Kingaroy'},
  {Id: 'CIT_AU_136', Name: 'Kangaroo Flat'},
  {Id: 'CIT_AU_137', Name: 'Bowen'},
  {Id: 'CIT_AU_138', Name: 'Young'},
  {Id: 'CIT_AU_139', Name: 'Cowra'},
  {Id: 'CIT_AU_140', Name: 'Hamilton'},
  {Id: 'CIT_AU_141', Name: 'Gunnedah'},
  {Id: 'CIT_AU_142', Name: 'Mount Evelyn'},
  {Id: 'CIT_AU_143', Name: 'Andergrove'},
  {Id: 'CIT_AU_144', Name: 'Moree'},
  {Id: 'CIT_AU_145', Name: 'Benalla'},
  {Id: 'CIT_AU_146', Name: 'Currumbin'},
  {Id: 'CIT_AU_147', Name: 'Wollert'},
  {Id: 'CIT_AU_148', Name: 'Mittagong'},
  {Id: 'CIT_AU_149', Name: 'Kelso'},
  {Id: 'CIT_AU_150', Name: 'Yanchep'},
  {Id: 'CIT_AU_151', Name: 'Bentley'},
  {Id: 'CIT_AU_152', Name: 'Moe'},
  {Id: 'CIT_AU_153', Name: 'Ayr'},
  {Id: 'CIT_AU_154', Name: 'Moranbah'},
  {Id: 'CIT_AU_155', Name: 'Leeton'},
  {Id: 'CIT_AU_156', Name: 'Moss Vale'},
  {Id: 'CIT_AU_157', Name: 'Wallan'},
  {Id: 'CIT_AU_158', Name: 'Springwood'},
  {Id: 'CIT_AU_159', Name: 'Lesmurdie'},
  {Id: 'CIT_AU_160', Name: 'Forbes'},
  {Id: 'CIT_AU_161', Name: 'Emu Plains'},
  {Id: 'CIT_AU_162', Name: 'Rye'},
  {Id: 'CIT_AU_163', Name: 'Darley'},
  {Id: 'CIT_AU_164', Name: 'Ararat'},
  {Id: 'CIT_AU_165', Name: 'Tweed Heads'},
  {Id: 'CIT_AU_166', Name: 'Highfields'},
  {Id: 'CIT_AU_167', Name: 'Charters Towers'},
  {Id: 'CIT_AU_168', Name: 'Melton'},
  {Id: 'CIT_AU_169', Name: 'Katoomba'},
  {Id: 'CIT_AU_170', Name: 'Kilmore'},
  {Id: 'CIT_AU_171', Name: 'Mooroopna'},
  {Id: 'CIT_AU_172', Name: 'Yarrawonga'},
  {Id: 'CIT_AU_173', Name: 'Maryborough'},
  {Id: 'CIT_AU_174', Name: 'Deniliquin'},
  {Id: 'CIT_AU_175', Name: 'Mandurah'},
  {Id: 'CIT_AU_176', Name: 'Bli Bli'},
  {Id: 'CIT_AU_177', Name: 'Kiama'},
  {Id: 'CIT_AU_178', Name: 'Narre Warren North'},
  {Id: 'CIT_AU_179', Name: 'Narrabri'},
  {Id: 'CIT_AU_180', Name: 'Bairnsdale'},
  {Id: 'CIT_AU_181', Name: 'Clifton Springs'},
  {Id: 'CIT_AU_182', Name: 'North Tamborine'},
  {Id: 'CIT_AU_183', Name: 'Bargara'},
  {Id: 'CIT_AU_184', Name: 'Kingscliff'},
  {Id: 'CIT_AU_185', Name: 'Healesville'},
  {Id: 'CIT_AU_186', Name: 'Fremantle'},
  {Id: 'CIT_AU_187', Name: 'Blaxland'},
  {Id: 'CIT_AU_188', Name: 'Atherton'},
  {Id: 'CIT_AU_189', Name: 'Kyabram'},
  {Id: 'CIT_AU_190', Name: 'Innisfail'},
  {Id: 'CIT_AU_191', Name: 'Sanctuary Point'},
  {Id: 'CIT_AU_192', Name: 'Collie'},
  {Id: 'CIT_AU_193', Name: 'Gatton'},
  {Id: 'CIT_AU_194', Name: 'Merrimac'},
  {Id: 'CIT_AU_195', Name: 'Murwillumbah'},
  {Id: 'CIT_AU_196', Name: 'Urraween'},
  {Id: 'CIT_AU_197', Name: 'Kyneton'},
  {Id: 'CIT_AU_198', Name: 'Bongaree'},
  {Id: 'CIT_AU_199', Name: 'Roma'},
  {Id: 'CIT_AU_200', Name: 'Mount Cotton'},
  {Id: 'CIT_AU_201', Name: 'Cootamundra'},
  {Id: 'CIT_AU_202', Name: 'Beerwah'},
  {Id: 'CIT_AU_203', Name: 'Castlemaine'},
  {Id: 'CIT_AU_204', Name: 'Pottsville'},
  {Id: 'CIT_AU_205', Name: 'Cooma'},
  {Id: 'CIT_AU_206', Name: 'Gordonvale'},
  {Id: 'CIT_AU_207', Name: 'Lysterfield'},
  {Id: 'CIT_AU_208', Name: 'Bomaderry'},
  {Id: 'CIT_AU_209', Name: 'Chinchilla'},
  {Id: 'CIT_AU_210', Name: 'Northam'},
  {Id: 'CIT_AU_211', Name: 'Yass'},
  {Id: 'CIT_AU_212', Name: 'Strathalbyn'},
  {Id: 'CIT_AU_213', Name: 'Burpengary'},
  {Id: 'CIT_AU_214', Name: 'Waterford'},
  {Id: 'CIT_AU_215', Name: 'Lennox Head'},
  {Id: 'CIT_AU_216', Name: 'Beaudesert'},
  {Id: 'CIT_AU_217', Name: 'Margaret River'},
  {Id: 'CIT_AU_218', Name: 'Helensburgh'},
  {Id: 'CIT_AU_219', Name: 'Goondiwindi'},
  {Id: 'CIT_AU_220', Name: 'Seymour'},
  {Id: 'CIT_AU_221', Name: 'Nambucca Heads'},
  {Id: 'CIT_AU_222', Name: 'Nuriootpa'},
  {Id: 'CIT_AU_223', Name: 'Wauchope'},
  {Id: 'CIT_AU_224', Name: 'Tumut'},
  {Id: 'CIT_AU_225', Name: 'Wentworthville'},
  {Id: 'CIT_AU_226', Name: 'Tuncurry'},
  {Id: 'CIT_AU_227', Name: 'Glen Innes'},
  {Id: 'CIT_AU_228', Name: 'Yamba'},
  {Id: 'CIT_AU_229', Name: 'Wentworth Falls'},
  {Id: 'CIT_AU_230', Name: 'South Grafton'},
  {Id: 'CIT_AU_231', Name: 'Kurri Kurri'},
  {Id: 'CIT_AU_232', Name: 'Stawell'},
  {Id: 'CIT_AU_233', Name: 'Cobram'},
  {Id: 'CIT_AU_234', Name: 'Wynyard'},
  {Id: 'CIT_AU_235', Name: 'Naracoorte'},
  {Id: 'CIT_AU_236', Name: 'North Mackay'},
  {Id: 'CIT_AU_237', Name: 'Cannington'},
  {Id: 'CIT_AU_238', Name: 'Concord'},
  {Id: 'CIT_AU_239', Name: 'Woodend'},
  {Id: 'CIT_AU_240', Name: 'Dromana'},
  {Id: 'CIT_AU_241', Name: 'Emerald'},
  {Id: 'CIT_AU_242', Name: 'Biloela'},
  {Id: 'CIT_AU_243', Name: 'Alstonville'},
  {Id: 'CIT_AU_244', Name: 'Cannonvale'},
  {Id: 'CIT_AU_245', Name: 'Point Vernon'},
  {Id: 'CIT_AU_246', Name: 'Palmwoods'},
  {Id: 'CIT_AU_247', Name: 'Scone'},
  {Id: 'CIT_AU_248', Name: 'Whittlesea'},
  {Id: 'CIT_AU_249', Name: 'East Ballina'},
  {Id: 'CIT_AU_250', Name: 'Sarina'},
  {Id: 'CIT_AU_251', Name: 'Byron Bay'},
  {Id: 'CIT_AU_252', Name: 'Granville'},
  {Id: 'CIT_AU_253', Name: 'Corowa'},
  {Id: 'CIT_AU_254', Name: 'Richmond'},
  {Id: 'CIT_AU_255', Name: 'Cooranbong'},
  {Id: 'CIT_AU_256', Name: 'Bullsbrook'},
  {Id: 'CIT_AU_257', Name: 'Inverloch'},
  {Id: 'CIT_AU_258', Name: 'New Norfolk'},
  {Id: 'CIT_AU_259', Name: 'Stanthorpe'},
  {Id: 'CIT_AU_260', Name: 'Wingham'},
  {Id: 'CIT_AU_261', Name: 'West Tamworth'},
  {Id: 'CIT_AU_262', Name: 'Irymple'},
  {Id: 'CIT_AU_263', Name: 'Dunsborough'},
  {Id: 'CIT_AU_264', Name: 'Kununurra'},
  {Id: 'CIT_AU_265', Name: 'Smithfield Heights'},
  {Id: 'CIT_AU_266', Name: 'Woolgoolga'},
  {Id: 'CIT_AU_267', Name: 'Bannockburn'},
  {Id: 'CIT_AU_268', Name: 'Maffra'},
  {Id: 'CIT_AU_269', Name: 'Tannum Sands'},
  {Id: 'CIT_AU_270', Name: 'Ocean Shores'},
  {Id: 'CIT_AU_271', Name: 'Howard Springs'},
  {Id: 'CIT_AU_272', Name: 'Leongatha'},
  {Id: 'CIT_AU_273', Name: 'Worragee'},
  {Id: 'CIT_AU_274', Name: 'Tahmoor'},
  {Id: 'CIT_AU_275', Name: 'Red Cliffs'},
  {Id: 'CIT_AU_276', Name: 'Glenbrook'},
  {Id: 'CIT_AU_277', Name: 'McMinns Lagoon'},
  {Id: 'CIT_AU_278', Name: 'Kiama Downs'},
  {Id: 'CIT_AU_279', Name: 'Lyndhurst'},
  {Id: 'CIT_AU_280', Name: 'Wonthaggi'},
  {Id: 'CIT_AU_281', Name: 'Junee'},
  {Id: 'CIT_AU_282', Name: 'Pinjarra'},
  {Id: 'CIT_AU_283', Name: 'Encounter Bay'},
  {Id: 'CIT_AU_284', Name: 'Nairne'},
  {Id: 'CIT_AU_285', Name: 'Cowes'},
  {Id: 'CIT_AU_286', Name: 'Picton'},
  {Id: 'CIT_AU_287', Name: 'Salamander Bay'},
  {Id: 'CIT_AU_288', Name: 'Lakes Entrance'},
  {Id: 'CIT_AU_289', Name: 'Mansfield'},
  {Id: 'CIT_AU_290', Name: 'Churchill'},
  {Id: 'CIT_AU_291', Name: 'Bucasia'},
  {Id: 'CIT_AU_292', Name: 'Strathfield'},
  {Id: 'CIT_AU_293', Name: 'Boyne Island'},
  {Id: 'CIT_AU_294', Name: 'Blackwater'},
  {Id: 'CIT_AU_295', Name: 'Millicent'},
  {Id: 'CIT_AU_296', Name: 'Oakey'},
  {Id: 'CIT_AU_297', Name: 'Temora'},
  {Id: 'CIT_AU_298', Name: 'Narromine'},
  {Id: 'CIT_AU_299', Name: 'Ningi'},
  {Id: 'CIT_AU_300', Name: 'Bega'},
  {Id: 'CIT_AU_301', Name: 'Mount Low'},
  {Id: 'CIT_AU_302', Name: 'Leura'},
  {Id: 'CIT_AU_303', Name: 'Renmark'},
  {Id: 'CIT_AU_304', Name: 'South West Rocks'},
  {Id: 'CIT_AU_305', Name: 'Tanunda'},
  {Id: 'CIT_AU_306', Name: 'Kadina'},
  {Id: 'CIT_AU_307', Name: 'Newman'},
  {Id: 'CIT_AU_308', Name: 'Glenella'},
  {Id: 'CIT_AU_309', Name: 'Roxby Downs'},
  {Id: 'CIT_AU_310', Name: 'Numurkah'},
  {Id: 'CIT_AU_311', Name: 'Korumburra'},
  {Id: 'CIT_AU_312', Name: 'Rasmussen'},
  {Id: 'CIT_AU_313', Name: 'Tatura'},
  {Id: 'CIT_AU_314', Name: 'Carnarvon'},
  {Id: 'CIT_AU_315', Name: 'Romsey'},
  {Id: 'CIT_AU_316', Name: 'Blackheath'},
  {Id: 'CIT_AU_317', Name: 'Bargo'},
  {Id: 'CIT_AU_318', Name: 'Old Bar'},
  {Id: 'CIT_AU_319', Name: 'Narrandera'},
  {Id: 'CIT_AU_320', Name: 'Ingham'},
  {Id: 'CIT_AU_321', Name: 'Manjimup'},
  {Id: 'CIT_AU_322', Name: 'George Town'},
  {Id: 'CIT_AU_323', Name: 'Wyong'},
  {Id: 'CIT_AU_324', Name: 'Broadford'},
  {Id: 'CIT_AU_325', Name: 'Drysdale'},
  {Id: 'CIT_AU_326', Name: 'Narrogin'},
  {Id: 'CIT_AU_327', Name: 'Cockatoo'},
  {Id: 'CIT_AU_328', Name: 'Victor Harbor'},
  {Id: 'CIT_AU_329', Name: 'Oxford Park'},
  {Id: 'CIT_AU_330', Name: 'Moama'},
  {Id: 'CIT_AU_331', Name: 'Bungendore'},
  {Id: 'CIT_AU_332', Name: 'Latrobe'},
  {Id: 'CIT_AU_333', Name: 'Curl Curl'},
  {Id: 'CIT_AU_334', Name: 'Beachmere'},
  {Id: 'CIT_AU_335', Name: 'Berri'},
  {Id: 'CIT_AU_336', Name: 'Wellington'},
  {Id: 'CIT_AU_337', Name: 'Tenterfield'},
  {Id: 'CIT_AU_338', Name: 'Thirlmere'},
  {Id: 'CIT_AU_339', Name: 'Legana'},
  {Id: 'CIT_AU_340', Name: 'Faulconbridge'},
  {Id: 'CIT_AU_341', Name: 'The Entrance'},
  {Id: 'CIT_AU_342', Name: 'Cobar'},
  {Id: 'CIT_AU_343', Name: 'Wallaroo'},
  {Id: 'CIT_AU_344', Name: 'Moruya'},
  {Id: 'CIT_AU_345', Name: 'Gerringong'},
  {Id: 'CIT_AU_346', Name: 'Sandstone Point'},
  {Id: 'CIT_AU_347', Name: 'Tambourine'},
  {Id: 'CIT_AU_348', Name: 'Margate'},
  {Id: 'CIT_AU_349', Name: 'Trafalgar'},
  {Id: 'CIT_AU_350', Name: 'Somerset'},
  {Id: 'CIT_AU_351', Name: 'Marian'},
  {Id: 'CIT_AU_352', Name: 'Weipa'},
  {Id: 'CIT_AU_353', Name: 'Kerang'},
  {Id: 'CIT_AU_354', Name: 'Westbrook'},
  {Id: 'CIT_AU_355', Name: 'Smithton'},
  {Id: 'CIT_AU_356', Name: 'Longford'},
  {Id: 'CIT_AU_357', Name: 'Karana Downs'},
  {Id: 'CIT_AU_358', Name: 'Beechworth'},
  {Id: 'CIT_AU_359', Name: 'Penguin'},
  {Id: 'CIT_AU_360', Name: 'Anna Bay'},
  {Id: 'CIT_AU_361', Name: 'Loxton'},
  {Id: 'CIT_AU_362', Name: 'Pearcedale'},
  {Id: 'CIT_AU_363', Name: 'Landsborough'},
  {Id: 'CIT_AU_364', Name: 'Laidley'},
  {Id: 'CIT_AU_365', Name: 'Wonga Park'},
  {Id: 'CIT_AU_366', Name: 'Peregian Beach'},
  {Id: 'CIT_AU_367', Name: 'Cooroy'},
  {Id: 'CIT_AU_368', Name: 'Bellingen'},
  {Id: 'CIT_AU_369', Name: 'Riddell'},
  {Id: 'CIT_AU_370', Name: 'Suffolk Park'},
  {Id: 'CIT_AU_371', Name: 'Buninyong'},
  {Id: 'CIT_AU_372', Name: 'Weston'},
  {Id: 'CIT_AU_373', Name: 'Katanning'},
  {Id: 'CIT_AU_374', Name: 'Bellbrae'},
  {Id: 'CIT_AU_375', Name: 'Sawtell'},
  {Id: 'CIT_AU_376', Name: 'Silverdale'},
  {Id: 'CIT_AU_377', Name: 'Tallebudgera'},
  {Id: 'CIT_AU_378', Name: 'Bay View'},
  {Id: 'CIT_AU_379', Name: 'Portarlington'},
  {Id: 'CIT_AU_380', Name: 'Nanango'},
  {Id: 'CIT_AU_381', Name: 'Logan Village'},
  {Id: 'CIT_AU_382', Name: 'Glass House Mountains'},
  {Id: 'CIT_AU_383', Name: 'Sussex Inlet'},
  {Id: 'CIT_AU_384', Name: 'Proserpine'},
  {Id: 'CIT_AU_385', Name: 'Merimbula'},
  {Id: 'CIT_AU_386', Name: 'Scarness'},
  {Id: 'CIT_AU_387', Name: 'Port Douglas'},
  {Id: 'CIT_AU_388', Name: 'Gosford'},
  {Id: 'CIT_AU_389', Name: 'Bridgewater'},
  {Id: 'CIT_AU_390', Name: 'Lake Cathie'},
  {Id: 'CIT_AU_391', Name: 'Condobolin'},
  {Id: 'CIT_AU_392', Name: 'Paynesville'},
  {Id: 'CIT_AU_393', Name: 'Woodford'},
  {Id: 'CIT_AU_394', Name: 'Maddingley'},
  {Id: 'CIT_AU_395', Name: 'Hurstbridge'},
  {Id: 'CIT_AU_396', Name: 'Quirindi'},
  {Id: 'CIT_AU_397', Name: 'Maleny'},
  {Id: 'CIT_AU_398', Name: 'Calliope'},
  {Id: 'CIT_AU_399', Name: 'Corrimal'},
  {Id: 'CIT_AU_400', Name: 'Walkerston'},
  {Id: 'CIT_AU_401', Name: 'Blayney'},
  {Id: 'CIT_AU_402', Name: 'Camperdown'},
  {Id: 'CIT_AU_403', Name: 'Aldgate'},
  {Id: 'CIT_AU_404', Name: 'Port Fairy'},
  {Id: 'CIT_AU_405', Name: 'Tyabb'},
  {Id: 'CIT_AU_406', Name: 'Lowood'},
  {Id: 'CIT_AU_407', Name: 'Charleville'},
  {Id: 'CIT_AU_408', Name: 'Derby'},
  {Id: 'CIT_AU_409', Name: 'Mooloolah'},
  {Id: 'CIT_AU_410', Name: 'Pittsworth'},
  {Id: 'CIT_AU_411', Name: 'Vincentia'},
  {Id: 'CIT_AU_412', Name: 'Coonabarabran'},
  {Id: 'CIT_AU_413', Name: 'Euroa'},
  {Id: 'CIT_AU_414', Name: 'Oberon'},
  {Id: 'CIT_AU_415', Name: 'Woombye'},
  {Id: 'CIT_AU_416', Name: 'Nhulunbuy'},
  {Id: 'CIT_AU_417', Name: 'Camden'},
  {Id: 'CIT_AU_418', Name: 'Murrumbateman'},
  {Id: 'CIT_AU_419', Name: 'Fernvale'},
  {Id: 'CIT_AU_420', Name: 'East Hills'},
  {Id: 'CIT_AU_421', Name: 'Bohle Plains'},
  {Id: 'CIT_AU_422', Name: 'Junortoun'},
  {Id: 'CIT_AU_423', Name: 'Myrtleford'},
  {Id: 'CIT_AU_424', Name: 'Crib Point'},
  {Id: 'CIT_AU_425', Name: 'Marcoola'},
  {Id: 'CIT_AU_426', Name: 'Mullumbimby'},
  {Id: 'CIT_AU_427', Name: 'Creswick'},
  {Id: 'CIT_AU_428', Name: 'Clare'},
  {Id: 'CIT_AU_429', Name: 'Tura Beach'},
  {Id: 'CIT_AU_430', Name: 'Eden'},
  {Id: 'CIT_AU_431', Name: 'West Wyalong'},
  {Id: 'CIT_AU_432', Name: 'Gilgandra'},
  {Id: 'CIT_AU_433', Name: 'Yunderup'},
  {Id: 'CIT_AU_434', Name: 'Rochester'},
  {Id: 'CIT_AU_435', Name: 'McLaren Vale'},
  {Id: 'CIT_AU_436', Name: 'Miners Rest'},
  {Id: 'CIT_AU_437', Name: 'Robinvale'},
  {Id: 'CIT_AU_438', Name: 'Wilton'},
  {Id: 'CIT_AU_439', Name: 'Riverview'},
  {Id: 'CIT_AU_440', Name: 'Bogangar'},
  {Id: 'CIT_AU_441', Name: 'Shoalhaven Heads'},
  {Id: 'CIT_AU_442', Name: 'Saint George'},
  {Id: 'CIT_AU_443', Name: 'Little Hampton'},
  {Id: 'CIT_AU_444', Name: 'Clermont'},
  {Id: 'CIT_AU_445', Name: 'Home Hill'},
  {Id: 'CIT_AU_446', Name: 'Central Coast'},
  {Id: 'CIT_AU_447', Name: 'Kuranda'},
  {Id: 'CIT_AU_448', Name: 'Wilberforce'},
  {Id: 'CIT_AU_449', Name: 'Tom Price'},
  {Id: 'CIT_AU_450', Name: 'Urunga'},
  {Id: 'CIT_AU_451', Name: 'Dysart'},
  {Id: 'CIT_AU_452', Name: 'Two Rocks'},
  {Id: 'CIT_AU_453', Name: 'Ballan'},
  {Id: 'CIT_AU_454', Name: 'Gloucester'},
  {Id: 'CIT_AU_455', Name: 'Bonny Hills'},
  {Id: 'CIT_AU_456', Name: 'Longreach'},
  {Id: 'CIT_AU_457', Name: 'Stirling'},
  {Id: 'CIT_AU_458', Name: 'Perth'},
  {Id: 'CIT_AU_459', Name: 'Bordertown'},
  {Id: 'CIT_AU_460', Name: 'Lewiston'},
  {Id: 'CIT_AU_461', Name: 'Waroona'},
  {Id: 'CIT_AU_462', Name: 'Leschenault'},
  {Id: 'CIT_AU_463', Name: 'Terranora'},
  {Id: 'CIT_AU_464', Name: 'Kapunda'},
  {Id: 'CIT_AU_465', Name: 'Woori Yallock'},
  {Id: 'CIT_AU_466', Name: 'Saint Georges Basin'},
  {Id: 'CIT_AU_467', Name: 'Pomona'},
  {Id: 'CIT_AU_468', Name: 'Sorell'},
  {Id: 'CIT_AU_469', Name: 'Tea Gardens'},
  {Id: 'CIT_AU_470', Name: 'Culburra'},
  {Id: 'CIT_AU_471', Name: 'Yarra Glen'},
  {Id: 'CIT_AU_472', Name: 'Beaconsfield Upper'},
  {Id: 'CIT_AU_473', Name: 'Morisset'},
  {Id: 'CIT_AU_474', Name: 'Merredin'},
  {Id: 'CIT_AU_475', Name: 'Deloraine'},
  {Id: 'CIT_AU_476', Name: 'Evans Head'},
  {Id: 'CIT_AU_477', Name: 'Rosewood'},
  {Id: 'CIT_AU_478', Name: 'Greta'},
  {Id: 'CIT_AU_479', Name: 'Wollongbar'},
  {Id: 'CIT_AU_480', Name: 'Donnybrook'},
  {Id: 'CIT_AU_481', Name: 'McCrae'},
  {Id: 'CIT_AU_482', Name: 'Angle Vale'},
  {Id: 'CIT_AU_483', Name: 'Bridgetown'},
  {Id: 'CIT_AU_484', Name: 'North Wonthaggi'},
  {Id: 'CIT_AU_485', Name: 'Heathcote'},
  {Id: 'CIT_AU_486', Name: 'Howlong'},
  {Id: 'CIT_AU_487', Name: 'Williamstown'},
  {Id: 'CIT_AU_488', Name: 'Harvey'},
  {Id: 'CIT_AU_489', Name: 'Coonamble'},
  {Id: 'CIT_AU_490', Name: 'Uralla'},
  {Id: 'CIT_AU_491', Name: 'Bundanoon'},
  {Id: 'CIT_AU_492', Name: 'Cloncurry'},
  {Id: 'CIT_AU_493', Name: 'Tolga'},
  {Id: 'CIT_AU_494', Name: 'Huonville'},
  {Id: 'CIT_AU_495', Name: 'Merbein'},
  {Id: 'CIT_AU_496', Name: 'Alexandra'},
  {Id: 'CIT_AU_497', Name: 'Waikerie'},
  {Id: 'CIT_AU_498', Name: 'Point Lonsdale'},
  {Id: 'CIT_AU_499', Name: 'Hill Top'},
  {Id: 'CIT_AU_500', Name: 'Stirling North'},
  {Id: 'CIT_AU_501', Name: 'Hahndorf'},
  {Id: 'CIT_AU_502', Name: 'Berry'},
  {Id: 'CIT_AU_503', Name: 'Burnett Heads'},
  {Id: 'CIT_AU_504', Name: 'Moore Park'},
  {Id: 'CIT_AU_505', Name: 'Burradoo'},
  {Id: 'CIT_AU_506', Name: 'Mannum'},
  {Id: 'CIT_AU_507', Name: 'Wooroloo'},
  {Id: 'CIT_AU_508', Name: 'Crookwell'},
  {Id: 'CIT_AU_509', Name: 'Appin'},
  {Id: 'CIT_AU_510', Name: 'Cooloola Cove'},
  {Id: 'CIT_AU_511', Name: 'Cooktown'},
  {Id: 'CIT_AU_512', Name: 'Jindabyne'},
  {Id: 'CIT_AU_513', Name: 'Maclean'},
  {Id: 'CIT_AU_514', Name: 'Stratford'},
  {Id: 'CIT_AU_515', Name: 'Lawson'},
  {Id: 'CIT_AU_516', Name: 'Thursday Island'},
  {Id: 'CIT_AU_517', Name: 'Woodside'},
  {Id: 'CIT_AU_518', Name: 'Narooma'},
  {Id: 'CIT_AU_519', Name: 'Macksville'},
  {Id: 'CIT_AU_520', Name: 'Molong'},
  {Id: 'CIT_AU_521', Name: 'Grenfell'},
  {Id: 'CIT_AU_522', Name: 'Yarrabah Aboriginal Community'},
  {Id: 'CIT_AU_523', Name: 'Denmark'},
  {Id: 'CIT_AU_524', Name: 'Bonville'},
  {Id: 'CIT_AU_525', Name: 'Walcha'},
  {Id: 'CIT_AU_526', Name: 'Manilla'},
  {Id: 'CIT_AU_527', Name: 'Yarra Junction'},
  {Id: 'CIT_AU_528', Name: 'Daylesford'},
  {Id: 'CIT_AU_529', Name: 'York'},
  {Id: 'CIT_AU_530', Name: 'Anglesea'},
  {Id: 'CIT_AU_531', Name: 'Kambalda'},
  {Id: 'CIT_AU_532', Name: 'Lobethal'},
  {Id: 'CIT_AU_533', Name: 'The Oaks'},
  {Id: 'CIT_AU_534', Name: 'Gulgong'},
  {Id: 'CIT_AU_535', Name: 'Finley'},
  {Id: 'CIT_AU_536', Name: 'Capel'},
  {Id: 'CIT_AU_537', Name: 'Korora'},
  {Id: 'CIT_AU_538', Name: 'Exmouth'},
  {Id: 'CIT_AU_539', Name: 'Boonah'},
  {Id: 'CIT_AU_540', Name: 'Saint Leonards'},
  {Id: 'CIT_AU_541', Name: 'Vasse'},
  {Id: 'CIT_AU_542', Name: 'Aberdare'},
  {Id: 'CIT_AU_543', Name: 'Bunyip'},
  {Id: 'CIT_AU_544', Name: 'Dodges Ferry'},
  {Id: 'CIT_AU_545', Name: 'Palm Island'},
  {Id: 'CIT_AU_546', Name: 'Cohuna'},
  {Id: 'CIT_AU_547', Name: 'Bright'},
  {Id: 'CIT_AU_548', Name: 'Hay'},
  {Id: 'CIT_AU_549', Name: 'Launching Place'},
  {Id: 'CIT_AU_550', Name: 'Tully'},
  {Id: 'CIT_AU_551', Name: 'Huntly'},
  {Id: 'CIT_AU_552', Name: 'Rutherglen'},
  {Id: 'CIT_AU_553', Name: 'Murgon'},
  {Id: 'CIT_AU_554', Name: 'Scottsdale'},
  {Id: 'CIT_AU_555', Name: 'Baranduda'},
  {Id: 'CIT_AU_556', Name: 'Moore'},
  {Id: 'CIT_AU_557', Name: 'Goolwa'},
  {Id: 'CIT_AU_558', Name: 'Gembrook'},
  {Id: 'CIT_AU_559', Name: 'Port Moonta'},
  {Id: 'CIT_AU_560', Name: 'Warracknabeal'},
  {Id: 'CIT_AU_561', Name: 'Abermain'},
  {Id: 'CIT_AU_562', Name: 'Beveridge'},
  {Id: 'CIT_AU_563', Name: 'Willunga'},
  {Id: 'CIT_AU_564', Name: 'Maningrida'},
  {Id: 'CIT_AU_565', Name: 'Innes Park'},
  {Id: 'CIT_AU_566', Name: 'Tara'},
  {Id: 'CIT_AU_567', Name: 'Wickham'},
  {Id: 'CIT_AU_568', Name: 'Terang'},
  {Id: 'CIT_AU_569', Name: 'Sandy Beach'},
  {Id: 'CIT_AU_570', Name: 'Lightning Ridge'},
  {Id: 'CIT_AU_571', Name: 'Port Keats'},
  {Id: 'CIT_AU_572', Name: 'Hadspen'},
  {Id: 'CIT_AU_573', Name: 'Balnarring'},
  {Id: 'CIT_AU_574', Name: 'Canowindra'},
  {Id: 'CIT_AU_575', Name: 'Tin Can Bay'},
  {Id: 'CIT_AU_576', Name: 'Tuross Head'},
  {Id: 'CIT_AU_577', Name: 'Emerald Beach'},
  {Id: 'CIT_AU_578', Name: 'Jindera'},
  {Id: 'CIT_AU_579', Name: 'Yandina'},
  {Id: 'CIT_AU_580', Name: 'Freeling'},
  {Id: 'CIT_AU_581', Name: 'Meringandan'},
  {Id: 'CIT_AU_582', Name: 'Agnes Water'},
  {Id: 'CIT_AU_583', Name: 'Mount Clear'},
  {Id: 'CIT_AU_584', Name: 'Coolamon'},
  {Id: 'CIT_AU_585', Name: 'Mirboo North'},
  {Id: 'CIT_AU_586', Name: 'Kyogle'},
  {Id: 'CIT_AU_587', Name: 'Saint Arnaud'},
  {Id: 'CIT_AU_588', Name: 'Lismore Heights'},
  {Id: 'CIT_AU_589', Name: 'Toogoom'},
  {Id: 'CIT_AU_590', Name: 'Ravenswood'},
  {Id: 'CIT_AU_591', Name: 'Coldstream'},
  {Id: 'CIT_AU_592', Name: 'Baxter'},
  {Id: 'CIT_AU_593', Name: 'Crows Nest'},
  {Id: 'CIT_AU_594', Name: 'Nyngan'},
  {Id: 'CIT_AU_595', Name: 'Ceduna'},
  {Id: 'CIT_AU_596', Name: 'Walgett'},
  {Id: 'CIT_AU_597', Name: 'Alice River'},
  {Id: 'CIT_AU_598', Name: 'Yarram'},
  {Id: 'CIT_AU_599', Name: 'Lower Chittering'},
  {Id: 'CIT_AU_600', Name: 'Kootingal'},
  {Id: 'CIT_AU_601', Name: 'Gowrie Junction'},
  {Id: 'CIT_AU_602', Name: 'Port Elliot'},
  {Id: 'CIT_AU_603', Name: 'Angaston'},
  {Id: 'CIT_AU_604', Name: 'Aberdeen'},
  {Id: 'CIT_AU_605', Name: 'Mid Sapphire Beach'},
  {Id: 'CIT_AU_606', Name: 'Wee Waa'},
  {Id: 'CIT_AU_607', Name: 'One Mile'},
  {Id: 'CIT_AU_608', Name: 'Saint Helens'},
  {Id: 'CIT_AU_609', Name: 'Burrum Heads'},
  {Id: 'CIT_AU_610', Name: 'Koroit'},
  {Id: 'CIT_AU_611', Name: 'Cundletown'},
  {Id: 'CIT_AU_612', Name: 'Heddon Greta'},
  {Id: 'CIT_AU_613', Name: 'Macedon'},
  {Id: 'CIT_AU_614', Name: 'Bellbird'},
  {Id: 'CIT_AU_615', Name: 'Murrumburrah'},
  {Id: 'CIT_AU_616', Name: 'Buxton'},
  {Id: 'CIT_AU_617', Name: 'Guyra'},
  {Id: 'CIT_AU_618', Name: 'Dungog'},
  {Id: 'CIT_AU_619', Name: 'Freshwater'},
  {Id: 'CIT_AU_620', Name: 'Port Sorell'},
  {Id: 'CIT_AU_621', Name: 'Bangalow'},
  {Id: 'CIT_AU_622', Name: 'Emu Park'},
  {Id: 'CIT_AU_623', Name: 'Cedar Grove'},
  {Id: 'CIT_AU_624', Name: 'Orbost'},
  {Id: 'CIT_AU_625', Name: 'Warburton'},
  {Id: 'CIT_AU_626', Name: 'Westbury'},
  {Id: 'CIT_AU_627', Name: 'Longwarry'},
  {Id: 'CIT_AU_628', Name: 'Lyndoch'},
  {Id: 'CIT_AU_629', Name: 'Nimbin'},
  {Id: 'CIT_AU_630', Name: 'Wahroonga'},
  {Id: 'CIT_AU_631', Name: 'Clarence Town'},
  {Id: 'CIT_AU_632', Name: 'Heyfield'},
  {Id: 'CIT_AU_633', Name: 'Branxton'},
  {Id: 'CIT_AU_634', Name: 'Camden Haven'},
  {Id: 'CIT_AU_635', Name: 'Malanda'},
  {Id: 'CIT_AU_636', Name: 'Gayndah'},
  {Id: 'CIT_AU_637', Name: 'Wallerawang'},
  {Id: 'CIT_AU_638', Name: 'Freemans Reach'},
  {Id: 'CIT_AU_639', Name: 'Wondai'},
  {Id: 'CIT_AU_640', Name: 'Mount Morgan'},
  {Id: 'CIT_AU_641', Name: 'Wattleglen'},
  {Id: 'CIT_AU_642', Name: 'Winchelsea'},
  {Id: 'CIT_AU_643', Name: 'Dayboro'},
  {Id: 'CIT_AU_644', Name: 'Mossman'},
  {Id: 'CIT_AU_645', Name: 'Dalmeny'},
  {Id: 'CIT_AU_646', Name: 'Moonee Beach'},
  {Id: 'CIT_AU_647', Name: 'Malua Bay'},
  {Id: 'CIT_AU_648', Name: 'Tongala'},
  {Id: 'CIT_AU_649', Name: 'Two Wells'},
  {Id: 'CIT_AU_650', Name: 'Eumundi'},
  {Id: 'CIT_AU_651', Name: 'Curra'},
  {Id: 'CIT_AU_652', Name: 'Barmera'},
  {Id: 'CIT_AU_653', Name: 'Middlemount'},
  {Id: 'CIT_AU_654', Name: 'Bourke'},
  {Id: 'CIT_AU_655', Name: 'Dennington'},
  {Id: 'CIT_AU_656', Name: 'Mount Barker'},
  {Id: 'CIT_AU_657', Name: 'Mulwala'},
  {Id: 'CIT_AU_658', Name: 'Balaklava'},
  {Id: 'CIT_AU_659', Name: 'Cowaramup'},
  {Id: 'CIT_AU_660', Name: 'Moura'},
  {Id: 'CIT_AU_661', Name: 'Kilcoy'},
  {Id: 'CIT_AU_662', Name: 'Windsor'},
  {Id: 'CIT_AU_663', Name: 'Nagambie'},
  {Id: 'CIT_AU_664', Name: 'Nathalia'},
  {Id: 'CIT_AU_665', Name: 'Gleneagle'},
  {Id: 'CIT_AU_666', Name: 'Nhill'},
  {Id: 'CIT_AU_667', Name: 'Robertson'},
  {Id: 'CIT_AU_668', Name: 'Tumbarumba'},
  {Id: 'CIT_AU_669', Name: 'Ceduna'},
  {Id: 'CIT_AU_670', Name: 'Withcott'},
  {Id: 'CIT_AU_671', Name: 'Oakdale'},
  {Id: 'CIT_AU_672', Name: 'Foster'},
  {Id: 'CIT_AU_673', Name: 'Cobden'},
  {Id: 'CIT_AU_674', Name: 'Jacobs Well'},
  {Id: 'CIT_AU_675', Name: 'Redbank'},
  {Id: 'CIT_AU_676', Name: 'Wyreema'},
  {Id: 'CIT_AU_677', Name: 'Lower King'},
  {Id: 'CIT_AU_678', Name: 'Mount Crosby'},
  {Id: 'CIT_AU_679', Name: 'Woorim'},
  {Id: 'CIT_AU_680', Name: 'Chidlow'},
  {Id: 'CIT_AU_681', Name: 'Craignish'},
  {Id: 'CIT_AU_682', Name: 'Wagin'},
  {Id: 'CIT_AU_683', Name: 'Barooga'},
  {Id: 'CIT_AU_684', Name: 'Yackandandah'},
  {Id: 'CIT_AU_685', Name: 'Queenstown'},
  {Id: 'CIT_AU_686', Name: 'Kingscote'},
  {Id: 'CIT_AU_687', Name: 'Denman'},
  {Id: 'CIT_AU_688', Name: 'Kambalda West'},
  {Id: 'CIT_AU_689', Name: 'Raworth'},
  {Id: 'CIT_AU_690', Name: 'Garfield'},
  {Id: 'CIT_AU_691', Name: 'Campbells Creek'},
  {Id: 'CIT_AU_692', Name: 'Batehaven'},
  {Id: 'CIT_AU_693', Name: 'Moora'},
  {Id: 'CIT_AU_694', Name: 'Park Ridge'},
  {Id: 'CIT_AU_695', Name: 'Coober Pedy'},
  {Id: 'CIT_AU_696', Name: 'Jurien Bay'},
  {Id: 'CIT_AU_697', Name: 'Merriwa'},
  {Id: 'CIT_AU_698', Name: 'Miles'},
  {Id: 'CIT_AU_699', Name: 'Olinda'},
  {Id: 'CIT_AU_700', Name: 'Brunswick Heads'},
  {Id: 'CIT_AU_701', Name: 'Mount Compass'},
  {Id: 'CIT_AU_702', Name: 'Dimboola'},
  {Id: 'CIT_AU_703', Name: 'Clunes'},
  {Id: 'CIT_AU_704', Name: 'Heywood'},
  {Id: 'CIT_AU_705', Name: 'Kooralbyn'},
  {Id: 'CIT_AU_706', Name: 'Teesdale'},
  {Id: 'CIT_AU_707', Name: 'Iluka'},
  {Id: 'CIT_AU_708', Name: 'Broulee'},
  {Id: 'CIT_AU_709', Name: 'Holbrook'},
  {Id: 'CIT_AU_710', Name: 'Drayton'},
  {Id: 'CIT_AU_711', Name: 'Nullagine'},
  {Id: 'CIT_AU_712', Name: 'Esk'},
  {Id: 'CIT_AU_713', Name: 'Gulmarrad'},
  {Id: 'CIT_AU_714', Name: 'Corindi Beach'},
  {Id: 'CIT_AU_715', Name: 'Gundagai'},
  {Id: 'CIT_AU_716', Name: 'Tathra'},
  {Id: 'CIT_AU_717', Name: 'North Haven'},
  {Id: 'CIT_AU_718', Name: 'Spreyton'},
  {Id: 'CIT_AU_719', Name: 'Boambee'},
  {Id: 'CIT_AU_720', Name: 'Casterton'},
  {Id: 'CIT_AU_721', Name: 'Pine Mountain'},
  {Id: 'CIT_AU_722', Name: 'Somers'},
  {Id: 'CIT_AU_723', Name: 'Tailem Bend'},
  {Id: 'CIT_AU_724', Name: 'Milton'},
  {Id: 'CIT_AU_725', Name: 'Sutton'},
  {Id: 'CIT_AU_726', Name: 'Wy Yung'},
  {Id: 'CIT_AU_727', Name: 'Rosedale'},
  {Id: 'CIT_AU_728', Name: 'Millgrove'},
  {Id: 'CIT_AU_729', Name: 'Braidwood'},
  {Id: 'CIT_AU_730', Name: 'Yarragon'},
  {Id: 'CIT_AU_731', Name: 'Kingston South East'},
  {Id: 'CIT_AU_732', Name: 'Belgrave South'},
  {Id: 'CIT_AU_733', Name: 'Boorowa'},
  {Id: 'CIT_AU_734', Name: 'Jamberoo'},
  {Id: 'CIT_AU_735', Name: 'Yaroomba'},
  {Id: 'CIT_AU_736', Name: 'Colo Vale'},
  {Id: 'CIT_AU_737', Name: 'Kleinton'},
  {Id: 'CIT_AU_738', Name: 'Chiltern'},
  {Id: 'CIT_AU_739', Name: 'Apollo Bay'},
  {Id: 'CIT_AU_740', Name: 'Plainland'},
  {Id: 'CIT_AU_741', Name: 'Sorrento'},
  {Id: 'CIT_AU_742', Name: 'Warialda'},
  {Id: 'CIT_AU_743', Name: 'Yea'},
  {Id: 'CIT_AU_744', Name: 'Lang Lang'},
  {Id: 'CIT_AU_745', Name: 'Childers'},
  {Id: 'CIT_AU_746', Name: 'Cambooya'},
  {Id: 'CIT_AU_747', Name: 'Werris Creek'},
  {Id: 'CIT_AU_748', Name: 'Bowen Mountain'},
  {Id: 'CIT_AU_749', Name: 'Tooradin'},
  {Id: 'CIT_AU_750', Name: 'Bridport'},
  {Id: 'CIT_AU_751', Name: 'Chinderah'},
  {Id: 'CIT_AU_752', Name: 'Mapleton'},
  {Id: 'CIT_AU_753', Name: 'Millmerran'},
  {Id: 'CIT_AU_754', Name: 'Badger Creek'},
  {Id: 'CIT_AU_755', Name: 'Jamestown'},
  {Id: 'CIT_AU_756', Name: 'Cygnet'},
  {Id: 'CIT_AU_757', Name: 'Basin View'},
  {Id: 'CIT_AU_758', Name: 'Sheffield'},
  {Id: 'CIT_AU_759', Name: 'Curlewis'},
  {Id: 'CIT_AU_760', Name: 'Walloon'},
  {Id: 'CIT_AU_761', Name: 'Devon Meadows'},
  {Id: 'CIT_AU_762', Name: 'Halls Creek'},
  {Id: 'CIT_AU_763', Name: 'Yallourn North'},
  {Id: 'CIT_AU_764', Name: 'Mirani'},
  {Id: 'CIT_AU_765', Name: 'Beaufort'},
  {Id: 'CIT_AU_766', Name: 'River Heads'},
  {Id: 'CIT_AU_767', Name: 'Yenda'},
  {Id: 'CIT_AU_768', Name: 'Rockbank'},
  {Id: 'CIT_AU_769', Name: 'Bermagui'},
  {Id: 'CIT_AU_770', Name: 'Kinglake'},
  {Id: 'CIT_AU_771', Name: 'Crescent Head'},
  {Id: 'CIT_AU_772', Name: 'Tocumwal'},
  {Id: 'CIT_AU_773', Name: 'Nyora'},
  {Id: 'CIT_AU_774', Name: 'Gol Gol'},
  {Id: 'CIT_AU_775', Name: 'Cumbalum'},
  {Id: 'CIT_AU_776', Name: 'Allansford'},
  {Id: 'CIT_AU_777', Name: 'Hatton Vale'},
  {Id: 'CIT_AU_778', Name: 'Barham'},
  {Id: 'CIT_AU_779', Name: 'Ferny Creek'},
  {Id: 'CIT_AU_780', Name: 'Crystal Brook'},
  {Id: 'CIT_AU_781', Name: 'Maldon'},
  {Id: 'CIT_AU_782', Name: 'Little Grove'},
  {Id: 'CIT_AU_783', Name: 'Donald'},
  {Id: 'CIT_AU_784', Name: 'Longford'},
  {Id: 'CIT_AU_785', Name: 'Katherine'},
  {Id: 'CIT_AU_786', Name: 'Lake Cargelligo'},
  {Id: 'CIT_AU_787', Name: 'Drummond Cove'},
  {Id: 'CIT_AU_788', Name: 'Jensen'},
  {Id: 'CIT_AU_789', Name: 'Tumby Bay'},
  {Id: 'CIT_AU_790', Name: 'Inverleigh'},
  {Id: 'CIT_AU_791', Name: 'Culcairn'},
  {Id: 'CIT_AU_792', Name: 'Burrill Lake'},
  {Id: 'CIT_AU_793', Name: 'Hillston'},
  {Id: 'CIT_AU_794', Name: 'Richmond'},
  {Id: 'CIT_AU_795', Name: 'Cooran'},
  {Id: 'CIT_AU_796', Name: 'Clifton'},
  {Id: 'CIT_AU_797', Name: 'Metung'},
  {Id: 'CIT_AU_798', Name: 'Booral'},
  {Id: 'CIT_AU_799', Name: 'Oakhurst'},
  {Id: 'CIT_AU_800', Name: 'Gooburrum'},
  {Id: 'CIT_AU_801', Name: 'Wentworth'},
  {Id: 'CIT_AU_802', Name: 'Long Beach'},
  {Id: 'CIT_AU_803', Name: 'Bingara'},
  {Id: 'CIT_AU_804', Name: 'Table Top'},
  {Id: 'CIT_AU_805', Name: 'Bulahdelah'},
  {Id: 'CIT_AU_806', Name: 'Barcaldine'},
  {Id: 'CIT_AU_807', Name: 'Blackall'},
  {Id: 'CIT_AU_808', Name: 'Peterborough'},
  {Id: 'CIT_AU_809', Name: 'Marong'},
  {Id: 'CIT_AU_810', Name: 'Karuah'},
  {Id: 'CIT_AU_811', Name: 'Port Denison'},
  {Id: 'CIT_AU_812', Name: 'Barraba'},
  {Id: 'CIT_AU_813', Name: 'Toodyay'},
  {Id: 'CIT_AU_814', Name: 'Doomadgee'},
  {Id: 'CIT_AU_815', Name: 'Ripley'},
  {Id: 'CIT_AU_816', Name: 'Ravenshoe'},
  {Id: 'CIT_AU_817', Name: 'Hindmarsh'},
  {Id: 'CIT_AU_818', Name: 'Frederickton'},
  {Id: 'CIT_AU_819', Name: 'Warren'},
  {Id: 'CIT_AU_820', Name: 'Bombala'},
  {Id: 'CIT_AU_821', Name: 'Paraburdoo'},
  {Id: 'CIT_AU_822', Name: 'Dongara'},
  {Id: 'CIT_AU_823', Name: 'Streaky Bay'},
  {Id: 'CIT_AU_824', Name: 'Mortlake'},
  {Id: 'CIT_AU_825', Name: 'Surfside'},
  {Id: 'CIT_AU_826', Name: 'Meadows'},
  {Id: 'CIT_AU_827', Name: 'Wallington'},
  {Id: 'CIT_AU_828', Name: 'Douglas Park'},
  {Id: 'CIT_AU_829', Name: 'Howard'},
  {Id: 'CIT_AU_830', Name: 'Bagdad'},
  {Id: 'CIT_AU_831', Name: 'Alligator Creek'},
  {Id: 'CIT_AU_832', Name: 'Macclesfield'},
  {Id: 'CIT_AU_833', Name: 'Wundowie'},
  {Id: 'CIT_AU_834', Name: 'Kalbarri'},
  {Id: 'CIT_AU_835', Name: 'Corryong'},
  {Id: 'CIT_AU_836', Name: 'Hay Point'},
  {Id: 'CIT_AU_837', Name: 'Evandale'},
  {Id: 'CIT_AU_838', Name: 'Balranald'},
  {Id: 'CIT_AU_839', Name: 'Kurrajong Heights'},
  {Id: 'CIT_AU_840', Name: 'Quindalup'},
  {Id: 'CIT_AU_841', Name: 'Mount Macedon'},
  {Id: 'CIT_AU_842', Name: 'Rushworth'},
  {Id: 'CIT_AU_843', Name: 'Coleambally'},
  {Id: 'CIT_AU_844', Name: 'Waterview Heights'},
  {Id: 'CIT_AU_845', Name: 'Little River'},
  {Id: 'CIT_AU_846', Name: 'Bywong'},
  {Id: 'CIT_AU_847', Name: 'Sharon'},
  {Id: 'CIT_AU_848', Name: 'Queenscliff'},
  {Id: 'CIT_AU_849', Name: 'Kandos'},
  {Id: 'CIT_AU_850', Name: 'Valla Beach'},
  {Id: 'CIT_AU_851', Name: 'Batlow'},
  {Id: 'CIT_AU_852', Name: 'Chewton'},
  {Id: 'CIT_AU_853', Name: 'Penola'},
  {Id: 'CIT_AU_854', Name: 'Cardwell'},
  {Id: 'CIT_AU_855', Name: 'Glenden'},
  {Id: 'CIT_AU_856', Name: 'Neerim South'},
  {Id: 'CIT_AU_857', Name: 'Geeveston'},
  {Id: 'CIT_AU_858', Name: 'Renmark West'},
  {Id: 'CIT_AU_859', Name: 'Birdwood'},
  {Id: 'CIT_AU_860', Name: 'Beaconsfield'},
  {Id: 'CIT_AU_861', Name: 'Fitzroy Crossing'},
  {Id: 'CIT_AU_862', Name: 'Coolah'},
  {Id: 'CIT_AU_863', Name: 'Seven Mile Beach'},
  {Id: 'CIT_AU_864', Name: 'Toogoolawah'},
  {Id: 'CIT_AU_865', Name: 'Alton Downs'},
  {Id: 'CIT_AU_866', Name: 'Coraki'},
  {Id: 'CIT_AU_867', Name: 'Ranelagh'},
  {Id: 'CIT_AU_868', Name: 'Serpentine'},
  {Id: 'CIT_AU_869', Name: 'Mundubbera'},
  {Id: 'CIT_AU_870', Name: 'Berrigan'},
  {Id: 'CIT_AU_871', Name: 'Hanwood'},
  {Id: 'CIT_AU_872', Name: 'San Remo'},
  {Id: 'CIT_AU_873', Name: 'Lucknow'},
  {Id: 'CIT_AU_874', Name: 'Millthorpe'},
  {Id: 'CIT_AU_875', Name: 'Babinda'},
  {Id: 'CIT_AU_876', Name: 'Mount Dandenong'},
  {Id: 'CIT_AU_877', Name: 'Rainbow Beach'},
  {Id: 'CIT_AU_878', Name: 'Langwarrin'},
  {Id: 'CIT_AU_879', Name: 'Collinsville'},
  {Id: 'CIT_AU_880', Name: 'Silvan'},
  {Id: 'CIT_AU_881', Name: 'Jandowae'},
  {Id: 'CIT_AU_882', Name: 'Wongaling Beach'},
  {Id: 'CIT_AU_883', Name: 'Warragamba'},
  {Id: 'CIT_AU_884', Name: 'Henty'},
  {Id: 'CIT_AU_885', Name: 'The Rock'},
  {Id: 'CIT_AU_886', Name: 'Smiths Lake'},
  {Id: 'CIT_AU_887', Name: 'Mundijong'},
  {Id: 'CIT_AU_888', Name: 'Aldavilla'},
  {Id: 'CIT_AU_889', Name: 'Canungra'},
  {Id: 'CIT_AU_890', Name: 'Binningup'},
  {Id: 'CIT_AU_891', Name: 'Hawks Nest'},
  {Id: 'CIT_AU_892', Name: 'Allora'},
  {Id: 'CIT_AU_893', Name: 'Beauty Point'},
  {Id: 'CIT_AU_894', Name: 'Dunedoo'},
  {Id: 'CIT_AU_895', Name: 'Boyanup'},
  {Id: 'CIT_AU_896', Name: 'Buronga'},
  {Id: 'CIT_AU_897', Name: 'Valley Heights'},
  {Id: 'CIT_AU_898', Name: 'Normanton'},
  {Id: 'CIT_AU_899', Name: 'Greenwell Point'},
  {Id: 'CIT_AU_900', Name: 'Airlie Beach'},
  {Id: 'CIT_AU_901', Name: 'Hamilton Island'},
  {Id: 'CIT_AU_902', Name: 'Eden Park'},
  {Id: 'CIT_AU_903', Name: 'Timboon'},
  {Id: 'CIT_AU_904', Name: 'Witta'},
  {Id: 'CIT_AU_905', Name: 'Coominya'},
  {Id: 'CIT_AU_906', Name: 'Snug'},
  {Id: 'CIT_AU_907', Name: 'Raglan'},
  {Id: 'CIT_AU_908', Name: 'Boddington'},
  {Id: 'CIT_AU_909', Name: 'Berridale'},
  {Id: 'CIT_AU_910', Name: 'Nelly Bay'},
  {Id: 'CIT_AU_911', Name: 'Haddon'},
  {Id: 'CIT_AU_912', Name: 'Avoca'},
  {Id: 'CIT_AU_913', Name: 'Jarrahdale'},
  {Id: 'CIT_AU_914', Name: 'Dorrigo'},
  {Id: 'CIT_AU_915', Name: 'Ouyen'},
  {Id: 'CIT_AU_916', Name: 'Monto'},
  {Id: 'CIT_AU_917', Name: 'Glenview'},
  {Id: 'CIT_AU_918', Name: 'Bindoon'},
  {Id: 'CIT_AU_919', Name: 'Trentham'},
  {Id: 'CIT_AU_920', Name: 'Marulan'},
  {Id: 'CIT_AU_921', Name: 'Ardrossan'},
  {Id: 'CIT_AU_922', Name: 'Kinglake West'},
  {Id: 'CIT_AU_923', Name: 'Kojonup'},
  {Id: 'CIT_AU_924', Name: 'Yerrinbool'},
  {Id: 'CIT_AU_925', Name: 'Bamaga'},
  {Id: 'CIT_AU_926', Name: 'Beverley'},
  {Id: 'CIT_AU_927', Name: 'Darlington Point'},
  {Id: 'CIT_AU_928', Name: 'Oatlands'},
  {Id: 'CIT_AU_929', Name: 'Middleton'},
  {Id: 'CIT_AU_930', Name: 'Aldershot'},
  {Id: 'CIT_AU_931', Name: 'Gordon'},
  {Id: 'CIT_AU_932', Name: 'Menangle'},
  {Id: 'CIT_AU_933', Name: 'Gundaroo'},
  {Id: 'CIT_AU_934', Name: 'Brewarrina'},
  {Id: 'CIT_AU_935', Name: 'Kendall'},
  {Id: 'CIT_AU_936', Name: 'Cunnamulla'},
  {Id: 'CIT_AU_937', Name: 'Hughenden'},
  {Id: 'CIT_AU_938', Name: 'Indented Head'},
  {Id: 'CIT_AU_939', Name: 'Quorn'},
  {Id: 'CIT_AU_940', Name: 'Boggabri'},
  {Id: 'CIT_AU_941', Name: 'Tieri'},
  {Id: 'CIT_AU_942', Name: 'Cherbourg'},
  {Id: 'CIT_AU_943', Name: 'Moonbi'},
  {Id: 'CIT_AU_944', Name: 'Monash'},
  {Id: 'CIT_AU_945', Name: 'Bowraville'},
  {Id: 'CIT_AU_946', Name: 'McLaren Flat'},
  {Id: 'CIT_AU_947', Name: 'Carlton'},
  {Id: 'CIT_AU_948', Name: 'Meningie'},
  {Id: 'CIT_AU_949', Name: 'Bakers Hill'},
  {Id: 'CIT_AU_950', Name: 'Eudlo'},
  {Id: 'CIT_AU_951', Name: 'Carisbrook'},
  {Id: 'CIT_AU_952', Name: 'Lorne'},
  {Id: 'CIT_AU_953', Name: 'South Murwillumbah'},
  {Id: 'CIT_AU_954', Name: 'Wurruk'},
  {Id: 'CIT_AU_955', Name: 'Cressy'},
  {Id: 'CIT_AU_956', Name: 'Echunga'},
  {Id: 'CIT_AU_957', Name: 'Augusta'},
  {Id: 'CIT_AU_958', Name: 'Cowell'},
  {Id: 'CIT_AU_959', Name: 'Peak Hill'},
  {Id: 'CIT_AU_960', Name: 'Yungaburra'},
  {Id: 'CIT_AU_961', Name: 'Paringa'},
  {Id: 'CIT_AU_962', Name: 'Dampier'},
  {Id: 'CIT_AU_963', Name: 'Burra'},
  {Id: 'CIT_AU_964', Name: 'Springsure'},
  {Id: 'CIT_AU_965', Name: 'Minlaton'},
  {Id: 'CIT_AU_966', Name: 'Yulara'},
  {Id: 'CIT_AU_967', Name: 'Wahgunyah'},
  {Id: 'CIT_AU_968', Name: 'Ellalong'},
  {Id: 'CIT_AU_969', Name: 'Moorooduc'},
  {Id: 'CIT_AU_970', Name: 'Mylor'},
  {Id: 'CIT_AU_971', Name: 'Nicholson'},
  {Id: 'CIT_AU_972', Name: 'Franklin'},
  {Id: 'CIT_AU_973', Name: 'Eagle Point'},
  {Id: 'CIT_AU_974', Name: 'Brandon'},
  {Id: 'CIT_AU_975', Name: 'Kersbrook'},
  {Id: 'CIT_AU_976', Name: 'Kalbar'},
  {Id: 'CIT_AU_977', Name: 'Minden'},
  {Id: 'CIT_AU_978', Name: 'Parsons Point'},
  {Id: 'CIT_AU_979', Name: 'Tinonee'},
  {Id: 'CIT_AU_980', Name: 'Julatten'},
  {Id: 'CIT_AU_981', Name: 'Robe'},
  {Id: 'CIT_AU_982', Name: 'Kew'},
  {Id: 'CIT_AU_983', Name: 'Greenock'},
  {Id: 'CIT_AU_984', Name: 'Bouldercombe'},
  {Id: 'CIT_AU_985', Name: 'Glengarry'},
  {Id: 'CIT_AU_986', Name: 'Mollymook'},
  {Id: 'CIT_AU_987', Name: 'Tallangatta'},
  {Id: 'CIT_AU_988', Name: 'Briagolong'},
  {Id: 'CIT_AU_989', Name: 'Jabiru'},
  {Id: 'CIT_AU_990', Name: 'Puckapunyal'},
  {Id: 'CIT_AU_991', Name: 'Keith'},
  {Id: 'CIT_AU_992', Name: 'Balhannah'},
  {Id: 'CIT_AU_993', Name: 'Cabarlah'},
  {Id: 'CIT_AU_994', Name: 'Tomerong'},
  {Id: 'CIT_AU_995', Name: 'Yarraman'},
  {Id: 'CIT_AU_996', Name: 'Mallacoota'},
  {Id: 'CIT_AU_997', Name: 'Panton Hill'},
  {Id: 'CIT_AU_998', Name: 'Sassafras Gully'},
  {Id: 'CIT_AU_999', Name: 'Lockhart'},
  {Id: 'CIT_AU_1000', Name: 'Coutts Crossing'},
  {Id: 'CIT_AU_1001', Name: 'Helidon'},
  {Id: 'CIT_AU_1002', Name: 'Nana Glen'},
  {Id: 'CIT_AU_1003', Name: 'Gin Gin'},
  {Id: 'CIT_AU_1004', Name: 'Gin Gin'},
  {Id: 'CIT_AU_1005', Name: 'Charlton'},
  {Id: 'CIT_AU_1006', Name: 'Dimbulah'},
  {Id: 'CIT_AU_1007', Name: 'Avenel'},
  {Id: 'CIT_AU_1008', Name: 'Lawrence'},
  {Id: 'CIT_AU_1009', Name: 'Lower Beechmont'},
  {Id: 'CIT_AU_1010', Name: 'Mulambin'},
  {Id: 'CIT_AU_1011', Name: 'Elliott Heads'},
  {Id: 'CIT_AU_1012', Name: 'Bluewater'},
  {Id: 'CIT_AU_1013', Name: 'Murrurundi'},
  {Id: 'CIT_AU_1014', Name: 'Riverton'},
  {Id: 'CIT_AU_1015', Name: 'Port Broughton'},
  {Id: 'CIT_AU_1016', Name: 'Smythesdale'},
  {Id: 'CIT_AU_1017', Name: 'Goombungee'},
  {Id: 'CIT_AU_1018', Name: 'Mitchell'},
  {Id: 'CIT_AU_1019', Name: 'Maitland'},
  {Id: 'CIT_AU_1020', Name: 'Coleraine'},
  {Id: 'CIT_AU_1021', Name: 'Jerilderie'},
  {Id: 'CIT_AU_1022', Name: 'Yellow Rock'},
  {Id: 'CIT_AU_1023', Name: 'Yallingup'},
  {Id: 'CIT_AU_1024', Name: 'Bucca'},
  {Id: 'CIT_AU_1025', Name: 'Mount Pleasant'},
  {Id: 'CIT_AU_1026', Name: 'Wesburn'},
  {Id: 'CIT_AU_1027', Name: 'Nome'},
  {Id: 'CIT_AU_1028', Name: 'Lethbridge'},
  {Id: 'CIT_AU_1029', Name: 'Nar Nar Goon'},
  {Id: 'CIT_AU_1030', Name: 'Capella'},
  {Id: 'CIT_AU_1031', Name: 'The Palms'},
  {Id: 'CIT_AU_1032', Name: 'Millfield'},
  {Id: 'CIT_AU_1033', Name: 'Dunbogan'},
  {Id: 'CIT_AU_1034', Name: 'Tomakin'},
  {Id: 'CIT_AU_1035', Name: 'Currie'},
];

export {COUNTRY_CODES, US_CITIES, TW_CITIES, AU_CITIES};

export const getCities = (country:any) => {
  let cities = [];
  if (country === 'US') {
    // adding state name along with city name
    let citiesWithState:any = [];
    US_CITIES.map(city => {
      const stateName = city.stateName ? ', ' + city.stateName : '';
      citiesWithState.push({...city, Name: city.Name + stateName});
    });
    cities = citiesWithState;
  } else if (country === 'AU') {
    cities = AU_CITIES;
  } else if (country === 'TW') {
    cities = TW_CITIES;
  }

  return cities;
};
