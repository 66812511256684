import {getTimeZoneFromZip} from '../utils/commonMethods'
import moment from 'moment';

export function formatEventTime(startTimestamp: string, endTimestamp: string, zipCode: string): string {
  const startTime = moment(startTimestamp).tz(getTimeZoneFromZip(parseInt(zipCode)))
  const endTime = moment(endTimestamp).tz(getTimeZoneFromZip(parseInt(zipCode)));

  // Format date: Thu, Aug 29, 2024
  const formattedDate = startTime.format('ddd, MMM D, YYYY');

  // Format time: 5:05 AM
  const formattedStartTime = startTime.format('h:mm A');
  const formattedEndTime = endTime.format('h:mm A');

  return `${formattedDate} ${formattedStartTime} - ${formattedEndTime}`;
}
export function formattedTime(startTimestamp: string, endTimestamp: string, zipCode: string): string {
  const startTime = moment(startTimestamp).tz(getTimeZoneFromZip(parseInt(zipCode)));
  const endTime = moment(endTimestamp).tz(getTimeZoneFromZip(parseInt(zipCode)));

  // Format time: 5:05 AM
  const formattedStartTime = startTime.format('h:mm A');
  const formattedEndTime = endTime.format('h:mm A');

  return `${formattedStartTime} - ${formattedEndTime}`;
}