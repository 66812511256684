export function getCurrencySymbol(currencyCode:any) {
    switch (currencyCode.toUpperCase()) {
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      case 'GBP':
        return '£';
      case 'JPY':
        return '¥';
      case 'CAD':
        return 'C$';
      case 'AUD':
        return 'A$';
      case 'CHF':
        return 'CHF'; // Swiss Franc symbol is often shown as 'CHF'
      case 'CNY':
        return '¥'; // Chinese Yuan often uses the same symbol as Japanese Yen
      case 'INR':
        return '₹';
      default:
        return currencyCode; // Return the code itself if no symbol is found
    }
  }