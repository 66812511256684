import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, TextField, Grid, Box, useMediaQuery, useTheme } from '@mui/material';
import useDebounce from '../customHooks/useDebounce';
import { getCities } from '../constants/countryCodes';

const destinations = getCities('US'); // Assume this returns 30k records

const CityAutocomplete = ({ value, onChange, onInputChange: parentOnInputChange }:any) => {
  const [inputValue, setInputValue] = useState(value?.Name || '');
  const [options, setOptions] = useState(destinations);
  const debouncedInputValue = useDebounce(inputValue, 300);

  const [open, setOpen] = useState(false);
   
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const filterOptions = useCallback(() => {
    if (debouncedInputValue.length < 2) return [];

    return destinations
      .filter(
        (option:any) =>
          option.Name.toLowerCase().includes(debouncedInputValue.toLowerCase()) &&
          option !== value // Exclude selected option from the list
      )
      .slice(0, 100); // Limit to 100 results
  }, [debouncedInputValue, value]);

  useEffect(() => {
    setOptions(filterOptions());
  }, [filterOptions]);

  const handleInputChange = (event:any, newInputValue:any) => {
    setInputValue(newInputValue);
    if (newInputValue.length >= 2) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    parentOnInputChange(newInputValue);
  };

  const handleSelectChange = (event:any, newSelectedOption:any) => {
    onChange(newSelectedOption);
    setOpen(false);
  };
  const inputStyles = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#e0e0e0',
      },
      '&:hover fieldset': {
        borderColor: '#2D3178',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2D3178',
      },
    },
    '& .MuiInputBase-input': {
      padding: '4px 6px',
    },
    '& .MuiInputLabel-root': {
      transform: 'translate(14px, 12px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.7)',
      },
    },
   
  };

  return (
    <Grid item xs={12} sm={6} md={5} sx={{ width: '85%' }}>
      <Autocomplete
        fullWidth
        options={options}
        getOptionLabel={(option) => option.Name.length > 50 ? `${option.Name.substring(0, 50)}...` : option.Name}
        value={value}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleSelectChange}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option.id}>
            {option.Name.length > 50 ? `${option.Name.substring(0, 50)}...` : option.Name}
          </Box>
        )}
        renderInput={(params) => (
          <TextField  {...params} sx={inputStyles} label=" Search Location " variant="outlined" />
        )}
        sx={{
          width: '100%',
          '.MuiInputBase-root': {
            padding: '4px 14px',   // Adjust padding
            fontSize: '0.9rem',    // Adjust font size
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: isMobile ? '4px' : '4px',
            '& .MuiAutocomplete-popupIndicator': {
              display: 'none',
            },
          },
        }}
        filterOptions={(x) => x}
        open={open && options.length > 0 && inputValue.length >= 2}
      />
    </Grid>
  );
};

export default CityAutocomplete;