import { analytics, logEvent } from './firebase';
export const trackHomePageView = (pagePath: string) => {
  logEvent(analytics, 'web_experience_list_view', { page_path: pagePath });
};

export const trackExperienceDetailView = (pagePath: string) => {
  logEvent(analytics, 'web_experience_detail_view', { page_path: pagePath });
}

// Track "Book Experience" button clicks
export const trackBookExperienceClick = (experienceId: string, experienceName: string) => {
  logEvent(analytics, 'web_book_experience_click', {
    experience_id: experienceId,
    experience_name: experienceName
  });
};

// Track completed transactions
export const trackCompletedTransaction = (experienceId: string, experienceName: string, value: string) => {
  logEvent(analytics, 'web_transaction_completed', {
    experience_id: experienceId,
    experience_name: experienceName,
    value: parseFloat(value) // Include the transaction value if available
  });
};
