import React, { useEffect, useState } from 'react';
import { Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery, Box, IconButton, FormControl, InputLabel, Select, MenuItem, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker, LocalizationProvider, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTheme } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';
import CityAutocomplete from './Autocomplete';
import ClearIcon from '@mui/icons-material/Clear';
import isBetween from 'dayjs/plugin/isBetween';
import { SelectChangeEvent } from '@mui/material/Select'
import CloseIcon from '@mui/icons-material/Close';
const categoryOptions = [
    { id: 1, src: require('../assets/images/arts.svg').default, label: 'Arts' },
    { id: 2, src: require('../assets/images/wellness.svg').default, label: 'Wellness' },
    { id: 3, src: require('../assets/images/culinary.svg').default, label: 'Culinary' },
    { id: 4, src: require('../assets/images/crafts.svg').default, label: 'Crafts' },
    { id: 5, src: require('../assets/images/beverages.svg').default, label: 'Beverages' },
    { id: 6, src: require('../assets/images/technology.svg').default, label: 'Technology' },
];
function SearchBar({ onSearchClick}: any) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
    const [selectedLocation, setSelectedLocation]:any = useState(null);
    const [activeButton, setActiveButton] = useState<string | null>(null);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [category, setCategory] = useState('');
    const [selectedCity, setSelectedCity]: any = useState(null);
    const [dateRange, setDateRange] = useState('');
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [isclearLocation, setClearLocation] = useState(false);
    const [isclearDate, setClearDate] = useState(false);
    const [isclearCategoty, setClearCategoty] = useState(false);
    const [issearchClicked, setsearchClicked] = useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };
    useEffect(() => {
        console.log("isclearCategoty =>",isclearCategoty);
        localStorage.setItem('searchButonClick',"false")
        handleSearch()
        // if(category){
        //     handleSearch();
        // }
        // if (isclearCategoty === true) {
        //     // localStorage.getItem('searchButonClick')
        //     handleSearch(selectedDate ? selectedDate.format('YYYY-MM-DD') : null, selectedLocation || selectedCity?.Name, '');

        // }
        // if(isclearDate === true){
        //     // localStorage.getItem('searchButonClick')
        //     handleSearch(null, selectedLocation || selectedCity?.Name, category);
        // }
        // if(isclearLocation === true){
        //     //localStorage.getItem('searchButonClick')
        //     handleSearch(selectedDate ? selectedDate.format('YYYY-MM-DD'):null, null, category);
        // }

        // if(isclearCategoty || isclearDate||isclearLocation){
        //     handleSearch(null, null, ''); 
        // }


    }, [category, selectedCity, selectedDate,startDate,endDate])
    const handleClose = () => {
        setOpen(false);
        // setCategory('')
        // setStartDate(null)
        // setEndDate(null)
        // setSelectedDate(null)
        // setSelectedLocation(null)
    };
    const handleClearAll = () => {
        setCategory('')
        setStartDate(null)
        setEndDate(null)
        setSelectedDate(null)
        setSelectedLocation(null)
        setSelectedCity(null)
        setClearDate(true)
        setClearCategoty(true)
        setClearLocation(true)
        
    };
    const handleDateChange = (newValue: Dayjs | null) => {
        setStartDate(null)
        setActiveButton(null)
        setEndDate(null)
        setSelectedDate(newValue);
        setClearDate(false)
    };
    const handleCategoryChange = (event: SelectChangeEvent<string>) => {
        setCategory(event.target.value); // Update state with the selected value
        setClearCategoty(false)
    };
    const setThisWeek = () => {
        setStartDate(null);
        setEndDate(null);
        const today = dayjs();

        // Start the week from Monday (1) and end on Sunday (7)
        const startOfWeek = today.startOf('week').add(1, 'day'); // Adjust to Monday
        const endOfWeek = today.endOf('week').add(1, 'day');     // Adjust to Sunday
        
        // If today is past the start of the week, show only future days
        const effectiveStartOfWeek = today.isAfter(startOfWeek) ? today : startOfWeek;

        setSelectedDate(null);
        setActiveButton('week');
        setStartDate(effectiveStartOfWeek.format('YYYY-MM-DD'));
        setEndDate(endOfWeek.format('YYYY-MM-DD'));
        setDateRange(`${effectiveStartOfWeek.format('MMM D')} - ${endOfWeek.format('MMM D')}`);
        setIsDatePickerOpen(false);
        setClearDate(false)
    };

    const setThisMonth = () => {
        setStartDate(null)
        setEndDate(null)
        const today = dayjs();
        const startOfMonth = dayjs().startOf('month');
        const endOfMonth = dayjs().endOf('month');

        // If today is past the start of the month, show only future days
        const effectiveStartOfMonth = today.isAfter(startOfMonth) ? today : startOfMonth;

        setSelectedDate(null);
        setActiveButton('month');
        setStartDate(effectiveStartOfMonth.format('YYYY-MM-DD'))
        setEndDate(endOfMonth.format('YYYY-MM-DD'))
        setDateRange(`${effectiveStartOfMonth.format('MMM D')} - ${endOfMonth.format('MMM D')}`);
        setIsDatePickerOpen(false);
        setClearDate(false)
    };
    const CustomDay = (props: PickersDayProps<Dayjs>) => {
        const { day, selected, ...other } = props;

        const isInRange = startDate && endDate && day.isBetween(startDate, endDate, null, '[]'); // Check if the day is within the range

        return (
            <PickersDay
                {...other}
                day={day}
                selected={selected}
                sx={{
                    backgroundColor: isInRange ? 'rgba(45, 49, 120, 0.2)' : undefined, // Highlight the range
                    '&:hover': {
                        backgroundColor: isInRange ? 'rgba(45, 49, 120, 0.3)' : undefined,
                    },
                }}
            />
        );
    };


    const FilterButton = ({ onClick, children, isActive }: { onClick: () => void, children: React.ReactNode, isActive: boolean }) => (
        <Button
            className='custom-button'
            variant="outlined"
            onClick={onClick}
            sx={{
                borderRadius: '50px',
                color: isActive ? 'white' : '#2D3178',
                backgroundColor: isActive ? '#2D3178' : 'white',
                padding: '4px 8px',
                fontSize: '0.75rem',
                textTransform: 'none',
                border: isActive ? '1px solid #2D3178' : '1px solid #e0e0e0',
                textAlign: 'center',
                lineHeight: 1,
                marginRight: '8px',
                '&:hover': {
                    backgroundColor: isActive ? '#2D3178' : 'white',
                    border: isActive ? '1px solid #2D3178' : '1px solid black'
                }
            }}
        >
            {children}
        </Button>
    );


    const CustomFooter = ({ onAccept, onCancel }: { onAccept: () => void, onCancel: () => void }) => {
        const isMobileView = useMediaQuery('(max-width:600px)'); // Check if the view is mobile

        return (
            <Box className='custom-filter' display="flex" justifyContent="center" flexDirection="column">
                {/* Centered buttons only in web view */}
                {!isMobileView && (
                    <Box display="flex" justifyContent="center" flexDirection="row" mb={2}>
                        <FilterButton onClick={setThisWeek} isActive={activeButton === 'week'}>This week</FilterButton>
                        <FilterButton onClick={setThisMonth} isActive={activeButton === 'month'}>This month</FilterButton>
                        {/* <FilterButton onClick={onAccept} isActive={false}>OK</FilterButton>  */}
                    </Box>
                )}

                {/* Four buttons in mobile view */}
                {isMobileView && (
                    <Box display="flex" justifyContent="space-around" flexDirection="row">
                        <FilterButton onClick={setThisWeek} isActive={activeButton === 'week'}>This week</FilterButton>
                        <FilterButton onClick={setThisMonth} isActive={activeButton === 'month'}>This month</FilterButton>
                        {/* <FilterButton onClick={onCancel} isActive={false}>Cancel</FilterButton> */}
                        <FilterButton onClick={onAccept} isActive={false}>OK</FilterButton>
                    </Box>
                )}
            </Box>
        );
    };
    useEffect(()=>{
        // setSelectedLocation(selectedCity)
        if(!selectedCity){
            setSelectedLocation(null)
            setClearLocation(true)
        }else{
            setClearLocation(false)
        }
        
    },[selectedCity])
    const handleChange = (value: any) => {
        // setSelectedLocation(value)
        // if (!value){
        //     setSelectedLocation(null)
        //     setClearLocation(true)
        // } else{
        //     setClearLocation(false)
        // }
    }
    const handleClearDate = () => {
        setSelectedDate(null);
        setActiveButton(null);
        setDateRange('');
        setStartDate('');
        setEndDate('')
        setClearDate(true)

    }
    const handleCancelCategory = () => {
        setCategory('');
        setClearCategoty(true)
    }
    const fixedSearchBarStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
        zIndex: 1000,
        padding: '10px 0',
        marginTop: '10px',
        marginBottom: '10rem'
    };
    const inputStyles = {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#e0e0e0',
            },
            '&:hover fieldset': {
                borderColor: '#2D3178',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#2D3178',
            },
        },
        '& .MuiInputBase-input': {
            padding: '10px 14px',
        },
        '& .MuiInputLabel-root': {
            transform: 'translate(14px, 12px) scale(1)',
            '&.MuiInputLabel-shrink': {
                transform: 'translate(14px, -6px) scale(0.75)',
            },
        },
    };

    const handleSearch = (date: string | null = null, location: string | null = null, cat: string = '') => {
        const formattedDate = date || (selectedDate ? selectedDate.format('YYYY-MM-DD') : null);
        const isInRange = startDate && endDate;
        const start_date = isInRange ? startDate : null;
        const end_date = isInRange ? endDate : null;
        onSearchClick(location || selectedLocation?.Name || selectedCity?.Name, formattedDate, start_date, end_date, cat || category);

        if (isMobile) {
            handleClose();
        }
    };
    const renderClearButton = (onClear: any) => (
        <InputAdornment position="end">
            <IconButton
                edge="end"
                onClick={handleCancelCategory}
                style={{ padding: '2rem' }}
            >
                <ClearIcon fontSize="small" />
            </IconButton>
        </InputAdornment>
    );
    const renderSearchFields = () => (
        <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ marginTop: '1rem' }}>
            <Grid item xs={12} sm={3}>
                <FormControl fullWidth sx={inputStyles}>
                    <InputLabel id="category-select-label">Category</InputLabel>
                    <Select
                        labelId="category-select-label"
                        id="category-select"
                        value={category}
                        label="Category"
                        onChange={handleCategoryChange}
                        endAdornment={category ? renderClearButton(() => setCategory('')) : null}
                    >
                        {categoryOptions.map((option) => (
                            <MenuItem key={option.id} value={option.label}>
                                <Box display="flex" alignItems="center">
                                    <img src={option.src} alt={option.label} width="20px" height="20px" />
                                    <Box ml={1}>{option.label}</Box>
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <CityAutocomplete
                value={selectedCity}
                onChange={setSelectedCity}
                onInputChange={handleChange}

            />
            <Grid item xs={12} sm={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        minDate={dayjs()}
                        open={isDatePickerOpen}
                        onOpen={() => setIsDatePickerOpen(true)}
                        onClose={() => setIsDatePickerOpen(false)}
                        slots={{
                            day: CustomDay,
                            actionBar: CustomFooter,
                            textField: (params) => (
                                <TextField
                                    {...params}
                                    sx={inputStyles}
                                    value={dateRange || (selectedDate ? selectedDate.format('MMM D, YYYY') : '')}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {(dateRange || selectedDate) && (
                                                    <IconButton
                                                        size="small"
                                                        onClick={handleClearDate}
                                                        style={{ position: 'absolute', right: '40px' }}
                                                    >
                                                        <ClearIcon fontSize="small" />
                                                    </IconButton>
                                                )}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            ),
                        }}
                    />
                </LocalizationProvider>
            </Grid>
            {/* <Grid item xs={12} sm="auto">
                <Button
                    variant="contained"
                    onClick={() => handleSearch()}
                    sx={{
                        backgroundColor: "#2D3178",
                        borderRadius: '50%',
                        minWidth: '50px',
                        height: '50px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <SearchIcon />
                </Button>
            </Grid> */}
        </Grid>
    );

    return (
        <Box sx={fixedSearchBarStyle}>
            <Grid container justifyContent="center" style={{ marginTop: '3rem' }}>
                <Grid item xs={12} sm={10} md={8} style={{ textAlign: 'center' }}>
                    {isMobile ? (
                        <Box display="none" justifyContent="center" width="100%" >
                            <Button
                                sx={{
                                    backgroundColor: '#2D3178',
                                    borderRadius: 50,
                                    color: '#ffff',
                                    padding: '10px',
                                    minWidth: '50px',
                                    position: 'relative',
                                    border: '1px solid #2D3178',
                                    textTransform:'capitalize',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        border: 'none',
                                        boxShadow: 'none'
                                    },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    fontSize:18
                                }}
                                onClick={handleClickOpen}
                            >
                                <SearchIcon  />
                                {/* Search */}
                            </Button>
                        </Box>
                    ) : (renderSearchFields())}
                </Grid>


                <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                    <DialogTitle>Search   <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton></DialogTitle>
                    <DialogContent >
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={12} sx={{ marginTop: '5%' }}>
                                <CityAutocomplete
                                  value={selectedCity}
                                  onChange={setSelectedCity}
                                  onInputChange={handleChange}
                                //   sx={{ width: '100%', mb: 2 }}
                                   />
                            </Grid>
                            <Grid item xs={12} sx={{ width: '82%' }}>
                                <FormControl sx={{ mb: 2, marginTop: '1rem', width: '85%' }}>
                                    <InputLabel id="category-select-label">Category</InputLabel>
                                    <Select
                                        labelId="category-select-label"
                                        id="category-select"
                                        value={category}
                                        label="Category"
                                        onChange={handleCategoryChange}
                                    >
                                        {categoryOptions.map((option) => (
                                            <MenuItem key={option.id} value={option.label}>
                                                <Box display="flex" alignItems="center">
                                                    <img src={option.src} alt={option.label} width="20px" height="20px" />
                                                    <Box ml={1}>{option.label}</Box>
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {category && ( // Show the button if a category is selected
                                <IconButton
                                    size="small"
                                    style={{ position: 'absolute', top: '52%', left: isMobile? '10rem' : '12rem', transform: 'translateY(-50%)' }}
                                    onClick={handleCancelCategory}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            )}
                            <Grid item xs={12}>
                                <Box sx={{ position: 'relative', display: 'inline-flex', width: '88%' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Date"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            minDate={dayjs()}
                                            slots={{
                                                day: CustomDay,
                                                actionBar: CustomFooter,
                                                textField: (params) => (
                                                    <TextField
                                                        {...params}
                                                        sx={inputStyles}
                                                        value={dateRange || (selectedDate ? selectedDate.format('MMM D, YYYY') : '')}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {(dateRange || selectedDate) && (
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={handleClearDate}
                                                                            style={{ position: 'absolute', right: isMobile ? '20px' : '40px' }}
                                                                        >
                                                                            <ClearIcon fontSize="small" />
                                                                        </IconButton>
                                                                    )}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                ),
                                            }}
                                            slotProps={{
                                                textField: {
                                                    InputProps: {
                                                        endAdornment: null, // Remove the calendar icon
                                                    }
                                                },
                                                // actionBar:{
                                                //     onCancel:handleCancelDate,
                                                //     onAccept: handleCancelDate
                                                // }

                                            }}

                                            sx={{ width: '80%' }}
                                        />
                                    </LocalizationProvider>
                                    {/* {selectedDate && (
                                        <IconButton
                                            size="small"
                                            onClick={handleClearDate}
                                            sx={{
                                                position: 'absolute',
                                                right: '5rem', // Positioning the button inside the date field, towards the right
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                            }}
                                        >
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    )} */}
                                </Box>
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'flex-start' }}>
                        <Button onClick={handleClearAll}  sx={{
                            marginLeft:'1rem',
                            backgroundColor: "#ffff",  // Set background color to white
                            color: '#2D3178',          // Change text color
                            border: '2px solid #2D3178', // Add border
                            padding: '2px 10px',       // Reduce padding
                            fontSize: '0.7rem',        // Reduce font size
                            minHeight: '30px',         // Set minimum height
                            textTransform:'none'
                        }}>
                            Clear all
                        </Button>
                        {/* <Button
                            onClick={() => handleSearch()}
                            variant="contained"
                            sx={{
                                backgroundColor: "#2D3178",
                                borderRadius: '50%',
                                minWidth: '50px',
                                height: '50px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                '&:hover': {
                                    backgroundColor: '#2D3178',
                                },
                            }}
                        >
                            <SearchIcon />
                        </Button> */}
                    </DialogActions>
                </Dialog>
            </Grid>
        </Box>
    );
}

export default SearchBar;