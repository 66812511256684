import React, { useEffect, useState, useCallback } from 'react';
import { Dialog, DialogContent, Typography, Button, Box } from '@mui/material';
import { useGoogleLogin, TokenResponse } from '@react-oauth/google';
import axios from 'axios';

interface UserInfo {
  id: string;
  email: string;
  name: string;
  picture: string;
}

interface SignInDialogProps {
  open: boolean;
  onClose: () => void;
  onSignInSuccess: (user: UserInfo) => void;
}

const SignInDialog: React.FC<SignInDialogProps> = ({ open, onClose, onSignInSuccess }) => {
  const [error, setError] = useState<string | null>(null);

  const handleSuccess = useCallback(async (tokenResponse: TokenResponse) => {
    try {
      const { data } = await axios.get<UserInfo>(
        'https://www.googleapis.com/oauth2/v1/userinfo',
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
            Accept: 'application/json',
          },
        }
      );
      onSignInSuccess(data);
      onClose();
    } catch (error) {
      console.error('Error fetching user info:', error);
      setError('Failed to fetch user information. Please try again.');
    }
  }, [onSignInSuccess, onClose]);

  const handleError = useCallback(() => {
    setError('Login failed. Please try again.');
  }, []);

  const login = useGoogleLogin({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  useEffect(() => {
    if (open) {
      setError(null);
      login();

      // Close the dialog after 2 seconds
      const timer = setTimeout(() => {
        onClose();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [open, login, onClose]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        {error ? (
          <>
            <Typography color="error">{error}</Typography>
            <Button onClick={handleClose} variant="contained" color="primary" style={{ marginTop: '1rem' }}>
              Close
            </Button>
          </>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Typography>Please wait...</Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SignInDialog;
