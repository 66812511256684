import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#dc004e',
        },
    },
    typography: {
        fontFamily: 'Roc Grotesk, Poppins, Arial, Helvetica, Verdana, Tahoma, sans-serif ',
        fontWeightRegular: 400,
        fontWeightBold: 700,
    },
});

export default theme;
