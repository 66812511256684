const STRINGS = {
    bookClass: {
      title: 'Book Experience',
      date: 'Date & Time',
      location: 'Location',
      applyCoupons: 'Apply Coupons',
      priceDetails: 'Price Details',
      pricePerPerson: 'Price per person',
      couponDiscount: 'Coupon Applied',
      subTotal: 'Subtotal',
      serviceCharge: 'Service Charge',
      grandTotal: 'Grand Total',
      cancelPolicy: 'Cancellation Policy',
      confirmButton: 'Confirm & Pay',
      success: 'Success',
      noOfSpots: 'No. of Spots',
      groupSize: 'Group Size',
    },

  };
  
  export { STRINGS };
  