import React, { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Box } from '@mui/material';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface PaymentFormProps {
  amount: number;
  onSuccess: () => void;
  onError: (errorMessage: any) => void;
  paymentData: any; // Expected to contain clientSecret
}

const stripePaymentIntent = async (bookingData: any): Promise<any> => {
  console.log("bookingData =>", bookingData);
  const token = localStorage.getItem('authToken');
  const url = `${process.env.REACT_APP_BASE_API_URL}/v1/booking/stripe-payment-response/${bookingData.bookingId}`;

  try {
    const response = await axios.post(url, {
      id: bookingData.clientSecret.split('_secret_')[0],
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error creating booking:', error);
    throw new Error('Failed to create booking');
  }
};

const deleteBooking = async (bookingId: string,userId:string): Promise<any> => {
  const token = localStorage.getItem('authToken');
  const url = `${process.env.REACT_APP_BASE_API_URL}/v1/booking/${bookingId}/${userId}`;

  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data; // Return the response data from the deletion
  } catch (error: any) {
    let errors = error?.response?.data?.message;
    
    // Customize error messages based on specific conditions
    if (error?.response?.data?.message === 'Booking not found') {
      errors = 'The booking you are trying to delete does not exist.';
    }
    if (error?.response?.data?.message === 'Cannot delete completed bookings') {
      errors = 'You cannot delete a booking that has already been completed.';
    }
    
    console.log("errors =>",errors);
    
  }
};
const PaymentForm: React.FC<PaymentFormProps> = ({ amount, onSuccess, onError, paymentData }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardComplete, setCardComplete] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCardChange = (event: any) => {
    setCardComplete(event.complete);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  
    if (!stripe || !elements || !cardComplete) return;
  
    setIsProcessing(true);
  
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)!,
    });
  
    if (error) {
      // Check for specific error related to test card in live mode
      if (error.code === 'card_declined' && error.decline_code === 'live_mode_test_card') {
        onError('Please use a valid card.');
      } else {
        onError(error.message);
      }
      setIsProcessing(false);
      return;
    }
    const { clientSecret,bookingId,userId } = paymentData;
    try {
      // const response = await stripePaymentIntent(paymentData);
  
      // const paymentResponse = response.guideInfo;
  
        const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethod.id,
        });
  
        if (confirmError) {
          // Check for specific error related to test card in live mode
          if (confirmError.code === 'card_declined' && confirmError.decline_code === 'live_mode_test_card') {
            onError('Please use a valid card.');
            await deleteBooking(bookingId,userId)
          } else {
            onError(confirmError.message);
          }
        } else if (paymentIntent?.status === 'succeeded') {
          const response = await stripePaymentIntent(paymentData);
          onSuccess();
        } else {
          onError('Payment did not succeed. Please try again.');
          await deleteBooking(bookingId,userId)
        }
    } catch (axiosError) {
      console.error('[axiosError]', axiosError);
      onError('Payment processing failed. Please try again.');
      await deleteBooking(bookingId,userId)
    } finally {
      setIsProcessing(false);
    }
  };
  

  return (
    <form onSubmit={handleSubmit}>
      <Box 
        sx={{
          maxWidth: '500px',
          margin: 'auto',
          padding: '20px',
          backgroundColor: '#f7f7f7',
          borderRadius: '10px'
        }}
      >
        <CardElement
          options={{
            hidePostalCode: true, // Disable zip code field
            style: {
              base: {
                fontSize: isMobile ? '13px' : '18px',
                padding: '10px',
                color: '#424770',
                letterSpacing: '0.025em',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
          onChange={handleCardChange}
        />
        <Box mt={4}>
          <Button 
            type="submit" 
            variant="contained" 
            color="primary"
            disabled={!cardComplete || isProcessing}
            sx={{
              padding: isMobile ? '5px 10px' :  '10px 20px',
              fontSize: isMobile ? '14px' : '16px'
            }}
          >
          {isProcessing ? 'Processing...' : 'Pay Now'}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default PaymentForm;